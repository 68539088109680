/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import Spinner from 'react-bootstrap/Spinner';


export default function HRUserSafetyRecordVersionHeader({users, versions, selectedVersion, setSelectedVersion, isLoading}) {

    const selectedVersionAuthor = selectedVersion ? users.find(u => u.companyUserUid === selectedVersion.author) : undefined;


    const versionElements = versions.map((version) => {
        const versionAuthor = users.find(u => u.companyUserUid === version.author);

        return (
            <Dropdown.Item key={version.uid} onClick={() => setSelectedVersion(version)}>
                {moment(version.submissionDateTime).format('MMM D, YYYY') + ' - ' + versionAuthor.firstName + ' ' + versionAuthor.lastName + (version.uid === versions[0].uid ? ' (latest)' : '')}
            </Dropdown.Item>
        )
    });

    return (
        <Modal.Header>
            <Modal.Title style={{fontSize: 18}}>
                {
                    ' Authored by ' + selectedVersionAuthor.firstName + ' ' + selectedVersionAuthor.lastName + ' on ' +
                    moment(selectedVersion.submissionDateTime).format('MMM D, YYYY') +
                    (selectedVersion.uid === versions[0].uid ? ' (LATEST VERSION)' : ' (HISTORIC)')
                }
            </Modal.Title>
            <Dropdown>
                <Dropdown.Toggle disabled={isLoading} style={{backgroundColor: 'white', color: 'var(--bs-primary)', borderWidth: 2, paddingTop: 0, paddingBottom: 0}}>
                    {isLoading ? <Spinner size='sm'/> : 'Change Version'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {versionElements}
                </Dropdown.Menu>
            </Dropdown>
        </Modal.Header>
    )
}