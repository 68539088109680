/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/



import React from "react";
import { getUsers, deleteUser } from "../services/HRServices/HRService";
import { Container, Row, Col, Modal} from "react-bootstrap";
import CustomControl from "../components/CustomControl";
import CustomButton from "../components/CustomButton";
import QuickTable from "../components/QuickTable";
import { Form } from "react-bootstrap";
import Cookies from "universal-cookie";


export default class UserSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            selectedUser:undefined,
            firstName:'',
            middleName:'',
            lastName:'',
            isLoading:false,
            selectedCompany:undefined,
            showCompanySelector:false,
            showDeleteModal:false,
            deleteConfirmation:''
        }
        this.isSuperAdmin = ['superDuperAdministrator', 'superAdministrator'].includes(new Cookies().get('userData').permissions)
    }

    async handleGetUsers(){
        this.setState({isLoading:true});
        const data = {
            firstName: this.state.firstName,
            middleName: this.state.middleName,
            lastName: this.state.lastName
        }
        const response = await getUsers(data);
        if(response && response.status === '200'){
            this.setState({users:response.users});
        }
        this.setState({isLoading:false});
    }

    async handleDeleteUser(){
        this.setState({isLoading:true});

        const response = await deleteUser(this.state.selectedUser.uid);
        if(response.status == '200'){
            const newArr = Array.from(this.state.users).filter((u)=>u.uid !== this.state.selectedUser.uid);
            this.setState({users:newArr, deleteConfirmation:'', showDeleteModal:false, showCompanySelector:false});
        }
        this.setState({isLoading:false});
    }


    render() {
        const { firstName, middleName, lastName, isLoading, users, selectedUser, showCompanySelector, selectedCompany, deleteConfirmation, showDeleteModal } = this.state;

        const userElements = users.map((u)=>{

            return (
                <tr key={u.uid} className='cursor-pointer' onClick={()=>this.setState({selectedUser:u, showCompanySelector:true})}>
                    <td>{u.uid}</td>
                    <td>{u.lastName}</td>
                    <td>{u.firstName}</td>
                    <td>{u.middleName}</td>
                    <td>{u.email}</td>
                </tr>
            )
        });
        const userCompanies = !selectedUser ? [] : selectedUser.companies.map((c)=>{
            const company = {
                companyIdentifier:c.companyIdentifier,
                companyName:c.companyName
            }
            const companyUser = {
                ...selectedUser,
                companyUserUid:c.companyUserUid,
                isEnabled:c.isEnabled
            }

            return (
                <tr key={c.companyIdentifier} className='cursor-pointer' onClick={()=>{this.props.handleSelectUser(company, companyUser);this.setState({showCompanySelector:false})}}>
                    <td>{c.companyIdentifier}</td>
                    <td>{c.companyUserUid}</td>
                    <td>{c.companyName}</td>
                </tr>
            )
        });

        const confirmationCode = 'delete this employee';

        const letterElements = confirmationCode.split('').map((char, index) => {
            return (
                <p key={index} style={{display: 'inline', marginBottom: 0, color: (deleteConfirmation.length >= (index + 1) && deleteConfirmation.charAt(index) === char) ? 'green' : 'red'}}>{char}</p>
            )
        });

        return(
            <>
                <Modal.Body>
                    <Container fluid>
                        <Row>
                            <Col>
                            <p style={{fontWeight:'bold', fontSize:20, padding:10}}>Enter a employee name to search: </p>
                            </Col>
                        </Row>
                        <Row>
                            <CustomControl type='text' title='First Name' value={firstName} onChange={(event)=>this.setState({firstName:event.target.value})} lg={4}/>
                            <CustomControl type='text' title='Middle Name' value={middleName} onChange={(event)=>this.setState({middleName:event.target.value})} lg={4}/>
                            <CustomControl type='text' title='Last Name' value={lastName} onChange={(event)=>this.setState({lastName:event.target.value})} lg={4}/>
                        </Row>
                        <Row style={{marginBottom:10}}>
                            <Col className="d-flex justify-content-end">
                                <CustomButton isLoading={isLoading} label='Search' onClick={()=>this.handleGetUsers()}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {users.length > 0 && <QuickTable title={'Employees'} headers={['Uid','Last Name', 'First Name', 'Middle Name', 'Email']} rows={userElements} responsive bordered hover/>}
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal show={showCompanySelector} onHide={()=>{this.setState({showCompanySelector:false})}} size='lg'>
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedUser ? selectedUser.lastName + ', ' + selectedUser.firstName + ' ' + selectedUser.middleName.substring(0,1): ''}</Modal.Title>
                    </Modal.Header>
                    {this.isSuperAdmin && 
                    <div style={{display:'flex', justifyContent:'flex-end'}}>
                        <CustomButton style={{background:'none', color:'red', boxShadow:'none', border:'none'}} label='Delete Employee' onClick={()=>this.setState({showDeleteModal:true})}/>
                    </div>
                    }
                    <Modal.Body>
                        <QuickTable title='Employee Companies' headers={['Company Identifier', 'Company User Uid', 'Company Name']} rows={userCompanies} responsive bordered hover/> 
                    </Modal.Body>
                </Modal>
                <Modal show={showDeleteModal} onHide={()=>this.setState({showDeleteModal:false, deleteConfirmation:''})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Employee?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Once deleted all data from this employee will no longer be accesible and can not be recovered. Please be sure you want to delete before continuing.</p>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 12}}>
                        <Form.Group>
                            <Form.Label>Type "{letterElements}" to confirm deletion</Form.Label>
                            <Form.Control isValid={this.state.deleteConfirmation === confirmationCode} isInvalid={!confirmationCode.includes(this.state.deleteConfirmation)} value={this.state.deleteConfirmation} onChange={(event) => {this.setState({deleteConfirmation:event.target.value})}} type='text' placeholder='delete this employee'/>
                        </Form.Group>
                        <CustomButton label='Confirm Deletion' isLoading={isLoading} disabled={this.state.deleteConfirmation !== confirmationCode} onClick={()=>this.handleDeleteUser()}/>
                    </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}