/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";

import CustomControl from "../../../components/CustomControl";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from 'react-bootstrap/Container';
import AddressControl from "../../../components/AddressControl";
import RadioControl from "../../../components/RadioControl";
import AvailabilityControl from "../../../components/AvailabilityControl";
import { Button, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";


export default function CreateCompanyAO({AOInfo, handleSetAOInfo, validators, emailIsTaken, handleCheckEmail}) {
    
    return (
        <Container fluid>
            <Row>
                <CustomControl validator={validators.firstName} lg={3} type='text' max={30} title='First Name' value={AOInfo.firstName} onChange={(event) => handleSetAOInfo('firstName', event.target.value)}/>
                <CustomControl validator={validators.middleName} lg={3} type='text' max={30} title='Middle Name' value={AOInfo.middleName} onChange={(event) => handleSetAOInfo('middleName', event.target.value)}/>
                <CustomControl validator={validators.lastName} lg={3} type='text' max={30} title='Last Name' value={AOInfo.lastName} onChange={(event) => handleSetAOInfo('lastName', event.target.value)}/>
                <CustomControl validator={validators.phoneNumber} required lg={3} max={10} type='text' title='Phone Number' value={AOInfo.phoneNumber} onChange={(event) => handleSetAOInfo('phoneNumber', event.target.value)}/>
                
                <Col lg={4}>
                    <InputGroup>
                        <CustomControl validator={validators.email} required  max={50} type='text' title='Email Address' value={AOInfo.email} onChange={(event) => handleSetAOInfo('email', event.target.value)}/>
                        { emailIsTaken === undefined ?
                            <Button onClick={handleCheckEmail}>Check Email</Button>
                            : emailIsTaken ?
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faX} style={{color: 'red'}}/>
                            </InputGroup.Text>
                            :
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/>
                            </InputGroup.Text>
                        }
                    </InputGroup>
                </Col>

                
                
                <CustomControl validator={validators.dateOfBirth} required lg={3} type='date' title='Date of Birth' value={AOInfo.dateOfBirth} onChange={(event) => handleSetAOInfo('dateOfBirth', event.target.value)}/>
                <CustomControl validator={validators.ssn} required lg={3} max={9} type='text' title='Social Security #' value={AOInfo.ssn} onChange={(event) => handleSetAOInfo('ssn', event.target.value)}/>
                <Col xxl={12}>
                    <AddressControl validator={validators.address} lg={4} title='Address' address={AOInfo.address} setAddress={(value) => {handleSetAOInfo('address', value)}}/>
                </Col>
                <CustomControl validator={validators.payRate} max={10} required lg={4} type='number' title='Pay Rate' value={AOInfo.payRate} onChange={(event) => handleSetAOInfo('payRate', event.target.value)}/>
                <CustomControl validator={validators.hourlyWage} max={10} required lg={4} type='number' title='Hourly Wage' value={AOInfo.hourlyWage} onChange={(event) => handleSetAOInfo('hourlyWage', event.target.value)}/>
                <Col lg={6} style={{marginBottom: 8}}>
                    <RadioControl validator={validators.payType} inline title='Pay Type' selection={AOInfo.payType} setSelection={(value) => {handleSetAOInfo('payType', value)}} optionNames={['Per Year', 'Per Day', 'Per Hour', 'Per Stop', 'Per Mile']} optionValues={['py', 'pd', 'ph', 'ps', 'pm']}/>
                </Col>
                <Col lg={6} style={{marginBottom: 8}}>
                    <AvailabilityControl validator={validators.availability} inline title='Expected Availability' value={AOInfo.availability} setValue={(value) => {handleSetAOInfo('availability', value)}}/>
                </Col>
            </Row>
        </Container>
    )
}