/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Dropdown, Form, Modal, Row } from 'react-bootstrap';
import { createEmployeePay, updateEmployeePay } from '../../../services/HumanResourcesService';
import { ValidationGroup, validateExistence, validateGreaterThanZero, validatePayDateEffective } from '../../../validation';
import CustomControl from '../../../components/CustomControl';
import SwitchControl from '../../../components/SwitchControl';
import Picker from '../../../components/Picker';
import TextAreaControl from '../../../components/TextAreaControl';
import moment from 'moment';
import QuickTable from '../../../components/QuickTable';
import DropdownControl from '../../../components/DropdownControl';
import ChildSupportControl from '../../../components/ChildSupportControl';
import CustomButton from '../../../components/CustomButton';
import AlertModal from '../../../components/AlertModals/AlertModal';
import { validateUsd } from '../../Payroll/payrollTools';
import { validateDecimal } from '../../../tools';

export default function HRUserPayEditor({userIdentifier, selectedPay, nonEffectivePay, effectivePay, permissionsTemplate, handlePayCrud}) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [dateEffective, setDateEffective] = useState(selectedPay?.dateEffective ?? moment().format('YYYY-MM-DD'));
	const [title, setTitle] = useState(selectedPay?.title ?? '');
	const [fulltime, setFulltime] = useState(selectedPay?.fulltime ?? true);
	const [payType, setPayType] = useState(selectedPay?.payType ?? undefined);
	const [payRate, setPayRate] = useState(selectedPay?.payRate ?? '');
	const [ptoAccrual, setPtoAccrual] = useState(selectedPay?.ptoAccrual ?? undefined);
	const [ptoAccrualType, setPtoAccrualType] = useState(selectedPay?.ptoAccrualType ?? undefined);
	const [medical, setMedical] = useState(selectedPay?.medical ?? '');
	const [dental, setDental] = useState(selectedPay?.dental ?? '');
	const [vision, setVision] = useState(selectedPay?.vision ?? '');
	const [childSupport, setChildSupport] = useState(selectedPay ? JSON.parse(selectedPay.childSupport) : []);
	const [notes, setNotes] = useState(selectedPay?.notes ?? '');

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [sendOfferLetter, setSendOfferLetter] = useState(false);

    const defaultPermissions = {}; 
    permissionsTemplate.forEach(p => defaultPermissions[p.key] = 0);

    const [permissions, setPermissions] = useState(selectedPay?.permissions ?? defaultPermissions);

    const validationGroup = new ValidationGroup();


    const handleSubmit = async () => {
        setIsSubmitting(true);
        const pay = {
            uid: selectedPay?.uid,
            userIdentifier: userIdentifier,
            dateEffective: dateEffective,
            title: title,
            fulltime: fulltime,
            payType: payType,
            payRate: validateUsd(payRate),
            ptoAccrual: validateDecimal(ptoAccrual),
            ptoAccrualType: ptoAccrualType,
            medical: validateUsd(medical),
            dental: validateUsd(dental),
            vision: validateUsd(vision),
            childSupport: JSON.stringify(childSupport.map(cs => validateUsd(cs))),
            notes: notes,
            permissions: permissions,
        }

        if (selectedPay) {
            const response = await updateEmployeePay(pay, sendOfferLetter);
            if (response.status === 200) {
                setShowConfirmation(false);
                handlePayCrud('update', pay);
            }
        } else {
            const response = await createEmployeePay(pay, sendOfferLetter);
            if (response.status === 200) {
                setShowConfirmation(false);
                pay.uid = response.uid;
                handlePayCrud('create', pay);
            }
        }
        setIsSubmitting(false);
    }

    const handlePrefill = (pay) => {
        setTitle(pay.title);
        setFulltime(pay.fulltime);
        setPayType(pay.payType);
        setPayRate(pay.payRate);
        setPtoAccrual(pay.ptoAccrual);
        setPtoAccrualType(pay.ptoAccrualType);
        setMedical(pay.medical);
        setDental(pay.dental);
        setVision(pay.vision);
        setChildSupport(JSON.parse(pay.childSupport));
        setNotes(pay.notes);
        setPermissions(pay.permissions);
    }

    const handlePermissionChange = (permissionKey) => {
        const newPermissions = structuredClone(permissions);
        newPermissions[permissionKey] = newPermissions[permissionKey] ? 0 : 1;
        setPermissions(newPermissions);
    }

    function handleSetHireEmployeePermissionsTemplate(template){
        const newPermissions = structuredClone(permissions)
        permissionsTemplate.forEach(p => newPermissions[p.key] = parseInt(p[template]));
        setPermissions(newPermissions);
    }

    const permissionRows = permissionsTemplate.map((p) => {
        return (
            <tr key={p.key}>
                <td>{p.label}</td>
                <td>{p.description}</td>
                <td>
                    <Form.Check type='switch' checked={permissions[p.key] ?? false} onChange={()=> handlePermissionChange(p.key)}/>
                </td>
            </tr>
        )
    })
    

    const payDropdownItems = nonEffectivePay.filter(p=>p.payType !== -1).map((p) => {
        return (
            <Dropdown.Item key={p.uid} onClick={() => {handlePrefill(p)}}>
                {`${moment(p.dateEffective).format('MMM D, YYYY')} - ${p.title}`}
            </Dropdown.Item>
        )
    })

    const otherPay = (effectivePay ? [effectivePay].concat(nonEffectivePay) : nonEffectivePay).filter(p => p.uid !== selectedPay?.uid);

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{selectedPay ? 'Editing / Viewing Employee Pay' : 'Creating Employee Pay'}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                { !selectedPay && 
                    <Dropdown style={{alignSelf: 'flex-end'}}>
                        <Dropdown.Toggle disabled={!effectivePay && !nonEffectivePay.length} variant='outline-primary'>Prefill With Existing Pay</Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Header>Effective Pay</Dropdown.Header>
                            { effectivePay && effectivePay.payType !== -1 &&
                                <Dropdown.Item onClick={() => {handlePrefill(effectivePay)}}>
                                    {`${moment(effectivePay.dateEffective).format('MMM D, YYYY')} - ${effectivePay.title}`}
                                </Dropdown.Item>
                            }
                            <Dropdown.Divider/>
                            <Dropdown.Header>Other Pay</Dropdown.Header>
                            {payDropdownItems}
                        </Dropdown.Menu>
                    </Dropdown>
                }
                <Container fluid style={{padding: 0}}>
                    <Row>
                        <Col lg={6} xl={4} style={{marginBottom: 8}}>
                            <CustomControl floatingLabel title='Effective Date' type='date' value={dateEffective} setValue={setDateEffective} validator={validationGroup.createValidator(() => validatePayDateEffective(otherPay, dateEffective))}/>
                        </Col>
                        <Col lg={6} xl={4} style={{marginBottom: 8}}>
                            <CustomControl floatingLabel title='Title' value={title} setValue={setTitle} onChange={(event) => {setTitle(event.target.value)}} validator={validationGroup.createValidator(() => validateExistence(dateEffective))}/>
                        </Col>
                        <Col lg={6} xl={4} style={{marginBottom: 8}}>
                            <Picker title='Employee Type' selection={fulltime} setSelection={setFulltime} itemValues={[1, 0]} itemNames={['Full-Time', 'Part-Time']}/>
                        </Col>
                        <Col lg={6} xl={4} style={{marginBottom: 8}}>
                            <Picker validator={validationGroup.createValidator(() => validateExistence(payType))} title='Pay Type' selection={payType} setSelection={setPayType} itemValues={[0, 1, 2]} itemNames={['Per Hour', 'Salaried (Non-Exempt)', 'Salaried (Exempt)']}/>
                        </Col>
                        <Col lg={6} xl={4} style={{marginBottom: 8}}>
                            <CustomControl floatingLabel validator={validationGroup.createValidator(() => validateGreaterThanZero(payRate))} title='Pay Rate' value={payRate} setValue={setPayRate} onChange={(event) => {setPayRate(event.target.value)}}/>
                        </Col>
                        <Col lg={6} xl={4} style={{marginBottom: 8}}>
                            <Picker title='PTO Accrual Type' selection={ptoAccrualType} setSelection={setPtoAccrualType} itemValues={[0, 1]} itemNames={['Per 40 Hours', 'Per Pay Period']}/>
                        </Col>
                        <Col lg={6} xl={4} style={{marginBottom: 8}}>
                            <CustomControl floatingLabel title={`PTO Accrual ${ptoAccrualType == 0 ? 'Per 40 Hours' : 'Per Pay Period'}`} value={ptoAccrual} setValue={setPtoAccrual} onChange={(event) => {setPtoAccrual(event.target.value)}}/>
                        </Col>
                        <Col lg={6} xl={4} style={{marginBottom: 8}}>
                            <CustomControl floatingLabel title='Medical Insurance' value={medical} setValue={setMedical} onChange={(event) => {setMedical(event.target.value)}} type='number'/>
                        </Col>
                        <Col lg={6} xl={4} style={{marginBottom: 8}}>
                            <CustomControl floatingLabel title='Dental Insurance' value={dental} setValue={setDental} onChange={(event) => {setDental(event.target.value)}} type='number'/>
                        </Col>
                        <Col lg={6} xl={4} style={{marginBottom: 8}}>
                            <CustomControl floatingLabel title='Vision Insurance' value={vision} setValue={setVision} onChange={(event) => {setVision(event.target.value)}} type='number'/>
                        </Col>
                    </Row>
                </Container>

                <ChildSupportControl value={childSupport} setValue={setChildSupport}/>

                <TextAreaControl label='Notes' rows={4} value={notes} setValue={setNotes} max={300} validator={validationGroup.createValidator(() => validateExistence(notes))}/>
                
                <div style={{display: 'flex', alignItems: 'center', padding: 12, border: '1px solid lightgray', marginBottom: 8, gap: 18}}>
                    <p style={{fontSize: 16, margin: 0}}>Default Permission Templates</p>
                    <Button onClick={()=>handleSetHireEmployeePermissionsTemplate('associate')} style={{width: 120}} variant='outline-primary'>Associate</Button>
                    <Button onClick={()=>handleSetHireEmployeePermissionsTemplate('manager')}  style={{width: 120}} variant='outline-primary'>Manager</Button>
                    <Button onClick={()=>handleSetHireEmployeePermissionsTemplate('owner')} style={{width: 120}} variant='outline-primary'>Owner</Button>
                </div>
                <QuickTable size='sm' title='Permissions' rows={permissionRows} headers={['Permission Title', 'Description', 'On / Off']} widths={[null, null, 1]} noWrap responsive={false}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='outline-primary' disabled={!validationGroup.isValid()} onClick={() => {setShowConfirmation(true)}}>
                    {selectedPay ? 'Save Changes' : 'Submit'}
                </Button>
            </Modal.Footer>
            <Modal show={showConfirmation} onHide={() => {setShowConfirmation(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Send New Hire Paperwork?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{`If selected, New Hire Paperwork will be generated with the ${selectedPay ? 'corrected information' : 'information entered'} and sent to the employee to sign.`}</p>
                    <SwitchControl title={'Send New Hire Paperwork'} value={sendOfferLetter} setValue={setSendOfferLetter}/>
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton label='Submit' isLoading={isSubmitting} onClick={handleSubmit}/>
                </Modal.Footer>
            </Modal>
        </>
    )
}