/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, ListGroup, Modal } from "react-bootstrap";
import moment from "moment";
import PayrollEntry from "../Models/PayrollEntry";
import { getPayrollVersion as getPayrollVersionAdmin, sendBackPayrollPeriod } from "../../../services/PayrollServiceAdmin";
import { getPayrollVersion as getPayrollVersionClient } from "../../../services/PayrollServiceClient";
import { adminMode } from "../payrollTools";
import CustomButton from "../../../components/CustomButton";

export default function PayrollVersionList({
    payrollVersions, 
    bcVersions, 
    bcData,
    csas, 
    loadedVersion,
    handleSetEntries,
    payrollPeriod,
    handleHideEditor,
    isSendingBack,
}) {

    const [selectedVersion, setSelectionVersion] = useState(undefined);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showClearModal, setShowClearModal] = useState(false);

    const handleChangePayrollVersion = async () => {
        setIsSubmitting(true);
        let response;
        if (adminMode) {
            response = await getPayrollVersionAdmin(selectedVersion.uid, selectedVersion.type, payrollPeriod.companyIdentifier, payrollPeriod.uid);
        } else {
            response = await getPayrollVersionClient(selectedVersion.uid, selectedVersion.type, payrollPeriod.uid);
        }

        if (response.status === '200') {
            const hydrationData = {
                ptoData: response.pto, 
                ptoBalanceData: response.ptoBalanceData, 
                loanData: response.loanData, 
                ticketData: response.ticketData
            }
            const entries = PayrollEntry.decodeArray(response.entries, payrollPeriod.periodStart, payrollPeriod.periodEnd, hydrationData);
            handleSetEntries(entries, true);
        }
        setIsSubmitting(false);
    }

    const handleSendBackPayroll = async () => {
        setIsSubmitting(true);

        const type = selectedVersion.type === 'ttaSubmission' ? 'approvedEntries' : selectedVersion.type === 'aoSubmission' ? 'entries' : selectedVersion.type;

        const response = await sendBackPayrollPeriod(payrollPeriod.uid, {type: type, uid: selectedVersion.uid});
        if (response.status === '200') {
            handleHideEditor();
        }
        setIsSubmitting(false);
    }


    const ttaVersionList = payrollVersions.filter((v)=> v.isTta == '1').map((version)=>{
        return (
            <ListGroup.Item action key={version.uid} active={selectedVersion?.uid === version.uid && selectedVersion?.type === 'version'} onClick={()=>{setSelectionVersion({uid: version.uid, type: 'version'})}} style={{display:'flex', justifyContent:'space-between'}}>
                <p style={{marginBottom: 0, marginRight: 12}}>{version.user ? version.user.lastName + ', ' + version.user.firstName + ' ' + (version.user?.middleName ?? '').substring(0,1) : ''}</p>
                <p style={{marginBottom: 0, opacity: 0.5}}>{moment(version.dateTime).format('MMM D, YYYY [at] h:mm:ss A')}</p>
            </ListGroup.Item>
        )
    })

    const aoVersionList = payrollVersions.filter((v)=> v.isTta == '0').map((version)=>{
        return (
            <ListGroup.Item action key={version.uid} active={selectedVersion?.uid === version.uid && selectedVersion?.type === 'version'} onClick={()=>{setSelectionVersion({uid: version.uid, type: 'version'})}} style={{display:'flex', justifyContent:'space-between'}}>
                { parseInt(version.companyUserIdentifier) > 0 ? 
                    <p style={{marginBottom: 0, marginRight: 12}}>{version.user ? version.user.lastName + ', ' + version.user.firstName + ' ' + (version.user?.middleName ?? '').substring(0,1) : ''}</p>
                    :
                    <p style={{marginBottom: 0, marginRight: 12}}>TTA Version Sent Back</p>
                }
                <p style={{marginBottom: 0, opacity: 0.5}}>{moment(version.dateTime).format('MMM D, YYYY [at] h:mm:ss A')}</p>
            </ListGroup.Item>
        )
    })

    const bcVersionList = bcVersions.map((version)=>{
        return (
            <ListGroup.Item action key={version.uid} active={selectedVersion?.uid === version.uid && selectedVersion?.type === 'bcVersion'} onClick={()=>{setSelectionVersion({uid: version.uid, type: 'bcVersion'})}} style={{display:'flex', justifyContent:'space-between'}}>
                <p style={{marginBottom: 0, marginRight: 12}}>{version.user ? version.user.lastName + ', ' + version.user.firstName + ' ' + (version.user?.middleName ?? '').substring(0,1) : ''}</p>
                <p style={{marginBottom: 0, opacity: 0.5}}>{moment(version.dateTime).format('MMM D, YYYY [at] h:mm:ss A')}</p>
            </ListGroup.Item>
        )
    })

    const bcDataList = bcData.map((version)=>{
        const csa = csas.find((c)=>c.uid === version.csaIdentifier);
        return (
            <ListGroup.Item action key={version.uid} active={selectedVersion?.uid === version.uid && selectedVersion?.type === 'bcSubmission'} onClick={()=>{setSelectionVersion({uid: version.uid, type: 'bcSubmission'})}} style={{display:'flex', justifyContent:'space-between'}}>
                <p style={{marginBottom: 0, marginRight: 12}}>{csa ? csa.csaName : 'BC Submission'}</p>
            </ListGroup.Item>
        )
    })

    let matchedVersion;

    if (loadedVersion) {
        if (loadedVersion.type === 'version') {
            matchedVersion = payrollVersions.find(v => v.uid == loadedVersion.uid);
        } else if (loadedVersion.type === 'bcVersion') {
            matchedVersion = bcVersions.find(v => v.uid == loadedVersion.uid);
        }
    }
    
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{isSendingBack ? 'Send Payroll Version Back To IC?' : 'Revert To Previous Version?'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loadedVersion ? 
                    <div style={{display:'flex', width:'100%', flexDirection:'column', paddingTop:10, paddingBottom:10}}>
                        <p style={{textDecoration:'underline'}}>{'Version Preloaded into Editor'}</p>
                        { loadedVersion.type === 'ttaSubmission' ?
                            <span>TTA Submission</span>
                        : loadedVersion.type === 'aoSubmission' ?
                            <span>AO Submission</span>
                        : loadedVersion.type === 'bcSubmission' ?
                            <span>BC Submission</span>
                        : loadedVersion.type === 'version' ?
                            <div style={{display:'flex', justifyContent:'space-between'}}>
                                { parseInt(matchedVersion.companyUserIdentifier) > 0 ? 
                                    <span style={{marginRight: 12}}>
                                        {matchedVersion.user?.lastName + ', ' + matchedVersion.user?.firstName + ' ' + (matchedVersion.user?.middleName ?? '').substring(0,1)}
                                    </span>                                    
                                    :
                                    <span style={{marginRight: 12}}>TTA Version Sent Back</span>
                                }
                                <span style={{opacity: 0.5}}>
                                    {moment(matchedVersion.dateTime).format('MMM D, YYYY [at] h:mm:ss A')}
                                </span>
                            </div>
                        : loadedVersion.type === 'bcVersion' ?
                            <div style={{display:'flex', justifyContent:'space-between'}}>
                                <span style={{marginRight: 12}}>
                                    {matchedVersion.user?.lastName + ', ' + matchedVersion.user?.firstName + ' ' + (matchedVersion.user?.middleName ?? '').substring(0,1)}
                                </span>
                                <span style={{opacity: 0.5}}>
                                    {moment(matchedVersion.dateTime).format('MMM D, YYYY [at] h:mm:ss A')}
                                </span>
                            </div>
                        :
                            <span>ERR</span>
                        }
                    </div>
                    :
                    <span style={{fontStyle: 'italic', opacity: 0.5}}>The initial information in the payroll entries was generated from the data in our system.</span>
                }

                { payrollPeriod.approvedEntries &&
                    <div style={{marginTop: 10}}>
                        <p style={{textDecoration:'underline'}}>TTA Submission</p>
                        <ListGroup>
                            <ListGroup.Item action active={selectedVersion?.uid === payrollPeriod.uid && selectedVersion?.type === 'ttaSubmission'} onClick={() => {setSelectionVersion({uid: payrollPeriod.uid, type: 'ttaSubmission'})}} style={{display:'flex', justifyContent:'space-between'}}>
                                <p style={{marginBottom: 0, marginRight: 12}}>TTA Submission</p>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                }
                {ttaVersionList.length > 0 &&
                    <div style={{marginTop: 10}}>
                        <p style={{textDecoration:'underline'}}>TTA Saves</p>
                        <ListGroup>
                            {ttaVersionList}
                        </ListGroup>
                    </div>
                }
                {payrollPeriod.entries &&
                    <div style={{marginTop: 10}}>
                        <p style={{textDecoration:'underline'}}>AO Submission</p>
                        <ListGroup>
                            <ListGroup.Item action active={selectedVersion?.uid === payrollPeriod.uid && selectedVersion?.type === 'aoSubmission'} onClick={() => {setSelectionVersion({uid: payrollPeriod.uid, type: 'aoSubmission'})}} style={{display:'flex', justifyContent:'space-between'}}>
                                <p style={{marginBottom: 0, marginRight: 12}}>AO Submission</p>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                }
                {aoVersionList.length > 0 &&
                    <div style={{marginTop: 10}}>
                        <p style={{textDecoration:'underline'}}>AO Saves</p>
                        <ListGroup>
                            {aoVersionList}
                        </ListGroup>
                    </div>
                }
                {!isSendingBack && bcDataList.length > 0 &&
                    <div style={{marginTop: 10}}>
                        <p style={{textDecoration:'underline'}}>BC Submissions</p>
                        <ListGroup style={{marginTop: 10}}>
                            {bcDataList}
                        </ListGroup>
                    </div>
                }
                {!isSendingBack && bcVersionList.length > 0 &&
                    <div style={{marginTop: 10}}>
                        <p style={{textDecoration:'underline'}}>BC Saves</p>
                        <ListGroup style={{marginTop: 10}}>
                            {bcVersionList}
                        </ListGroup>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                { !isSendingBack &&
                    <>
                        <FontAwesomeIcon icon={faInfoCircle} style={{color:'var(--bs-primary)'}}/>
                        <span>You will lose any unsaved changes when you select a version</span>
                    </>
                }
                { isSendingBack &&
                    <Button style={{border:'none', boxShadow:'none', color:'red', background:'none'}} onClick={()=>{setSelectionVersion({uid: payrollPeriod.uid, type: 'clearAll'}); setShowClearModal(true)}}>Send Back Cleared Payroll?</Button>
                }
                <CustomButton label={isSendingBack ? 'Submit' : 'Import Version'} disabled={!selectedVersion} onClick={isSendingBack ? handleSendBackPayroll : handleChangePayrollVersion} isLoading={isSubmitting}/>
            </Modal.Footer>
            <Modal show={showClearModal} onHide={()=>setShowClearModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure you want to send back a cleared pay period?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Sending a cleared pay period back to the IC will delete all saved IC and TTA entries</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleSendBackPayroll}>Submit</Button>
                </Modal.Footer>
             </Modal>
        </>
    )
}