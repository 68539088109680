/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import CompanyVehicleModal from "./CompanyVehicleModal";
import { getCompanyVehicles } from "../../../services/CompanyServices/CompanyVehiclesService";
import PageSpinner from "../../../components/PageSpinner";
import QuickTable from "../../../components/QuickTable";

export default function CompanyVehicles({selectedCompany}) {
    const [isLoading, setIsLoading] = useState(false);
    const [showInactiveItems, setShowInactiveItems] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [csas, setCsas] = useState([]);
    const [vehicles, setVehicles] = useState([]);

    const sortByUid = (a, b) => { return a.uid - b.uid };

    const loadData = async () => {
        setIsLoading(true);
        const response = await getCompanyVehicles(selectedCompany.uid);
        if (response.status === '200') {
            setVehicles(response.vehicles.sort(sortByUid));
            setCsas(response.csas);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadData();
    }, [])

    const handleCrud = (type, vehicle) => {
        let newArray = Array.from(vehicles);
        if (type !== 'create') {
            newArray = newArray.filter(r => r.uid !== vehicle.uid);
        }
        if (type !== 'delete') {
            newArray.push(vehicle);
        }
        newArray.sort(sortByUid);
        setVehicles(newArray);
        setShowModal(false);
    }

    const vehicleRows = vehicles.filter(vehicle => showInactiveItems || vehicle.isActive === '1').map((vehicle) => {

        const csa = csas.find(r => r.uid === vehicle.csaIdentifier);

        return (
            <tr key={vehicle.uid} style={vehicle.isActive === '1' ? {} : {opacity: 0.5}} className='cursor-pointer' onClick={() => {setSelectedItem(vehicle); setShowModal(true);}}>
                <td>{vehicle.uid}</td>
                <td>{vehicle.vehicleName + (vehicle.isActive === '1' ? '' : ' (inactive)')}</td>
                <td>{vehicle.vehicleWeight}</td>
                <td>{csa.csaName}</td>
            </tr>
        )
    });

    const content = (
        <div>
            <div style={{display: 'flex', paddingLeft: 24, paddingRight: 24, marginBottom: 12, justifyContent: 'space-between', alignItems: 'center'}}>
                <Form.Check style={{flexShrink: 0}} type='switch' label='Show Inactive Vehicles' checked={showInactiveItems} onChange={() => {setShowInactiveItems(!showInactiveItems)}}/>
                <Button style={{color: 'white'}} onClick={() => {setSelectedItem(undefined); setShowModal(true);}}>
                    Add Vehicle
                </Button>
            </div>

            <QuickTable headers={['UID', 'Name', 'Weight', 'CSA']} rows={vehicleRows} hover/>

            <Modal show={showModal} onHide={() => {setShowModal(false)}} centered size='lg'>
                <CompanyVehicleModal data={selectedItem} handleCrud={handleCrud} csas={csas} selectedCompany={selectedCompany}/>
            </Modal>
        </div>
    )

    return (
        <>
            {isLoading ? <PageSpinner/> : content}
        </>
    )
}