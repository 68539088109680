/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { Button, Card} from "react-bootstrap";
import DropdownControl from "../../components/DropdownControl";
import { useEffect, useMemo, useState } from "react";
import { getCompanyEmployees } from "../../services/AdminToolsService";
import { AppGlobals } from "../../App";
import CustomButton from "../../components/CustomButton";

export default function CompanyEmployeesList({companies}) {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);

    async function handleGetEmployeeList() {
        setIsLoading(true);
        const response = await getCompanyEmployees(selectedCompany);
        if (response.status == 200) {

            response.employees.sort(sortEmployees);
            const companyName = companies.find((company) => company.uid == selectedCompany)?.companyName ?? 'ERR';
            const csvString = generateEmployeeListCsv(response.employees, companyName);
            downloadCsv(csvString, companyName);

        } else {
            AppGlobals.alert('danger', response.message);
        }
        setIsLoading(false);
    }

    function sortEmployees(a, b) {
        const nameA = `${a.lastName.toUpperCase()}${a.firstName.toUpperCase()}`;
        const nameB = `${b.lastName.toUpperCase()}${b.firstName.toUpperCase()}`;
        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    }

    function generateEmployeeListCsv(employees, companyName) {
        const rows = [];
        rows.push([ "\"" + companyName + "\""]);
        rows.push([]);
        rows.push(['Last Name', 'First Name', 'Title', 'Phone Number', 'Email'])
        rows.push([]);

        employees.forEach((employee) => {
            rows.push([employee.lastName, employee.firstName, employee?.pay?.title ?? 'Unknown', employee.phoneNumber, employee.email])
        })
        
        const stringRows = rows.map(r => r.join(','));
        const fullString = stringRows.join('\r');
        return fullString;
    }

    const downloadCsv = (str, companyName) => {
        const blob = new Blob([str], { type: 'text/csv;charset=utf-8,' });
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = companyName + ' ' + 'Employee List';
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }

    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title>Get List of Employees by Company</Card.Title>
                </Card.Header>
                <Card.Body>
                    <DropdownControl title='Select Company' selection={selectedCompany} setSelection={setSelectedCompany} itemNames={companies.map(c=>c.companyName)} itemValues={companies.map(c=>c.uid)}/>
                </Card.Body>
                <Card.Footer style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <CustomButton label={'Download Company List'} onClick={handleGetEmployeeList} disabled={!selectedCompany} isLoading={isLoading}/>
                </Card.Footer>
            </Card>
        </>
    )
}