/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import QuickTable from "../../../../../components/QuickTable";
import { usdFormatter } from "../../../../../tools";
import HRUserLoan from "../../../../HumanResources/HRUserLoan/HRUserLoan";
import Ticket from "../../../Models/Ticket";
import { getEmployeePayrollLoans } from "../../../../../services/HRServices/HRLoanService";
import Loan from "../../../Models/Loan";
import PayrollEmployeeLoansTooltip from './PayrollEmployeeLoansTooltip';
import CustomButton from "../../../../../components/CustomButton";

export default function PayrollEmployeeLoansEditor({entry, handleSet, selectedPayPeriod}) {
    const [showModal, setShowModal] = useState(false);
    const [showError, setShowError] = useState(false);

    const handleRecalculateEntry = async () => {
        const response = await getEmployeePayrollLoans(entry.companyUserIdentifier, selectedPayPeriod);
        if (response.status === '200') {
            const newLoans = response.loanData.map((loan) => {
                loan.isEnabled = entry.loans.find(l => l.uid == loan.uid)?.isEnabled ?? true;
                return Loan.decode(loan);
            });
            const newTickets = response.ticketData.map((ticket) => {
                ticket.isEnabled = entry.tickets.find(l => l.uid == ticket.uid)?.isEnabled ?? true;
                return Ticket.decode(ticket);
            });
            handleSet(entry.companyUserIdentifier, 'loans', newLoans);
            handleSet(entry.companyUserIdentifier, 'tickets', newTickets);
        } else {
            setShowError(true);
        }
    }

    const handleSetLoanEnabled = (uid) => {
        const newArray = Array.from(entry.loans);
        const loan = entry.loans.find(l => l.uid == uid);
        if (loan) {
            loan.isEnabled = !loan.isEnabled;
            handleSet(entry.companyUserIdentifier, 'loans', newArray);
        }
    }

    const loanRows = entry.loans.map((l) => {
        return (
            <tr key={l.uid} style={{opacity: l.isEnabled ? 1.0 : 0.5}}>
                <td>{l.name}</td>
                <td>{usdFormatter.format(l.amount)}</td>
                <td>
                    <Button onClick={() => {handleSetLoanEnabled(l.uid)}} style={{padding: '0px 8px 0px 8px', color: 'white', border: 'none', backgroundColor: l.isEnabled ? 'limegreen' : 'gray'}}>{l.isEnabled ? 'Yes' : 'No'}</Button>
                </td>
            </tr>
        )
    });

    return (
        <Card>
            <Card.Body>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 12}}>
                    <div style={{display: 'flex', alignItems: 'center', gap: 12}}>
                        <Card.Title style={{margin: 0}}>Loans</Card.Title>
                        <PayrollEmployeeLoansTooltip/>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', gap: 12}}>
                        <Button variant="outline-primary" onClick={() => {setShowModal(true)}}>View/Edit Loans & FTD</Button>
                    </div>
                </div>
                <div style={{display:'flex'}}>
                    { entry.loans.length > 0 &&
                        <QuickTable title={'Loans'} headers={['Name', 'Installment', 'Include In Payroll?']} rows={loanRows} size={'sm'}/>
                    }
                </div>
            </Card.Body>
            <Modal show={showModal} onHide={() => {setShowModal(false)}} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>{'Loans & Fine Ticket Damage for ' + entry.name()}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HRUserLoan user={{companyUserUid: entry.companyUserIdentifier}} handleRecalculateEntry={handleRecalculateEntry}/>
                </Modal.Body>
            </Modal>
            <Modal show={showError} onHide={() => {setShowError(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title style={{color: 'red'}}>Failed to update employee's payroll entry</Modal.Title>
                </Modal.Header>
            </Modal>
        </Card>
    )
}
