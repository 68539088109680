/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ButtonGroup } from "react-bootstrap";
import AddNDBonusForm from "./AddNDBonusForm";
import AddAdditionalPayForm from "./AddAdditionalPayForm";
import AddHolidayForm from "./AddHolidayForm";
import CustomTabs from "../../../../../../components/CustomTabs";
import AddDBonusForm from "./AddDBonusForm";

export default function PayModal({handleAddNonDiscretionaryBonus, handleAddDiscretionaryBonus, handleAddAdditionalPay, handleAddHoliday, qualifiesForPtoAndHolidays}) {
    const [activeTab, setActiveTab] = useState(0);
   
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Adding Pay</Modal.Title>
            </Modal.Header>
            <Modal.Header style={{alignItems: 'normal', flexDirection: 'column'}}>
                <CustomTabs value={activeTab} setValue={setActiveTab} tabs={['Nondiscretionary Bonus', 'Discretionary Bonus', 'Additional Pay'].concat(qualifiesForPtoAndHolidays ? ['Holiday'] : [])} flex/>
            </Modal.Header>
            { activeTab === 0 ?
                    <AddNDBonusForm handleAddNonDiscretionaryBonus={handleAddNonDiscretionaryBonus}/>
                : activeTab === 1 ?
                    <AddDBonusForm handleAddDiscretionaryBonus={handleAddDiscretionaryBonus}/>
                : activeTab === 2 ?
                    <AddAdditionalPayForm handleAddAdditionalPay={handleAddAdditionalPay}/>
                : activeTab === 3 ? 
                    <AddHolidayForm handleAddHoliday={handleAddHoliday} qualifiesForPtoAndHolidays={qualifiesForPtoAndHolidays}/>
                : ''
            }
        </>
    )
}