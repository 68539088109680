/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useEffect } from "react";
import { createLinehaulToken } from "../services/PayrollServiceLh";
import Cookies from "universal-cookie";
import { DEV_MODE } from "../services/serviceTools";
import { Button } from "react-bootstrap";

export default function LHHumanResources() {


    useEffect(() => {
        handleCreateLinehaulToken();
    }, [])


    async function handleCreateLinehaulToken() {
        const response = await createLinehaulToken();
        if (response.status == '200') {
            const elem = window.document.createElement('a');
            elem.href = DEV_MODE ? 
                `http://localhost:3000/authenticate?authToken=${response.token}&destination=human-resources`
            : 
                `https://mytacticaltrucking.com/authenticate?authToken=${response.token}&destination=human-resources`
            ;
            elem.target = '_blank';
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
        }
    }

    return (
        <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Button onClick={handleCreateLinehaulToken}>Go to Linehaul HR</Button>
        </div>
    )
}