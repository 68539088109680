/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import './MFA.css';
import { Form, Modal } from 'react-bootstrap';
import { useRef, useState } from 'react';
import React from 'react';
import { authenticateMFA } from '../../services/AuthenticationService';
import Cookies from 'universal-cookie';
import Button from 'react-bootstrap/Button';
import CustomControl from '../../components/CustomControl';
import CustomButton from '../../components/CustomButton';

function MFA({tokenIdentifier}) {
    const ref = useRef(null);
    const [mfa, setMfa] = useState('');
    const [error, setError] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (value) => {
        while (value.length > 6 || (value.length > 0 && !'1234567890'.includes(value[value.length - 1]))) {
            value = value.slice(0, value.length - 1);
        }
        setMfa(value)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setError(undefined)
        if (mfa.length === 6) {
            setIsLoading(true)
            authenticateMFA(tokenIdentifier, mfa).then((response) => {
                if (response.status === '202') {
                    new Cookies().set('adminToken', response.token);
                    window.location.reload(false);   
                } else if (response.status === '401') {
                    setError('Incorrect code')
                }
                setIsLoading(false)
            });
        }
    }

    return(
        <>
            <Modal.Header>
                <Modal.Title>Multi-Factor Authentication</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p style={{alignSelf: 'center'}}>Please enter the code that has been sent to your mobile device.</p>
                <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                    <div style={{display: 'flex', flexDirection: 'row', maxWidth: 800, gap: 4}}>
                        <Form.Control autoFocus style={{fontSize: '15vmin', textAlign: 'center'}} type='number' maxLength={6} value={mfa} onChange={(event) => {handleChange(event.target.value)}}/>
                    </div>
                    <CustomButton disabled={mfa.length < 6} label='Submit' isLoading={isLoading} onClick={handleSubmit}/>
                    { error &&
                        <span style={{color: 'red', alignSelf: 'center'}}>{error}</span>
                    }
                </form>
            </Modal.Body>
        </>
    )
}

export default MFA;