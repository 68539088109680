/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState, useEffect} from 'react';
import RichTextEditor from '../../components/RichText/RichTextEditor';
import { EditorState } from 'draft-js';
import RichText from '../../components/RichText/RichText';
import { getNotification, deleteNotification, disableNotification } from '../../services/NotificationService';
import CustomButton from '../../components/CustomButton';
import { Modal, Alert } from 'react-bootstrap';
import moment from 'moment';
import PageSpinner from '../../components/PageSpinner';
import NotificationEditor from './NotificationEditor';
import AlertModal from '../../components/AlertModals/AlertModal';
import SendNotificationModal from './SendNotificationModal';
import AcknowledgementModal from './AcknowledgementModal';

export default function NotificationModal({selectedNotification, hideModal, handleAddNotification, handleUpdateNotification, handleRemoveNotification}) {
    const [isLoading, setIsLoading] = useState(false);
    const [notification, setNotification] = useState(undefined);
    const [modalSwitch, setModalSwitch] = useState(undefined);

    const loadData = async () => {
        setIsLoading(true);
        const response = await getNotification(selectedNotification);
        if (response.status === '200') {

            let isActive = false;

            if (response.notification.effectiveDate) {
                if (moment().isSameOrAfter(moment(response.notification.effectiveDate), 'minute')) {
                    if (response.notification.expirationDate) {
                        isActive = moment().isBefore(moment(response.notification.expirationDate), 'minute');
                    } else {
                        isActive = true;
                    }
                }
            }

            setNotification({
                uid: selectedNotification,
                name: response.notification.name,
                effectiveDate: response.notification.effectiveDate,
                expirationDate: response.notification.expirationDate,
                titles: response.notification.recipients ? response.notification.recipients.split(',') : [],
                editorState: RichText.decode(response.notification.richText),
                isActive: isActive,
                acknowledgements: response.notification.acknowledgements,
                numberOfRecipients: response.notification.numberOfRecipients
            })
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadData();
    },[])

   

    const handleDeleteNotification = async () => {
        const response = await deleteNotification(selectedNotification);
        if (response.status === '200') {
            handleRemoveNotification(selectedNotification);
            return true;
        } else {
            return false;
        }
    }

    const handleDisableNotification = async () => {
        const response = await disableNotification(selectedNotification);
        if (response.status === '200') {
            const newNotification = {
                ...notification,
                effectiveDate: undefined,
                isActive: false,
            }
            handleUpdateNotification(newNotification);
            setNotification(newNotification);
            return true;
        } else {
            return false;
        }
    }

    return (
        <>
            <Modal.Header closeButton style={{gap: 12}}>
                <Modal.Title>{notification?.name ?? "Notification"}</Modal.Title>
                {notification?.isActive &&
                    <Alert variant='success' style={{margin: 0, padding: 8, fontWeight: 'bold'}}>ACTIVE</Alert>
                }
                { (notification?.isActive || notification?.acknowledgements.length > 0) &&
                    <div style={{display: 'flex', gap: 12, alignItems: 'center'}}>
                        <span style={{fontSize: 20, fontWeight: 'bold', opacity: 0.5}}>Acknowledgements:</span>
                        <span style={{fontSize: 20, fontWeight: 'bold'}}>{`${notification.acknowledgements.length} / ${notification.numberOfRecipients}`}</span>
                        <span style={{fontSize: 20, fontWeight: 'bold', color: notification.acknowledgements.length >= notification.numberOfRecipients ? 'green' : 'gold'}}>{`(${((notification.acknowledgements.length / notification.numberOfRecipients) * 100).toFixed(2)}%)`}</span>
                        <CustomButton label='View Acknowledgements' onClick={() => {setModalSwitch('acknowledgements')}}/>
                    </div>
                }
            </Modal.Header>
            <Modal.Body>
                {isLoading ? <PageSpinner/> :
                    <RichTextEditor readOnly={true} editorState={notification?.editorState ?? EditorState.createEmpty()}/>
                }
            </Modal.Body>
            <Modal.Footer>
                <CustomButton disabled={isLoading} label='Edit' onClick={()=>{setModalSwitch('edit')}}/>
                <CustomButton disabled={isLoading} label='Duplicate' onClick={()=>{setModalSwitch('editDuplicate')}}/>
                <CustomButton disabled={isLoading} label={notification?.isActive ? 'Resend' : 'Send'} onClick={()=>{setModalSwitch('send')}}/>
                { notification?.isActive &&
                    <CustomButton disabled={isLoading} label={'Disable'} onClick={()=>{setModalSwitch('disable')}}/>
                }
                <CustomButton disabled={isLoading} style={{background: 'none', color: 'red', border: 'none', boxShadow: 'none'}} label='Delete Notification' onClick={()=>{setModalSwitch('delete')}}/>
            </Modal.Footer>
            <Modal show={modalSwitch === 'edit' || modalSwitch === 'editDuplicate'} onHide={()=>{setModalSwitch(undefined)}} size='xl'>
                <NotificationEditor 
                    notification={{
                        uid: modalSwitch === 'editDuplicate' ? undefined : selectedNotification,
                        name: notification?.name + (modalSwitch === 'editDuplicate' ? ' (duplicate)' : ''),
                        editorState: notification?.editorState
                    }}
                    hideModal={()=>{
                        if (modalSwitch === 'editDuplicate') {
                            hideModal();
                        }
                        setModalSwitch(undefined);
                    }} 
                    handleAddNotification={handleAddNotification}  
                    handleUpdateNotification={(n) => {
                        const newNotification = {
                            ...notification,
                            editorState: RichText.decode(n.richText),
                            name: n.name
                        }
                        handleUpdateNotification(newNotification);
                        setNotification(newNotification);
                    }}
                />
            </Modal>
            <Modal show={modalSwitch === 'send'} onHide={() => {setModalSwitch(undefined)}} size='lg'>
                <SendNotificationModal notification={notification} setNotification={setNotification} hideModal={() => {setModalSwitch(undefined)}}/>
            </Modal>
            <Modal show={modalSwitch === 'acknowledgements'} onHide={() => {setModalSwitch(undefined)}}>
                <AcknowledgementModal acknowledgements={notification?.acknowledgements ?? []}/>
            </Modal>
            <AlertModal show={modalSwitch === 'delete'} onHide={() => {setModalSwitch(undefined)}} title={'Delete Notification?'} message='This cannot be undone' buttonLabel='Delete' callBack={handleDeleteNotification}/>
            <AlertModal 
                show={modalSwitch === 'disable'} 
                onHide={() => {setModalSwitch(undefined)}} 
                title={'Disable Notification?'} 
                message='This notification will no longer be sent to any of the recipients. Acknowledgements will not be deleted and will continue to be viewable.' 
                buttonLabel='Disable Notification' 
                callBack={handleDisableNotification}
            />
        </>
    )
}