/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { getEmployeePto } from '../../../services/HumanResourcesService';
import moment from 'moment';
import QuickTable from '../../../components/QuickTable';
import { Button } from 'react-bootstrap';
import HRUserPtoModal from '../HRUserPTO/HRUserPtoModal';
import CustomSpinner from '../../../components/CustomSpinner';
import CustomButton from '../../../components/CustomButton';
import DateRangeSelector from '../../../components/DateRangeSelector';
import { dateRangeToString, validateDecimal } from '../../../tools';
import { toFixedMax } from '../../Payroll/payrollTools';
import HRScheduleTimeOffCreator from '../HRSchedule/HRScheduleTimeOffCreator';

export default function HRUserPto({selectedEmployee, defaultStartDate, defaultEndDate, crudCallback}) {
    const [isLoading, setIsLoading] = useState(true);
    const [modalSwitch, setModalSwitch] = useState('');
    const [pto, setPto] = useState([]);
    const [ptoBalance, setPtoBalance] = useState(0);
    const [selectedPto, setSelectedPto] = useState(undefined);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [showDateRangeSelector, setShowDateRangeSelector] = useState(false);


    const sortTransactions = (a, b) => {
        if (a.dateandtime < b.dateandtime) {
            return 1;
        } else if (a.dateandtime > b.dateandtime) {
            return -1;
        } else {
            return 0;
        }
    }

    const sortScheduledTransactions = (a, b) => {
        if (a.timeOffDate < b.timeOffDate) {
            return 1;
        } else if (a.timeOffDate > b.timeOffDate) {
            return -1;
        } else {
            return 0;
        }
    }

    const sortAccruedTransactions = (a, b) => {
        if (a.payrollPeriod.periodStart < b.payrollPeriod.periodStart) {
            return 1;
        } else if (a.payrollPeriod.periodStart > b.payrollPeriod.periodStart) {
            return -1;
        } else {
            return 0;
        }
    }

    const loadData = async (startDate = defaultStartDate, endDate = defaultEndDate) => {
        setIsLoading(true);
        const response = await getEmployeePto(selectedEmployee.uid, startDate ? startDate : '1000-01-01', endDate ? endDate : '3000-01-01');
        if (response.status === 200) {
            setPto(response.pto);
            setPtoBalance(validateDecimal(response.ptoBalance));
            setStartDate(startDate);
            setEndDate(endDate);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadData();
    }, crudCallback ? [] : [selectedEmployee])

    const handleTransactionCrud = (type, data) => {
        let newArray = Array.from(pto);
        if (type === 'create') {
            newArray.push(data);
            setPtoBalance(ptoBalance + data.amount);
        } else if (type === 'update') {
            newArray = newArray.filter(t => t.uid != data.uid);
            newArray.push(data);
            setPtoBalance(ptoBalance - validateDecimal(pto.find(p => p.uid == data.uid).amount) + data.amount);
        } else if (type === 'delete') {
            newArray = newArray.filter(t => t.uid != data.uid);
            setPtoBalance(ptoBalance - validateDecimal(pto.find(p => p.uid == data.uid).amount));
        }
        setModalSwitch('');
        setPto(newArray);
        if (crudCallback) {
            crudCallback();
        }
    }

    const handleCreateTimeOff = (ptoArray, timeOff) => {
        setPto(pto.concat(ptoArray));
        setModalSwitch('');
    }

    const handleSetSelectedDateRange = (startDate, endDate) => {
        loadData(startDate, endDate);
    }

    const scheduledPtoRows = pto.filter(i => i.timeOffIdentifier).sort(sortScheduledTransactions).map((item) => {
        return (
            <tr className='cursor-pointer' key={item.uid} onClick={() => {setSelectedPto(item); setModalSwitch('editor')}}>

                <td>{moment(item.timeOffDate).format('MMM D, YYYY')}</td>
                <td>{moment(item.dateCreated).format('MMM D, YYYY')}</td>
                <td style={{color: parseInt(item.amount) > 0 ? 'green' : 'red'}}>{parseInt(item.amount) > 0 ? `+${parseInt(item.amount)}` : parseInt(item.amount)}</td>
                <td>{item.payrollPeriod ? `${moment(item.payrollPeriod.periodStart).format('MMM D, YYYY')} - ${moment(item.payrollPeriod.periodEnd).format('MMM D, YYYY')}` : ''}</td>
                <td style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 200, overflowX: 'hidden'}}>{item.notes}</td>
            </tr>
        );
    });

    const manualPtoRows = pto.filter(i => !i.timeOffIdentifier && !i.payrollIdentifier).sort(sortTransactions).map((item) => {
        return (
            <tr className='cursor-pointer' key={item.uid} onClick={() => {setSelectedPto(item); setModalSwitch('editor')}}>
                <td>{moment(item.dateCreated).format('MMM D, YYYY')}</td>
                <td style={{color: parseFloat(item.amount) > 0 ? 'green' : 'red'}}>{parseFloat(item.amount) > 0 ? `+${parseFloat(item.amount)}` : parseFloat(item.amount)}</td>
                <td style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 200, overflowX: 'hidden'}}>{item.notes}</td>
            </tr>
        );
    });

    const accruedPtoRows = pto.filter(i => !i.timeOffIdentifier && i.payrollIdentifier).sort(sortAccruedTransactions).map((item) => {
        return (
            <tr key={item.uid}>
                <td>{`${moment(item.payrollPeriod.periodStart).format('MMM D, YYYY')} - ${moment(item.payrollPeriod.periodEnd).format('MMM D, YYYY')}`}</td>
                <td style={{color: 'green'}}>{`+${toFixedMax(item.amount, 3)}`}</td>
            </tr>
        );
    })

    const content = (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 12}}>
                <span>Current PTO Balance: <b>{`${toFixedMax(ptoBalance, 2)} Hours`}</b></span>
                <div style={{display: 'flex', gap: 12}}>
                    <Button variant='outline-primary' onClick={() => {setShowDateRangeSelector(true)}}>Viewing Scheduled PTO for: <b>{dateRangeToString(startDate, endDate)}</b></Button>
                    <DateRangeSelector handleSetDateRange={handleSetSelectedDateRange} show={showDateRangeSelector} onHide={() => {setShowDateRangeSelector(false)}} presetOptions={['pastYear', 'pastMonth', 'pastWeek', 'nextWeek', 'nextMonth', 'nextYear', 'allTime']}/>
                    <CustomButton label='Schedule Time Off' onClick={() => {setModalSwitch('createTimeOff')}}/>
                    <Button variant={'outline-primary'} onClick={() => {setSelectedPto(undefined); setModalSwitch('editor')}}>Add/Subtract PTO</Button>
                </div>
            </div>
            <QuickTable title='Scheduled PTO' headers={['Date of PTO', 'Date Approved', 'Amount (Hours)', 'Payroll Period Paid', 'Notes']} rows={scheduledPtoRows} hover/>
            <QuickTable title='Manually Entered Transactions' headers={['Date Created', 'Amount (Hours)', 'Notes']} rows={manualPtoRows} hover/>
            <QuickTable title='Accrued PTO' headers={['Payroll Period Earned', 'Amount (Hours)']} rows={accruedPtoRows}/>
            <Modal show={modalSwitch === 'editor'} onHide={() => {setModalSwitch('')}} size='lg'>
                <HRUserPtoModal handleTransactionCrud={handleTransactionCrud} userIdentifier={selectedEmployee.uid} selectedPto={selectedPto}/>
            </Modal>
            <Modal show={modalSwitch === 'createTimeOff'} onHide={() => {setModalSwitch('')}} size='lg'>
                <HRScheduleTimeOffCreator userIdentifier={selectedEmployee.uid} handleCreateTimeOff={handleCreateTimeOff}/>
            </Modal>
        </div>
    )

    return isLoading ? <CustomSpinner spinnerHeight='10vh' spinnerWidth='10vh' height='50vh'/> : content;
    
}
