/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from "../serviceTools";
import moment from "moment";

export async function submitNewHire(companyIdentifier, data) {
    var data = {
        type: 'hr',
        target: 'newHire',
        authToken: getToken(),
        companyIdentifier: companyIdentifier,
        data: data
    }

    // const response = await getResponse(data);
    // return response;
}

export async function getPermissionsTemplate() {
    var data = {
        type: 'hr',
        target: 'getPermissionsTemplate',
        authToken: getToken(),
    }

    return {
        status: '200',
        template: [
            {
                permissionName: 'hr_profile',
                permissionTitle: 'HR Profile',
                description: 'Access to employee profile information on all employees in the company',
                driver: '0',
                bc: '1',
                ao: '1',
            },
            {
                permissionName: 'hr_schedule',
                permissionTitle: 'HR Schedule',
                description: 'Access to employee schedule items; ability to accept requests for time off and ability to report call-offs and no-shows',
                driver: '0',
                bc: '1',
                ao: '1',
            },
            {
                permissionName: 'hr_timesheet',
                permissionTitle: 'HR Timesheet',
                description: 'Access to employee timesheet records; ability to view, modify, create, and delete timesheet entries',
                driver: '0',
                bc: '1',
                ao: '1',
            },
            {
                permissionName: 'hr_pay',
                permissionTitle: 'HR Pay',
                description: 'Access to employee pay information; ability to view current and historic pay information and define a new position for the employee',
                driver: '0',
                bc: '1',
                ao: '1',
            },
            {
                permissionName: 'hr_safety',
                permissionTitle: 'HR Safety',
                description: 'Access to employee safety records; ability to view, create, and revise accidents, incidents, moving violations, and ride-alongs',
                driver: '0',
                bc: '1',
                ao: '1',
            },
            {
                permissionName: 'hr_bwc',
                permissionTitle: 'HR BWC',
                description: 'Access to employee BWC records; ability to view, create, and revise BWC reports',
                driver: '0',
                bc: '1',
                ao: '1',
            },
            {
                permissionName: 'hr_documents',
                permissionTitle: 'HR Documents',
                description: 'Access to employee documents (images, pdfs, word documents, etc.); ability to download existing documents and upload new documents',
                driver: '0',
                bc: '1',
                ao: '1',
            },
            {
                permissionName: 'hr_permissions',
                permissionTitle: 'HR Permissions',
                description: 'Access to employee permissions; ability to view and modify permissions',
                driver: '0',
                bc: '0',
                ao: '1',
            },
            {
                permissionName: 'payroll',
                permissionTitle: 'Payroll',
                description: 'Access to company payroll; ability to view current and historic payroll information and approve payroll periods',
                driver: '0',
                bc: '1',
                ao: '1',
            },
            {
                permissionName: 'scheduleMatch',
                permissionTitle: 'Schedule Match',
                description: 'Access to company scheduling; ability to view, create, modify, and delete scheduled routes, paid training, and managers on duty.',
                driver: '0',
                bc: '1',
                ao: '1',
            },
            {
                permissionName: 'myCompany',
                permissionTitle: 'Company Resources & Settings',
                description: 'Access to company resources and settings; ability to view, create, modify, and deactivate CSAs, work areas, and vehicles; ability to modify company settings (ex. company address).',
                driver: '0',
                bc: '1',
                ao: '1',
            },
            {
                permissionName: 'profile',
                permissionTitle: 'Profile',
                description: 'Ability to view his or her own personal information, pay information, and ability to change his or her password',
                driver: '1',
                bc: '1',
                ao: '1',
            },
            {
                permissionName: 'timeClock',
                permissionTitle: 'Time Clock',
                description: 'Ability to view his or her own timesheet records, clock-in, and clock-out',
                driver: '1',
                bc: '1',
                ao: '1',
            },
            {
                permissionName: 'mySchedule',
                permissionTitle: 'My Schedule',
                description: 'Ability to view his or her own personal calendar of routes, time off, etc.',
                driver: '1',
                bc: '1',
                ao: '1',
            },
            {
                permissionName: 'daysOff',
                permissionTitle: 'Days Off',
                description: 'Ability to view his or her own history of time off requests and their statuses (approved, rejected, pending); ability to request time off',
                driver: '1',
                bc: '1',
                ao: '1',
            },
            {
                permissionName: 'reports',
                permissionTitle: 'Reports',
                description: 'View company reports, such as timesheets, budget information, user lists, etc.',
                driver: '0',
                bc: '0',
                ao: '1',
            },
        ]
    }

    // const response = await getResponse(data);
    // return response;
}

export async function getCompanyBenefits(companyIdentifier) {
    var data = {
        type: 'hr',
        target: 'getCompanyBenefits',
        authToken: getToken(),
        companyIdentifier: companyIdentifier
    }

    return {
        status: '200',
        benefits: 'example benefits',
    }

    // const response = await getResponse(data);
    // return response;
}

export async function checkEmail(email) {
    var data = {
        type: 'hr',
        target: 'checkEmail',
        authToken: getToken(),
        email: email
    }

    const response = await getResponse(data);
    return response;
}