/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { fakeCompanies, fakeCompanyUsers, fakePay, getFakeUID, fakeHRUserBWCData, } from "../../fake-data";
import fakeUsers from "../../fakeData/fakeUsers";
import fakeAddresses from "../../fakeData/fakeAddresses";
import { getResponse, getToken } from "../serviceTools";
import { timeout } from "../../tools";
import moment from "moment";

export async function getHRUserBWCRecords(companyUserUid) {
    var data = {
        type: 'employee',
        target: 'getBwc',
        authToken: getToken(),
        companyUserUid: companyUserUid
    }

    // const response = await getResponse(data);

    // return response;

    await timeout(300);

    const bwcs = JSON.parse(JSON.stringify(fakeHRUserBWCData.filter(r => r.companyUserIdentifier === companyUserUid).map(item => item.versions[item.versions.length - 1])));
    const addresses = JSON.parse(JSON.stringify(fakeAddresses.filter(r => bwcs.find(bwc => bwc.treatmentFacilityAddress === r.uid))));

    return {
        status: '200',
        data: bwcs,
        addresses: addresses
    }
}

export async function getBWCRecord(uid) {
    var data = {
        type: 'employee',
        target: 'getBwcRecordVersions',
        authToken: getToken(),
        uid: uid
    }

    // const response = await getResponse(data);

    // return response;

    await timeout(300);

    const versions = JSON.parse(JSON.stringify(fakeHRUserBWCData.find(r => r.uid === uid).versions));

    return {
        status: '200',
        data: versions,
        addresses: JSON.parse(JSON.stringify(fakeAddresses.filter(r => versions.find(bwc => bwc.treatmentFacilityAddress === r.uid))))
    }

}

export async function createBWCRecord(companyUserUid, record) {
    var data = {
        type: 'employee',
        target: 'createBwc',
        authToken: getToken(),
        companyUserUid: companyUserUid,
        data: record
    }

    // const response = await getResponse(data);

    // return response;

    await timeout(300);

    const newRecord = JSON.parse(JSON.stringify(record));
    newRecord.bwcRecordsIdentifier = getFakeUID();
    newRecord.author = companyUserUid;
    newRecord.uid = getFakeUID();
    newRecord.submissionDateTime = moment().format('YYYY-MM-DD hh:mm')

    return {
        status: '200',
        data: newRecord
    }

}

export async function reviseBWCRecord(record) {
    var data = {
        type: 'employee',
        target: 'reviseBwc',
        authToken: getToken(),
        data: record
    }

    // const response = await getResponse(data);

    // return response;
    
    await timeout(300);

    const newRecord = JSON.parse(JSON.stringify(record));
    newRecord.author = fakeHRUserBWCData.find(r => r.uid === record.bwcRecordsIdentifier).versions[0].author;
    newRecord.uid = getFakeUID();
    newRecord.submissionDateTime = moment().format('YYYY-MM-DD hh:mm')

    return {
        status: '200',
        data: newRecord
    }

}