/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useEffect, useRef, useState } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import CustomButton from "../../../components/CustomButton";
import moment from "moment";
import { getLhApiLogs } from "../../../services/AdminToolsService";
import PageSpinner from "../../../components/PageSpinner";
import DateTimePicker from "../../../components/DateTimePicker";
import ApiLogElementBody from "./ApiLogElementBody";

export default function LhApiLogs() {
    const ref = useRef();

    const [isLoading, setIsLoading] = useState(false);

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [statusMin, setStatusMin] = useState(0);
    const [statusMax, setStatusMax] = useState(999);
    const [firstNameFilter, setFirstNameFilter] = useState('');
    const [lastNameFilter, setLastNameFilter] = useState('');
    const [companyFilter, setCompanyFilter] = useState('');
    const [terminalFilter, setTerminalFilter] = useState('');
    const [tokenFilter, setTokenFilter] = useState('');
    const [typeFilter, setTypeFilter] = useState('');
    const [targetFilter, setTargetFilter] = useState('');


    const [filtersChanged, setFiltersChanged] = useState(false);

    const [logs, setLogs] = useState([]);
    const [maxUid, setMaxUid] = useState(9007199254740991);

    useEffect(() => {
        setFiltersChanged(true);
    }, [startDate, endDate, statusMin, statusMax, firstNameFilter, lastNameFilter, companyFilter, terminalFilter, tokenFilter, typeFilter, targetFilter]);

    useEffect(() => {
        handleGetApiLogs();
    }, []);

    const handleGetApiLogs = async (add = false) => {
        setIsLoading(true);

        const filters = {
            startDate: startDate ? startDate : '0000-00-00', 
            endDate: endDate ? endDate : '9999-01-01', 
            statusMin: statusMin, 
            statusMax: statusMax, 
            firstName: firstNameFilter, 
            lastName: lastNameFilter, 
            company: companyFilter, 
            terminal: terminalFilter, 
            token: tokenFilter,
            type: typeFilter,
            target: targetFilter
        }

        const response = await getLhApiLogs(filters, add ? maxUid : 9007199254740991);
        if (response.status == 200) {
            if (add) {
                setLogs([...logs, ...response.apiLogs]);
            } else {
                setLogs(response.apiLogs);
            }
            setMaxUid(response.maxUid);
            setFiltersChanged(false);
        }
        setIsLoading(false);
    }

    const handleClearFilters = () => {
        setStartDate('');
        setEndDate('');
        setStatusMin(0);
        setStatusMax(999);
        setFirstNameFilter('');
        setLastNameFilter('');
        setCompanyFilter('');
        setTerminalFilter('');
        setTokenFilter('');
        setTypeFilter('');
        setTargetFilter('');
    }

    const handleScrollToTop = () => {
        ref.current?.scrollIntoView();
    }

    const statusLabels = {
        'All': [0, 999], 
        '200': [200, 200], 
        'Not 200': [300, 999], 
        '4XX': [400, 499], 
        '5XX': [500, 599], 
    };

    const statusDropdownButtons = ['All', '200', 'Not 200', '4XX', '5XX'].map((label) => {
        const values = statusLabels[label];
        return (
            <Form.Check key={label} style={{marginTop: 4, fontSize: 15}} inline type="radio" checked={statusMin == values[0] && statusMax == values[1]} onChange={() => {setStatusMin(values[0]); setStatusMax(values[1]);}} label={label}/>
        )
    })

    const apiLogElements = logs.map((log) => {
        return (
            <ApiLogElement key={log.uid} log={log}/>
        )
    })

    return (
        <>
            <Modal.Header ref={ref} closeButton>
                <Modal.Title>TC API Logs</Modal.Title>
            </Modal.Header>
            <Modal.Header style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 6}}>
                <div style={{border: '1px solid lightgray', borderRadius: 6, height: 105}}>
                    <div style={{borderBottom: '1px solid lightgray', textAlign: 'center'}}>
                        <span style={{fontWeight: 'bold', opacity: 0.65}}>Status</span>
                    </div>
                    <div style={{width: 228, padding: 6}}>
                        {statusDropdownButtons}
                    </div>
                </div>
                <DateTimePicker value={startDate} setValue={setStartDate} optional title='Min Date & Time' type='dateTimePrecise'/>
                <DateTimePicker value={endDate} setValue={setEndDate} optional title='Max Date & Time' type='dateTimePrecise'/>
                <div style={{border: '1px solid lightgray', borderRadius: 6, width: '100%'}}>
                    <div style={{borderBottom: '1px solid lightgray', textAlign: 'center'}}>
                        <span style={{fontWeight: 'bold', opacity: 0.65}}>Other Filters</span>
                    </div>
                    <div style={{display: 'flex', padding: 4, alignItems: 'center', gap: 4, flexWrap: 'wrap'}}>
                        <Form.Control style={{fontSize: 12, maxWidth: 150}} placeholder="First Name" value={firstNameFilter} onChange={(e) => {setFirstNameFilter(e.target.value)}}/>
                        <Form.Control style={{fontSize: 12, maxWidth: 150}} placeholder="Last Name" value={lastNameFilter} onChange={(e) => {setLastNameFilter(e.target.value)}}/>
                        <Form.Control style={{fontSize: 12, maxWidth: 150}} placeholder="Company Name" value={companyFilter} onChange={(e) => {setCompanyFilter(e.target.value)}}/>
                        <Form.Control style={{fontSize: 12, maxWidth: 150}} placeholder="Terminal Name" value={terminalFilter} onChange={(e) => {setTerminalFilter(e.target.value)}}/>
                        <Form.Control style={{fontSize: 12, maxWidth: 312}} placeholder="Auth Token" value={tokenFilter} onChange={(e) => {setTokenFilter(e.target.value)}}/>
                        <Form.Control style={{fontSize: 12, maxWidth: 150}} placeholder="Request Type" value={typeFilter} onChange={(e) => {setTypeFilter(e.target.value)}}/>
                        <Form.Control style={{fontSize: 12, maxWidth: 150}} placeholder="Request Target" value={targetFilter} onChange={(e) => {setTargetFilter(e.target.value)}}/>
                        
                    </div>
                </div>
                <div style={{width: '100%'}}>
                    <div style={{width: '50%', maxWidth: 400, minWidth: 116, marginLeft: 'auto', marginRight: 'auto', display: 'flex', flexDirection: 'column'}}>
                        <CustomButton disabled={!filtersChanged} label='Apply Filters' isLoading={isLoading} onClick={() => handleGetApiLogs()}/>
                    </div>
                </div>
                <Button style={{padding: 0, background: 'none', color: 'var(--bs-primary)', border: 'none', boxShadow: 'none'}} onClick={handleClearFilters}>Clear Filters</Button>
                
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                { logs.length == 0 ? isLoading ? <PageSpinner/> : <span style={{textAlign: 'center', opacity: 0.65, fontSize: 25}}>No Results</span> :
                    <div style={{display: 'flex', flexDirection: 'column', gap: 12, opacity: isLoading ? 0.5 : 1}}>
                        {apiLogElements}
                    </div>
                }
                { logs.length > 0 && 
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 12, gap: 12}}>
                        { maxUid > 1 && 
                            <CustomButton isLoading={isLoading} label={'Load More Data'} onClick={() => handleGetApiLogs(true)}/>
                        }
                        <a className="cursor-pointer" style={{textDecoration: 'none'}} onClick={handleScrollToTop}>Back to Top</a>
                    </div>
                }
            </Modal.Body>
        </>
    )
}

function ApiLogElement({log}) {
    return (
        <Card>
            <Card.Header style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 12}}>
                <Card.Title style={{marginBottom: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{`#${log.uid}`}</Card.Title>
                <Card.Title style={{marginBottom: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{moment(log.dateTime).format('MMM D, YYYY [at] h:mm A')}</Card.Title>
                <div style={{display: 'flex', flexWrap: 'wrap', gap: 12}}>
                    { log.companyUser ? 
                        <Card.Title style={{marginBottom: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{`${log.companyUser.firstName} ${log.companyUser.middleName ? `${log.companyUser.middleName.substring(0, 1)} ` : ''}${log.companyUser.lastName} | ${log.companyUser.terminalName} | ${log.companyUser.companyName}${log.adminUser ? ' (SHADOWING)' : ''}`}</Card.Title>
                    : log.adminUser ? 
                        <Card.Title style={{marginBottom: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{`${log.adminUser.firstName} ${log.adminUser.lastName} (ADMIN)`}</Card.Title>
                    :
                        <Card.Title style={{marginBottom: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>Anonymous</Card.Title>
                    }
                    { log.token && 
                        <input value={log.token} readOnly style={{width: 80, borderRadius: 6}}/>
                    }
                </div>
            </Card.Header>
            <ApiLogElementBody log={log}/>
        </Card>
    )
}