/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomControl from '../../../components/CustomControl';
import AddressControl from '../../../components/AddressControl';
import { validateExistence, validateEmail, validatePhoneNumber, validateSsn, AddressValidator } from '../../../validation';

export default function HRHirePersonalInfo({personalInformation, handleSetPersonalInformation, validationGroup}) {

    return (
        <Container fluid>
            <Row>
                <CustomControl validator={validationGroup.createValidator('firstName', personalInformation.firstName, validateExistence)} lg={3} type='text' max={30} title='First Name' value={personalInformation.firstName} onChange={(event) => handleSetPersonalInformation('firstName', event.target.value)}/>
                <CustomControl validator={validationGroup.createValidator('middleName', personalInformation.middleName, validateExistence)} lg={3} type='text' max={30} title='Middle Name' value={personalInformation.middleName} onChange={(event) => handleSetPersonalInformation('middleName', event.target.value)}/>
                <CustomControl validator={validationGroup.createValidator('lastName', personalInformation.lastName, validateExistence)} lg={3} type='text' max={30} title='Last Name' value={personalInformation.lastName} onChange={(event) => handleSetPersonalInformation('lastName', event.target.value)}/>
                <CustomControl validator={validationGroup.createValidator('email', personalInformation.email, validateEmail)} required lg={3} max={50} type='text' title='Email Address' value={personalInformation.email} onChange={(event) => handleSetPersonalInformation('email', event.target.value)}/>
                <CustomControl validator={validationGroup.createValidator('phoneNumber', personalInformation.phoneNumber, validatePhoneNumber)} required lg={3} max={10} type='text' title='Phone Number' value={personalInformation.phoneNumber} onChange={(event) => handleSetPersonalInformation('phoneNumber', event.target.value)}/>
                <CustomControl validator={validationGroup.createValidator('dateOfBirth', personalInformation.dateOfBirth, validateExistence)} required lg={3} type='date' title='Date of Birth' value={personalInformation.dateOfBirth} onChange={(event) => handleSetPersonalInformation('dateOfBirth', event.target.value)}/>
                <CustomControl validator={validationGroup.createValidator('ssn', personalInformation.ssn, validateSsn)} required lg={3} max={9} type='text' title='Social Security #' value={personalInformation.ssn} onChange={(event) => handleSetPersonalInformation('ssn', event.target.value)}/>
                <Col xxl={12}>
                    <AddressControl validator={validationGroup.pushValidator('address', new AddressValidator(personalInformation.address))} lg={4} title='Address' address={personalInformation.address} setAddress={(value) => {handleSetPersonalInformation('address', value)}}/>
                </Col>
            </Row>
        </Container>
    )
}