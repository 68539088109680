/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import CustomControl from '../../../components/CustomControl';
import CustomButton from '../../../components/CustomButton';
import { Modal } from 'react-bootstrap';
import { createEmployeeTicketInstallment } from '../../../services/HRServices/HRLoanService';
import moment from 'moment';
import { ValidationGroup, validateExistence, validateGreaterThanZero } from '../../../validation';

export default function HRUserTicketInstallmentModal({ticketIdentifier, handleCreateInstallment}) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [amount, setAmount] = useState(0);
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));

    const handleSubmit = async () => {
        setIsSubmitting(true);

        const installment = {
            ticketIdentifier: ticketIdentifier,
            amount: amount,
            date: date
        }

        const response = await createEmployeeTicketInstallment(installment);
        if (response.status === '200') {
            installment.uid = response.uid;
            handleCreateInstallment(installment);
        }

        setIsSubmitting(false);
    }

    const validationGroup = new ValidationGroup();

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Create Manual Installment</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <CustomControl validator={validationGroup.createValidator(() => validateGreaterThanZero(amount))} type='number' value={amount} onChange={(event) => {setAmount(event.target.value)}} title='Payment Amount'/>
                <CustomControl max={moment().format('YYYY-MM-DD')} validator={validationGroup.createValidator(() => validateExistence(date))} type='date' value={date} onChange={(event) => {setDate(event.target.value)}} title='Payment Date'/>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton disabled={!validationGroup.isValid()} isLoading={isSubmitting} label='Submit' onClick={handleSubmit}/>
            </Modal.Footer>
        </>
    )
}