/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import Dashboard from './views/Dashboard/Dashboard';
import Payroll from './views/Payroll/Payroll';
import MtbPayroll from './views/MTBPayroll/Payroll';
import HumanResources from './views/HumanResources/HumanResources';
import Companies from './views/Companies/Companies';
import Notifications from './views/Notifications/Notifications';
import UserSearch from './views/UserSearch';
import AdminTools from './views/AdminTools/AdminTools';
import LHPayroll from './views/LHPayroll/LHPayroll';
import LHHumanResources from './views/LHHumanResources';
const pages = {
    dashboard: {
        path: '/',
        element: <Dashboard/>,
        loader: async () => {
            document.title = 'TTA - Dashboard';
        },
    },
    companies: {
        path: '/companies',
        element: <Companies/>,
        loader: async () => {
            document.title = 'TTA - Dashboard';
        },
    },
    
    payroll: {
        path: 'payroll',
        element: <Payroll/>,
        loader: async () => {
            document.title = 'TTA - Payroll';
        },
    },
    mtbPayroll: {
        path: 'mtb-payroll',
        element: <MtbPayroll/>,
        loader: async () => {
            document.title = 'TTA - MTB Payroll';
        },
    },
    lhPayroll: {
        path: 'lh-payroll',
        element: <LHPayroll/>,
        loader: async () => {
            document.title = 'TTA - LH Payroll';
        },
    },
    
    hr: {
        path: 'human-resources',
        element: <HumanResources/>,
        loader: async () => {
            document.title = 'TTA - Human Resources';
        },
    },
    lhHr: {
        path: 'lh-human-resources',
        element: <LHHumanResources/>,
        loader: async () => {
            document.title = 'TTA - Human Resources';
        },
    },
    notifications:{
        path: 'notifications',
        element: <Notifications/>,
        loader: async () => {
            document.title = 'TTA - Notifications';
        },
    },
    adminTools: {
        path: 'admin-tools',
        element: <AdminTools/>,
        loader: async () => {
            document.title = 'TTA - Admin Tools';
        }
    }
}


export function getPages(permissions) {
    const arr = [];
    Object.values(pages).forEach((obj) => {
        if (!obj.requiredPermission || permissions[obj.requiredPermission] === '1') {
            arr.push(obj);
        }
    });
    return arr;
}