/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { Accordion, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

export default function ApiLogElementBody({log}) {
    return (
        <Card.Body>
            <Accordion alwaysOpen>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        <div style={{display: 'flex', justifyContent: 'space-between', gap: 12, width: '100%', paddingRight: 12}}>
                            <span>Request</span>
                            <div style={{display: 'flex', gap: 12, alignItems: 'center', fontWeight: 'bold'}}>
                                <span>{log.type}</span>
                                <FontAwesomeIcon icon={faArrowRight}/>
                                <span>{log.target}</span>
                            </div>
                        </div>

                    </Accordion.Header>
                    <Accordion.Body style={{color: 'black', padding: 12}}>
                        {log.request}
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>
                        <div style={{display: 'flex', justifyContent: 'space-between', gap: 12, width: '100%', paddingRight: 12}}>
                            <span>Response</span>
                            <span style={{color: log.status == 200 ? 'green' : 'red', fontWeight: 'bold'}}>{log.status ? log.status : '500'}</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body style={{color: 'black', padding: 12}}>
                        {log.response}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            { (log.error || !log.status) && 
                <div style={{paddingTop: 12, textAlign: 'center'}}>
                    <span style={{color: 'red'}}>{log.error ? log.error : 'Internal Server Error'}</span>
                </div>
            }
        </Card.Body>
    )
}