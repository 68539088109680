/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import moment from 'moment';
import Alert from 'react-bootstrap/Alert';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTicket, faCarBurst, faGraduationCap, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { fakeHRUserSafetyData } from '../../../fake-data';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Card from 'react-bootstrap/Card';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Dropdown from 'react-bootstrap/Dropdown';
import HRUserSafetyAccident from './HRUserSafetyAccident';
import HRUserSafetyAccidentForm from './HRUserSafetyAccidentForm';
import HRUserSafetyIncident from './HRUserSafetyIncident';
import HRUserSafetyIncidentForm from './HRUserSafetyIncidentForm';
import HRUserSafetyRideAlong from './HRUserSafetyRideAlong';
import HRUserSafetyRideAlongForm from './HRUserSafetyRideAlongForm';
import HRUserSafetyMovingViolation from './HRUserSafetyMovingViolation';
import HRUserSafetyMovingViolationForm from './HRUserSafetyMovingViolationForm';
import HRUserSafetyRecord from './HRUserSafetyRecord';
import CustomSpinner from '../../../components/CustomSpinner';
import { getHRUserSafetyRecords, getAccidentRecord, getIncidentRecord, getRideAlongRecord, getMovingViolationRecord } from '../../../services/HRServices/HRSafetyService';
import { usdFormatter, addressToString } from '../../../tools';



function SafetyItemCard({icon, color, title, content, onClick}) {
    return (
        <Card className='cursor-pointer'  onClick={onClick} style={{display: 'flex', flexDirection: 'row', flex: 1}}>
            <div style={{display: 'flex', height: '100%', width: 180, padding: 12, gap: 12, borderTopLeftRadius: 6, borderBottomLeftRadius: 6, backgroundColor: color, alignItems: 'center', flexShrink: 0}}>
                <FontAwesomeIcon icon={icon} style={{color: 'white'}}/>
                <span style={{color: 'white'}}>{title}</span>
            </div>
            <div style={{borderTopRightRadius: 6, borderBottomRightRadius: 6, border: '1px solid ' + color, borderLeft: 'none', flex: 1, minWidth: 0, alignItems: 'center', gap: 12, display: 'flex', paddingLeft: 8}}>
                {content}
            </div>
        </Card>
    )
}


class HRUserSafety extends React.Component {
    constructor(props) { //users //user
        super(props);
        this.state = {
            isLoading: true,
            users: [],
            safetyItems: [],
            selectedSafetyRecord: undefined,
            safetyItemFilter: 'all',
            modalSwitch: '',
        }

        this.loadData = this.loadData.bind(this);
        this.setModalSwitch = this.setModalSwitch.bind(this);
        this.hideForm = this.hideForm.bind(this);
        this.handleAddRecord = this.handleAddRecord.bind(this);
        this.handleReviseRecord = this.handleReviseRecord.bind(this);
    }


    sortSafetyItems(a, b) {

        const aDate = a.date ? a.date : a.dateTime;
        const bDate = b.date ? b.date : b.dateTime;
        
        if (moment(aDate).isBefore(moment(bDate), 'hours')) {
            return 1;
        } else if (moment(aDate).isAfter(moment(bDate), 'hours')) {
            return -1;
        } else {
            return 0;
        }
    }

    loadData() {
        if (!this.state.isLoading) {
            this.setState({isLoading: true});
        }

        getHRUserSafetyRecords(this.props.user.userID).then((response) => {
            if (response && response.status === '200') {
                this.setState({
                    safetyItems: response.data.accidents.concat(response.data.incidents.concat(response.data.rideAlongs.concat(response.data.movingViolations))).sort(this.sortSafetyItems)
                });
            }
            this.setState({isLoading: false});
        });
    }


    componentDidMount() {
        this.loadData();
    }
    

    getRecordType(record) {
        if (record.accidentID) {
            return 'Accident';
        } else if (record.incidentID) {
            return 'Incident';
        } else if (record.movingViolationID) {
            return 'Moving Violation';
        } else {
            return 'Ride-Along';
        }
    }

    safetyRecordAPIDictionary = {
        'Accident': getAccidentRecord,
        'Incident': getIncidentRecord,
        'Ride-Along': getRideAlongRecord,
        'Moving Violation': getMovingViolationRecord
    }

    rideAlongTypeDictionary = {
        newHire: 'New Hire', safety: 'Safety', service: 'Service', customerService: 'Customer Service', productivity: 'Productivity'
    }

    hideForm() {
        this.setState({modalSwitch: ''});
    }

    setModalSwitch(value) {
        this.setState({modalSwitch: value});
    }

    handleAddRecord(record) {
        const newSafetyitems = Array.from(this.state.safetyItems);
        newSafetyitems.push(record);
        newSafetyitems.sort(this.sortSafetyItems);
        this.setState({safetyItems: newSafetyitems, modalSwitch: ''});
    }

    handleReviseRecord(record, type) {
        const newSafetyitems = Array.from(this.state.safetyItems.filter(i => this.getRecordType(i) !== type || i.uid !== this.state.selectedSafetyRecord.uid));
        newSafetyitems.push(record);
        newSafetyitems.sort(this.sortSafetyItems);
        this.setState({safetyItems: newSafetyitems, selectedSafetyRecord: record, modalSwitch: type});
    }

    render() {
        
        const safetyItemsFiltered = this.state.safetyItems.filter((item) => {
            return this.state.safetyItemFilter === 'all' || this.state.safetyItemFilter === this.getRecordType(item);
        });

        const safetyItemElements = safetyItemsFiltered.map((item, index) => {

            const recordType = this.getRecordType(item);

            let content;
            let color;
            let icon;
            let title = recordType;

            const address = item.address

            switch (recordType) {
                case 'Accident':
                    color = '#eb6767';
                    icon = faCarBurst;
                    content = (
                        <>
                            <span style={{flexShrink: 0}}>{'Addess: ' + addressToString(item.address)}</span>
                            <span style={{flexShrink: 0}}>{'Cost: ' + usdFormatter.format(item.cost)}</span>
                            <span style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{'Notes: ' + item.notes}</span>
                        </>
                    );
                    break;
                case 'Incident':
                    color = '#e3b88a'; 
                    icon = faTriangleExclamation;
                    content = (
                        <>
                            <span style={{flexShrink: 0}}>{'Addess: ' + addressToString(item.address)}</span>
                            <span style={{flexShrink: 0}}>{'Cost: ' + usdFormatter.format(item.cost)}</span>
                            <span style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{'Notes: ' + item.notes}</span>
                        </>
                    );
                    break;
                case 'Ride-Along':
                    color = '#d4cf8a'; 
                    icon = faGraduationCap;
                    content = (
                        <>
                            <span style={{flexShrink: 0}}>{'Type: ' + this.rideAlongTypeDictionary[item.rideAlongType]}</span>
                            <span style={{flexShrink: 0}}>{'Performance: ' + item.performance.charAt(0).toUpperCase() + item.performance.slice(1)}</span>
                            <span style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{'Notes: ' + item.notes}</span>
                        </>
                    );
                    break;
                case 'Moving Violation':
                    color = '#9dbceb'; 
                    icon = faTicket;
                    content = (
                        <>
                            <span style={{flexShrink: 0}}>{'Police Report Number: ' + item.policeReportNumber}</span>
                            <span style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{'Notes: ' + item.notes}</span> 
                        </>
                    );
                    break;
                default:
                    break;
            }

            return (
                <div key={title + item.uid} style={{display: 'flex', flexDirection: 'row', gap: 12, alignItems: 'center', flex: 1}}>
                    <p style={{margin: 0, width: 100}}>{moment(item.dateTime ? item.dateTime : item.date).format('MMM D, YYYY')}</p>
                    <SafetyItemCard  icon={icon} color={color} title={title} content={content} onClick={() => {this.setState({selectedSafetyRecord: item, modalSwitch: recordType})}}/>
                </div>
            )
        });
        
        

        const safetyRecordModals = Object.keys(this.safetyRecordAPIDictionary).map((key) => {
            return (
                <Modal key={key} show={this.state.modalSwitch === key} onHide={this.hideForm} size='lg' centered>
                    <HRUserSafetyRecord 
                        users={this.props.users} 
                        reviseRecord={() => {this.setModalSwitch(key + ' Form')}} 
                        type={key} 
                        safetyRecord={this.state.selectedSafetyRecord} 
                        apiFunction={this.safetyRecordAPIDictionary[key]}
                    />
                </Modal>
            )
        });

        const createNewButtons = Object.keys(this.safetyRecordAPIDictionary).map((key) => {
            return (
                <Dropdown.Item key={key} onClick={() => {this.setState({modalSwitch: key + ' Form', selectedSafetyRecord: undefined});}}>{key}</Dropdown.Item>
            )
        });
                         

        const content = (
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 12}}>
                    <div>
                        <Form.Check label='All' inline type='radio' checked={this.state.safetyItemFilter === 'all'} onChange={() => {this.setState({safetyItemFilter: 'all'})}}/>
                        <Form.Check label='Accidents' inline type='radio' checked={this.state.safetyItemFilter === 'Accident'} onChange={() => {this.setState({safetyItemFilter: 'Accident'})}}/>
                        <Form.Check label='Incidents' inline type='radio' checked={this.state.safetyItemFilter === 'Incident'} onChange={() => {this.setState({safetyItemFilter: 'Incident'})}}/>
                        <Form.Check label='Moving Violations' inline type='radio' checked={this.state.safetyItemFilter === 'Moving Violation'} onChange={() => {this.setState({safetyItemFilter: 'Moving Violation'})}}/>
                        <Form.Check label='Ride-Alongs' inline type='radio' checked={this.state.safetyItemFilter === 'Ride-Along'} onChange={() => {this.setState({safetyItemFilter: 'Ride-Along'})}}/>
                    </div>
                    <Dropdown>
                        <Dropdown.Toggle style={{backgroundColor: 'white', borderWidth: 2, paddingTop: 0, paddingBottom: 0}}>Create New</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {createNewButtons}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: 12, backgroundColor: 'rgb(240, 240, 240)', padding: 8, borderRadius: 6, maxHeight: 600, overflowY: 'scroll'}}>
                    {safetyItemElements}
                </div>
                    
            </div>
        );

        return (
            <>
                {this.state.isLoading ? <CustomSpinner spinnerHeight='10vh' spinnerWidth='10vh' height='50vh'/> : content}
                {safetyRecordModals}
                
                <Modal show={this.state.modalSwitch === 'Accident Form'} onHide={this.hideForm} size='lg' centered>
                    <HRUserSafetyAccidentForm 
                        users={this.props.users} 
                        selectedSafetyRecord={this.state.selectedSafetyRecord} 
                        handleAddRecord={this.handleAddRecord} 
                        handleReviseRecord={this.handleReviseRecord}
                    />
                </Modal>
                
                <Modal show={this.state.modalSwitch === 'Incident Form'} onHide={this.hideForm} size='lg' centered>
                    <HRUserSafetyIncidentForm 
                        users={this.props.users} 
                        selectedSafetyRecord={this.state.selectedSafetyRecord} 
                        handleAddRecord={this.handleAddRecord} 
                        handleReviseRecord={this.handleReviseRecord}
                    />
                </Modal>
                
                <Modal show={this.state.modalSwitch === 'Ride-Along Form'} onHide={this.hideForm} size='lg' centered>
                    <HRUserSafetyRideAlongForm 
                        users={this.props.users} 
                        selectedSafetyRecord={this.state.selectedSafetyRecord} 
                        handleAddRecord={this.handleAddRecord} 
                        handleReviseRecord={this.handleReviseRecord}
                    />
                </Modal>
                
                <Modal show={this.state.modalSwitch === 'Moving Violation Form'} onHide={this.hideForm} size='lg' centered>
                    <HRUserSafetyMovingViolationForm 
                        users={this.props.users} 
                        selectedSafetyRecord={this.state.selectedSafetyRecord} 
                        handleAddRecord={this.handleAddRecord} 
                        handleReviseRecord={this.handleReviseRecord}
                    />
                </Modal>
            </>
        )
    }
}

export default HRUserSafety;