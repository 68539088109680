/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import Modal from 'react-bootstrap/Modal';
import CustomSpinner from "../../components/CustomSpinner";
import SearchBar from "../../components/SearchBar";
import QuickTable from "../../components/QuickTable";
import { getCompanies } from "../../services/CompanyServices/CompaniesService";
import Button from 'react-bootstrap/Button';

export default class CompanySelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            companies: [],
            companyNameFilter: ''
        }

    }

    loadData() {
        if (!this.state.isLoading) {
            this.setState({isLoading: true})
        }
        getCompanies().then((response) => {
            if (response && response.status === '200') {
                this.setState({companies: response.data});
            }
        }).finally(() => {
            this.setState({isLoading: false});
        });
    }

    componentDidMount() {
        this.loadData();
    }

    handleSelectCompany(company) {
        this.setState({isLoading: true});
        this.props.handleSelectCompany(company);
    }


    render() {

        const filteredCompanies = this.state.companies.filter((company) => {
            return company.companyName.toLocaleLowerCase().includes(this.state.companyNameFilter.toLocaleLowerCase());
        });

        const companyRows = this.state.isLoading ? (
            <tr>
                <td colSpan={3}><CustomSpinner height={400}/></td>
            </tr>
        ) : filteredCompanies.map((company) => {
            return (
                <tr key={company.uid} className='cursor-pointer' onClick={() => {this.handleSelectCompany(company)}} style={this.state.selectedCompany === company.uid ? {backgroundColor: 'yellow'} : {}}>
                    <td>{company.uid}</td>
                    <td>{company.companyName}</td>
                    <td>{company.isEnabled === '0' ? 'No' : 'Yes'}</td>
                </tr>
            )
        });


        return (
            <>
                <Modal.Header>
                    <Modal.Title>Select Company</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div style={{display: 'flex', alignItems: 'center', gap: 12}}>
                            <SearchBar label='Filter Companies By Name' value={this.state.companyNameFilter} setValue={(value) => {this.setState({companyNameFilter: value})}}/>
                            <Button style={{flexShrink: 0, marginBottom: 12}} onClick={() => {this.props.setModalSwitch('createCompany')}}>Onboard New Company</Button>
                        </div>
                        <div style={{height: 500, overflowY: 'scroll'}}>
                            <QuickTable title='Companies' headers={['UID', 'Company Name', 'Enabled?']} rows={companyRows} hover/>
                        </div>
                    </div>
                </Modal.Body>
            </>
        )
    }
}