
/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomControl from '../../../components/CustomControl';
import DropdownControl from '../../../components/DropdownControl';
import CustomButton from '../../../components/CustomButton';
import { reviseMovingViolationRecord, createMovingViolationRecord } from '../../../services/HRServices/HRSafetyService';
import TextAreaControl from '../../../components/TextAreaControl';

export default function HRUserSafetyMovingViolationForm({users, selectedSafetyRecord, handleAddRecord, handleReviseRecord}) {

    const [dateTime, setDateTime] = useState(selectedSafetyRecord ? selectedSafetyRecord.dateTime : moment().format('YYYY-MM-DD HH:mm'));
    const [policeReportNumber, setPoliceReportNumber] = useState(selectedSafetyRecord ? selectedSafetyRecord.policeReportNumber : '');
    const [notes, setNotes] = useState(selectedSafetyRecord ? selectedSafetyRecord.notes : '');
    const [movingViolationType, setMovingViolationType] = useState(selectedSafetyRecord ? selectedSafetyRecord.movingViolationType : undefined);

    const [confirmSubmission, setConfirmSubmission] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    // attachments
    // author
    // submissionDate

    const handleSubmit = () => {
        setIsLoading(true);
        setConfirmSubmission(false);
        const newMovingViolation = {
            movingViolationID: selectedSafetyRecord ? selectedSafetyRecord.movingViolationID : undefined,
            dateTime: dateTime,
            notes: notes,
            policeReportNumber: policeReportNumber
        }

        if (selectedSafetyRecord) {
            reviseMovingViolationRecord(newMovingViolation).then((response) => {
                if (response && response.status === '200') {
                    newMovingViolation.uid = response.uid;
                    handleReviseRecord(newMovingViolation, 'Moving Violation');
                }
                setIsLoading(false);
            });
        } else {
            createMovingViolationRecord(newMovingViolation).then((response) => {
                if (response && response.status === '200') {
                    newMovingViolation.movingViolationID = response.uid;
                    newMovingViolation.uid = response.versionID;
                    handleAddRecord(newMovingViolation);
                }
                setIsLoading(false);
            })
        }
    }


    const movingViolationTypes = [
        'DUI/OVI',
        'Failure to submit to a sobriety test or drug screening presented by any law enforcement agency, the DOT, the company (IC\'s company), or FedEx',
        'Failure to pass a sobriety test or drug screening presented by any law enforcement agency, the DOT, the company (IC\'s company), or FedEx',
        'Failure to stop or remain at the scene of an accident',
        'Use of any vehicle in the commission of a felony',
        'Operating a CMV (commercial motor vehicle) while a license has been suspended, revoked, canceled, or expired',
        'Causing a fatality or causing serious bodily injury that results in medical costs in excess of $100,000, through the operation of a CMV (Irrespective of conviction).',
        'Using any vehicle, including personal vehicle, in the commission of a felony involving manufacturing, distributing, or dispensing a controlled substance.',
        'Exceeding the posted speed limit by 15 m.p.h. or more',
        'Reckless and aggressive operation of a vehicle',
        'Making improper or erratic traffic lane changes',
        'Following the vehicle ahead too closely',
        'Seatbelt violation',
        'Violating applicable law relating to motor traffic control device (other than parking violation) arising in connection with a fatal accident.',
        'Operating a CMV without first obtaining, or possessing at the time of, a commercial driver\'s license, or commercial learner\'s permit with the proper class (this would be only for line haul operations)',
        'Violating any law or regulation, including 49 CFR 392.80 and 392.82, prohibiting operation of a CMV while texting or e-mailing, while using a hand-held telephone, scanner, or other hand-held electronic communication device.',
        'Failing to slow down, stop, or check as required by applicable law before driving onto a railroad crossing',
        'Failing to obey a motor traffic control device at a railroad crossing.',
        'Conviction of violating an out of service order on a CMV',
        'Causing property damage to another vehicle, building, yard or landscaping, and/or any property in the yard/landscape',
        'Parking violations for the company\'s CMV only',
        'Failure to follow FedEx, company, and any government regulations in transporting hazardous material'
    ];

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{selectedSafetyRecord ? 'Revising Moving Violation' : 'Creating New Moving Violation Report'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <CustomControl disabled={isLoading} originalValue={selectedSafetyRecord ? selectedSafetyRecord.dateTime : undefined} lg={6} type='datetime-local' title='Date & Time' value={dateTime} onChange={(event) => {setDateTime(event.target.value)}}/>
                        <CustomControl disabled={isLoading} originalValue={selectedSafetyRecord ? selectedSafetyRecord.policeReportNumber : undefined} lg={6} type='text' title='Police Report Number' value={policeReportNumber} onChange={(event) => {setPoliceReportNumber(event.target.value)}}/>
                        <Col>
                            <DropdownControl title='Moving Violation Type' selection={movingViolationType} setSelection={setMovingViolationType} itemNames={movingViolationTypes} itemValues={movingViolationTypes}/>
                        </Col>
                        <Col lg={12} style={{marginBottom: 8}}>
                            <Form.Group>
                                <Form.Label>Attachments</Form.Label>  
                                <Form.Control disabled={isLoading} type='file' multiple/>
                            </Form.Group>
                        </Col>
                        <Col xxl={12} style={{marginBottom: 8}}>
                            <TextAreaControl label='Notes' originalValue={selectedSafetyRecord ? selectedSafetyRecord.notes : undefined} disabled={isLoading} rows={4} value={notes} setValue={setNotes}/>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton label='Submit' isLoading={isLoading} onClick={() => {setConfirmSubmission(true)}}/>
            </Modal.Footer>
            <Modal show={confirmSubmission} centered onHide={() => {setConfirmSubmission(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Submission</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Once submitted, the entered information will become permanent record. Proceed?
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{color: 'white'}} onClick={() => {setConfirmSubmission(false)}}>Cancel</Button>
                    <Button style={{color: 'white'}} onClick={handleSubmit}>Confirm Submission</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
    
}

