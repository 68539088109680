/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useEffect, useState } from "react";
import { Button, Card, Dropdown, ListGroup, Modal } from "react-bootstrap";
import DropdownControl from "../../../components/DropdownControl";
import CustomControl from "../../../components/CustomControl";
import CustomButton from "../../../components/CustomButton";
import DropdownItemWithCheck from "../../../components/DropdownItemWithCheck";
import { naturalSort, validateDecimal, validateDecimalFixed } from "../../../tools";
import KeyValueRow from "../../../components/KeyValueRow";
import { AppGlobals } from "../../../App";
import { submitPtoTransactions } from "../../../services/AdminToolsService";
import { ValidationGroup, validateGreaterThanZero } from "../../../validation";

export default function CompanyPtoTransaction({companies, csas}){
    const titles = ['Admin', 'AO', 'AVP Driver', 'BC', 'Driver'];
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedCSAs, setSelectedCSAs] = useState([]);
    const [selectedTitles, setSelectedTitles] = useState(titles)
    const [amount, setAmount] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const validationGroup = new ValidationGroup();
    
    useEffect(() => {
        setSelectedCSAs(csas.filter(csa => csa.companyIdentifier == selectedCompany).map(c => c.uid))
    }, [selectedCompany])

    async function handleSubmitPtoTransactions(){
        setIsSubmitting(true);
        const response = await submitPtoTransactions(validateDecimalFixed(amount, 2), selectedCSAs, selectedTitles);
        if(response.status == 200){
            AppGlobals.alert('success', response.message);
            setShowModal(false)
        }else{
            AppGlobals.alert('danger', response.message);
        }
        setIsSubmitting(false);
    }

    function handleSelectionCrud(state, setState, uid){
        if(state.includes(uid)){
            setState((prevState) => prevState.filter(ps => ps != uid))
        } else{
            setState((prevState) => {
                const newArray = Array.from(prevState);
                newArray.push(uid);
                return newArray;
            })
        }
    }

    const csaString = selectedCSAs.length > 1 ? ` +${selectedCSAs.length-1}` : '';
    const titleString = selectedTitles.length > 1 ? ` +${selectedTitles.length-1}` : '';
    const disabled = !selectedCompany || !amount || !selectedCSAs.length || !selectedTitles.length;

    const csaDropdown = (
        <Dropdown autoClose='outside'>
            <Dropdown.Toggle>
                {selectedCSAs.length === 0 ? 'Select CSA(s)' : `Selected CSA(s): ${csas.find(csa => csa.uid == selectedCSAs[0])?.csaName}${csaString}` }
            </Dropdown.Toggle>
            <Dropdown.Menu>
            {naturalSort(csas, 'csaName').filter((csa) => csa.companyIdentifier == selectedCompany).map(csa => {
                return <DropdownItemWithCheck key={csa.uid} active={selectedCSAs.includes(csa.uid)} label={csa.csaName} onClick={() => handleSelectionCrud(selectedCSAs, setSelectedCSAs, csa.uid)}/>
            })}
            </Dropdown.Menu>
        </Dropdown>
    )

    const titlesDropdown = (
        <Dropdown autoClose='outside'>
            <Dropdown.Toggle>
                {selectedTitles.length === 0 ? 'Select Title(s)' : `Selected Title(s): ${selectedTitles[0]}${titleString}` }
            </Dropdown.Toggle>
            <Dropdown.Menu>
            {titles.map(title => {
                return <DropdownItemWithCheck key={title} active={selectedTitles.includes(title)} label={title} onClick={() => handleSelectionCrud(selectedTitles, setSelectedTitles, title)}/>
            })}
            </Dropdown.Menu>
        </Dropdown>
    )


    return (
        <>
        <Card>
            <Card.Header>
                <Card.Title>Company PTO Balancing</Card.Title>
            </Card.Header>
            <Card.Body>
                <DropdownControl title='Select Company' selection={selectedCompany} setSelection={setSelectedCompany} itemNames={companies.map(c=>c.companyName)} itemValues={companies.map(c=>c.uid)}/>
                { selectedCompany && 
                    <>
                        <hr/>
                        <div style={{display: 'flex', justifyContent: 'center', gap: 24, marginBottom: 12}}>
                            {csaDropdown}
                            {titlesDropdown}
                        </div>
                        <CustomControl max={99} validator={validationGroup.createValidator(() => validateGreaterThanZero(amount))} title='PTO Amount' value={amount} onChange={(event) => setAmount(event.target.value)}/>
                    </>
                }
            </Card.Body>
            <Card.Footer style={{display: 'block', marginLeft: 'auto'}}>
                <Button onClick={() => setShowModal(true)} disabled={disabled || !validationGroup.isValid()}>Submit PTO Transactions</Button>
            </Card.Footer>
        </Card>
        <Modal centered size='lg' show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>PTO Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5 style={{textAlign: 'center'}}>Are you sure you want to set the pto balance to {amount} for all employees in {companies.find(c => c.uid === selectedCompany)?.companyName} under the following criteria?</h5>
                <ListGroup>
                    <KeyValueRow isListItem title='PTO Balance Exceeds' value={amount}/>
                    <KeyValueRow isListItem title='Selected CSAs' value={csas.filter(csa => selectedCSAs.includes(csa.uid)).map(csa => csa.csaName).join(', ')}/>
                    <KeyValueRow isListItem title='Selected Titles' value={selectedTitles.join(', ')}/>
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton label='Submit' disabled={disabled} isLoading={isSubmitting} onClick={handleSubmitPtoTransactions}/>
            </Modal.Footer>
        </Modal>   
        </>
    )
}