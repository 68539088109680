/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import moment from 'moment';
import Alert from 'react-bootstrap/Alert';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import CustomSpinner from '../../../components/CustomSpinner';
import HRUserScheduleItemEditor from './HRUserScheduleItemEditor';
import { getHRUserScheduleItems, } from '../../../services/HRServices/HRScheduleService';
import QuickTable from '../../../components/QuickTable';
import { dateRangeToString, scheduleTypeDictionary } from '../../../tools';
import { ButtonGroup } from 'react-bootstrap';
import CalendarObject from './CalendarObject';
import DateRangeSelector from '../../../components/DateRangeSelector';

class HRUserSchedule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            scheduleItems: [],
            selectedItem: undefined,
            modalSwitch: 'none',

            showModal: false,
            showAddNoShow: false,
            showCallIn: false,

            viewSwitch: 'table',
            selectedDate: moment().startOf('month'),


            startDate: moment().format('YYYY-MM-DD'),
            endDate: undefined,

            workAreas: [],
            vehicles: [],
            users: [],
        }
        this.handleCrud = this.handleCrud.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleSetDateRange = this.handleSetDateRange.bind(this);
        this.loadData = this.loadData.bind(this);
    }

    sortScheduleItems(a, b) {
        if (moment(a.date).isBefore(moment(b.date))) {
            return 1;
        } else if (moment(a.date).isAfter(moment(b.date))) {
            return -1;
        } else {
            return 0;
        }
    }


    loadData(startDate = this.state.startDate, endDate = this.state.endDate) {
        this.setState({isLoading: true});
        getHRUserScheduleItems(this.props.companyUserUid, startDate, endDate).then((response) => {
            if (response && response.status === '200') {
                this.setState({
                    scheduleItems: response.data.sort(this.sortScheduleItems),
                    users: response.users,
                    workAreas: response.workAreas,
                    vehicles: response.vehicles
                });
            }
            this.setState({isLoading: false})
        });
    }

    componentDidMount() {
        this.loadData();
    }

    handleCrud(type, data) {
        let newArray = Array.from(this.state.scheduleItems);
        if (type === 'create') {
            newArray.push(data);
            newArray.sort(this.sortScheduleItems);
        } else if (type === 'update') {
            newArray = newArray.filter(r => r.uid !== data.uid);
            newArray.push(data);
            newArray.sort(this.sortScheduleItems);
        } else if (type === 'delete') {
            newArray = newArray.filter(r => r.uid !== data.uid);
        }
        this.setState({scheduleItems: newArray, modalSwitch: 'none'});
    }


    hideModal() {
        this.setState({modalSwitch: 'none'});
    }

    getDaysOfTheWeek(date) {
        const startOfWeek = (date.day() === 6 ? moment(date) : moment(date).subtract(date.day() + 1, 'days'))
        const daysOfWeek = [
            moment(startOfWeek), 
            moment(startOfWeek).add(1, 'days'), 
            moment(startOfWeek).add(2, 'days'), 
            moment(startOfWeek).add(3, 'days'), 
            moment(startOfWeek).add(4, 'days'), 
            moment(startOfWeek).add(5, 'days'), 
            moment(startOfWeek).add(6, 'days'),
        ];
        return daysOfWeek;
    }

    handleSetDateRange(startDate, endDate) {
        this.setState({startDate: startDate, endDate: endDate});
        this.loadData(startDate, endDate);
    }


    render() {

        const routes = this.state.scheduleItems.filter(r => r.scheduleType === 'route');

        const routeRows = routes.map((r) => {
            let sdUser;
            if (r.secondaryDriver) {
                sdUser = this.state.users.find(u => u.companyUserUid === r.secondaryDriver);
            }
            const workArea = this.state.workAreas.find(wa => wa.uid === r.workAreaIdentifier).workAreaName;
            const vehicle = this.state.vehicles.find(v => v.uid === r.vehicleIdentifier).vehicleName;

            return (
                <tr key={r.uid} className='cursor-pointer' onClick={() => {this.setState({selectedItem: r, modalSwitch: 'editItem'})}}>
                    <td>{r.uid}</td>
                    <td>{moment(r.date).format('MMM D, YYYY')}</td>
                    <td>{moment(`2000-01-01 ${r.timeScheduled}`).format('h:mm A')}</td>
                    <td>{workArea}</td>
                    <td>{vehicle}</td>
                    <td>{sdUser ? sdUser.firstName + ' ' + sdUser.lastName + ' (' + r.secondaryDriverType + ')' : 'None'}</td>
                    <td style={{maxWidth: 200, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{r.notes}</td>
                    <td>{moment(r.submissionDate).format('MMM D, YYYY')}</td>
                </tr>
            )
        });

        const nonRoutes = this.state.scheduleItems.filter(r => r.scheduleType !== 'route');

        const nonRouteRows = nonRoutes.map((r) => {
            return (
                <tr key={r.uid} className='cursor-pointer' onClick={() => {this.setState({selectedItem: r, modalSwitch: 'editItem'})}}>
                    <td>{r.uid}</td>
                    <td>{scheduleTypeDictionary[r.scheduleType].label}</td>
                    <td>{moment(r.date).format('MMM D, YYYY')}</td>
                    <td>{r.endDate ? moment(r.endDate).format('MMM D, YYYY') : ''}</td>
                    <td>{r.timeScheduled}</td>
                    <td style={{maxWidth: 200, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{r.notes}</td>
                    <td>{moment(r.submissionDate).format('MMM D, YYYY')}</td>
                </tr>
            )
        });

        const calendarRows = [0, 1, 2, 3, 4, 5].map((rowIndex) => {
    
            const columns = this.getDaysOfTheWeek(moment(this.state.selectedDate).add(rowIndex, 'weeks')).map((date, colIndex) => {

                const isOutsideDateRange = !moment(date).isBetween(this.state.startDate ? moment(this.state.startDate) : moment('1000-01-01'), this.state.endDate ? moment(this.state.endDate) : moment('3000-01-01'), 'days', '[]');
    
                const scheduleItemsOnDay = isOutsideDateRange ? [] : this.state.scheduleItems.filter((entry) => {
                    if (entry.endDate) {
                        return entry.date === date.format('YYYY-MM-DD') || date.isBetween(moment(entry.date), moment(entry.endDate), 'day', '[]');
                    } else {
                        return entry.date === date.format('YYYY-MM-DD');
                    }
                });
    
                let calendarObjects = scheduleItemsOnDay.map((data) => {
                    return <CalendarObject popup={rowIndex > 2} key={data.uid} object={data} users={this.state.users} workAreas={this.state.workAreas} vehicles={this.state.vehicles} onClick={() => {this.setState({modalSwitch: 'editItem', selectedItem: data})}}/>;
                });
                
                calendarObjects = calendarObjects.filter((obj) => {
                    return obj !== '';
                })
    
                return (
                    <td key={colIndex} style={{padding: 0, width: '14.2%'}} className={'calendar-td' + (date.format('MMM D') === moment().format('MMM D') ? ' calendar-td-selected' : '') + (date.format('MMM') !== this.state.selectedDate.format('MMM') ? ' calendar-td-off-month' : '')}>
                        <div style={{position: 'relative', display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-between', padding: 4, backgroundColor: date.format('MMM D') === moment().format('MMM D') ? 'rgba(238, 255, 0, 0.3)' : ''}}>
                            <p style={{alignSelf: 'flex-end', fontSize: '1.5vmax', marginBottom: 0, opacity: isOutsideDateRange ? 0.2 : 1}}>{date.format('D')}</p>
                            <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                                {calendarObjects}
                            </div>
                            { isOutsideDateRange ?
                                <div style={{position: 'absolute', zIndex: 1, top: 0, left: 0, right: 0, bottom: 0, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <p style={{marginBottom: 0, fontWeight: 'bold'}}>OUTSIDE DATE RANGE</p>
                                </div>

                            : ''}
                        </div>
                    </td>
                );
            });
    
            return (
                <tr key={rowIndex} style={{height: '16.66%'}}>
                    {columns}
                </tr>
            );
        });
    
        const calendarHeaders = ['Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri'];

        
        
        

        const content = (
            <>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 12, marginBottom: 12}}>
                    <ButtonGroup>
                        <Button 
                            style={this.state.viewSwitch !== 'table' ? {backgroundColor: 'white', color: 'var(--bs-primary)'} : {}}
                            onClick={() => {this.setState({viewSwitch: 'table'})}}
                            >
                            Table View
                        </Button>
                        <Button 
                            style={this.state.viewSwitch !== 'calendar' ? {backgroundColor: 'white', color: 'var(--bs-primary)'} : {}} 
                            onClick={() => {this.setState({viewSwitch: 'calendar'})}}
                            >
                            Calendar View
                        </Button>
                    </ButtonGroup>

                    <Button onClick={() => {this.setState({modalSwitch: 'dateRangePicker'})}}>
                        {dateRangeToString(this.state.startDate, this.state.endDate)}
                    </Button>
                    <Button style={{color: 'white'}} onClick={() => this.setState({modalSwitch: 'editItem', selectedItem: undefined})}>Add Schedule Item</Button>
                </div>
                { this.state.viewSwitch === 'table' ?
                    <>
                        <QuickTable title='Routes' headers={['uid', 'Date', 'Time Scheduled', 'Work Area', 'Vehicle', 'Secondary Driver', 'Notes', 'Submission Date']} rows={routeRows} hover/>
                        <QuickTable title='Other Schedule Items' headers={['uid', 'Schedule Type', 'Date', 'End Date', 'Time Scheduled', 'Notes', 'Submission Date']} rows={nonRouteRows} hover/>
                    </>
                    :
                    <>
                        <div style={{display: 'flex', flexDirection: 'row', padding: 8, alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                            <button type='button' className='button-primary' onClick={() => this.setState({selectedDate: moment(this.state.selectedDate).subtract(1, 'month')})}>
                                <FontAwesomeIcon icon={faArrowLeft}/>
                            </button>
                            <Form.Control style={{marginRight: 12, marginLeft: 12, maxWidth: 300}} type='month' value={this.state.selectedDate.format('YYYY-MM')} onChange={(event) => {this.setState({selectedDate: moment(event.target.value).startOf('month')})}}/>
                            <button type='button' className='button-primary' onClick={() => this.setState({selectedDate: moment(this.state.selectedDate).add(1, 'month')})}>
                                <FontAwesomeIcon icon={faArrowRight}/>
                            </button>
                        </div>
                        <QuickTable headers={calendarHeaders} rows={calendarRows}/>
                    </>
                }
                
            </>
        )

        return (
            <>
                {this.state.isLoading ? <CustomSpinner spinnerHeight='10vh' spinnerWidth='10vh' height='50vh'/> : content}
                <Modal show={this.state.modalSwitch === 'editItem'} onHide={this.hideModal} size='lg'>
                    <HRUserScheduleItemEditor selectedItem={this.state.selectedItem} handleCrud={this.handleCrud} companyUserIdentifier={this.props.companyUserUid} users={this.state.users} workAreas={this.state.workAreas} vehicles={this.state.vehicles} loadData={this.loadData}/>
                </Modal>
                <DateRangeSelector show={this.state.modalSwitch === 'dateRangePicker'} onHide={this.hideModal} centered handleSetDateRange={this.handleSetDateRange} presetOptions={['pastYear', 'pastMonth', 'pastWeek', 'nextWeek', 'nextMonth', 'nextYear', 'allTime']}/>
            </>
        )
    }
}

export default HRUserSchedule;