/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from "../serviceTools";
import { timeout } from "../../tools";
import { getFakeUID } from "../../fake-data";

export async function getCompanyVehicles(companyIdentifier) {
    const data = {
        type: 'company',
        target: 'getVehicles',
        authToken: getToken(),
        companyIdentifier: companyIdentifier
    }

    const response = await getResponse(data);
    return response;
}

export async function createVehicle(companyIdentifier, vehicle) {
    const data = {
        type: 'company',
        target: 'createVehicle',
        authToken: getToken(),
        companyIdentifier: companyIdentifier,
        data: vehicle,
    }

    const response = await getResponse(data);
    return response;
}
export async function updateVehicle(companyIdentifier, vehicle) {
    const data = {
        type: 'company',
        target: 'updateVehicle',
        authToken: getToken(),
        companyIdentifier: companyIdentifier,
        data: vehicle,
    }

    const response = await getResponse(data);
    return response;
}
export async function deleteVehicle(uid) {
    const data = {
        type: 'company',
        target: 'deleteVehicle',
        authToken: getToken(),
        uid: uid,
    }

    const response = await getResponse(data);
    return response;
}