/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import Decoder from "../../../decoding";
import { validateDecimal, validateBig, validateInteger, validateUsd } from "../payrollTools";
import { getFakeUID } from "../../../tools";
import Big from "big.js";

export default class Pay {
    id;
    payType;
    payRate;
    unitsWorked;
    payStart;
    payEnd;

    constructor(payType, payRate, unitsWorked, payStart, payEnd) {
        this.id = getFakeUID();
        this.payType = payType;
        this.payRate = payRate;
        this.unitsWorked = unitsWorked;
        this.payEnd = payEnd;
        this.payStart = payStart;
    }

    static initDefault() {
        return new Pay(0, 0.0, 0, 'ERR', 'ERR');
    }

    static decode(json) {
        const decoder = new Decoder(json);

        const payType = decoder.decode('payType', Decoder.Integer);
        const payRate = decoder.decode('payRate', Decoder.Decimal);
        const unitsWorked = decoder.decode('unitsWorked', Decoder.Decimal);
        const payStart = decoder.decode('payStart', Decoder.StringStrict);
        const payEnd = decoder.decode('payEnd', Decoder.StringStrict);

        if (decoder.checkForErrors()) {
            return new Pay(payType, payRate, unitsWorked, payStart, payEnd);
        } else {
            return Pay.initDefault();
        }
    }


    encode() {
        return {
            payType: this.payType,
            payRate: validateDecimal(this.payRate),
            unitsWorked: validateDecimal(this.unitsWorked),
            payStart: this.payStart,
            payEnd: this.payEnd
        }
    }

    duplicate() {
        return new Pay(this.payType, this.payRate, this.unitsWorked, this.payStart, this.payEnd);
    }

    payPeriodLength() {
        return moment(this.payEnd).diff(moment(this.payStart), 'days') + 1;
    }

    getWages() {
        if (this.payType == 0) {
            return validateBig(this.payRate).times(this.unitsWorked);
        } else if (this.payType > 0) {
            return validateBig(this.payRate).div(52).times(validateBig(this.payPeriodLength()).div(7));
        } else {
            return new Big(0);
        }
    }
    
}