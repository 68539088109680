/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {getHRUserPto} from '../../../services/HRServices/HRPtoService';
import moment from 'moment';
import QuickTable from '../../../components/QuickTable';
import { Button } from 'react-bootstrap';
import HRUserPtoModal from '../HRUserPTO/HRUserPtoModal';
import CustomSpinner from '../../../components/CustomSpinner';
import HRCreateScheduleItemModal from '../HRUserSchedule/HRCreateScheduleItemModal';
import CustomButton from '../../../components/CustomButton';
import DateRangeSelector from '../../../components/DateRangeSelector';
import { dateIsInRange, dateRangeToString } from '../../../tools';

export default class HRUserPto extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            modalSwitch: '',
            transactions: [],
            selectedTransaction: undefined,
            startDate: props.startDate,
            endDate: props.endDate,
            showDateRangeSelector: false,
        };
        this.handleSetSelectedDateRange = this.handleSetSelectedDateRange.bind(this);
        this.handleTransactionCrud = this.handleTransactionCrud.bind(this);
    }


    sortTransactions(a, b) {
        if (a.dateandtime < b.dateandtime) {
            return 1;
        } else if (a.dateandtime > b.dateandtime) {
            return -1;
        } else {
            return 0;
        }
    }

    sortScheduledTransactions(a, b) {
        if (a.scheduleItem.date < b.scheduleItem.date) {
            return 1;
        } else if (a.scheduleItem.date > b.scheduleItem.date) {
            return -1;
        } else {
            return 0;
        }
    }

    sortAccruedTransactions(a, b) {
        if (a.payrollPeriod.periodStart < b.payrollPeriod.periodStart) {
            return 1;
        } else if (a.payrollPeriod.periodStart > b.payrollPeriod.periodStart) {
            return -1;
        } else {
            return 0;
        }
    }

    async loadData() {
        this.setState({isLoading: true});
        const response = await getHRUserPto(this.props.user.companyUserUid);
        if (response.status === '200') {
            this.setState({transactions: response.data});
        }
        this.setState({isLoading: false});
    }

    componentDidMount() {
        this.loadData(false);
    }

    handleTransactionCrud(type, data) {
        let newArray = Array.from(this.state.transactions);
        if (type === 'create') {
            newArray.push(data);
        } else if (type === 'update') {
            newArray = newArray.filter(t => t.uid != data.uid);
            newArray.push(data);
        } else if (type === 'delete') {
            newArray = newArray.filter(t => t.uid != data.uid);
        }
        this.setState({modalSwitch: '', transactions: newArray});
        if (this.props.handleRecalculate) {
            this.props.handleRecalculate();
        }
    }

    handleSetSelectedDateRange(startDate, endDate) {
        this.setState({startDate: startDate, endDate: endDate});
    }

    

    render() {

        const balance = this.state.transactions.reduce((prev, curr) => {
            return prev + curr.number;
        }, 0);

  
        const scheduledPtoRows = this.state.transactions.filter(i => i.scheduleItem && dateIsInRange(i.scheduleItem.date, this.state.startDate, this.state.endDate)).sort(this.sortScheduledTransactions).map((item) => {
            return (
                <tr className='cursor-pointer' key={item.uid} onClick={() => {this.setState({selectedTransaction: item, modalSwitch: 'transactionEditor'})}}>

                    <td>{moment(item.scheduleItem.date).format('MMM D, YYYY')}</td>
                    <td>{moment(item.dateandtime).format('MMM D, YYYY [at] h:mm A')}</td>
                    <td style={{color: item.number > 0 ? 'green' : 'red'}}>{item.number > 0 ? `+${item.number}` : item.number}</td>
                    <td>{item.payrollPeriod ? `${moment(item.payrollPeriod.periodStart).format('MMM D, YYYY')} - ${moment(item.payrollPeriod.periodEnd).format('MMM D, YYYY')}` : ''}</td>
                    <td style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 200, overflowX: 'hidden'}}>{item.notes}</td>
                </tr>
            );
        });

        const manualPtoRows = this.state.transactions.filter(i => !i.scheduleItem && !i.payrollPeriod).sort(this.sortTransactions).map((item) => {
            return (
                <tr className='cursor-pointer' key={item.uid} onClick={() => {this.setState({selectedTransaction: item, modalSwitch: 'transactionEditor'})}}>
                    <td>{moment(item.dateandtime).format('MMM D, YYYY [at] h:mm A')}</td>
                    <td style={{color: item.number > 0 ? 'green' : 'red'}}>{item.number > 0 ? `+${item.number}` : item.number}</td>
                    <td style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 200, overflowX: 'hidden'}}>{item.notes}</td>
                </tr>
            );
        });

        const accruedPtoRows = this.state.transactions.filter(i => !i.scheduleItem && i.payrollPeriod).sort(this.sortAccruedTransactions).map((item) => {
            return (
                <tr key={item.uid}>
                    <td>{`${moment(item.payrollPeriod.periodStart).format('MMM D, YYYY')} - ${moment(item.payrollPeriod.periodEnd).format('MMM D, YYYY')}`}</td>
                    <td style={{color: 'green'}}>{`+${item.number}`}</td>
                </tr>
            );
        })

        const content = (
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 12}}>
                    <span>Current PTO Balance: <b>{`${balance} Hours`}</b></span>
                    <div style={{display: 'flex', gap: 12}}>
                        <Button variant='outline-primary' onClick={() => {this.setState({showDateRangeSelector: true})}}>Viewing Scheduled PTO for: <b>{dateRangeToString(this.state.startDate, this.state.endDate)}</b></Button>
                        <DateRangeSelector handleSetDateRange={this.handleSetSelectedDateRange} show={this.state.showDateRangeSelector} onHide={() => {this.setState({showDateRangeSelector: false})}} presetOptions={['pastYear', 'pastMonth', 'pastWeek', 'nextWeek', 'nextMonth', 'nextYear', 'allTime']}/>
                        <CustomButton label='Schedule Time Off' onClick={() => {this.setState({modalSwitch:'showCreateTimeOff'})}}/>
                        <Button variant={'outline-primary'} onClick={() => {this.setState({ selectedTransaction: null, modalSwitch: 'transactionEditor'})}}>
                            Add/Subtract PTO
                        </Button>
                    </div>
                </div>
                <div>
                    <h3>PTO Transaction History</h3>
                    <div style={{maxHeight: 250, overflowY: 'scroll', marginBottom: 12}}>
                        <QuickTable 
                            noMargin
                            title='Scheduled PTO'
                            headers={['Date of PTO', 'Date Approved', 'Amount (Hours)', 'Payroll Period Paid', 'Notes']}
                            rows={scheduledPtoRows}
                            hover
                        />
                    </div>
                    <div style={{maxHeight: 250, overflowY: 'scroll', marginBottom: 12}}>
                        <QuickTable 
                            noMargin
                            title='Manually Entered Transactions'
                            headers={['Date Created', 'Amount (Hours)', 'Notes']}
                            rows={manualPtoRows}
                            hover
                        />
                    </div>
                    <div style={{maxHeight: 250, overflowY: 'scroll', marginBottom: 12}}>
                        <QuickTable 
                            noMargin
                            title='Accrued PTO'
                            headers={['Payroll Period Earned', 'Amount (Hours)']}
                            rows={accruedPtoRows}
                            hover
                        />
                    </div>
                </div>
                <Modal show={this.state.modalSwitch === 'transactionEditor'} onHide={() => {this.setState({modalSwitch: ''})}} size='lg'>
                    <HRUserPtoModal
                        handleTransactionCrud={this.handleTransactionCrud}
                        user={this.props.user}
                        selectedTransaction={this.state.selectedTransaction}
                    />
                </Modal>
                <Modal show={this.state.modalSwitch === 'showCreateTimeOff'} onHide={() => {this.setState({modalSwitch: ''})}} size='lg'>
                    <HRCreateScheduleItemModal companyIdentifier={this.props.companyIdentifier} companyUserIdentifier={this.props.user.companyUserUid} handleAddPTO={() => {
                        this.setState({modalSwitch: ''});
                        this.loadData();
                        if (this.props.handleRecalculate) {
                            this.props.handleRecalculate();
                        }
                    }}/>
                </Modal>
            </div>
        )

        return this.state.isLoading ? <CustomSpinner spinnerHeight='10vh' spinnerWidth='10vh' height='50vh'/> : content;
    }
}
