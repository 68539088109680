/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from "./serviceTools";

export async function checkAuthentication() {
    const data = {
        type: 'authentication',
        target: 'checkAuthentication',
        authToken: getToken()
    }

    const response = await getResponse(data);
    
    return response;
}

export async function login(username, password) {
    const data = {
        type: 'authentication',
        target: 'authenticate',
        username: username,
        password: password
    }
    return await getResponse(data);
}

export async function authenticateMFA(tokenIdentifier, code) {
    const data = {
        type: 'authentication',
        target: 'mfa',
        tokenIdentifier: tokenIdentifier,
        code: code
    }
    return await getResponse(data);
}

export async function logout() {
    const data = {
        type: 'authentication',
        target: 'logout',
        authToken: getToken(),
    }
    return await getResponse(data);
}