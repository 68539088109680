/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useEffect, useState} from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import { getAllTimeOff } from '../../../services/HRServices/HRScheduleService';
import { scheduleTypeDictionary } from '../../../tools';
import QuickTable from '../../../components/QuickTable';
import PageSpinner from '../../../components/PageSpinner';

export default function HRTimeOffModal({companyIdentifier, startDate, endDate}) {
    const [isLoading, setIsLoading] = useState(false);
    const [timeOff, setTimeOff] = useState([]);


    const sortTimeOff = (a, b) => {
        if (a.date < b.date) {
            return -1;
        } else if (a.date > b.date) {
            return 1;
        } else {
            return 0;
        }
    }

    const loadData = async () => {
        setIsLoading(true);
        const response = await getAllTimeOff(companyIdentifier, startDate, endDate);
        if (response.status === '200') {
            setTimeOff(response.data.sort(sortTimeOff));
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadData();
    }, [])


    const timeOffElements = timeOff.map((item) => {
        return (
            <tr key={item.uid}>
                <td>{`${item.user?.lastName}, ${item.user?.firstName} ${(item.user?.middleName ?? '').substring(0, 1)}`}</td>
                <td>{scheduleTypeDictionary[item.scheduleType].label}</td>
                <td>{item.scheduleType === 'requestOff' ? `${moment(item.date).format('MMM D')} - ${moment(item.endDate).format('MMM D')}` : moment(item.date).format('MMM D')}</td>
            </tr>
        )
    })

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{`All Time-Off Between ${moment(startDate).format('MMM D, YYYY')} - ${moment(endDate).format('MMM D, YYYY')}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { isLoading ? <PageSpinner/> :
                    <QuickTable headers={['Name', 'Type', 'Date(s)']} rows={timeOffElements}/>
                }
            </Modal.Body>
        </>
    )
}