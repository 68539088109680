/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import moment from 'moment';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import CustomButton from '../../../components/CustomButton';
import CustomControl from '../../../components/CustomControl';
import TextAreaControl from '../../../components/TextAreaControl';
import { validateExistence, ValidationGroup } from '../../../validation';
import { createPtoTransaction, deletePtoTransaction, updatePtoTransaction } from '../../../services/HRServices/HRPtoService';
import { validateDecimal } from '../../../tools';
import { Button } from 'react-bootstrap';
import AlertModal from '../../../components/AlertModals/AlertModal';

export default function HRUserPtoModal({user, selectedTransaction, handleTransactionCrud}) {
    const [notes, setNotes] = useState(selectedTransaction ? selectedTransaction.notes : '');
    const [ptoAmount, setPtoAmount] = useState(selectedTransaction ? selectedTransaction.number : '');
    const [isLoading, setIsLoading] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    const validationGroup = new ValidationGroup();

    const handleSubmit = async () => {
        setIsLoading(true);
        const transaction = {
            ...selectedTransaction,
            companyUserIdentifier: user.companyUserUid,
            dateandtime: moment().format('YYYY-MM-DD HH:mm:ss'),
            number: validateDecimal(ptoAmount),
            notes: notes,
        };
        if (selectedTransaction) {
            const response = await updatePtoTransaction(transaction);
            if (response.status === '200') {
                handleTransactionCrud('update', transaction);
            }
        } else {
            const response = await createPtoTransaction(transaction);
            if (response.status === '200') {
                transaction.uid = response.uid;
                handleTransactionCrud('create', transaction);
            }
        }
        setIsLoading(false);
    };

    const handleDelete = async () => {
        if (selectedTransaction) {
            const response = await deletePtoTransaction(selectedTransaction.uid);
            if (response.status === '200') {
                handleTransactionCrud('delete', selectedTransaction);
                return true;
            }
        }
        return false;
    }

    return (
        <>
            <Modal.Header closeButton style={{gap: 12}}>
                <Modal.Title>{selectedTransaction ? 'Edit PTO Transaction' : 'Create PTO Transaction'}</Modal.Title>
                <Button variant='outline-primary' onClick={() => {setShowDelete(true)}}>Delete Transaction</Button>
            </Modal.Header>
            <Modal.Body>
                <div style={{ paddingBottom: 10 }}>
                    <CustomControl
                        type='number'
                        maxInt={9999}
                        value={ptoAmount}
                        onChange={(event) => {setPtoAmount(event.target.value)}}
                        title='PTO Amount'
                        disabled={isLoading}
                        validator={validationGroup.createValidator(()=>validateExistence(ptoAmount))}
                    />
                </div>
                <div>
                    <TextAreaControl
                        rows={10}
                        value={notes}
                        setValue={(value) => {setNotes(value)}}
                        label='Transaction Notes'
                        disabled={isLoading}
                        validator={validationGroup.createValidator(()=>validateExistence(notes))}
                    />
                </div>
            </Modal.Body>
            
            <Modal.Footer>
                <CustomButton
                    onClick={handleSubmit}
                    isLoading={isLoading}
                    label={selectedTransaction ? 'Save Changes' : 'Submit'}
                    disabled={!validationGroup.isValid()}
                />
            </Modal.Footer>
            
            <AlertModal show={showDelete} onHide={() => {setShowDelete(false)}} callBack={handleDelete} title='Delete PTO Transaction?' message='This cannot be undone. If this transaction is associated with scheduled PTO, the scheduled item will be deleted as well.' buttonLabel='Delete Transaction'/>
        </>
    );
}
