/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form'
import { wrapElementInCol } from '../tools';

export default function CustomCheck({forceTouched, selection, setSelection, originalValue, optionNames, optionValues, inline, title, disabled, bordered = true, validator, xs, sm, md, lg, xl, xxl}) {
    const [touched, setTouched] = useState(false);


    const handleSetSelection = (value) => {
        if (selection.includes(value)) {
            setSelection(selection.filter(e => e != value));
        } else {
            const newSelection = Array.from(selection);
            newSelection.push(value);
            setSelection(newSelection);
        }
    }

    const radioButtons = optionValues.map((optionValue, index) => {
        return (
            <Form.Check onFocus={() => {setTouched(true)}} disabled={disabled} inline={inline} key={optionValue} type='checkbox' label={optionNames[index]} checked={selection.includes(optionValue)} onChange={() => handleSetSelection(optionValue)}/>
        )
    });

    const content = (
        <Form.Group>
            { title ? <Form.Label>{title}</Form.Label> : ''}
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: bordered ? '1px solid ' + (validator && validator.isValid() ? 'green' : validator  ? 'rgb(209, 30, 54)' : 'lightgray') : '', borderRadius: 6, padding: bordered ? 12 : 0, backgroundColor: originalValue && originalValue !== selection ? '#faffe3' : ''}}>
                <div>
                    {radioButtons}
                </div>
                { validator && validator.isValid() ?
                <FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/>
                : validator ? <FontAwesomeIcon icon={faExclamationCircle} style={{color: 'rgb(209, 30, 54)'}}/>
                : ''
                }
            </div>
            
        </Form.Group>
    );

    return (
        <>
            {xs || sm || md || lg || xl || xxl ? wrapElementInCol(content, {xs: xs, sm: sm, md: md, lg: lg, xl: xl, xxl: xxl}) : content}
        </>
    )
}