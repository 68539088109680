/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from '../serviceTools';

export async function getHRUserPtoBalance(companyUserUid) {
    var data = {
        type: 'hr',
        target: 'getPtoBalance',
        authToken: getToken(),
        companyUserIdentifier: companyUserUid,
    };

    const response = await getResponse(data);

    return response;
}

export async function addHRUserPto(transaction) {
    var data = {
        type: 'hr',
        target: 'addPto',
        authToken: getToken(),
        companyUserIdentifier: transaction.companyUserIdentifier,
        number: transaction.number,
        notes: transaction.notes,
    };
    const response = await getResponse(data);

    return response;
}

export async function createPtoTransaction(transaction) {
    var data = {
        type: 'hr',
        target: 'createPtoTransaction',
        authToken: getToken(),
        companyUserIdentifier: transaction.companyUserIdentifier,
        number: transaction.number,
        notes: transaction.notes
    }
    return await getResponse(data);
}

export async function getHRUserPto(companyUserUid) {
    var data = {
        type: 'hr',
        target: 'getPto',
        authToken: getToken(),
        companyUserIdentifier: companyUserUid,
    };

    return await getResponse(data);
}

export async function updatePtoTransaction(transaction) {
    var data = {
        type: 'hr',
        target: 'updatePto',
        authToken: getToken(),
        pto: transaction
    }
    return await getResponse(data);
}

export async function deletePtoTransaction(uid) {
    var data = {
        type: 'hr',
        target: 'deletePto',
        authToken: getToken(),
        uid: uid
    }
    return await getResponse(data);
}