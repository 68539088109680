/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState, useEffect } from "react";
import CustomControl from "../../components/CustomControl";
import SwitchControl from "../../components/SwitchControl";
import { getCompanySettings, updateCompanySettings } from "../../services/CompanyServices/CompanySettingsService";
import PageSpinner from "../../components/PageSpinner";
import LoadingButton from "../../components/LoadingButton";
import CustomButton from "../../components/CustomButton";
import { validateInteger } from "../../tools";
import { Form } from "react-bootstrap";
import RadioControl from "../../components/RadioControl";

export default function CompanySettings({selectedCompany}) {

    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [companySettings, setCompanySettings] = useState(undefined)
    const [originalCompanySettings, setOriginalCompanySettings] = useState(undefined);
    const [failedToSave, setFailedToSave] = useState(false);

    const loadData = async () => {
        setIsLoading(true);
        const response = await getCompanySettings(selectedCompany.uid);
        if (response.status === '200') {
            setCompanySettings(response.settings);
            setOriginalCompanySettings(response.settings);
        }
        setIsLoading(false);
    }


    useEffect(() => {
        loadData();
    }, [])

    const handleSetSettings = (key, value) => {
        const newObj = structuredClone(companySettings);
        newObj[key] = value;
        setCompanySettings(newObj);
    }

    const handleSaveChanges = async () => {
        setIsSaving(true);
        const response = await updateCompanySettings(companySettings);
        if (response.status === '200') {
            setOriginalCompanySettings(companySettings);
        }
        setIsSaving(false);
    }

    const content = !companySettings ? '' : (
        <div style={{display: 'flex', flexDirection: 'column', gap: 16}}>
            <SwitchControl 
                originalValue={originalCompanySettings.companyAddressAllowClockIn} 
                title='Include company address as a valid clock-in location for employees' 
                value={companySettings.companyAddressAllowClockIn} setValue={(value) => {handleSetSettings('companyAddressAllowClockIn', value)}}
            />
            
            { companySettings.companyAddressAllowClockIn === 1 &&
                <div style={{width: '100%', paddingLeft: 24, borderLeft: '1px solid lightgray'}}>
                    <CustomControl
                        type='number' 
                        title='Company address clock-in radius (in miles)' 
                        originalValue={originalCompanySettings.companyAddressClockInRadius} 
                        value={companySettings.companyAddressClockInRadius} 
                        onChange={(event) => {handleSetSettings('companyAddressClockInRadius', event.target.value)}} 
                    />
                </div>
            }

            <CustomControl
                type='number' 
                title='Set number of days drivers can see when viewing their schedule in TTA App' 
                maxInt={365}
                originalValue={originalCompanySettings.driverSchedulePreviewDays} 
                value={companySettings.driverSchedulePreviewDays} 
                onChange={(event) => {handleSetSettings('driverSchedulePreviewDays', event.target.value)}} 
            />
            <SwitchControl
                title='Send AO and BCs a text when a new employee logs in for the first time'
                originalValue={originalCompanySettings.sendFirstLoginText}
                value={companySettings.sendFirstLoginText} 
                setValue={(value) => {handleSetSettings('sendFirstLoginText', value)}}
            />

            <SwitchControl 
                originalValue={originalCompanySettings.allowBCAccessToSensitiveInformation} 
                title='Allow business contacts to access sensitive information ( this will force MFA for business contacts)' 
                value={companySettings.allowBCAccessToSensitiveInformation} setValue={(value) => {handleSetSettings('allowBCAccessToSensitiveInformation', value)}}
            />
            <SwitchControl 
                originalValue={originalCompanySettings.forceMfa} 
                title='Force all employees to use MFA' 
                value={companySettings.forceMfa} setValue={(value) => {handleSetSettings('forceMfa', value)}}
            />
            <SwitchControl 
                originalValue={originalCompanySettings.forceLocation} 
                title='Force employees to enable location services when clocking in and out' 
                value={companySettings.forceLocation} setValue={(value) => {handleSetSettings('forceLocation', value)}}
            />
            { companySettings.forceLocation == 1 &&
                <div style={{width: '100%', paddingLeft: 24, borderLeft: '1px solid lightgray'}}>
                    <SwitchControl 
                        originalValue={originalCompanySettings.enableGeofencing} 
                        title='Enable Geofencing (require employees to be within radius of CSA to clock in)' 
                        value={companySettings.enableGeofencing} setValue={(value) => {handleSetSettings('enableGeofencing', value)}}
                    />
                </div>
            }
            { companySettings.payrollEnabled == 1 &&
                <SwitchControl 
                    title={'Include AOs in payroll by default'}
                    originalValue={originalCompanySettings.includeAOsInPayroll}
                    value={companySettings.includeAOsInPayroll}
                    setValue={(value)=>{handleSetSettings('includeAOsInPayroll', value)}} 
                />
            }
            { companySettings.payrollEnabled == 1 &&
                <SwitchControl 
                    title={'Show BCs Payroll History'}
                    originalValue={originalCompanySettings.showBcsPayrollHistory}
                    value={companySettings.showBcsPayrollHistory}
                    setValue={(value)=>{handleSetSettings('showBcsPayrollHistory', value)}} 
                />
            }
            <SwitchControl 
                title='Limit the number of employees who can request time off on a given day'
                originalValue={originalCompanySettings.requestOffPerDayLimit ?? false}
                value={companySettings.requestOffPerDayLimit ?? false}
                setValue={(value)=>{handleSetSettings('requestOffPerDayLimit', companySettings.requestOffPerDayLimit == null ? 1 : null)}} 
            />
            { companySettings.requestOffPerDayLimit != null &&
                <div style={{width: '100%', paddingLeft: 24, borderLeft: '1px solid lightgray'}}>
                    <span style={{fontSize: 13, opacity: 0.6}}>{'Maximum number of employees who can request time off on a given day'}</span>
                    <div style={{display: 'flex', gap: 8, border: '1px solid lightgray', borderRadius: 6, alignItems: 'center', maxWidth: 600, paddingRight: 6}}>
                        <div style={{padding: 6, borderTopLeftRadius: 6, borderBottomLeftRadius: 6, backgroundColor: 'var(--bs-primary)', borderRight: '1px solid lightgray'}}>
                            <span style={{whiteSpace: 'nowrap', color: 'white'}}>{`${companySettings.requestOffPerDayLimit} Employee${companySettings.requestOffPerDayLimit > 1 ? 's' : ''}`}</span>
                        </div>
                        <Form.Range style={{}} min={1} max={9} value={companySettings.requestOffPerDayLimit} onChange={(event) => {handleSetSettings('requestOffPerDayLimit', validateInteger(event.target.value))}}/>
                    </div>

                </div>
            }

            <SwitchControl 
                originalValue={originalCompanySettings.restrictBcsToCsa} 
                title='Restrict BCs to their own CSAs' 
                value={companySettings.restrictBcsToCsa} setValue={(value) => {handleSetSettings('restrictBcsToCsa', value)}}
            />
            <SwitchControl 
                originalValue={originalCompanySettings.payrollEnabled} 
                title='Payroll Enabled' 
                value={companySettings.payrollEnabled} setValue={(value) => {handleSetSettings('payrollEnabled', value)}}
            />
            <SwitchControl 
                originalValue={originalCompanySettings.generatePayrollForEachCsa} 
                title='Run payroll separately for each CSA' 
                value={companySettings.generatePayrollForEachCsa} setValue={(value) => {handleSetSettings('generatePayrollForEachCsa', value)}}
            />
            <span style={{fontSize: 13, opacity: 0.6}}>{'Payroll Period Length'}</span>
            <div style={{display: 'flex', gap: 8, border: '1px solid lightgray', borderRadius: 6, alignItems: 'center', maxWidth: 600, paddingRight: 6, backgroundColor: originalCompanySettings.payrollPeriodLength != companySettings.payrollPeriodLength ? '#faffe3' : 'transparent'}}>
                <div style={{padding: 6, borderTopLeftRadius: 6, borderBottomLeftRadius: 6, backgroundColor: 'var(--bs-primary)', borderRight: '1px solid lightgray'}}>
                    <span style={{whiteSpace: 'nowrap', color: 'white'}}>{`${companySettings.payrollPeriodLength} Day${companySettings.payrollPeriodLength > 1 ? 's' : ''}`}</span>
                </div>
                <Form.Range style={{}} step={7} min={7} max={28} value={companySettings.payrollPeriodLength} onChange={(event) => {handleSetSettings('payrollPeriodLength', validateInteger(event.target.value))}}/>
            </div>
            <RadioControl 
                originalValue={originalCompanySettings.payrollPeriodStartDate} 
                title='Payroll Period Start Date' 
                inline
                optionValues={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
                optionNames={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
                selection={companySettings.payrollPeriodStartDate} 
                setSelection={(value) => {handleSetSettings('payrollPeriodStartDate', value)}}
            />
            

            <CustomButton label='Save Changes' style={{alignSelf: 'flex-end'}} isLoading={isSaving} onClick={handleSaveChanges}/>
        </div>
    )

    return (
        <>
            {isLoading ? <PageSpinner/> : content}
        </> 
    )
}