/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { faArrowDown, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import instructions1 from '../../../../assets/excel-to-csv-instructions1.png';
import instructions2 from '../../../../assets/excel-to-csv-instructions2.png';
import moment from "moment";
import CustomButton from "../../../../components/CustomButton";
import { submitSettlementReport } from "../../../../services/ReportsService";
import RadioControl from "../../../../components/RadioControl";
import { ValidationGroup, validateExistence } from "../../../../validation";
import SettlementEmployeeMatchModal from "./SettlementEmployeeMatchModal";

export default function SettlementReportUploadModal({hideModal, handleSetSettlementReportData, csas, userData, handleUpdateUserFedexIds, payrollView}) {
    const fileRef = useRef();
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showEmployeeMatch, setShowEmployeeMatch] = useState(false);

    const [error, setError] = useState('Please upload your Fedex report');
    const [touched, setTouched] = useState(false);

    const [selectedCsa, setSelectedCsa] = useState(undefined);
 
    const [sReport, setSReport] = useState(undefined);
    const [showUploadButton, setShowUploadButton] = useState(false);

    const handleFileOnChange = () => {
        if (!touched) {
            setTouched(true);
        }
        const file = fileRef.current.files[0];
        if (file) {
            setShowUploadButton(true);
            const indexOfDot = file.name.lastIndexOf('.');
            const fileType = file.name.slice(indexOfDot + 1);
            if (fileType === 'csv') {
                setError(undefined);
                return;
            } else {
                setError('Invalid file type. Please upload a .csv file.');
            }
        } else {
            setError('Please upload your Fedex report');
        }
    }

    const csaNames = csas.map((csa)=>csa.csaName);
    const csaIdentifiers = csas.map((csa)=>csa.uid);


    const monthKey = {
        'JAN': '01',
        'FEB': '02',
        'MAR': '03',
        'APR': '04',
        'MAY': '05',
        'JUN': '06',
        'JUL': '07',
        'AUG': '08',
        'SEP': '09',
        'OCT': '10',
        'NOV': '11',
        'DEC': '12'
    }
    const decodeFedexDate = (date) => {
        const day = date.substring(0, 2);
        const month = date.substring(3, 6);
        const year = date.substring(7, 11);
        const newString = year + '-' + monthKey[month] + '-' + day;
        return newString;
    }



    const handleMatchEmployees = (array) =>{
       
        const newReport = structuredClone(sReport);

        array.forEach((o)=>{
            const matches = newReport.days.filter((d)=>d.fedexId === o.fedexId);
            matches.forEach((match) => {
                match.companyUserIdentifier = o.companyUserIdentifier;
            })
        });
        newReport.isComplete = !newReport.days.find((d)=>!d.companyUserIdentifier);
        setSReport(newReport);
    }


    const handleUpload = () => {
        setShowUploadButton(false);
        const file = fileRef.current.files[0];
        if (file) {
            const indexOfDot = file.name.lastIndexOf('.');
            const fileType = file.name.slice(indexOfDot + 1);
            if (fileType !== 'csv') {
                this.setState({errors: [{
                    message: 'Invalid file type. Please upload a .csv file.'
                }]});
                return;
            }
            setIsLoading(true);

            const reader = new FileReader();
    
            reader.onerror = () => {
                console.log(reader.error);
            }
    
            reader.onload = async () => {
    
                const str = reader.result;
    
                let rows = str.split(/\r\n|\r|\n/);
                for (let i = 0; i < rows.length; i++) {
                    rows[i] = rows[i].split(',');
                }

                if (rows[0][0].charAt(0) == "\"") {
                    rows = rows.map((r) => {
                        return r.map((rr) => {
                            return rr.slice(1, rr.length - 1);
                        })
                    })
                }

                let pointer = 0;
                let days = [];
                let startDate = undefined;
                let endDate = undefined;

                while (pointer < rows.length) { 
                    if (rows[pointer][0] === 'DRIVER #:') { //found first driver in list
                        pointer += 3; //proceed to the row where actual data is
                        while (true) { //this loop runs until there are no more drivers
                            while (rows[pointer][0] !== 'WEEKLY TOTALS:') { //for each row that begins with a date
                                const date = decodeFedexDate(rows[pointer][0]);
                                const dayIndex = moment(date).day() === 6 ? 0 : moment(date).day() + 1;
                                const fedexId = rows[pointer][1];
                                const sName = rows[pointer][2];
                                const totalPackages = parseInt(rows[pointer][4]) + parseInt(rows[pointer][6]);
                                const totalStops = parseInt(rows[pointer][5]) + parseInt(rows[pointer][7]) + parseInt(rows[pointer][9]);
                                const user = userData.find((u)=>u.fedexId == fedexId);
                                if (!startDate || moment(date).isBefore(startDate, 'days')) {
                                    startDate = date;
                                } else if (!endDate || moment(date).isAfter(endDate, 'days')) {
                                    endDate = date;
                                }


                                
                                days.push({
                                    companyUserIdentifier: user?.companyUserUid,
                                    sReportName:sName,
                                    fedexId:fedexId,
                                    date: date,
                                    stops: totalStops,
                                    packages: totalPackages
                                });
                                
                                pointer += 1;
                            }
                            pointer += 1;
                            if (rows[pointer][0] !== 'DRIVER #:') { //Look for the next driver section
                                break; //break out of the whole process if there are no more drivers
                            }
                            pointer += 2;
                        }
                        break;
                    }
                    pointer += 1;
                }
                if (days.length === 0) {
                    setError('Something went wrong when trying to read the file. Are you sure you uploaded the correct file?');
                } else {
                    
                    const sReport = {
                        startDate:startDate,
                        endDate:endDate,
                        csaIdentifier:selectedCsa,
                        days:days,
                        isComplete: !days.find((d)=>!d.companyUserIdentifier)
                    }
                    setSReport(sReport);
                    if(days.find(d=>!d.companyUserIdentifier) && userData.find(u => !u.fedexId)) {
                        setShowEmployeeMatch(true);
                    }
                }
                setIsLoading(false);
            }
            reader.readAsText(file);
        }
    }
    
    const handleSubmit = async() =>{
        const sReportToSubmit = structuredClone(sReport);
        sReportToSubmit.days = sReport.days.filter((d)=>d.companyUserIdentifier);
        const response = await submitSettlementReport(sReportToSubmit);
        if(response.status === '200'){
            sReportToSubmit.uid = response.uid;
            handleSetSettlementReportData(sReportToSubmit);
            hideModal();
        }
    }

    const validationGroup = new ValidationGroup();
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Upload FedEx Settlement Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {payrollView && 
                <>
                    <p style={{marginBottom: 0}}>By uploading your settlement report:</p>
                    <p style={{marginBottom: 0, marginLeft: 4}}>- Pay will be automatically calculated for employees who are paid per stop</p>
                    <p style={{marginBottom: 0, marginLeft: 4}}>- Over-threshold stop wages will be automatically calculated for all employees who qualify</p>
                </>
                }
                {/* <p style={{marginLeft: 4}}>- Stops and packages on each day for each employee will be viewable in the Production Report screen</p> */}
                <p>To upload the file, please export the file in <b>.csv</b> format.
                    <FontAwesomeIcon onClick={() => {setShowHelpModal(true)}} className="cursor-pointer" style={{color: 'var(--bs-primary)', marginLeft: 4}} icon={faQuestionCircle}/>
                </p>
                <RadioControl inline validator={validationGroup.createValidator(()=>validateExistence(selectedCsa))} selection={selectedCsa} setSelection={setSelectedCsa} optionNames={csaNames} optionValues={csaIdentifiers} title={'Select the report CSA: '}/>
                <Form.Control style={{marginTop:10}} isValid={error === undefined} isInvalid={error !== undefined} type='file' ref={fileRef} onChange={handleFileOnChange}/>
                { error &&
                    <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>
                }
                {showUploadButton && 
                <div style={{display: 'flex', flexDirection: 'column', marginTop: 12}}>
                    <CustomButton disabled={error !== undefined || !validationGroup.isValid()} onClick={handleUpload} label='Upload' isLoading={isLoading}/>
                </div>
                }

            </Modal.Body>
            <Modal.Footer>
                { sReport?.days.find(d => !d.companyUserIdentifier) &&
                    <div>
                        <FontAwesomeIcon icon={faTriangleExclamation} style={{color: 'gold', marginRight:6}}/>
                        <span>
                            {`The settlement report you have uploaded will generate incomplete data because it contains FedEx Ids which are not associated with any employees. ${!payrollView ? 'You can revise this settlement report at any time by assigning the unused FedEx Ids and then resubmitting the report.' : ''}`}
                        </span>
                    </div>
                }
                {sReport?.days.length > 0 && sReport.days.find(d => !d.companyUserIdentifier) && userData.find(u => !u.fedexId) &&
                    <CustomButton label={'Assign FedEx Ids'} onClick={()=>setShowEmployeeMatch(true)}/>
                }
                {sReport?.days.length > 0 && <CustomButton label='Submit Settlement Report' onClick={()=>handleSubmit()}/>}
            </Modal.Footer>
            <Modal show={showHelpModal} onHide={() => {setShowHelpModal(false)}} size='md'>
                <Modal.Header closeButton>
                    <Modal.Title>Excel to .csv Instructions</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                    <img src={instructions1} style={{maxWidth: 450}}/>
                    <FontAwesomeIcon icon={faArrowDown}/>
                    <img src={instructions2} style={{maxWidth: 450}}/>
                </Modal.Body>
            </Modal>
            <Modal show={showEmployeeMatch} onHide={()=>setShowEmployeeMatch(false)} size='xl'>
                <SettlementEmployeeMatchModal days={sReport?.days} userData={userData} handleUpdateUserFedexIds={handleUpdateUserFedexIds} hideModal={()=>setShowEmployeeMatch(false)} handleMatchEmployees={handleMatchEmployees}/>
            </Modal>
        </>
    )
}