import React from "react";
import moment from "moment";
import { Card } from "react-bootstrap";
import Wizard from '../../components/Wizard/Wizard';
import Modal from 'react-bootstrap/Modal';
import { usdFormatter } from "../../tools";
import PayrollEditor from "./PayrollEditor/PayrollEditor";
import PayrollPeriod from "./PayrollPeriod/PayrollPeriod";
import PageSpinner from "../../components/PageSpinner";
import CustomButton from '../../components/CustomButton';
import Table from 'react-bootstrap/Table';
import { getPayrollHistory } from "../../services/PayrollServiceClient";
import Cookies from "universal-cookie";
import Alert from 'react-bootstrap/Alert';
import QuickTable from "../../components/QuickTable";

export default class PayrollClient extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            periods: [],
            selectedPayPeriod: undefined,
            modalSwitch: 'none'
        }

        this.loadData = this.loadData.bind(this);
        // this.isAo = cookieMonster.get('userData').title === 'AO';
        this.companyName = new Cookies().get('companyName');
        // this.isBC = cookieMonster.get('userData').title === 'BC';
    }

    sortPayrollPeriods(a, b) {
        if (moment(a.periodStart).isBefore(moment(b.periodStart), 'days')) {
            return 1;
        } else if (moment(b.periodStart).isBefore(moment(a.periodStart), 'days')) {
            return -1;
        } else {
            if (a.isCustom == '1') {
                return -1;
            } else if (b.isCustom == '1') {
                return 1;
            } else {
                return 0;
            }
        }
    }

    async loadData() {
        this.setState({isLoading: true});
        const response = await getPayrollHistory();
        if (response.status === 200) {
            this.setState({
                periods: response.payrollPeriods,
            });
        }
        
        this.setState({isLoading: false});
    }

    componentDidMount() {
        this.loadData();
    }

    payrollStatusUncompletedKey = ['Ready for Review', 'Sent to TTA', 'Approved by TTA'];
    payrollStatusCompletedKey = ['Pending', 'Reviewed', 'Approved by TTA'];
    payrollStatusKey = ['Pending', 'Ready for Review', 'Sent to Payroll', 'Approved by TTA'];

    bcPayrollUncompletedKey = ['Pending', 'Ready for Review', 'Sent to AO for Review', 'Sent to TTA'];
    bcPayrolCompletedKey = ['Pending', 'Reviewed', 'Approved by AO', 'Approved by TTA'];


    render() {
        

        const customPayrollPeriods = this.state.periods.filter(p => p.isCustom);
        const currentCustomPayrollPeriods = customPayrollPeriods.filter(p => !p.ttaSubmission);

        const nonCustomPayrollPeriods = this.state.periods.filter(p => !p.isCustom);
        const currentPayrollPeriods = nonCustomPayrollPeriods.filter(p => !p.ttaSubmission);

        const historicPayrollPeriods = this.state.periods.filter(p => p.ttaSubmission);

        const currentPayrollCards = currentPayrollPeriods.map((period) => {
            return (
                <Card key={period.uid} style={{marginBottom: 12}}>
                    <Card.Body style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <Card.Title>{`${period.location ? `${period.location.name} | ` : ''}${moment(period.periodStart).format('MMM D')} - ${moment(period.periodEnd).format('MMM D, YYYY')}`}</Card.Title>
                            { period.isEditable ?
                                    <CustomButton label='Review Payroll Period' onClick={() => {this.setState({modalSwitch: 'editor', selectedPayPeriod: period})}}/>
                                : period.clientSubmission ?
                                    <CustomButton label='View Payroll Period' onClick={() => {this.setState({modalSwitch: 'viewer', selectedPayPeriod: period})}}/>
                                : 
                                    <span style={{fontStyle:'italic', opacity:0.5}}>TTA has taken over this payroll period</span>
                            }
                        </div>
                        
                        <div style={{minWidth: 500, marginBottom: 12, marginTop: 12}}>
                            <Wizard 
                                steps={this.payrollStatusUncompletedKey} 
                                completedSteps={this.payrollStatusCompletedKey} 
                                stepIndex={period.ttaSubmission ? 3 : period.clientSubmission ? 1 : 0}
                            />
                        </div>
                        { period.gross &&
                            <Card.Title style={{alignSelf: 'flex-end', fontSize: 25, marginBottom: 0}}>{'Gross: ' + usdFormatter.format(period.gross)}</Card.Title>
                        }
                    </Card.Body>
                </Card>
            )
        })

        const payrollHistoryElements = historicPayrollPeriods.map((period) => {
            return (
                <tr className="cursor-pointer" key={period.uid} onClick={() => {this.setState({selectedPayPeriod: period, modalSwitch: 'viewer'})}}>
                    <td>{moment(period.periodStart).format('MMM D') + ' - ' + moment(period.periodEnd).format('MMM D, YYYY')}</td>
                    <td>{period.location?.name ?? 'All'}</td>
                    <td>{usdFormatter.format(period.gross ?? 0)}</td>
                </tr>
            )
        });
       

        const customPayrollCards = currentCustomPayrollPeriods.map((period)=>{

            return (
                <Card key ={period.uid} style={{marginBottom:12}}>
                    <Card.Body style={{display:'flex', flexDirection:'column'}}>
                        <div style={{display:'flex', justifyContent:'space-between'}}>
                            <Card.Title>{`Custom Period | ${moment(period.periodStart).format('MMM D')} - ${moment(period.periodEnd).format('MMM D, YYYY')}`}</Card.Title>
                            { period.isEditable ?
                                    <CustomButton label='Review Custom Payroll Period' onClick={() => {this.setState({modalSwitch: 'editor', selectedPayPeriod: period})}}/>
                                : period.clientSubmission ?
                                    <CustomButton label='View Custom Payroll Period' onClick={() => {this.setState({modalSwitch: 'viewer', selectedPayPeriod: period})}}/>
                                : 
                                    <span style={{fontStyle:'italic', opacity:0.5}}>TTA has taken over this payroll period</span>
                            } 
                        </div>
                        <div style={{minWidth: 500, marginBottom: 12, marginTop: 12}}>
                            <Wizard steps={this.payrollStatusUncompletedKey} completedSteps={this.payrollStatusCompletedKey} stepIndex={period.ttaSubmission ? 3 : period.clientSubmission ? 1 : 0}/>
                        </div>
                        { period.gross &&
                            <Card.Title style={{alignSelf: 'flex-end', fontSize: 25, marginBottom: 0}}>{'Gross: ' + usdFormatter.format(period.gross)}</Card.Title>
                        }
                    </Card.Body>
                </Card>
            )
        })        
   
        const content = (
            <>
                {currentPayrollCards}
                {customPayrollCards}
                
                <Card style={{flex: 1}}>
                    <Card.Body>
                        <Card.Title>Previous Pay Periods</Card.Title>
                        <QuickTable hover headers={['Period', 'Location', 'Gross']} rows={payrollHistoryElements}/>
                    </Card.Body>
                </Card>
                
                <Modal show={this.state.modalSwitch === 'editor'} backdrop='static' fullscreen centered>
                    <PayrollEditor 
                        selectedPayPeriod={this.state.selectedPayPeriod} 
                        hideModal={() => {this.setState({modalSwitch: 'none'})}} 
                        loadData={this.loadData}
                        companyName={this.companyName}
                    />
                </Modal>
                <Modal show={this.state.modalSwitch === 'viewer'} fullscreen centered onHide={() => {this.setState({modalSwitch: 'none'})}}>
                    <PayrollPeriod selectedPayPeriod={this.state.selectedPayPeriod} companyName={this.companyName}/>
                </Modal>
                
            </>
        );
    
    
        return (
            <div className="page-wrapper" style={{padding: 12, display:'flex', flexDirection:'column'}}>
                {this.state.isLoading ? <PageSpinner/> : content}
            </div>
        )
    }
}