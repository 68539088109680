/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import moment from "moment";
import Modal from 'react-bootstrap/Modal';
import HRUserTimesheet from "../../../../HumanResources/HRUserTimesheet/HRUserTimesheet";
import QuickTable from "../../../../../components/QuickTable";
import Button from "react-bootstrap/Button";
import { adminMode } from "../../../payrollTools";
import { Alert } from "react-bootstrap";
import { faInfoCircle, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pay from "../../../Models/Pay";
import HRUserTimesheetEditor from "../../../../HumanResources/HRUserTimesheet/HRUserTimesheetEditor";

export default class PayrollPeriodUserTimesheet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            showEditor: false,
            selectedEntry: undefined,
            originalTimesheetData: props.timesheetData,
        }
        this.handleRecalculatePay = this.handleRecalculatePay.bind(this);
        this.handleAddEntry = this.handleAddEntry.bind(this);
        this.handleUpdateEntry = this.handleUpdateEntry.bind(this);
        this.handleDeleteEntry = this.handleDeleteEntry.bind(this);
    }


    sortPay = (a, b) => {
        if (moment(a.date).isBefore(moment(b.date), 'day')) {
            return -1;
        } else if (moment(a.date).isAfter(moment(b.date), 'day')) {
            return 1;
        } else {
            return a.uid - b.uid;
        }
    }

    getTotalHoursWorked() {
        return this.props.timesheetData.reduce((prev, curr) => {
            return prev + (curr.outTime && curr.outTime !== 'ACO' ? moment(curr.outTime).diff(moment(curr.inTime), 'hours', true) : 0.0);
        }, 0.0);
    }

    getTotalDaysWorked() {
        let daysWorked = 0;
        for (let i = 0; i < this.props.timesheetData.length; i++) {
            const iDate = moment(this.props.timesheetData[i].inTime).format('YYYY-MM-DD');
            let doContinue = false;
            for (let j = i + 1; j < this.props.timesheetData.length; j++) {
                const jDate = moment(this.props.timesheetData[j].inTime).format('YYYY-MM-DD');
                if (iDate === jDate) {
                    doContinue = true;
                    break;
                }
            }
            if (doContinue) {
                continue;
            }
            daysWorked++;
        }
        return daysWorked;
    }

    handleRecalculatePay() {
        const amountOfWeeks = (moment(this.props.entry.periodEnd).add(1,'day')).diff(moment(this.props.entry.periodStart), 'weeks');
        const hourlyAndDailyPays = this.props.payData.filter(p => p.payType === 'ph' || p.payType === 'pd').sort(this.sortPay);

        for (let i = 0; i < amountOfWeeks; i++) {
            const weekStart = moment(this.props.entry.periodStart).add(i, 'weeks');
            const weekEnd = moment(weekStart).add(6,'days');
            const pay = [];
           

            const paysBeforeNextWeek = hourlyAndDailyPays.filter((pay)=>moment(pay.date).isSameOrBefore(moment(weekEnd), 'day'));

            const paysDuringWeek = []

            for (let i = paysBeforeNextWeek.length -1; i >= 0; i--) {
                paysDuringWeek.unshift(paysBeforeNextWeek[i]);
                if (moment(paysBeforeNextWeek[i].date).isSameOrBefore(moment(weekStart), 'day')) {
                    break;
                }
            }

          
            const timesheetEntriesForWeek = this.props.timesheetData.filter((time) => moment(time.inTime).isBetween(moment(weekStart),moment(weekEnd),'day','[]'));
            let daysWorkedInWeek = 0;
            for (let j = 0; j < timesheetEntriesForWeek.length; j++) {
                let doContinue = false;
                for (let k = j + 1; k < timesheetEntriesForWeek.length; k++) {
                    if (moment(timesheetEntriesForWeek[j].inTime).isSame(moment(timesheetEntriesForWeek[k].inTime), 'day')) {
                        doContinue = true;
                        break;
                    }
                }
                if (doContinue) {
                    continue;
                }
                daysWorkedInWeek++;
            }
            
            const hoursWorkedInWeek = parseFloat(timesheetEntriesForWeek.reduce((prev, curr) => {
                return prev + (curr.outTime && curr.outTime !== 'ACO' ? moment(curr.outTime).diff(moment(curr.inTime), 'hours', true) : 0);
            }, 0.0).toFixed(2));
            
            paysDuringWeek.forEach((p, index)=>{
                const startDate = moment(p.date).isBefore(moment(weekStart)) ? weekStart.format('YYYY-MM-DD') : p.date;
                const endDate = index < paysDuringWeek.length - 1 ? moment(paysDuringWeek[index + 1].date).subtract(1, 'day').format('YYYY-MM-DD') : weekEnd.format('YYYY-MM-DD'); 

                const timesheetEntriesForPay = this.props.timesheetData.filter((time) => moment(time.inTime).isBetween(moment(startDate),moment(endDate),'day','[]'));
                let daysWorkedInPay = 0;
                for (let j = 0; j < timesheetEntriesForPay.length; j++) {
                    let doContinue = false;
                    for (let k = j + 1; k < timesheetEntriesForPay.length; k++) {
                        if (moment(timesheetEntriesForPay[j].inTime).isSame(moment(timesheetEntriesForPay[k].inTime), 'day')) {
                            doContinue = true;
                            break;
                        }
                    }
                    if (doContinue) {
                        continue;
                    }
                    daysWorkedInPay++;
                }
            
                const hoursWorkedInPay = parseFloat(timesheetEntriesForPay.reduce((prev, curr) => {
                    return prev + (curr.outTime && curr.outTime !== 'ACO' ? moment(curr.outTime).diff(moment(curr.inTime), 'hours', true) : 0);
                }, 0.0).toFixed(3));
                
                let unitsWorkedInPay = 0;
                if(p.payType === 'ph'){
                    unitsWorkedInPay = hoursWorkedInPay;
                } else if(p.payType === 'pd'){
                    unitsWorkedInPay= daysWorkedInPay;
                }
    
                pay.push(new Pay(p.payType, p.payRate, unitsWorkedInPay, startDate, endDate));
                
            });

            this.props.handleSetWeek(i, 'daysWorked', daysWorkedInWeek);
            this.props.handleSetWeek(i, 'hoursWorked', hoursWorkedInWeek);

            let newPayArray = this.props.entry.weeks[i].pay.filter(p => p.payType !== 'pd' && p.payType !== 'ph');
            newPayArray = newPayArray.concat(pay);

            this.props.handleSetWeek(i, 'pay', newPayArray);
            this.setState({showModal: false});
            this.props.hideModal();
        }
    }


    handleAddEntry(entry) {
        const newArray = Array.from(this.props.timesheetData);
        newArray.push(entry);
        this.props.handleSetTimesheetData(
            this.props.entry.companyUserIdentifier, 
            newArray.filter(t => moment(t.inTime).isBetween(moment(this.props.entry.periodStart), moment(this.props.entry.periodEnd), 'days', '[]'))
        );
    }

    handleUpdateEntry(entry) {
        const newArray = Array.from(this.props.timesheetData.filter(e => e.uid !== entry.uid));
        newArray.push(entry);
        this.setState({entries: newArray});
        this.props.handleSetTimesheetData(
            this.props.entry.companyUserIdentifier, 
            newArray.filter(t => moment(t.inTime).isBetween(moment(this.props.entry.periodStart), moment(this.props.entry.periodEnd), 'days', '[]'))
        );    
    }

    handleDeleteEntry(uid) {
        const newArray = Array.from(this.props.timesheetData.filter(e => e.uid !== uid));
        this.props.handleSetTimesheetData(
            this.props.entry.companyUserIdentifier, 
            newArray.filter(t => moment(t.inTime).isBetween(moment(this.props.entry.periodStart), moment(this.props.entry.periodEnd), 'days', '[]'))
        );    
    }

    render() {

        const sortedTimesheetData = Array.from(this.props.timesheetData).sort((a, b) => {
            if (moment(a.inTime).isBefore(moment(b.inTime))) {
                return -1;
            } else if (moment(a.inTime).isAfter(moment(b.inTime))) {
                return 1;
            } else {
                return 0;
            }
        })

        const entryRows = sortedTimesheetData.map((entry) => {
            return (
                <tr key={entry.uid} className={adminMode ? '' : 'cursor-pointer'} onClick={() => {
                    if (!adminMode) {
                        this.setState({selectedEntry: entry, showEditor: true});
                    }
                }}>
                    <td>{moment(entry.inTime).format('ddd D')}</td>
                    <td>{moment(entry.inTime).format('hh:mm A')}</td>
                    <td>{entry.outTime ? entry.outTime === 'ACO' ? 'Auto Clock-Out' : moment(entry.outTime).format('hh:mm A') : ''}</td>
                    <td>{entry.outTime && entry.outTime !== 'ACO' ? (moment(entry.outTime).diff(moment(entry.inTime), 'hours', true)).toFixed(2) : 'N/A'}</td>
                </tr>
            )
        });

        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>{'Timesheet for ' + this.props.entry.name() + ' during ' + moment(this.props.entry.periodStart).format('MMM D, YYYY') + ' - ' + moment(this.props.entry.periodEnd).format('MMM D, YYYY')}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{display: 'flex', flexDirection: 'column'}}>
                    { !adminMode &&
                        <Button variant={'outline-primary'} style={{alignSelf: 'flex-end', marginBottom: 10}} onClick={() => {this.setState({showEditor: true, selectedEntry: undefined})}}>
                            New Timesheet Entry
                        </Button>
                    }
                    <QuickTable hover={!adminMode} headers={['Date', 'In Time', 'Out Time', 'Hours Worked']} rows={entryRows}/>
                </Modal.Body>
                { !adminMode && this.state.originalTimesheetData != this.props.timesheetData &&
                    <Modal.Footer style={{justifyContent: 'center', textAlign: 'center', padding: 2}}>
                        <Alert variant="warning">Timesheet changes will not be reflected in this employee's payroll entry unless you press<br/> <b>Recalculate Hourly/Daily Wages</b></Alert>
                    </Modal.Footer>
                }
                <Modal.Footer style={{display:'flex'}}>
                    <span style={{fontSize: 20, marginRight:10}}>{'Total Hours: ' + this.getTotalHoursWorked().toFixed(2)}</span>
                    <span style={{fontSize: 20, marginRight:10}}>{'Total Days: ' + this.getTotalDaysWorked()}</span>
                    { !adminMode &&
                        <>
                            <Button variant="outline-primary" onClick={() => {this.setState({showModal: true})}}>Recalculate Hourly/Daily Wages</Button>
                            {/* <Button variant={'outline-primary'}  onClick={()=>{this.setState({showEditor:!this.state.showEditor})}}>{this.state.showEditor ? 'Table View' : 'Edit Timesheet'}</Button> */}
                        </>
                    }
                </Modal.Footer>
                <Modal show={this.state.showEditor} onHide={() => {this.setState({showEditor: false})}} centered size='lg'>
                    <HRUserTimesheetEditor
                        user={{companyUserUid: this.props.entry.companyUserIdentifier}}
                        selectedEntry={this.state.selectedEntry} 
                        hideModal={() => {this.setState({showEditor: false})}} 
                        handleAddEntry={this.handleAddEntry} 
                        handleUpdateEntry={this.handleUpdateEntry} 
                        handleDeleteEntry={this.handleDeleteEntry} 
                        handleSetTimeData={() => {}}
                        entries={this.props.timesheetData}
                    />
                </Modal>
                <Modal show={this.state.showModal} onHide={() => {this.setState({showModal: false})}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Recalculate Hourly/Daily Wages</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FontAwesomeIcon icon={faTriangleExclamation} style={{color: 'gold', marginRight: 8}}/>
                        Upon recalculating this employee's hourly/daily wages, any preexisting hourly/daily wages will be deleted and recreated based upon his or her timesheet data. hours worked and days worked will be overriden.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='outline-primary' onClick={this.handleRecalculatePay}>Proceed</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}