/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import PayModal from "../PayModal/PayModal";
import moment from "moment";
import CustomControl from "../../../../../../components/CustomControl";
import PayrollEmployeeNondiscretionaryBonuses from "./PayrollEmployeeNondiscretionaryBonuses";
import PayrollEmployeeWagesEditor from "./PayrollEmployeeWagesEditor";
import PayrollEmployeeAdditionalPayEditor from "./PayrollEmployeeAdditionalPayEditor";
import PayrollEmployeePtoEditor from "./PayrollEmployeePtoEditor";
import PayrollEmployeeHolidaysEditor from "./PayrollEmployeeHolidaysEditor";
import { bigToUsd } from "../../../../payrollTools";
import OverthresholdStopWagesEditor from "./OverthresholdStopWagesEditor";

export default function PayrollEmployeePayEditor({entry, index, handleSet, handleSetWeek, handleAddNdToAllEntries, handleAddAPToAllEntries, displayPtoInDays, setDisplayPtoInDays, payData, selectedPayPeriod, handleRecalculateStopWages}) {
    const [showModal, setShowModal] = useState(false);

    const handleAddWages = (pay, applyToAll) => {
        if(applyToAll){
            entry.weeks.forEach((week, i)=>{
                const newArr = Array.from(week.pay);
                newArr.push(pay);
                handleSetWeek(i, 'pay', newArr);
            });
        } else {
            const newArr = Array.from(entry.weeks[index].pay);
            newArr.push(pay);
            handleSetWeek(index, 'pay', newArr);
        }
        setShowModal(false);
    }

    const handleAddAdditionalPay = (additionalPay, applyToAll) => {
        if (applyToAll) {
            handleAddAPToAllEntries(additionalPay, index);
        } else {
            const newArr = Array.from(entry.weeks[index].additionalPay);
            newArr.push(additionalPay);
            handleSetWeek(index, 'additionalPay', newArr);
        }
        setShowModal(false);
    }

    const handleAddNonDiscretionaryBonus = (ndBonus, applyToAll) => {
        if (applyToAll) {
            handleAddNdToAllEntries(ndBonus, index);
        } else {
            const newArr = Array.from(entry.weeks[index].ndBonuses);
            newArr.push(ndBonus);
            handleSetWeek(index, 'ndBonuses', newArr);
        }
        setShowModal(false);
    }

    const handleAddPto = (pto) => {
        const newArr = Array.from(entry.weeks[index].ptoArray);
        newArr.push(pto);
        handleSetWeek(index, 'ptoArray', newArr);
        setShowModal(false);
    }

    const handleAddHoliday = (holiday) => {
        const newArr = Array.from(entry.weeks[index].holidayArray);
        newArr.push(holiday);
        handleSetWeek(index, 'holidayArray', newArr);
        setShowModal(false);
    }


    const weekStart = moment(entry.periodStart).add(index, 'weeks');
    const diff = moment(entry.periodEnd).diff(weekStart, 'weeks');
    const weekEnd = moment(entry.periodEnd).subtract(diff, 'weeks');
  
    return (
        <>
            { entry.weeks.length > 1 &&
                <div style={{display: 'flex', flexDirection:'column'}}>
                    <p style={{fontWeight:'bold'}}>Week: {weekStart.format('MMM D, YYYY') + ' - ' +weekEnd.format('MMM D, YYYY')}</p>
                </div>
            }

            <div style={{display:'flex', flexDirection:'row', alignItems:'center', gap:12}}>
                <Card.Title>Employee Pay</Card.Title>
                <Button  style={{color: 'white', marginBottom: 8, borderRadius: 14, padding: '0px 5px 0px 5px'}} onClick={() => {setShowModal(true)}}>
                    <FontAwesomeIcon icon={faPlus} style={{color: 'white'}}/>
                </Button>
            </div>
            <div style={{border: '1px solid lightgray', borderRadius: 6, padding: 12, marginBottom: 6}}>
                <PayrollEmployeeWagesEditor entry={entry} index={index} handleSetWeek={handleSetWeek}/>
                <PayrollEmployeeNondiscretionaryBonuses week={entry.weeks[index]} index={index} handleSetWeek={handleSetWeek} entry={entry}/>
                
                <PayrollEmployeeAdditionalPayEditor week={entry.weeks[index]} index={index} handleSetWeek={handleSetWeek}/>
                { entry.qualifiesForPtoAndHolidays() && entry.weeks[index].holidayArray.length > 0 &&
                    <PayrollEmployeeHolidaysEditor entry={entry} index={index} handleSetWeek={handleSetWeek}/>
                }
            </div>
            <OverthresholdStopWagesEditor entry={entry} handleSetWeek={handleSetWeek} index={index} handleRecalculateStopWages={handleRecalculateStopWages}/>
            
            <div style={{display:'flex', gap:10}}>
                {entry.weeks[index].qualifiesForFLSA()? <p style={{color:'green'}}>Employee Qualifies For FLSA Overtime</p>:<p>Employee Does Not Qualify For FLSA Overtime This Week</p>}
            </div>   
            <InputGroup style={{marginBottom: 12}}>
                <CustomControl type='number' title='FLSA Hourly Rate' value={entry.weeks[index].hourlyWage} onChange={(event)=>{handleSetWeek(index, 'hourlyWage', event.target.value)}}/>
                { entry.weeks[index].pay.find(p => p.payType == 'ph') ?
                    <CustomControl disabled title='Hours Worked' value={entry.weeks[index].getHoursWorked()}/>
                :
                    <CustomControl type='number' title='Hours Worked' value={entry.weeks[index].hoursWorked} onChange={(event)=>{handleSetWeek(index, 'hoursWorked', event.target.value)}}/>
                }
                { entry.weeks[index].qualifiesForFLSA() && 
                    <CustomControl disabled title='Overtime Rate' value={entry.weeks[index].overtimeRate().toFixed(2)}/>
                }
                <InputGroup.Text>
                    <Form.Check type='switch' label='Vehicle Under 10K' checked={entry.weeks[index].under10k} onChange={(event)=>{handleSetWeek(index,'under10k', !entry.weeks[index].under10k)}}/>
                </InputGroup.Text>
                {entry.weeks[index].qualifiesForFLSA() &&
                    <InputGroup.Text>
                    <div style={{display:'flex', gap:5}}>
                        <span>{'O.T. Sub Gross:'}</span>
                        <span style={{color:'green'}}>{bigToUsd(entry.weeks[index].getOvertimePayWages())}</span>

                    </div>
                    </InputGroup.Text>
                }
            </InputGroup>
            { entry.qualifiesForPtoAndHolidays() &&
                <PayrollEmployeePtoEditor entry={entry} index={index} handleSet={handleSet} handleSetWeek={handleSetWeek} payData={payData} selectedPayPeriod={selectedPayPeriod}/>
            }
            <Modal show={showModal} onHide={() => setShowModal(false)} centered size='xl'>
                <PayModal 
                    periodStart={entry.periodStart} 
                    periodEnd={entry.periodEnd} 
                    handleAddWages={handleAddWages} 
                    handleAddAdditionalPay={handleAddAdditionalPay} 
                    handleAddNonDiscretionaryBonus={handleAddNonDiscretionaryBonus}
                    handleAddPto={handleAddPto}
                    displayPtoInDays={displayPtoInDays} 
                    setDisplayPtoInDays={setDisplayPtoInDays} 
                    handleAddHoliday={handleAddHoliday} 
                    qualifiesForPtoAndHolidays={() => entry.qualifiesForPtoAndHolidays()}
                    disableAddingPay={entry.weeks[index].pay.length === 4}
                />
            </Modal>
        </>
    )
}