/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { fakeCompanies, fakeCompanyUsers, fakePay, getFakeUID, fakeHRUserSafetyData, fakeMyScheduleData, fakeVehicles, fakeWorkAreas, fakeCsas, fakeHRUserBWCData, fakeAddresses, } from "../../fake-data";
import fakeUsers from "../../fakeData/fakeUsers";
import { getResponse, getToken } from "../serviceTools";
import { timeout } from "../../tools";
import moment from "moment";

export async function getHRUserPayInformation(companyUserIdentifier) {
    var data = {
        type: 'hr',
        target: 'getEmployeePay',
        authToken: getToken(),
        companyUserIdentifier: companyUserIdentifier
    }

    const response = await getResponse(data);
    return response;
}

export async function createUserPay(payInfo) {
    var data = {
        type: 'hr',
        target: 'createEmployeePay',
        authToken: getToken(),
        data: payInfo
    }

    const response = await getResponse(data);
    return response;
}

export async function deleteUserPay(uid) {
    var data = {
        type: 'hr',
        target: 'deleteEmployeePay',
        authToken: getToken(),
        uid: uid
    }

    const response = await getResponse(data);
    return response;
}

// export async function updateUserPay(payInfo) {
//     var data = {
//         type: 'hr',
//         target: 'updatePay',
//         authToken: getToken(),
//         data: payInfo
//     }

//     return true;

//     // const response = await getResponse(data);

//     // return response;
// }