import fakeUsers from "./fakeUsers";
import fakeCompanies from "./fakeCompanies";
import { fakeCsas } from "./fakeCompanyResources";

const fakeCompanyUsers = [];

for (let i = 0; i < fakeUsers.length; i++) {

	const daysOfTheWeek = ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

	const availablity = [];

	for (let j = 0; j < 5; j++) {
		availablity.push(daysOfTheWeek[ (i + j) % daysOfTheWeek.length]);
	}

	const companyIdentifier = fakeCompanies[i % fakeCompanies.length].uid;

	const csasInCompany = fakeCsas.filter(r => r.companyIdentifier === companyIdentifier);

    fakeCompanyUsers.push({
        companyUserUid: i + 1,
        userIdentifier: fakeUsers[i].uid,
        companyIdentifier: companyIdentifier,
        companyUserIsEnabled: i % 50 === 0 ? '0' : '1',
		availability: availablity.join(','),
		csaIdentifier: csasInCompany[(i + Math.floor(i / fakeCompanies.length)) % csasInCompany.length].uid,
		fedexName: (fakeUsers[i].firstName + fakeUsers[i].lastName).toUpperCase()
    });
    
}
for (let i = 0; i < 20; i++) {

	const daysOfTheWeek = ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

	const availablity = [];

	for (let j = 0; j < 5; j++) {
		availablity.push(daysOfTheWeek[ (i + j) % daysOfTheWeek.length]);
	}

	const companyIdentifier = fakeCompanies[(i + 1) % fakeCompanies.length].uid;

	const csasInCompany = fakeCsas.filter(r => r.companyIdentifier === companyIdentifier);

    fakeCompanyUsers.push({
        companyUserUid: fakeCompanyUsers.length + 1 + i,
        userIdentifier: fakeUsers[i].uid,
        companyIdentifier: companyIdentifier,
        companyUserIsEnabled: '1',
		availability: availablity.join(','),
		csaIdentifier: csasInCompany[(i + Math.floor(i / fakeCompanies.length)) % csasInCompany.length].uid,
		fedexName: (fakeUsers[i].firstName + fakeUsers[i].lastName).toUpperCase()
    });
}

export default fakeCompanyUsers;