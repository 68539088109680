/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import fakeCompanyUsers from "./fakeCompanyUsers";
import moment from "moment";

const fakePay = [];

for (let i = 0; i < fakeCompanyUsers.length; i++) {

	const payType = i % 13 === 0 ? 'py' : ['pd', 'ph', 'ps', 'pm'][i % 4];
	const payRate = i % 13 === 0 ? 40000 : [120, 15, 3, 2][i % 4];
	const title = i % 9 === 0 ? 'BC' : 'Driver';

	fakePay.push({
		uid: i + 1,
		companyUserIdentifier: fakeCompanyUsers[i].companyUserUid,
		title: title,
		date: moment().subtract(i, 'days').subtract(i % 12, 'months').format('YYYY-MM-DD'),
		payRate: payRate,
		payType: payType,
		hourlyWage: (i % 5) + 12,
		notes: 'These are sample pay notes',
		employeeType: i % 3 === 0 ? '1' : '0',
		medical: i % 3 ? ((i % 10 + 1) * 105).toString() : undefined,
		dental: i % 4 ? ((i % 10 + 1) * 110).toString() : undefined,
		vision: i % 5 ? ((i % 10 + 1) * 115).toString() : undefined,
		childSupport: undefined,
		bwcCode: ((i + 1) * 57).toString(),
	});
}

export default fakePay;