/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import fakeUsers from "./fakeData/fakeUsers";
import fakeCompanyUsers from "./fakeData/fakeCompanyUsers";
import fakeAddresses from "./fakeData/fakeAddresses";
import fakeCompanies from "./fakeData/fakeCompanies";
import fakePay from "./fakeData/fakePay";
import { fakeCsas, fakeVehicles, fakeWorkAreas } from "./fakeData/fakeCompanyResources";

const loremIpsum = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris non metus non erat sollicitudin varius. Nunc erat metus, congue nec tellus sit amet, laoreet lobortis lectus. Cras quis urna quis ante pulvinar aliquet vel quis mi. Aliquam consectetur mattis orci, vitae scelerisque odio fringilla ut. Vestibulum auctor facilisis nisl eu blandit. Vestibulum dui dolor, consectetur quis laoreet varius, aliquam a metus. Nulla lacinia in tellus at varius. Quisque lacus elit, congue vel ultricies eu, venenatis vel sem. Nunc tempus aliquam volutpat. Morbi et tellus sem. Fusce dictum at felis non dictum.';


export const fakeTimesheet = [];

for (let j = 0; j < fakeCompanyUsers.length; j++) {
	for (let i = 0; i < 30; i++) {
		const date = moment().subtract(i, 'days').format('YYYY-MM-DD');
		const inTime = i % 3 === 0 ? '07:00' : i % 3 === 1 ? '08:00' : '09:00';
		const outTime = i % 3 === 0 ? '15:00' : i % 3 === 1 ? '16:00' : '17:00';
		
		fakeTimesheet.push({
			uid: i,
			companyUserIdentifier: fakeCompanyUsers[j].companyUserUid,
			inTime: date + ' ' + inTime,
			outTime: i === 0 ? undefined : i < 14 && i % 3 === 0 ? 'ACO' : date + ' ' + outTime,
			inLat: 41.24020421321586,
			inLong: -81.65390038636382,
			outLat: i < 14 && i % 3 === 0 ? undefined : 41.24020421321586,
			outLong: i < 14 && i % 3 === 0 ? undefined : -81.65390038636382,
		});
	}
}

export const fakeSchedule = [];

for (let j = 0; j < fakeCompanyUsers.length; j++) {
	for (let i = 0; i < 100; i++) {
		const date = moment().subtract(30, 'days').add(i, 'days');
		const submissionDate = moment().subtract((i % 7) + 1, 'days');
		const endDate = moment(date).add((i % 6) + 1, 'days');
	
		const newObject = {
			uid: (j * 100) + i,
			companyUserIdentifier: fakeCompanyUsers[j].companyUserUid,
			date: date.format('YYYY-MM-DD'),
			submissionDate: submissionDate.format('YYYY-MM-DD')
		}
	
		if (i === 40) {
			newObject.scheduleType = 'pto';
			newObject.endDate = endDate.format('YYYY-MM-DD');
			newObject.notes = 'Visiting family for the holidays';
			i += (i % 6) + 1;
		} else if (i % 17 === 0) {
			newObject.scheduleType = 'callOff';
			newObject.notes = 'Sick';
		} else if (i % 15 === 0) {
			newObject.scheduleType = 'requestOff';
			newObject.endDate = endDate.format('YYYY-MM-DD');
			newObject.notes = 'Attending special event';
			i += (i % 6) + 1;
		} else if (i % 13 === 0) {
			newObject.scheduleType = 'noShow';
			newObject.notes = 'He didn\'t show up and didn\'t say anything.';
		} else if (i % 11 === 0) {
			newObject.scheduleType = 'dayOff';
			newObject.endDate = endDate.format('YYYY-MM-DD');
			newObject.notes = 'It\'s my birthday';
			i += (i % 6) + 1;
		} else if (i % 19 === 0) {
			newObject.scheduleType = 'rejectedRequest';
			newObject.notes = 'Want to go party';
		} else if ((i + 1) % 7 < 2) {
			continue;
		} else {
			newObject.scheduleType = 'route';
			newObject.timeScheduled = moment(date).startOf('day').add((i % 3) + 7, 'hours').format('HH:mm');
			newObject.notes = 'Please deliver lots of packages.';
		}
		fakeSchedule.push(newObject);
	}
}




export const fakePayrollData = [];

for (let i = 0; i < 100; i++) {

    const offset = moment().day() === 6 ? 0 : moment().day() + 1;

	const companyIndex = i % fakeCompanies.length;

    const periodStart = moment().subtract(offset, 'days').subtract(companyIndex, 'weeks').format('YYYY-MM-DD');
    const periodEnd = moment(periodStart).add(6, 'days').format('YYYY-MM-DD');

    const entries = [];

	const companyUsers = fakeCompanyUsers.filter(r => r.companyIdentifier === fakeCompanies[companyIndex].uid);

    for (let j = 0; j < companyUsers.length; j++) {

		const userPay = fakePay.find(r => r.companyUserIdentifier === companyUsers[j].companyUserUid);
		const userData = fakeUsers.find(r => r.uid === companyUsers[j].userIdentifier);
		const name = userData.firstName + ' ' + userData.lastName;

		const entry = {
            uid: j + 1,
            companyUserIdentifier: companyUsers[j].companyUserUid,
            periodStart: periodStart,
            periodEnd: periodEnd,
            under10k: j % 3 === 0 ? true : false,
            hoursWorked: j % 4 === 0 ? (15 + ((companyIndex + j) % 7)): (35  + ((companyIndex + j) % 7)),
            daysWorked: j % 4 === 0 ? 3 : 5,
            payRate: userPay.payRate,
            payType: userPay.payType,
            hourlyWage: userPay.hourlyWage,
			title: userPay.title, //Don't think I need this
			firstName: userData.firstName,
            lastName: userData.lastName,
            medical: userPay.medical,
            dental: userPay.dental,
            vision: userPay.vision,
            childSupport: userPay.childSupport,
            bwcCode: userPay.bwcCode,
            additionalPay: [],
            pto: j % 6 === 0 ? 1 : 0,
            stops: 0,
            miles: 0,
            holidays: 0,
            notes: '',
            csaName: fakeCsas.find(csa => csa.uid === companyUsers[j].csaIdentifier).csaName,
        };

        entries.push(entry);
    }

    fakePayrollData.push(
        {
            uid: i + 1,
			companyIdentifier: fakeCompanies[companyIndex].uid,
            periodStart: periodStart,
            periodEnd: periodEnd,
            status: Math.min(i % 9, 4).toString(),
            gross: Math.min(i % 9, 4) > 1 ? 10000 + (companyIndex * 100): undefined,
            originalEntries: JSON.stringify(entries),
			approvedEntries: JSON.stringify(entries),
        }
    );
}



export const fakeMyScheduleData = [];

for (let i = 0; i < 100; i++) {
    const date = moment().subtract(30, 'days').add(i, 'days');
    const submissionDate = moment().subtract((i % 7) + 1, 'days');
    const endDate = moment(date).add((i % 6) + 1, 'days');

    const newObject = {
        uid: i,
        companyUserIdentifier: 1,
        date: date.format('YYYY-MM-DD'),
        submissionDate: submissionDate.format('YYYY-MM-DD')
    }

    if (i === 40) {
        newObject.scheduleType = 'pto';
        newObject.endDate = endDate.format('YYYY-MM-DD');
        newObject.notes = 'Visiting family for the holidays';
        i += (i % 6) + 1;
    } else if (i % 17 === 0) {
        newObject.scheduleType = 'callOff';
        newObject.notes = 'Sick';
    } else if (i % 15 === 0) {
        newObject.scheduleType = 'requestOff';
        newObject.endDate = endDate.format('YYYY-MM-DD');
        newObject.notes = 'Attending special event';
		i += (i % 6) + 1;
    } else if (i % 13 === 0) {
        newObject.scheduleType = 'noShow';
        newObject.notes = 'He didn\'t show up and didn\'t say anything.';
    } else if (i % 11 === 0) {
        newObject.scheduleType = 'dayOff';
        newObject.endDate = endDate.format('YYYY-MM-DD');
        newObject.notes = 'It\'s my birthday';
		i += (i % 6) + 1;
    } else if (i % 19 === 0) {
        newObject.scheduleType = 'rejectedRequest';
        newObject.notes = 'Want to go party';
    } else if ((i + 1) % 7 < 2) {
        continue;
    } else {
        newObject.scheduleType = 'route';
        newObject.timeScheduled = moment(date).startOf('day').add((i % 3) + 7, 'hours').format('HH:mm');
        newObject.notes = 'Please deliver lots of packages.';
    }
    fakeMyScheduleData.push(newObject);

	
}


export let fakeHRUserSafetyData = {
    accidents: [{
        uid: 1,
        userID: 1,
        versions: []
    }],
    incidents: [{
        uid: 1,
        userID: 1,
        versions: []
    }],
    movingViolations: [{
        uid: 1,
        userID: 1,
        versions: []
    }],
    rideAlongs: [{
        uid: 1,
        userID: 1,
        versions: []
    }],
};

for (let i = 0; i < 5; i++) {

    const bcs = fakeCompanyUsers;

    

    fakeHRUserSafetyData.accidents[0].versions.push(
        {
            uid: i + 1,
            accidentID: 1,
            isBackingAccident: i > 3,
            accidentType: i < 2 ? 'structure' : 'other',
            dateTime: moment().subtract(6, 'days').format('YYYY-MM-DD HH:mm'),
            cost: i > 3 ? 540 : 350,
            attachments: [], 
            notes: i > 2 ? loremIpsum : '',
            reporterToFedex: i < 2 ? undefined : bcs[0].companyUserUid,
            author: bcs[i % bcs.length].userID,
            submissionDateTime: moment().subtract(8, 'days').add(i, 'days').format('YYYY-MM-DD HH:mm'),
            address: i < 1 ? {thoroughfare: '', premise: '', administrativeArea: '', locality: 'Twinsburg', postalCode: ''} : {thoroughfare: '321 Fake St.', premise: '', administrativeArea: 'Ohio', locality: 'Twinsburg', postalCode: '44087'},
            propertyOwnerContacted: i > 1 ? true : false,
            propertyOwnerFirstName: i > 1 ? 'Camron' : undefined,
            propertyOwnerLastName: i > 1 ? 'Shaw' : undefined,
            propertyOwnerEmail: i > 1 ? 'cs@fakeemail.com' : undefined,
            propertyOwnerPhoneNumber: i > 1 ? '123456789' : undefined,
            propertyOwnerInsuranceCompany: i > 1 ? 'Nationwide' : undefined,
            propertyOwnerAddress: i > 1 ? {thoroughfare: '456 fake ave.', premise: '', administrativeArea: 'Ohio', locality: 'Hudson', postalCode: '44236'} : undefined,
            policeContacted: i > 1 ? true : false,
            policeReportNumber: i > 1 ? '321' : undefined,
        }
    );

    fakeHRUserSafetyData.incidents[0].versions.push(
        {
            uid: i + 1,
            incidentID: 1,
            dateTime: moment().subtract(7, 'days').format('YYYY-MM-DD HH:mm'),
            cost: i > 3 ? 540 : 350,
            attachments: [], 
            notes: i > 2 ? loremIpsum : '',
            author: bcs[i % bcs.length].companyUserUid,
            submissionDateTime: moment().subtract(8, 'days').add(i, 'days').format('YYYY-MM-DD HH:mm'),
            address: i < 1 ? {thoroughfare: '', premise: '', administrativeArea: '', locality: 'Twinsburg', postalCode: ''} : {thoroughfare: '321 Fake St.', premise: '', administrativeArea: 'Ohio', locality: 'Twinsburg', postalCode: '44087'},
            propertyOwnerContacted: i > 1 ? true : false,
            propertyOwnerFirstName: i > 1 ? 'Camron' : undefined,
            propertyOwnerLastName: i > 1 ? 'Shaw' : undefined,
            propertyOwnerEmail: i > 1 ? 'cs@fakeemail.com' : undefined,
            propertyOwnerPhoneNumber: i > 1 ? '123456789' : undefined,
            propertyOwnerInsuranceCompany: i > 1 ? 'Nationwide' : undefined,
            propertyOwnerAddress: i > 1 ? {thoroughfare: '456 fake ave.', premise: '', administrativeArea: 'Ohio', locality: 'Hudson', postalCode: '44236'} : undefined,
        }
    );
    

    fakeHRUserSafetyData.rideAlongs[0].versions.push(
        {
            uid: i + 1,
            rideAlongID: 1,
            date: moment().subtract(8, 'days').format('YYYY-MM-DD'),
            secondaryDriver: bcs[0].companyUserUid,
            secondaryDriverType: 'rideAlong',
            timeScheduled: '08:00',
            notes: i > 2 ? loremIpsum : '',
            author: bcs[i % bcs.length].userID,
            submissionDateTime: moment().subtract(8, 'days').add(i, 'days').format('YYYY-MM-DD HH:mm'),
            rideAlongType: 'safety',
            performance: 'excellent' 
        }
    );

    fakeHRUserSafetyData.movingViolations[0].versions.push(
        {
            uid: i + 1,
            movingViolationID: 1,
            dateTime: moment().subtract(9, 'days').format('YYYY-MM-DD HH:mm'),
            movingViolationType: 'Seatbelt violation',
            attachments: [], 
            notes: i > 2 ? loremIpsum : '',
            author: bcs[i % bcs.length].companyUserUid,
            submissionDateTime: moment().subtract(8, 'days').add(i, 'days').format('YYYY-MM-DD HH:mm'),
            policeReportNumber: i > 0 ? '321' : ''
        }
    );
}



export let fakeHRUserBWCData = [];

for (let j = 0; j < fakeCompanyUsers.length; j++) {
	
	const companyIdentifier = fakeCompanyUsers[j].companyIdentifier;
	const bcs = fakeCompanyUsers.filter(r => r.companyIdentifier === companyIdentifier && fakePay.find(p => p.companyUserIdentifier === r.companyUserUid).title === 'BC');
	for (let k = 0; k < 3; k++) {
		const bwc = {
			uid: j * 3 + k,
			companyUserIdentifier: fakeCompanyUsers[j].companyUserUid,
			versions: []
		}
		for (let i = 0; i < 3; i++) {
			const submissionDateStatic = moment().subtract(50 - i, 'days');
			const dateOfInjuryStatic = moment(submissionDateStatic).subtract(7, 'days');
			const admittedDateTimeStatic = moment(dateOfInjuryStatic).add(1, 'days');
			const rtwLightDateStatic = moment(admittedDateTimeStatic).add(4, 'days');
			const rtwFullDateStatic = moment(rtwLightDateStatic).add(14, 'days');
			const injuryReportDateStatic = moment(dateOfInjuryStatic).add(i % 2, 'days');
	
			bwc.versions.push({
				uid: i,
				bwcRecordsIdentifier: bwc.uid,
				dateOfInjury: dateOfInjuryStatic.format('YYYY-MM-DD'),
				injuryReportDate: injuryReportDateStatic.format('YYYY-MM-DD'),
				injuryReportedTo: bcs.length > 0 ? bcs[i % bcs.length].companyUserUid : undefined,
				treatmentFacilityName: i < 1 ? '' : 'Cleveland Clinic',
				treatmentFacilityAddress: i < 1 ? {} : fakeAddresses[(j * 3 + i) % fakeAddresses.length].uid,
				treatmentFacilityAdmittedDateTime: i < 1 ? undefined : admittedDateTimeStatic.format('YYYY-MM-DD HH:mm'),
				salaryContinuation: false,
				rtwLightDutyDate: i < 2 ? undefined : rtwLightDateStatic.format('YYYY-MM-DD'),
				lightDutyDescription: i < 2 ? '' : 'Just Driving',
				rtwFullDutyDate: i < 3 ? undefined : rtwFullDateStatic.format('YYYY-MM-DD'),
				numberOfDaysMissed: i < 2 ? undefined : rtwLightDateStatic.diff(dateOfInjuryStatic, 'day'), 
				bwcInjuryClaimNumber: i < 2 ? '' : '35782',
				medco14: undefined,
				c55: undefined,
				attachments: [],
				notes: loremIpsum,
				submissionDateTime: moment(submissionDateStatic).add(i, 'days').format('YYYY-MM-DD HH:mm'),
				author: bcs.length > 0 ? bcs[i % bcs.length].companyUserUid : undefined,
			});
		}
		fakeHRUserBWCData.push(bwc);
	}
}


export let fakeHRUserDocumentsData = [
    {
        uID: 1,
        title: 'W4',
        link: '',
        fileType: '.doc'
    },
    {
        uID: 2,
        title: 'IT4',
        link: '',
        fileType: '.pdf',
    },
    {
        uID: 3,
        title: 'Direct Deposit',
        link: '',
        fileType: '.doc',
    },
    {
        uID: 4,
        title: 'I9',
        link: '',
        fileType: '.doc',
    },
    {
        uID: 5,
        title: 'BWC Claim 2022/06/03',
        link: '',
        fileType: '.doc',
    },
    {
        uID: 6,
        title: 'Accident 2022/08/05 image 1',
        link: '',
        fileType: '.png',
    }
];

export let fakeEmployeePermissions = {
	uid: 1,
	hr_profile: '1',
    hr_schedule: '1',
    hr_timesheet: '1',
    hr_pay: '1',
    hr_safety: '1',
    hr_bwc: '1',
    hr_documents: '1',
    hr_permissions: '0',
    payroll: '1',
    scheduleMatch: '1',
    myCompany: '1',
    profile: '1',
    timeClock: '1',
    mySchedule: '1',
    daysOff: '1',
}

export const fakePermissionsTemplate = [
    {
        permissionName: 'hr_profile',
        permissionTitle: 'HR Profile',
        description: 'Access to employee profile information on all employees in the company',
        driver: '0',
        bc: '1',
        ao: '1',
    },
    {
        permissionName: 'hr_schedule',
        permissionTitle: 'HR Schedule',
        description: 'Access to employee schedule items; ability to accept requests for time off and ability to report call-offs and no-shows',
        driver: '0',
        bc: '1',
        ao: '1',
    },
    {
        permissionName: 'hr_timesheet',
        permissionTitle: 'HR Timesheet',
        description: 'Access to employee timesheet records; ability to view, modify, create, and delete timesheet entries',
        driver: '0',
        bc: '1',
        ao: '1',
    },
    {
        permissionName: 'hr_pay',
        permissionTitle: 'HR Pay',
        description: 'Access to employee pay information; ability to view current and historic pay information and define a new position for the employee',
        driver: '0',
        bc: '1',
        ao: '1',
    },
    {
        permissionName: 'hr_safety',
        permissionTitle: 'HR Safety',
        description: 'Access to employee safety records; ability to view, create, and revise accidents, incidents, moving violations, and ride-alongs',
        driver: '0',
        bc: '1',
        ao: '1',
    },
    {
        permissionName: 'hr_bwc',
        permissionTitle: 'HR BWC',
        description: 'Access to employee BWC records; ability to view, create, and revise BWC reports',
        driver: '0',
        bc: '1',
        ao: '1',
    },
    {
        permissionName: 'hr_documents',
        permissionTitle: 'HR Documents',
        description: 'Access to employee documents (images, pdfs, word documents, etc.); ability to download existing documents and upload new documents',
        driver: '0',
        bc: '1',
        ao: '1',
    },
    {
        permissionName: 'hr_permissions',
        permissionTitle: 'HR Permissions',
        description: 'Access to employee permissions; ability to view and modify permissions',
        driver: '0',
        bc: '0',
        ao: '1',
    },
    {
        permissionName: 'payroll',
        permissionTitle: 'Payroll',
        description: 'Access to company payroll; ability to view current and historic payroll information and approve payroll periods',
        driver: '0',
        bc: '1',
        ao: '1',
    },
    {
        permissionName: 'scheduleMatch',
        permissionTitle: 'Schedule Match',
        description: 'Access to company scheduling; ability to view, create, modify, and delete scheduled routes, paid training, and managers on duty.',
        driver: '0',
        bc: '1',
        ao: '1',
    },
    {
        permissionName: 'myCompany',
        permissionTitle: 'Company Resources & Settings',
        description: 'Access to company resources and settings; ability to view, create, modify, and deactivate CSAs, work areas, and vehicles; ability to modify company settings (ex. company address).',
        driver: '0',
        bc: '1',
        ao: '1',
    },
    {
        permissionName: 'profile',
        permissionTitle: 'Profile',
        description: 'Ability to view his or her own personal information, pay information, and ability to change his or her password',
        driver: '1',
        bc: '1',
        ao: '1',
    },
    {
        permissionName: 'timeClock',
        permissionTitle: 'Time Clock',
        description: 'Ability to view his or her own timesheet records, clock-in, and clock-out',
        driver: '1',
        bc: '1',
        ao: '1',
    },
    {
        permissionName: 'mySchedule',
        permissionTitle: 'My Schedule',
        description: 'Ability to view his or her own personal calendar of routes, time off, etc.',
        driver: '1',
        bc: '1',
        ao: '1',
    },
    {
        permissionName: 'daysOff',
        permissionTitle: 'Days Off',
        description: 'Ability to view his or her own history of time off requests and their statuses (approved, rejected, pending); ability to request time off',
        driver: '1',
        bc: '1',
        ao: '1',
    },
];


export const fakeMyCompanyData = {
    csas: [],
    workAreas: [],
    vehicles: [],
    settings: {
        address: {thoroughfare: '321 fake street', premise: '', administrativeArea: 'Ohio', locality: 'Twinsburg', postalCode: '12345'},
        companyAddressAllowClockIn: false,
        companyAddressClockInRadius: 1,
        benefits: 'None',
        forceMFA: false,
        requireLocationForTimePunch: false,
        enableGeofencing: false,
        allowBCAccessToSensitiveInformation: false,
    }
}

let fakeUID = 0;

export function getFakeUID() {
    fakeUID--;
    return fakeUID;
}