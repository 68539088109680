/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/


import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight} from "@fortawesome/free-solid-svg-icons";
import { usdFormatter} from "../../../../../tools";
import {Card, ListGroup, Accordion, Tabs, Tab } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { bigToUsd, holidayPayTypeDictionary, payTypeDictionary, validateDecimal, validateInteger} from "../../../payrollTools";
import KeyValueRow from "../../../../../components/KeyValueRow";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import moment from "moment";
import QuickTable from "../../../../../components/QuickTable";


export default function PayrollGrossCalculator({entry}) {

    let dBonusElements = entry.dBonuses.map((item) => {
        return (
            <KeyValueRow forceRow={true} valueStyle={{color: 'green', opacity: 1.0}} isListItem key={item.id} title={item.label} value={usdFormatter.format(item.amount)}/>
        )
    });

    const deductionFlatRateElements = entry.deductions.filter(d => !d.isPercentage()).map((item) => {
        return (
            <KeyValueRow forceRow={true} valueStyle={{color: 'red', opacity: 1.0}} isListItem key={item.id} title={item.label} value={item.isPercentage() ? `${item.getAmount()}%` : usdFormatter.format(item.getAmount())}/>
        )
    });

    const deductionPercentageElements = entry.deductions.filter(d => d.isPercentage()).map((item) => {
        return (
            <KeyValueRow forceRow={true} valueStyle={{color: 'red', opacity: 1.0}} isListItem key={item.id} title={item.label} value={item.isPercentage() ? `${item.getAmount()}%` : usdFormatter.format(item.getAmount())}/>
        )
    });

    const weekCards = entry.weeks.map((week, index)=>{
        return (
            <Tab key={index} eventKey={index} title={'Week ' + (index + 1)}>
                <div style={{border: '1px solid #DEE2E6', borderTop:'none', padding:6, marginBottom:10, borderRadius:10, borderTopLeftRadius:0, borderTopRightRadius:0}}>
                    <PayrollGrossCalculatorWeek entry={entry} week={week} index={index}/>
                </div>
            </Tab>
        )
    })
   
    return (
        <>
            <Modal.Header style={{display:'flex', justifyContent:'center', alignContent:'center'}}  closeButton>
                <p style={{fontWeight: 'bold', fontSize: 24 }}>{entry.firstName + ' ' + entry.middleName + ' ' + entry.lastName + '\'s Gross Pay: '} <span style={{color: 'green', opacity: 1.0}}>{bigToUsd(entry.gross())}</span></p>
            </Modal.Header>
            <Modal.Body>
                <div style={{overflowY: 'scroll', display: 'flex', flexDirection: 'column', gap: 12, flex: 1, padding: 8}}>
                    <ListGroup>
                        {entry.weeks.length > 1 ? 
                            <Tabs defaultActiveKey={0} id='employeePay'>
                                {weekCards}
                            </Tabs>
                            :
                            <PayrollGrossCalculatorWeek entry={entry} week={entry.weeks[0]} index={0}/>
                        } 
                        <Accordion style={{marginBottom:5}} alwaysOpen>
                            <Accordion.Item eventKey="0">
                                <AccordionHeader>
                                    <div style={{flex:1, display:'flex', justifyContent:'space-between', alignItems:'center', marginRight:10}}>
                                        <span>{'Total Discretionary Bonuses:'}</span> 
                                        <span style={{color: 'green', opacity: 1.0}}>{bigToUsd(entry.totalDBonuses())}</span>
                                    </div>
                                </AccordionHeader>
                                <AccordionBody>
                                    <ListGroup style={{margin: 6}}>
                                        {dBonusElements}
                                    </ListGroup>
                                </AccordionBody>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <AccordionHeader>
                                    <div style={{flex:1, display:'flex', justifyContent:'space-between', alignItems:'center', marginRight:10}}>
                                        <span>{'Total Deductions (Flat Rate):'}</span> 
                                        <span style={{color: 'red', opacity: 1.0}}>{bigToUsd(entry.allDeductionsFlatRate())}</span>
                                    </div>
                                </AccordionHeader>
                                <AccordionBody>
                                    <ListGroup style={{margin: 6}}>
                                        {deductionFlatRateElements}
                                    </ListGroup>
                                </AccordionBody>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <AccordionHeader>
                                    <div style={{flex:1, display:'flex', justifyContent:'space-between', alignItems:'center', marginRight:10}}>
                                        <span>{'Total Deductions (Percentage):'}</span> 
                                        <span style={{color: 'red', opacity: 1.0}}>{`${entry.allDeductionsPercentage()}%`}</span>
                                    </div>
                                </AccordionHeader>
                                <AccordionBody>
                                    <ListGroup style={{margin: 6}}>
                                        {deductionPercentageElements}
                                    </ListGroup>
                                </AccordionBody>
                            </Accordion.Item>
                        </Accordion>
                    </ListGroup>
                </div>
            </Modal.Body>
        </>
    )
    
}



function PayrollGrossCalculatorWeek({entry, week, index}) {

    const weekStart = moment(entry.periodStart).add(index, 'weeks');
    const diff = moment(entry.periodEnd).diff(weekStart, 'weeks');
    const weekEnd = moment(entry.periodEnd).subtract(diff, 'weeks');

    const payCards = week.pay.length === 0 ? '' : week.pay.map((payItem)=>{
        return (
            <ListGroup key={payItem.id} style={{paddingTop:5, paddingBottom:5}}>
                { !week.qualifiesForFLSA() && <KeyValueRow forceRow isListItem title='Pay' value={usdFormatter.format(payItem.payRate) + ' ' + payTypeDictionary[payItem.payType]}/>}
                { payItem.payType === 'py' && !week.qualifiesForFLSA() &&
                    <>
                        <KeyValueRow forceRow isListItem title={'Salary'} value={usdFormatter.format(payItem.payRate)}/>
                        <KeyValueRow forceRow valueStyle={{color: 'green', opacity: 1.0}} isListItem title={'Salary Wages'} value={bigToUsd(payItem.getWages())}/>
                    </>
                }
                { payItem.payType === 'pd' && !week.qualifiesForFLSA() &&
                    <>
                        <KeyValueRow forceRow isListItem title={'Days Worked'} value={payItem.unitsWorked}/>
                        <KeyValueRow forceRow isListItem title={'Daily Rate'} value={usdFormatter.format(payItem.payRate)}/>
                        <KeyValueRow forceRow valueStyle={{color: 'green', opacity: 1.0}} isListItem title={'Daily Wages'} value={bigToUsd(payItem.getWages())}/>
                    </>
                }
                { payItem.payType === 'ph' && !week.qualifiesForFLSA() &&
                    <>
                        <KeyValueRow forceRow isListItem title={'Hours Worked'} value={payItem.unitsWorked}/>
                        <KeyValueRow forceRow isListItem title='Hourly Rate' value={usdFormatter.format(payItem.payRate)}/>
                        <KeyValueRow forceRow valueStyle={{color: 'green', opacity: 1.0}} isListItem title='Hourly Wages' value={bigToUsd(payItem.getWages())}/>
                    </>
                }
                { payItem.payType === 'ps' && !week.qualifiesForFLSA() &&
                    <>
                        <KeyValueRow forceRow isListItem title='Stops' value={payItem.unitsWorked}/>
                        <KeyValueRow forceRow isListItem title='Stop Pay Rate' value={usdFormatter.format(payItem.payRate)}/>
                        <KeyValueRow forceRow valueStyle={{color: 'green', opacity: 1.0}} isListItem title='Stop Wages' value={bigToUsd(payItem.getWages())}/>
                    </>
                }
                { payItem.payType === 'pm' && !week.qualifiesForFLSA() &&
                    <>
                        <KeyValueRow forceRow isListItem title='Miles' value={payItem.unitsWorked}/>
                        <KeyValueRow forceRow isListItem title='Mile Pay Rate' value={usdFormatter.format(payItem.payRate)}/>
                        <KeyValueRow forceRow valueStyle={{color: 'green', opacity: 1.0}} isListItem title='Mile Wages' value={bigToUsd(payItem.getWages())}/>
                    </>
                }
            </ListGroup>
        )
    });

    const ndBonuses = week.ndBonuses.map((b, i) => {
        return (
            <ListGroup key={i}>
                <KeyValueRow forceRow={true} valueStyle={{color: 'green', opacity: 1.0}} isListItem title={b.label} value={usdFormatter.format(b.amount)}/>
            </ListGroup>
        )
    });

    const payrollPeriodStartIndex = parseInt(moment(entry.periodStart).format('d'));
    const payrollPeriodStartOffset = payrollPeriodStartIndex == 6 ? 0 : payrollPeriodStartIndex + 1; //data it stored as [sat, sun, mon, ...] while table columns are based on the payroll period date range

    const otStopWages = [0, 1, 2, 3, 4, 5, 6].filter((dayIndex) => {
        const offsetDayIndex = (dayIndex + payrollPeriodStartOffset) % 7;
        const result = validateInteger(week.otStopWageDefinitions[offsetDayIndex].threshold) > 0 && validateInteger(week.stops[offsetDayIndex]) > validateInteger(week.otStopWageDefinitions[offsetDayIndex].threshold) && validateDecimal(week.otStopWageDefinitions[offsetDayIndex].amount) > 0;
        return result;
    }).map((dayIndex) => {
        const offsetDayIndex = (dayIndex + payrollPeriodStartOffset) % 7;
        const date = moment(entry.periodStart).add(index, 'weeks').add(dayIndex, 'days').format('ddd D');
        const bonus = usdFormatter.format((validateInteger(week.stops[offsetDayIndex]) - validateInteger(week.otStopWageDefinitions[offsetDayIndex].threshold)) * validateDecimal(week.otStopWageDefinitions[offsetDayIndex].amount));
        return (
            <KeyValueRow forceRow isListItem key={dayIndex} title={date} value={bonus}/>
        )

    })

    const additionalPay = week.additionalPay.map((item, index) => {
        return (
            <ListGroup key={index}>
                <KeyValueRow forceRow={true} valueStyle={{color: 'green', opacity: 1.0}} isListItem title={item.label} value={usdFormatter.format(item.amount)}/>
            </ListGroup>
        )
    })

    const ptoElements = week.ptoArray.map((pto, index) => {
        return (
            <ListGroup key={pto.uid} style={{padding:10}}>
                <span style={{color:'black', marginLeft:10, fontWeight:'bold'}}>{'PTO Item #'+(index+1)}</span>
                <KeyValueRow forceRow={true} isListItem title={'PTO Pay Rate'} value={usdFormatter.format(pto.payRate)}/>
                <KeyValueRow forceRow={true} isListItem title={'PTO Hours'} value={pto.hours}/>
                <KeyValueRow forceRow={true} valueStyle={{color: 'green', opacity: 1.0}} isListItem title={'PTO Pay'} value={bigToUsd(pto.getPtoPay())}/>
            </ListGroup>
        )
    })

    const holidayElements = week.holidayArray.map((holiday, index) => {
        let holidayType = holiday.payType === 'pd' ? 'Day(s)' : 'Hour(s)';
        return (
            <ListGroup key={holiday.id} style={{padding:10}}>
                <span style={{color:'black', marginLeft:10, fontWeight:'bold'}}>{'Holiday Item #'+(index+1)}</span>
                {holiday.payType !== 'flat' &&  <KeyValueRow forceRow={true} isListItem title={'Holiday ' + holidayType} value={holiday.unitsWorked}/>}
                <KeyValueRow forceRow={true} isListItem title={'Holiday Pay Rate (' + holidayPayTypeDictionary[holiday.payType] + ')'} value={usdFormatter.format(holiday.payRate)}/>
                <KeyValueRow forceRow={true} valueStyle={{color: 'green', opacity: 1.0}} isListItem title={'Holiday Pay'} value={bigToUsd(holiday.holidayPay())}/>
            </ListGroup>
        )
    })

    return (
        <ListGroup>
            <KeyValueRow forceRow isListItem title='Pay Week' value={weekStart.format('MMM D, YYYY') + ' - ' +weekEnd.format('MMM D, YYYY')}/>
            <KeyValueRow forceRow isListItem title='Overtime Threshold' value={'40 hours'}/>
            <KeyValueRow forceRow isListItem title='Operated Vehicle Weighing Under 10k lbs.?' value={week.under10k ? 'Yes' : 'No'}/>
            <KeyValueRow forceRow valueStyle={{color: 'green', opacity: 1.0}} isListItem title='Meets Requirements for FLSA Overtime?' value={week.qualifiesForFLSA() ? 'Yes' : 'No'}/>
            { week.qualifiesForFLSA() &&
            <>
                <KeyValueRow forceRow isListItem title='Pay' value={usdFormatter.format(week.hourlyRate()) + ' ' + payTypeDictionary['ph']}/>
                <KeyValueRow forceRow isListItem title={'Hours Worked (Excluding Overtime)'} value={week.straightTimeHoursWorked().toFixed(2)}/>
                <KeyValueRow forceRow isListItem title='Hourly Rate' value={bigToUsd(week.hourlyRate())}/>
                <KeyValueRow forceRow valueStyle={{color: 'green', opacity: 1.0}} isListItem title='Hourly Wages' value={bigToUsd(week.hourlyWages())}/>
                <KeyValueRow forceRow isListItem title='Overtime Hours' value={week.overtimeHoursWorked().toFixed(2)}/>
                <KeyValueRow forceRow isListItem title='Overtime Rate' value={bigToUsd(week.overtimeRate())}/>
                <KeyValueRow forceRow valueStyle={{color: 'green', opacity: 1.0}} isListItem title='Overtime Wages' value={bigToUsd(week.overtimeWages())}/>
            </>
            }
            {payCards}
            <Accordion style={{marginBottom:5}} alwaysOpen>
                <Accordion.Item  eventKey={'0'}>
                    <AccordionHeader>
                        <div style={{flex:1, display:'flex', justifyContent:'space-between', alignItems:'center', marginRight:10}}>
                            <span>{'Nondiscretionary Bonuses:'}</span>
                            <span style={{color: 'green', opacity: 1.0}}>{bigToUsd(week.getNDBonuses())}</span>
                        </div>
                    </AccordionHeader>
                    <AccordionBody>
                        {ndBonuses}
                    </AccordionBody>
                </Accordion.Item>
                <Accordion.Item  eventKey={'-1'}>
                    <AccordionHeader>
                        <div style={{flex:1, display:'flex', justifyContent:'space-between', alignItems:'center', marginRight:10}}>
                            <span>{'Over-Threshold Stop Wages:'}</span>
                            <span style={{color: 'green', opacity: 1.0}}>{bigToUsd(week.totalOTStopWages())}</span>
                        </div>
                    </AccordionHeader>
                    <AccordionBody style={{padding: 8}}>
                        <ListGroup>
                            {otStopWages}
                        </ListGroup>
                    </AccordionBody>
                </Accordion.Item>
                <Accordion.Item  eventKey={'1'}>
                    <AccordionHeader>
                        <div style={{flex:1, display:'flex', justifyContent:'space-between', alignItems:'center', marginRight:10}}>
                            <span>{'Additional Pay:'}</span>
                            <span style={{color: 'green', opacity: 1.0}}>{bigToUsd(week.getAdditionalPay())}</span>
                        </div>
                    </AccordionHeader>
                    <AccordionBody>
                        {additionalPay}
                    </AccordionBody>
                </Accordion.Item>
                <Accordion.Item  eventKey={'2'}>
                    <AccordionHeader>
                        <div style={{flex:1, display:'flex', justifyContent:'space-between', alignItems:'center', marginRight:10}}>
                            <span>{'PTO Pay:'}</span>
                            <span style={{color: 'green', opacity: 1.0}}>{bigToUsd(week.totalPtoWages())}</span>
                        </div>
                    </AccordionHeader>
                    <AccordionBody>
                        {ptoElements}
                    </AccordionBody>
                </Accordion.Item>
                <Accordion.Item  eventKey={'3'}>
                    <AccordionHeader>
                        <div style={{flex:1, display:'flex', justifyContent:'space-between', alignItems:'center', marginRight:10}}>
                            <span>{'Holiday Pay:'}</span>
                            <span style={{color: 'green', opacity: 1.0}}>{bigToUsd(week.totalHolidayWages())}</span>
                        </div>
                    </AccordionHeader>
                    <AccordionBody>
                        {holidayElements}
                    </AccordionBody>
                </Accordion.Item>
            </Accordion>
            {week.qualifiesForFLSA() &&
                <Card style={{margin:6}}>
                    <Card.Body>
                        <Card.Title>FLSA Calculation</Card.Title>
                        <p>All nondiscretionary bonuses (including OT stop wages, incentive wages, and stand-by wages) are incorporated into the overtime pay rate by dividing the bonus pay by the number of hours worked and adding it to the hourly wage</p>
                        <div>
                            <p style={{marginBottom: 0}}>{'Overtime pay rate = ( ( hourly wage + (nondiscretionary bonuses  ÷ hours worked) ) ÷ 2.0 ) + hourly wage'}</p>
                            <FontAwesomeIcon icon={faArrowRight} style={{marginLeft: 12, marginRight: 12}}/>
                            <span>{'( (' + bigToUsd(week.hourlyRate()) + ' + (' + bigToUsd(week.ndBonusesIncorporatedIntoOvertimeRate())  + ' ÷ ' + week.getHoursWorked() + ') ) ÷ 2.0 ) + ' + bigToUsd(week.hourlyRate())  + ' = '}</span>
                            <span style={{fontWeight: 'bold'}}>{bigToUsd(week.overtimeRate())}</span>
                        </div>
                    </Card.Body>
                </Card>
            }
        </ListGroup>
    )
}