/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import { getCompanies } from '../../services/CompanyServices/CompaniesService';
import DropdownControl from '../../components/DropdownControl';
import CustomButton from '../../components/CustomButton';
import { addCompanyUser } from '../../services/HRServices/HRService';
import { getCompanyCsas } from '../../services/CompanyServices/CompanyCsasService';
import { validateExistence, ValidationGroup } from '../../validation';

export default function HRAddCompanyUser({user, hideModal}) {
    const [isLoading, setIsLoading] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(undefined);
    const [csas, setCsas] = useState([]);
    const [selectedCsa, setSelectedCsa] = useState(undefined);
    const [csasLoading, setCsasLoading] = useState(false);

    const validationGroup = new ValidationGroup();

    const handleSetSelectedCompany = (value) => {
        setCsasLoading(true);
        setSelectedCompany(value);
        if (value) {
            getCompanyCsas(value).then((response) => {
                if (response.status === '200') {
                    setCsas(response.csas);
                }
                setCsasLoading(false);
            })
        }

    }

    const handleSubmit = () => {
        addCompanyUser(user.companyUserUid, selectedCompany, selectedCsa).then((response) => {
            if (response.status === '200') {
                hideModal();
            }
        })
    }

    useEffect(() => {
        setIsLoading(true);
        getCompanies().then((response) => {
            if (response.status === '200') {
                setCompanies(response.data.filter(company=>!user.associatedCompanyIdentifiers.includes(parseInt(company.uid))));
            }
            setIsLoading(false);
        });
    }, [])


    return (
        <>
            <Modal.Header>
                <Modal.Title>Add User To Another Company</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                {companies.length > 0 ?
                    <>
                    <DropdownControl validator={validationGroup.createValidator(() => validateExistence(selectedCompany))} selection={selectedCompany} setSelection={handleSetSelectedCompany} itemValues={companies.map(r => r.uid)} itemNames={companies.map(r => r.companyName)} title='Company'/>
                    { (!csasLoading && selectedCompany) &&
                        <DropdownControl validator={validationGroup.createValidator(() => validateExistence(selectedCsa))} selection={selectedCsa} setSelection={setSelectedCsa} itemValues={csas.map(r => r.uid)} itemNames={csas.map(r => r.csaName)} title='CSA'/>
                    }
                    </>
                : <p>There are no companies that this user can be assigned to.</p>
            }
            </Modal.Body>
            <Modal.Footer>
                {companies.length > 0 && <CustomButton isLoading={isLoading} disabled={!validationGroup.isValid()} label='Submit' onClick={handleSubmit}/>}
            </Modal.Footer>
        </>
    )
}