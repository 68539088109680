import moment from "moment";
import { stateDictionary } from "./tools";

export function validateAvailablity(value) {
    if (!value.includes(true)) {
        return 'Must check at least one day';
    }
}

export function validateAddressPostalCode(value) {
    if (!value) {
        return 'Required';
    } else if (value && !/^\d{5,9}$/g.test(value)) {
        return 'Invalid Postal Code';
    }
}

export function validateAddressStreet2(value) {
    if (value && !/^[\w\s]{1,}\s{1}\d{1,}$/g.test(value)) {
        return 'Invalid Street Address 2'
    }
}

export function validateAddressStreet1(value) {
    if (!value) {
        return 'Required';
    }  else if (!/^\d{1,}\s[\s\w.]{1,}$/g.test(value)) {
        return 'Invalid Street Address 1';
    }
}

export function validateSsn(value) {
    if (!value) {
        return 'Required';
    } else if (!/^\d{9}$/g.test(value)) {
        return 'Invalid SSN';
    }
}

export function validatePhoneNumber(value) {
    if (!value) {
        return 'Required';
    } else if (!/^1?\d{10}$/g.test(value)) {
        return 'Invalid Phone Number';
    }
}

export function validateEmail(value) {
    if (!value) {
        return 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
        return 'Invalid email address';
    }
}

export function validateExistence(value, requiredLength = 1) {

    if (!value && value != '0') {
        return 'Required';
    } else if (value.toString().length < requiredLength) {
        return 'Invalid input';
    }
}

export function validatePositiveNumber(value) {
    if (!value && value != '0') {
        return 'Required';
    } else if (value < 0) {
        return 'Must enter positive number';
    }
}

export function validateGreaterThanZero(value) {
    if (!value && value != '0') {
        return 'Required';
    } else if (value <= 0) {
        return 'Must be > 0';
    }
}

export function validatePasswordMatch(value, secondValue) {
    if (!value && value != '0') {
        return 'Required';
    } else if (value !== secondValue) {
        return 'Passwords must match';
    }
}

export function validateStateSelection(value){
    if(!value){
        return 'Required';
    }
    let isStateValid = false;
    for(const key in stateDictionary){
        if(stateDictionary[key] === value){
            isStateValid = true;
            break;
        }
    }
    if(!isStateValid){
        return 'Please enter a valid state';
    }
}

export function validatePayDateEffective(existingPay, dayIndex, value) {
    if(!value){
        return 'Required';
    } else if (moment(value).format('d') != dayIndex) {
        return `Effective Date must be on the start of a new pay period (${['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayIndex]})`;
    } else if (existingPay.find(p => p.dateEffective == value)) {
        return 'Effective Date must not be on the same day as an existing pay';
    }
}

export function validateNonEmptyArray(value) {
    if(!value || value.length == 0) {
        return 'You must select at least 1 option';
    }
}

export class Validator {

    validation; //callback
    validMessage;

    constructor(validation, validMessage = '') {
        this.validation = validation;
        this.validMessage = validMessage;
    }



    isValid() {
        return !this.validation();
    }

    getInvalidMessage() {
        return this.isValid() ? '' : this.validation();
    }

    getValidMessage() {
        return this.isValid() ? this.validMessage : ''
    }


}

class KeyedValidator {
    value;
    validation;
    validMessage;

    constructor(value, validation, validMessage = '') {
        this.value = value
        this.validation = validation;
        this.validMessage = validMessage;
    }
    
    updateValue(value) {
        this.value = value;
    }

    isValid() {
        return !this.validation(this.value);
    }

    getInvalidMessage() {
        return this.isValid() ? '' : this.validation(this.value);
    }

    getValidMessage() {
        return this.isValid() ? this.validMessage : ''
    }
}

export class KeyedValidationGroup {
    validators = {}

    createValidator(key, value, validation, validMessage = '') {
        if (this.validators[key]) {
            this.validators[key].updateValue(value);
            return this.validators[key];
        } else {
            const newValidator = new KeyedValidator(value, validation, validMessage);
            this.validators[key] = newValidator;
            return newValidator;
        }
    }

    pushValidator(key, validator) {
        this.validators[key] = validator;
        return validator;
    }

    removeValidator(key){
        delete this.validators[key];
    }
    
    isValid() {
        return Object.values(this.validators).reduce((prev, curr) => {
            return prev && curr.isValid();
        }, true);
    }
}

export class ValidationGroup {

    validators = [];

    createValidator(validation, validMessage = '') {
        const newValidator = new Validator(validation, validMessage);
        this.validators.push(newValidator);
        return newValidator;
    }

    pushValidator(validator) {
        this.validators.push(validator);
        return validator;
    }

    isValid() {
        return this.validators.reduce((prev, curr) => {
            return prev && curr.isValid();
        }, true);
    }
}


export class AddressValidator {
    thoroughfare;
    premise;
    administrativeArea;
    locality;
    postalCode;

    constructor(address) {
        this.thoroughfare = new Validator(() => validateAddressStreet1(address.thoroughfare))
        this.premise = new Validator(() => validateAddressStreet2(address.premise))
        this.administrativeArea = new Validator(() => validateExistence(address.administrativeArea))
        this.locality = new Validator(() => validateExistence(address.locality))
        this.postalCode = new Validator(() => validateAddressPostalCode(address.postalCode))
    }

    isValid() {
        return this.thoroughfare.isValid() && this.premise.isValid() && this.administrativeArea.isValid() && this.locality.isValid() && this.postalCode.isValid();
    }
    
}