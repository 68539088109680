/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import CustomControl from './CustomControl';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

function AddressControl({title, originalValue, address, setAddress, xs, sm, md, lg, xl, xxl, validator, disabled, containerBreakPoints}) {

    const addressComponents = [
        {key: 'uid', title: 'addressIdentifier', max: 30},
        {key: 'thoroughfare', title: 'Street Address 1', max: 30},
        {key: 'premise', title: 'Street Address 2', max: 30},
        {key: 'locality', title: 'City', max: 20},
        {key: 'administrativeArea', title: 'State', max: 10},
        {key: 'postalCode', title: 'Postal Code', max: 5}
    ];

    const handleSetAddress = (key, value) => {
        const newAddress = address ? structuredClone(address) : {};
        newAddress[key] = value;
        setAddress(newAddress);
    }
    


    const addressComponentElements = addressComponents.map((item, index) => {
        return (
            <CustomControl type='text' key={item.key} disabled={disabled || index === 0} max={item.max} validator={validator ? validator[item.key] : undefined} originalValue={originalValue !== undefined ? originalValue[item.key] : undefined} title={item.title} value={address && address[item.key] ? address[item.key] : ''} onChange={(event) => {handleSetAddress(item.key, event.target.value)}} xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}/>
        )
    })

    const content = (
        <Form.Group>
            <Form.Label>{title}</Form.Label>
            <Container fluid style={{border: '1px solid lightgray', borderRadius: 6, padding: 12, marginBottom: 8}}>
                <Row>
                    {addressComponentElements}
                </Row>
            </Container>
        </Form.Group>
    );

    const contentInCol = containerBreakPoints ? (
        <Col style={{marginBottom: 8}} xs={containerBreakPoints.xs} sm={containerBreakPoints.sm} md={containerBreakPoints.md} lg={containerBreakPoints.lg} xl={containerBreakPoints.xl} xxl={containerBreakPoints.xxl}>
            {content}
        </Col>
    ) : '';

    //{thoroughfare: '456 fake ave.', premise: '', administrativeArea: 'Ohio', locality: 'Hudson', postalCode: '44236'},
    return (
        <>
            {containerBreakPoints ? contentInCol : content}
        </>
    )
}

export default AddressControl;