/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Modal from 'react-bootstrap/Modal';
import QuickTable from "../../components/QuickTable";
import SearchBar from "../../components/SearchBar";
import CustomSpinner from "../../components/CustomSpinner";
import { getCompanies, getCompanyUsers } from "../../services/CompanyServices/CompaniesService";
import { Button } from "react-bootstrap";


export default class UsersForEmail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            companies: [],
            selectedCompany: undefined,
            companyNameFilter: '',
            userFilter: '',
            userFilterType: 'name',
            loadingCompanies: false,
            loadingUsers: false,
        }
    }
 
    loadData() {
        if (!this.state.loadingCompanies) {
            this.setState({loadingCompanies: true});
            getCompanies().then((response) => {
                if (response && response.status === '200') {
                    this.setState({companies: response.data});
                }
            }).finally(() => {
                this.setState({loadingCompanies: false});
            });
        }
    }

    componentDidMount() {
        this.loadData();
    }

    async handleSelectCompany(company) {
        if (company.uid !== this.state.selectedCompany?.uid) {
            this.setState({selectedCompany: undefined, loadingUsers: true});
            const response = await getCompanyUsers(company.uid);
            if (response.status === '200') {
                this.setState({users: response.users, selectedCompany: company});
            }
            this.setState({loadingUsers: false});
        }
    }

    handleSelectUser(user) {
      
        const userToAdd = {
            userIdentifier:user.userIdentifier,
            companyUserUid:user.companyUserUid,
            firstName:user.firstName,
            lastName:user.lastName,
            email:user.email
        }
        let newArray = Array.from(this.props.usersForEmail);
      
        if (this.props.usersForEmail.find((u)=>u.userIdentifier=== user.userIdentifier)) {
            newArray = newArray.filter(u => u.userIdentifier !== user.userIdentifier);
        } else {
            newArray.push(userToAdd);
        }
        this.props.setUsersForEmail(newArray);
    }



    render() {
      
        const filteredCompanies = this.state.companies.filter((company) => {
            return company.companyName.toLocaleLowerCase().includes(this.state.companyNameFilter.toLocaleLowerCase());
        })

        const companyRows = this.state.loadingCompanies ? (
            <tr>
                <td colSpan={3}><CustomSpinner height={400}/></td>
            </tr>
        ) : filteredCompanies.map((company) => {
            return (
                <tr key={company.uid} className='cursor-pointer' onClick={() => {this.handleSelectCompany(company)}} style={this.state.selectedCompany === company.uid ? {backgroundColor: 'yellow'} : {}}>
                    <td>{company.uid}</td>
                    <td>{company.companyName}</td>
                    <td>{company.isEnabled === '0' ? 'No' : 'Yes'}</td>
                </tr>
            )
        });



        const filteredUsers = this.state.users.filter((user) => {
            if (this.state.userFilterType === 'name') {
                return (user.firstName + ' ' + user.lastName).toLocaleLowerCase().includes(this.state.userFilter.toLocaleLowerCase());
            } else {
                return user[this.state.userFilterType].toString().toLocaleLowerCase().includes(this.state.userFilter.toString().toLocaleLowerCase());
            }
        });

        const userRows = this.state.loadingUsers ? (
            <tr>
                <td colSpan={6}><CustomSpinner height={400}/></td>
            </tr>
        ) : filteredUsers.map((user) => {
            let userSelected = this.props.usersForEmail.find((u)=>u.userIdentifier === user.userIdentifier);
            return (
                <tr key={user.userIdentifier} style={{backgroundColor:userSelected ? 'yellow' : ''}} onClick={() => {this.handleSelectUser(user)}}>
                    <td>{user.userIdentifier}</td>
                    <td>{user.companyUserUid}</td>
                    <td>{user.lastName}</td>
                    <td>{user.firstName}</td>
                    <td>{user.title}</td>
                    <td>{user.csaName}</td>
                    {user.companyUserIsEnabled === '0' ?  <td>{user.title ? user.title : user.payType}</td> : <td>Active</td>}
                </tr>
            )
        });

        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>Select User</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{display: 'flex', gap: 12,}}>
                    <div>
                        <SearchBar label='Filter Companies By Name' value={this.state.companyNameFilter} setValue={(value) => {this.setState({companyNameFilter: value})}}/>
                        <div style={{height: 500, overflowY: 'scroll'}}>
                            <QuickTable title='Companies' headers={['UID', 'Company Name', 'Enabled?']} rows={companyRows} hover/>
                        </div>
                    </div>
                    <FontAwesomeIcon icon={faArrowRight} style={{alignSelf: 'center'}}/>
                    <div>
                        <SearchBar 
                            label='Filter Users By...' 
                            value={this.state.userFilter} 
                            setValue={(value) => {this.setState({userFilter: value})}} 
                            type={this.state.userFilterType} 
                            setType={(value) => {this.setState({userFilterType: value})}} 
                            typeValues={['name', 'title', 'csaName']}  
                            typeNames={['Name', 'Title', 'CSA']} 
                        />
                        <div style={{height: 500, overflowY: 'scroll'}}>
                            <QuickTable title='Users' headers={['User ID', 'CompanyUser ID', 'Last Name', 'First Name', 'Title', 'CSA', 'Status']} rows={userRows} hover/>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.hideModal}>Finish</Button>
                </Modal.Footer>
            </>
        )
    }
}