/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Validator, validateGreaterThanZero } from "../../../../../../validation";
import QuickTable from "../../../../../../components/QuickTable";
import { adminMode, bigToUsd, toFixedMax } from "../../../../payrollTools";
import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import moment from "moment";
// import HRUserPto from "../../../../../HumanResources/HRUserPTO/HRUserPto";
import Pto from "../../../../Models/Pto";
import CustomControl from "../../../../../../components/CustomControl";
import { ptoAccrualTypeDictionary, usdFormatter } from "../../../../../../tools";
import Picker from "../../../../../../components/Picker";

export default function PayrollEmployeePtoEditor({entry, handleSet}) {
    const [showModal, setShowModal] = useState(false);
    const [showError, setShowError] = useState(false);

    const weekElements = entry.weeks.map((week, index) => {
        const weekStart = moment(entry.periodStart).add(index, 'weeks');
        const weekEnd = moment(weekStart).add(6, 'days');


        const ptoElements = week.pto.map((pto) => {
            return (
                <tr key={pto.uid}>
                    <td>{moment(pto.date).format('MMM D, YYYY')}</td>
                    <td>{usdFormatter.format(pto.payRate)}</td>
                    <td>{pto.hours}</td>
                    <td>{bigToUsd(pto.getPtoPay())}</td>
                </tr>
            )
        });


        return (
            <React.Fragment key={index}>
                { entry.weeks.length > 1 && week.pto.length > 0 && 
                    <tr key={index + 'week'}>
                        <td colSpan={4} style={{textAlign: 'center', fontWeight: 'bold'}}>{`Week #${index + 1} (${weekStart.format('MMM D')} - ${weekEnd.format('MMM D')})`}</td>
                    </tr>
                }
                {ptoElements}
            </React.Fragment>
        )
    })

    


    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <Card.Title>PTO</Card.Title>
            { entry.weeks.reduce((prev, curr) => {return prev + curr.pto.length}, 0) > 0 &&
                <QuickTable headers={['Date Scheduled', 'Rate', 'Hours', 'PTO Wages']} rows={weekElements} size={'sm'}/>
            }
            <div style={{marginBottom: 8, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Container fluid >
                    <Row>
                        <Picker lg={6} title='PTO Accrual Type' itemNames={Object.values(ptoAccrualTypeDictionary)} itemValues={Object.keys(ptoAccrualTypeDictionary)} selection={entry.ptoAccrualType} setSelection={(value) => {handleSet(entry.userIdentifier, 'ptoAccrualType', value)}}/>
                        <Col lg={6}>
                            <CustomControl floatingLabel title={`PTO Accrual ${ptoAccrualTypeDictionary[entry.ptoAccrualType]}`} value={entry.ptoAccrual} setValue={(value) => {handleSet(entry.userIdentifier, 'ptoAccrual', value)}} onChange={(event) => {handleSet(entry.userIdentifier, 'ptoAccrual', event.target.value)}}/>
                        </Col>
                    </Row>
                </Container>
                <QuickTable noWrap noMargin headers={['', 'Current PTO Balance', 'PTO to be Accrued', 'Projected Balance']} size='sm' rows={[
                    <tr key={1}>
                        <td>Hours</td>
                        <td>{toFixedMax(entry.ptoBalance, 3)}</td>
                        <td>{toFixedMax(entry.getPtoHoursAccrued(), 3)}</td>
                        <td>{toFixedMax(entry.ptoBalance + entry.getPtoHoursAccrued(), 3)}</td>
                    </tr>
                ]}/>
            </div>
            
            <Modal show={showModal} onHide={() => {setShowModal(false)}} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>{`PTO for ${entry.name()}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <HRUserPto user={{uid: entry.userIdentifier}} startDate={entry.periodStart} endDate={entry.periodEnd} handleRecalculate={handleRecalculate}/> */}
                </Modal.Body>
            </Modal>
            <Modal show={showError} onHide={() => {setShowError(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Failed to Recalculate Employee's PTO</Modal.Title>
                </Modal.Header>
                <Modal.Body>Something went wrong. The PTO listed in Payroll may be out of sync with the data in our system.</Modal.Body>
            </Modal>
        </div>
    )
}

