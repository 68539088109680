/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import moment from 'moment';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserInjured } from '@fortawesome/free-solid-svg-icons';
import { fakeHRUserBWCData } from '../../../fake-data';
import Card from 'react-bootstrap/Card';
import HRUserBWCDetails from './HRUserBWCDetails';
import HRUserBWCForm from './HRUserBWCForm';
import CustomSpinner from '../../../components/CustomSpinner';
import { getHRUserBWCRecords } from '../../../services/HRServices/HRBWCService';

class HRUserBWC extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            users: [],
            BWCs: [],
            showForm: false,
            showDetails: false,
            selectedBWC: undefined,
        }

        this.hideModal = this.hideModal.bind(this);
        this.handleAddRecord = this.handleAddRecord.bind(this);
        this.handleReviseRecord = this.handleReviseRecord.bind(this);
    }

    sortBWCs(a, b) {
        
        if (moment(a.injuryReportDate).isBefore(moment(b.injuryReportDate), 'hours')) {
            return 1;
        } else if (moment(a.injuryReportDate).isAfter(moment(b.injuryReportDate), 'hours')) {
            return -1;
        } else {
            return 0;
        }
    }

    loadData() {
        if (!this.state.isLoading) {
            this.setState({isLoading: true});
        }
        getHRUserBWCRecords(this.props.companyUserUid).then((response) => {
            if (response && response.status === '200') {
                response.data.forEach((item) => {
                    if (item.treatmentFacilityAddress) {
                        item.treatmentFacilityAddress = response.addresses.find(r => r.uid === item.treatmentFacilityAddress);
                    } else {
                        item.treatmentFacilityAddress = {};
                    }
                });

                this.setState({
                    BWCs: response.data.sort(this.sortBWCs)
                });
            }
            this.setState({isLoading: false});
        });


    }

    handleAddRecord(record) {
        const newArray = Array.from(this.state.BWCs);
        newArray.push(record);
        newArray.sort(this.sortBWCs);
        this.setState({BWCs: newArray, modalSwitch: ''});
    }

    handleReviseRecord(record) {
        const newArray = Array.from(this.state.BWCs.filter(i => i.bwcRecordsIdentifier !== this.state.selectedBWC.bwcRecordsIdentifier));
        newArray.push(record);
        newArray.sort(this.sortBWCs);
        this.setState({BWCs: newArray, selectedBWC: record, modalSwitch: 'details'});
    }

    hideModal() {
        this.setState({modalSwitch: ''});
    }
    

    componentDidMount() {
        this.loadData();
    }

    render() {

        
        const status = this.state.BWCs.length > 0 ? (this.state.BWCs[0].rtwFullDutyDate ? 'full' : this.state.BWCs[0].rtwLightDutyDate ? 'light' : 'none') : undefined;

        const bwcElements = this.state.BWCs.map((item) => {

            const info = {};
            info['Date of Injury'] = moment(item.dateOfInjury).format('MMM D, YYYY');
            info['Date Reported'] = moment(item.injuryReportDate).format('MMM D, YYYY');

            if (this.props.windowWidth >= 992) {
                info['RTW (Light Duty)'] = item.rtwLightDutyDate ? moment(item.rtwLightDutyDate).format('MMM D, YYYY') : 'N/A';
                
                info['RTW (Full Duty)'] = item.rtwFullDutyDate ? moment(item.rtwFullDutyDate).format('MMM D, YYYY') : 'Unknown';
                
                info['Last Updated'] = moment(item.submissionDateTime).format('MMM D, YYYY');
            }

            
            const infoElements = Object.keys(info).map((key) => {
                const value = info[key];

                return (
                    <div key={key} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', flex: 1, borderLeft: '1px solid var(--bs-primary)', fontSize: 12}}>
                        <p style={{marginBottom: 0, fontWeight: 'bold'}}>{key}</p>
                        <p style={{marginBottom: 0}}>{value}</p>
                    </div>
                )

            });


            return (
                
                <Card key={item.bwcRecordsIdentifier} className='cursor-pointer'  onClick={() => {this.setState({selectedBWC: item, modalSwitch: 'details'})}} style={{display: 'flex', flexDirection: 'row', flex: 1}}>

                    <div style={{display: 'flex', height: '100%', padding: 18, gap: 12, borderTopLeftRadius: 6, borderBottomLeftRadius: 6, backgroundColor: 'var(--bs-primary)', alignItems: 'center', flexShrink: 0}}>
                        <FontAwesomeIcon icon={faUserInjured} style={{color: 'white'}}/>
                    </div>
                    <div style={{borderTopRightRadius: 6, borderBottomRightRadius: 6, border: '2px solid var(--bs-primary)', borderLeft: 'none', flex: 1, minWidth: 0, alignItems: 'center', display: 'flex'}}>
                        {infoElements}
                    </div>
                </Card>
                
            )
        });


        const content = (
            <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    { status && status !== 'full' ?
                        <Alert style={{marginBottom: 0, flex: 1}} variant='warning'>{status === 'light' ? 'According to the most recent BWC report, employee is currently performing light duty.' : 'According to the most recent BWC claim, employee has not yet returned to work.'}</Alert>
                        : ''
                    }
                    <div/>
                    <Button style={{color: 'white', marginLeft: 12}} onClick={() => {this.setState({modalSwitch: 'form', selectedBWC: undefined})}}>Create New BWC Report</Button>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: 12, backgroundColor: 'rgb(240, 240, 240)', padding: 8, borderRadius: 6, maxHeight: 400, overflowY: 'scroll'}}>
                    {bwcElements}
                </div>

            </div>
        )

        return (
            <>
                {this.state.isLoading ? <CustomSpinner spinnerHeight='10vh' spinnerWidth='10vh' height='50vh'/> : content}
                <Modal show={this.state.modalSwitch === 'details'} onHide={this.hideModal} size='lg' centered>
                    <HRUserBWCDetails users={this.props.users} bwcRecord={this.state.selectedBWC} reviseRecord={() => {this.setState({modalSwitch: 'form'})}}/>
                </Modal>
                <Modal show={this.state.modalSwitch === 'form'} onHide={this.hideModal} size='lg' centered>
                    <HRUserBWCForm user={this.props.user} users={this.props.users} selectedBWC={this.state.selectedBWC} handleAddRecord={this.handleAddRecord} handleReviseRecord={this.handleReviseRecord}/>
                </Modal>
            </>
        )
    }
}


export default HRUserBWC;