/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import CustomButton from "../CustomButton";

export default function AlertModal({show, onHide, centered, size, callBack, title, message, buttonLabel}) {

    const [isLoading, setIsLoading] = useState(false);
    const [didError, setDidError] = useState(false);

    const handleCallBack = async () => {
        setIsLoading(true);
        if (didError) {
            setDidError(false);
        }
        const response = await callBack();
        if (response) {
            onHide();
        } else {
            setDidError(true);
        }
        setIsLoading(false);
    }

    return (
        <Modal show={show} onHide={onHide} centered={centered} size={size}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message}
            </Modal.Body>
            <Modal.Footer>
                { didError &&
                    <span style={{color: 'red'}}>Something went wrong</span>
                }
                <CustomButton isLoading={isLoading} label={buttonLabel} onClick={handleCallBack}/>
            </Modal.Footer>
        </Modal>
    )
}