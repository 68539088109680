/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import Button from 'react-bootstrap/Button';
import CustomSpinner from "../../../components/CustomSpinner";
import { getPayrollPeriod as getPayrollPeriodAdmin } from "../../../services/PayrollServiceMtb";
import { getPayrollPeriod as getPayrollPeriodClient } from "../../../services/PayrollServiceClient";
import { ButtonGroup } from "react-bootstrap";
import PayrollCSADownloadModal from "../PayrollCSVDownloadModal";
import PayrollEntry from "../Models/PayrollEntry";
import PayrollPeriodTable from "./PayrollPeriodTable";
import PayrollPeriodPdf from "./PayrollPeriodPdf";
import { adminMode } from "../payrollTools";


export default class PayrollPeriod extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            clientSubmission: undefined,
            ttaSubmission: undefined,


            periodStart: undefined,
            periodEnd: undefined,
            tabSwitch: true,
            showModal: false,
            showPdf: false,
            isCustom: false
        }
    }

    async loadData() {
        this.setState({isLoading: true});

        if (adminMode) {
            const response = await getPayrollPeriodAdmin(this.props.selectedPayPeriod.uid);
            if (response.status === 200) {
                this.setState({
                    clientSubmission: response.payrollPeriod.clientSubmission ? PayrollEntry.decodeArray(response.payrollPeriod.clientSubmission.entries) : undefined,
                    ttaSubmission: response.payrollPeriod.ttaSubmission ? PayrollEntry.decodeArray(response.payrollPeriod.ttaSubmission.entries) : undefined,
                    periodStart: response.payrollPeriod.periodStart, 
                    periodEnd: response.payrollPeriod.periodEnd,
                    isCustom: response.payrollPeriod.isCustom == 1,
                });
                
            }
        } else {
            const response = await getPayrollPeriodClient(this.props.selectedPayPeriod.uid);
            if (response.status === 200) {
                this.setState({
                    clientSubmission: response.payrollPeriod.clientSubmission ? PayrollEntry.decodeArray(response.payrollPeriod.clientSubmission.entries) : undefined,
                    ttaSubmission: response.payrollPeriod.ttaSubmission ? PayrollEntry.decodeArray(response.payrollPeriod.ttaSubmission.entries) : undefined,
                    periodStart: response.payrollPeriod.periodStart, 
                    periodEnd: response.payrollPeriod.periodEnd,
                    isCustom: response.payrollPeriod.isCustom == 1,
                });
            }
        }
        
        this.setState({isLoading: false});
    }

    componentDidMount() {
        this.loadData();
    }

    render() {

        const useTabbedView = this.state.clientSubmission && this.state.ttaSubmission;
      
        const content = (
            <div style={{padding: 12, display: 'flex', flexDirection: 'column', gap: 12}}>
                { useTabbedView ?
                    <>
                        <ButtonGroup>
                            <Button style={{backgroundColor: this.state.tabSwitch ? 'white' : 'var(--bs-primary)', color: this.state.tabSwitch ? 'var(--bs-primary)' : 'white'}} onClick={() => {this.setState({tabSwitch: false})}}>Company Submission</Button>
                            <Button style={{backgroundColor: !this.state.tabSwitch ? 'white' : 'var(--bs-primary)', color: !this.state.tabSwitch ? 'var(--bs-primary)' : 'white'}} onClick={() => {this.setState({tabSwitch: true})}}>TTA Submission</Button>
                        </ButtonGroup>
                        {this.state.tabSwitch && 
                            <span style={{backgroundColor:'yellow', width: 'max-content'}}>Updated From Company Submission</span>
                        }
                        <PayrollPeriodTable entries={this.state.tabSwitch ? this.state.ttaSubmission : this.state.clientSubmission} originalEntries={this.state.tabSwitch ? this.state.clientSubmission : undefined} periodStart={this.state.periodStart} periodEnd={this.state.periodEnd}/> 
                    </>
                    :
                    <>
                        {this.state.clientSubmission &&
                            <>
                                <div style={{textAlign:'center'}}>
                                    <span style={{fontWeight:'bold', fontSize:18}}>Company Submission</span>
                                </div>
                                <PayrollPeriodTable entries={this.state.clientSubmission} originalEntries={undefined} periodStart={this.state.periodStart} periodEnd={this.state.periodEnd}/> 
                            </>
                        }
                        { this.state.ttaSubmission &&
                            <>
                                <div style={{textAlign:'center'}}>
                                    <span style={{fontWeight:'bold', fontSize:18}}>TTA Submission</span>
                                </div>
                                <PayrollPeriodTable entries={this.state.ttaSubmission} originalEntries={undefined} periodStart={this.state.periodStart} periodEnd={this.state.periodEnd}/> 
                            </>
                        }
                    </>
                }
            </div>
        );
       
        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>{(this.state.isCustom ? 'Custom ' : '') + 'Payroll for ' + this.props.companyName + ' Between ' + moment(this.state.periodStart).format('MMM D, YYYY') + ' - ' + moment(this.state.periodEnd).format('MMM D, YYYY')}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                    {this.state.isLoading ? <CustomSpinner height={300}/> : content}
                </Modal.Body>
                <Modal.Footer>
                    { this.props.editPeriod &&
                        <Button variant={'outline-primary'} onClick={this.props.editPeriod}>Edit Period</Button>
                    }
                    <Button variant={'outline-primary'} onClick={() => {this.setState({showModal: true})}}>Download Payroll CSV</Button>
                    <Button variant={'outline-primary'} onClick={() => {this.setState({showPdf: true})}}>View Payroll PDF</Button>
                </Modal.Footer>
                <Modal show={this.state.showModal} backdrop='static' centered>
                    <PayrollCSADownloadModal 
                        entries={this.state.ttaSubmission ? this.state.ttaSubmission : this.state.clientSubmission} 
                        callBack={() => {this.setState({showModal: false})}}
                        periodStart={this.state.periodStart} 
                        periodEnd={this.state.periodEnd} 
                        companyName={this.props.companyName}
                    />
                </Modal>
                <Modal show={this.state.showPdf} onHide={()=>this.setState({showPdf:false})} fullscreen>
                    <PayrollPeriodPdf 
                        entries={this.state.ttaSubmission ? this.state.ttaSubmission : this.state.clientSubmission}
                        originalEntries={useTabbedView ? this.state.clientSubmission : undefined}
                        callBack={() => {this.setState({showModal: false})}}
                        periodStart={this.state.periodStart} 
                        periodEnd={this.state.periodEnd} 
                        companyName={this.props.companyName}
                    />
                </Modal>
            </>
        );
    }
}
