/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Dropdown from 'react-bootstrap/Dropdown';

export default function DropdownControl({selection, setSelection, itemNames, itemValues, title, validator}) {
    const [initialized, setInitialized] = useState(false);
    const [search, setSearch] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [touched, setTouched] = useState(false);

    if (!initialized && selection) {
        setInitialized(true);
        for (let i = 0; i < itemValues.length; i++) {
            if (itemValues[i] === selection) {
                setSearch(itemNames[i]);
                break;
            }
        }
    }

    let filteredItemNames = [];
    let filteredItemValues = [];

    for (let i = 0; i < itemNames.length; i++) {
        if (itemNames[i].toLowerCase().includes(search.toLowerCase())) {
            filteredItemNames.push(itemNames[i]);
            filteredItemValues.push(itemValues[i]);
        }
    }

    const dropdownItems = filteredItemNames.map((name, index) => {
        return (
            <Dropdown.Item key={itemValues[index]} eventKey={name} active={selection && selection === filteredItemValues[index]} onClick={() => {setSelection(filteredItemValues[index]); setSearch(name)}}>{name}</Dropdown.Item>
        )
    });


    const handleOnChange = (event) => {
        setSearch(event.target.value);
        setSelection(undefined);
    }

    return (
        <Form.Group style={{marginBottom: 8}}>
            <Form.Label>{title}</Form.Label>
            <InputGroup>
                <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch}/>
                </InputGroup.Text>
                <Form.Control 
                    type='text' 
                    value={search} 
                    onChange={handleOnChange} 
                    onFocus={() => {setShowDropdown(true)}} 
                    onBlur={() => {setTimeout(() => {setShowDropdown(false); if (!touched) {setTouched(true)}}, 150)}}
                    isValid={selection}
                    isInvalid={validator && touched ? !selection : undefined}
                />
                { validator && touched ?
                    <Form.Control.Feedback type='invalid'>{validator.getInvalidMessage()}</Form.Control.Feedback>
                    : ''
                }
            </InputGroup>
            <Dropdown.Menu show={showDropdown} style={{maxHeight: 200, overflowY: 'scroll', marginLeft: 40}}>
                {dropdownItems}
            </Dropdown.Menu>

        </Form.Group>
    )
}
