/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import officeMeme from './OfficeMeme.gif'
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import fedExBanner from '../../assets/fed-ex-banner.jpeg';

function ErrorPage() {
    return (
        <div style={{display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-end'}}>
            <div style={{flex: 1}}>
                {/* <img src={fedExBanner} alt='background' style={{position: 'fixed', zIndex: -1, height: '100%', right: 0}}/> */}
                <div style={{display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.7)'}}>
                    <p style={{fontSize: 200, margin: 0}}>404</p>
                    <h1>The page you are looking for does not exist or has not yet been implemented</h1>
                </div>
            </div>
            <NavigationBar/>
        </div>
        
    );
}

export default ErrorPage;