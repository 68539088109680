/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

export default function CustomTabs({value, setValue, tabs, flex}) {

    const tabButtons = tabs.map((label, index) => {
        const isActive = value === index;

        return (
            <Button key={label} variant='outline-primary' onClick={() => {setValue(index)}} style={isActive ? {backgroundColor: 'var(--bs-primary)', color: 'white'} : {}}>
                {label}
            </Button>
        )
    })

    return (
        <ButtonGroup>
            {tabButtons}
        </ButtonGroup>
    )
}