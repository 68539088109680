/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from "./serviceTools";

export async function getPayrollHistory() {
    var data = {
        type: 'mtbPayroll',
        target: 'getAll',
        authToken: getToken(),
    }
    const response = await getResponse(data);

    return response;
}

export async function getPayrollPeriod(uid) {
    var data = {
        type: 'mtbPayroll',
        target: 'get',
        authToken: getToken(),
        uid: uid
    }
    const response = await getResponse(data);
    return response;
}
    
export async function reviewPayrollPeriod(uid) {
    var data = {
        type: 'mtbPayroll',
        target: 'review',
        authToken: getToken(),
        uid: uid
    }
    const response = await getResponse(data);
    return response;
}

export async function approvePayrollPeriod(payrollVersion) {
    var data = {
        type: 'mtbPayroll',
        target: 'approve',
        authToken: getToken(),
        payrollVersion: payrollVersion,
    }

    return await getResponse(data);
}

export async function savePayrollVersion(payrollVersion){
    var data = {
        type:'mtbPayroll',
        target: 'saveVersion',
        authToken: getToken(),
        payrollVersion: payrollVersion
    }

    const response = await getResponse(data);
    return response;
}

export async function getPayrollVersion(uid) {
    var data = {
        type: 'mtbPayroll',
        target: 'getVersion',
        authToken: getToken(),
        uid: uid,
    }

    return await getResponse(data);
}

export async function hydratePayrollEntry(entry) {
    var data = {
        type: 'mtbPayroll',
        target: 'hydratePayrollEntry',
        authToken: getToken(),
        entry: entry,
    }

    return await getResponse(data);
}

export async function deleteCustomPayroll(uid) {
    var data = {
        type: 'mtbPayroll',
        target: 'deleteCustom',
        authToken: getToken(),
        uid: uid
    }

    return await getResponse(data);
}

export async function sendBackPayrollPeriod(payrollVersionIdentifier) {
    var data = {
        type: 'mtbPayroll',
        target: 'sendBack',
        authToken: getToken(),
        payrollVersionIdentifier: payrollVersionIdentifier
    }

    return await getResponse(data);
}

export async function createCustomPayroll(companyIdentifier, periodStart, periodEnd) {
    var data = {
        type: 'mtbPayroll',
        target: 'createCustom',
        authToken: getToken(),
        companyIdentifier: companyIdentifier,
        periodStart: periodStart,
        periodEnd: periodEnd
    }

    return await getResponse(data);
}

export async function generatePayrollEntries(payrollIdentifier, userIdentifiers) {
    var data = {
        type: 'mtbPayroll',
        target: 'generatePayrollEntries',
        authToken: getToken(),
        payrollIdentifier: payrollIdentifier,
        userIdentifiers: userIdentifiers
    }

    return await getResponse(data);
}