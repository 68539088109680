/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Validator, validateGreaterThanZero } from "../../../../../../validation";
import QuickTable from "../../../../../../components/QuickTable";
import { adminMode, bigToUsd, toFixedMax } from "../../../../payrollTools";
import { Card, Container, Modal, Row } from "react-bootstrap";
import moment from "moment";
import HRUserPto from "../../../../../HumanResources/HRUserPTO/HRUserPto";
import Pto from "../../../../Models/Pto";
import { getEmployeePayrollPto as getEmployeePayrollPtoClient } from "../../../../../../services/PayrollServiceClient";
import { getEmployeePayrollPto as getEmployeePayrollPtoAdmin } from "../../../../../../services/PayrollServiceAdmin";
import CustomControl from "../../../../../../components/CustomControl";
import { ptoAccrualTypeDictionary } from "../../../../../../tools";
import Picker from "../../../../../../components/Picker";

export default function PayrollEmployeePtoEditor({entry, index, handleSet, handleSetWeek, payData, selectedPayPeriod}) {
    const [showModal, setShowModal] = useState(false);
    const [showError, setShowError] = useState(false);

    const handleRecalculate = async () => {
        const response = adminMode ? await getEmployeePayrollPtoAdmin(entry.companyUserIdentifier, selectedPayPeriod) : await getEmployeePayrollPtoClient(entry.companyUserIdentifier, selectedPayPeriod);

        if (response.status === '200') {
            handleSet(entry.companyUserIdentifier, 'ptoBank', response.balance);

            const newPto = [];
            const existingPto = [];

            response.pto.forEach((item) => {
                for (let i = 0; i < entry.weeks.length; i++) {
                    const foundPto = entry.weeks[i].ptoArray.find(p => p.uid == item.uid);
                    if (foundPto) {
                        existingPto.push(new Pto(item.uid, foundPto.payRate, item.number, item.date));
                        return;
                    }
                }
                newPto.push(item);
            })

            for (let weekIndex = 0; weekIndex < entry.weeks.length; weekIndex++) {
                const weekStart = moment(entry.periodStart).add(weekIndex, 'weeks');
                const weekEnd = moment(weekStart).add(6,'days');
                let ptoArray = existingPto.filter(p => moment(p.date).isBetween(weekStart, weekEnd, 'days', '[]'));

                const paysBeforeNextWeek = payData.filter((pay)=>moment(pay.date).isSameOrBefore(moment(weekEnd), 'day'));

                const paysDuringWeek = [];

                for (let i = paysBeforeNextWeek.length -1; i >= 0; i--) {
                    paysDuringWeek.unshift(paysBeforeNextWeek[i]);
                    if (moment(paysBeforeNextWeek[i].date).isSameOrBefore(moment(weekStart), 'day')) {
                        break;
                    }
                }
                                
                paysDuringWeek.forEach((p, index)=>{
                    const startDate = moment(p.date).isBefore(moment(weekStart)) ? weekStart.format('YYYY-MM-DD') : p.date;
                    const endDate = index < paysDuringWeek.length - 1 ? moment(paysDuringWeek[index + 1].date).subtract(1, 'day').format('YYYY-MM-DD') : weekEnd.format('YYYY-MM-DD'); 
                    let pto = newPto.filter((pto)=>moment(pto.date).isBetween(moment(startDate), moment(endDate), 'day', '[]')).map(si => Pto.decodeFromScheduleItem(si, p));
                    ptoArray = ptoArray.concat(pto);
                });

                ptoArray.sort((a, b) => {
                    if (a.date < b.date) {
                        return -1;
                    } else if (a.date > b.date) {
                        return 1;
                    } else {
                        return 0;
                    }
                })
                
                handleSetWeek(weekIndex, 'ptoArray', ptoArray);
            }
        } else {
            setShowError(true);
        }
    }


    const handleSetPto = (uid, key, value) => {
        const newArr = Array.from(entry.weeks[index].ptoArray);
        newArr.find(r => r.uid === uid)[key] = value;
        handleSetWeek(index, 'ptoArray', newArr);
    }

    const ptoElements = entry.weeks[index].ptoArray.map((pto) => {

        const validator = new Validator(() => validateGreaterThanZero(pto.payRate));

        return (
            <tr key={pto.uid}>
                <td>{moment(pto.date).format('MMM D, YYYY')}</td>
                <td>
                    <InputGroup>
                        <InputGroup.Text style={{padding: '2px 6px 2px 6px'}}>$</InputGroup.Text>
                        <Form.Control isInvalid={!validator.isValid()} style={{padding: '2px 6px 2px 6px'}} type='number' min={0} value={pto.payRate} placeholder='PTO Rate' onChange={(event) => {handleSetPto(pto.uid, 'payRate', event.target.value)}}/>
                        { validator ? <Form.Control.Feedback type='invalid'>{validator.getInvalidMessage()}</Form.Control.Feedback> : ''}
                    </InputGroup>
                </td>
                <td>{pto.hours}</td>
                <td>{bigToUsd(pto.getPtoPay())}</td>
                <td>
                    <Form.Check type='switch' checked={pto.isEnabled} onChange={(e) => {handleSetPto(pto.uid, 'isEnabled', !pto.isEnabled)}}/>
                </td>
            </tr>
        )
    });


    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 8}}>
                <Card.Title style={{marginBottom: 0}}>PTO</Card.Title>
                <div style={{display: 'flex', alignItems: 'center', gap: 12}}>
                    <Button variant="outline-primary" onClick={() => {setShowModal(true)}}>View/Edit PTO</Button>
                </div>
            </div>
            { ptoElements.length > 0 &&
                <QuickTable headers={['Date Scheduled', 'Rate', 'Hours', 'PTO Wages', 'Enabled']} rows={ptoElements} size={'sm'} noWrap widths={[1, null, 1, 1, 1]}/>
            }
            <div style={{marginBottom: 8, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Container fluid >
                    <Row>
                        <Picker lg={6} title='PTO Accrual Type' itemNames={Object.values(ptoAccrualTypeDictionary)} itemValues={Object.keys(ptoAccrualTypeDictionary)} selection={entry.ptoAccrualType} setSelection={(value) => {handleSet(entry.companyUserIdentifier, 'ptoAccrualType', value)}}/>
                        <CustomControl lg={6} title={`PTO Accrual ${ptoAccrualTypeDictionary[entry.ptoAccrualType]}`} value={entry.ptoAccrual} onChange={(event) => {handleSet(entry.companyUserIdentifier, 'ptoAccrual', event.target.value)}}/>
                    </Row>
                </Container>
                <QuickTable noWrap noMargin headers={['', 'Current PTO Balance', 'PTO to be Accrued', 'Projected Balance']} size='sm' rows={[
                    <tr key={1}>
                        <td>Hours</td>
                        <td>{toFixedMax(entry.ptoBank, 3)}</td>
                        <td>{toFixedMax(entry.getPtoHoursAccrued(), 3)}</td>
                        <td>{toFixedMax(entry.ptoBank + entry.getPtoHoursAccrued(), 3)}</td>
                    </tr>,
                    <tr key={2}>
                        <td>Days</td>
                        <td>{toFixedMax(entry.ptoBank / 8.0, 3)}</td>
                        <td>{toFixedMax(entry.getPtoHoursAccrued() / 8.0, 3)}</td>
                        <td>{toFixedMax((entry.ptoBank + entry.getPtoHoursAccrued()) / 8.0, 3)}</td>
                    </tr>
                ]}/>
            </div>
            
            <Modal show={showModal} onHide={() => {setShowModal(false)}} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>{`PTO for ${entry.name()}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HRUserPto user={{companyUserUid: entry.companyUserIdentifier}} startDate={entry.periodStart} endDate={entry.periodEnd} handleRecalculate={handleRecalculate}/>
                </Modal.Body>
            </Modal>
            <Modal show={showError} onHide={() => {setShowError(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Failed to Recalculate Employee's PTO</Modal.Title>
                </Modal.Header>
                <Modal.Body>Something went wrong. The PTO listed in Payroll may be out of sync with the data in our system.</Modal.Body>
            </Modal>
        </div>
    )
}

