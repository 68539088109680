/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from "./serviceTools";

export async function getFile(uid) {
    const data = {
        type: 'documentStorage',
        target: 'getDocument',
        authToken: getToken(),
        uid: uid
    }

    // const response = await getResponse(data);
    // return response;
}

//documentOwner: employee OR company

export async function uploadFile(fileObj) {
    const data = {
        type: 'documentStorage',
        target: 'createDocument',
        authToken: getToken(),
        data: fileObj
    }

    // const response = await getResponse(data);
    // return response;
}

export async function downloadFile(uid) {
    const data = {
        type: 'documentStorage',
        target: 'downloadDocument',
        authToken: getToken(),
        uid: uid
    }

    // const response = await getResponse(data);
    // return response;
}

export async function deleteFile(uid) {
    const data = {
        type: 'documentStorage',
        target: 'deleteDocument',
        authToken: getToken(),
        uid: uid
    }

    // const response = await getResponse(data);
    // return response;
}

export async function getAllFiles(companyUserUid) {
    const data = {
        type: 'documentStorage',
        target: 'getAllDocuments',
        authToken: getToken(),
        companyUserUid: companyUserUid
    }

    // const response = await getResponse(data);
    // return response;
}