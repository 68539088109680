/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import QuickTable from '../../../components/QuickTable';
import HRUserLoanEditor from './HRUserLoanEditor';
import CustomButton from '../../../components/CustomButton';
import { usdFormatter, validateDecimal } from '../../../tools';
import PageSpinner from '../../../components/PageSpinner';
import { getAllEmployeeLoans } from '../../../services/HumanResourcesService';

export default function HRUserLoan({selectedEmployee, crudCallback}) {

    const [isLoading, setIsLoading] = useState(false);
    const [loans, setLoans] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedLoan, setSelectedLoan] = useState(false);

    
    const loadData = async () => {
        setIsLoading(true);
        const response = await getAllEmployeeLoans(selectedEmployee.uid);
        if (response.status === 200) {
            setLoans(response.loans);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadData();
    }, crudCallback ? [] : [selectedEmployee])

    const handleLoanCrud = (type, data) => {
        let newArr = Array.from(loans);
        if (type === 'create') {
            newArr.push(data);
        } else if (type === 'update') {
            newArr = newArr.filter(t => t.uid != data.uid);
            newArr.push(data);
        } else if (type === 'delete') {
            newArr = newArr.filter(t => t.uid != data);
        } else if (type === 'installment') {
            const loan = newArr.find(l => l.uid == data.loanIdentifier);
            loan.installmentSum = validateDecimal(loan.installmentSum) + validateDecimal(data.amount);
        }
        setLoans(newArr);
        if (type !== 'installment') {
            setShowModal(false);
        }
        if (crudCallback) {
            crudCallback();
        }
    }

    const loanRows = loans.filter(l => validateDecimal(l.installmentSum) < validateDecimal(l.amount)).map((l)=>{
        const amountLeft = validateDecimal(l.amount) - validateDecimal(l.installmentSum);
        return (
            <tr key={l.uid} className='cursor-pointer' onClick={()=>{setSelectedLoan(l.uid); setShowModal(true)}}>
                <td>{l.name}</td>
                <td>{usdFormatter.format(l.amount)}</td>
                <td>{usdFormatter.format(l.installment)}</td>
                <td>{usdFormatter.format(l.installmentSum)}</td>
                <td>{usdFormatter.format(amountLeft)}</td>
            </tr>
        )
    });

    const loanHistoryRows = loans.filter(l => validateDecimal(l.installmentSum) >= validateDecimal(l.amount)).map((l) => {
        return (
            <tr key={'loan' + l.uid} className='cursor-pointer' onClick={()=>{setSelectedLoan(l.uid); setShowModal(true)}}>
                <td>{l.name}</td>
                <td>{l.amount}</td>
            </tr>
        )
    })


    return isLoading ? <PageSpinner/> : (
        <div>
            <div style={{display:'flex', justifyContent:'flex-end', marginBottom: 8, gap: 8, alignItems: 'center'}}>
                <CustomButton label={'Create Loan'} onClick={() => {setSelectedLoan(undefined); setShowModal(true)}}/>
            </div>
            <QuickTable title='Current Loans' headers={['Name','Amount Borrowed', 'Installment', 'Amount Paid', 'Amount Remaining']} rows={loanRows}  size={'sm'}/>
            <QuickTable title='Paid Loans' headers={['Name', 'Amount']} rows={loanHistoryRows} size='sm'/>
            
            <Modal show={showModal} onHide={()=> {setShowModal(false)}} size='lg'>
                <HRUserLoanEditor userIdentifier={selectedEmployee.uid} selectedLoan={selectedLoan} handleLoanCrud={handleLoanCrud}/>
            </Modal>
        </div>
    )
}