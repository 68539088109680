/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { fakeCompanyUsers, fakeMyScheduleData, fakeVehicles, fakeWorkAreas, fakeCsas, getFakeUID, fakeTimesheet } from "../../fake-data";
import fakeUsers from "../../fakeData/fakeUsers";
import { getResponse, getToken } from "../serviceTools";
import { timeout } from "../../tools";
import moment from "moment";

export async function getHRUserTimesheetEntries(companyUserIdentifier, startDate, endDate) {
    var data = {
        type: 'hr',
        target: 'getEmployeeTimesheet',
        authToken: getToken(),
        companyUserIdentifier: companyUserIdentifier,
        startDate: startDate,
        endDate: endDate
    }

    const response = await getResponse(data);
    return response;
}

export async function createTimesheetEntry(entry) {
    var data = {
        type: 'hr',
        target: 'createTimesheetEntry',
        authToken: getToken(),
        data: entry
    }

    const response = await getResponse(data);
    return response;
}

export async function updateTimesheetEntry(entry) {
    var data = {
        type: 'hr',
        target: 'updateTimesheetEntry',
        authToken: getToken(),
        data: entry
    }

    const response = await getResponse(data);
    return response;
}

export async function deleteTimesheetEntry(uid) {
    var data = {
        type: 'hr',
        target: 'deleteTimesheetEntry',
        authToken: getToken(),
        uid: uid
    }

    const response = await getResponse(data);
    return response;
}