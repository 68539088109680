/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState, useEffect } from "react";
import moment from "moment";
import Modal from 'react-bootstrap/Modal';
import QuickTable from "../../../../../components/QuickTable";


export default function PayrollPeriodUserSettlementReport({settlementReportData, entry}) {

    const userSettlementReportDays = settlementReportData.reduce((prev, curr) => {
        return prev.concat(curr.days.filter(d => d.companyUserIdentifier == entry.companyUserIdentifier));
    }, [])


    const userSettlementReportElements = userSettlementReportDays.sort((a,b)=>a.date < b.date ? -1 : a.date > b.date ? 1 : 0).map((s)=>{

        return (
            <tr key={s.date}>
                <td>{moment(s.date).format('ddd D')}</td>
                <td>{s.stops}</td>
                <td>{s.packages}</td>
            </tr>
        )
    });

    const totalStops = userSettlementReportDays.reduce((prev, curr)=>prev + curr.stops, 0);
    const totalPackages = userSettlementReportDays.reduce((prev,curr)=>prev + curr.packages, 0);

    const totals = (
        <tr key={0} style={{fontWeight:'bold'}}>
            <td>Totals</td>
            <td>{totalStops}</td>
            <td>{totalPackages}</td>
        </tr>
    );

    userSettlementReportElements.push(totals);

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{'Settlement Report Data for ' + entry.lastName + ', ' + entry.firstName + ' ' + entry.middleName.substring(0,1)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {userSettlementReportDays.length > 0 ?
                <QuickTable title='Settlement Report Data' headers={['Date', 'Stops', 'Packages']} rows={userSettlementReportElements} size='sm' bordered striped/>
                :
                <span style={{opacity:0.5, fontStyle:'italic'}}>This employee does not have any uploaded settlement data.</span>

                }
            </Modal.Body>
        </>
    )

}