/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import { Button, ListGroup, Modal } from "react-bootstrap";
import { getCompanyUsers, resetEmployeePasswords } from "../../services/CompanyServices/CompaniesService";
import CustomButton from "../../components/CustomButton";
import PageSpinner from "../../components/PageSpinner";


export default function CompanyPasswordReset({selectedCompany}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        loadData();
    }, [])

    const loadData = async () => {
        setIsLoading(true);
        const response = await getCompanyUsers(selectedCompany.uid);
        if (response.status === '200') {
            setUsers(response.users.filter(u => u.isAdministrator != '1'));
        }
        setIsLoading(false);
    }

    const handleSubmit = async () => {
        setSuccess(false);
        setError(false);
        setIsSubmitting(true);
        const response = await resetEmployeePasswords(selectedCompany.uid, selectedUsers);
        if (response.status === '200') {
            setSuccess(true);
        } else {
            setError(true);
        }
        setIsSubmitting(false);
    }

    const employeeItems = users.map((user) => {
        return (
            <ListGroup.Item key={user.userIdentifier} action active={selectedUsers.includes(user.userIdentifier)} onClick={() => {
                if (selectedUsers.includes(user.userIdentifier)) {
                    setSelectedUsers(selectedUsers.filter(u => u != user.userIdentifier));
                } else {
                    setSelectedUsers([...selectedUsers, user.userIdentifier]);
                }
            }}>{`${user.lastName}, ${user.firstName} ${user.middleName}`}</ListGroup.Item>
        )
    })


    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Reset Employee Passwords</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                { isLoading ? <PageSpinner/> : 
                    <>
                        <Button onClick={() => {
                            if (selectedUsers.length === users.length) {
                                setSelectedUsers([]);
                            } else {
                                setSelectedUsers(users.map(u => u.userIdentifier))
                            }
                        }}>Toggle All</Button>
                        <ListGroup style={{maxHeight: 600, overflowY: 'auto'}}>
                            {employeeItems}
                        </ListGroup>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                { success && 
                    <span style={{color: 'green'}}>Successfully reset passwords!</span>
                }
                { error && 
                    <span style={{color: 'red'}}>Something went wrong</span>
                }
                <CustomButton disabled={selectedUsers.length == 0} isLoading={isSubmitting} onClick={handleSubmit} label={`Reset ${selectedUsers.length} Employee Password(s)`}/>
            </Modal.Footer>
        </>
    )
}