/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState, useEffect} from 'react';
import { getAllNotifications } from '../../services/NotificationService';
import {ListGroup, Button, Modal, InputGroup, Form } from 'react-bootstrap';
import PageSpinner from '../../components/PageSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import NotificationEditor from './NotificationEditor';
import moment from 'moment';
import NotificationModal from './NotificationModal';
import SendCustomTextModal from './SendCustomTextModal';

export default function Notifications() {

    const [isLoading, setIsLoading] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [selectedNotification, setSelectedNotification] = useState(undefined);
    const [nameSearch, setNameSearch] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showTextModal, setShowTextModal] = useState(false);

    const loadData = async () => {
        setIsLoading(true);
        const response = await getAllNotifications();
        if(response.status === '200'){
            setNotifications(response.notifications.sort((a,b)=>a.name.localeCompare(b.name)));
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadData();
    },[])

    
    const handleAddNotification = (notification) => {
        const newArray = Array.from(notifications);
        newArray.push(notification);
        setNotifications(newArray);
    }
    const handleUpdateNotification = (notification) => {
        const newArray = notifications.filter(n => n.uid != notification.uid);
        newArray.push(notification);
        setNotifications(newArray);
    }
    const handleRemoveNotification = (uid) => {
        const newArray = notifications.filter(n => n.uid != uid);
        setNotifications(newArray);
        setShowModal(false);
    }

    const notificationsList = notifications.filter((n) => n.name.toLowerCase().includes(nameSearch.toLowerCase())).map((n) => {
        let isActive = false;
        if(n.effectiveDate && !n.expirationDate && moment().isSameOrAfter(moment(n.effectiveDate))){
            isActive = true;
        } else if(n.effectiveDate && n.expirationDate && moment().isBetween(moment(n.effectiveDate), moment(n.expirationDate),[])){
            isActive = true;
        }

        return (
            <ListGroup.Item key={n.uid} style={{ display: 'flex', justifyContent: 'space-between'}} action onClick={() => {setSelectedNotification(n.uid); setShowModal(true);}}>
                <span>{n.name}</span>
                <span style={{color: isActive ? 'green' : ''}}>{isActive ? n.expirationDate ? `Expires ${moment(n.expirationDate).format('MMM D, YYYY [at] h:mm A')}` : 'Active' : 'Inactive'}</span>
            </ListGroup.Item>
        );
    });

    const content = (
        <div style={{padding: 12, display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%'}}>
            <h1 style={{textAlign:'center'}}>Notifications</h1>
            <div style={{display:'flex', flexDirection:'column', gap: 12, width: '100%', maxWidth: 1000, flex: 1, overflow: 'auto'}}>
                <div style={{display: 'flex', alignItems: 'center', gap: 12, width: '100%'}}>
                    <InputGroup style={{flex: 1}}>
                        <InputGroup.Text><FontAwesomeIcon icon={faSearch} style={{color: 'black'}}/></InputGroup.Text>
                        <Form.Control type='text' value={nameSearch} placeholder='Search Notifications' onChange={(event) => {setNameSearch(event.target.value)}}/>
                    </InputGroup>
                    <Button onClick={() => {setShowCreateModal(true)}} style={{margin:5}}>Create A Notification</Button>
                    <Button onClick={()=>{setShowTextModal(true)}}>Send Text</Button>
                </div>

                <ListGroup className='hidden-scrollbar' style={{flex: 1, backgroundColor: 'white', padding: 8, overflowY: 'scroll'}}>
                    {notifications.length > 0 ? notificationsList :
                        <ListGroup.Item>
                            <p style={{fontStyle:'italic', opacity:0.5, fontSize:24, margin: 0}}>You have no created notifications</p>
                        </ListGroup.Item>
                    }
                </ListGroup>
            </div>
            <Modal show={showModal} onHide={()=>{setShowModal(false)}} size='xl'>
                <NotificationModal 
                    selectedNotification={selectedNotification} 
                    hideModal={()=>{setShowModal(false)}} 
                    handleAddNotification={handleAddNotification} 
                    handleUpdateNotification={handleUpdateNotification} 
                    handleRemoveNotification={handleRemoveNotification} 
                />
            </Modal>
            <Modal show={showCreateModal} onHide={()=>{setShowCreateModal(false)}} size='xl'>
                <NotificationEditor hideModal={()=>{setShowCreateModal(false)}} handleAddNotification={handleAddNotification}/>
            </Modal>
            <Modal size='xl' show={showTextModal} onHide={()=>setShowTextModal(false)}>
                <SendCustomTextModal/>
            </Modal>
        </div>
    )


    return isLoading ? <PageSpinner/> : content;
}