/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from "../serviceTools";
import { timeout } from "../../tools";
import { getFakeUID } from "../../fake-data";

export async function getCompanyWorkAreas(companyIdentifier) {
    const data = {
        type: 'company',
        target: 'getWorkAreas',
        authToken: getToken(),
        companyIdentifier: companyIdentifier
    }

    const response = await getResponse(data);
    return response;
}

export async function createWorkArea(companyIdentifier, workArea) {
    const data = {
        type: 'company',
        target: 'createWorkArea',
        authToken: getToken(),
        companyIdentifier: companyIdentifier,
        data: workArea,
    }

    const response = await getResponse(data);
    return response;
}
export async function updateWorkArea(companyIdentifier, workArea) {
    const data = {
        type: 'company',
        target: 'updateWorkArea',
        authToken: getToken(),
        companyIdentifier,
        data: workArea,
    }

    const response = await getResponse(data);
    return response;
}
export async function deleteWorkArea(uid) {
    const data = {
        type: 'company',
        target: 'deleteWorkArea',
        authToken: getToken(),
        uid: uid,
    }

    const response = await getResponse(data);
    return response;
}