/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Big from "big.js";
import Decoder from "../../../decoding";
import { validateDecimal, validateInteger, validateBig } from "../payrollTools";
import NDBonus from "./NDBonus";
import AdditionalPay from "./AdditionalPay";
import Pto from "./Pto";
import Holiday from "./Holiday";
import DBonus from "./DBonus";
import Pay from "./Pay";

export default class PayrollEntryWeek {

    daysWorked; //int
    hoursWorked; //decimal
    ndBonuses; //NDBonus[]
    dBonuses; //DBonus[]
    additionalPay;
    pto; //[Pto]
    holidays; //[Holiday]
    pay; //[Pay]

    constructor(daysWorked, hoursWorked, ndBonuses, dBonuses, additionalPay, pto, holidays, pay) {
        this.daysWorked = daysWorked;
        this.hoursWorked = hoursWorked;
        this.ndBonuses = ndBonuses;
        this.dBonuses = dBonuses;
        this.additionalPay = additionalPay;
        this.pto = pto;
        this.holidays = holidays;
        this.pay = pay;
    }

    static initDefault() {
        return new PayrollEntryWeek(0, 0.0, [], [], [], [], [], []);
    }

    static decode(json) {
        const decoder = new Decoder(json);
        const daysWorked = decoder.decode('daysWorked', Decoder.Integer);
        const hoursWorked = decoder.decode('hoursWorked', Decoder.Decimal);
        const ndBonuses = (decoder.decode('ndBonuses', Decoder.Array) ?? []).map(b => NDBonus.decode(b));
        const dBonuses = (decoder.decode('dBonuses', Decoder.Array) ?? []).map(b => DBonus.decode(b));
        const additionalPay = (decoder.decode('additionalPay', Decoder.Array) ?? []).map(ap => AdditionalPay.decode(ap));
        const pto = (decoder.decode('pto', Decoder.Array) ?? []).map(pto => Pto.decode(pto));
        const holidays = (decoder.decode('holidays', Decoder.Array) ?? []).map(holiday => Holiday.decode(holiday));
        const pay = (decoder.decode('pay', Decoder.Array) ?? []).map(p => Pay.decode(p));
        if (decoder.checkForErrors()) {
            return new PayrollEntryWeek(daysWorked, hoursWorked, ndBonuses, dBonuses, additionalPay, pto, holidays, pay);
        } else {
            return PayrollEntryWeek.initDefault();
        }
    }

    encode() {
        return {
            daysWorked: validateInteger(this.daysWorked),
            hoursWorked: validateDecimal(this.hoursWorked),
            ndBonuses: this.ndBonuses.map(b => b.encode()),
            dBonuses: this.dBonuses.map(db => db.encode()),
            additionalPay: this.additionalPay.map(ap => ap.encode()),
            pto: this.pto.map(pto => pto.encode()),
            holidays: this.holidays.map(holiday => holiday.encode()),
            pay: this.pay.map(p => p.encode())
        }
    }

    /////////////
    //FLSA - only call these functions if the entry qualifies for FLSA
    /////////////
    qualifiesForFLSA() {
        return this.hoursWorked > 40 && this.pay.find(p => parseInt(p.payType) < 2);
    }
    
    hourlyRate() { //FLSA
        const hourlyPay = this.pay.find(p => p.payType == 0);
        const salaryPay = this.pay.find(p => p.payType == 1);
        if (hourlyPay) {
            return validateBig(hourlyPay.payRate);
        } else if (salaryPay) {
            return validateBig(salaryPay.payRate).div(52.0).div(40.0); // for 52 forty-hour work weeks
        } else {
            return new Big('0.0');
        }
    }
    
    overtimeRate() { //FLSA
        return this.hourlyRate().plus(this.getNDBonuses().div(this.hoursWorked)).div(2.0).add(this.hourlyRate());
    }

    adjustedHourlyRate() { //FLSA
        return this.overtimeRate().div(1.5);
    }
    
    // straightTimeHoursWorked() { //FLSA
    //     return validateBig(40);
    // }

    overtimeHoursWorked() { //FLSA
        return validateBig(this.hoursWorked).minus(40);
    }


    // regularWages() { //FLSA
    //     if (this.payType == 0 || this.qualifiesForFLSA()) {
    //         return new Big('0.0');
    //     } else {
    //         return validateBig(this.payRate).div(52.0);
    //     } 
    // }
    
    hourlyWages() { //FLSA
        return this.hourlyRate().times(40);
    }
    
    overtimeWages() { //FLSA
        return this.overtimeRate().times(this.overtimeHoursWorked());
    }
    /////////////
    //FLSA
    /////////////
    
    getNDBonuses() {
        return this.ndBonuses.reduce((prev, curr) => {
            return prev.plus(validateBig(curr.getAmount()));
        }, new Big('0.0'));
    }

    getDBonuses() {
        return this.dBonuses.reduce((prev, curr) => {
            return prev.plus(validateBig(curr.getAmount()));
        }, new Big('0.0'));
    }

    getAdditionalPay() {
        return this.additionalPay.reduce((prev, curr) => {
            return prev.plus(validateBig(curr.getAmount()));
        }, new Big('0.0'));
    }

    ptoHours() {
        return this.pto.reduce((prev, curr) => { return prev + validateInteger(curr.hours) }, 0);
    }

    ptoWages() {
        const ptoWages = this.pto.reduce((prev, curr)=>{
            return prev.plus(curr.getPtoPay());
        }, new Big('0.00'));
        return ptoWages;
    }
    
    holidayWages() {
        const holidayWages = this.holidays.reduce((prev,curr)=>{
            return prev.plus(curr.holidayPay());
        }, new Big('0.00'));
        return holidayWages;
    }

    payWages() {
        if (this.qualifiesForFLSA()) {
            return this.hourlyWages().plus(this.overtimeWages());
        } else {
            return this.pay.reduce((prev, curr) => {
                return prev.plus(curr.getWages());
            }, new Big(0));
        }
    }
    
    getSubGross() {
        return this.getNDBonuses()
            .plus(this.getDBonuses())
            .plus(this.getAdditionalPay())
            .plus(this.ptoWages())
            .plus(this.holidayWages())
            .plus(this.payWages())
        ;
    }

    getColumnInclusion() {

        const includeOvertimeColumns = this.qualifiesForFLSA();
        const includeSalary = !includeOvertimeColumns && this.pay.find(p => parseInt(p.payType) > 0) !== undefined;

        return {
            includeSalary: includeSalary,
            includeHourly: includeOvertimeColumns || this.pay.find(p => p.payType == 0) !== undefined,
            includeOvertimeColumns: includeOvertimeColumns,
            includePto: this.pto.find(p => p.hours > 0),
            includeHolidayWages: this.holidays.find(h => h.holidayPay().toNumber() !== 0.00),
            includeHolidayBonus: this.dBonuses.find(db => db.type === 'Holiday Bonus'),
            includeOtherDiscretionaryBonus: this.dBonuses.find(db => db.type === 'Other Discretionary Bonus')
        }
    }
    
}