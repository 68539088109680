/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useEffect, useState } from "react"
import { getHRUserActivity } from "../../../services/HRServices/HRActivityService";
import { AppGlobals } from "../../../App";
import moment from "moment";
import { dateRangeToString } from "../../../tools";
import { Button, Dropdown, ListGroup } from "react-bootstrap";
import DateRangeSelector from "../../../components/DateRangeSelector";
import KeyValueRow from "../../../components/KeyValueRow";
import QuickTable from "../../../components/QuickTable";

export default function HRUserActivity({companyUserUid}){

    const [isLoading, setIsLoading] = useState(false);
    const [activity, setActivity] = useState([])
    const [startDate, setStartDate] = useState(moment().subtract(6, 'days').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [sortType, setSortType] = useState('Descending')

    useEffect(() => {
        loadData(startDate, endDate);
    }, [startDate, endDate])

    function handleSetDateRange(startDate, endDate) {
        setStartDate(startDate);
        setEndDate(endDate)
    }

    async function loadData(startDate, endDate){
        setIsLoading(true);
        const response = await getHRUserActivity(companyUserUid, startDate ? startDate : '0001-01-01', endDate ? endDate : moment().format('YYYY-MM-DD'));
        if(response.status == 200){
            setActivity(response.activity);
        }else{
            AppGlobals.alert('danger', response.status)
        }
        setIsLoading(false);
    }

    const activityRows = activity.sort((a, b) =>{
        if(sortType === 'Descending'){
            return a.dateTime < b.dateTime ? 1 : a.dateTime > b.dateTime ? -1 : 0
        } else {
            return a.dateTime < b.dateTime ? -1 : a.dateTime > b.dateTime ? 1 : 0
        }
    }).map(a => {
        return (
            <tr key={a.uid}>
                <td>{moment(a.dateTime).format('MMM D, YYYY hh:mm A')}</td>
                <td>{a.ip}</td>
            </tr>
        )
    })
      
    return (
        <>
            <div>
                <div style={{display: 'flex', gap: 6, alignItems: 'center', justifyContent: 'left'}}>
                    <Button style={{color: 'white'}} onClick={() => {setShowDatePicker(true)}}>{dateRangeToString(startDate, endDate)}</Button>
                    <Button onClick={() => setSortType(sortType === 'Descending' ? 'Ascending' : 'Descending')}>Sorting by {sortType}</Button>
                </div>
                <QuickTable headers={['Date Time', 'IP']} rows={activityRows}/>
            </div>
            <DateRangeSelector 
                show={showDatePicker} 
                onHide={() => setShowDatePicker(false)} 
                handleSetDateRange={handleSetDateRange} 
                presetOptions={['pastYear', 'pastMonth', 'pastWeek', 'allTime']} 
                centered
            />
        </>
    )
}