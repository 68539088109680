/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import PayModal from "../PayModal/PayModal";
import PayrollEmployeeNondiscretionaryBonuses from "./PayrollEmployeeNondiscretionaryBonuses";
import PayrollEmployeeWagesEditor from "./PayrollEmployeeWagesEditor";
import PayrollEmployeeAdditionalPayEditor from "./PayrollEmployeeAdditionalPayEditor";
import PayrollEmployeePtoEditor from "./PayrollEmployeePtoEditor";
import PayrollEmployeeHolidaysEditor from "./PayrollEmployeeHolidaysEditor";
import PayrollEmployeeDiscretionaryBonuses from "./PayrollEmployeeDiscretionaryBonuses";
import { Dropdown } from "react-bootstrap";
import moment from "moment";

export default function PayrollEmployeePayEditor({entry, index = 0, handleSet, handleSetWeek, handleAddBonusToAllEntries, selectedPayPeriod}) {
    const [showModal, setShowModal] = useState(false);
    const [weekIndex, setWeekIndex] = useState(0);

    const handleAddAdditionalPay = (additionalPay, applyToAll) => {
        if (applyToAll) {
            handleAddBonusToAllEntries('additionalPay', additionalPay, weekIndex);
        } else {
            const newArr = Array.from(entry.weeks[weekIndex].additionalPay);
            newArr.push(additionalPay);
            handleSetWeek(weekIndex, 'additionalPay', newArr);
        }
        setShowModal(false);
    }

    const handleAddNonDiscretionaryBonus = (ndBonus, applyToAll) => {
        if (applyToAll) {
            handleAddBonusToAllEntries('ndBonuses', ndBonus, weekIndex);
        } else {
            const newArr = Array.from(entry.weeks[weekIndex].ndBonuses);
            newArr.push(ndBonus);
            handleSetWeek(weekIndex, 'ndBonuses', newArr);
        }
        setShowModal(false);
    }

    const handleAddDiscretionaryBonus = (dBonus, applyToAll) => {
        if (applyToAll) {
            handleAddBonusToAllEntries('dBonuses', dBonus, weekIndex);
        } else {
            const newArr = Array.from(entry.weeks[weekIndex].dBonuses);
            newArr.push(dBonus);
            handleSetWeek(weekIndex, 'dBonuses', newArr);
        }
        setShowModal(false);
    }

    const handleAddHoliday = (holiday) => {
        const newArr = Array.from(entry.weeks[weekIndex].holidays);
        newArr.push(holiday);
        handleSetWeek(weekIndex, 'holidays', newArr);
        setShowModal(false);
    }

    const weekDropdownElements = entry.weeks.map((week, index) => {
        return (
            <Dropdown.Item key={index} onClick={() => {setWeekIndex(index); setShowModal(true);}}>
                {`Week #${index + 1} | ${moment(entry.periodStart).add(index, 'weeks').format('MMM D')} - ${moment(entry.periodEnd).add(index, 'weeks').format('MMM D')}`}
            </Dropdown.Item>
        )
    });

    return (
        <Card>
            <Card.Body>
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', gap: 12, marginBottom: 8}}>
                    <Card.Title style={{margin: 0}}>Employee Pay</Card.Title>
                    { entry.weeks.length > 1 ?
                        <Dropdown>
                            <Dropdown.Toggle variant='outline-primary' style={{borderRadius: 16, padding: '0px 5px 0px 5px'}}>
                                <FontAwesomeIcon icon={faPlus}/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {weekDropdownElements}
                            </Dropdown.Menu>
                        </Dropdown>
                        :
                        <Button style={{color: 'white', borderRadius: 14, padding: '0px 5px 0px 5px'}} onClick={() => {setShowModal(true)}}>
                            <FontAwesomeIcon icon={faPlus} style={{color: 'white'}}/>
                        </Button>
                    }
                </div>
                <PayrollEmployeeWagesEditor entry={entry}/>
                { entry.weeks.reduce((prev, curr) => {return prev || curr.ndBonuses.length > 0}, false) &&
                    <PayrollEmployeeNondiscretionaryBonuses handleSetWeek={handleSetWeek} entry={entry}/>
                }
                { entry.weeks.reduce((prev, curr) => {return prev || curr.dBonuses.length > 0}, false) &&
                    <PayrollEmployeeDiscretionaryBonuses handleSetWeek={handleSetWeek} entry={entry}/>
                }
                { entry.weeks.reduce((prev, curr) => {return prev || curr.additionalPay.length > 0}, false) &&
                    <PayrollEmployeeAdditionalPayEditor entry={entry} handleSetWeek={handleSetWeek}/>
                }
                { entry.qualifiesForPtoAndHolidays() && 
                    <>
                        { entry.weeks.reduce((prev, curr) => {return prev || curr.holidays.length > 0}, false) &&
                            <PayrollEmployeeHolidaysEditor entry={entry} handleSetWeek={handleSetWeek}/>
                        }
                        {/* { entry.weeks.reduce((prev, curr) => {return prev || curr.pto.length > 0}, false) &&
                        } */}
                        <PayrollEmployeePtoEditor entry={entry} handleSet={handleSet}/>
                    </>
                }
            </Card.Body>
            <Modal show={showModal} onHide={() => setShowModal(false)} centered size='lg'>
                <PayModal 
                    handleAddAdditionalPay={handleAddAdditionalPay} 
                    handleAddNonDiscretionaryBonus={handleAddNonDiscretionaryBonus}
                    handleAddDiscretionaryBonus={handleAddDiscretionaryBonus}
                    handleAddHoliday={handleAddHoliday} 
                    qualifiesForPtoAndHolidays={() => entry.qualifiesForPtoAndHolidays()}
                />
            </Modal>
        </Card>
    )
}