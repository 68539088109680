/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import Button from 'react-bootstrap/Button';
import CustomSpinner from "../../../components/CustomSpinner";
import { getPayrollPeriod as getPayrollPeriodAdmin } from "../../../services/PayrollServiceAdmin";
import { getPayrollPeriod as getPayrollPeriodClient } from "../../../services/PayrollServiceClient";
import { ButtonGroup, Dropdown, InputGroup } from "react-bootstrap";
import PayrollCSADownloadModal from "../PayrollCSVDownloadModal";
import PayrollEntry from "../Models/PayrollEntry";
import PayrollPeriodTable from "./PayrollPeriodTable";
import PayrollPeriodPdf from "./PayrollPeriodPdf";
import { adminMode } from "../payrollTools";


export default class PayrollPeriod extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            payrollData: [],
            approvedPayrollData: [],
            periodStart: undefined,
            periodEnd: undefined,
            tabSwitch: true,
            showModal: false,
            showPdf: false,
            isCustom: false,

            versions: [],
            bcData: [],
            bcVersions: [],
            csas: [],
            viewingSelectionType: '',
            viewingSelectionUid: undefined,
            comparingSelectionType: '',
            comparingSelectionUid: undefined,
        }
    }

    sortEntries(a, b) {
        if (a.csaName < b.csaName) {
            return -1;
        } else if (a.csaName > b.csaName) {
            return 1;
        } else {
            if (a.isNew !== b.isNew) {
                if (a.isNew) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if (a.name() < b.name()) {
                    return -1;
                } else if (a.name() > b.name()) {
                    return 1;
                } else {
                    return 0;
                }
            }
        }
    }

    async loadData() {
        this.setState({isLoading: true});

        if (adminMode) {
            const response = await getPayrollPeriodAdmin(this.props.selectedPayPeriod.uid);
            if (response.status === '200') {
                const entries = response.data.entries ? PayrollEntry.decodeArray(response.data.entries,response.data.periodStart, response.data.periodEnd).sort(this.sortEntries) : undefined;
                const approvedEntries = response.data.approvedEntries ? PayrollEntry.decodeArray(response.data.approvedEntries, response.data.periodStart, response.data.periodEnd).sort(this.sortEntries) : undefined;
                this.setState({
                    payrollData: entries,
                    approvedPayrollData: approvedEntries,
                    periodStart: response.data.periodStart, 
                    periodEnd: response.data.periodEnd,
                    isCustom: response.data.isCustom == '1',
                    versions: response.versions,
                    bcData: response.bcData,
                    bcVersions: response.bcVersions,
                    csas: response.csas,
                    viewingSelectionType: response.data.approvedEntries ? 'ttaSubmission' : 'companySubmission',
                });
                
            }
        } else {
            const response = await getPayrollPeriodClient(this.props.selectedPayPeriod.uid);
            if (response.status === '200') {
    
                let entries = undefined;
                let approvedEntries = undefined;
    
                if (response.bcData && !response.data.entries && !response.data.approvedEntries) {
                    entries = PayrollEntry.decodeArray(response.bcData.entries, response.data.periodStart, response.data.periodEnd).sort(this.sortEntries);

                } else {
                    entries = response.data.entries ? PayrollEntry.decodeArray(response.data.entries,response.data.periodStart, response.data.periodEnd).sort(this.sortEntries) : undefined;
                    approvedEntries = response.data.approvedEntries ? PayrollEntry.decodeArray(response.data.approvedEntries,response.data.periodStart, response.data.periodEnd).sort(this.sortEntries) : undefined;
                }
    
                this.setState({
                    payrollData: entries,
                    approvedPayrollData: approvedEntries,
                    periodStart: response.data.periodStart, 
                    periodEnd: response.data.periodEnd,
                    isCustom: response.data.isCustom == '1'
                });
                
            }
        }
        
        this.setState({isLoading: false});
    }

    componentDidMount() {
        this.loadData();
    }

    getSelectedViewingLabel() {
        if (this.state.viewingSelectionType == 'ttaSubmission') {
            return 'TTA Submission';
        } else if (this.state.viewingSelectionType == 'companySubmission') {
            return 'Company Submission';
        } else if (this.state.viewingSelectionType == 'version') {
            const version = this.state.versions.find(v => v.uid == this.state.viewingSelectionUid);
            const name = version.user ? `${version.user.firstName} ${version.user.lastName}` : 'TTA'
            return `Save: ${name} - ${moment(version.dateTime).format('MMM D, YYYY [at] h:mm A')}`;
        } else if (this.state.viewingSelectionType == 'bcVersion') {
            const version = this.state.bcVersions.find(v => v.uid == this.state.viewingSelectionUid);
            const bcPayroll = this.state.bcData.find(e => e.uid == version.bcPayrollIdentifier);
            const csa = bcPayroll?.csaIdentifier == '-1' ? 'All CSAs' : this.state.csas.find(c => c.uid == bcPayroll?.csaIdentifier)?.csaName;
            return `BC Save: ${csa}`;
        } else if (this.state.viewingSelectionType == 'bcSubmission') {
            const bcSubmission = this.state.bcData.find(v => v.uid == this.state.viewingSelectionUid);
            const csa = bcSubmission.csaIdentifier == '-1' ? 'All CSAs' : this.state.csas.find(c => c.uid == bcSubmission.csaIdentifier)?.csaName;
            return `BC Submission: ${csa}`;
        } else {
            return 'None';
        }
    }

    getSelectedComparingLabel() {
        if (this.state.comparingSelectionType == 'ttaSubmission') {
            return 'TTA Submission';
        } else if (this.state.comparingSelectionType == 'companySubmission') {
            return 'Company Submission';
        } else if (this.state.comparingSelectionType == 'version') {
            const version = this.state.versions.find(v => v.uid == this.state.comparingSelectionUid);
            const name = version.user ? `${version.user.firstName} ${version.user.lastName}` : 'TTA'
            return `${name} - ${moment(version.dateTime).format('MMM D, YYYY [at] h:mm A')}`;
        } else if (this.state.comparingSelectionType == 'bcVersion') {
            const version = this.state.bcVersions.find(v => v.uid == this.state.comparingSelectionUid);
            const bcPayroll = this.state.bcData.find(e => e.uid == version.bcPayrollIdentifier);
            const csa = bcPayroll?.csaIdentifier == '-1' ? 'All CSAs' : this.state.csas.find(c => c.uid == bcPayroll?.csaIdentifier)?.csaName;
            return `BC Save - ${csa}`;
        } else if (this.state.comparingSelectionType == 'bcSubmission') {
            const bcSubmission = this.state.bcData.find(v => v.uid == this.state.comparingSelectionUid);
            const csa = bcSubmission.csaIdentifier == '-1' ? 'All CSAs' : this.state.csas.find(c => c.uid == bcSubmission.csaIdentifier)?.csaName;
            return `BC Submission - ${csa}`;
        } else {
            return 'None';
        }
    }


    render() {

        const selectedViewingEntries = 
            this.state.viewingSelectionType == 'ttaSubmission' ? this.state.approvedPayrollData : 
            this.state.viewingSelectionType == 'companySubmission' ? this.state.payrollData : 
            this.state.viewingSelectionType == 'version' ? PayrollEntry.decodeArray(this.state.versions.find(v => v.uid == this.state.viewingSelectionUid).entries, this.state.periodStart, this.state.periodEnd).sort(this.sortEntries) :
            this.state.viewingSelectionType == 'bcVersion' ? PayrollEntry.decodeArray(this.state.bcVersions.find(v => v.uid == this.state.viewingSelectionUid).entries, this.state.periodStart, this.state.periodEnd).sort(this.sortEntries) :
            this.state.viewingSelectionType == 'bcSubmission' ? PayrollEntry.decodeArray(this.state.bcData.find(v => v.uid == this.state.viewingSelectionUid).entries, this.state.periodStart, this.state.periodEnd).sort(this.sortEntries) :
            undefined
        ;

        const selectedComparingEntries = 
            this.state.comparingSelectionType == 'ttaSubmission' ? this.state.approvedPayrollData : 
            this.state.comparingSelectionType == 'companySubmission' ? this.state.payrollData : 
            this.state.comparingSelectionType == 'version' ? PayrollEntry.decodeArray(this.state.versions.find(v => v.uid == this.state.comparingSelectionUid).entries, this.state.periodStart, this.state.periodEnd).sort(this.sortEntries) :
            this.state.comparingSelectionType == 'bcVersion' ? PayrollEntry.decodeArray(this.state.bcVersions.find(v => v.uid == this.state.comparingSelectionUid).entries, this.state.periodStart, this.state.periodEnd).sort(this.sortEntries) :
            this.state.comparingSelectionType == 'bcSubmission' ? PayrollEntry.decodeArray(this.state.bcData.find(v => v.uid == this.state.comparingSelectionUid).entries, this.state.periodStart, this.state.periodEnd).sort(this.sortEntries) :
            undefined
        ;

            

        const viewingVersions = this.state.versions.map((version) => {
            const name = version.user ? `${version.user.firstName} ${version.user.lastName}` : 'TTA'

            return (
                <Dropdown.Item onClick={() => {this.setState({viewingSelectionType: 'version', viewingSelectionUid: version.uid})}} active={this.state.viewingSelectionType == 'version' && this.state.viewingSelectionUid == version.uid} key={version.uid}>
                    {`${name} - ${moment(version.dateTime).format('MMM D, YYYY [at] h:mm A')}`}
                </Dropdown.Item>
            )
        })

        const viewingBcSubmissions = this.state.bcData.map((bcSubmission) => {
            const csa = bcSubmission.csaIdentifier == '-1' ? 'All CSAs' : this.state.csas.find(c => c.uid == bcSubmission.csaIdentifier)?.csaName;
            return (
                <Dropdown.Item key={bcSubmission.uid} onClick={() => {this.setState({viewingSelectionType: 'bcSubmission', viewingSelectionUid: bcSubmission.uid})}} active={this.state.viewingSelectionType == 'bcSubmission' && this.state.viewingSelectionUid == bcSubmission.uid}>{csa}</Dropdown.Item>
            )
        })

        const viewingBcVersions = this.state.bcVersions.map((version) => {
            const bcPayroll = this.state.bcData.find(e => e.uid == version.bcPayrollIdentifier);
            const csa = bcPayroll?.csaIdentifier == '-1' ? 'All CSAs' : this.state.csas.find(c => c.uid == bcPayroll?.csaIdentifier)?.csaName;

            return (
                <Dropdown.Item key={version.uid} onClick={() => {this.setState({viewingSelectionType: 'bcVersion', viewingSelectionUid: version.uid})}} active={this.state.viewingSelectionType == 'bcVersion' && this.state.viewingSelectionUid == version.uid}>
                    {`${csa} - ${moment(version.dateTime).format('MMM D, YYYY [at] h:mm A')}`}
                </Dropdown.Item>
            )
        })

        const comparingVersions = this.state.versions.map((version) => {
            const name = version.user ? `${version.user.firstName} ${version.user.lastName}` : 'TTA'

            return (
                <Dropdown.Item onClick={() => {this.setState({comparingSelectionType: 'version', comparingSelectionUid: version.uid})}} key={version.uid} active={this.state.comparingSelectionType == 'version' && this.state.comparingSelectionUid == version.uid}>
                    {`${name} - ${moment(version.dateTime).format('MMM D, YYYY [at] h:mm A')}`}
                </Dropdown.Item>
            )
        })

        const comparingBcSubmissions = this.state.bcData.map((bcSubmission) => {
            const csa = bcSubmission.csaIdentifier == '-1' ? 'All CSAs' : this.state.csas.find(c => c.uid == bcSubmission.csaIdentifier)?.csaName;
            return (
                <Dropdown.Item key={bcSubmission.uid} onClick={() => {this.setState({comparingSelectionType: 'bcSubmission', comparingSelectionUid: bcSubmission.uid})}} active={this.state.comparingSelectionType == 'bcSubmission' && this.state.comparingSelectionUid == bcSubmission.uid}>{csa}</Dropdown.Item>
            )
        })

        const comparingBcVersions = this.state.bcVersions.map((version) => {
            const bcPayroll = this.state.bcData.find(e => e.uid == version.bcPayrollIdentifier);
            const csa = bcPayroll?.csaIdentifier == '-1' ? 'All CSAs' : this.state.csas.find(c => c.uid == bcPayroll?.csaIdentifier)?.csaName;

            return (
                <Dropdown.Item key={version.uid} onClick={() => {this.setState({comparingSelectionType: 'bcVersion', comparingSelectionUid: version.uid})}} active={this.state.comparingSelectionType == 'bcVersion' && this.state.comparingSelectionUid == version.uid}>
                    {`${csa} - ${moment(version.dateTime).format('MMM D, YYYY [at] h:mm A')}`}
                </Dropdown.Item>
            )
        })
        

        const useTabbedView = this.state.approvedPayrollData !== undefined && this.state.payrollData !== undefined;
      
        const content = (
            <div style={{padding: 12, display: 'flex', flexDirection: 'column', gap: 12}}>
                <InputGroup>
                    <Dropdown>
                        <Dropdown.Toggle variant="outline-primary">{`Viewing: ${this.getSelectedViewingLabel()}`}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Header>Submissions</Dropdown.Header>
                            { this.state.approvedPayrollData && 
                                <Dropdown.Item onClick={() => {this.setState({viewingSelectionType: 'ttaSubmission'})}} active={this.state.viewingSelectionType == 'ttaSubmission'}>TTA Submission</Dropdown.Item>
                            }
                            { this.state.payrollData && 
                                <Dropdown.Item onClick={() => {this.setState({viewingSelectionType: 'companySubmission'})}} active={this.state.viewingSelectionType == 'companySubmission'}>Company Submission</Dropdown.Item>
                            }
                            <Dropdown.Divider/>
                            <Dropdown.Header>Saves</Dropdown.Header>
                            {viewingVersions}
                            <Dropdown.Divider/>
                            <Dropdown.Header>BC Submissions</Dropdown.Header>
                            {viewingBcSubmissions}
                            <Dropdown.Divider/>
                            <Dropdown.Header>BC Saves</Dropdown.Header>
                            {viewingBcVersions}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                        <Dropdown.Toggle variant="outline-primary">{`Comparing With: ${this.getSelectedComparingLabel()}`}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => {this.setState({comparingSelectionType: ''})}} active={this.state.comparingSelectionType == ''}>None</Dropdown.Item>
                            <Dropdown.Divider/>
                            <Dropdown.Header>Submissions</Dropdown.Header>
                            { this.state.approvedPayrollData && 
                                <Dropdown.Item onClick={() => {this.setState({comparingSelectionType: 'ttaSubmission'})}} active={this.state.comparingSelectionType == 'ttaSubmission'}>TTA Submission</Dropdown.Item>
                            }
                            { this.state.payrollData && 
                                <Dropdown.Item onClick={() => {this.setState({comparingSelectionType: 'companySubmission'})}} active={this.state.comparingSelectionType == 'companySubmission'}>Company Submission</Dropdown.Item>
                            }
                            <Dropdown.Divider/>
                            <Dropdown.Header>Saves</Dropdown.Header>
                            {comparingVersions}
                            <Dropdown.Divider/>
                            <Dropdown.Header>BC Submissions</Dropdown.Header>
                            {comparingBcSubmissions}
                            <Dropdown.Divider/>
                            <Dropdown.Header>BC Saves</Dropdown.Header>
                            {comparingBcVersions}
                        </Dropdown.Menu>
                    </Dropdown>
                </InputGroup>
                { selectedViewingEntries && 
                    <PayrollPeriodTable entries={selectedViewingEntries} originalEntries={selectedComparingEntries} periodStart={this.state.periodStart} periodEnd={this.state.periodEnd}/> 
                }
                { selectedComparingEntries &&
                    <>  
                        <hr/>
                        <h5>{this.getSelectedComparingLabel()}</h5>
                        <PayrollPeriodTable entries={selectedComparingEntries} originalEntries={selectedViewingEntries} periodStart={this.state.periodStart} periodEnd={this.state.periodEnd}/> 
                    </> 
                }
            </div>
        );
       
        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>{(this.state.isCustom ? 'Custom ' : '') + 'Payroll for ' + this.props.companyName + ' Between ' + moment(this.state.periodStart).format('MMM D, YYYY') + ' - ' + moment(this.state.periodEnd).format('MMM D, YYYY')}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                    {this.state.isLoading ? <CustomSpinner height={300}/> : content}
                </Modal.Body>
                <Modal.Footer>
                    { this.props.editPeriod &&
                        <Button variant={'outline-primary'} onClick={this.props.editPeriod}>Edit Period</Button>
                    }
                    <Button variant={'outline-primary'} onClick={() => {this.setState({showModal: true})}}>Download Payroll CSV</Button>
                    <Button variant={'outline-primary'} onClick={() => {this.setState({showPdf: true})}}>View Payroll PDF</Button>
                </Modal.Footer>
                <Modal show={this.state.showModal} backdrop='static' centered>
                    <PayrollCSADownloadModal 
                        entries={this.state.approvedPayrollData ? this.state.approvedPayrollData : this.state.payrollData} 
                        callBack={() => {this.setState({showModal: false})}}
                        periodStart={this.state.periodStart} 
                        periodEnd={this.state.periodEnd} 
                        companyName={this.props.companyName}
                    />
                </Modal>
                <Modal show={this.state.showPdf} onHide={()=>this.setState({showPdf:false})} fullscreen>
                    <PayrollPeriodPdf 
                        entries={this.state.approvedPayrollData ? this.state.approvedPayrollData : this.state.payrollData}
                        originalEntries={useTabbedView ? this.state.payrollData : undefined}
                        callBack={() => {this.setState({showModal: false})}}
                        periodStart={this.state.periodStart} 
                        periodEnd={this.state.periodEnd} 
                        companyName={this.props.companyName}
                    />
                </Modal>
            </>
        );
    }
}
