/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { fakeCsas, fakeWorkAreas, fakeVehicles } from "../../fakeData/fakeCompanyResources";
import fakeUsers from "../../fakeData/fakeUsers";
import fakeCompanyUsers from "../../fakeData/fakeCompanyUsers";
import fakeAddresses from "../../fakeData/fakeAddresses";
import fakeCompanies from "../../fakeData/fakeCompanies";
import fakePay from "../../fakeData/fakePay";
import { getResponse, getToken } from "../serviceTools";
import { timeout } from "../../tools";

export async function getCompanyResources(companyIdentifier) {
    var data = {
        type: 'hr',
        target: 'getCompanyResources',
        authToken: getToken(),
        companyIdentifier: companyIdentifier
    }

    const response = await getResponse(data);
    return response;
}

export async function addCompanyUser(companyUserIdentifier, companyIdentifier, csaIdentifier) {
    var data = {
        type: 'hr',
        target: 'addCompanyUser',
        authToken: getToken(),
        companyUserIdentifier: companyUserIdentifier,
        companyIdentifier: companyIdentifier,
        csaIdentifier: csaIdentifier
    }

    const response = await getResponse(data);
    return response;
}

export async function submitNewHire(data) {
    var data = {
        type: 'employee',
        target: 'newHire',
        authToken: getToken(),
        data: data
    }

    // const response = await getResponse(data);
    // return response && response.status === '200';
}

export async function getPermissionsTemplate() {
    var data = {
        type: 'employee',
        target: 'getPermissionsTemplate',
        authToken: getToken(),
    }

    // const response = await getResponse(data);
    // return response;
}


export async function getSSN() {
    
}

export async function getUsers(data){
    var data = {
        type:'hr',
        target:'getUsers',
        authToken:getToken(),
        data:data
    }
    const response = await getResponse(data);
    return response;
}

export async function deleteUser(userIdentifier){
    var data = {
        type:'hr',
        target:'deleteUser',
        authToken:getToken(),
        userIdentifier:userIdentifier
    }
    const response = await getResponse(data);
    return response;
}