/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';

import { createCompanyNote, deleteCompanyNote } from '../../../services/CompanyServices/CompanyNotesService';
import Modal from 'react-bootstrap/Modal';
import AlertModal from '../../../components/AlertModals/AlertModal';

export default function CompanyNotesModal({note, handleDeleteNote}) {

    const [title, setTitle] = useState(note ? note.title : '');
    const [body, setBody] = useState(note ? note.body : '');
    const [showModal, setShowModal] = useState(false);


    const handleHandleDeleteNote = async () => {
        const response = await deleteCompanyNote(note.uid);
        if (response.status === '200') {
            handleDeleteNote(note.uid);
            return true;
        }
    }


    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{note ? 'Company Note' : 'Creating Company Note'}</Modal.Title>
                {note && 
                    <Button style={{marginLeft: 24, color: 'white'}} onClick={() => {setShowModal(true)}}>Delete Note</Button>              
                }
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <Form.Group>
                    <Form.Text>Title</Form.Text>
                    <Form.Control disabled placeholder='Title' value={title} onChange={(event) => {setTitle(event.target.value)}}/>
                </Form.Group>
                <Form.Group>
                    <Form.Text>Notes</Form.Text>
                    <Form.Control disabled as='textarea' rows={12} value={body} onChange={(event) => {setBody(event.target.value)}}/>
                </Form.Group>
            </Modal.Body>
            <AlertModal 
                show={showModal} 
                onHide={() => setShowModal(false)} 
                centered 
                title='Permanently Delete Note?' 
                message='This action cannot be undone' 
                buttonLabel='Confirm Deletion' 
                callBack={handleHandleDeleteNote}
            />
        </>
    )
}