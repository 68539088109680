/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../decoding";
import { validateDecimal, validateUsd } from "../payrollTools";
import { getFakeUID } from "../../../tools";

export default class NDBonus {

    id;
    amount;
    isReoccuring;
    canBeReoccuring;

    constructor(amount = 0, isReoccuring, canBeReoccuring = true) {
        this.id = getFakeUID();
        this.amount = amount;
        this.isReoccuring = isReoccuring;
        this.canBeReoccuring = canBeReoccuring
    }

    static initDefault() {
        return new NDBonus(0, false, false);
    }

    static decode(json) {
        const decoder = new Decoder(json);
        const amount = decoder.decode('amount', Decoder.Decimal);
        const isReoccuring = decoder.decode('isReoccuring', Decoder.Boolean, {defaultValue: false, warn: true});
        const canBeReoccuring = decoder.decode('canBeReoccuring', Decoder.Boolean, {defaultValue: true, warn: true});

        if (decoder.checkForErrors()) {
            return new NDBonus(amount, isReoccuring, canBeReoccuring);
        } else {
            return NDBonus.initDefault();
        }
    }

    encode() {
        return {
            amount: validateDecimal(this.amount),
            isReoccuring: this.isReoccuring,
            canBeReoccuring: this.canBeReoccuring,
        }
    }
    
    duplicate() {
        return new NDBonus(this.amount, this.isReoccuring, this.canBeReoccuring)
    }

    getAmount() {
        return validateUsd(this.amount);
    }

}