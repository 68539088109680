/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState, useRef } from 'react';
import { InputGroup } from 'react-bootstrap';
import CustomButton from '../../../components/CustomButton';
import Modal from 'react-bootstrap/Modal';
import CustomControl from '../../../components/CustomControl';
import { uploadCompanyDocument } from '../../../services/CompanyServices/CompanyDocumentsService';
import Form from 'react-bootstrap/Form';

export default function CompanyFileUploadControl({handleUploadDocument, companyIdentifier}) { 
    const [isLoading, setIsLoading] = useState(false);
    const [documentName, setDocumentName] = useState('');
    const [documentType, setDocumentType] = useState('');
    const [isRequired, setIsRequired] = useState('0');
    const [uploadResponse, setUploadResponse] = useState(undefined);
    const ref = useRef();

    const handleOnChange = () => {
        const file = ref.current.files[0];
        const indexOfDot = file.name.lastIndexOf('.');
        setIsRequired('0');
        setDocumentName(file.name.slice(0, indexOfDot));
        setDocumentType(file.name.slice(indexOfDot + 1));
    }
    

    const handleUpload = async () => {
        const file = ref.current.files[0];
        if (file) {
            setIsLoading(true);
            setUploadResponse('');
            const reader = new FileReader();
    
            reader.onerror = () => {
                console.log(reader.error);
                setIsLoading(false);
            }
    
            reader.onload = () => {
                const fileObj = {
                    base64: reader.result,
                    documentName: documentName,
                    documentType: documentType,
                    isRequired: isRequired,
                };
    
                uploadCompanyDocument(companyIdentifier, fileObj).then((response) => {
                    if (response.status === '200') {
                        setUploadResponse('success');
                        fileObj.uid = response.uid;
                        handleUploadDocument(fileObj);
                    } else {
                        throw('Failed to upload file');
                    }
                }).catch((error) => {
                    setUploadResponse('failure');
                }).finally(() => {
                    setIsLoading(false);
                });
            }
    
            reader.readAsDataURL(file);
        }
        
    }

    const fileIsPresent = ref.current && ref.current.files[0];

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Upload Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input style={{width: 0, height: 0, opacity: 0}} ref={ref} type='file' onChange={handleOnChange}/>
                <InputGroup>
                    { uploadResponse === 'success' ? '' :
                        <CustomButton 
                            onClick={() => {ref.current.click()}} 
                            label={fileIsPresent ? 'Change File' : 'Choose File'} 
                            isLoading={isLoading}
                        />
                    }
                    <CustomControl title={fileIsPresent ? 'Document Name' : 'File to Upload'} value={documentName} onChange={(event) => {setDocumentName(event.target.value)}} disabled={!fileIsPresent}/>
                    
                    { (ref.current && ref.current.files[0]) &&
                        <InputGroup.Text>{documentType}</InputGroup.Text>
                    }
                </InputGroup>
            </Modal.Body>
            <Modal.Footer style={{display: 'flex', justifyContent: 'space-between'}}>
                <Form.Check disabled={!fileIsPresent || documentType !== 'pdf'} type='switch' label='Require Employees to Acknowledge Document (PDFs only)' checked={isRequired === '1'} onChange={() => {setIsRequired(isRequired === '1' ? '0' : '1')}}/>
                { uploadResponse && uploadResponse === 'success' ?
                    <InputGroup.Text style={{color: 'green'}}>Successfully Uploaded</InputGroup.Text>
                    : uploadResponse && uploadResponse === 'failure' ?
                    <InputGroup.Text style={{color: 'red'}}>Failed to upload</InputGroup.Text>
                    : ''
                }
                <CustomButton label='Upload' isLoading={isLoading} onClick={handleUpload} disabled={!fileIsPresent || !documentName}/>
            </Modal.Footer>
        </>
    )

}