/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useState } from "react"
import { AppGlobals } from "../../App";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { migrateCompanyResources } from "../../services/AdminToolsService";
import DropdownControl from "../../components/DropdownControl";
import CustomButton from "../../components/CustomButton";

export default function MigrateCompany({companies}){

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedSendingCompany, setSelectedSendingCompany] = useState(null);
    const [selectedReceivingCompany, setSelectedReceivingCompany] = useState(null);
    const [migrateType, setMigrateType] = useState('add');

    async function handleMigrateCompanyResources(){
        setIsSubmitting(true);
        const response = await migrateCompanyResources(selectedSendingCompany, selectedReceivingCompany, migrateType)
        if(response.status == 200){
            AppGlobals.alert('success', response.message);
            setShowModal(false)
        }else{
            AppGlobals.alert('danger', response.message);
        }
        setIsSubmitting(false)
    }

    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title>Migrate Company Resources To Another Company</Card.Title>
                </Card.Header>
                <Card.Body>
                    <div style={{display: 'flex', gap: 12, justifyContent: 'end'}}>
                        <Form.Check type='radio' label='Add' checked={migrateType === 'add'} onChange={()=>setMigrateType('add')}/>
                        <Form.Check type='radio' label='Replace' checked={migrateType === 'replace'} onChange={()=>setMigrateType('replace')}/>
                    </div>
                    <DropdownControl title='Sending Company' selection={selectedSendingCompany} setSelection={setSelectedSendingCompany} itemNames={companies.map(c=>c.companyName)} itemValues={companies.map(c=>c.uid)}/>
                    <div style={{marginBottom: 20}}></div>
                    {selectedSendingCompany && <DropdownControl title='Receiving Company' selection={selectedReceivingCompany} setSelection={setSelectedReceivingCompany} itemNames={companies.filter(c=>c.uid != selectedSendingCompany).map(c=>c.companyName)} itemValues={companies.filter(c=>c.uid != selectedSendingCompany).map(c=>c.uid)}/>}
                </Card.Body>
                <Card.Footer>
                    <Button disabled={!selectedSendingCompany || !selectedReceivingCompany} onClick={()=>setShowModal(true)} style={{display: 'block', marginLeft: 'auto'}}>
                        Migrate Resources
                    </Button>
                </Card.Footer>
            </Card>
            <Modal show={showModal} onHide={()=>setShowModal(false)}>
               <Modal.Header closeButton>
                    <Modal.Title>Migrate Company Resources</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                    <p>This action will {migrateType} the following company resources:</p>
                    <ul>
                        <li>CSAs</li>
                        <li>Work Areas</li>
                        <li>Vehicles</li>
                        <li>Company Resources</li>
                    </ul>
                    <p style={{margin: 0}}><strong>From: </strong>{companies.find(c=>c.uid == selectedSendingCompany)?.companyName}</p>
                    <p style={{margin: 0, marginBottom: 4}}><strong>To: </strong>{companies.find(c=>c.uid == selectedReceivingCompany)?.companyName}</p>
               </Modal.Body>
               <Modal.Footer>
                    <CustomButton isLoading={isSubmitting} label={'Migrate'} onClick={handleMigrateCompanyResources}/>
               </Modal.Footer>
            </Modal>
        </>
    )
}