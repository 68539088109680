/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Wizard from "../../../components/Wizard/Wizard";
import CreateCompanyAO from "./CreateCompanyAO";
import CreateCompanyCsas from "./CreateCompanyCsas";
import { AddressValidator, validateAvailablity, validateEmail, validateExistence, validatePhoneNumber, validateSsn, ValidationGroup, Validator } from "../../../validation";
import CreateCompanyWorkAreas from "./CreateCompanyWorkAreas";
import CreateCompanyVehicles from "./CreateCompanyVehicles";
import CloseButtonWithWarning from "../../../components/AlertModals/CloseButtonWithWarning";
import CreateCompanyInformation from "./CreateCompanyInformation";
import CreateCompanyReview from "./CreateCompanyReview";
import CustomButton from "../../../components/CustomButton";
import { encodeAvailability, permissionsTemplate } from "../../../tools";
import { onboardCompany } from "../../../services/CompanyServices/CompaniesService";
import { checkEmail } from "../../../services/CompanyServices/CompanyHireService";

export default function CreateCompany({hideModal}) {
    const [isLoading, setIsLoading] = useState(false);

    const [wizardIndex, setWizardIndex] = useState(0);

    const [AOInfo, setAOInfo] = useState({ //userData
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        dateOfBirth: '',
        ssn: '',
        employeeType: '0',
        title: 'AO',
        address: {},
        payType: '',
        payRate: '',
        hourlyWage: '',
        availability: [false, false, false, false, false, false, false],
    });

    const [companyInfo, setCompanyInfo] = useState({ //companyData
        isEnabled: '1',
        companyName: '',
        fedexUsername: '',
        fedexPassword: '',
        wcUsername: '',
        wcPassword: '',
        wcPolicyNumber: '',
        phoneNumber: '',
        address: {},
        benefits: '',
        forceMfa: '0',
        forceLocation: '0',
        companyAddressAllowClockIn: '0',
        companyAddressClockInRadius: 1.0,
        enableGeofencing: '0',
        allowBCAccessToSensitiveInformation: '0',
        ein: '',
        enableBCNotifications: '0',
        restrictBcsToCsa: '0',
        showBcsPayrollHistory: '0',
        payrollEnabled: '0',
        bcPayrollEnabled: '0',
        payrollPeriodLength: 7,
        payrollPeriodStartDate: 'Sun',
        driverSchedulePreviewDays: 7,
        sendFirstLoginText: '1',
        requestOffPerDayLimit: null, // not used in UI
        includeAOsInPayroll: '0',
        generatePayrollForEachCsa: '0',
    })

    const [csas, setCsas] = useState([]); //csaData
    const [workAreas, setWorkAreas] = useState([]); //workAreaData
    const [vehicles, setVehicles] = useState([]); //vehicleData›

    const [emailIsTaken, setEmailIsTaken] = useState(undefined);


    const handleSubmit = async () => {
        setIsLoading(true);

        const userData = structuredClone(AOInfo);
        userData.availability = encodeAvailability(userData.availability);

        const newPermissions = {};
        permissionsTemplate.forEach((permObj) => {
            newPermissions[permObj.permissionName] = permObj.ao;
        });
        userData.permissions = newPermissions


        const checkEmailResponse = await checkEmail(AOInfo.email)
        if (checkEmailResponse.status === '400') {
            alert("AO email address is already taken.");
            return;
        }

        const response = await onboardCompany(userData, csas, workAreas, vehicles, companyInfo)
        if (response.status === '200') {
            hideModal();
        }
        setIsLoading(false);
    }

    const handleCheckEmail = async () => {
        const response = await checkEmail(AOInfo.email);
        if (response.status === '400') {
            setEmailIsTaken(true);
        } else {
            setEmailIsTaken(false);
        }

    }


    const handleSetAOInfo = (key, value) => {
        const newObj = structuredClone(AOInfo);
        newObj[key] = value;
        if (key === 'email') {
            setEmailIsTaken(undefined);
        }
        setAOInfo(newObj);
    }

    const handleSetCompanyInfo = (key, value) => {
        const newObj = structuredClone(companyInfo);
        newObj[key] = value;
        setCompanyInfo(newObj);
    }

    const handleAddCsa = (csa) => {
        const newArray = Array.from(csas);
        newArray.push(csa);
        setCsas(newArray) ;
    }
    
    const handleRemoveCsa = (uid) => {
        if (workAreas.map(r => r.csaIdentifier).includes(uid)) {
            const newWorkAreas = Array.from(workAreas).filter(r => r.csaIdentifier !== uid);
            setWorkAreas(newWorkAreas);
        }
        if (vehicles.map(r => r.csaIdentifier).includes(uid)) {
            const newVehicles = Array.from(vehicles).filter(r => r.csaIdentifier !== uid);
            setVehicles(newVehicles);
        }
        const newArray = Array.from(csas).filter(r => r.uid !== uid);
        setCsas(newArray);
    }
    const handleAddWorkArea = (wa) => {
        const newArray = Array.from(workAreas);
        newArray.push(wa);
        setWorkAreas(newArray) ;
    }
    
    const handleRemoveWorkArea = (uid) => {
        const newArray = Array.from(workAreas).filter(r => r.uid !== uid);
        setWorkAreas(newArray);
    }
    const handleAddVehicle = (vehicle) => {
        const newArray = Array.from(vehicles);
        newArray.push(vehicle);
        setVehicles(newArray) ;
    }
    
    const handleRemoveVehicle = (uid) => {
        const newArray = Array.from(vehicles).filter(r => r.uid !== uid);
        setVehicles(newArray);
    }

    const aoInfoValidators = {
        firstName: new Validator(() => validateExistence(AOInfo.firstName)),
        middleName: new Validator(() => validateExistence(AOInfo.middleName)),
        lastName: new Validator(() => validateExistence(AOInfo.lastName)),
        email: new Validator(() => validateEmail(AOInfo.email)),
        phoneNumber: new Validator(() => validatePhoneNumber(AOInfo.phoneNumber)),
        dateOfBirth: new Validator(() => validateExistence(AOInfo.dateOfBirth)),
        ssn: new Validator(() => validateSsn(AOInfo.ssn)),
        address: new AddressValidator(AOInfo.address),
        payType: new Validator(() => validateExistence(AOInfo.payType)),
        payRate: new Validator(() => validateExistence(AOInfo.payRate)),
        hourlyWage: new Validator(() => validateExistence(AOInfo.hourlyWage)),
        availability: new Validator(() => validateAvailablity(AOInfo.availability)),
    }
    const companyInfoValidators = {
        companyName: new Validator(() => validateExistence(companyInfo.companyName)),
        fedexUsername: new Validator(() => validateExistence(companyInfo.fedexUsername)),
        fedexPassword: new Validator(() => validateExistence(companyInfo.fedexPassword)),
        wcUsername: new Validator(() => validateExistence(companyInfo.wcUsername)),
        wcPassword: new Validator(() => validateExistence(companyInfo.wcPassword)),
        phoneNumber: new Validator(() => validatePhoneNumber(companyInfo.phoneNumber)),
        address: new AddressValidator(companyInfo.address),
        benefits: new Validator(() => validateExistence(companyInfo.benefits)),
        companyAddressClockInRadius: new Validator(() => validateExistence(companyInfo.companyAddressClockInRadius)),
        ein: new Validator(() => validateExistence(companyInfo.ein)),
    }

    const formIsValid = Object.values(aoInfoValidators).reduce((prev, curr) => {
        return prev && curr.isValid();
    }, true) && Object.values(companyInfoValidators).reduce((prev, curr) => {
        return prev && curr.isValid();
    }, true);

    return (
        <>
            <Modal.Header>
                <Modal.Title>Creating Company</Modal.Title>
                <CloseButtonWithWarning callBack={hideModal} centered/>
            </Modal.Header>
            <Modal.Header>
                <div style={{width: '100%', marginLeft: 24, marginRight: 24}}>

                    <Wizard steps={['Create AO Profile', 'CSAs', 'Work Areas', 'Vehicles', 'Company Information', 'Review']} stepIndex={wizardIndex} setStepIndex={setWizardIndex}/>
                </div>
            </Modal.Header>
            <Modal.Body>
                { wizardIndex === 0 ?
                    <CreateCompanyAO AOInfo={AOInfo} validators={aoInfoValidators} handleSetAOInfo={handleSetAOInfo} emailIsTaken={emailIsTaken} handleCheckEmail={handleCheckEmail}/>
                    : wizardIndex === 1 ?
                    <CreateCompanyCsas csas={csas} handleAddCsa={handleAddCsa} handleRemoveCsa={handleRemoveCsa}/>
                    : wizardIndex === 2 ?
                    <CreateCompanyWorkAreas workAreas={workAreas} handleAddWorkArea={handleAddWorkArea} handleRemoveWorkArea={handleRemoveWorkArea} csas={csas}/>
                    : wizardIndex === 3 ? 
                    <CreateCompanyVehicles vehicles={vehicles} handleAddVehicle={handleAddVehicle} handleRemoveVehicle={handleRemoveVehicle} csas={csas}/>
                    : wizardIndex === 4 ?
                    <CreateCompanyInformation companyInfo={companyInfo} handleSetCompanyInfo={handleSetCompanyInfo} validators={companyInfoValidators}/>
                    :
                    <CreateCompanyReview AOInfo={AOInfo} csas={csas} workAreas={workAreas} vehicles={vehicles} companyInfo={companyInfo}/>
                }
            </Modal.Body>
            { wizardIndex === 5 &&
                <Modal.Footer>
                    <CustomButton label='Submit' onClick={handleSubmit}/>
                </Modal.Footer>
            }
        </>
    )
}