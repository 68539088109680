/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import './Wizard.css';
import React, {useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEllipsisH } from '@fortawesome/free-solid-svg-icons';

function Wizard({steps, completedSteps, stepIndex, setStepIndex}) {

    const handleStepChange = (index) => {
        if (setStepIndex) {
            setStepIndex(index);
        }
    }

    const elements = steps.map((step, index) => {
        let elementsToReturn = [];
        elementsToReturn.push(
            <div key={step} className={setStepIndex ? 'cursor-pointer' : ''} onClick={() => handleStepChange(index)}>
                <div className={'wizard-icon-wrapper' + (index < stepIndex ? ' wizard-icon-wrapper-complete' : index === stepIndex ? ' wizard-icon-wrapper-pending' : '')}>
                    {index <= stepIndex ? <FontAwesomeIcon className={'wizard-icon' + (index < stepIndex ? ' wizard-icon-complete' : '')} icon={index < stepIndex ? faCheck : faEllipsisH}/> : ''}
                    <p style={{position: 'absolute', whiteSpace: 'nowrap', bottom: -28, margin: 0, fontWeight: index === stepIndex ? 'bold' : 'normal', opacity: index === stepIndex ? 1.0 : 0.7}}>
                        {index >= stepIndex || !completedSteps ? step : completedSteps[index]}
                    </p>
                </div>
                
            </div>
        );
        
        if (index < steps.length - 1) {
            elementsToReturn.push(<div key={step + ' line'} style={{display: 'flex', flex: 1, height: 2, marginTop: 20, backgroundColor: 'var(--bs-primary)', opacity: index >= stepIndex ? 0.0 : 1.0}}/>);
        }
        return elementsToReturn;
    })

    return (
        <div style={{position: 'relative', display: 'flex', padding: '8px 40px 8px 40px'}} >
            {elements}
        </div>
    )
}

export default Wizard;
