/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import { ListGroup, Modal, Button, OverlayTrigger} from 'react-bootstrap';
import moment from 'moment';
import CustomButton from '../../../../components/CustomButton';
import KeyValueRow from '../../../../components/KeyValueRow';
import SettlementReportUploadModal from './SettlementReportUploadModal';
import NDBonus from '../../Models/NDBonus';
import Pay from '../../Models/Pay';
import PayrollEntryWeek from '../../Models/PayrollEntryWeek';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation, faCircleHalfStroke, faCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle as faCircleEmpty } from '@fortawesome/free-regular-svg-icons';
import { Popover } from 'react-bootstrap';
import { adminMode } from '../../payrollTools';


export default function PayrollSettlementReportModal({payData, hideModal, settlementReportData, handleUpdateUserFedexIds, userData, payrollPeriod, handleSetSettlementReportData, csas, entries, handleSetEntry, employeeFilter}) {

    const [showUploadModal, setShowUploadModal] = useState(false);

    const [usersToRecalculate, setUsersToRecalculate] = useState(Array.from(employeeFilter));
    const [showRecalculateModal, setShowRecalculateModal] = useState(false);


    const getEmployeeAvailableDataStatus = (companyUserIdentifier) => {
        const coveredDays = [];
        settlementReportData.forEach((report) => {
            if (report.days.find(d => d.companyUserIdentifier == companyUserIdentifier)) {
                const numberOfDaysInReport = moment(report.endDate).diff(moment(report.startDate), 'days') + 1;
                for (let i = 0; i < numberOfDaysInReport; i++) {
                    if (!coveredDays.includes(moment(report.startDate).add(i, 'days').format('YYYY-MM-DD'))) {
                        coveredDays.push(moment(report.startDate).add(i, 'days').format('YYYY-MM-DD'));
                    }
                } 
            }
        });
        
        const numberOfDaysInPeriod = moment(payrollPeriod.periodEnd).diff(moment(payrollPeriod.periodStart),'days') + 1;
        
        const daysAccounted = [];
        for (let i = 0; i < numberOfDaysInPeriod; i++) {
            let periodDay = moment(payrollPeriod.periodStart).add(i, 'days').format('YYYY-MM-DD');
            const matchedDay = coveredDays.find(cd => cd == periodDay);
            if (matchedDay) {
                daysAccounted.push(periodDay);
            } 
        }

        const availableStatus = numberOfDaysInPeriod == daysAccounted.length ? 'complete' : daysAccounted.length > 0 ? 'partial' : 'none';
        return availableStatus;
    }

    const handleAddUserToRecalculate = (user) =>{
        let newArr = Array.from(usersToRecalculate);
        if(!newArr.includes(user)){
            newArr.push(user);
        } else {
            newArr = newArr.filter((u)=>u !== user);
        }
        setUsersToRecalculate(newArr);
    }

    const handleRecalculateStopWages = () =>{
        usersToRecalculate.forEach((companyUserIdentifier)=>{
            const entry = entries.find((e)=>e.companyUserIdentifier == companyUserIdentifier);
            entry.recalculateStopWages(settlementReportData, payData);
            handleSetEntry(entry);
        });
        setShowRecalculateModal(false);
        hideModal();
    }

    const usersToRecalculateElements = entries.filter(e => employeeFilter.includes(e.companyUserIdentifier)).map(e => e.companyUserIdentifier).map((e)=>{
      
        const isActive = usersToRecalculate.includes(e);
        const user = entries.find((u)=>u.companyUserIdentifier == e);
        return (
            <ListGroup.Item key={e} action active={isActive} onClick={()=>{handleAddUserToRecalculate(e)}}>{user.lastName + ', ' + user.firstName + ' ' + user.middleName.substring(0,1)}</ListGroup.Item>
        )
    });


    const userListItems = entries.filter(e => employeeFilter.includes(e.companyUserIdentifier)).map(e => e.companyUserIdentifier).map((companyUserIdentifier) => {
        const entry = entries.find(e => e.companyUserIdentifier == companyUserIdentifier);
        const availableStatus = getEmployeeAvailableDataStatus(companyUserIdentifier);

        return (
            <ListGroup.Item key={companyUserIdentifier} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <span>{entry.name()}</span>
                <div style={{display: 'flex', gap: 12}}>
                    <OverlayTrigger overlay={
                        <Popover style={{position: 'fixed'}}>
                            <Popover.Header style={{padding: 6}}>{entry.settlementReportStatus == 'complete' ? 'Complete Data Utilized' : entry.settlementReportStatus == 'partial' ? 'Partial Data Utilized' : 'No Data Utilized'}</Popover.Header>
                            <Popover.Body style={{padding: 6}}>{`This represents the settlement data used to generate the stop wages for this employee.`}</Popover.Body>
                        </Popover>
                    }>
                        <div className='cursor-pointer' style={{border: '1px solid lightgray', borderRadius: 12, padding: 2}}>
                            <span style={{opacity: 0.5, fontStyle: 'italic', marginRight: 4}}>Utilized:</span>
                            <FontAwesomeIcon style={{color: entry.settlementReportStatus == 'complete' ? 'limegreen' : entry.settlementReportStatus == 'partial' ? 'gold' : 'lightgray'}} icon={entry.settlementReportStatus == 'complete' ? faCircle : entry.settlementReportStatus == 'partial' ? faCircleHalfStroke : faCircleEmpty}/>
                        </div>
                    </OverlayTrigger>
                    <div style={{width: 1, backgroundColor: 'black'}}/>
                    <OverlayTrigger overlay={
                        <Popover style={{position: 'fixed'}}>
                            <Popover.Header style={{padding: 6}}>{availableStatus == 'complete' ? 'Complete Data Available' : availableStatus == 'partial' ? 'Partial Data Available' : 'No Data Available'}</Popover.Header>
                            <Popover.Body style={{padding: 6}}>{`This represents the settlement data in our system between ${moment(payrollPeriod.periodStart).format('MMM D, YYYY')} - ${moment(payrollPeriod.periodEnd).format('MMM D, YYYY')} for this employee.`}</Popover.Body>
                        </Popover>
                    }>
                        <div className='cursor-pointer' style={{border: '1px solid lightgray', borderRadius: 12, padding: 2}}>
                            <span style={{opacity: 0.5, fontStyle: 'italic', marginRight: 4}}>Available:</span>
                            <FontAwesomeIcon style={{color: availableStatus == 'complete' ? 'limegreen' : availableStatus == 'partial' ? 'gold' : 'lightgray'}} icon={availableStatus == 'complete' ? faCircle : availableStatus == 'partial' ? faCircleHalfStroke : faCircleEmpty}/>
                        </div>
                    </OverlayTrigger>
                </div>
            </ListGroup.Item>
        )
    });

    const settlementReportElements = settlementReportData.map((s)=>{
        const csaName = csas.find((c)=>c.uid == s.csaIdentifier).csaName;

        return (
            <KeyValueRow key={s.uid} isListItem title={csaName} value={moment(s.startDate).format('MMM D, YYYY') + ' - ' + moment(s.endDate).format('MMM D, YYYY')}/>
        )
    });

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Settlement Report Data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Please upload all settlement reports for this payroll period. As you upload reports you can view the affected employees and days below.</p>
                <ListGroup>
                    {userListItems}
                </ListGroup>
                <p style={{textDecoration:'underline', marginTop:10}}>Loaded Settlement Reports</p>
                <ListGroup>
                    {settlementReportElements}
                </ListGroup>
            </Modal.Body>
             {!adminMode && 
                <Modal.Footer>
                    <CustomButton label={'Recalculate Stop Wages'} onClick={()=>setShowRecalculateModal(true)}/>
                    <CustomButton label={'Upload Settlement Report'} onClick={()=>setShowUploadModal(true)}/>
                </Modal.Footer>
            }
            <Modal show={showUploadModal} onHide={()=>{setShowUploadModal(false)}} size='lg'>
                <SettlementReportUploadModal payrollView={true} csas={csas} userData={userData} handleSetSettlementReportData={handleSetSettlementReportData} handleUpdateUserFedexIds={handleUpdateUserFedexIds} hideModal={()=>setShowUploadModal(false)}/>
            </Modal>
            <Modal show={showRecalculateModal} onHide={() => {setShowRecalculateModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Recalculate Stop Wages</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        {usersToRecalculateElements}
                    </ListGroup>
                    <FontAwesomeIcon icon={faTriangleExclamation} style={{color: 'gold', marginRight: 8}}/>
                    Upon recalculating an employee's stop wages, any preexisting stops & stop wages will be deleted and recreated based upon the uploaded settlement data.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='outline-primary' onClick={handleRecalculateStopWages}>Proceed</Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}