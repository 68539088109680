/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import CompanyCSAModal from "../CompanyCsas/CompanyCSAModal";
import { getCompanyWorkAreas } from "../../../services/CompanyServices/CompanyWorkAreasService";
import PageSpinner from "../../../components/PageSpinner";
import QuickTable from "../../../components/QuickTable";
import CompanyWorkAreaModal from "./CompanyWorkAreaModal";

export default function CompanyWorkAreas({selectedCompany}) {
    const [isLoading, setIsLoading] = useState(false);
    const [showInactiveItems, setShowInactiveItems] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [csas, setCsas] = useState([]);
    const [workAreas, setWorkAreas] = useState([]);

    const sortByUid = (a, b) => { return a.uid - b.uid };

    const loadData = async () => {
        setIsLoading(true);
        const response = await getCompanyWorkAreas(selectedCompany.uid);
        if (response.status === '200') {
            setWorkAreas(response.workAreas.sort(sortByUid));
            setCsas(response.csas);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadData();
    }, [])

    const handleCrud = (type, workArea) => {
        let newArray = Array.from(workAreas);
        if (type !== 'create') {
            newArray = newArray.filter(r => r.uid !== workArea.uid);
        }
        if (type !== 'delete') {
            newArray.push(workArea);
        }
        newArray.sort(sortByUid);
        setWorkAreas(newArray);
        setShowModal(false);
    }

    const workAreaRows = workAreas.filter(workArea => showInactiveItems || workArea.isActive === '1').map((workArea) => {

        const csa = csas.find(r => r.uid === workArea.csaIdentifier);

        return (
            <tr key={workArea.uid} style={workArea.isActive === '1' ? {} : {opacity: 0.5}} className='cursor-pointer' onClick={() => {setSelectedItem(workArea); setShowModal(true);}}>
                <td>{workArea.uid}</td>
                <td>{workArea.workAreaName + (workArea.isActive === '1' ? '' : ' (inactive)')}</td>
                <td>{csa.csaName}</td>
            </tr>
        )
    });

    const content = (
        <div>
            <div style={{display: 'flex', paddingLeft: 24, paddingRight: 24, marginBottom: 12, justifyContent: 'space-between', alignItems: 'center'}}>
                <Form.Check style={{flexShrink: 0}} type='switch' label='Show Inactive Work Areas' checked={showInactiveItems} onChange={() => {setShowInactiveItems(!showInactiveItems)}}/>
                <Button style={{color: 'white'}} onClick={() => {setSelectedItem(undefined); setShowModal(true);}}>
                    Add Work Area
                </Button>
            </div>

            <QuickTable headers={['UID', 'Name', 'CSA']} rows={workAreaRows} hover/>

            <Modal show={showModal} onHide={() => {setShowModal(false)}} centered size='lg'>
                <CompanyWorkAreaModal data={selectedItem} handleCrud={handleCrud} csas={csas} selectedCompany={selectedCompany}/>
            </Modal>
        </div>
    )

    return (
        <>
            {isLoading ? <PageSpinner/> : content}
        </>
    )
}