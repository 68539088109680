/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ErrorPage from './views/ErrorPage/ErrorPage';
// import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './css/custom.min.css';
import Login from './views/Login/Login';
import { checkAuthentication } from './services/AuthenticationService';
import Cookies from 'universal-cookie';
import { getPages } from './pages';

document.title = 'TTA';

checkAuthentication().then((response) => {
    if (response && response.status === '202') {
        const cookieMonster = new Cookies();
        cookieMonster.set('permissions', response.permissions);
        cookieMonster.set('userData', response.userData);

        const permissions = response.permissions;

        const router = createBrowserRouter([
            {
                path: '/',
                element: <App userData={response.userData}/>,
                errorElement: <ErrorPage/>,
                children: getPages(permissions)
            },
        ]);

        ReactDOM.createRoot(document.getElementById("root")).render(
            <RouterProvider router={router}/>
        );


    } else if (response.status === '401') {
        ReactDOM.createRoot(document.getElementById("root")).render(
            <Login/>
        );
    }
});


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
