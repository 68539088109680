/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from "../serviceTools";


export async function getAllEmployeeLoans(companyUserIdentifier){
    const data = {
        type: 'hr',
        target: 'getAllLoans',
        authToken: getToken(),
        companyUserIdentifier: companyUserIdentifier
    }

    const response = await getResponse(data);

    return response;
}

export async function getEmployeeLoan(uid){
    const data = {
        type: 'hr',
        target: 'getLoan',
        authToken: getToken(),
        uid: uid
    }

    const response = await getResponse(data);
    return response;
}

export async function createEmployeeLoan(loan){
    const data = {
        type: 'hr',
        target: 'createLoan',
        authToken: getToken(),
        loan: loan
    }

    const response = await getResponse(data);

    return response;
}

export async function createEmployeeLoanInstallment(loanInstallment){
    const data = {
        type: 'hr',
        target: 'createLoanInstallment',
        authToken: getToken(),
        loanInstallment: loanInstallment
    }

    const response = await getResponse(data);
    return response;
}

export async function updateEmployeeLoan(loan){
    const data = {
        type: 'hr',
        target: 'updateLoan',
        authToken: getToken(),
        loan: loan
    }

    const response = await getResponse(data);

    return response;
}

export async function deleteEmployeeLoan(uid){
    const data = {
        type: 'hr',
        target: 'deleteLoan',
        authToken: getToken(),
        uid: uid
    }

    const response = await getResponse(data);

    return response;
}

export async function deleteEmployeeLoanInstallment(uid){
    const data = {
        type: 'hr',
        target: 'deleteLoanInstallment',
        authToken: getToken(),
        uid: uid
    }

    const response = await getResponse(data);

    return response;
}

export async function getEmployeeTicket(uid){
    const data = {
        type: 'hr',
        target: 'getTicket',
        authToken: getToken(),
        uid: uid
    }

    const response = await getResponse(data);

    return response;
}

export async function createEmployeeTicket(ticket){
    const data = {
        type: 'hr',
        target: 'createTicket',
        authToken: getToken(),
        ticket: ticket
    }

    const response = await getResponse(data);

    return response;
}

export async function createEmployeeTicketInstallment(ticketInstallment) {
    const data = {
        type: 'hr',
        target: 'createTicketInstallment',
        authToken: getToken(),
        ticketInstallment: ticketInstallment
    }

    const response = await getResponse(data);

    return response;
}

export async function updateEmployeeTicket(ticket){
    const data = {
        type: 'hr',
        target: 'updateTicket',
        authToken: getToken(),
        ticket: ticket
    }

    const response = await getResponse(data);

    return response;
}

export async function deleteEmployeeTicket(uid){
    const data = {
        type: 'hr',
        target: 'deleteTicket',
        authToken: getToken(),
        uid: uid
    }

    const response = await getResponse(data);
    return response;
}

export async function deleteEmployeeTicketInstallment(uid){
    const data = {
        type: 'hr',
        target: 'deleteTicketInstallment',
        authToken: getToken(),
        uid: uid
    }

    const response = await getResponse(data);
    return response;
}

export async function getEmployeePayrollLoans(companyUserIdentifier, payrollPeriod){
    const data = {
        type:'payroll',
        target:'getEmployeePayrollLoans',
        authToken:getToken(),
        companyUserIdentifier:companyUserIdentifier,
        payrollPeriod:payrollPeriod
    }
    const response = await getResponse(data);
    return response;
}