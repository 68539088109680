/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import CustomControl from "../../../../../components/CustomControl";
import Modal from 'react-bootstrap/Modal';
import { validateExistence, ValidationGroup, validateGreaterThanZero } from "../../../../../validation";
import { Form } from "react-bootstrap";
import SwitchControl from "../../../../../components/SwitchControl";
import {Row, Container} from "react-bootstrap";
import RadioControl from "../../../../../components/RadioControl";
import Deduction from "../../../Models/Deduction";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function PayrollDeductionModal({handleAddDeduction}) {
    const [amount, setAmount] = useState('');
    const [type, setType] = useState('');
    const [isReoccuring, setIsReoccuring] = useState(false);

    const [applyToAll, setApplyToAll] = useState(false);

    const validationGroup = new ValidationGroup();

    const handleSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }
        if (validationGroup.isValid()) {
            handleAddDeduction(new Deduction(type, amount, isReoccuring), applyToAll);
        }
    }

    
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Adding Deductions</Modal.Title>
            </Modal.Header>
            <Modal.Header style={{display: 'flex', flexDirection: 'column', gap: 12, alignItems: 'flex-start', width: '100%'}}>
                <RadioControl validator={validationGroup.createValidator(() => validateExistence(type))} title='Deductions' inline selection={type} setSelection={setType} optionNames={Deduction.deductionTypes} optionValues={Deduction.deductionTypes}/>             
            </Modal.Header>
            <Modal.Body >
                <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                    <CustomControl floatingLabel validator={validationGroup.createValidator(() => validateGreaterThanZero(amount))} type='number' min={0} value={amount} onChange={(event) => {setAmount(event.target.value)}} setValue={setAmount} title='Amount'/>
                    <SwitchControl value={isReoccuring} setValue={setIsReoccuring} title={'Reoccurring'}/>
                    <PayrollAdditionalPayModalTooltip boldText='Reoccurring' text={' - Next payroll period, this Deduction will be automatically added to the employee(s)'}/>                
                    <button style={{width: 0, height: 0, padding: 0, margin: -7, opacity: 0}} type="submit" className='button-primary'/>
                </form>
            </Modal.Body>
            <Modal.Footer style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Form.Check type='switch' checked={applyToAll} onChange={() => {setApplyToAll(!applyToAll)}} label='Add deduction to all employees'/>
                <Button style={{color: 'white'}} disabled={!validationGroup.isValid()} onClick={handleSubmit}>Submit</Button>
            </Modal.Footer>
        </>
    )
}

function PayrollAdditionalPayModalTooltip({boldText, text}) {
    return (
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 12}}>
            <FontAwesomeIcon style={{color: 'var(--bs-primary)'}} icon={faInfoCircle}/>
            <div>
                <span style={{fontWeight: 'bold'}}>{boldText + ' '}</span>
                <span>{text}</span>
            </div>
        </div>
    )
}