/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useEffect, useState} from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import { acceptRequestOff } from '../../../services/HRServices/HRScheduleService';
import CustomButton from '../../../components/CustomButton';
import { getHRUserPtoBalance } from '../../../services/HRServices/HRPtoService';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import AlertModal from '../../../components/AlertModals/AlertModal';
import { toFixedMax } from '../../Payroll/payrollTools';

export default function HRUserSchedulePTODaySelector({selectedItem, handleAddPTO}) {
    const [isLoading, setIsLoading] = useState(false);

    const [selectedDays, setSelectedDays] = useState({});

    const [ptoBalance, setPtoBalance] = useState(0);

    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        getHRUserPtoBalance(selectedItem.companyUserIdentifier).then((response) => {
            if (response.status === '200') {
                setPtoBalance(response.balance);
            }
        })
        
    }, []);

    const handleCreatePTO = async () => {
        setIsLoading(true);
        const numberOfDays = moment(selectedItem.endDate).diff(moment(selectedItem.date), 'days') + 1;
        const scheduleItems  = [];
        
        for (let i = 0; i < numberOfDays; i++) {
            const date = moment(selectedItem.date).add(i, 'days').format('YYYY-MM-DD');
            const isSelected = selectedDays[date] !== undefined && selectedDays[date] !== 0;
            scheduleItems.push({
                companyUserIdentifier: selectedItem.companyUserIdentifier,
                date: date,
                notes: selectedItem.notes,
                scheduleType: isSelected ? 'pto' : 'dayOff',
                hours: selectedDays[date]
            });
        }

        const createPtoResponse = await acceptRequestOff(scheduleItems, selectedItem.uid);

        if (createPtoResponse.status === '200') {
            handleAddPTO();
            
        }

        setIsLoading(false);
    }


    const handleSelectDay = (momentDate) => {
        const date = momentDate.format('YYYY-MM-DD');
        let newObj = structuredClone(selectedDays);

        if (!newObj[date]) {
            newObj[date] = 8;
        } else if (newObj[date] === 8) {
            newObj[date] = 6
        } else if (newObj[date] === 6) {
            newObj[date] = 4
        } else if (newObj[date] === 4) {
            newObj[date] = 2
        } else if (newObj[date] === 2) {
            newObj[date] = 0
        }
        setSelectedDays(newObj);
    }

    const days = [];

    const firstDay = moment(selectedItem.date).startOf('week');
    const lastDay = moment(selectedItem.endDate).endOf('week');

    for (let i = 0; i < lastDay.diff(firstDay, 'days') + 1; i++) {
        days.push(moment(firstDay).add(i, 'days'));
    }


    const dayElements = days.map((day) => {
        const isInRange = moment(day).isBetween(moment(selectedItem.date), moment(selectedItem.endDate), 'days', '[]');
        const hoursOnDay = selectedDays[day.format('YYYY-MM-DD')] ? selectedDays[day.format('YYYY-MM-DD')] : 0;

        const selectionOverlay = (
            <CircularProgressbar value={hoursOnDay} maxValue={8} strokeWidth={50} styles={buildStyles({strokeLinecap: 'butt', pathColor: "yellow",
            trailColor: "white"})}/>
        )

        return (
            <div 
                key={day.format('YYYY-MM-DD')} 
                onClick={isInRange ? () => {handleSelectDay(day)} : () => {}} 
                className='cursor-pointer' 
                style={{position: 'relative', display: 'flex', backgroundColor: 'white', flexDirection: 'column', opacity: isInRange ? 1 : 0.2, justifyContent: 'center', alignItems: 'center', border: '2px var(--bs-primary) solid', width: 58, height: 58, borderRadius: 29, padding: 8}}
                >
                <div style={{position: 'absolute', zIndex: 0}}>
                    {selectionOverlay}
                </div>
                <p className='unselectable-text' style={{margin: 0, zIndex: 1, fontSize: 15}}>{day.format('D')}</p>
                <p className='unselectable-text' style={{margin: 0, zIndex: 1, fontSize: 15}}>{day.format('ddd')}</p>
                
            </div>
        )
    });


    return (
        <>
            <Modal.Header>
                <Modal.Title>Select Days for PTO</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Select the days for which the employee will recieve paid time off from the requested date range:
                <p style={{textAlign: 'center', fontWeight: 'bold'}}>{moment(selectedItem.date).format('MMM D, YYYY') + ' - ' + moment(selectedItem.endDate).format('MMM D, YYYY')}</p>
                <div style={{display: 'flex', flexWrap: 'wrap', gap: 8}}>
                    {dayElements}
                </div>
            </Modal.Body>
            <Modal.Footer style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', fontSize: 15}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span>PTO Hours Selected</span>
                    <span style={{fontWeight: 'bold'}}>{Object.values(selectedDays).reduce((prev, curr) => {return prev + curr}, 0.0)}</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span>PTO Hours Available</span>
                    <span style={{fontWeight: 'bold'}}>{toFixedMax(ptoBalance, 3)}</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span>Remaining Balance</span>
                    <span style={{fontWeight: 'bold'}}>{toFixedMax(ptoBalance - Object.values(selectedDays).reduce((prev, curr) => {return prev + curr}, 0.0), 3)}</span>
                </div>
            </Modal.Footer>
            <Modal.Footer>
                <CustomButton label='Submit' disabled={selectedDays.length === 0 || showAlert} isLoading={isLoading} onClick={()=>{setShowAlert(true)}}/>
            </Modal.Footer>
            <AlertModal title='Are you sure you want to submit?' show={showAlert} onHide={()=>setShowAlert(false)} callBack={handleCreatePTO} buttonLabel='Submit' message='If your employee would like to use PTO please make sure you have selected PTO days and hours.'/>
        </>
    )
}