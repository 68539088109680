/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { createRef, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { encodeAvailability } from "../../../../tools";
import { checkEmail, getPermissionsTemplate, submitNewHire } from "../../../../services/CompanyServices/CompanyHireService";
import CustomButton from "../../../../components/CustomButton";
import { CloseButton } from "react-bootstrap";
import { getCompanyResources } from "../../../../services/CompanyServices/CompaniesService";
import template from '../../../../assets/OnboardingTemplate.xlsx';
import moment from "moment";

export default class HRHireCSV extends React.Component {
    constructor(props) {
        super(props);
        this.fileRef = createRef();
        this.state = {
            isLoading: false,
            permissionsTemplate: undefined,
            errors: [],
            success: 0,
            csas: []
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    loadData() {
        this.setState({isLoading: true}, async () => {
            const response = await getPermissionsTemplate();
    
            if (response && response.status === '200') {
                this.setState({permissionsTemplate: response.template});
            }
            const companyResourceResponse = await getCompanyResources(this.props.selectedCompany.uid);
            if (companyResourceResponse.status === '200') {
                this.setState({csas: companyResourceResponse.csas});
            }

            this.setState({isLoading: false});
        });

    }

    componentDidMount() {
        this.loadData();
    }

    getPermissions(title) {
        title = title.toLowerCase();

        const permissions = {}

        this.state.permissionsTemplate.forEach((permObj) => {
            permissions[permObj.permissionName] = permObj[title];
        });

        return permissions;
    }

    handleSubmit() {
        const file = this.fileRef.current.files[0];
        if (file) {
            this.setState({isLoading: true});
            const reader = new FileReader();
    
            reader.onerror = () => {
                console.log(reader.error);
            }
    
            reader.onload = async () => {
    
                const str = reader.result;
    
                let rows = str.split(/\r\n|\r|\n/);
                rows = rows.slice(5);
                for (let i = 0; i < rows.length; i++) {
                    rows[i] = rows[i].split(',');
                    rows[i] = rows[i].slice(0, 49);
                }

                rows = rows.filter(r => r[0]);

                const errors = [];
                const dataArray = [];

                for (let i = 0; i < rows.length; i++) {
                    const row = rows[i];

                    const csa = this.props.csas.find(csa => csa.csaName.toLowerCase() === row[20].toLowerCase());

                    if (!csa) {
                        errors.push({
                            row: i + 6,
                            message: 'CSA Not Found'
                        });
                    }

                    let missingRequiredFields = 0;

                    for (let j = 0; j < 29; j++) {
                        if ([1, 8, 25, 26, 27, 28].includes(j)) {
                            continue;
                        }
                        if (!row[j]) {
                            missingRequiredFields++;
                        }
                    }
                    if (missingRequiredFields) {
                        errors.push({
                            row: i + 6,
                            message: 'Missing ' + missingRequiredFields + ' required column' + (missingRequiredFields > 1 ? 's' : '')
                        });
                    }

                    const response = await checkEmail(row[3]);
                    if (response.status === '400') {
                        errors.push({
                            row: i + 6,
                            message: 'Email address has already been taken'
                        });
                    }

                    
                    const data = {
                        firstName: row[0],
                        middleName: row[1],
                        lastName: row[2],
                        email: row[3], 
                        phoneNumber: row[4], 
                        dateOfBirth: row[5], 
                        ssn: row[6], 
                        address: {
                            thoroughfare: row[7], 
                            premise: row[8], 
                            administrativeArea: row[9], 
                            locality: row[10], 
                            postalCode: row[11]
                        }, 
                        availability: encodeAvailability(row.slice(12, 19).map(r => r === 'Yes' ? true : false)),
                        payType: row[19],
                        csaIdentifier: csa ? csa.uid : undefined,
                        employeeType: row[21] === 'Full-Time' ? 0 : 1,
                        title: row[22],
                        payRate: row[23],
                        hourlyWage: row[24],
                        dailyStartTime: row[25],
                        startDate: row[26],
                        firstDayStartTime: row[27],
                        benefits: row[28],
                        eContact1FirstName: row[29],
                        eContact1LastName: row[30],
                        eContact1PhoneNumber: row[31],
                        eContact1Email: row[32],
                        eContact1Relationship: row[33],
                        eContact1Address: row[34] || row[35] || row[36] || row[37] || row[38] ? {
                            thoroughfare: row[34], 
                            premise: row[35], 
                            administrativeArea: row[36], 
                            locality: row[37], 
                            postalCode: row[38]
                        } : null,
                        eContact2FirstName: row[39],
                        eContact2LastName: row[40],
                        eContact2PhoneNumber: row[41],
                        eContact2Email: row[42],
                        eContact2Relationship: row[43],
                        eContact2Address: row[44] || row[45] || row[46] || row[47] || row[48] ? {
                            thoroughfare: row[44], 
                            premise: row[45], 
                            administrativeArea: row[46], 
                            locality: row[47], 
                            postalCode: row[48]
                        } : null,
                        
                        permissions: this.getPermissions(row[22]),
                        supervisor: null,
            
                        sendOnboarding: false,
                    }

                    if(isNaN(data.payRate)){
                        errors.push({
                            row: i + 6,
                            message: 'Pay Rate is not a number. Please enter in the number format 0.00'
                        });
                    }

                    if(isNaN(data.hourlyWage)){
                        errors.push({
                            row: i + 6,
                            message: 'Hourly Wage is not a number. Please enter in the number format 0.00'
                        });
                    }

                    if(!moment(data.startDate).isValid()){
                        errors.push({
                            row: i + 6,
                            message: 'Start Date is not a date. Please enter in the format YYYY-MM-DD'
                        })
                    }
                    
                    
                    dataArray.push(data);
                }
                if (errors.length === 0) {
                    for (let d = 0; d < dataArray.length; d++) {
                        const dataObj = dataArray[d];
                        const hireResponse = await submitNewHire(dataObj);
                        if (!hireResponse || hireResponse.status !== '200') {
                            errors.push({
                                row: d + 6,
                                message: 'Something went wrong. Please contact Tactical Transportation Advisors using the help button in the bottom right corner.'
                            });
                            this.setState({errors: errors, isLoading: false});
                            return;
                        }
                    }
                    this.setState({isLoading: false, success: rows.length});
                } else {
                    this.setState({errors: errors, isLoading: false});
                }

            }
            reader.readAsText(file);
        }
    }

    async handleDownloadTemplate() {
        const fetchResponse = await fetch(template);
        const blob = await fetchResponse.blob();
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = "OnboardingTemplate";
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }

    render() {


        const errorElements = this.state.errors.map((error, index) => {
            return (
                <div key={index} style={{color: 'red'}}>
                    { error.row &&
                        <>
                            <span style={{fontWeight: 'bold'}}>{'Row ' + error.row}</span>
                            <span>{' -> '}</span>
                        </>
                    }
                    <span>{error.message}</span>
                </div>
            )
        });

        return (
            <>
                <Modal.Header>
                    <Modal.Title>Upload Onboarding CSV</Modal.Title>
                    <CloseButton onClick={this.props.hideModal}/>
                </Modal.Header>
                <Modal.Body>
                    <Button onClick={this.handleDownloadTemplate}>Download Template</Button>
                    <Form.Control type='file' accept=".csv" ref={this.fileRef} onChange={() => {this.setState({errors: [], success: 0})}}/>
                </Modal.Body>
                <Modal.Footer>
                    { !(this.state.errors.length > 0 || this.state.success !== 0) &&
                        <CustomButton label='Submit' isLoading={this.state.isLoading} onClick={this.handleSubmit}/>
                    }
                    <div>
                        {errorElements}
                    </div>
                    { this.state.success !== 0 &&
                        <span style={{color: 'green'}}>{'Successfully created ' + this.state.success + ' new user' + (this.state.success > 1 ? 's!' : '!')}</span>
                    }

                </Modal.Footer>
            </>
        )
    }

}