/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import Big from "big.js";
import { validateBig, validateDecimal, validateInteger } from "../payrollTools";
import Pay from "./Pay";
import AdditionalPay from "./AdditionalPay";
import Deduction from "./Deduction";
import Holiday from "./Holiday";
import Pto from "./Pto";
import Decoder from "../../../decoding";
import PayrollEntryWeek from "./PayrollEntryWeek";
import NDBonus from "./NDBonus";
import DBonus from "./DBonus";
import Loan from "./Loan";
import Ticket from "./Ticket";
import { usdFormatter } from "../../../tools";

export default class PayrollEntry {
    companyUserIdentifier; //string
    firstName; //string
    middleName; //string
    lastName; //string
    fedexName; //String
    pay; //[Pay]
    title; //string
    medical; //string
    dental; //string
    vision; //string
    bwcCode; //string
    childSupport; //[string]
    loans; //[Loan]
    tickets; //[Ticket]
    dBonuses; //[DBonus]
    deductions; //[Deductions]
    csaName; //String
    notes; //string
    reoccuringNotes; //boolean
    ptoBank; //integer
    isNew; //bool

    periodStart; //string
    periodEnd; //string

    status; //string
    settlementReportStatus;
    ptoAccrual;
    ptoAccrualType;
    
    // only used for state handling
    weekIndex = 0;


    constructor(
        companyUserIdentifier, 
        firstName, 
        middleName, 
        lastName, 
        fedexName,
        weeks,
        title, 
        medical, 
        dental, 
        vision, 
        bwcCode, 
        childSupport, 
        loans,
        tickets,
        dBonuses,
        csaName,
        notes, 
        reoccuringNotes, 
        isNew, 
        periodStart, 
        periodEnd, 
        deductions,
        ptoBank,
        status,
        settlementReportStatus,
        ptoAccrual,
        ptoAccrualType
    ) {
        this.companyUserIdentifier = companyUserIdentifier;
        this.firstName = firstName;
        this.middleName = middleName;
        this.lastName = lastName;
        this.fedexName = fedexName;
        this.weeks = weeks;
        this.title = title;
        this.medical = medical;
        this.dental = dental;
        this.vision = vision;
        this.bwcCode = bwcCode;
        this.childSupport = childSupport;
        this.loans = loans;
        this.tickets = tickets;
        this.dBonuses = dBonuses;
        this.csaName = csaName;
        this.notes = notes;
        this.reoccuringNotes = reoccuringNotes;
        this.isNew = isNew;
        this.periodStart = periodStart;
        this.periodEnd = periodEnd;
        this.deductions = deductions;
        this.ptoBank = ptoBank;
        this.status = status;
        this.settlementReportStatus = settlementReportStatus;
        this.ptoAccrual = ptoAccrual;
        this.ptoAccrualType = ptoAccrualType;
    }

    static initDefault() {
        return new PayrollEntry(-1, 'ERR', 'ERR', 'ERR', '', [], 'ERR', 0, 0, 0, '', [], [], [], [], '', '', '', false, '', '', [], 0, '', 'none', 0, 0);
    }

    static cleanString(string){
        let newString = string.replace(/\n/g, " ");
        return newString;
    }

    static decodeArray(jsonStringArray, periodStart, periodEnd, hydrationData) {
        let jsonArray = JSON.parse(this.cleanString(jsonStringArray));
        return jsonArray.map((obj)=> {
            const decodedEntry = PayrollEntry.decode(obj, periodStart, periodEnd);

            if (hydrationData) {
                const employeePto = hydrationData.ptoData.filter(p => p.companyUserIdentifier == obj.companyUserIdentifier);
                const employeePtoBalance = hydrationData.ptoBalanceData[obj.companyUserIdentifier] ?? 0;
                const employeeLoans = hydrationData.loanData.filter(l => l.companyUserIdentifier == obj.companyUserIdentifier);
                const employeeTickets = hydrationData.ticketData.filter(t => t.companyUserIdentifier == obj.companyUserIdentifier);
                decodedEntry.hydrate(employeePto, employeePtoBalance, employeeLoans, employeeTickets);
            }
            return decodedEntry;
        })
    }

    static decode(json, periodStart, periodEnd) {
        const decoder = new Decoder(json);
        const companyUserIdentifier = decoder.decode('companyUserIdentifier', Decoder.UidString);
        const firstName = decoder.decode('firstName', Decoder.StringStrict);
        const middleName = decoder.decode('middleName', Decoder.StringStrict);
        const lastName = decoder.decode('lastName', Decoder.StringStrict);
        const fedexName = decoder.decode('fedexName', Decoder.StringStrict, {defaultValue: '?', warn: false});
        const weeks = PayrollEntryWeek.decodeFromEntry(json, periodStart, periodEnd, json.weeks[0]?.hourlyWage ?? 0);
        const title = decoder.decode('title', Decoder.StringStrict);
        const medical = decoder.decode('medical', Decoder.Decimal);
        const dental = decoder.decode('dental', Decoder.Decimal);
        const vision = decoder.decode('vision', Decoder.Decimal);
        const bwcCode = decoder.decode('bwcCode', Decoder.StringStrict, {defaultValue: '?', warn: false});
        const childSupport = decoder.decode('childSupport', Decoder.DecimalArray, {defaultValue: [], warn: true});
        const loans = decoder.decode('loans', Decoder.Array, {defaultValue: [], warn: false}).map(l => Loan.decode(l));
        const tickets = decoder.decode('tickets', Decoder.Array, {defaultValue: [], warn: false}).map(l => Ticket.decode(l));
        const dBonuses = DBonus.decodeFromEntry(json);
        const deductions = Deduction.decodeFromEntry(json);
        const csaName = decoder.decode('csaName', Decoder.StringStrict);
        const notes = decoder.decode('notes', Decoder.StringStrict, {defaultValue: '', warn: true});
        const reoccuringNotes = decoder.decode('reoccuringNotes', Decoder.Boolean, {defaultValue: false, warn: false});
        const isNew = decoder.decode('isNew', Decoder.Boolean, {defaultValue: false, warn: false});
        const status = decoder.decode('status', Decoder.StringStrict, {defaultValue: '', warn: true});
        const settlementReportStatus = decoder.decode('settlementReportStatus', Decoder.StringStrict, {defaultValue:'none', warn: false});
        const ptoAccrual = decoder.decode('ptoAccrual', Decoder.Decimal, {defaultValue: 0, warn: false});
        const ptoAccrualType = decoder.decode('ptoAccrualType', Decoder.Integer, {defaultValue: 0, warn: false});

        if (decoder.checkForErrors()) {
            return new PayrollEntry(
                companyUserIdentifier,
                firstName,
                middleName,
                lastName,
                fedexName,
                weeks,
                title,
                medical,
                dental,
                vision,
                bwcCode,
                childSupport,
                loans,
                tickets,
                dBonuses,
                csaName,
                notes,
                reoccuringNotes,
                isNew,
                periodStart,
                periodEnd,
                deductions,
                0,
                status,
                settlementReportStatus,
                ptoAccrual,
                ptoAccrualType
            )
        } else {
            return PayrollEntry.initDefault();
        }

    }

    hydrate(ptoData, ptoBalance, loanData, ticketData) {

        for (let i = 0; i < this.weeks.length; i++) {
            const weekStart = moment(this.periodStart).add(i, 'weeks');
            const weekEnd = moment(weekStart).add(6,'days');

            const payData = {
                payType: this.weeks[i].pay[0]?.payType,
                payRate: this.weeks[i].pay[0]?.payRate,
                hourlyWage: this.weeks[i].hourlyWage ?? 0.0
            };

            const ptoArray = ptoData.filter(p => moment(p.date).isBetween(weekStart, weekEnd, 'days', '[]')).map((pto) => {
                const decodedPto = Pto.decodeFromScheduleItem(pto, payData);
                const foundPto = this.weeks[i].ptoArray.find(p => p.uid == pto.uid);
                if (foundPto) {
                    decodedPto.payRate = foundPto.payRate;
                    decodedPto.isEnabled = foundPto.isEnabled;
                }
                return decodedPto;
            });
            this.weeks[i].ptoArray = ptoArray;
        }

        this.ptoBank = ptoBalance;

        const loans = loanData.map((loan) => {
            const decodedLoan = Loan.decode(loan);
            const foundLoan = this.loans.find(l => l.uid == loan.uid);
            if (foundLoan) {
                decodedLoan.isEnabled = foundLoan.isEnabled;
            }
            return decodedLoan;
        })
        this.loans = loans;
        const tickets = ticketData.map((ticket) => {
            const decodedTicket = Ticket.decode(ticket);
            const foundTicket = this.tickets.find(t => t.uid == ticket.uid);
            if (foundTicket) {
                decodedTicket.isEnabled = foundTicket.isEnabled;
            }
            return decodedTicket;
        })
        this.tickets = tickets;
    }

    encode() {
        return {
            companyUserIdentifier: this.companyUserIdentifier,
            firstName: this.firstName,
            middleName: this.middleName,
            lastName: this.lastName,
            fedexName: this.fedexName,
            weeks:this.weeks.map((week)=>week.encode()),
            title: this.title,
            medical: validateDecimal(this.medical),
            dental: validateDecimal(this.dental),
            vision: validateDecimal(this.vision),
            bwcCode: this.bwcCode,
            childSupport: this.childSupport.map(cs => validateDecimal(cs)),
            loans: this.loans.map(l => l.encode()),
            tickets: this.tickets.map(t => t.encode()),
            dBonuses: this.dBonuses.map(r => r.encode()),
            csaName: this.csaName,
            notes: this.notes,
            reoccuringNotes: this.reoccuringNotes,
            deductions: this.deductions.map(d => d.encode()),
            isNew: this.isNew,
            status: this.status,
            settlementReportStatus:this.settlementReportStatus,
            ptoAccrual: this.ptoAccrual,
            ptoAccrualType: this.ptoAccrualType
        }
    }

    static encodeArray(entriesArray) {
        const encodedArray = entriesArray.map(entry => entry.encode());
        return JSON.stringify(encodedArray);
    }

    static generate({user, vehicleWeights, payData, timesheetData, ptoData, loanData, ticketData, previousEntry, isNew, csaName, periodStart, periodEnd, ptoBalance, settlementReportData, autoIncentiveWageAmount, autoIncentiveWageThreshold, customIncentiveWages, autoOvertimeThreshold, autoOvertimeAmount}) {
        //settlementReportStatus
        payData.sort((a,b)=>{
            if (moment(a.date).isBefore(moment(b.date), 'day')) {
                return -1;
            } else if (moment(a.date).isAfter(moment(b.date), 'day')) {
                return 1;
            } else {
                return a.uid - b.uid;
            }
        })

        const coveredDays = [];
        settlementReportData.forEach((report) => {
            if (report.days.find(d => d.companyUserIdentifier == user.companyUserUid)) {
                const numberOfDaysInReport = moment(report.endDate).diff(moment(report.startDate), 'days') + 1;
                for (let i = 0; i < numberOfDaysInReport; i++) {
                    if (!coveredDays.includes(moment(report.startDate).add(i, 'days').format('YYYY-MM-DD'))) {
                        coveredDays.push(moment(report.startDate).add(i, 'days').format('YYYY-MM-DD'));
                    }
                } 
            }
        });
        
        const numberOfDaysInPeriod = moment(periodEnd).diff(moment(periodStart),'days') + 1;
        
        const daysAccounted = [];
        for (let i = 0; i < numberOfDaysInPeriod; i++) {
            let periodDay = moment(periodStart).add(i, 'days').format('YYYY-MM-DD');
            const matchedDay = coveredDays.find(cd => cd == periodDay);
            if (matchedDay) {
                daysAccounted.push(periodDay);
            } 
        }

        const settlementReportStatus = numberOfDaysInPeriod == daysAccounted.length ? 'complete' : daysAccounted.length > 0 ? 'partial' : 'none';

        let settlementDays = [];
        settlementReportData.forEach((report) => {
            settlementDays = settlementDays.concat(report.days);
        })
        
        //add additional pay elements for automatic reimbursements
        const dBonuses = previousEntry?.dBonuses.filter(db => db.isReoccuring) ?? [];
        const deductions = previousEntry?.deductions.filter(d => d.isReoccuring) ?? [];

        const automaticDeductions = payData.length > 0 ? (payData[payData.length - 1].automaticDeductions && payData[payData.length - 1].automaticDeductions != 'Array' ? JSON.parse(payData[payData.length - 1].automaticDeductions) : []) : [];
        automaticDeductions.forEach((deduction) => {
            if (Deduction.deductionTypes.includes(deduction.label)) {
                deductions.push(new Deduction(deduction.label, deduction.label, Math.abs(deduction.amount), false, false));
            } else {
                deductions.push(new Deduction('Other (Flat Rate)', deduction.label, Math.abs(deduction.amount), false, false));
            }
        })

        const ptoAccrual = payData.length > 0 ? validateDecimal(payData[payData.length - 1].ptoAccrual) : 0;
        const ptoAccrualType = payData.length > 0 ? validateInteger(payData[payData.length - 1].ptoAccrualType) : 0;

        const amountOfWeeks = (moment(periodEnd).add(1,'day')).diff(moment(periodStart), 'weeks');

        const weeks = [];
        for (let i = 0; i < amountOfWeeks; i++) {
            let ptoArray = [];
            const pay =[];
            const weekStart = moment(periodStart).add(i, 'weeks');
            const weekEnd = moment(weekStart).add(6,'days');
           
            const under10k = vehicleWeights.filter((v)=>moment(v.date).isBetween(moment(weekStart), moment(weekEnd),'[]')).find(r => parseInt(r.vehicleWeight) < 10000) ? true : false;

            const additionalPay = previousEntry?.weeks[i]?.additionalPay.filter(ap => ap.isReoccuring) ?? [];

            if (i === 0 && payData.length > 0) {
                const automaticReimbursements = payData[payData.length-1].automaticReimbursement && payData[payData.length-1].automaticReimbursement != 'Array' ? JSON.parse(payData[payData.length-1].automaticReimbursement) : [];
                automaticReimbursements.forEach((reimbursement) => {
                    if (AdditionalPay.additionalPayTypes.includes(reimbursement.label)) {
                        additionalPay.push(new AdditionalPay(reimbursement.label, reimbursement.label, reimbursement.amount, false, false));
                    } else {
                        additionalPay.push(new AdditionalPay('Expense Reimbursement', reimbursement.label, reimbursement.amount, false, false));
                    }
                });
            }

            let nDBonuses = previousEntry?.weeks[i]?.ndBonuses.filter((bonus)=>bonus.isReoccuring) ?? [];
            

            const paysBeforeNextWeek = payData.filter((pay)=>moment(pay.date).isSameOrBefore(moment(weekEnd), 'day'));

            const paysDuringWeek = []; //ASC

            for (let i = paysBeforeNextWeek.length -1; i >= 0; i--) {
                paysDuringWeek.unshift(paysBeforeNextWeek[i]);
                if (moment(paysBeforeNextWeek[i].date).isSameOrBefore(moment(weekStart), 'day')) {
                    break;
                }
            }
          
            const timesheetEntriesForWeek = timesheetData.filter((time) => moment(time.inTime).isBetween(moment(weekStart),moment(weekEnd),'day','[]'));
            let daysWorkedInWeek = 0;
            for (let j = 0; j < timesheetEntriesForWeek.length; j++) {
                let doContinue = false;
                for (let k = j + 1; k < timesheetEntriesForWeek.length; k++) {
                    if (moment(timesheetEntriesForWeek[j].inTime).isSame(moment(timesheetEntriesForWeek[k].inTime), 'day')) {
                        doContinue = true;
                        break;
                    }
                }
                if (doContinue) {
                    continue;
                }
                daysWorkedInWeek++;
            }
            
            const hoursWorkedInWeek = parseFloat(timesheetEntriesForWeek.reduce((prev, curr) => {
                return prev + (curr.outTime && curr.outTime !== 'ACO' ? moment(curr.outTime).diff(moment(curr.inTime), 'hours', true) : 0);
            }, 0.0).toFixed(2));


            const thisWeeksSettlementData = settlementDays.filter((s)=>moment(s.date).isBetween(moment(weekStart),moment(weekEnd),'days','[]'));

            const stops = [0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
                return thisWeeksSettlementData.filter(s => moment(s.date).format('d') == (dayIndex == 0 ? 6 : dayIndex - 1)).reduce((prev, curr) => {
                    return prev + curr.stops;
                }, 0);
            });

            paysDuringWeek.forEach((p, index)=>{
                const startDate = moment(p.date).isBefore(moment(weekStart)) ? weekStart.format('YYYY-MM-DD') : p.date;
                const endDate = index < paysDuringWeek.length - 1 ? moment(paysDuringWeek[index + 1].date).subtract(1, 'day').format('YYYY-MM-DD') : weekEnd.format('YYYY-MM-DD'); 
                let pto = ptoData.filter((pto)=>moment(pto.date).isBetween(moment(startDate), moment(endDate), 'day', '[]')).map(si => Pto.decodeFromScheduleItem(si, p));
                ptoArray = ptoArray.concat(pto);
                const settlementDataForPay = thisWeeksSettlementData.filter((s)=>moment(s.date).isBetween(moment(startDate),moment(endDate),'day','[]'));

                const timesheetEntriesForPay = timesheetData.filter((time) => moment(time.inTime).isBetween(moment(startDate),moment(endDate),'day','[]'));
                let daysWorkedInPay = 0;
                for (let j = 0; j < timesheetEntriesForPay.length; j++) {
                    let doContinue = false;
                    for (let k = j + 1; k < timesheetEntriesForPay.length; k++) {
                        if (moment(timesheetEntriesForPay[j].inTime).isSame(moment(timesheetEntriesForPay[k].inTime), 'day')) {
                            doContinue = true;
                            break;
                        }
                    }
                    if (doContinue) {
                        continue;
                    }
                    daysWorkedInPay++;
                }
            
                const hoursWorkedInPay = parseFloat(timesheetEntriesForPay.reduce((prev, curr) => {
                    return prev + (curr.outTime && curr.outTime !== 'ACO' ? moment(curr.outTime).diff(moment(curr.inTime), 'hours', true) : 0);
                }, 0.0).toFixed(3));

               
                const totalStops = settlementDataForPay.reduce((prev,curr)=> prev + curr.stops, 0);

                
                let unitsWorkedInPay = 0;
                if(p.payType === 'ph'){
                    unitsWorkedInPay = hoursWorkedInPay;
                } else if(p.payType === 'pd'){
                    unitsWorkedInPay= daysWorkedInPay;
                } else if(p.payType === 'ps'){
                    unitsWorkedInPay = totalStops;
                }

    
                const originalPay = new Pay(p.payType, p.payRate, unitsWorkedInPay, startDate, endDate);
                pay.push(originalPay);
                
            });

            let incentivePayAmount = 0;
            if (autoIncentiveWageThreshold != null) {
                const daysOverThreshold = daysWorkedInWeek - autoIncentiveWageThreshold;
                if (daysOverThreshold > 0) {
                    incentivePayAmount += daysOverThreshold * validateDecimal(autoIncentiveWageAmount);
                }
            }
            customIncentiveWages.forEach((ciw) => {
                const timesheetEntryOnDay = timesheetEntriesForWeek.find(t => moment(t.inTime).day() == (ciw.dayIndex == 0 ? 6 : ciw.dayIndex - 1));
                if (timesheetEntryOnDay) {
                    incentivePayAmount += validateDecimal(ciw.amount);
                }
            })
            if (incentivePayAmount > 0) {
                pay.push(new Pay('iw', incentivePayAmount, 0, weekStart, weekEnd));
            }

            if (autoOvertimeThreshold != null) {
                const daysOverThreshold = daysWorkedInWeek - autoOvertimeThreshold;
                if (daysOverThreshold > 0) {
                    const hourlyAndDailyWagesForAutoOvertime = pay.reduce((prev, curr) => {
                        if (curr.payType == 'ph' || curr.payType == 'pd') {
                            return prev.plus(curr.getWages());
                        } else {
                            return prev;
                        }
                    }, new Big(0));

                    const autoOvertimeDollarAmount = validateDecimal(hourlyAndDailyWagesForAutoOvertime.times(validateBig(autoOvertimeAmount)).toFixed(2));
                    if (autoOvertimeDollarAmount > 0) {
                        pay.push(new Pay('ot', autoOvertimeDollarAmount, 0, weekStart, weekEnd));
                    }
                }
            }

            const otStopWageDefinitions = [{}, {}, {}, {}, {}, {}, {}];

            if (paysDuringWeek[0]?.stopBonusWeekdayThreshold > 0 && paysDuringWeek[0].stopBonusAmount > 0) {
                otStopWageDefinitions[2] = {threshold: paysDuringWeek[0].stopBonusWeekdayThreshold, amount: paysDuringWeek[0].stopBonusAmount};
                otStopWageDefinitions[3] = {threshold: paysDuringWeek[0].stopBonusWeekdayThreshold, amount: paysDuringWeek[0].stopBonusAmount};
                otStopWageDefinitions[4] = {threshold: paysDuringWeek[0].stopBonusWeekdayThreshold, amount: paysDuringWeek[0].stopBonusAmount};
                otStopWageDefinitions[5] = {threshold: paysDuringWeek[0].stopBonusWeekdayThreshold, amount: paysDuringWeek[0].stopBonusAmount};
                otStopWageDefinitions[6] = {threshold: paysDuringWeek[0].stopBonusWeekdayThreshold, amount: paysDuringWeek[0].stopBonusAmount};
            }
            if (paysDuringWeek[0]?.stopBonusWeekendThreshold > 0 && paysDuringWeek[0].stopBonusWeekendAmount > 0) {
                otStopWageDefinitions[0] = {threshold: paysDuringWeek[0].stopBonusWeekendThreshold, amount: paysDuringWeek[0].stopBonusWeekendAmount};
                otStopWageDefinitions[1] = {threshold: paysDuringWeek[0].stopBonusWeekendThreshold, amount: paysDuringWeek[0].stopBonusWeekendAmount};
            }

            
            weeks.push(new PayrollEntryWeek(
                daysWorkedInWeek,
                hoursWorkedInWeek,
                pay,
                nDBonuses,
                under10k,
                paysDuringWeek[paysDuringWeek.length-1]?.hourlyWage ?? 0, 
                additionalPay, 
                ptoArray, 
                [],
                otStopWageDefinitions,
                stops
            ));
           
        }
        
        return new PayrollEntry(
            user.companyUserUid,
            user.firstName,
            user.middleName[0] ?? '',
            user.lastName,
            user.fedexName,
            weeks,
            payData[payData.length - 1]?.title ?? '',
            payData[0]?.medical ?? 0,
            payData[0]?.dental ?? 0,
            payData[0]?.vision ?? 0,
            payData[payData.length - 1]?.bwcCode ?? '',
            payData[0]?.childSupport && payData[0]?.childSupport !== 'Array' ? JSON.parse(payData[0]?.childSupport) : [],
            loanData.map(l => Loan.decode(l)),
            ticketData.map(t => Ticket.decode(t)),
            dBonuses,
            csaName,
            previousEntry !== undefined && previousEntry.reoccuringNotes ? previousEntry.notes : '',
            previousEntry !== undefined && previousEntry.reoccuringNotes,
            isNew,
            periodStart,
            periodEnd,
            deductions,
            ptoBalance,
            '',
            settlementReportStatus,
            ptoAccrual,
            ptoAccrualType
        )
    }

    recalculateStopWages = (settlementReportData, payData) => {

        let userPayData = payData.filter((p)=>p.companyUserIdentifier == this.companyUserIdentifier);
        userPayData.sort((a,b)=>{
            if (moment(a.date).isBefore(moment(b.date), 'day')) {
                return -1;
            } else if (moment(a.date).isAfter(moment(b.date), 'day')) {
                return 1;
            } else {
                return a.uid - b.uid;
            }
        });

        const amountOfWeeks = (moment(this.periodEnd).add(1,'day')).diff(moment(this.periodStart), 'weeks');

        const weeks = [];
        for (let i = 0; i < amountOfWeeks; i++) {
            
            const pay = this.weeks[i].pay.filter((p)=>p.payType != 'ps');
            const weekStart = moment(this.periodStart).add(i, 'weeks');
            const weekEnd = moment(weekStart).add(6,'days');                

            const paysBeforeNextWeek = userPayData.filter((pay)=>moment(pay.date).isSameOrBefore(moment(weekEnd), 'day'));

            const paysDuringWeek = []

            for (let i = paysBeforeNextWeek.length -1; i >= 0; i--) {
                paysDuringWeek.unshift(paysBeforeNextWeek[i]);
                if (moment(paysBeforeNextWeek[i].date).isSameOrBefore(moment(weekStart), 'day')) {
                    break;
                }
            }
            
            let usersSettlementReportDays = [];

            settlementReportData.forEach((report)=>{
                usersSettlementReportDays = usersSettlementReportDays.concat(report.days.filter((d)=>d.companyUserIdentifier == this.companyUserIdentifier));
            });
            

            const thisWeeksSettlementData = usersSettlementReportDays.filter((s)=>moment(s.date).isBetween(moment(weekStart),moment(weekEnd),'days','[]') && s.companyUserIdentifier == this.companyUserIdentifier);

            const stops = [0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
                return thisWeeksSettlementData.filter(s => moment(s.date).format('d') == (dayIndex == 0 ? 6 : dayIndex - 1)).reduce((prev, curr) => {
                    return prev + curr.stops;
                }, 0);
            });


            const paysPerStopDuringWeek =  paysDuringWeek.filter((p)=>p.payType == 'ps');
            paysPerStopDuringWeek.forEach((p, index)=>{
                const startDate = moment(p.date).isBefore(moment(weekStart),'days') ? weekStart.format('YYYY-MM-DD') : p.date;
                const endDate = index < paysPerStopDuringWeek.length - 1 ? moment(paysPerStopDuringWeek[index + 1].date).subtract(1, 'day').format('YYYY-MM-DD') : weekEnd.format('YYYY-MM-DD'); 
                
                const settlementDataForPay = thisWeeksSettlementData.filter((s)=>moment(s.date).isBetween(moment(startDate),moment(endDate),'day','[]'));

                const totalStops = settlementDataForPay.reduce((prev,curr)=> prev + curr.stops, 0);

                const originalPay = new Pay(p.payType, p.payRate, totalStops, startDate, endDate);
                pay.push(originalPay);
                
            });
        
            weeks.push(new PayrollEntryWeek(
                this.weeks[i].daysWorked,
                this.weeks[i].hoursWorked,
                pay,
                this.weeks[i].ndBonuses,
                this.weeks[i].under10k,
                this.weeks[i].hourlyWage,
                this.weeks[i].additionalPay, 
                this.weeks[i].ptoArray, 
                this.weeks[i].holidayArray,
                this.weeks[i].otStopWageDefinitions,
                stops
            ));
            
        }
        this.weeks = weeks;
        this.settlementReportStatus = this.getEmployeeAvailableDataStatus(settlementReportData);
        return this;
    }

    getEmployeeAvailableDataStatus(settlementReportData) {
        const coveredDays = [];
        settlementReportData.forEach((report) => {
            if (report.days.find(d => d.companyUserIdentifier == this.companyUserIdentifier)) {
                const numberOfDaysInReport = moment(report.endDate).diff(moment(report.startDate), 'days') + 1;
                for (let i = 0; i < numberOfDaysInReport; i++) {
                    if (!coveredDays.includes(moment(report.startDate).add(i, 'days').format('YYYY-MM-DD'))) {
                        coveredDays.push(moment(report.startDate).add(i, 'days').format('YYYY-MM-DD'));
                    }
                } 
            }
        });
        
        const numberOfDaysInPeriod = moment(this.periodEnd).diff(moment(this.periodStart),'days') + 1;
        
        const daysAccounted = [];
        for (let i = 0; i < numberOfDaysInPeriod; i++) {
            let periodDay = moment(this.periodStart).add(i, 'days').format('YYYY-MM-DD');
            const matchedDay = coveredDays.find(cd => cd == periodDay);
            if (matchedDay) {
                daysAccounted.push(periodDay);
            } 
        }

        const availableStatus = numberOfDaysInPeriod == daysAccounted.length ? 'complete' : daysAccounted.length > 0 ? 'partial' : 'none';
        return availableStatus;
    }

    clearPay() {

        for(let i = 0; i < this.weeks.length; i++){
            this.weeks[i] = PayrollEntryWeek.initDefault();
        }
      
        this.medical = 0;
        this.vision = 0;
        this.dental = 0;
        this.childSupport = [];
        this.dBonuses = [];
        this.deductions = [];
    }
    
    set(key, value) {
        this[key] = value;
        return this.duplicate()
    }

    name() {
        return this.lastName + ', ' + this.firstName + ' ' + this.middleName;
    }


    payPeriodLength() {
        return moment(this.periodEnd).diff(moment(this.periodStart), 'days') + 1;
    }
    
    pto() {
        return this.weeks.reduce((prev, curr) => { return prev + curr.pto() }, 0);
    }

    getPayPeriodsPerYear() {
        return 52 / (this.payPeriodLength() / 7);
    }

    qualifiesForPtoAndHolidays() {
        return !this.weeks.find((week)=>week.pay.find((pay)=>pay.payType === 'py'));
    }

    totalPtoWages() {
        if(this.qualifiesForPtoAndHolidays()){
            const ptoWages = this.weeks.reduce((prev, curr)=>{
                return prev.plus(curr.totalPtoWages());
            }, new Big('0.00'));
            return ptoWages;
        } else {
            return new Big('0.00');
        }
    }
    
    totalHolidayWages() {
        if(this.qualifiesForPtoAndHolidays()) {
            const holidayWages = this.weeks.reduce((prev, curr)=>{
                return prev.plus(curr.totalHolidayWages());
            }, new Big('0.00'));
            return holidayWages;
        } else {
            return new Big('0.0');
        }
    }
    
    totalChildSupport() {
        return this.childSupport.reduce((prev, curr) => {return prev + validateDecimal(curr)}, 0.0);
    }

    getAllNdBonusesOfType(type) {
        return this.weeks.reduce((prev, curr) => {
            return prev.concat(curr.ndBonuses.filter(nd => nd.type === type));
        }, []);
    }

    totalNdBonusesByType() {
        const totals = {};
        NDBonus.ndBonusTypes.forEach(type => totals[type] = 0.0);

        this.weeks.forEach((week) => {
            const weekTotals = week.totalNdBonusesByType();
            Object.entries(weekTotals).forEach(([key, value]) => {
                totals[key] += value;
            })
        })

        return totals;
    }

    totalDeductions() {
        const additionalDeductions = this.allDeductionsFlatRate();
        const loans = this.getEnabledLoans().reduce((prev, curr) => {
            return prev.plus(validateBig(curr.getAmount()));
        }, new Big('0.0'));
        const tickets = this.getEnabledTickets().reduce((prev, curr) => {
            return prev.plus(validateBig(curr.getAmountToPay(this)));
        }, new Big('0.0'));

        return validateBig(this.medical)
            .plus(validateBig(this.dental))
            .plus(validateBig(this.vision))
            .plus(validateBig(this.totalChildSupport()))
            .plus(additionalDeductions)
            .plus(loans)
            .plus(tickets)
        ;
    }

    totalDeductionsByType() {
        const totals = {};
        Deduction.deductionTypes.filter(t => t !== '401K (% of Gross)').forEach((type) => {
            totals[type] = this.deductions.filter(d => d.type === type).reduce((prev, curr) => {
                return prev + curr.getAmount();
            }, 0.0);
        });
        return totals;
    }

    totalDBonuses() {
        return this.dBonuses.reduce((prev, curr) => {
            return prev.plus(validateBig(curr.getAmount()));
        }, new Big('0.0'));
    }
    
    allDeductionsFlatRate() {
        return this.deductions.filter(d => !d.isPercentage()).reduce((prev, curr) => {
            return prev.plus(validateBig(curr.getAmount()));
        }, new Big('0.0'));
    }
    allDeductionsPercentage(){
        return this.deductions.filter(d => d.isPercentage()).reduce((prev, curr) => {
            return prev.plus(validateBig(curr.getAmount()));
        }, new Big('0.0'));
    }

    totalNdBonusOtStopWages() {
        return this.weeks.reduce((prev, curr) => {
            return prev.plus(curr.totalNdBonusOtStopWages());
        }, new Big('0.0'));
    }

    totalOTStopWages() {
        return this.weeks.reduce((prev, curr) => {
            return prev.plus(curr.totalOTStopWages());
        }, new Big('0.0'));
    }

    totalStopAndOTStopWages() { // for payroll spreadsheet only
        return this.weeks.reduce((prev, curr) => {
            return prev.plus(curr.totalStopAndOTStopWages());
        }, new Big('0.0'));
    }

    totalIncentiveWages() {
        return this.weeks.reduce((prev, curr) => {
            return prev.plus(curr.totalIncentiveWages());
        }, new Big('0.0'));
    }
    
    totalStandByWages() {
        return this.weeks.reduce((prev, curr) => {
            return prev.plus(curr.totalStandByWages());
        }, new Big('0.0'));
    }

    totalAutoOvertimeWages() {
        return this.weeks.reduce((prev, curr) => {
            return prev.plus(curr.totalAutoOvertimeWages());
        }, new Big('0.0'));
    }

    getEnabledLoans() {
        return this.loans.filter(l => l.isEnabled);
    }

    getEnabledTickets() {
        return this.tickets.filter(t => t.isEnabled);
    }

    // totalLoansToPay() {
    //     return this.getEnabledLoans().reduce((prev, curr) => prev.plus(new Big(curr.getAmount())), new Big('0.00'));
    // }

    // totalTicketsToPay() {
    //     return this.getEnabledTickets().filter(t => !t.deductFromSafetyBonuses).reduce((prev, curr) => prev.plus(new Big(curr.getAmount())), new Big('0.00'));
    // }
    
    gross() {
        const totalSubGross = this.weeks.reduce((prev, curr) => prev.plus(curr.getSubGross()), new Big('0.00'));
           
        return totalSubGross
            .plus(this.totalPtoWages())
            .plus(this.totalHolidayWages())
            .plus(this.totalDBonuses())
            // .sub(this.totalLoansToPay())
            // .sub(this.totalTicketsToPay())
        ;
        
    }

    getColumnInclusion() {

        const weeklyColumnInclusion = this.weeks.map(w => w.getColumnInclusion());
        const includeOvertimeColumns = weeklyColumnInclusion.find(ci => ci.includeOvertimeColumns) !== undefined;
        const includeSalary = !includeOvertimeColumns && weeklyColumnInclusion.find(ci => ci.includeSalary);
        const includeDaily = !includeOvertimeColumns && weeklyColumnInclusion.find(ci => ci.includeDaily);

        return {
            includeSalary: includeSalary,
            includeDaily: includeDaily,
            includeOvertimeColumns: includeOvertimeColumns,
            includeHourly: includeOvertimeColumns || weeklyColumnInclusion.find(ci => ci.includeHourly),
            includeStop: weeklyColumnInclusion.find(ci => ci.includeStop) !== undefined,
            includeOTStopWages: weeklyColumnInclusion.find(ci => ci.includeOTStopWages) !== undefined,
            includeMile: weeklyColumnInclusion.find(ci => ci.includeMile) !== undefined,
            includeExtraDay: weeklyColumnInclusion.find(ci => ci.includeExtraDay) !== undefined,
            includeIncentiveWages: weeklyColumnInclusion.find(ci => ci.includeIncentiveWages) !== undefined,
            includeStandByWages: weeklyColumnInclusion.find(ci => ci.includeStandByWages) !== undefined,
            includeAutoOvertime: weeklyColumnInclusion.find(ci => ci.includeAutoOvertime) !== undefined,
            includePto: weeklyColumnInclusion.find(ci => ci.includePto) !== undefined,
            includeRegularWages: includeSalary || includeDaily,
            includeHolidayWages:  weeklyColumnInclusion.find(ci => ci.includeHolidayWages) !== undefined,
            inlcudeChildSupport: this.childSupport.find(cs => parseFloat(cs) !== 0.0),
            includeMedical: validateDecimal(this.medical) !== 0.0,
            includeDental: validateDecimal(this.dental) !== 0.0,
            includeVision: validateDecimal(this.vision) !== 0.0,
            includeHolidayBonus: this.dBonuses.find(dBonus => dBonus.type === 'Holiday Bonus') !== undefined,
            includeOtherDiscretionaryBonus: this.dBonuses.find(dBonus => dBonus.type === 'Other Discretionary Bonus') !== undefined,
            includeLoans: this.loans.length > 0,
            includeTickets: this.tickets.find(t => t.getAmountToPay(this) > 0) !== undefined,
        }
    }

    getNDBonusColumnInclusion() {
        const inclusion = new Set();
        this.weeks.forEach((week) => {
            const weekInclusion = week.getNDBonusColumnInclusion();
            weekInclusion.forEach((type) => {
                inclusion.add(type);
            })
        })
        return inclusion;
    }

    getDeductionColumnInclusion() {
        const inclusion = new Set();
        Deduction.deductionTypes.forEach((type) => {
            if (this.deductions.find(d => d.type === type)) {
                inclusion.add(type);
            }
        });
        return inclusion;
    }

    getReducedWeeks() {
        const payArray = [];
        this.weeks.forEach((week) => {
            week.pay.forEach((pay) => {
                if (payArray.length === 0) {
                    payArray.push(pay.duplicate());
                } else {
                    const match = payArray.find(p => p.payType === pay.payType && p.payRate === pay.payRate);
                    if (match) {
                        match.unitsWorked = validateDecimal(match.unitsWorked) + validateDecimal(pay.unitsWorked);
                    } else {
                        payArray.push(pay.duplicate());
                    }
                }
            })
        });

        const daysWorked = this.weeks.reduce((prev, curr) => {
            return prev + validateInteger(curr.daysWorked);
        }, 0);
        const hoursWorked = this.weeks.reduce((prev, curr) => {
            return prev + validateDecimal(curr.hoursWorked);
        }, 0.0);

        return new PayrollEntryWeek(daysWorked, hoursWorked, payArray, [], false, 0.0, [null, null, null, null, null, null, null]);

    }

    getAllAdditionalPay() {
        let array = [];
        this.weeks.forEach((week) => {
            array = array.concat(week.additionalPay);
        })
        return array;
    }

    getNotesWithLoansAndTickets() {
        let str = '';
        if (this.getEnabledLoans().length > 0) {
            str += 'Loans: ';
            this.getEnabledLoans().forEach((l, index) => {
                if (index > 0) {
                    str += ', ';
                }
                str += `${l.name} - ${usdFormatter.format(l.getAmount())}`
            });
            str += '\n';
        }
        if (this.getEnabledTickets().length > 0) {
            str += 'Fine Ticket Damage: ';
            this.getEnabledTickets().forEach((t, index) => {
                if (index > 0) {
                    str += ', ';
                }
                str += `${t.name} - ${usdFormatter.format(t.getAmountToPay(this))} ${t.deductFromSafetyBonuses ? `(deducted from safety bonuses)` : ''}`
            });
            str += '\n';
        }

        return str + this.notes;
    }

    getPtoHoursAccrued() {
        if (this.ptoAccrual > 0) {
            if (this.ptoAccrualType == 0) {
                const hoursWorked = this.weeks.reduce((prev, curr) => {
                    if (curr.pay.find(p => p.payType === 'ph' || p.payType === 'pd')) { //use pay data to determine hours worked for the week
                        return prev + curr.pay.reduce((prevPay, currPay) => {
                            if (currPay.payType === 'ph') {
                                return prevPay + validateDecimal(currPay.unitsWorked);
                            } else if (currPay.payType === 'pd') {
                                return prevPay + (validateDecimal(currPay.unitsWorked) * 8.0);
                            } else {
                                return prevPay;
                            }
                        }, 0);
                    } else { //use hours worked on week
                        return prev + curr.hoursWorked;
                    }
                }, 0.0);
                return (hoursWorked / 40.0) * this.ptoAccrual;
            } else {
                return this.ptoAccrual;
            }
        } else {
            return 0;
        }
    }

}









