/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState, useRef } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';




class KeyValueRow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            displayAsRow: true
        }

        this.ref = React.createRef();

        this.handleWindowResize = this.handleWindowResize.bind(this);
    }

    handleWindowResize() {
        const widthForRow = this.props.widthForRow ? this.props.widthForRow : 400;

        if (this.ref.current.clientWidth >= widthForRow !== this.state.displayAsRow) {
            this.setState({displayAsRow: this.ref.current.clientWidth >= widthForRow});
        }
    }

    componentDidMount() {
        this.handleWindowResize();
        window.addEventListener('resize', this.handleWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize)
    }

    render() {
    
        if (this.props.isListItem) {
            return (
                <ListGroup.Item ref={this.ref} style={{display: 'flex', flexDirection: (this.state.displayAsRow || this.props.forceRow) ? 'row' : 'column', justifyContent: 'space-between'}}>
                    <p style={this.props.titleStyle ? {marginBottom: 0, marginRight: 12, ...this.props.titleStyle} : {marginBottom: 0, marginRight: 12}}>{this.props.title}</p>
                    <p style={this.props.valueStyle ? {marginBottom: 0, opacity: 0.5, ...this.props.valueStyle} : {marginBottom: 0, opacity: 0.5}}>{this.props.value}{this.props.secondValue}</p>
                </ListGroup.Item>
            )
        } else {
            return (
                <div ref={this.ref} style={{display: 'flex', flexDirection: (this.state.displayAsRow || this.props.forceRow) ? 'row' : 'column', justifyContent: 'space-between'}}>
                    <p style={{marginBottom: 0, marginRight: 12}}>{this.props.title}</p>
                    <p style={{marginBottom: 0, opacity: 0.5}}>{this.props.value}</p>
                </div>
            )
        }
    }

}

export default KeyValueRow;


