/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import Form from 'react-bootstrap/Form';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomControl from '../../components/CustomControl';
import CustomSpinner from '../../components/CustomSpinner';
import AddressControl from '../../components/AddressControl';
import { changeHRUserPassword, getEmployeeProfile, getHRUserProfile, updateHRUserProfile } from '../../services/HRServices/HRProfileService';
import AvailabilityControl from '../../components/AvailabilityControl';
import { parseAvailability, packageAddress, encodeAvailability, addressToString, formatPhoneNumber, formatSSN } from '../../tools';
import PasswordProtectedButton from '../../components/PasswordProtectedButton';
import KeyValueRow from '../../components/KeyValueRow';
import { ListGroup } from 'react-bootstrap';
import moment from 'moment';
import SwitchControl from '../../components/SwitchControl';
import Modal from 'react-bootstrap/Modal';
import RadioControl from '../../components/RadioControl';
import { AddressValidator, ValidationGroup, Validator, validateSsn, validateExistence, validateEmail, validatePhoneNumber } from '../../validation';
import CustomButton from '../../components/CustomButton';

class HRUserProfile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isSubmitting: false,
            csas: [],
            user: {},
            originalUser: {},
            newPassword: '',
            sendEmployeePassword: true,
        }
        this.handleSaveChanges = this.handleSaveChanges.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
    }


    async loadData() {
        this.setState({isLoading: true});
        const response = await getEmployeeProfile(this.props.companyUserUid);
        if (response.status === '200') {
            response.profile.availability = parseAvailability(response.profile.availability);
            this.setState({csas: response.csas, user: response.profile, originalUser: response.profile});
        }
        this.setState({isLoading: false});
    }

    componentDidMount() {
        this.loadData();
    }

    handleSetUser(key, value) {
        const newUser = structuredClone(this.state.user);
        newUser[key] = value;
        this.setState({user: newUser});
    }

    async handleSaveChanges() {
        this.setState({isSubmitting: true});
        const userToSend = structuredClone(this.state.user);
        userToSend.availability = encodeAvailability(userToSend.availability);    
        const response = await updateHRUserProfile(userToSend);
        if (response.status === '200') {
            this.setState({originalUser: structuredClone(this.state.user)});
        }
        this.setState({isSubmitting: false});
    }

    handleChangePassword() {
        changeHRUserPassword(this.state.user.companyUserUid, this.state.newPassword, this.state.sendEmployeePassword).then((response) => {
            if (response && response.status === '200') {
                this.setState({showModal: false});
            }
        });
    }

    render() {
        
        const validationGroup = new ValidationGroup();
        const ssnValidator = this.state.user.ssn ? new Validator(() => validateSsn(this.state.user.ssn)) : '';
        const addressValidator = this.state.user.address ? new AddressValidator(this.state.user.address) : '';
        const content = this.state.isLoading ? '' : (
            <Container fluid>
                <Row>
                    <CustomControl lg={4} xl={3} xxl={3} readOnly={true} type='number' title='userIdentifier' originalValue={this.state.originalUser.userIdentifier ? this.state.originalUser.userIdentifier : ''} value={this.state.user.userIdentifier} onChange={(event) => {this.handleSetUser('userIdentifier', event.target.value)}}/>
                    <CustomControl lg={4} xl={3} xxl={3} readOnly={true} type='number' title='companyUserUid' originalValue={this.state.originalUser.companyUserUid ? this.state.originalUser.companyUserUid : ''} value={this.state.user.companyUserUid} onChange={(event) => {this.handleSetUser('companyUserUid', event.target.value)}}/>
                    <CustomControl lg={4} xl={3} xxl={3} validator={validationGroup.createValidator(() => validateExistence(this.state.user.firstName))} type='text' title='firstName' originalValue={this.state.originalUser.firstName ? this.state.originalUser.firstName : ''} value={this.state.user.firstName} onChange={(event) => {this.handleSetUser('firstName', event.target.value)}}/>
                    <CustomControl lg={4} xl={3} xxl={3} validator={validationGroup.createValidator(() => validateExistence(this.state.user.middleName))} type='text' title='middleName' originalValue={this.state.originalUser.middleName ? this.state.originalUser.middleName : ''} value={this.state.user.middleName} onChange={(event) => {this.handleSetUser('middleName', event.target.value)}}/>
                    <CustomControl lg={4} xl={3} xxl={3} validator={validationGroup.createValidator(() => validateExistence(this.state.user.lastName))} type='text' title='lastName' originalValue={this.state.originalUser.lastName ? this.state.originalUser.lastName : ''} value={this.state.user.lastName} onChange={(event) => {this.handleSetUser('lastName', event.target.value)}}/>
                    <CustomControl lg={4} xl={3} xxl={3} validator={validationGroup.createValidator(() => validateEmail(this.state.user.email))} type='text' title='email' originalValue={this.state.originalUser.email ? this.state.originalUser.email : ''} value={this.state.user.email} onChange={(event) => {this.handleSetUser('email', event.target.value)}}/>
                    <CustomControl lg={4} xl={3} xxl={3} validator={validationGroup.createValidator(() => validatePhoneNumber(this.state.user.phoneNumber))} type='text' title='phoneNumber' originalValue={this.state.originalUser.phoneNumber ? this.state.originalUser.phoneNumber : ''} value={this.state.user.phoneNumber} onChange={(event) => {this.handleSetUser('phoneNumber', event.target.value)}}/>
                    <CustomControl lg={4} xl={3} xxl={3} validator={ssnValidator} type='text' title='Social Security Number' originalValue={this.state.originalUser.ssn ? this.state.originalUser.ssn : ''} value={this.state.user.ssn} onChange={(event) => {this.handleSetUser('ssn', event.target.value)}}/>
                    <CustomControl lg={4} xl={3} validator={validationGroup.createValidator(() => validateExistence(this.state.user.dateOfBirth))} type='date' title='dateOfBirth' originalValue={this.state.originalUser.dateOfBirth ? this.state.originalUser.dateOfBirth : ''} value={this.state.user.dateOfBirth} onChange={(event) => {this.handleSetUser('dateOfBirth', event.target.value)}}/>
                    <CustomControl lg={4} xl={3} type='date' title='Med-Card Expiration Date' originalValue={this.state.originalUser.medcoExpiration} value={this.state.user.medcoExpiration} onChange={(event) => {this.handleSetUser('medcoExpiration', event.target.value)}}/>
                    
                    <CustomControl lg={4} xl={3} xxl={2} validator={validationGroup.createValidator(() => validateExistence(this.state.user.fedexName))} type='text' title='fedexName' originalValue={this.state.originalUser.fedexName ? this.state.originalUser.fedexName : ''} value={this.state.user.fedexName ? this.state.user.fedexName : ''} onChange={(event) => {this.handleSetUser('fedexName', event.target.value)}}/>
                    <CustomControl lg={4} xl={3} xxl={2} type='date' title='Start Date' originalValue={this.state.originalUser.startDate} value={this.state.user.startDate} onChange={(event) => {this.handleSetUser('startDate', event.target.value)}}/>
                    <CustomControl lg={4} xl={3} xxl={2} type='time' title='Daily Start Time' originalValue={this.state.originalUser.dailyStartTime} value={this.state.user.dailyStartTime} onChange={(event) => {this.handleSetUser('dailyStartTime', event.target.value)}}/>
                    <SwitchControl lg={4} xl={3} xxl={2} title='MFA Enabled' originalValue={this.state.originalUser.mfaEnabled} value={this.state.user.mfaEnabled} setValue={(value) => {this.handleSetUser('mfaEnabled', value)}}/>
                    <SwitchControl lg={4} xl={3} title='On Leave of Absence' originalValue={this.state.originalUser.onLoa} value={this.state.user.onLoa} setValue={(value) => {this.handleSetUser('onLoa', value)}}/>
                    
                    <SwitchControl lg={4} xl={3} value={this.state.user.isEnabled} setValue={(value) => {this.handleSetUser('isEnabled', value)}} originalValue={this.state.originalUser.isEnabled ? this.state.originalUser.isEnabled : ''} title='Is Enabled as User'/>
                    <SwitchControl lg={8} xl={6} xxl={4} value={this.state.user.companyUserIsEnabled} setValue={(value) => {this.handleSetUser('companyUserIsEnabled', value)}} originalValue={this.state.originalUser.companyUserIsEnabled ? this.state.originalUser.companyUserIsEnabled : ''} title='Is Enabled as Company Employee'/>
                    {/* <SwitchControl lg={4} xl={3} xxl={2} value={this.state.user.isAdministrator} setValue={(value) => {this.handleSetUser('isAdministrator', value)}} originalValue={this.state.originalUser.isAdministrator ? this.state.originalUser.isAdministrator : ''} title='Is Administrator'/> */}
                    <AvailabilityControl xxl={6} title='Availability' originalValue={this.state.originalUser.availability ? this.state.originalUser.availability : ''} inline value={this.state.user.availability} setValue={(value) => {this.handleSetUser('availability', value)}}/>
                    <RadioControl xxl={6} originalValue={this.state.originalUser.csaIdentifier ? this.state.originalUser.csaIdentifier : ''} title='CSA' inline selection={this.state.user.csaIdentifier} setSelection={(value) => {this.handleSetUser('csaIdentifier', value)}} optionNames={this.state.csas.map(c => c.csaName)} optionValues={this.state.csas.map(c => c.uid)}/>
                    
                    <AddressControl lg={4} xxl={2} containerBreakPoints={{xxl: 12}} validator={addressValidator} title='address' originalValue={this.state.originalUser.address ? this.state.originalUser.address : {}} address={this.state.user.address} setAddress={(value) => {this.handleSetUser('address', value)}}/>
                    <CustomControl lg={4} xl={3} xxl={2} type='text' title='eContact1FirstName' originalValue={this.state.originalUser.eContact1FirstName ? this.state.originalUser.eContact1FirstName : ''} value={this.state.user.eContact1FirstName ? this.state.user.eContact1FirstName : ''} onChange={(event) => {this.handleSetUser('eContact1FirstName', event.target.value)}}/>
                    <CustomControl lg={4} xl={3} xxl={2} type='text' title='eContact1LastName' originalValue={this.state.originalUser.eContact1LastName ? this.eContact1LastName : ''} value={this.state.user.eContact1LastName ? this.state.eContact1LastName : ''} onChange={(event) => {this.handleSetUser('eContact1LastName', event.target.value)}}/>
                    <CustomControl lg={4} xl={3} xxl={2} type='text' title='eContact1PhoneNumber' originalValue={this.state.originalUser.eContact1PhoneNumber ? this.state.originalUser.eContact1PhoneNumber : ''} value={this.state.user.eContact1PhoneNumber ? this.state.user.eContact1PhoneNumber : ''} onChange={(event) => {this.handleSetUser('eContact1PhoneNumber', event.target.value)}}/>
                    <CustomControl lg={4} xl={3} xxl={2} type='text' title='eContact1Email' originalValue={this.state.originalUser.eContact1Email ? this.state.originalUser.eContact1Email : ''} value={this.state.user.eContact1Email ? this.state.user.eContact1Email : ''} onChange={(event) => {this.handleSetUser('eContact1Email', event.target.value)}}/>
                    <CustomControl lg={4} xl={3} xxl={2} type='text' title='eContact1Relationship' originalValue={this.state.originalUser.eContact1Relationship ? this.state.originalUser.eContact1Relationship : ''} value={this.state.user.eContact1Relationship ? this.state.user.eContact1Relationship : ''} onChange={(event) => {this.handleSetUser('eContact1Relationship', event.target.value)}}/>
                    <AddressControl lg={4} xxl={2} containerBreakPoints={{xxl: 12}} title='eContact1Address' originalValue={this.state.originalUser.eContact1Address ? this.state.originalUser.eContact1Address : {}} address={this.state.user.eContact1Address} setAddress={(value) => {this.handleSetUser('eContact1Address', value)}}/>
                    <CustomControl lg={4} xl={3} xxl={2} type='text' title='eContact2FirstName' originalValue={this.state.originalUser.eContact2FirstName ? this.state.originalUser.eContact2FirstName : ''} value={this.state.user.eContact2FirstName ? this.state.user.eContact2FirstName : ''} onChange={(event) => {this.handleSetUser('eContact2FirstName', event.target.value)}}/>
                    <CustomControl lg={4} xl={3} xxl={2} type='text' title='eContact2LastName' originalValue={this.state.originalUser.eContact2LastName ? this.state.originalUser.eContact2LastName : ''} value={this.state.user.eContact2LastName ? this.state.user.eContact2LastName : ''} onChange={(event) => {this.handleSetUser('eContact2LastName', event.target.value)}}/>
                    <CustomControl lg={4} xl={3} xxl={2} type='text' title='eContact2PhoneNumber' originalValue={this.state.originalUser.eContact2PhoneNumber ? this.state.originalUser.eContact2PhoneNumber : ''} value={this.state.user.eContact2PhoneNumber ? this.state.user.eContact2PhoneNumber : ''} onChange={(event) => {this.handleSetUser('eContact2PhoneNumber', event.target.value)}}/>
                    <CustomControl lg={4} xl={3} xxl={2} type='text' title='eContact2Email' originalValue={this.state.originalUser.eContact2Email ? this.state.originalUser.eContact2Email : ''} value={this.state.user.eContact2Email ? this.state.user.eContact2Email : ''} onChange={(event) => {this.handleSetUser('eContact2Email', event.target.value)}}/>
                    <CustomControl lg={4} xl={3} xxl={2} type='text' title='eContact2Relationship' originalValue={this.state.originalUser.eContact2Relationship ? this.state.originalUser.eContact2Relationship : ''} value={this.state.user.eContact2Relationship ? this.state.user.eContact2Relationship : ''} onChange={(event) => {this.handleSetUser('eContact2Relationship', event.target.value)}}/>
                    <AddressControl lg={4} xxl={2} containerBreakPoints={{xxl: 12}} title='eContact2Address' originalValue={this.state.originalUser.eContact2Address ? this.state.originalUser.eContact2Address : {}} address={this.state.user.eContact2Address} setAddress={(value) => {this.handleSetUser('eContact2Address', value)}}/>
                    <Col lg={6} style={{marginBottom: 8}}>
                        <div style={{display: 'flex', flexDirection: 'column', marginTop: 12}}>
                            <Button onClick={() => {this.setState({showModal: true})}}>Change Password</Button>
                        </div>
                    </Col>                    
                    <Col lg={6} style={{marginBottom: 8}}>                        
                        <div style={{display: 'flex', flexDirection: 'column', marginTop: 12}}>
                            
                            <CustomButton onClick={this.handleSaveChanges} isLoading={this.state.isSubmitting} label='Save Changes'/>
                        </div>
                    </Col>                    
                </Row>
            </Container>
            
        );

        return (
            <>
                {this.state.isLoading ? <CustomSpinner spinnerHeight='10vh' spinnerWidth='10vh' height='50vh'/> : content}
                <Modal show={this.state.showModal} onHide={() => {this.setState({showModal: false})}} centered>
                    <Modal.Header>
                        <Modal.Title>Change Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CustomControl value={this.state.newPassword} title='New Password' onChange={(event) => {this.setState({newPassword: event.target.value})}}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Form.Check type='switch' checked={this.state.sendEmployeePassword} onChange={() => {this.setState({sendEmployeePassword: !this.state.sendEmployeePassword})}} label='Send employee new password?'/>
                        <Button style={{color: 'white'}} onClick={this.handleChangePassword}>Submit</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default HRUserProfile;