/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CustomSpinner from '../../../components/CustomSpinner';
import QuickTable from '../../../components/QuickTable';
import { dateRangeToString, durationToString } from '../../../tools.js';
import HRUserTimesheetEditor from './HRUserTimesheetEditor';
import { getHRUserTimesheetEntries } from '../../../services/HRServices/HRTimesheetService';
import DateRangeSelector from '../../../components/DateRangeSelector';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning, faCheck } from '@fortawesome/free-solid-svg-icons';

class HRUserTimesheet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            entries: [],
            selectedStartDate: moment().subtract(1, 'weeks'),
            selectedEndDate: moment(),
            showDatePicker: false,
            selectedEntry: undefined,
            showEditor: false,
        }

        this.loadData = this.loadData.bind(this);
        this.handleCrud = this.handleCrud.bind(this);
        this.handleSetDateRange = this.handleSetDateRange.bind(this);
    }

    sortEntries(a, b) {
        if (moment(a.inTime).isBefore(moment(b.inTime))) {
            return 1;
        } else if (moment(a.inTime).isAfter(moment(b.inTime))) {
            return -1;
        } else {
            return 0;
        }
    }


    loadData(startDate, endDate) {
        if (!this.state.isLoading) {
            this.setState({isLoading: true});
        }

        getHRUserTimesheetEntries(this.props.companyUserUid, startDate ? startDate : '1000-01-01', endDate ? endDate : '3000-01-01').then((response) => {
            if (response && response.status === '200') {
                this.setState({
                    entries: response.data.sort(this.sortEntries),
                    selectedStartDate: startDate,
                    selectedEndDate: endDate,
                })
            }
        }).finally(() => {
            this.setState({isLoading: false});
        });
    }

    componentDidMount() {
        this.loadData(moment().subtract(1, 'weeks').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'));
    }

    handleCrud(type, data) {
        let newArray = Array.from(this.state.entries);
        if (type === 'create') {
            newArray.push(data);
            newArray.sort(this.sortEntries);
        } else if (type === 'update') {
            newArray = newArray.filter(r => r.uid !== data.uid);
            newArray.push(data);
            newArray.sort(this.sortEntries);
        } else if (type === 'delete') {
            newArray = newArray.filter(r => r.uid !== data.uid);
        }
        this.setState({entries: newArray, showEditor: false});
    }

    handleSetDateRange(startDate, endDate) {
        this.loadData(startDate, endDate);
    }

    render() {

        const timesheetEntryRows = this.state.entries.map((item) => {
            return (
                <tr key={item.uid} className='cursor-pointer' onClick={() => {this.setState({selectedEntry: item, showEditor: true})}}>
                    <td>{moment(item.inTime).format('MMM D, YYYY')}</td>
                    <td>{moment(item.inTime).format('hh:mm A')}</td>
                    <td>{item.outTime ? item.outTime === 'ACO' ? 'Auto Clock-Out' : moment(item.outTime).format('hh:mm A') : ''}</td>
                    <td>{item.outTime && item.outTime !== 'ACO' ? durationToString(moment(item.outTime).diff(moment(item.inTime), 'minutes')) : 'N/A'}</td>
                    <td>
                        {item.incidents === '1' || item.accidents === '1' || item.injuries === '1' ? 
                            <OverlayTrigger overlay={<Tooltip>An incident, accident, or injury was reported on this day</Tooltip>}>
                                <FontAwesomeIcon style={{color: 'gold'}} icon={faWarning}/>
                            </OverlayTrigger>
                        : 
                            <OverlayTrigger overlay={<Tooltip>No incidents, accidents, or injuries were reported on this day</Tooltip>}>
                                <FontAwesomeIcon style={{color: 'green'}} icon={faCheck}/>
                            </OverlayTrigger>
                        }
                    </td>
                </tr>
            )
        });

        const content = (
            
            <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>   
                    <Button style={{color: 'white', justifySelf: 'flex-end'}} onClick={() => {this.setState({showDatePicker: true})}}>{dateRangeToString(this.state.selectedStartDate, this.state.selectedEndDate)}</Button>
                    <Button style={{color: 'white', alignSelf: 'flex-end'}} onClick={() => {this.setState({showEditor: true, selectedEntry: undefined})}}>New Timesheet Entry</Button>
                </div>
                <QuickTable headers={['Date', 'In Time', 'Out Time', 'Total Time', '']} rows={timesheetEntryRows} hover size='sm'/>
                
                <DateRangeSelector 
                    show={this.state.showDatePicker} 
                    onHide={() => {this.setState({showDatePicker: false})}} 
                    handleSetDateRange={this.handleSetDateRange} 
                    presetOptions={['pastYear', 'pastMonth', 'pastWeek', 'allTime']} 
                    centered
                />
                <Modal show={this.state.showEditor} onHide={() => {this.setState({showEditor: false})}} centered size='lg'>
                    <HRUserTimesheetEditor 
                        companyUserUid={this.props.companyUserUid}
                        selectedEntry={this.state.selectedEntry} 
                        handleCrud={this.handleCrud}
                        timesheetEntries={this.state.entries}
                    />
                </Modal>
            </div>
        )

        return this.state.isLoading ? <CustomSpinner spinnerHeight='10vh' spinnerWidth='10vh' height='50vh'/> : content;
    }
}

export default HRUserTimesheet;