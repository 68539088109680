
/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import moment from 'moment';
import ListGroup from 'react-bootstrap/ListGroup';
import KeyValueRow from '../../../components/KeyValueRow';

export default function HRUserSafetyMovingViolation({record}) {

    return (
        <ListGroup style={{flex: 1}}>
            <KeyValueRow isListItem title='Date & Time' value={moment(record.dateTime).format('MMM D, YYYY [at] hh:mm A')}/>
            <KeyValueRow isListItem title='Moving Violation Type' value={record.movingViolationType}/>
            <KeyValueRow isListItem title='Police Report Number' value={record.policeReportNumber}/>
            <ListGroup.Item>
                <p>Notes</p>
                {record.notes}
            </ListGroup.Item>
        </ListGroup>
    )
    
}
