/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { createScheduleItem, updateScheduleItem, deleteScheduleItem } from '../../../services/HRServices/HRScheduleService';
import CustomButton from '../../../components/CustomButton';
import { Container, Row } from 'react-bootstrap';
import RadioControl from '../../../components/RadioControl';
import { scheduleTypeDictionary } from '../../../tools';
import CustomControl from '../../../components/CustomControl';
import DropdownControl from '../../../components/DropdownControl';
import Col from 'react-bootstrap/Col';
import AlertModal from '../../../components/AlertModals/AlertModal';

export default function HRUserScheduleItemEditor({selectedItem, handleCrud, companyUserIdentifier, users, workAreas, vehicles}) {
    const [scheduleType, setScheduleType] = useState(selectedItem ? selectedItem.scheduleType : '');
    const [date, setDate] = useState(selectedItem ? selectedItem.date : '');
    const [endDate, setEndDate] = useState(selectedItem ? selectedItem.endDate : '');
    const [timeScheduled, setTimeScheduled] = useState(selectedItem ? selectedItem.timeScheduled : '');
    const [workAreaIdentifier, setWorkAreaIdentifier] = useState(selectedItem ? selectedItem.workAreaIdentifier : '');
    const [vehicleIdentifier, setVehicleIdentifier] = useState(selectedItem ? selectedItem.vehicleIdentifier : '');
    const [secondaryDriver, setSecondaryDriver] = useState(selectedItem ? selectedItem.secondaryDriver : '');
    const [secondaryDriverType, setSecondaryDriverType] = useState(selectedItem ? selectedItem.secondaryDriverType : '');
    const [notes, setNotes] = useState(selectedItem ? selectedItem.notes : '');

    const [isLoading, setIsLoading] = useState(false);

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const handleSubmit = () => {
        setIsLoading(true);
        const newItem = {
            uid: selectedItem ? selectedItem.uid : undefined,
            companyUserIdentifier: companyUserIdentifier,
            scheduleType: scheduleType, 
            date: date, 
            endDate: ['rejectedRequest', 'requestOff'].includes(scheduleType) ? endDate : undefined,
            timeScheduled: ['route', 'managerOnDuty', 'paidTraining'].includes(scheduleType) ? timeScheduled : undefined,
            workAreaIdentifier: scheduleType === 'route' ? workAreaIdentifier : undefined,
            vehicleIdentifier: scheduleType === 'route' ? vehicleIdentifier : undefined,
            secondaryDriver: scheduleType === 'route' ? secondaryDriver : undefined,
            secondaryDriverType: scheduleType === 'route' ? secondaryDriverType : undefined,
            notes: notes, 
            submissionDate: moment().format('YYYY-MM-DD')
        }

        if (selectedItem) {
            updateScheduleItem(newItem).then((response) => {
                if (response && response.status === '200') {
                    handleCrud('update', newItem);
                }
            });
        } else {
            createScheduleItem(newItem).then((response) => {
                if (response && response.status === '200') {
                    newItem.uid = response.uid;
                    handleCrud('create', newItem);
                }
            });;

            
        }
        
    }
    
    const handleDelete = async () => {
        const response = await deleteScheduleItem(selectedItem.uid);
        if (response && response.status === '200') {
            setShowDeleteConfirmation(false);
            handleCrud('delete', selectedItem);
            return true;
        } else {
            return false;
        }
    }
    
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{selectedItem ? 'Editing Schedule Item' : 'Creating Schedule Item'}</Modal.Title>
                { selectedItem ?
                    <CustomButton isLoading={isLoading} label='Delete Schedule Item' onClick={() => {setShowDeleteConfirmation(true)}} style={{marginLeft: 12}}/>
                : ''}
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <RadioControl xxl={12} disabled={selectedItem} title='Schedule Type' inline selection={scheduleType} setSelection={setScheduleType} originalValue={selectedItem ? selectedItem.scheduleType : undefined} optionNames={Object.keys(scheduleTypeDictionary).filter(r => r !== 'driverGeneratedRoute').map(r => scheduleTypeDictionary[r].label)} optionValues={Object.keys(scheduleTypeDictionary).filter(r => r !== 'driverGeneratedRoute')}/>
                        <CustomControl lg={12} title={['rejectedRequest', 'requestOff'].includes(scheduleType) ? 'Start Date' : 'Date'} type='date' value={date} onChange={(event) => {setDate(event.target.value)}}/>
                        { ['rejectedRequest', 'requestOff'].includes(scheduleType) ?
                            <CustomControl lg={12} title='End Date' type='date' value={endDate} onChange={(event) => {setEndDate(event.target.value)}}/>
                        : ''}
                        { ['route', 'managerOnDuty', 'paidTraining'].includes(scheduleType) ?
                            <CustomControl xxl={12} title='Time Scheduled' type='time' value={timeScheduled} onChange={(event) => {setTimeScheduled(event.target.value)}}/>
                        : ''}
                        
                        { scheduleType === 'route' ?
                            <>
                                <Col xxl={12} style={{marginBottom: 8}}>
                                    <DropdownControl title='Work Area' selection={workAreaIdentifier} setSelection={setWorkAreaIdentifier} itemNames={workAreas.map(r => r.workAreaName)} itemValues={workAreas.map(r => r.uid)}/>
                                </Col>
                                <Col xxl={12} style={{marginBottom: 8}}>
                                    <DropdownControl title='Vehicle' selection={vehicleIdentifier} setSelection={setVehicleIdentifier} itemNames={vehicles.map(r => r.vehicleName)} itemValues={vehicles.map(r => r.uid)}/>
                                </Col>
                                <Col xxl={12} style={{marginBottom: 8}}>
                                    <DropdownControl title='Secondary Driver' selection={secondaryDriver} setSelection={setSecondaryDriver} itemNames={users.map(r => r.firstName + ' ' + r.lastName)} itemValues={users.map(r => r.companyUserUid)}/>
                                </Col>
                                { secondaryDriver ?
                                    <RadioControl lg={12} title='Secondary Driver Type' inline selection={secondaryDriverType} setSelection={setSecondaryDriverType} originalValue={selectedItem ? selectedItem.secondaryDriverType : undefined} optionNames={['Jumper', 'Trainer']} optionValues={['jumper', 'trainer']}/>
                                : ''}
                                
                            </>
                        : ''}
                        
                        <Col xxl={12}>
                            <Form.Group>
                                <Form.Label>Notes</Form.Label>
                                <Form.Control as='textarea' max={65535} disabled={isLoading} rows={4} value={notes} onChange={(event) => {setNotes(event.target.value)}}/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton label={selectedItem ? 'Save Changes' : 'Create Schedule Item'} isLoading={isLoading} onClick={handleSubmit}/>
            </Modal.Footer>
            <AlertModal 
                show={showDeleteConfirmation} 
                onHide={() => {setShowDeleteConfirmation(false)}} 
                title='Deletion Confirmation' 
                message={'Permanently delete schedule item?'} 
                buttonLabel='Delete' 
                callBack={handleDelete} 
                centered
            />
        </>
    )
}

