/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../decoding";
import { validateDecimal, validateUsd } from "../payrollTools";
import { getFakeUID } from "../../../tools";

export default class AdditionalPay {

    id;
    type;
    amount;
    isReoccuring;
    canBeReoccuring;

    constructor(type, amount = 0, isReoccuring, canBeReoccuring = true) {
        this.id = getFakeUID();
        this.type = type;
        this.amount = amount;
        this.isReoccuring = isReoccuring;
        this.canBeReoccuring = canBeReoccuring
    }

    static initDefault() {
        return new AdditionalPay('Expense Reimbursement', 0, false, false);
    }

    static decode(json) {
        const decoder = new Decoder(json);
        const type = decoder.decode('type', Decoder.StringStrict);
        const amount = decoder.decode('amount', Decoder.Decimal);
        const isReoccuring = decoder.decode('isReoccuring', Decoder.Boolean, {defaultValue: false, warn: true});
        const canBeReoccuring = decoder.decode('canBeReoccuring', Decoder.Boolean, {defaultValue: true, warn: true});

        if (decoder.checkForErrors()) {
            return new AdditionalPay(type, amount, isReoccuring, canBeReoccuring);
        } else {
            return AdditionalPay.initDefault();
        }
    }

    encode() {
        return {
            type: this.type,
            amount: validateDecimal(this.amount),
            isReoccuring: this.isReoccuring,
            canBeReoccuring: this.canBeReoccuring,
        }
    }

    duplicate() {
        return new AdditionalPay(this.type, this.amount, this.isReoccuring, this.canBeReoccuring)
    }
    getAmount() {
        return validateUsd(this.amount);
    }
    
    static additionalPayTypes = [
        'Expense Reimbursement', //now in gross
        'Backpay'
    ]
}