/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";

import CustomControl from "../../../components/CustomControl";
import Row from "react-bootstrap/Row";
import Container from 'react-bootstrap/Container';
import RadioControl from "../../../components/RadioControl";
import QuickTable from "../../../components/QuickTable";
import { Button } from "react-bootstrap";
import { getFakeUID } from "../../../fake-data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faX } from "@fortawesome/free-solid-svg-icons";
import ListGroup from 'react-bootstrap/ListGroup';
import KeyValueRow from "../../../components/KeyValueRow";
import moment from "moment";
import { addressToString, availabilityToString, formatPhoneNumber, formatSSN, packetIdentifierDictionary, usdFormatter } from "../../../tools";


export default function CreateCompanyReview({AOInfo, csas, workAreas, vehicles, companyInfo}) {

    const csaRows = csas.map((csa) => {
        return (
            <p style={{marginBottom: 0}} key={csa.uid}>{csa.csaName + ' | ' + packetIdentifierDictionary[csa.packetIdentifier] + ' | ' + addressToString(csa.address)}</p>
        )
    })
    const workAreaRows = workAreas.map((wa) => {
        return (
            <p style={{marginBottom: 0}} key={wa.uid}>{wa.workAreaName + ' | ' + csas.find(r => r.uid === wa.csaIdentifier).csaName}</p>
        )
    })
    const vehicleRows = vehicles.map((v) => {
        return (
            <p style={{marginBottom: 0}} key={v.uid}>{v.vehicleName + ' | ' + v.vehicleWeight + ' | ' + csas.find(r => r.uid === v.csaIdentifier).csaName}</p>
        )
    })

    return (
        <ListGroup>
            
            <KeyValueRow isListItem title={'AO First Name'} value={AOInfo.firstName}/>
            <KeyValueRow isListItem title={'AO Middle Name'} value={AOInfo.middleName}/>
            <KeyValueRow isListItem title={'AO Last Name'} value={AOInfo.lastName}/>
            <KeyValueRow isListItem title={'AO Email Address'} value={AOInfo.email}/>
            <KeyValueRow isListItem title={'AO Phone Number'} value={formatPhoneNumber(AOInfo.phoneNumber)}/>
            <KeyValueRow isListItem title={'AO Date of Birth'} value={moment(AOInfo.dateOfBirth).format('MMM D, YYYY')}/>
            <KeyValueRow isListItem title={'AO Social Security Number'} value={formatSSN(AOInfo.ssn)}/>
            <KeyValueRow isListItem title={'AO Address'} value={addressToString(AOInfo.address)}/>
            <KeyValueRow isListItem title={'AO Pay Type'} value={AOInfo.payType}/>
            <KeyValueRow isListItem title={'AO Pay Rate'} value={usdFormatter.format(AOInfo.payRate)}/>
            <KeyValueRow isListItem title={'AO Hourly Wage'} value={usdFormatter.format(AOInfo.hourlyWage)}/>
            <KeyValueRow isListItem title={'AO Availability'} value={availabilityToString(AOInfo.availability)}/>

            <ListGroup.Item style={{display: 'flex', justifyContent: 'space-between'}}>
                <p>CSAs</p>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                    {csaRows}
                </div>
            </ListGroup.Item>
            <ListGroup.Item style={{display: 'flex', justifyContent: 'space-between'}}>
                <p>Work Areas</p>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                    {workAreaRows}
                </div>
            </ListGroup.Item>
            <ListGroup.Item style={{display: 'flex', justifyContent: 'space-between'}}>
                <p>Vehicles</p>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                    {vehicleRows}
                </div>
            </ListGroup.Item>

            <KeyValueRow isListItem title={'Company Name'} value={companyInfo.companyName}/>
            <KeyValueRow isListItem title={'FedEx Username'} value={companyInfo.fedexUsername}/>
            <KeyValueRow isListItem title={'FedEx Password'} value={companyInfo.fedexPassword}/>
            <KeyValueRow isListItem title={'WC Username'} value={companyInfo.wcUsername}/>
            <KeyValueRow isListItem title={'WC Password'} value={companyInfo.wcPassword}/>
            <KeyValueRow isListItem title={'Company Phone Number'} value={formatPhoneNumber(companyInfo.phoneNumber)}/>
            <KeyValueRow isListItem title={'Company Address'} value={addressToString(companyInfo.address)}/>
            <KeyValueRow isListItem title={'Company Benefits'} value={companyInfo.benefits}/>
            <KeyValueRow isListItem title={'EIN'} value={companyInfo.ein}/>
            <KeyValueRow isListItem title={'Force MFA?'} value={companyInfo.forceMfa === '1' ? 'Yes' : 'No'}/>
            <KeyValueRow isListItem title={'Force Location?'} value={companyInfo.forceLocation === '1' ? 'Yes' : 'No'}/>
            <KeyValueRow isListItem title={'Company Address Allow Clock-In?'} value={companyInfo.companyAddressAllowClockIn === '1' ? 'Yes' : 'No'}/>
            <KeyValueRow isListItem title={'Company Address Clock-In Radius'} value={companyInfo.companyAddressClockInRadius}/>
            <KeyValueRow isListItem title={'Enable Geofencing?'} value={companyInfo.enableGeofencing === '1' ? 'Yes' : 'No'}/>
            <KeyValueRow isListItem title={'Allow BC Access To Sensitive Information?'} value={companyInfo.allowBCAccessToSensitiveInformation === '1' ? 'Yes' : 'No'}/>
            <KeyValueRow isListItem title={'Enable BC Notifications?'} value={companyInfo.enableBCNotifications === '1' ? 'Yes' : 'No'}/>
            <KeyValueRow isListItem title={'Restrict BC To CSA?'} value={companyInfo.restrictBcsToCsa === '1' ? 'Yes' : 'No'}/>
            <KeyValueRow isListItem title={'Show BCs CSA Payroll History?'} value={companyInfo.showBcsCSAPayrollHistory === '1' ? 'Yes' : 'No'}/>
            <KeyValueRow isListItem title={'Payroll Enabled?'} value={companyInfo.payrollEnabled === '1' ? 'Yes' : 'No'}/>
            
        </ListGroup>
    )
}