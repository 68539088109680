/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useState } from "react";
import { addScheduleItemsToRouteTable, migrateDocumentRequests } from "../../services/AdminToolsService";
import { AppGlobals } from "../../App";
import { Button, Card, Modal } from "react-bootstrap";
import CustomButton from "../../components/CustomButton";

export default function MigrateOldDocumentRequests({}) {

    const [showModal, setShowModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false)

    async function handleMigrateDocumentRequests() {
        setIsSubmitting(true);
        const response = await migrateDocumentRequests();
        if (response.status == 200) {
            AppGlobals.alert('success', response.message);
        } else {
            AppGlobals.alert('danger', response.message);
        }
        setIsSubmitting(false);
    }

    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title>Migrate Old Document Requests From Before 1.9.3</Card.Title>
                </Card.Header>
                <Card.Body>
                    Create new route rows in route table from all existing schedule items that have a schedule type of "route" or "driverGeneratedRoute".
                </Card.Body>
                <Card.Footer>
                    <Button onClick={()=>setShowModal(true)} style={{display: 'block', marginLeft: 'auto'}}>
                        Migrate Document Requests
                    </Button>
                </Card.Footer>
            </Card>
            <Modal show={showModal} onHide={()=>setShowModal(false)}>
               <Modal.Header closeButton>
                    <Modal.Title>Migrate Document Requests</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                    Are you sure?
               </Modal.Body>
               <Modal.Footer>
                    <CustomButton isLoading={isSubmitting} label={'Migrate Document Requests'} onClick={handleMigrateDocumentRequests}/>
               </Modal.Footer>
            </Modal>
        </>
    )
}