/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import CustomButton from "../../components/CustomButton";
import DropdownControl from "../../components/DropdownControl";
import { validateExistence, ValidationGroup } from "../../validation";
import {generatePTOAccrual } from "../../services/PayrollServiceAdmin";
import moment from "moment";
import KeyValueRow from "../../components/KeyValueRow";
import { ListGroup } from "react-bootstrap";

export default function GeneratePTOAccrualModal({companies, payrollPeriods}) {
    const [isLoading, setIsLoading] = useState(false);
    const [ptoAccrualData, setPtoAccrualData] = useState(null);
    const [error, setError] = useState(false);
    const [company, setCompany] = useState(undefined);
    const [payrollIdentifier, setPayrollIdentifier] = useState(undefined);

    const validationGroup = new ValidationGroup();

    const handleSubmit = async () => {
        setError(false);
        setIsLoading(true);
            const response = await generatePTOAccrual(payrollIdentifier);
            if(response.status === '200'){
                setPtoAccrualData(response.data);
            }else{
                setError(true)
            }
        setIsLoading(false);
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Generate PTO Accrual</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <DropdownControl 
                    validator={validationGroup.createValidator(() => validateExistence(company))} 
                    title='Company' selection={company} setSelection={setCompany} 
                    itemNames={companies.map(c => c.companyName)} 
                    itemValues={companies.map(c => c.uid)}
                    disabled={isLoading}
                />
                { company &&
                    <DropdownControl 
                        validator={validationGroup.createValidator(() => validateExistence(payrollIdentifier))} 
                        title='Period' selection={payrollIdentifier} setSelection={setPayrollIdentifier} 
                        itemNames={payrollPeriods.filter(p => p.companyIdentifier == company).map(p => `${moment(p.periodStart).format('MMM D, YYYY')} - ${moment(p.periodEnd).format('MMM D, YYYY')}`)} 
                        itemValues={payrollPeriods.filter(p => p.companyIdentifier == company).map(p => p.uid)}
                        disabled={isLoading}
                    />
                }
                <ListGroup>
                {ptoAccrualData && 
                    ptoAccrualData.map(data=>{
                        return <KeyValueRow isListItem title={<span>( {data.companyUserIdentifier} ) {data.name.firstName} {data.name.middleName} {data.name.lastName}</span>} value={<span>PTO Hours: {data.hours.toFixed(2)}</span>}/>
                    })            
                }
                </ListGroup>
            </Modal.Body>
            <Modal.Footer style={{display: 'flex', justifyContent: 'space-between'}}>
                <CustomButton label='Generate PTO Accrual' isLoading={isLoading} disabled={!validationGroup.isValid()} onClick={handleSubmit}/>
                {error && <span style={{color: 'red'}}>Failed To Generate PTO</span>}
            </Modal.Footer>
        </>
    )
}