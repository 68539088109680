/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import CustomControl from '../../../components/CustomControl';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { createTimesheetEntry, updateTimesheetEntry, deleteTimesheetEntry } from '../../../services/HRServices/HRTimesheetService';
import AlertModal from '../../../components/AlertModals/AlertModal';
import { downloadDocument } from '../../../services/HRServices/HRDocumentsService';
import SwitchControl from '../../../components/SwitchControl';
import { Alert } from 'react-bootstrap';


function HRUserTimesheetEditor({companyUserUid, selectedEntry, handleCrud, timesheetEntries}) {
    const [inTime, setInTime] = useState(selectedEntry ? moment(selectedEntry.inTime).format('YYYY-MM-DD HH:mm') : `${moment().format('YYYY-MM-DD')} 08:00`);
    const [outTime, setOutTime] = useState(selectedEntry && selectedEntry.outTime ? moment(selectedEntry.outTime).format('YYYY-MM-DD HH:mm') : '');
    const [isLoading, setIsLoading] = useState(false);
    const [inPicture, setInPicture] = useState(undefined);
    const [outPicture, setOutPicture] = useState(undefined);
    const [error, setError] = useState(undefined);

    const [incidents, setIncidents] = useState(selectedEntry ? selectedEntry.incidents : '0');
    const [accidents, setAccidents] = useState(selectedEntry ? selectedEntry.accidents : '0');
    const [injuries, setInjuries] = useState(selectedEntry ? selectedEntry.injuries : '0');


    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const handleSubmit = () => {
        setIsLoading(true);
        const newEntry = {
            uid: selectedEntry ? selectedEntry.uid : undefined,
            companyUserIdentifier: companyUserUid,
            inTime: moment(inTime).format('YYYY-MM-DD HH:mm'),
            outTime: moment(outTime).format('YYYY-MM-DD HH:mm'),
            incidents: incidents,
            accidents: accidents,
            injuries: injuries,
            inLat: selectedEntry ? selectedEntry.inLat : undefined,
            inLong: selectedEntry ? selectedEntry.inLong : undefined,
            outLat: selectedEntry ? selectedEntry.outLat : undefined,
            outLong: selectedEntry ? selectedEntry.outLong : undefined,
            inPicture: selectedEntry ? selectedEntry.inPicture : undefined,
            outPicture: selectedEntry ? selectedEntry.outPicture : undefined,
        }

        if (selectedEntry) {
            updateTimesheetEntry(newEntry).then((response) => {
                if (response && response.status === '200') {
                    handleCrud('update', newEntry);
                }
                setIsLoading(false);
            });
        } else {
            createTimesheetEntry(newEntry).then((response) => {
                if (response && response.status === '200') {
                    newEntry.uid = response.uid;
                    handleCrud('create', newEntry);
                }
                setIsLoading(false);
            });
        }
    }

    const handleDownloadFile = async (uid) => {
        const response = await downloadDocument(uid);
        if (response.status === '200') {
            return {
                ...response.data,
                base64: response.base64
            }
        }
    }

    useEffect(() => {
        if(selectedEntry) {
            if (selectedEntry.inPicture) {
                handleDownloadFile(selectedEntry.inPicture).then((response) => {
                    setInPicture(response);
                })
            }
            if (selectedEntry.outPicture) {
                handleDownloadFile(selectedEntry.outPicture).then((response) => {
                    setOutPicture(response);
                })
            }
        }
    }, []);

    const handleDelete = async () => {
        try {
            const response = await deleteTimesheetEntry(selectedEntry.uid);
            if (response && response.status === '200') {
                handleCrud('delete', selectedEntry);
                return true;
            }
        } catch(e) {
            console.log(e);
        }
        return false;
    }

    const handleTimeUpdates = () => {
        setError(undefined);
      
        if (moment(inTime).isAfter(moment(outTime), 'seconds')) {
            setError('The out time must come after the in time');
        } else {
            const filteredEntries = timesheetEntries.filter(e => e.uid !== selectedEntry?.uid ?? -1);
            for (let i = 0; i < filteredEntries.length; i++) {
                if (!moment(inTime).isSameOrAfter(moment(filteredEntries[i].outTime), 'minute') && !moment(outTime).isSameOrBefore(moment(filteredEntries[i].inTime), 'minute')) {
                    setError('You can not overlap time with an existing timesheet entry');
                    return;
                }
            }
        } 
    };

    useEffect(() => {
    handleTimeUpdates();
    }, [inTime, outTime]); 

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{selectedEntry ? 'Editing Timesheet Entry' : 'Creating Timesheet Entry'}</Modal.Title>
                { selectedEntry ? 
                    <Button style={{marginLeft: 12}} onClick={() => {setShowDeleteConfirmation(true)}}>Delete Entry</Button>
                : ''}
            </Modal.Header>
            <Modal.Body>
                {error && <Alert variant='warning' style={{fontSize:'10', padding:2}}>{error}</Alert>}
                <Container fluid>
                    <Row>
                        <CustomControl originalValue={selectedEntry ? moment(selectedEntry.inTime).format('YYYY-MM-DD HH:mm') : undefined} lg={6} disabled={isLoading} title='In Time' type='datetime-local' value={inTime} onChange={(event) => {setInTime(event.target.value)}}/>
                        <CustomControl originalValue={selectedEntry && selectedEntry.outTime ? moment(selectedEntry.outTime).format('YYYY-MM-DD HH:mm') : undefined} lg={6} disabled={isLoading} title='Out Time' type='datetime-local' value={outTime} onChange={(event) => {setOutTime(event.target.value)}}/>
                        { selectedEntry &&
                            <>
                                <Col lg={6} style={{marginBottom: 8}}>    
                                    { inPicture ?
                                        <div style={{width: '100%', backgroundColor: 'black', borderRadius: 6}}>
                                            <img src={inPicture.base64} style={{objectFit: 'contain', width: '100%', aspectRatio: 1}}/>
                                        </div>
                                    : selectedEntry.inLat && selectedEntry.inLong ?
                                        <LoadScript googleMapsApiKey="AIzaSyBO6pLOjJq8W63VtQ3TPkpUW1mF7gdfJgo">
                                            <GoogleMap mapContainerStyle={{width: '100%', aspectRatio: 1}} center={{lat: parseFloat(selectedEntry.inLat), lng: parseFloat(selectedEntry.inLong)}} zoom={16}>
                                                <Marker label='In' position={{lat: parseFloat(selectedEntry.inLat), lng: parseFloat(selectedEntry.inLong)}}/>
                                            </GoogleMap>
                                        </LoadScript>
                                    : 
                                        <div style={{width: '100%', aspectRatio: 1, display: 'flex', fontSize: 20, justifyContent: 'center', alignItems: 'center', border: '1px solid lightgray', borderRadius: 6}}>
                                            Clock-In Location Unknown
                                        </div>
                                    }
                                </Col>
                                <Col lg={6} style={{marginBottom: 8}}>    
                                    { outPicture ?
                                        <div style={{width: '100%', backgroundColor: 'black', borderRadius: 6}}>
                                            <img src={outPicture.base64} style={{objectFit: 'contain', width: '100%', aspectRatio: 1}}/>
                                        </div>
                                    : selectedEntry.outLat && selectedEntry.outLong ?
                                        <LoadScript googleMapsApiKey="AIzaSyBO6pLOjJq8W63VtQ3TPkpUW1mF7gdfJgo">
                                            <GoogleMap mapContainerStyle={{width: '100%', aspectRatio: 1}} center={{lat: parseFloat(selectedEntry.outLat), lng: parseFloat(selectedEntry.outLong)}} zoom={16}>
                                                <Marker label='Out' position={{lat: parseFloat(selectedEntry.outLat), lng: parseFloat(selectedEntry.outLong)}}/>
                                            </GoogleMap>
                                        </LoadScript>
                                    :
                                        <div style={{width: '100%', aspectRatio: 1, display: 'flex', fontSize: 20, justifyContent: 'center', alignItems: 'center', border: '1px solid lightgray', borderRadius: 6}}>
                                            Clock-Out Location Unknown
                                        </div>
                                    }
                                </Col>
                                <SwitchControl lg={4} originalValue={selectedEntry ? selectedEntry.incidents : undefined} title={'Reported Incident?'} value={incidents} setValue={setIncidents}/>
                                <SwitchControl lg={4} originalValue={selectedEntry ? selectedEntry.accidents : undefined} title={'Reported Accident?'} value={accidents} setValue={setAccidents}/>
                                <SwitchControl lg={4} originalValue={selectedEntry ? selectedEntry.injuries : undefined} title={'Reported Injury?'} value={injuries} setValue={setInjuries}/>
                            </>
                        }
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button style={{color: 'white'}} disabled={isLoading || !inTime} onClick={handleSubmit}>
                    {isLoading ? <Spinner size='sm'/> : selectedEntry ? 'Save Changes' : 'Submit'}
                </Button>
            </Modal.Footer>
            <AlertModal 
                show={showDeleteConfirmation} 
                onHide={() => {setShowDeleteConfirmation(false)}} 
                title='Deletion Confirmation' 
                message={'Permanently delete schedule item?'} 
                buttonLabel='Delete' 
                callBack={handleDelete} 
                centered
            />
        </>
    )
}

export default HRUserTimesheetEditor;