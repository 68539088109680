/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState, useEffect} from 'react';
import RichTextEditor from '../../components/RichText/RichTextEditor';
import { EditorState } from 'draft-js';
import RichText from '../../components/RichText/RichText';
import { getNotification, deleteNotification } from '../../services/NotificationService';
import CustomButton from '../../components/CustomButton';
import { Modal, Alert, ListGroup } from 'react-bootstrap';
import moment from 'moment';
import PageSpinner from '../../components/PageSpinner';
import NotificationEditor from './NotificationEditor';
import AlertModal from '../../components/AlertModals/AlertModal';
import SendNotificationModal from './SendNotificationModal';
import SearchBar from '../../components/SearchBar';

export default function AcknowledgementModal({acknowledgements}) {

    const [nameFilter, setNameFilter] = useState('');


    const userElements = acknowledgements.filter(ack => `${ack.firstName} ${ack.middleName} ${ack.lastName}`.toLowerCase().includes(nameFilter.toLowerCase())).map((ack, index) => {
        return (
            <ListGroup.Item key={index}>
                {`${ack.firstName} ${ack.middleName} ${ack.lastName}`}
            </ListGroup.Item>
        )
    })

    return (
        <>
            <Modal.Header>
                <Modal.Title>Notification Acknowledgements</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SearchBar label='Search by Name' value={nameFilter} setValue={setNameFilter}/>
                <ListGroup style={{marginTop: 12}}>
                    {userElements}
                </ListGroup>
            </Modal.Body>
        </>
    )
}