/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from "./serviceTools";

export async function getHumanResourceEmployees() {
    const data = {
        type: 'mtbHr',
        target: 'hub',
        authToken: getToken(),
    }

    const response = await getResponse(data);
    return response;
}

//////////////////
/// HR PROFILE
//////////////////
export async function getEmployeeProfile(userIdentifier) {
    const data = {
        type: 'mtbHr',
        target: 'getProfile',
        authToken: getToken(),
        userIdentifier
    }

    const response = await getResponse(data);
    return response;
}

export async function getSSN(password, userIdentifier) {
    const data = {
        type: 'mtbHr',
        target: 'getSSN',
        authToken: getToken(),
        password,
        userIdentifier
    }

    const response = await getResponse(data);
    return response;
}

export async function updateEmployeeProfile(employee){
    var data = {
        type: 'mtbHr',
        target: 'updateProfile',
        authToken: getToken(),
        employee
    }

    return await getResponse(data);
}

//////////////////
/// HR SCHEDULE
//////////////////

export async function getEmployeeSchedule(userIdentifier) {
    var data = {
        type: 'mtbHr',
        target: 'getSchedule',
        authToken: getToken(),
        userIdentifier: userIdentifier
    }

    return await getResponse(data);
}

export async function createCallOff(callOff) {
    var data = {
        type: 'mtbHr',
        target: 'createCallOff',
        authToken: getToken(),
        callOff: callOff
    }

    return await getResponse(data);
}

export async function getEmployeeFutureTimeOff(userIdentifier) {
    var data = {
        type: 'mtbHr',
        target: 'getEmployeeFutureTimeOff',
        authToken: getToken(),
        userIdentifier: userIdentifier
    }

    return await getResponse(data);
}

export async function getAllFutureTimeOff() {
    var data = {
        type: 'mtbHr',
        target: 'getAllFutureTimeOff',
        authToken: getToken(),
    }

    return await getResponse(data);
}

export async function acceptRequestOff(uid, ptoArray) {
    var data = {
        type: 'mtbHr',
        target: 'acceptRequestOff',
        authToken: getToken(),
        uid: uid,
        ptoArray: ptoArray
    }

    return await getResponse(data);
}

export async function rejectRequestOff(uid) {
    var data = {
        type: 'mtbHr',
        target: 'rejectRequestOff',
        authToken: getToken(),
        uid: uid
    }

    return await getResponse(data);
}

export async function createTimeOff(timeOff, ptoArray) {
    var data = {
        type: 'mtbHr',
        target: 'createTimeOff',
        authToken: getToken(),
        timeOff: timeOff,
        ptoArray: ptoArray
    }

    return await getResponse(data);
}

//////////////////
/// HR TIMESHEETS
//////////////////
export async function getTimesheetEntries(userIdentifier, startDate, endDate) {
    var data = {
        type: 'mtbHr',
        target: 'getAllTimesheets',
        authToken: getToken(),
        userIdentifier,
        startDate,
        endDate
    }

    const response = await getResponse(data);
    return response;
}

export async function createTimesheetEntry(timesheetEntry) {
    var data = {
        type: 'mtbHr',
        target: 'createTimesheet',
        authToken: getToken(),
        timesheetEntry
    }

    return await getResponse(data);
}

export async function updateTimesheetEntry(timesheetEntry) {
    var data = {
        type: 'mtbHr',
        target: 'updateTimesheet',
        authToken: getToken(),
        timesheetEntry
    }

    return await getResponse(data);
}

export async function deleteTimesheetEntry(timesheetEntryIdentifier) {
    var data = {
        type: 'mtbHr',
        target: 'deleteTimesheet',
        authToken: getToken(),
        timesheetEntryIdentifier
    }

    return await getResponse(data);
}

//////////////////
/// HR PAY
//////////////////

export async function getAllEmployeePay(userIdentifier) {
    var data = {
        type: 'mtbHr',
        target: 'getAllPay',
        authToken: getToken(),
        userIdentifier: userIdentifier
    }

    return await getResponse(data);
}

export async function createEmployeePay(pay, _) {
    var data = {
        type: 'mtbHr',
        target: 'createPay',
        authToken: getToken(),
        pay: pay
    }

    return await getResponse(data);
}

export async function updateEmployeePay(pay, _) {
    var data = {
        type: 'mtbHr',
        target: 'updatePay',
        authToken: getToken(),
        pay: pay
    }

    return await getResponse(data);
}

//////////////////
/// HR PTO
//////////////////

export async function getEmployeePto(userIdentifier, startDate, endDate) {
    var data = {
        type: 'mtbHr',
        target: 'getPto',
        authToken: getToken(),
        userIdentifier: userIdentifier,
        startDate: startDate,
        endDate: endDate
    }

    return await getResponse(data);
}

export async function createEmployeePto(pto) {
    var data = {
        type: 'mtbHr',
        target: 'createPto',
        authToken: getToken(),
        pto: pto,
    }

    return await getResponse(data);
}

export async function updateEmployeePto(pto) {
    var data = {
        type: 'mtbHr',
        target: 'updatePto',
        authToken: getToken(),
        pto: pto,
    }

    return await getResponse(data);
}

export async function deleteEmployeePto(uid) {
    var data = {
        type: 'mtbHr',
        target: 'deletePto',
        authToken: getToken(),
        uid: uid,
    }

    return await getResponse(data);
}

export async function getPtoBalance(userIdentifier) {
    var data = {
        type: 'mtbHr',
        target: 'getPtoBalance',
        authToken: getToken(),
        userIdentifier: userIdentifier,
    }

    return await getResponse(data);
}

//////////////////
/// HR LOANS
//////////////////

export async function getAllEmployeeLoans(userIdentifier) {
    var data = {
        type: 'mtbHr',
        target: 'getAllLoans',
        authToken: getToken(),
        userIdentifier: userIdentifier,
    }

    return await getResponse(data);
}

export async function getEmployeeLoan(uid) {
    var data = {
        type: 'mtbHr',
        target: 'getLoan',
        authToken: getToken(),
        uid: uid,
    }

    return await getResponse(data);
}

export async function createEmployeeLoan(loan) {
    var data = {
        type: 'mtbHr',
        target: 'createLoan',
        authToken: getToken(),
        loan: loan,
    }

    return await getResponse(data);
}

export async function createEmployeeLoanInstallment(loanInstallment) {
    var data = {
        type: 'mtbHr',
        target: 'createLoanInstallment',
        authToken: getToken(),
        loanInstallment: loanInstallment,
    }

    return await getResponse(data);
}

export async function updateEmployeeLoan(loan) {
    var data = {
        type: 'mtbHr',
        target: 'updateLoan',
        authToken: getToken(),
        loan: loan,
    }

    return await getResponse(data);
}

export async function deleteEmployeeLoan(uid) {
    var data = {
        type: 'mtbHr',
        target: 'deleteLoan',
        authToken: getToken(),
        uid: uid,
    }

    return await getResponse(data);
}

//////////////////
/// HR Documents
//////////////////


export async function getEmployeeDocument(uid) {
    var data = {
        type: 'mtbHr',
        target: 'getDocument',
        token: getToken(),
        uid: uid,
    }

    return await getResponse(data);
}

//////////////////
/// HR PERMISSIONS
//////////////////
export async function getHumanResourcePermissions(userIdentifier){
    const data = {
        type: 'mtbHr',
        target: 'getPermissions',
        authToken: getToken(),
        userIdentifier
    }

    const reponse = await getResponse(data);
    return reponse;
}

export async function updateHumanResourcePermissions(permissions) {
    const data = {
        type: 'mtbHr',
        target: 'updatePermissions',
        authToken: getToken(),
        permissions
    }

    return await getResponse(data);
}