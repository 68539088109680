/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../decoding";
import { validateDecimal, validateUsd } from "../payrollTools";
import NDBonus from "./NDBonus";
import { getFakeUID } from "../../../tools";

export default class DBonus {

    id;
    type;
    amount;

    constructor(type, amount = 0) {
        this.id = getFakeUID();
        this.type = type;
        this.amount = amount;
    }

    static initDefault() {
        return new DBonus('Holiday Bonus', 0);
    }

    static decode(json) {
        const decoder = new Decoder(json);
        const type = decoder.decode('type', Decoder.StringStrict);
        const amount = decoder.decode('amount', Decoder.Decimal);

        if (decoder.checkForErrors()) {
            return new DBonus(type, amount);
        } else {
            return DBonus.initDefault();
        }
    }

    encode() {
        return {
            type: this.type,
            amount: validateDecimal(this.amount)
        }
    }
    
    duplicate() {
        return new NDBonus(this.type, this.amount);
    }

    getAmount() {
        return validateUsd(this.amount);
    }
    
    static dBonusTypes = [
        'Holiday Bonus',
        'Other Discretionary Bonus'
    ];
}