/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState, useEffect} from 'react';
import RichTextEditor from '../../components/RichText/RichTextEditor';
import { EditorState } from 'draft-js';
import RichText from '../../components/RichText/RichText';
import { createNotification, getAllNotifications, getNotification, updateNotification, deleteNotification, sendNotification } from '../../services/NotificationService';
import CustomButton from '../../components/CustomButton';
import CustomControl from '../../components/CustomControl';
import { Form, Modal, ListGroup, Alert } from 'react-bootstrap';
import moment from 'moment';
import PageSpinner from '../../components/PageSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';


export default function NotificationEditor({notification, hideModal, handleAddNotification, handleUpdateNotification}) {

    const [editorState, setEditorState] = useState(notification ? notification.editorState : EditorState.createEmpty());
    const [notificationName, setNotificationName] = useState(notification ? notification.name : '');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async () => {
        setIsSubmitting(true);
        const notificationToSave = {
            uid: notification?.uid,
            richText: RichText.encode(editorState),
            name: notificationName
        }

        if (notificationToSave.uid) {
            const response = await updateNotification(notificationToSave);
            if (response.status === '200') {
                handleUpdateNotification(notificationToSave);
                hideModal();
            }
        } else {
            const response = await createNotification(notificationToSave);
            if (response.status === '200') {
                notificationToSave.uid = response.uid;
                handleAddNotification(notificationToSave);
                hideModal();
            }
        }
        setIsSubmitting(false);
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{notification?.uid ? 'Editing Notification' : 'Creating Notification'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                    <div style={{display:'flex', width: '100%', gap: 12}}>
                        <div style={{flex: 1}}>
                            <CustomControl type='text' title='Notification Name' value={notificationName} onChange={(event)=>setNotificationName(event.target.value)}/>
                        </div>
                    </div>
                    <div style={{display:'flex', gap:10}}>
                        <RichTextEditor editorState={editorState} setEditorState={setEditorState}/>
                    </div>
                    <div style={{display:'flex', width:'100%', justifyContent:'flex-end', padding:5, gap:6}}>
                        <CustomButton isLoading={isSubmitting} label={notification?.uid ? 'Save Changes' : 'Submit'} onClick={handleSubmit}/>
                    </div>
                </div>
            </Modal.Body>
        </>
    )
}