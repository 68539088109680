/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from "./serviceTools";

export async function matchSettlementReportEmployees(employees){
    const data = {
        type:'reports',
        target:'matchSettlementReportEmployees',
        authToken:getToken(),
        data:employees
    }

    // const response = await getResponse(data);
    // return response;
}

export async function submitSettlementReport(report){
    const data = {
        type:'reports',
        target:'submitSettlementReport',
        authToken:getToken(),
        data:report
    }

    // const response = await getResponse(data);
    // return response;
}
export async function getAllSettlementReports() {
    const data = {
        type: 'reports',
        target: 'getAllSettlementReports',
        authToken: getToken(),
    }
    // return await getResponse(data);
}

export async function getSettlementReport(uid) {
    const data = {
        type: 'reports',
        target: 'getSettlementReport',
        authToken: getToken(),
        uid: uid
    }
    // return await getResponse(data);
}


export async function getAllEmployeesAndCsas() {
    const data = {
        type: 'reports',
        target: 'getAllEmployeesAndCsas',
        authToken: getToken(),
    }
    // return await getResponse(data);
}
