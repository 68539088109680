/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";

import CustomControl from "../../../components/CustomControl";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from 'react-bootstrap/Container';
import AddressControl from "../../../components/AddressControl";
import RadioControl from "../../../components/RadioControl";
import AvailabilityControl from "../../../components/AvailabilityControl";
import QuickTable from "../../../components/QuickTable";
import { Button } from "react-bootstrap";
import { getFakeUID } from "../../../fake-data";
import { addressToString } from "../../../tools";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faX } from "@fortawesome/free-solid-svg-icons";
import { packetIdentifierDictionary } from "../../../tools";


export default function CreateCompanyCsas({csas, handleAddCsa, handleRemoveCsa}) {


    const [csaName, setCsaName] = useState('');
    const [address, setAddress] = useState({});
    const [clockInRadius, setClockInRadius] = useState('');
    const [packetIdentifier, setPacketIdentifier] = useState('');


    const csaRows = csas.map((csa) => {
        return (
            <tr key={csa.uid}>
                <td>{csa.csaName}</td>
                <td>{addressToString(csa.address)}</td>
                <td>{csa.clockInRadius}</td>
                <td style={{width: 0}}>
                    <Button onClick={() => {handleRemoveCsa(csa.uid)}}>
                        <FontAwesomeIcon icon={faX}/>
                    </Button>
                </td>
            </tr>
        )
    })

    const clearForm = () => {
        setCsaName('');
        setAddress({});
        setClockInRadius('');
    }
    
    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: 8}}>
            <QuickTable headers={['Name', 'Address', 'Clock-In Radius', '']} rows={csaRows}/>
            <FontAwesomeIcon icon={faArrowUp} size='2x'/>
            <Container fluid style={{border: 'solid 1px lightgray', padding: 12, borderRadius: 6}}>
                <Row>
                    <CustomControl md={4} type='text' title='Name' max={50} value={csaName} onChange={(event) => {setCsaName(event.target.value)}}/>
                    <CustomControl md={4} type='number' min={0.5} step={0.1} max={10} title='Clock-In Radius (in miles)' value={clockInRadius} onChange={(event) => {setClockInRadius(event.target.value)}}/>
                    <RadioControl md={4} title={'Packet Identifier'} selection={packetIdentifier} setSelection={setPacketIdentifier} optionValues={Object.keys(packetIdentifierDictionary)} optionNames={Object.values(packetIdentifierDictionary)}/>
                    <AddressControl md={4} title='Address' address={address} setAddress={setAddress}/>
                </Row>
                <Button disabled={!csaName || !address || !packetIdentifier} onClick={() => {handleAddCsa({uid: getFakeUID(), csaName: csaName, address: address, clockInRadius: clockInRadius, packetIdentifier: packetIdentifier}); clearForm()}}>Add CSA</Button>
            </Container>
        </div>
    )
}