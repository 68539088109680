/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from "../serviceTools";
import { timeout } from "../../tools";

export async function getCompanies() {
    var data = {
        type: 'company',
        target: 'getCompanies',
        authToken: getToken(),
    }
    return await getResponse(data);
}

export async function getCompanyUsers(companyIdentifier) {
    var data = {
        type: 'company',
        target: 'getCompanyUsers',
        authToken: getToken(),
        companyIdentifier: companyIdentifier
    }
    return await getResponse(data);
}

export async function getCompanyResources(companyIdentifier) {
    var data = {
        type: 'company',
        target: 'getCompanyResources',
        authToken: getToken(),
        companyIdentifier: companyIdentifier,
    }

    const response = await getResponse(data);
    return response;
}

export async function onboardCompany(userData, csaData, workAreaData, vehicleData, companyData) {
    var data = {
        type: 'company',
        target: 'createCompany',
        authToken: getToken(),
        userData: userData,
        csaData: csaData,
        workAreaData: workAreaData,
        vehicleData: vehicleData,
        companyData: companyData,
    }

    const response = await getResponse(data);
    return response;
}

export async function disableCompany(uid){
    var data = {
        type:'company',
        target:'disableCompany',
        authToken:getToken(),
        uid:uid
    }

    const response = await getResponse(data);
    return response;
}

export async function enableCompany(uid){
    var data = {
        type:'company',
        target:'enableCompany',
        authToken:getToken(),
        uid:uid
    }

    const response = await getResponse(data);
    return response;
}

export async function resetEmployeePasswords(companyIdentifier, userIdentifiers) {
    var data = {
        type: 'company',
        target: 'resetEmployeePasswords',
        authToken: getToken(),
        companyIdentifier: companyIdentifier,
        userIdentifiers: userIdentifiers
    }

    return await getResponse(data);
}