/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import { ValidationGroup, validateExistence } from '../../../validation';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import CustomButton from '../../../components/CustomButton';
import HRUserSchedulePTODaySelector from './HRUserSchedulePTODaySelector';
import HRTimeOffModal from './HRTimeOffModal';
import CustomDatePicker from '../../../components/CustomDatePicker/CustomDatePicker';
import PageSpinner from '../../../components/PageSpinner';
import { getEmployeeFutureTimeOff } from '../../../services/HumanResourcesService';
import TextAreaControl from '../../../components/TextAreaControl';

export default function HRScheduleTimeOffCreator({userIdentifier, handleCreateTimeOff}) {
    const [isLoading, setIsLoading] = useState(false);

    const [timeOff, setTimeOff] = useState([]);

    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);
    const [notes, setNotes] = useState('');

    const [showPtoSelector, setShowPtoSelector] = useState(false);
    const [showTimeOffModal, setShowTimeOffModal] = useState(false);
   

    const validationGroup = new ValidationGroup();


    const loadData = async () => {
        setIsLoading(true);
        const response = await getEmployeeFutureTimeOff(userIdentifier);
        if (response.status === 200) {
            setTimeOff(response.timeOff);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadData();
    }, []);


    const disabledDates = timeOff.reduce((prev, curr) => {
        const dates = [];
        const numberOfDays = moment(curr.endDate).diff(curr.startDate, 'days') + 1;
        for (let i = 0; i < numberOfDays; i++) {
            dates.push(moment(curr.startDate).add(i, 'days').format('YYYY-MM-DD'));
        }
        return prev.concat(dates);
    }, []);


    const newTimeOff = {
        uid: null,
        userIdentifier: userIdentifier,
        startDate: startDate,
        endDate: endDate,
        notes: notes
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Schedule Time Off</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { isLoading ? <PageSpinner/> :
                    <>
                        <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: 12}}>
                            <Button variant='outline-primary' onClick={() => {setShowTimeOffModal(true)}}>View Upcoming Pending & Accepted Time Off</Button>
                        </div>
                        <CustomDatePicker 
                            validator={validationGroup.createValidator(() => validateExistence(endDate))} 
                            title={'Date Range'} 
                            startDate={startDate} 
                            setStartDate={setStartDate} 
                            endDate={endDate} 
                            setEndDate={setEndDate} 
                            disabledDates={disabledDates} 
                        />
                        <TextAreaControl 
                            value={notes} 
                            setValue={setNotes} 
                            rows={6}
                            label='Reason'
                            validator={validationGroup.createValidator(() => validateExistence(notes))}
                        />
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <CustomButton onClick={() => {setShowPtoSelector(true)}} disabled={!validationGroup.isValid()} label={'Select PTO Days'}/>
            </Modal.Footer>
            <Modal show={showPtoSelector} onHide={()=>setShowPtoSelector(false)}>
                <HRUserSchedulePTODaySelector selectedTimeOff={newTimeOff} handleCreatePto={(ptoArray, newTimeOff) => {setShowPtoSelector(false); handleCreateTimeOff(ptoArray, newTimeOff);}}/>
            </Modal>
            <Modal show={showTimeOffModal} onHide={() => {setShowTimeOffModal(false)}} size='lg'>
                <HRTimeOffModal/>
            </Modal>
        </>
    )
}
