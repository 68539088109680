/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import fakeAddresses from "./fakeAddresses";

const companyNames = [
    'Leffler-Hill',
    'Heathcote, Schumm and Rippin',
    'Nikolaus, Raynor and Kovacek',
    'Walsh Group',
    'Ratke-McClure',
    'Okuneva-Yundt',
    'Kassulke-Sawayn',
    'Skiles-Dickinson',
    'Hayes, Schmitt and Gerlach',
    'Ruecker-Kilback'
]

const fakeCompanies = [];

for (let i = 0; i < companyNames.length; i++) {    
    fakeCompanies.push({
        uid: i + 1, 
        companyName: companyNames[i],
        fedexUsername: companyNames[i].toLocaleUpperCase(),
        fedexPassword: 'passwordforfedex',
        wcUsername: companyNames[i].toLocaleUpperCase() + 'WC',
        wcPassword: 'passwordforwc',
        phoneNumber: '1234567890',
        addressIdentifier: fakeAddresses[i % fakeAddresses.length].uid,
        benefits: 'No benefits',
        forceMfa: (i % 2).toString(),
        forceLocation: (i % 2).toString(),
        companyAddressAllowClockIn: (i % 2).toString(),
        companyAddressClockInRadius: '',
        enableGeofencing: (i % 2).toString(),
        allowBCAccessToSensitiveInformation: (i % 2).toString(),
        isEnabled: i === 9 ? '0' : '1'
    });
}

export default fakeCompanies;