/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { fakeCompanies, fakeCompanyUsers, fakePay, getFakeUID, fakeHRUserSafetyData, fakeMyScheduleData, fakeVehicles, fakeWorkAreas, fakeCsas, fakeHRUserBWCData, fakeAddresses, } from "../../fake-data";
import fakeUsers from "../../fakeData/fakeUsers";
import { getResponse, getToken } from "../serviceTools";
import { timeout } from "../../tools";
import moment from "moment";

export async function getHRUserSafetyRecords(userID) {
    var data = {
        type: 'REPLACE',
        authToken: getToken(),
        userID: userID
    }

    const response = await getResponse(data);


    const returnObject = {
        accidents: JSON.parse(JSON.stringify(fakeHRUserSafetyData.accidents.map(a => a.versions[a.versions.length - 1]))),
        incidents: JSON.parse(JSON.stringify(fakeHRUserSafetyData.incidents.map(a => a.versions[a.versions.length - 1]))),
        rideAlongs: JSON.parse(JSON.stringify(fakeHRUserSafetyData.rideAlongs.map(a => a.versions[a.versions.length - 1]))),
        movingViolations: JSON.parse(JSON.stringify(fakeHRUserSafetyData.movingViolations.map(a => a.versions[a.versions.length - 1])))
    }

    return {
        status: '200',
        data: returnObject
    };

    return response;
}

export async function getAccidentRecord(uid) {
    var data = {
        type: 'REPLACE',
        authToken: getToken(),
        uid: uid
    }

    const response = await getResponse(data);

    return {
        status: '200',
        data: JSON.parse(JSON.stringify(fakeHRUserSafetyData.accidents.find(a => a.uid === uid)))
    };

    return response;
}

export async function getIncidentRecord(uid) {
    var data = {
        type: 'REPLACE',
        authToken: getToken(),
        uid: uid
    }

    const response = await getResponse(data);

    return {
        status: '200',
        data: JSON.parse(JSON.stringify(fakeHRUserSafetyData.incidents.find(a => a.uid === uid)))
    };

    return response;
}

export async function getRideAlongRecord(uid) {
    var data = {
        type: 'REPLACE',
        authToken: getToken(),
        uid: uid
    }

    const response = await getResponse(data);

    return {
        status: '200',
        data: JSON.parse(JSON.stringify(fakeHRUserSafetyData.rideAlongs.find(a => a.uid === uid)))
    };

    return response;
}

export async function getMovingViolationRecord(uid) {
    var data = {
        type: 'REPLACE',
        authToken: getToken(),
        uid: uid
    }

    const response = await getResponse(data);

    return {
        status: '200',
        data: JSON.parse(JSON.stringify(fakeHRUserSafetyData.movingViolations.find(a => a.uid === uid)))
    };

    return response;
}

export async function createAccidentRecord(record) {
    var data = {
        type: 'REPLACE',
        authToken: getToken(),
        record: record
    }

    const response = await getResponse(data);
    const versionID = getFakeUID();
    const uid = getFakeUID();
    record.uid = versionID;
    record.accidentID = uid;
    record.author = 1;
    record.submissionDateTime = moment().format('YYYY-MM-DD HH:mm');

    fakeHRUserSafetyData.accidents.push({
        uid: uid,
        userID: record.userID,
        versions: [record]
    });

    return {
        status: '200',
        uid: uid,
        versionID: versionID
    };

    return response;
}

export async function createIncidentRecord(record) {
    var data = {
        type: 'REPLACE',
        authToken: getToken(),
        record: record
    }

    const response = await getResponse(data);
    const versionID = getFakeUID();
    const uid = getFakeUID();
    record.uid = versionID;
    record.incidentID = uid;
    record.author = 1;
    record.submissionDateTime = moment().format('YYYY-MM-DD HH:mm');

    fakeHRUserSafetyData.incidents.push({
        uid: uid,
        userID: record.userID,
        versions: [record]
    });

    return {
        status: '200',
        uid: uid,
        versionID: versionID
    };

    return response;
}

export async function createRideAlongRecord(record) {
    var data = {
        type: 'REPLACE',
        authToken: getToken(),
        record: record
    }

    const response = await getResponse(data);
    const versionID = getFakeUID();
    const uid = getFakeUID();
    record.uid = versionID;
    record.rideAlongID = uid;
    record.author = 1;
    record.submissionDateTime = moment().format('YYYY-MM-DD HH:mm');

    fakeHRUserSafetyData.rideAlongs.push({
        uid: uid,
        userID: record.userID,
        versions: [record]
    });

    return {
        status: '200',
        uid: uid,
        versionID: versionID
    };

    return response;
}

export async function createMovingViolationRecord(record) {
    var data = {
        type: 'REPLACE',
        authToken: getToken(),
        record: record
    }

    const response = await getResponse(data);
    const versionID = getFakeUID();
    const uid = getFakeUID();
    record.uid = versionID;
    record.movingViolationID = uid;
    record.author = 1;
    record.submissionDateTime = moment().format('YYYY-MM-DD HH:mm');

    fakeHRUserSafetyData.movingViolations.push({
        uid: uid,
        userID: record.userID,
        versions: [record]
    });

    return {
        status: '200',
        uid: uid,
        versionID: versionID
    };

    return response;
}

export async function reviseAccidentRecord(record) {
    var data = {
        type: 'REPLACE',
        authToken: getToken(),
        record: record
    }

    const response = await getResponse(data);

    record.uid = getFakeUID();
    record.author = 1;
    record.submissionDateTime = moment().format('YYYY-MM-DD HH:mm');
    fakeHRUserSafetyData.accidents.find(a => a.uid === record.accidentID).versions.push(record);

    return {
        status: '200',
        uid: record.uid
    };

    return response;
}

export async function reviseIncidentRecord(record) {
    var data = {
        type: 'REPLACE',
        authToken: getToken(),
        record: record
    }

    const response = await getResponse(data);

    record.uid = getFakeUID();
    record.author = 1;
    record.submissionDateTime = moment().format('YYYY-MM-DD HH:mm');
    fakeHRUserSafetyData.incidents.find(a => a.uid === record.incidentID).versions.push(record);

    return {
        status: '200',
        uid: record.uid
    };

    return response;
}

export async function reviseRideAlongRecord(record) {
    var data = {
        type: 'REPLACE',
        authToken: getToken(),
        record: record
    }

    const response = await getResponse(data);

    record.uid = getFakeUID();
    record.author = 1;
    record.submissionDateTime = moment().format('YYYY-MM-DD HH:mm');
    fakeHRUserSafetyData.rideAlongs.find(a => a.uid === record.rideAlongID).versions.push(record);

    return {
        status: '200',
        uid: record.uid
    };

    return response;
}

export async function reviseMovingViolationRecord(record) {
    var data = {
        type: 'REPLACE',
        authToken: getToken(),
        record: record
    }

    const response = await getResponse(data);

    record.uid = getFakeUID();
    record.author = 1;
    record.submissionDateTime = moment().format('YYYY-MM-DD HH:mm');
    fakeHRUserSafetyData.movingViolations.find(a => a.uid === record.movingViolationID).versions.push(record);

    return {
        status: '200',
        uid: record.uid
    };

    return response;
}