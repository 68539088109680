/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useState } from "react";
import { AppGlobals } from "../../App";
import { migrateCsa, migrateCsaResources } from "../../services/AdminToolsService";
import { Button, Card, Form, Modal } from "react-bootstrap";
import DropdownControl from "../../components/DropdownControl";
import CustomButton from "../../components/CustomButton";

export default function MigrateCsa({companies, csas}){

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [actionType, setActionType] = useState('migrateResources')
    const [migrateType, setMigrateType] = useState('add')
    
    const [sendingCompany, setSendingCompany] = useState(null);
    const [receivingCompany, setReceivingCompany] = useState(null)
    const [sendingCsa, setSendingCsa] = useState(null);
    const [receivingCsa, setReceivingCsa] = useState(null);

    async function handleMigrateCsaResources(){
        setIsSubmitting(true);
        const response = await migrateCsaResources(sendingCsa, receivingCsa, migrateType)
        if(response.status == 200){
            AppGlobals.alert('success', response.message);
            setShowModal(false)
        }else{
            AppGlobals.alert('danger', response.message);
        }
        setIsSubmitting(false)
    }

    async function handleMigrateCsa(){
        setIsSubmitting(true);
        const response = await migrateCsa(sendingCsa, receivingCompany)
        if(response.status == 200){
            AppGlobals.alert('success', response.message);
            setShowModal(false)
        }else{
            AppGlobals.alert('danger', response.message);
        }
        setIsSubmitting(false)
    }

    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title>Migrate CSA Resources or Add CSA To Another Company</Card.Title>
                </Card.Header>
                <Card.Body>
                    <div style={{display: 'flex', justifyContent: 'center', gap: 16, padding: 8, borderRadius: 6, marginBottom: 18}}>
                        <Form.Check style={{fontSize: 20}} type='radio' label='Migrate CSA Resources' checked={actionType === 'migrateResources'} onChange={()=>setActionType('migrateResources')}/>
                        <Form.Check style={{fontSize: 20}} type='radio' label='Add CSA To Another Company' checked={actionType === 'add'} onChange={()=>setActionType('add')}/>
                    </div>
                    {actionType === 'migrateResources' && <div style={{display: 'flex', gap: 12, justifyContent: 'end'}}>
                        <Form.Check type='radio' label='Add' checked={migrateType === 'add'} onChange={()=>setMigrateType('add')}/>
                        <Form.Check type='radio' label='Replace' checked={migrateType === 'replace'} onChange={()=>setMigrateType('replace')}/>
                    </div>
                    }
                    <DropdownControl title='Sending Company:' selection={sendingCompany} setSelection={(company)=>{setSendingCompany(company); setSendingCsa(null)}} itemNames={companies.map(c=>c.companyName)} itemValues={companies.map(c=>c.uid)}/>
                    { sendingCompany && <DropdownControl title='Sending Company CSA:' selection={sendingCsa} setSelection={setSendingCsa} itemNames={csas.filter(csa=>csa.companyIdentifier == sendingCompany).map(csa=>csa.csaName)} itemValues={csas.filter(csa=>csa.companyIdentifier == sendingCompany).map(csa=>csa.uid)}/>}
                    <div style={{marginBottom: 32}}></div>
                    {sendingCsa && <DropdownControl title='Receving Company:' selection={receivingCompany} setSelection={(company)=>{setReceivingCompany(company); setReceivingCsa(null)}} itemNames={actionType === 'add' ? companies.filter(company=>sendingCompany !== company.uid).map(c=>c.companyName) : companies.map(c=>c.companyName)} itemValues={actionType === 'add' ? companies.filter(company=>sendingCompany !== company.uid).map(c=>c.uid) : companies.map(c=>c.uid)}/>}
                    { receivingCompany && actionType === 'migrateResources' && <DropdownControl title='Receiving Company CSA:' selection={receivingCsa} setSelection={setReceivingCsa} itemNames={csas.filter(csa=>(csa.companyIdentifier == receivingCompany && csa.uid !== sendingCsa)).map(csa=>csa.csaName)} itemValues={csas.filter(csa=>csa.companyIdentifier == receivingCompany && csa.uid !== sendingCsa).map(csa=>csa.uid)}/>}
                </Card.Body>
                <Card.Footer>
                    <Button disabled={actionType === 'migrateResources' ? (!sendingCompany || !sendingCsa || !receivingCompany || !receivingCsa) : (!sendingCompany || !sendingCsa || !receivingCompany)} onClick={()=>setShowModal(true)} style={{display: 'block', marginLeft: 'auto'}}>
                        Migrate Resources
                    </Button>
                </Card.Footer>
            </Card>
            <Modal show={showModal} onHide={()=>{setShowModal(false)}}>
                <Modal.Header>
                    <Modal.Title>Confirm {actionType === 'add' ? 'Add CSA' : 'Migrate CSA Resources'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {actionType === 'add' ?
                        <p>Adding CSA: <strong>{csas.find(csa=>csa.uid === sendingCsa)?.csaName}</strong> and its resources to Company: <strong>{companies.find(company=>company.uid == receivingCompany)?.companyName}</strong></p>
                    :
                        <p>{migrateType === 'replace' ? 'Replacing' : 'Adding'} resources in CSA: <strong>{csas.find(csa=>csa.uid == receivingCsa)?.csaName}</strong> with CSA: <strong>{csas.find(csa=>csa.uid === sendingCsa)?.csaName}</strong></p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton isLoading={isSubmitting} label={actionType === 'add' ? 'Add' : 'Migrate'} onClick={actionType === 'migrateResources' ? handleMigrateCsaResources : handleMigrateCsa}/>
                </Modal.Footer>
            </Modal>
        </>
    )
}