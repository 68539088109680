/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import moment from 'moment';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { addressToString } from '../../../tools';
import KeyValueRow from '../../../components/KeyValueRow';
import { getBWCRecord } from '../../../services/HRServices/HRBWCService';
import HRUserSafetyRecordVersionHeader from '../Safety/HRUserSafetyRecordVersionHeader';

class HRUserBWCDetails extends React.Component {
    constructor(props) { //users reviseRecord bwcRecord
        super(props);

        this.state = {
            isLoading: true,
            versions: [props.bwcRecord],
            selectedVersion: props.bwcRecord,
            showForm: false,
        }

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }


    loadData() {
        if (!this.state.isLoading) {
            this.setState({isLoading: true});
        }
        getBWCRecord(this.props.bwcRecord.bwcRecordsIdentifier).then((response) => {
            if (response && response.status === '200') {
                response.data.forEach((item) => {
                    if (item.treatmentFacilityAddress) {
                        item.treatmentFacilityAddress = response.addresses.find(r => r.uid === item.treatmentFacilityAddress);
                    } else {
                        item.treatmentFacilityAddress = {};
                    }
                });

                const versions = response.data.sort((a, b) => {
                    if (moment(a.submissionDateTime).isBefore(moment(b.submissionDateTime), 'second')) {
                        return 1;
                    } else if (moment(a.submissionDateTime).isAfter(moment(b.submissionDateTime), 'second')) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
                this.setState({versions: versions, selectedVersion: versions[0]});
            }

            this.setState({isLoading: false});
        });
    }

    handleFormSubmit() {
        this.loadData();
        this.props.loadData();
    }
    

    componentDidMount() {
        this.loadData();
    }

    render() {
        const reportedTo = this.state.selectedVersion ? this.props.users.find(u => u.companyUserUid === this.state.selectedVersion.injuryReportedTo): undefined;

        const content = !this.state.selectedVersion ? '' : (
            <>
                <Modal.Header>
                    <Modal.Title>BWC Record</Modal.Title>
                    <Button style={{color: 'white', marginLeft: 12}} onClick={this.props.reviseRecord}>Revise Record</Button>
                </Modal.Header>
                <HRUserSafetyRecordVersionHeader 
                    users={this.props.users} 
                    versions={this.state.versions} 
                    selectedVersion={this.state.selectedVersion} 
                    setSelectedVersion={(value) => {this.setState({selectedVersion: value})}} 
                    isLoading={this.state.isLoading}
                />
                <Modal.Body>
                    <ListGroup>
                        <KeyValueRow isListItem title={'Date of Injury'} value={this.state.selectedVersion.dateOfInjury ? moment(this.state.selectedVersion.dateOfInjury).format('MMM D, YYYY') : ''}/>
                        <KeyValueRow isListItem title={'Injury Reported to'} value={reportedTo ? reportedTo.firstName + ' ' + reportedTo.lastName : ''}/>
                        <KeyValueRow isListItem title={'Date Reported'} value={this.state.selectedVersion.injuryReportDate ? moment(this.state.selectedVersion.injuryReportDate).format('MMM D, YYYY') : ''}/>
                        <KeyValueRow isListItem title={'Treatment Facility Name'} value={this.state.selectedVersion.treatmentFacilityName}/>
                        <KeyValueRow isListItem title={'Treatment Facility Address'} value={addressToString(this.state.selectedVersion.treatmentFacilityAddress)}/>
                        <KeyValueRow isListItem title={'Date & Time Admitted to Facility'} value={this.state.selectedVersion.treatmentFacilityAdmittedDateTime ? moment(this.state.selectedVersion.treatmentFacilityAdmittedDateTime).format('MMM D, YYYY [at] h:mm A') : ''}/>
                        <KeyValueRow isListItem title={'Salary Continuation?'} value={this.state.selectedVersion.salaryContinuation ? 'Yes' : 'No'}/>
                        <KeyValueRow isListItem title={'Date Returned to Work for Light Duty'} value={this.state.selectedVersion.rtwLightDutyDate ? moment(this.state.selectedVersion.rtwLightDutyDate).format('MMM D, YYYY') : ''}/>
                        <ListGroup.Item>
                            <p>Description of Light Duty</p>
                            <p style={{marginBottom: 0, opacity: 0.5}}>{this.state.selectedVersion.lightDutyDescription}</p>
                        </ListGroup.Item>
                        <KeyValueRow isListItem title={'Date Returned to Work for Full Duty'} value={this.state.selectedVersion.rtwFullDutyDate ? moment(this.state.selectedVersion.rtwFullDutyDate).format('MMM D, YYYY') : ''}/>
                        <KeyValueRow isListItem title={'Number of Days Missed'} value={this.state.selectedVersion.numberOfDaysMissed}/>
                        <KeyValueRow isListItem title={'BWC Injury Claim #'} value={this.state.selectedVersion.bwcInjuryClaimNumber}/>
                        <ListGroup.Item>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <span>Physician's Report of Work Ability (MEDCO-14)</span>
                                <Button style={{color: 'white', paddingTop: 0, paddingBottom: 0}}>Click To Download</Button>
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <span>Salary Continuation Agreement (C-55)</span>
                                <Button style={{color: 'white', paddingTop: 0, paddingBottom: 0}}>Click To Download</Button>
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <span>Other Attachments</span>
                                <Button style={{color: 'white', paddingTop: 0, paddingBottom: 0}}>Click To Download</Button>
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <p>Notes</p>
                            <p style={{marginBottom: 0, opacity: 0.5}}>{this.state.selectedVersion.notes}</p>
                        </ListGroup.Item>
                    </ListGroup>
                </Modal.Body>
            </>
        )

        return (
            <>
                {content}
            </>
        )
    }

}

export default HRUserBWCDetails;