
/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomControl from '../../../components/CustomControl';
import AddressControl from '../../../components/AddressControl';
import { reviseIncidentRecord, createIncidentRecord } from '../../../services/HRServices/HRSafetyService';
import CustomButton from '../../../components/CustomButton';
import TextAreaControl from '../../../components/TextAreaControl';


function HRUserSafetyIncidentForm({users, selectedSafetyRecord, handleAddRecord, handleReviseRecord}) {

    const [dateTime, setDateTime] = useState(selectedSafetyRecord ? selectedSafetyRecord.dateTime : moment().format('YYYY-MM-DD HH:mm'));
    const [address, setAddress] = useState(selectedSafetyRecord ? selectedSafetyRecord.address : {thoroughfare: '', premise: '', administrativeArea: '', locality: '', postalCode: ''});
    const [cost, setCost] = useState(selectedSafetyRecord ? selectedSafetyRecord.cost : 0);
    const [notes, setNotes] = useState(selectedSafetyRecord ? selectedSafetyRecord.notes : '');
    const [propertyOwnerContacted, setPropertyOwnerContacted] = useState(selectedSafetyRecord ? selectedSafetyRecord.propertyOwnerContacted : false);
    const [propertyOwnerFirstName, setPropertyOwnerFirstName] = useState(selectedSafetyRecord ? selectedSafetyRecord.propertyOwnerFirstName : '');
    const [propertyOwnerLastName, setPropertyOwnerLastName] = useState(selectedSafetyRecord ? selectedSafetyRecord.propertyOwnerLastName : '');
    const [propertyOwnerEmail, setPropertyOwnerEmail] = useState(selectedSafetyRecord ? selectedSafetyRecord.propertyOwnerEmail : '');
    const [propertyOwnerPhoneNumber, setPropertyOwnerPhoneNumber] = useState(selectedSafetyRecord ? selectedSafetyRecord.propertyOwnerPhoneNumber : '');
    const [propertyOwnerAddress, setPropertyOwnerAddress] = useState(selectedSafetyRecord ? selectedSafetyRecord.propertyOwnerAddress : {thoroughfare: '', premise: '', administrativeArea: '', locality: '', postalCode: ''});
    const [propertyOwnerInsuranceCompany, setPropertyOwnerInsuranceCompany] = useState(selectedSafetyRecord ? selectedSafetyRecord.propertyOwnerInsuranceCompany : '');

    const [confirmSubmission, setConfirmSubmission] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    // attachments
    // author
    // submissionDate

    const handleSubmit = () => {
        setIsLoading(true);
        setConfirmSubmission(false);
        const newIncident = {
            incidentID: selectedSafetyRecord ? selectedSafetyRecord.incidentID : undefined,
            dateTime: dateTime,
            address: address,
            cost: cost,
            notes: notes,
            propertyOwnerContacted: propertyOwnerContacted,
            propertyOwnerFirstName: propertyOwnerFirstName,
            propertyOwnerLastName: propertyOwnerLastName,
            propertyOwnerEmail: propertyOwnerEmail,
            propertyOwnerPhoneNumber: propertyOwnerPhoneNumber,
            propertyOwnerAddress: propertyOwnerAddress,
            propertyOwnerInsuranceCompany: propertyOwnerInsuranceCompany,
        }
        if (selectedSafetyRecord) {
            reviseIncidentRecord(newIncident).then((response) => {
                if (response && response.status === '200') {
                    newIncident.uid = response.uid;
                    handleReviseRecord(newIncident, 'Incident');
                }
                setIsLoading(false);
            });
        } else {
            createIncidentRecord(newIncident).then((response) => {
                if (response && response.status === '200') {
                    newIncident.incidentID = response.uid;
                    newIncident.uid = response.versionID;
                    handleAddRecord(newIncident);
                }
                setIsLoading(false);
            })
        }
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{selectedSafetyRecord ? 'Revising Incident' : 'Creating New Incident Report'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <CustomControl disabled={isLoading} lg={4} originalValue={selectedSafetyRecord ? selectedSafetyRecord.dateTime : undefined} type='datetime-local' title='Date & Time' value={dateTime} onChange={(event) => {setDateTime(event.target.value)}}/>
                        <CustomControl disabled={isLoading} lg={4} originalValue={selectedSafetyRecord ? selectedSafetyRecord.cost : undefined} type='number' title='Total Cost' value={cost} onChange={(event) => {setCost(event.target.value)}}/>

                        <Col xxl={12} style={{marginBottom: 8}}>
                            <AddressControl disabled={isLoading} originalValue={selectedSafetyRecord ? selectedSafetyRecord.address : undefined} title='Address' address={address} setAddress={setAddress} lg={6} xl={4}/>
                        </Col>

                        <Col xxl={12} style={{marginBottom: 8}}>
                            <Container style={{border: '1px lightgray solid', borderRadius: 6, paddingBottom: 12}}>
                                <Row>
                                    <Col xxl={12}>
                                        <Form.Check disabled={isLoading} style={{margin: 12}} type='switch' label='Property Owner Contacted?' checked={propertyOwnerContacted} onChange={() => {setPropertyOwnerContacted(!propertyOwnerContacted)}}/>
                                    </Col>
                                    {!propertyOwnerContacted ? '' : 
                                        <>
                                            <CustomControl disabled={isLoading} originalValue={selectedSafetyRecord ? selectedSafetyRecord.propertyOwnerFirstName : undefined} lg={4} type='text' title='First Name' value={propertyOwnerFirstName} onChange={(event) => {setPropertyOwnerFirstName(event.target.value)}}/>
                                            <CustomControl disabled={isLoading} originalValue={selectedSafetyRecord ? selectedSafetyRecord.propertyOwnerLastName : undefined} lg={4} type='text' title='Last Name' value={propertyOwnerLastName} onChange={(event) => {setPropertyOwnerLastName(event.target.value)}}/>
                                            <CustomControl disabled={isLoading} originalValue={selectedSafetyRecord ? selectedSafetyRecord.propertyOwnerEmail : undefined} lg={4} type='text' title='Email Address' value={propertyOwnerEmail} onChange={(event) => {setPropertyOwnerEmail(event.target.value)}}/>
                                            <CustomControl disabled={isLoading} originalValue={selectedSafetyRecord ? selectedSafetyRecord.propertyOwnerPhoneNumber : undefined} lg={6} type='text' title='Phone Number' value={propertyOwnerPhoneNumber} onChange={(event) => {setPropertyOwnerPhoneNumber(event.target.value)}}/>
                                            <CustomControl disabled={isLoading} originalValue={selectedSafetyRecord ? selectedSafetyRecord.propertyOwnerInsuranceCompany : undefined} lg={6} type='text' title='Insurance Company Name' value={propertyOwnerInsuranceCompany} onChange={(event) => {setPropertyOwnerInsuranceCompany(event.target.value)}}/>
                                            <AddressControl disabled={isLoading} originalValue={selectedSafetyRecord ? selectedSafetyRecord.propertyOwnerAddress : undefined} title='Address of Property Owner' address={propertyOwnerAddress} setAddress={setPropertyOwnerAddress} lg={4}/>
                                        </>
                                    }
                                </Row>
                            </Container>
                        </Col>
                        <Col lg={12} style={{marginBottom: 8}}>
                            <Form.Group>
                                <Form.Label>Attachments</Form.Label>  
                                <Form.Control disabled={isLoading} type='file' multiple/>
                            </Form.Group>
                        </Col>

                        <Col xxl={12} style={{marginBottom: 8}}>
                            <TextAreaControl label='Notes' originalValue={selectedSafetyRecord ? selectedSafetyRecord.notes : undefined} disabled={isLoading} rows={4} value={notes} setValue={setNotes}/>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton label='Submit' isLoading={isLoading} onClick={() => {setConfirmSubmission(true)}}/>
            </Modal.Footer>
            <Modal show={confirmSubmission} centered onHide={() => {setConfirmSubmission(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Submission</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Once submitted, the entered information will become permanent record. Proceed?
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{color: 'white'}} onClick={() => {setConfirmSubmission(false)}}>Cancel</Button>
                    <Button style={{color: 'white'}} onClick={handleSubmit}>Confirm Submission</Button>
                </Modal.Footer>
            </Modal>

        </>
    )
    
}

//NEW HIRE, SAFETY, SERVICE, CUSTOMER SERVICE, PRODUCTIVITY


export default HRUserSafetyIncidentForm;

