/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';

import Spinner from 'react-bootstrap/Spinner';

function CustomSpinner({width, height, spinnerWidth, spinnerHeight}) {

    return (
        
        <div style={{width: width, height: height, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 12}}>
            <Spinner style={{width: spinnerWidth, height: spinnerHeight}}/>
        </div>
        
    )
}

export default CustomSpinner;