/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/


import { getResponse, getToken } from "../serviceTools";

export async function getUserPermissions(companyUserIdentifier) {
    var data = {
        type: 'hr',
        target: 'getPermissions',
        authToken: getToken(),
        companyUserIdentifier: companyUserIdentifier
    }

    const response = await getResponse(data);

    return response;
}

export async function updateUserPermissions(permissionsIdentifier, permissions) {
    var data = {
        type: 'hr',
        target: 'updatePermissions',
        authToken: getToken(),
        permissionsIdentifier: permissionsIdentifier,
        data: permissions
    }
    
    const response = await getResponse(data);

    return response && response.status === '200';
}