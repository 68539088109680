/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import { getEmployeeNotes } from '../../../services/HRServices/HRNotesService';
import moment from 'moment';
import Card from 'react-bootstrap/Card';
import { Button, Form } from 'react-bootstrap';
import CustomSpinner from '../../../components/CustomSpinner';
import SearchBar from '../../../components/SearchBar';
import { dateIsInRange, dateRangeToString } from '../../../tools';
import DateRangeSelector from '../../../components/DateRangeSelector';
import SwitchControl from '../../../components/SwitchControl';
import { createEmployeeNote, updateEmployeeNote, deleteEmployeeNote } from '../../../services/HRServices/HRNotesService';
import CustomButton from '../../../components/CustomButton'
import Modal from 'react-bootstrap/Modal';
import AlertModal from '../../../components/AlertModals/AlertModal';

export default function HRNotesEditor({onHide, note, handleCrud, user, currentCompanyUser}) {

    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState(note ? note.title : '');
    const [body, setBody] = useState(note ? note.body : '');

    const [showDelete, setShowDelete] = useState(false);

    const handleSave = async () => {
        setIsLoading(true);
        const newNote = {
            uid: note ? note.uid : undefined,
            submissionDate: note ? note.submissionDate : undefined,
            author: currentCompanyUser,
            companyUserIdentifier: user.companyUserUid,
            title: title,
            body: body,
        }

        
        const response = await createEmployeeNote(newNote);
        if (response.status === '200') {
            newNote.uid = response.uid;
            handleCrud('create', newNote);
            onHide();
        } else if (response.status === '401') {
            window.location.reload(false);
        }
        

        setIsLoading(false);
    }

    const handleDelete = async () => {
        const response = await deleteEmployeeNote(note.uid);
        if (response.status === '200') {
            handleCrud('delete', note)
            onHide();
            return true;
        } else if (response.status === '401') {
            window.location.reload(false);
        }
        return false;
    }


    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{note ? 'Employee Note' : 'Creating Employee Note'}</Modal.Title>
                { note &&
                    <Button style={{color: 'white', marginLeft: 12}} onClick={() => {setShowDelete(true)}}>Delete Note</Button>
                }
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <Form.Group>
                    <Form.Text>Title</Form.Text>
                    <Form.Control disabled={isLoading || note} placeholder='Title' value={title} onChange={(event) => {setTitle(event.target.value)}}/>
                </Form.Group>
                <Form.Group>
                    <Form.Text>Notes</Form.Text>
                    <Form.Control disabled={isLoading || note} as='textarea' rows={12} value={body} onChange={(event) => {setBody(event.target.value)}}/>
                </Form.Group>
            </Modal.Body>
            { !note &&
                <Modal.Footer>
                    <CustomButton label='Submit' isLoading={isLoading} onClick={handleSave}/>
                </Modal.Footer>
            }
            <AlertModal show={showDelete} onHide={() => {setShowDelete(false)}} centered title='Deletion Confirmation' message='Permanently delete employee note? This action cannot be undone.' buttonLabel='Delete' callBack={handleDelete}/>
        </>
    )
}