/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import fakeCompanies from "./fakeCompanies";
import fakeAddresses from "./fakeAddresses";


const csaNames = [
    'Baton Rouge',
    'Waterbury',
    'Colorado Springs',
    'Cincinnati',
    'Chandler',
    'Metairie',
    'Cleveland',
    'Baltimore',
    'Birmingham',
    'Tulsa',
    'Reading',
    'Norfolk',
    'Montgomery',
    'Overland Park',
    'Dover',
    'Minneapolis',
    'Bellevue',
    'Topeka',
    'Lowell',
    'Milwaukee',
    'Meridian',
    'Norman',
    'Lewiston',
    'Cleveland',
    'Philadelphia',
    'Provo',
    'Aurora',
    'Duluth',
    'Spokane',
    'Cambridge',
    'Des Moines',
    'Kapolei',
    'Duluth',
    'Oklahoma City',
    'San Antonio',
    'Montpelier',
    'Allentown',
    'Pittsburgh',
    'Clarksville',
    'Boston',
    'Wilmington',
    'Bellevue',
    'Shreveport',
    'Cheyenne',
    'Lakewood',
    'Eugene',
    'Stamford',
    'Lawton',
    'Saint Paul',
    'Eugene',
    'Wilmington',
    'Portland',
    'Las Vegas',
    'Chicago',
    'Minneapolis',
    'Tulsa',
    'Columbia',
    'Reading',
    'Fairbanks',
    'Saint Louis',
    'San Diego',
    'Lafayette',
    'Juneau',
    'Springdale',
    'South Burlington',
    'Lewiston',
    'Southaven',
    'Nashville',
    'Salem',
    'Springdale',
    'Jackson',
    'Covington',
    'Columbus',
    'Norfolk',
    'Harrisburg',
    'Chicago',
    'Phoenix',
    'Dallas',
    'Kapolei',
    'Kapolei',
    'Olympia',
    'San Diego',
    'Minneapolis',
    'Butte',
    'Casper',
    'Wichita',
    'Stamford',
    'Indianapolis',
    'San Diego',
    'Wilmington',
    'Rochester',
    'Newark',
    'Frankfort',
    'Saint Paul',
    'Wilmington',
    'Olathe',
    'Hartford',
    'Bloomington',
    'Lewiston',
    'Cedar Rapids',
]

export const fakeCsas = [];

for (let i = 0; i < fakeCompanies.length * 2; i++) {
    fakeCsas.push({
        uid: i + 1,
        csaName: csaNames[i % csaNames.length],
        clockInRadius: 0.5,
        isActive: '1',
        addressIdentifier: fakeAddresses[i % fakeAddresses.length].uid,
        companyIdentifier: fakeCompanies[i % fakeCompanies.length].uid
    });
}

export const fakeVehicles = [];

for (let i = 0; i < fakeCsas.length * 5; i++) {
	fakeVehicles.push({
		uid: i + 1,
        vehicleName: 'V' + ((i + 1) * 4).toString(16),
        csaIdentifier: fakeCsas[i % fakeCsas.length].uid,
        isActive: '1',
        vehicleWeight: (8000 + (((i % fakeCsas.length) + 1) * 1500)).toString()
    });
}

export const fakeWorkAreas = [];

for (let i = 0; i < fakeCsas.length * 4; i++) {
	fakeWorkAreas.push({
		uid: i + 1,
        workAreaName: 'WA' + ((i + 1) * 7).toString(16),
        csaIdentifier: fakeCsas[i % fakeCsas.length].uid,
        isActive: '1',
    });
}