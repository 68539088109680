/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import { Card, Form } from 'react-bootstrap';
import './Login.css';
import { useState } from 'react';
import logo from '../../assets/TTA-Logo-150.png';
import React from 'react';
import { Formik} from 'formik';
import Cookies from 'universal-cookie';
import { login } from '../../services/AuthenticationService';
import MFA from '../MFA/MFA';
import Modal from 'react-bootstrap/Modal';
import CustomControl from '../../components/CustomControl';
import { ValidationGroup, validateEmail, validateExistence } from '../../validation';
import CustomButton from '../../components/CustomButton';

function Login() {

    const [showMFA, setShowMFA] = useState(false);
    const [tokenIdentifier, setTokenIdentifier] = useState(undefined);
    const [error, setError] = useState(undefined);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const validationGroup = new ValidationGroup();

    const submit = async (event) => {
        event?.preventDefault();
        setIsSubmitting(true);
        setError(undefined);
        const response = await login(email, password);
        if (response.status === '202') {
            setTokenIdentifier(response.tokenIdentifier);
            setShowMFA(true);
        } else if (response.status === '401') {
            setError('Username or password is incorrect');
        }
        setIsSubmitting(false);
    }

    return (
        <div id='login-wrapper'>
            <div id='login-background-image'></div>
            <div id='login-background-solid'></div>
            <Card id='login-card'>
                <Card.Body style={{display: 'flex', flexDirection: 'column'}}>
                    <img src={logo} style={{margin: 20}}/>
                    <div style={{height:1, width: '100%', backgroundColor: 'lightgray', marginBottom: 12}}/>
                    <form onSubmit={submit} style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                        <CustomControl validator={validationGroup.createValidator(() => validateEmail(email))} title='Email Address' value={email} onChange={(event) => {setEmail(event.target.value)}}/>
                        <CustomControl type='password' validator={validationGroup.createValidator(() => validateExistence(password))} title='Password' value={password} onChange={(event) => {setPassword(event.target.value)}}/>
                        <CustomButton type='submit' label='Login' isLoading={isSubmitting} onClick={submit}/>
                        <p style={{marginBottom: 0, color: 'red', alignSelf: 'center'}}>{error}</p>
                    </form>
                </Card.Body>
            </Card>
            <Modal show={showMFA} backdrop='static' centered size='lg'>
                <MFA tokenIdentifier={tokenIdentifier}/>
            </Modal>
        </div>
    )
}

export default Login;