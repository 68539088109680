/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import './App.css';
import React from 'react';
import { Outlet } from 'react-router-dom';
import NavigationBar from './components/NavigationBar/NavigationBar';
import TopNavigationBar from './components/TopNavigationBar/TopNavigationBar';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight, faCheck, faInfoCircle, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
import Cookies from 'universal-cookie';
import { Modal } from 'react-bootstrap';

export default class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showOffCanvas: true,
            showModal: false,
            showNotification:false,

            alertType: 'warning',
            alertTitle: '',
            showAlert: false,
        }
        this.contentRef = React.createRef();

        this.alert = this.alert.bind(this);
        this.dismissAlert = this.dismissAlert.bind(this);

        AppGlobals.alert = this.alert;
        AppGlobals.dismissAlert = this.dismissAlert;
    }

    componentDidMount() {
        const text = `Admin - ${this.props.userData.firstName} ${this.props.userData.lastName}`;

        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.innerHTML = `! function () {
                var e = window.Median = window.Median || [];
                if (!e._initialized)
                if (e._snippet_loaded) console.warn("Median Snippet loaded twice.");
                else {
                    e._snippet_loaded = !0, e._snippet_version = 3, e.methods = ["init", "identify", "endSession",
                    "on"], e.factory = function (n) {
                        return function () {
                        var t = Array.prototype.slice.call(arguments);
                        e.push([n, t])
                        }
                    };
                    for (var n = 0; n < e.methods.length; n++) {
                    var t = e.methods[n];
                    e[t] = e.factory(t)
                    }
                    var i = document.createElement("script");
                    i.type = "text/javascript", i.async = !0, i.src =
                    "https://js.hellomedian.com/v1/mdn-cobrowse-screenshare.js";
                    var a = document.getElementsByTagName("script")[0];
                    a.parentNode.insertBefore(i, a)
                }
                }();
            Median.init("f2a996e2-70ec-4c5c-90c0-944c7f5abb6b");
            Median.identify('${text}');`
        ;
        document.body.appendChild(s);
    }

    alert(type, title) {
        this.setState({alertType: type, alertTitle: title, showAlert: true});
    }

    dismissAlert() {
        this.setState({showAlert: false});
    }

    render() {   
        
        return (
            <div className="App"> {/* Navigation bar rendered second with row-reverse so that shadow renders on top of outlet */}
                <div ref={this.contentRef} className={'cwt-in'} style={{flex: 1,  height: '100vh', display: 'flex', flexDirection: 'column', position: 'relative'}}>
                    <TopNavigationBar/>
                    <div style={{height: '100vh', paddingTop: 60}}>
                        <Outlet/>
                    </div>
                    <Button style={{position: 'absolute', zIndex: 1, bottom: '45%', color: 'white', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, padding: 2, border: 'none', height: 100}} onClick={() => {this.setState({showOffCanvas: !this.state.showOffCanvas})}}>
                        <FontAwesomeIcon icon={this.state.showOffCanvas ? faCaretLeft : faCaretRight}/>
                    </Button>
                </div>
                <CSSTransition nodeRef={this.contentRef} in={this.state.showOffCanvas} timeout={250} classNames='my-node' unmountOnExit>
                    <div ref={this.contentRef}>
                        <NavigationBar/>
                    </div>
                </CSSTransition>
                <Modal show={this.state.showAlert} onHide={this.dismissAlert}>
                    <Modal.Body style={{padding: 8}}>
                        {
                            <div style={{display: 'flex', gap: 12, alignItems: 'center'}}>
                                <FontAwesomeIcon 
                                    style={{color: this.state.alertType == 'danger' ? 'red' : this.state.alertType == 'warning' ? 'gold' : this.state.alertType == 'info' ? 'var(--bs-primary)' : 'green'}} 
                                    icon={this.state.alertType == 'info' ? faInfoCircle : this.state.alertType === 'success' ? faCheck : faTriangleExclamation}
                                />
                                <div style={{flex: 1, overflow: 'hidden'}}>
                                    <span style={{overflowWrap: 'break-word', wordWrap: 'break-word'}}>{this.state.alertTitle}</span>
                                </div>
                            </div>
                        }
                        
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export class AppGlobals {
    static alert;
    static dismissAlert;
    static CookieMonster = new Cookies();
}