/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import HRUserProfile from './HRUserProfile';
import HRUserPay from './HRUserPay/HRUserPay';
import HRUserSchedule from './HRUserSchedule/HRUserSchedule';
import HRUserSafety from './Safety/HRUserSafety';
import HRUserBWC from './BWC/HRUserBWC';
import HRUserDocuments from './HRDocuments/HRUserDocuments';
import HRUserPermissions from './HRUserPermissions';
import HRUserTimesheet from './HRUserTimesheet/HRUserTimesheet';
import HRNotes from './HRNotes/HRNotes';
import UserSelector from './UserSelector';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import HRAddCompanyUser from './HRAddCompanyUser';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCalendar, faMoneyBill, faHelmetSafety, faUserInjured, faFile, faLock, faClock, faComment, faCaretRight, faChevronRight, faHandHoldingDollar, faPlane, faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import HRUserLoan from './HRUserLoan/HRUserLoan';
import HRUserPto from './HRUserPTO/HRUserPto';
import HRUserActivity from './HRUserActivity/HRUserActivity';

class HumanResources extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            windowWidth: window.innerWidth,
            selectedCompany: undefined,
            selectedCompanyUser: undefined,
            modalSwitch: 'selectUser',

            users: [],
            csas: [],
            workAreas: [],
            vehicles: []
        }
        this.handleWindowResize = this.handleWindowResize.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleSelectUser = this.handleSelectUser.bind(this);
        this.handleSetCompanyUser = this.handleSetCompanyUser.bind(this);
    }

    handleWindowResize() {
        this.setState({windowWidth: window.innerWidth});
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize)
    }
    hideModal() {
        this.setState({modalSwitch: 'none'});
    }

    handleSelectUser(company, companyUser) {
        this.setState({selectedCompany: company, selectedCompanyUser: companyUser});
        this.hideModal();
    }

    handleSetCompanyUser(companyUser) {
        this.setState({selectedCompanyUser: companyUser});
    }

    

    render() {
        
        const tabs = !this.state.selectedCompanyUser ? [] : [
            {title: 'Profile', icon: faUser, element: <HRUserProfile companyUserUid={this.state.selectedCompanyUser.companyUserUid}/>},
            {title: 'Schedule', icon: faCalendar, element: <HRUserSchedule companyUserUid={this.state.selectedCompanyUser.companyUserUid} users={this.state.users} workAreas={this.state.workAreas} vehicles={this.state.vehicles}/>},
            {title: 'Timesheet', icon: faClock, element: <HRUserTimesheet companyUserUid={this.state.selectedCompanyUser.companyUserUid}/>},
            {title: 'Pay', icon: faMoneyBill, element: <HRUserPay companyUserUid={this.state.selectedCompanyUser.companyUserUid} isEnabled={this.state.selectedCompanyUser.isEnabled}/>},
            {title: 'PTO', icon: faPlane, element: <HRUserPto user={this.state.selectedCompanyUser} companyIdentifier={this.state.selectedCompany.uid}/>},
            // {title: 'Safety', icon: faHelmetSafety, element: <HRUserSafety companyUserUid={this.state.selectedCompanyUser.companyUserUid} users={[]}/>},
            // {title: 'BWC', icon: faUserInjured, element: <HRUserBWC companyUserUid={this.state.selectedCompanyUser.companyUserUid} users={this.state.users} user={this.state.selectedCompanyUser} windowWidth={this.state.windowWidth}/>},
            {title: 'Loans', icon: faHandHoldingDollar, element: <HRUserLoan user={this.state.selectedCompanyUser}/>},
            {title: 'Documents', icon: faFile, element: <HRUserDocuments selectedCompanyUser={this.state.selectedCompanyUser}/>},
            {title: 'Permissions', icon: faLock, element: <HRUserPermissions companyUserUid={this.state.selectedCompanyUser.companyUserUid}/>},
            // {title: 'Notes', icon: faComment, element: <HRNotes companyUserUid={this.state.selectedCompanyUser.companyUserUid} users={this.state.users}/>}
            {title: 'Activity', icon: faClockRotateLeft, element: <HRUserActivity companyUserUid={this.state.selectedCompanyUser.companyUserUid}/>}
        ];

        const tabElements = tabs.map((tab, index) => {
            return (
                <Button key={tab.title} style={index === this.state.activeTab ? {color: 'white'} : {color: 'var(--bs-primary)', backgroundColor: 'white'}} onClick={() => this.setState({activeTab: index})}>
                    {this.state.windowWidth >= 1200 ? tab.title : <FontAwesomeIcon icon={tab.icon}/>}
                </Button>
            )
        });        
    
        return (
            <>
                <div style={{display: 'flex', flexDirection: 'column', padding: 12, height: '100%'}}>
                    <div style={{flexGrow: 0, flexShrink: 0}}>
                        <div style={{display: 'flex', gap: 12, marginBottom: 12}}>
                            { !this.state.selectedCompanyUser ? '' :
                                <Alert variant='primary' style={{marginBottom: 0, paddingTop: 4, paddingBottom: 4}}>
                                    <span>Currently Selected: </span>
                                    <span style={{fontWeight: 'bold'}}>{this.state.selectedCompany.companyName}</span>
                                    <FontAwesomeIcon icon={faChevronRight} style={{width: 25}}/>
                                    <span style={{fontWeight: 'bold'}}>{this.state.selectedCompanyUser.firstName + ' ' + this.state.selectedCompanyUser.lastName}</span>
                                </Alert>
                            }
                            <Button onClick={() => {this.setState({modalSwitch: 'selectUser', selectedCompanyUser: undefined})}}>{this.state.selectedCompanyUser ? 'Change User' : 'Select User'}</Button>
                            { this.state.selectedCompanyUser &&
                                <Button onClick={() => {this.setState({modalSwitch: 'addCompanyUser'})}}>Add User to Another Company</Button>
                            }
                        </div>
                        <ButtonGroup style={{flex: 1, display: 'flex', marginBottom: 12}}>
                            {tabElements}
                        </ButtonGroup>
                    </div>
                    { !this.state.selectedCompanyUser ? '' :
                        <div style={{flex: 1, overflowY: 'auto'}}>
                            {this.state.windowWidth >= 1200 ? '' : 
                                <h1 style={{alignSelf: 'center'}}>{tabs[this.state.activeTab].title}</h1>
                            }
                            <div style={{padding: '0px 12px 0px 12px'}}>
                                {tabs[this.state.activeTab].element}
                            </div>

                        </div>
                    }
                </div>
                <Modal show={this.state.modalSwitch === 'selectUser'} onHide={this.hideModal}  size='xl'>
                    <UserSelector handleSelectUser={this.handleSelectUser}/>
                </Modal>
                <Modal show={this.state.modalSwitch === 'addCompanyUser'} onHide={this.hideModal} centered>
                    <HRAddCompanyUser user={this.state.selectedCompanyUser} hideModal={this.hideModal}/>
                </Modal>
                
            </>
            
        )
    }

    
}

export default HumanResources;