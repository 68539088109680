/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';


export default function TextAreaControl({value, label, setValue, disabled, rows, originalValue, xs, sm, md, lg, xl, xxl}) {



    const content = (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <Form.Control as='textarea' disabled={disabled} rows={rows} value={value} onChange={(event) => {setValue(event.target.value)}} style={{backgroundColor: originalValue !== undefined && originalValue !== value ? '#faffe3' : ''}}/>
        </Form.Group>
    )

    const contentWrapped = xs || sm || md || lg || xl || xxl ? (
        <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} style={{marginBottom: 8}}>
            {content}
        </Col>
    ) : '';

    return (
        <>
            {xs || sm || md || lg || xl || xxl ? contentWrapped : content}
        </>
    )
}