
/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import moment from 'moment';
import ListGroup from 'react-bootstrap/ListGroup';
import { addressToString, formatPhoneNumber, usdFormatter } from '../../../tools';
import KeyValueRow from '../../../components/KeyValueRow';

export default function HRUserSafetyIncident({record}) {
    
    return (
        <ListGroup style={{flex: 1}}>
            <KeyValueRow isListItem title='Date & Time' value={moment(record.dateTime).format('MMM D, YYYY [at] hh:mm A')}/>
            <KeyValueRow isListItem title='Location' value={addressToString(record.address)}/>
            <KeyValueRow isListItem title='Total Cost' value={usdFormatter.format(record.cost)}/>
            {record.propertyOwnerContacted ? 
                <ListGroup.Item>
                    <KeyValueRow title={'Property Owner Contacted?'} value='Yes'/>
                    <div style={{borderLeft: '1px solid lightgray', padding: '4px 0px 0px 16px', margin: '4px 0px 12px 12px'}}>
                        <KeyValueRow title='First Name' value={record.propertyOwnerFirstName}/>
                        <KeyValueRow title='Last Name' value={record.propertyOwnerLastName}/>
                        <KeyValueRow title='Email Address' value={record.propertyOwnerEmail}/>
                        <KeyValueRow title='Phone Number' value={formatPhoneNumber(record.propertyOwnerPhoneNumber)}/>
                        <KeyValueRow title='Address' value={addressToString(record.propertyOwnerAddress)}/>
                        <KeyValueRow title='Insurance Company' value={record.propertyOwnerInsuranceCompany}/>
                    </div>
                </ListGroup.Item>
            : <KeyValueRow isListItem title={'Property Owner Contacted?'} value='No'/>}

            <ListGroup.Item>
                <p>Notes</p>
                {record.notes}
            </ListGroup.Item>
        </ListGroup>
    )
}
