/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import { Alert, Card, Modal } from 'react-bootstrap';
import CustomButton from '../../../../components/CustomButton';
import { matchSettlementReportEmployees } from '../../../../services/ReportsService';
import SearchBar from '../../../../components/SearchBar';


export default function SettlementEmployeeMatchModal({days, userData, handleUpdateUserFedexIds, handleMatchEmployees, hideModal}) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [matches, setMatches] = useState([]);
    const [selectedUser, setSelectedUser] = useState(undefined);
    const [selectedFedexId, setSelectedFedexId] = useState(undefined);

    const [didAutoMatch, setDidAutoMatch] = useState(false);

    const [userFilter, setUserFilter] = useState('');
    const [fedexIdFilter, setFedexIdFilter] = useState('');

    useEffect(() => {
        autoMatch();
    }, [])

    const autoMatch = () => {
        const unusedUsers = userData.filter(r => !matches.find((m)=>m.companyUserIdentifier === r.companyUserUid) && !r.fedexId);
        let unusedFedexIds = [];
        days.forEach((day) => {
            if (!day.companyUserIdentifier && !matches.find(mn => mn.fedexId == day.fedexId) && !unusedFedexIds.includes(day.fedexId)) {
                unusedFedexIds.push(day.fedexId);
            }
        })

        const autoMatches = [];

        for (let i = 0; i < unusedUsers.length; i++) {
            const user = unusedUsers[i];
            for (let j = 0; j < unusedFedexIds.length; j++) {
                const fedexId = unusedFedexIds[j]
                const sReportName = days.find(d => d.fedexId === fedexId).sReportName;
                if (`${user.firstName} ${user.lastName}`.toLowerCase() == sReportName.toLowerCase()) {
                    autoMatches.push({
                        companyUserIdentifier: user.companyUserUid,
                        fedexId: fedexId
                    })
                    unusedFedexIds.splice(j, 1);
                    break;
                }
            }
        }

        if (autoMatches.length > 0) {
            setMatches(autoMatches);
            setDidAutoMatch(true);
        }
    }

    const handleSubmit = async () => {
        setIsSubmitting(true);
        const response = await matchSettlementReportEmployees(matches);
        if (response.status === '200') {
            handleUpdateUserFedexIds(matches);
            handleMatchEmployees(matches);
            hideModal();
        }
        setIsSubmitting(false);
    }

    const handleSetMatchedNames = (companyUserUid, fedexId) => {
        const newArr = Array.from(matches);
        const match = {
            companyUserIdentifier:companyUserUid,
            fedexId:fedexId
        }
        newArr.push(match);
        setMatches(newArr);
    }

    const handleRemoveMatch = (companyUserIdentifier) => {
        const newArr = Array.from(matches).filter((n)=>n.companyUserIdentifier !== companyUserIdentifier);
        setMatches(newArr);
    }


    const handleSetSelectedUser = (companyUserIdentifier) => {
        if (selectedFedexId) {
            handleSetMatchedNames(companyUserIdentifier, selectedFedexId);
            setSelectedFedexId(undefined);
        } else {
            setSelectedUser(companyUserIdentifier);
        }
    }

    const handleSetSelectedFedexId = (fedexId) => {
        if (selectedUser) {
            handleSetMatchedNames(selectedUser, fedexId);
            setSelectedUser(undefined);
        } else {
            setSelectedFedexId(fedexId);
        }
    }

    const unusedUsers = userData.filter(r => !matches.find((m)=>m.companyUserIdentifier === r.companyUserUid) && !r.fedexId);
    const userRows = unusedUsers.filter(u => `${u.firstName} ${u.lastName}`.toLowerCase().includes(userFilter.toLowerCase())).map((user) => {
        const isSelected = selectedUser === user.companyUserUid;
        return (
            <p key={user.companyUserUid} className='cursor-pointer' onClick={() => handleSetSelectedUser(user.companyUserUid)} style={{borderRadius: 6, padding: '4px 8px 4px 8px', marginBottom: 4, border: '1px solid lightgray', backgroundColor: isSelected ? 'yellow' : 'white'}}>
                {user.firstName + ' ' + user.middleName + ' ' + user.lastName}
            </p>
        )
    });


    const unusedFedexIds = [];

    days.forEach((day) => {
        if (!day.companyUserIdentifier && !matches.find(mn => mn.fedexId == day.fedexId) && !unusedFedexIds.includes(day.fedexId)) {
            unusedFedexIds.push(day.fedexId);
        }
    })

    const fedexIdRows = unusedFedexIds.filter(f => days.find(d => d.fedexId === f).sReportName.toLowerCase().includes(fedexIdFilter.toLowerCase())).map((fedexId) => {
        const sReportName = days.find(d => d.fedexId === fedexId).sReportName;
        const isSelected = selectedFedexId === fedexId;
        return (
            <p key={fedexId} className='cursor-pointer' onClick={() => handleSetSelectedFedexId(fedexId)} style={{borderRadius: 6, padding: '4px 8px 4px 8px', marginBottom: 4, border: '1px solid lightgray', backgroundColor: isSelected ? 'yellow' : 'white'}}>
                {`${sReportName} (${fedexId})`}
            </p>
        )
    });

    const completedRows = matches.map((match) => {
        const user = userData.find(r => r.companyUserUid === match.companyUserIdentifier);
        const sReportName = days.find(d => d.fedexId === match.fedexId).sReportName;

        return (
            <div key={match.companyUserIdentifier} className='cursor-pointer' onClick={() => handleRemoveMatch(match.companyUserIdentifier)} style={{display: 'flex', justifyContent: 'space-between', borderRadius: 6, padding: '4px 8px 4px 8px', marginBottom: 4, border: '1px solid lightgray'}}>
                <span>{user.firstName + ' ' + user.middleName + ' ' + user.lastName}</span>
                <span>{`${sReportName} (${match.fedexId})`}</span>
            </div>
            
        )
    });

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Settlement Report Employee Name Match</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>The following employees do not have Fedex Ids in our system. These Ids must be set in order to collect Settlement Report data for your employees. Please match the employees in the left-hand column to the names/Ids from your uploaded settlement report on the right-hand column.</p>
                { didAutoMatch &&
                    <Alert variant='success' style={{padding: 4}}>One or more employees have been automatically assigned FedEx Ids. You can verify the accuracy of these matches in <b>Matched FedEx Ids</b> list below</Alert>
                }
                <div style={{display: 'flex', gap: 12, marginBottom: 12}}>
                    <Card style={{flex: 1}}>
                        <Card.Body>
                            <Card.Title>Employees Without FedEx Ids</Card.Title>
                            <SearchBar label={'Filter by Employee Name'} value={userFilter} setValue={setUserFilter}/>
                            <div style={{marginTop: 8, maxHeight: 400, overflowY: 'scroll'}}>
                                {userRows}
                            </div>
                        </Card.Body>
                    </Card>
                    <Card style={{flex: 1}}>
                        <Card.Body>
                            <Card.Title>Unused FedEx Ids</Card.Title>
                            <SearchBar label={'Filter by Settlement Report Name'} value={fedexIdFilter} setValue={setFedexIdFilter}/>
                            <div style={{marginTop: 8, maxHeight: 400, overflowY: 'scroll'}}>
                                {fedexIdRows}
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                <Card>
                    <Card.Body>
                        <div style={{display: 'flex'}}>
                            <Card.Title>Matched FedEx Ids (Click to Remove)</Card.Title>
                            
                        </div>
                        {completedRows}
                    </Card.Body>
                </Card>    
            </Modal.Body>
            <Modal.Footer>
                <CustomButton isLoading={isSubmitting} label={unusedFedexIds.length === 0 || unusedUsers.length === 0 ? 'Submit' : 'Submit Partially Complete'} onClick={handleSubmit}/>
            </Modal.Footer>
        </>
    )
}