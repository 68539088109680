/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import moment from 'moment';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { fakeHRUserPayData } from '../../../fake-data';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import CustomSpinner from '../../../components/CustomSpinner';
import HRUserPayEditor from './HRUserPayEditor';
import { payTypeDictionary, usdFormatter, ptoTypeDictionary, ptoAccrualTypeDictionary } from '../../../tools';
import { getHRUserPayInformation } from '../../../services/HRServices/HRPayService';
import KeyValueRow from '../../../components/KeyValueRow';
import { downloadFile } from '../../../services/FileControlService';
import FileDownloadButton from '../../../components/FileDownloadButton';
import { getAllFiles} from '../../../services/FileControlService';
import { getFileIcon } from '../../../tools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class HRUserPay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            payHistory: [],
            effectivePay: undefined,
            futurePay: [],
            selectedPay: undefined,
            isCreating: false,
            showModal: false,
            terminationFiles:[]
        }
        this.handleAddPay = this.handleAddPay.bind(this);
    }

    loadData() {
        if (!this.state.isLoading) {
            this.setState({isLoading: true});
        }
        if(this.props.isEnabled === '0'){
            getAllFiles(this.props.companyUserUid).then((response)=>{
                if(response && response.status === '200'){
                    this.setState({terminationFiles:response.data});
                }
            });
        }
        getHRUserPayInformation(this.props.companyUserUid).then((response) => {
            if (response && response.status === '200') {
                this.setState({effectivePay: response.effectivePay, payHistory: response.historicPay, futurePay: response.futurePay});
            }
            this.setState({isLoading: false});
        });
    }

    componentDidMount() {
        this.loadData();
    }

    handleAddPay(newPayInfo) {
        this.setState({showModal: false});
        this.loadData();
    }

    
    render() {
       
        let ptoType = this.state.effectivePay && this.state.effectivePay.ptoType ? this.state.effectivePay.ptoType : '';
        let ptoTypeName = 'Per 40 Hours';
        let terminationNotes = this.state.effectivePay && this.state.effectivePay.notes && this.props.isEnabled === '0' ? JSON.parse(this.state.effectivePay.notes):'';
        for(let type in ptoTypeDictionary){
            if(ptoType === type){
                ptoTypeName = ptoTypeDictionary[type];
            } 
        }

        const currentPositionData = !this.state.effectivePay ? [] : [
            {title: 'Effective Date', value: moment(this.state.effectivePay.date).format('MMM D, YYYY')},
            {title: 'Title', value: this.state.effectivePay.title},
            {title: 'BWC Code', value: this.state.effectivePay.bwcCode},
            {title: 'Pay Rate', value: usdFormatter.format(this.state.effectivePay.payRate)},
            {title: 'Pay Type', value: payTypeDictionary[this.state.effectivePay.payType]},
            {title: 'Hourly Wage', value: usdFormatter.format(this.state.effectivePay.hourlyWage)},
            {title: 'PTO Accrual ' + ptoAccrualTypeDictionary[this.state.effectivePay.ptoAccrualType], value: (this.state.effectivePay.ptoAccrual?this.state.effectivePay.ptoAccrual:0)},
            {title: 'Weekday Stop Threshold', value: (this.state.effectivePay.stopBonusWeekdayThreshold?this.state.effectivePay.stopBonusWeekdayThreshold:0)},
            {title: 'Weekend Stop Threshold', value: (this.state.effectivePay.stopBonusWeekendThreshold?this.state.effectivePay.stopBonusWeekendThreshold:0)},
            {title: 'Weekday Stop Wage Dollar Amount', value: (this.state.effectivePay.stopBonusAmount?usdFormatter.format(this.state.effectivePay.stopBonusAmount):usdFormatter.format(0))},
            {title: 'Weekend Stop Wage Dollar Amount', value: (this.state.effectivePay.stopBonusWeekendAmount?usdFormatter.format(this.state.effectivePay.stopBonusWeekendAmount):usdFormatter.format(0))},
            {title: 'Employee Type', value: this.state.effectivePay.employeeType === '0' ? 'Full-Time' : 'Part-Time'},
            {title: 'Medical Insurance', value: usdFormatter.format(this.state.effectivePay.medical)},
            {title: 'Dental Insurance', value: usdFormatter.format(this.state.effectivePay.dental)},
            {title: 'Vision Insurance', value: usdFormatter.format(this.state.effectivePay.vision)},
            {title: 'Child Support', value: this.state.effectivePay.childSupport ? usdFormatter.format(JSON.parse(this.state.effectivePay.childSupport).reduce((prev, curr) => {
                return prev + parseFloat(curr);
            }, 0)) : 0.0},
            {title: 'Automatic Reimbursements', value: this.state.effectivePay.automaticReimbursement ? usdFormatter.format(JSON.parse(this.state.effectivePay.automaticReimbursement).reduce((prev, curr) => {
                return prev + parseFloat(curr.amount);
            }, 0)) : 0.0},
            {title: 'Automatic Deductions (Flat Rate)', value: this.state.effectivePay.automaticDeductions? usdFormatter.format(JSON.parse(this.state.effectivePay.automaticDeductions).filter((d)=>d.label !== '401K (% of Gross)' && d.label !== 'Other (% of Gross)').reduce((prev, curr) => {
                return prev + parseFloat(curr.amount);
            }, 0)) : 0.0},
            {title: 'Automatic Deductions (% of Gross)', value: this.state.effectivePay.automaticDeductions? JSON.parse(this.state.effectivePay.automaticDeductions).filter((d)=>d.label === '401K (% of Gross)' || d.label === 'Other (% of Gross)').reduce((prev, curr) => {
                return prev + parseFloat(curr.amount);
            }, 0) + '%' : 0.0},
        ];
       
        const disciplinaryRightups1 = this.state.terminationFiles.length > 0 && terminationNotes.disciplinaryRightups1 ? this.state.terminationFiles.find((file)=>file.uid === terminationNotes.disciplinaryRightups1.toString()) : {};
        const disciplinaryRightups2 = this.state.terminationFiles.length > 0 && terminationNotes.disciplinaryRightups2 ? this.state.terminationFiles.find((file)=>file.uid === terminationNotes.disciplinaryRightups2.toString()) : {};
        const textsScreenshots1 =  this.state.terminationFiles.length > 0 && terminationNotes.textsScreenshots1 ? this.state.terminationFiles.find((file)=>file.uid === terminationNotes.textsScreenshots1.toString()) : {};
        const textsScreenshots2 =  this.state.terminationFiles.length > 0 && terminationNotes.textsScreenshots2 ? this.state.terminationFiles.find((file)=>file.uid === terminationNotes.textsScreenshots2.toString()) : {};
        const terminationLetter =  this.state.terminationFiles.length > 0 && terminationNotes.terminationLetter ? this.state.terminationFiles.find((file)=>file.uid === terminationNotes.terminationLetter.toString()) : {};
       
        const terminationElements = (
            <>
                <Card>
                    <Card.Header>
                        <Card.Title>Termination</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <ListGroup>
                            {terminationNotes.eligibleToRehire &&
                            <KeyValueRow isListItem title='Eligible To Rehire' value={terminationNotes.eligibleToRehire ? 'Yes' : 'No'} />
                            }
                            <KeyValueRow isListItem title='Termination Summary' value={terminationNotes.synopsis} />
                            <KeyValueRow isListItem title='Complaints' value={terminationNotes.complaints}/>
                        </ListGroup>
                        {Object.keys(disciplinaryRightups1).length !== 0 &&
                        <Card style={{display: 'flex', flexDirection: 'row', flex: 1, marginTop:10}}>
                            <div style={{display: 'flex', height: '100%', padding: 18, gap: 12, borderTopLeftRadius: 6, borderBottomLeftRadius: 6, backgroundColor: 'var(--bs-primary)', alignItems: 'center', flexShrink: 0}}>
                                <FontAwesomeIcon icon={getFileIcon(disciplinaryRightups1.documentType)} style={{color: 'white'}}/>
                            </div>
                            <div style={{borderTopRightRadius: 6, borderBottomRightRadius: 6, border: '2px solid var(--bs-primary)', padding: '0px 8px 0px 8px', borderLeft: 'none', flex: 1, alignItems: 'center', justifyContent: 'space-between', display: 'flex'}}>
                                <span style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '20%'}}>Disciplinary Rightup #1: {disciplinaryRightups1.documentName}</span>
                                <FileDownloadButton value={terminationNotes.disciplinaryRightups1} doc={disciplinaryRightups1} downloadService={downloadFile}/>
                            </div>
                        </Card>
                        }
                        {Object.keys(disciplinaryRightups2).length !== 0 &&
                        <Card style={{display: 'flex', flexDirection: 'row', flex: 1}}>
                            <div style={{display: 'flex', height: '100%', padding: 18, gap: 12, borderTopLeftRadius: 6, borderBottomLeftRadius: 6, backgroundColor: 'var(--bs-primary)', alignItems: 'center', flexShrink: 0}}>
                                <FontAwesomeIcon icon={getFileIcon(disciplinaryRightups2.documentType)} style={{color: 'white'}}/>
                            </div>
                            <div style={{borderTopRightRadius: 6, borderBottomRightRadius: 6, border: '2px solid var(--bs-primary)', padding: '0px 8px 0px 8px', borderLeft: 'none', flex: 1, alignItems: 'center', justifyContent: 'space-between', display: 'flex'}}>
                                <span style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '20%'}}>Disciplinary Rightup #2: {disciplinaryRightups2.documentName}</span>
                                <FileDownloadButton value={terminationNotes.disciplinaryRightups2} doc={disciplinaryRightups2} downloadService={downloadFile}/>
                            </div>
                        </Card>
                        }
                        {Object.keys(textsScreenshots1).length !== 0 &&
                        <Card style={{display: 'flex', flexDirection: 'row', flex: 1}}>
                            <div style={{display: 'flex', height: '100%', padding: 18, gap: 12, borderTopLeftRadius: 6, borderBottomLeftRadius: 6, backgroundColor: 'var(--bs-primary)', alignItems: 'center', flexShrink: 0}}>
                                <FontAwesomeIcon icon={getFileIcon(textsScreenshots1.documentType)} style={{color: 'white'}}/>
                            </div>
                            <div style={{borderTopRightRadius: 6, borderBottomRightRadius: 6, border: '2px solid var(--bs-primary)', padding: '0px 8px 0px 8px', borderLeft: 'none', flex: 1, alignItems: 'center', justifyContent: 'space-between', display: 'flex'}}>
                                <span style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '20%'}}>Texts/Screenshots #1: {textsScreenshots1.documentName}</span>
                                <FileDownloadButton value={terminationNotes.textsScreenshots1} doc={textsScreenshots1} downloadService={downloadFile}/>
                            </div>
                        </Card>
                        }
                        {Object.keys(textsScreenshots2).length !== 0 &&
                        <Card style={{display: 'flex', flexDirection: 'row', flex: 1}}>
                            <div style={{display: 'flex', height: '100%', padding: 18, gap: 12, borderTopLeftRadius: 6, borderBottomLeftRadius: 6, backgroundColor: 'var(--bs-primary)', alignItems: 'center', flexShrink: 0}}>
                                <FontAwesomeIcon icon={getFileIcon(textsScreenshots2.documentType)} style={{color: 'white'}}/>
                            </div>
                            <div style={{borderTopRightRadius: 6, borderBottomRightRadius: 6, border: '2px solid var(--bs-primary)', padding: '0px 8px 0px 8px', borderLeft: 'none', flex: 1, alignItems: 'center', justifyContent: 'space-between', display: 'flex'}}>
                                <span style={{whiteSpace: 'nowrap',textOverflow: 'ellipsis', width: '20%'}}>Texts/Screenshots #2: {textsScreenshots2.documentName}</span>
                                <FileDownloadButton value={terminationNotes.textsScreenshots2} doc={textsScreenshots2} downloadService={downloadFile}/>
                            </div>
                        </Card>
                        }
                        {Object.keys(terminationLetter).length !== 0 &&
                        <Card style={{display: 'flex', flexDirection: 'row', flex: 1}}>
                            <div style={{display: 'flex', height: '100%', padding: 18, gap: 12, borderTopLeftRadius: 6, borderBottomLeftRadius: 6, backgroundColor: 'var(--bs-primary)', alignItems: 'center', flexShrink: 0}}>
                                <FontAwesomeIcon icon={getFileIcon(terminationLetter.documentType)} style={{color: 'white'}}/>
                            </div>
                            <div style={{borderTopRightRadius: 6, borderBottomRightRadius: 6, border: '2px solid var(--bs-primary)', padding: '0px 8px 0px 8px', borderLeft: 'none', flex: 1, alignItems: 'center', justifyContent: 'space-between', display: 'flex'}}>
                                <span style={{whiteSpace: 'nowrap',textOverflow: 'ellipsis', width: '20%'}}>Termination Letter: {terminationLetter.documentName}</span>
                                <FileDownloadButton value={terminationNotes.terminationLetter} doc={terminationLetter} downloadService={downloadFile}/>
                            </div>
                        </Card>
                        }
                    </Card.Body>
                </Card>
            </>
        )

        const currentPositionElements = currentPositionData.map((keyValuePair) => {
            return (
                <KeyValueRow isListItem key={keyValuePair.title} title={keyValuePair.title} value={keyValuePair.value}/>
            )
        });


        const futureElements = this.state.futurePay.map((item) => {
            return (
                <tr key={item.uid} className='cursor-pointer' onClick={() => {this.setState({showModal: true, isCreating: false, selectedPay: item})}}>
                    <td>{moment(item.date).format('MMM D, YYYY')}</td>
                    <td>{item.title}</td>
                    <td>{usdFormatter.format(item.payRate)}</td>
                    <td>{payTypeDictionary[item.payType]}</td>
                    <td>{usdFormatter.format(item.hourlyWage)}</td>
                    <td>{item.employeeType === '0' ? 'Full-Time' : 'Part-Time'}</td>
                </tr>
            )
        })

        const historyElements = this.state.payHistory.map((item) => {
            return (
                <tr key={item.uid} className='cursor-pointer' onClick={() => {this.setState({showModal: true, isCreating: false, selectedPay: item})}}>
                    <td>{moment(item.date).format('MMM D, YYYY')}</td>
                    <td>{item.title}</td>
                    <td>{usdFormatter.format(item.payRate)}</td>
                    <td>{payTypeDictionary[item.payType]}</td>
                    <td>{usdFormatter.format(item.hourlyWage)}</td>
                    <td>{item.employeeType === '0' ? 'Full-Time' : 'Part-Time'}</td>
                </tr>
            )
        });
       
        const content = (
            <div style={{display: 'flex', flexDirection: 'column', width: '100%', gap: 12}}>
                <Card>
                    <Card.Body>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 8}}>
                            <Card.Title>Current Position</Card.Title>
                            { this.state.effectivePay &&
                                <Button style={{color: 'white'}} onClick={() => {this.setState({showModal: true, isCreating: true, selectedPay: this.state.effectivePay})}}>Create New</Button>
                            }
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', gap: 12}}>
                            <ListGroup style={{flex: 1}}>
                                {currentPositionElements}
                            </ListGroup>
                            <div style={{flex: 1, padding: 12, border: '1px lightgray solid', borderRadius: 6}}>
                                {this.props.isEnabled === '0' ?
                                <>
                                {terminationElements}
                                </> 
                                : this.state.effectivePay?.notes}
                                
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Title>Historic Pay</Card.Title>
                        <div style={{maxHeight: 360, overflowY: 'scroll'}}>
                            <Table responsive={true} striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Title</th>
                                        <th>Pay Rate</th>
                                        <th>Pay Type</th>
                                        <th>Hourly Wage</th>
                                        <th>Employee Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {historyElements}
                                </tbody>
                            </Table>
                        </div>
                        
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Title>Upcoming Pay</Card.Title>
                        <div style={{maxHeight: 360, overflowY: 'scroll'}}>
                            <Table responsive={true} striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Title</th>
                                        <th>Pay Rate</th>
                                        <th>Pay Type</th>
                                        <th>Hourly Wage</th>
                                        <th>Employee Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {futureElements}
                                </tbody>
                            </Table>
                        </div>
                        
                    </Card.Body>
                </Card>
            </div>
        )

        return (
            <>
                {this.state.isLoading ? <CustomSpinner spinnerHeight='10vh' spinnerWidth='10vh' height='50vh'/> : content}
                <Modal show={this.state.showModal} onHide={() => {this.setState({showModal: false})}} centered size='xl'>
                    <HRUserPayEditor companyUserUid={this.props.companyUserUid} handleAddPay={this.handleAddPay} selectedPay={this.state.selectedPay} isCreating={this.state.isCreating}/>
                </Modal>
            </>
        )
    }
}


export default HRUserPay;