/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useEffect, useState} from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import QuickTable from '../../../components/QuickTable';
import PageSpinner from '../../../components/PageSpinner';
import { getAllFutureTimeOff } from '../../../services/HumanResourcesService';

export default function HRTimeOffModal() {
    const [isLoading, setIsLoading] = useState(false);
    const [timeOff, setTimeOff] = useState([]);

    const loadData = async () => {
        setIsLoading(true);
        const response = await getAllFutureTimeOff();
        if (response.status === 200) {
            setTimeOff(response.timeOff);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadData();
    }, [])


    const timeOffElements = timeOff.map((item) => {
        return (
            <tr key={item.uid}>
                <td>{`${item.user.lastName}, ${item.user.firstName} ${item.user.middleName}`}</td>
                <td>{`${item.user.location.name}`}</td>
                <td>{`${moment(item.startDate).format('MMM D, YYYY')} - ${moment(item.endDate).format('MMM D, YYYY')}`}</td>
                <td>{item.status == 0 ? 'Pending' : item.status == 1 ? 'Accepted' : 'Rejected'}</td>
            </tr>
        )
    })

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>All Upcoming Time-Off</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { isLoading ? <PageSpinner/> :
                    <QuickTable headers={['Name', 'Location', 'Date Range', 'Status']} rows={timeOffElements}/>
                }
            </Modal.Body>
        </>
    )
}