
/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import HRUserSafetyRecordVersionHeader from './HRUserSafetyRecordVersionHeader';
import HRUserSafetyAccident from './HRUserSafetyAccident';
import HRUserSafetyIncident from './HRUserSafetyIncident';
import HRUserSafetyRideAlong from './HRUserSafetyRideAlong';
import HRUserSafetyMovingViolation from './HRUserSafetyMovingViolation';

export default class HRUserSafetyRecord extends React.Component {
    constructor(props) { //users type reviseRecord safetyRecord
        super(props);
        this.state = {
            isLoading: false,
            versions: [props.safetyRecord],
            selectedVersion: props.safetyRecord,
        }
    }

    getRecordID(record) {
        if (record.accidentID) {
            return record.accidentID;
        } else if (record.incidentID) {
            return record.incidentID;
        } else if (record.movingViolationID) {
            return record.movingViolationID;
        } else {
            return record.rideAlongID;
        }
    }

    loadData() {
        if (!this.state.isLoading) {
            this.setState({isLoading: true});
        }

        this.props.apiFunction(this.getRecordID(this.props.safetyRecord)).then((response) => {
            if (response && response.status === '200') {
                const versions = response.data.versions.sort((a, b) => {
                    if (moment(a.submissionDateTime).isBefore(moment(b.submissionDateTime), 'minute')) {
                        return 1;
                    } else if (moment(a.submissionDateTime).isAfter(moment(b.submissionDateTime), 'minute')) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
                this.setState({versions: versions, selectedVersion: versions[0]});
            }

            this.setState({isLoading: false});
        });

    }
    
    componentDidMount() {
        this.loadData();
    }

    getDataList() {
        switch (this.props.type) {
            case 'Accident':
                return <HRUserSafetyAccident users={this.props.users} record={this.state.selectedVersion}/>;
            case 'Incident':
                return <HRUserSafetyIncident record={this.state.selectedVersion}/>;
            case 'Ride-Along':
                return <HRUserSafetyRideAlong record={this.state.selectedVersion}/>;
            case 'Moving Violation':
                return <HRUserSafetyMovingViolation record={this.state.selectedVersion}/>;
            default:
                return '';
        }
    }

    render() {
        
        const content = !this.state.selectedVersion ? '' : (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.type + ' Record'}</Modal.Title>
                    <Button style={{color: 'white', marginLeft: 12}} onClick={this.props.reviseRecord}>Revise Record</Button>
                </Modal.Header>
                <HRUserSafetyRecordVersionHeader 
                    users={this.props.users} 
                    versions={this.state.versions} 
                    selectedVersion={this.state.selectedVersion} 
                    setSelectedVersion={(value) => {this.setState({selectedVersion: value})}} 
                    isLoading={this.state.isLoading}
                />
                <Modal.Body>
                    {this.getDataList()}
                </Modal.Body>
            </>
        );

        return (
            <>
                {content}
            </>
        )
    }
}
