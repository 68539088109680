/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import { bwcCodeDictionary } from '../tools';
import RadioControl from './RadioControl';
import CustomControl from './CustomControl';
import Col from 'react-bootstrap/Col';


export default function BwcCodeControl({value, setValue, originalValue, xs, sm, md, lg, xl, xxl, disabled, validator, inline}) {
    const [useCustom, setUseCustom] = useState(false); 

    const handleSetValue = (value) => {
        if (value === '0000') {
            setValue('');
            setUseCustom(true);
            return;
        } else if (useCustom) {
            setUseCustom(false);
        }
        setValue(value);
    }




    return (
        <>
            <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} style={{marginBottom: 8}}>
                <RadioControl 
                    title={'BWC Code'} 
                    selection={value} 
                    setSelection={handleSetValue} 
                    optionValues={Object.keys(bwcCodeDictionary)} 
                    optionNames={Object.keys(bwcCodeDictionary).map(key => bwcCodeDictionary[key] + (key ? ' (' + key + ')' : ''))} 
                    inline={inline} 
                    disabled={disabled} 
                    originalValue={originalValue} 
                    validator={validator} 
                />
            </Col>
            { useCustom &&
                <CustomControl max={10} disabled={disabled} originalValue={originalValue} xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} title='BWC Code' type='text' value={value}
                    onChange={(event) => {
                        setValue(event.target.value);
                    }}
                    validator={validator}
                />
                
            }
        </>
    )
}