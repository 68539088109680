/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import HRHirePersonalInfo from './HRHirePersonalInfo';
import HRHireEContacts from './HRHireEContacts';
import HRHireEmploymentDetails from './HRHireEmploymentDetails';
import HRHirePermissions from './HRHirePermissions';
import HRHireReview from './HRHireReview';
import Wizard from '../../../components/Wizard/Wizard.js';
import CloseButton from 'react-bootstrap/CloseButton';
import { KeyedValidationGroup } from '../../../validation';
import { getPermissionsTemplate, submitNewHire, getCompanyBenefits, checkEmail } from '../../../services/CompanyServices/CompanyHireService';
import { getCompanyResources } from '../../../services/HRServices/HRService';
import { encodeAvailability } from '../../../tools';
import CustomButton from '../../../components/CustomButton';
import PageSpinner from '../../../components/PageSpinner';

export default class HRHire extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            personalInformation: {
                firstName: '',
                middleName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                address: {},
                dateOfBirth: '',
                ssn: '',
                eContact1FirstName: '',
                eContact1LastName: '',
                eContact1PhoneNumber: '',
                eContact1Email: '',
                eContact1Address: {},
                eContact1Relationship: '',
                eContact2FirstName: '',
                eContact2LastName: '',
                eContact2PhoneNumber: '',
                eContact2Email: '',
                eContact2Address: {},
                eContact2Relationship: '',
            },
            employmentDetails: {
                availability: [false, false, false, false, false, false, false],
                csaIdentifier: '',
                title: '',
                payRate: '',
                payType: '',
                hourlyWage: '',
                employeeType: '',
                bwcCode: '',
                supervisor: undefined,
                dailyStartTime: '',
                startDate: '',
                firstDayStartTime: '',
                benefits: '',
                ptoAccrual: '',
                stopBonusWeekdayThreshold: '',
                stopBonusWeekendThreshold: '',
                stopBonusAmount: '',
                automaticReimbursement: [],
                automaticDeductions: [],
                medical: '',
                dental: '',
                vision: '',
                childSupport: []
            },
            permissions: undefined,
            permissionsTemplate: undefined,
            isLoading: false,
            isSubmitting: false,
            sendOnboarding: false,
            wizardIndex: 0,
            showCloseWarning: false,
            csas: [],
            users: [],
            emailTakenError: false,
        }

        this.keyedValidationGroup = new KeyedValidationGroup();
        this.handleSetPersonalInformation = this.handleSetPersonalInformation.bind(this);
        this.handleSetEmploymentDetails = this.handleSetEmploymentDetails.bind(this);
        this.handleSetPermissions = this.handleSetPermissions.bind(this);
        this.handleUseTemplate = this.handleUseTemplate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleSetPersonalInformation(key, value) {
        const newObj = structuredClone(this.state.personalInformation);
        newObj[key] = value;
        this.setState({personalInformation: newObj});
    }

    handleSetEmploymentDetails(key, value) {
        const newObj = structuredClone(this.state.employmentDetails);
        newObj[key] = value;
        this.setState({employmentDetails: newObj});
    }

    handleSetPermissions(permissionName) {
        const newPermissions = structuredClone(this.state.permissions);
        newPermissions[permissionName] = newPermissions[permissionName] === '0' ? '1' : '0';
        this.setState({permissions: newPermissions});
    }

    handleUseTemplate(templateName) {
        const newPermissions = structuredClone(this.state.permissions);
        this.state.permissionsTemplate.forEach((permObj) => {
            newPermissions[permObj.permissionName] = permObj[templateName];
        });
        this.setState({permissions: newPermissions});
    }


    async loadData() {
        this.setState({isLoading: true});
        const getCompanyResourcesResponse = await getCompanyResources(this.props.selectedCompany.uid);
        if (getCompanyResourcesResponse && getCompanyResourcesResponse.status === '200') {
            this.setState({users: getCompanyResourcesResponse.users, csas: getCompanyResourcesResponse.csas});
        }
        const getPermissionsTemplateResponse = await getPermissionsTemplate();
        if (getPermissionsTemplateResponse && getPermissionsTemplateResponse.status === '200') {
            const ePermissions = {};
            getPermissionsTemplateResponse.template.forEach((permObj) => {
                ePermissions[permObj.permissionName] = '0';
            });
            this.setState({permissionsTemplate: getPermissionsTemplateResponse.template, permissions: ePermissions});
        }
        const getCompanyBenefitsResponse = await getCompanyBenefits(this.props.selectedCompany.uid)
        if (getCompanyBenefitsResponse && getCompanyBenefitsResponse.status === '200') {
            this.handleSetEmploymentDetails('benefits', getCompanyBenefitsResponse.benefits);
        }
        this.setState({isLoading: false});
    }

    componentDidMount() {
        this.loadData();
    }
    

    async handleSubmit() {
        this.setState({isSubmitting: true});
        
        const checkEmailResponse = await checkEmail(this.state.personalInformation.email);
        if (checkEmailResponse.status === '400') {
            this.setState({emailTakenError: true, isSubmitting: false});
            return;
        } else if (this.state.emailTakenError) {
            this.setState({emailTakenError: false});
        }

        const newPermissions = {};

        this.state.wizardIndexpermissionsTemplate.forEach((permObj) => {
            newPermissions[permObj.permissionName] = this.state.permissions[permObj.permissionName];
        });

        const data = {
            ...this.state.personalInformation,
            ...this.state.employmentDetails,
            availability: encodeAvailability(this.state.employmentDetails.availability),
            automaticReimbursement: JSON.stringify(this.state.employmentDetails.automaticReimbursement),
            permissions: newPermissions,
            sendOnboarding: this.state.sendOnboarding,
        }
        submitNewHire(this.props.selectedCompany.uid, data).then((response) => {
            if (response && response.status === '200') {
                window.location.reload(false);
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            this.setState({isSubmitting: false});
        });
    }

    render() {

        const sections = {
            'Personal Information': (
                <HRHirePersonalInfo 
                    personalInformation={this.state.personalInformation} 
                    handleSetPersonalInformation={this.handleSetPersonalInformation}
                    validationGroup={this.keyedValidationGroup}
                />
            ),
            'Emergency Contacts': (
                <HRHireEContacts
                    personalInformation={this.state.personalInformation} 
                    handleSetPersonalInformation={this.handleSetPersonalInformation}
                />
            ),
            'Employment Details': (
                <HRHireEmploymentDetails 
                    csas={this.state.csas} users={this.state.users}
                    employmentDetails={this.state.employmentDetails} 
                    handleSetEmploymentDetails={this.handleSetEmploymentDetails}
                    validationGroup={this.keyedValidationGroup}
                />
            ),
            'Permissions': (
                <HRHirePermissions 
                    handleSetPermissions={this.handleSetPermissions} 
                    handleUseTemplate={this.handleUseTemplate} 
                    permissions={this.state.permissions} 
                    permissionsTemplate={this.state.permissionsTemplate}
                />
            ),
            'Review': (
                <HRHireReview 
                    csas={this.state.csas} users={this.state.users}
                    personalInformation={this.state.personalInformation} 
                    employmentDetails={this.state.employmentDetails}
                />
            )
        }
    
        return (
            <>
                <Modal.Header>
                    <Modal.Title>{'Hire New Employee at ' + this.props.selectedCompany.companyName}</Modal.Title>
                    <CloseButton onClick={() => {this.setState({showCloseWarning: true})}}/>
                </Modal.Header>
                <Modal.Body>
                    { this.state.isLoading ? <PageSpinner/> :
                        <>
                            <div style={{marginLeft: 20, marginRight: 20, marginBottom: 12}}>
                                <Wizard steps={Object.keys(sections)} stepIndex={this.state.wizardIndex} setStepIndex={(value) => this.setState({wizardIndex: value})}/>
                            </div>
                            {Object.values(sections)[this.state.wizardIndex]}
                        </>
                    }
                </Modal.Body>
                { this.state.wizardIndex === 4 &&
                    <Modal.Footer>
                        <div style={{display: 'flex', gap: 8, alignItems: 'center'}}>
                            <Form.Check type='switch' label='Generate and send onboarding paperwork to employee?' checked={this.state.sendOnboarding} onChange={() => {this.setState({sendOnboarding: !this.state.sendOnboarding})}}/>
                            <CustomButton  label='Submit' isLoading={this.state.isSubmitting} onClick={this.handleSubmit}/>
                        </div>
                    </Modal.Footer>
                }
                <Modal show={this.state.showCloseWarning} onHide={() => {this.setState({showCloseWarning: false})}}>
                    <Modal.Header>
                        <Modal.Title>Discard Unsubmitted Progress?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        You will lose all progress if you close out of this modal before submitting.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button style={{color: 'white'}} onClick={() => {this.setState({showCloseWarning: false})}}>Cancel</Button>
                        <Button style={{color: 'white'}} onClick={() => {this.setState({showCloseWarning: false}); this.props.hideModal()}}>Discard Progress</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

}