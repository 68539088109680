/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import Button from 'react-bootstrap/Button';
import SearchBar from "../../../components/SearchBar";
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import { getCompanyDocuments, downloadCompanyDocument, deleteCompanyDocument } from "../../../services/CompanyServices/CompanyDocumentsService";
import PageSpinner from "../../../components/PageSpinner";
import AlertModal from "../../../components/AlertModals/AlertModal";
import CompanyFileUploadControl from "./CompanyFileUploadControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFileIcon } from "../../../tools";
import FileDownloadButton from "../../../components/FileDownloadButton";
import { Alert } from "react-bootstrap";
import MyCompanyDocumentEditor from "./CompanyDocumentEditor";

export default class CompanyDocuments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            documents: [],
            filter: '',
            selectedDoc: undefined,
            modalSwitch: ''
        }
        this.handleEditDocument = this.handleEditDocument.bind(this);
        this.handleUploadDocument = this.handleUploadDocument.bind(this);
        this.handleDeleteDocument = this.handleDeleteDocument.bind(this);
    }

    async loadData() {
        this.setState({isLoading: true});

        const response = await getCompanyDocuments(this.props.selectedCompany.uid);
        if (response && response.status === '200') {
            this.setState({documents: response.data});
        } else if (response && response.status === '401') {
            window.location.reload(false);
        }


        this.setState({isLoading: false});
    }

    componentDidMount() {
        this.loadData();
    }

    handleUploadDocument(docObj) {
        const newArray = Array.from(this.state.documents);
        newArray.push(docObj);
        this.setState({documents: newArray, modalSwitch: ''});
    }

    handleEditDocument(document) {
        const newArray = Array.from(this.state.documents.filter(r => r.uid !== document.uid));
        newArray.push(document);
        this.setState({documents: newArray, modalSwitch: ''});
    }
    

    async handleDeleteDocument() {
        try {
            const response = await deleteCompanyDocument(this.state.selectedDoc.uid);
            if (response.status === '200') {
                const newArray = Array.from(this.state.documents).filter(r => r.uid !== this.state.selectedDoc.uid);
                this.setState({documents: newArray, selectedDoc: undefined});
                return true;
            }
        } catch (error) {
            console.log(error);
        }
        return false;
    }

    render() {

        const filteredDocuments = this.state.documents.filter((doc) => {
            return doc.documentName.toLowerCase().includes(this.state.filter.toLowerCase());
        });

        const documentsElements = filteredDocuments.map((doc) => {
            return (
                <Card key={doc.uid} style={{display: 'flex', flexDirection: 'row', flex: 1}}>
                    <div style={{display: 'flex', height: '100%', padding: 18, gap: 12, borderTopLeftRadius: 6, borderBottomLeftRadius: 6, backgroundColor: 'var(--bs-primary)', alignItems: 'center', flexShrink: 0}}>
                        <FontAwesomeIcon icon={getFileIcon(doc.documentType)} style={{color: 'white'}}/>
                    </div>
                    <div style={{borderTopRightRadius: 6, borderBottomRightRadius: 6, border: '2px solid var(--bs-primary)', padding: '0px 8px 0px 8px', borderLeft: 'none', flex: 1, alignItems: 'center', justifyContent: 'space-between', display: 'flex'}}>
                        <span style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '20%'}}>{doc.documentName}</span>
                        <div style={{display: 'flex', gap: 8, alignItems: 'center'}}>
                            { doc.isRequired === '1' &&
                                <Alert variant='success' style={{marginBottom: 0, padding: 6}}>Employees required to acknowledge</Alert>
                            }
                            <Button style={{color: 'white', flexShrink: 0}} onClick={() => {this.setState({selectedDoc: doc, modalSwitch: 'edit'})}}>Edit</Button>
                            <FileDownloadButton value={doc.uid} doc={doc} downloadService={downloadCompanyDocument}/>
                            <Button style={{color: 'white', flexShrink: 0}} onClick={() => {this.setState({selectedDoc: doc, modalSwitch: 'delete'})}}>Delete</Button>                            
                        </div>
                    </div>
                </Card>
            )
        });

        const content = (
            <div style={{height: '100%'}}>
                <div style={{display: 'flex', flex: 1, flexDirection: 'row', gap: 8, alignItems: 'center'}}>
                    <SearchBar label='Search for a document by title' value={this.state.filter} setValue={(value) => {this.setState({filter: value})}}/>
                    <Button style={{color: 'white', flexShrink: 0}} onClick={() => {this.setState({modalSwitch: 'upload'})}}>Upload Document</Button>
                </div>
                <div style={{display: 'flex', flex: 1, flexDirection: 'column', gap: 12, marginTop: 12}}>
                    {documentsElements}
                </div>
            </div>
        )

        return (
            <>
                {this.state.isLoading ? <PageSpinner/> : content}
                <Modal show={this.state.modalSwitch === 'upload'} onHide={() => {this.setState({modalSwitch: ''})}} centered size='lg'>
                    <CompanyFileUploadControl handleUploadDocument={this.handleUploadDocument} companyIdentifier={this.props.selectedCompany.uid}/>
                </Modal>
                <Modal show={this.state.modalSwitch === 'edit'} onHide={() => {this.setState({modalSwitch: ''})}}>
                    <MyCompanyDocumentEditor document={this.state.selectedDoc} handleEditDocument={this.handleEditDocument}/>
                </Modal>
                <AlertModal 
                    centered 
                    show={this.state.modalSwitch === 'delete'} 
                    onHide={() => {this.setState({selectedDoc: undefined, modalSwitch: ''})}} 
                    title='Deletion Confirmation' 
                    message='Permanently delete document?' 
                    buttonLabel='Delete' 
                    callBack={this.handleDeleteDocument}
                />
            </>
        )
    }
}