/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import QuickTable from "../../../../../../components/QuickTable";
import { bigToUsd, holidayPayTypeDictionary } from "../../../../payrollTools";
import moment from "moment";

export default function PayrollEmployeeHolidaysEditor({entry, handleSetWeek}) {

    const removeHoliday = (id, index) => {
        const newArr = entry.weeks[index].holidays.filter((element) => {
            return element.id !== id;
        });
        handleSetWeek(index, 'holidays', newArr);
    }

    const handleSetHoliday = (id, index, key, value) => {
        const newArr = Array.from(entry.weeks[index].holidays);
        newArr.find(r => r.id === id)[key] = value;
        handleSetWeek(index, 'holidays', newArr);
    }

    const weekElements = entry.weeks.map((week, index) => {
        const weekStart = moment(entry.periodStart).add(index, 'weeks');
        const weekEnd = moment(weekStart).add(6, 'days');

        const holidayElements = week.holidays.map((holiday) => {
            const payTypeDropDownItems = Object.entries(holidayPayTypeDictionary).map(([key, value]) => {
                return (
                    <Dropdown.Item key={key} active={holiday.payType === key} onClick={() => {handleSetHoliday(holiday.id, index, 'payType', key)}}>{value}</Dropdown.Item>
                )
            });
            
            return (
                <tr key={holiday.id}>
                    <td>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-primary" style={{padding: '2px 4px 2px 4px'}}>{holidayPayTypeDictionary[holiday.payType]}</Dropdown.Toggle>
                            <Dropdown.Menu onMouseDown={(e)=>e.preventDefault()}>
                                {payTypeDropDownItems}
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                    <td>
                        <InputGroup>
                            <InputGroup.Text style={{padding: '2px 6px 2px 6px'}}>$</InputGroup.Text>
                            <Form.Control isInvalid={!holiday.payRate || holiday.payRate <= 0} style={{padding: '2px 6px 2px 6px'}} type='number' min={0} value={holiday.payRate} placeholder='Pay Rate' onChange={(event) => {handleSetHoliday(holiday.id, index, 'payRate', event.target.value.replace('-', ''))}}/>
                        </InputGroup>
                    </td>
                    {holiday.payType !== 'flat' ? <td>
                        <Form.Control style={{padding: '2px 6px 2px 6px'}} isInvalid={!holiday.unitsWorked || holiday.unitsWorked < 0} type='number' min={0} value={holiday.unitsWorked} placeholder='Units' onChange={(event) => {handleSetHoliday(holiday.id, index, 'unitsWorked', event.target.value.replace('-', ''))}}/>
                    </td> : <td>N/A</td>}
                    <td>{bigToUsd(holiday.holidayPay())}</td>
                    <td>
                        <Button style={{color: 'white', padding: '2px 6px 2px 6px'}} onClick={() => removeHoliday(holiday.id, index)}>
                            <FontAwesomeIcon icon={faTrashCan}/>
                        </Button>
                    </td>
                </tr>
            );
        });

        return (
            <React.Fragment key={index}>
                { entry.weeks.length > 1 && week.holidays.length > 0 && 
                    <tr key={index + 'week'}>
                        <td colSpan={4} style={{textAlign: 'center', fontWeight: 'bold'}}>{`Week #${index + 1} (${weekStart.format('MMM D')} - ${weekEnd.format('MMM D')})`}</td>
                    </tr>
                }
                {holidayElements}
            </React.Fragment>
        )
    });



    return (
        <QuickTable responsive={false} title='Holidays' headers={['Pay Type', 'Pay Rate', 'Units Worked','Total', '']} widths={[null,null, null, null, 20]} rows={weekElements} size={'sm'}/>
    )
}