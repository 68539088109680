/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, ListGroup, Modal } from "react-bootstrap";
import moment from "moment";
import PayrollEntry from "../Models/PayrollEntry";
import { getPayrollVersion as getPayrollVersionAdmin, sendBackPayrollPeriod } from "../../../services/PayrollServiceMtb";
import { getPayrollVersion as getPayrollVersionClient } from "../../../services/PayrollServiceClient";
import { adminMode } from "../payrollTools";
import CustomButton from "../../../components/CustomButton";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

export default function PayrollVersionList({payrollPeriod, payrollVersions, latestVersion, handleSetEntries, handleHideEditor, isSendingBack}) {

    const [selectedVersion, setSelectionVersion] = useState(undefined);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showClearModal, setShowClearModal] = useState(false);

    const handleChangePayrollVersion = async () => {
        setIsSubmitting(true);
        let response;
        if (adminMode) {
            response = await getPayrollVersionAdmin(selectedVersion);
        } else {
            response = await getPayrollVersionClient(selectedVersion);
        }

        if (response.status === 200) {
            const entries = PayrollEntry.decodeArray(response.payrollVersion.entries);
            handleSetEntries(entries, true);
        }
        setIsSubmitting(false);
    }

    const handleSendBackPayroll = async () => {
        setIsSubmitting(true);

        const response = await sendBackPayrollPeriod(selectedVersion);
        if (response.status === 200) {
            handleHideEditor();
        }
        setIsSubmitting(false);
    }


    
    const initialVersion = payrollVersions.find(v => !v.userIdentifier && !v.adminIdentifier);
    const ttaSubmission = payrollVersions.find(v => payrollPeriod.ttaSubmission == v.uid);
    const clientSubmission = payrollVersions.find(v => payrollPeriod.clientSubmission == v.uid);

    const ttaVersionList = payrollVersions.filter((v) => v.adminIdentifier && ttaSubmission?.uid != v.uid).map((version)=>{
        return (
            <ListGroup.Item action key={version.uid} active={selectedVersion === version.uid} onClick={()=>{setSelectionVersion(version.uid)}} style={{display:'flex', justifyContent:'space-between'}}>
                <p style={{marginBottom: 0, marginRight: 12}}>TTA</p>
                <p style={{marginBottom: 0, opacity: 0.5}}>{moment(version.dateTime).format('MMM D, YYYY [at] h:mm:ss A')}</p>
            </ListGroup.Item>
        )
    })

    const clientVersionList = payrollVersions.filter((v) => v.userIdentifier && clientSubmission?.uid != v.uid).map((version)=>{
        return (
            <ListGroup.Item action key={version.uid} active={selectedVersion === version.uid} onClick={()=>{setSelectionVersion(version.uid)}} style={{display:'flex', justifyContent:'space-between'}}>
                { parseInt(version.userIdentifier) > 0 ? 
                    <p style={{marginBottom: 0, marginRight: 12}}>{version.user ? version.user.lastName + ', ' + version.user.firstName + ' ' + (version.user?.middleName ?? '').substring(0,1) : ''}</p>
                    :
                    <p style={{marginBottom: 0, marginRight: 12}}>TTA Version Sent Back</p>
                }
                <p style={{marginBottom: 0, opacity: 0.5}}>{moment(version.dateTime).format('MMM D, YYYY [at] h:mm:ss A')}</p>
            </ListGroup.Item>
        )
    })
    
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{isSendingBack ? 'Send Payroll Version Back To IC?' : 'Revert To Previous Version?'}</Modal.Title>
            </Modal.Header>
            <Modal.Header>
                <div style={{display:'flex', width:'100%', flexDirection:'column'}}>
                    <p style={{textDecoration:'underline'}}>{'Version Preloaded into Editor'}</p>
                    { payrollPeriod.ttaSubmission === latestVersion.uid ?
                        <span>TTA Submission</span>
                    : payrollPeriod.clientSubmission === latestVersion.uid ?
                        <span>Company Submission</span>
                    :
                        <div style={{display:'flex', justifyContent:'space-between'}}>
                            { latestVersion.user ? 
                                <span style={{marginRight: 12}}>
                                    {`${latestVersion.user.lastName}, ${latestVersion.user.firstName} ${latestVersion.user.middleName}`}
                                </span>                                    
                                : latestVersion.adminIdentifier ?
                                <span style={{marginRight: 12}}>TTA Save</span>     
                                : latestVersion.userIdentifier ? 
                                <span style={{marginRight: 12}}>TTA Version Sent Back</span>
                                :
                                <span style={{marginRight: 12}}>System Generated Data</span>
                            }
                            <span style={{opacity: 0.5}}>
                                {moment(latestVersion.dateTime).format('MMM D, YYYY [at] h:mm:ss A')}
                            </span>
                        </div>
                    }
                </div>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                { ttaSubmission &&
                    <div>
                        <p style={{textDecoration:'underline'}}>TTA Submission</p>
                        <ListGroup>
                            <ListGroup.Item action active={selectedVersion === ttaSubmission.uid} onClick={() => {setSelectionVersion(ttaSubmission.uid)}} style={{display:'flex', justifyContent:'space-between'}}>
                                <p style={{marginBottom: 0, marginRight: 12}}>TTA Submission</p>
                                <p style={{marginBottom: 0, opacity: 0.5}}>{moment(ttaSubmission.dateTime).format('MMM D, YYYY [at] h:mm:ss A')}</p>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                }
                {ttaVersionList.length > 0 &&
                    <div>
                        <p style={{textDecoration:'underline'}}>TTA Saves</p>
                        <ListGroup>
                            {ttaVersionList}
                        </ListGroup>
                    </div>
                }
                {clientSubmission &&
                    <div>
                        <p style={{textDecoration:'underline'}}>Company Submission</p>
                        <ListGroup>
                            <ListGroup.Item action active={selectedVersion === clientSubmission.uid} onClick={() => {setSelectionVersion(clientSubmission.uid)}} style={{display:'flex', justifyContent:'space-between'}}>
                                <p style={{marginBottom: 0, marginRight: 12}}>{clientSubmission.user ? clientSubmission.user.lastName + ', ' + clientSubmission.user.firstName + ' ' + (clientSubmission.user?.middleName ?? '').substring(0,1) : ''}</p>
                                <p style={{marginBottom: 0, opacity: 0.5}}>{moment(clientSubmission.dateTime).format('MMM D, YYYY [at] h:mm:ss A')}</p>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                }
                {clientVersionList.length > 0 &&
                    <div>
                        <p style={{textDecoration:'underline'}}>Company Saves</p>
                        <ListGroup>
                            {clientVersionList}
                        </ListGroup>
                    </div>
                }
                <div>
                    <ListGroup>
                        <ListGroup.Item action active={selectedVersion === initialVersion.uid} onClick={()=>{setSelectionVersion(initialVersion.uid)}} style={{display:'flex', justifyContent:'space-between'}}>
                            <p style={{marginBottom: 0, marginRight: 12}}>System Generated Data</p>
                            <p style={{marginBottom: 0, opacity: 0.5}}>{moment(initialVersion.dateTime).format('MMM D, YYYY [at] h:mm:ss A')}</p>
                        </ListGroup.Item>
                    </ListGroup>
                </div>
                { !isSendingBack &&
                    <div>
                        <FontAwesomeIcon icon={faInfoCircle} style={{color:'var(--bs-primary)', marginRight: 4}}/>
                        <span>You will lose any unsaved changes when you import a version</span>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <CustomButton label={isSendingBack ? 'Submit' : 'Import Version'} disabled={!selectedVersion} onClick={isSendingBack ? handleSendBackPayroll : handleChangePayrollVersion} isLoading={isSubmitting}/>
            </Modal.Footer>
        </>
    )
}