/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import {Modal, Form, InputGroup, Container, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CustomButton from '../../components/CustomButton';
import CustomControl from '../../components/CustomControl';
import moment from 'moment';
import { sendNotification } from '../../services/NotificationService';
import UsersForEmail from './UsersForEmail';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ValidationGroup, validateExistence, validateNonEmptyArray } from '../../validation';
import CustomCheck from '../../components/CustomCheck';

export default function SendNotificationModal({notification, setNotification, hideModal}) {

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [expirationDate, setExpirationDate] = useState(notification.expirationDate ?? '');
    const [effectiveDate, setEffectiveDate] = useState(notification.effectiveDate ?? '');
    const [titles, setTitles] = useState(notification.titles);
    const [usersForEmail, setUsersForEmail] = useState([]);
    const [showUserSelector, setShowUserSelector] = useState(false);
    const [showSubmitModal, setShowSubmitModal] = useState(false);


    const handleSubmit = async (resend) => {
        setIsSubmitting(true);
        const dataToSend = {
            uid: notification.uid,
            effectiveDate: moment(effectiveDate).format('YYYY-MM-DD HH:mm'),
            expirationDate: expirationDate ? moment(expirationDate).format('YYYY-MM-DD HH:mm') : undefined,
            recipients: titles.join(','),
            usersForEmail: usersForEmail
        }
        const response = await sendNotification(dataToSend, resend);
        if (response.status === '200'){

            let isActive = false;

            if (moment().isSameOrAfter(moment(effectiveDate), 'minute')) {
                if (expirationDate) {
                    isActive = moment().isBefore(moment(expirationDate), 'minute');
                } else {
                    isActive = true;
                }
            }

            setNotification({
                ...notification,
                effectiveDate: moment(effectiveDate).format('YYYY-MM-DD HH:mm'),
                expirationDate: expirationDate ? moment(expirationDate).format('YYYY-MM-DD HH:mm') : undefined,
                isActive: isActive,
                titles,
            })
            hideModal();
        }
        setIsSubmitting(false);
    }

    const handleRemoveUser = (companyUserUid) =>{
        const newArr = usersForEmail.filter((u)=>u.companyUserUid !== companyUserUid);
        setUsersForEmail(newArr);
    }

    const userEmailList = usersForEmail.sort((a,b)=>a.lastName.localeCompare(b.lastName)).map((user)=>{
        return (
            <div key={user.companyUserUid}>
                <InputGroup>
                    <InputGroup.Text style={{fontSize:14}}>{user.lastName + ', ' + user.firstName}</InputGroup.Text>
                    <CustomButton style={{padding:2}} label='Remove' onClick={()=>handleRemoveUser(user.companyUserUid)}/>
                </InputGroup>
            </div>
            
        )
    });

    const validationGroup = new ValidationGroup();

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{notification.isActive ? 'Resend Notification' : 'Send Notification'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                    <Container fluid>
                        <Row>
                            <CustomControl validator={validationGroup.createValidator(() => validateExistence(effectiveDate))} lg={6} type='datetime-local' min={moment().format('YYYY-MM-DD HH:mm')} title='Effective Date' value={effectiveDate} onChange={(event)=>setEffectiveDate(event.target.value)}/>     
                            <CustomControl lg={6} type='datetime-local' min={moment().format('YYYY-MM-DD HH:mm')} title='Expiration Date' value={expirationDate} onChange={(event)=>setExpirationDate(event.target.value)}/>
                        </Row>
                    </Container>
                    
                    <div style={{display:'flex', flexDirection:'row', gap: 12, alignItems: 'center'}}>
                        <span>Send Notification To:</span>
                        <CustomCheck validator={validationGroup.createValidator(() => validateNonEmptyArray(titles))} selection={titles} inline setSelection={setTitles} optionValues={['AO', 'BC', 'Driver', 'Admin']} optionNames={['AO', 'BC', 'Driver', 'Admin']}/>
                    </div>
                    {usersForEmail.length > 0 && 
                        <div>
                            <p style={{fontWeight:'bold', fontSize:20, textAlign:'center'}}>Email List</p>
                            <div style={{display:'flex', gap:6, flexWrap:'wrap'}}>
                                {userEmailList}
                            </div>
                        </div>
                    }
                    <div style={{display:'flex', justifyContent:'flex-end', padding:5, gap:6}}>
                        <CustomButton label='Select Users to Email' onClick={()=>setShowUserSelector(true)}/>
                        <CustomButton disabled={!validationGroup.isValid()} label={notification.isActive ? 'Resend' : 'Send Notification'} onClick={()=>setShowSubmitModal(true)}/>
                        { notification.isActive &&
                            <>
                                <CustomButton isLoading={isSubmitting} label={
                                    <div style={{display: 'flex', gap: 6, alignItems: 'center'}}>
                                        <span>Save Without Resending</span>
                                        <OverlayTrigger overlay={(
                                            <Tooltip>Users who have already acknowledged this notification will not be notified. Email recipients will still recieve an email.</Tooltip>
                                        )}>
                                            <FontAwesomeIcon icon={faQuestionCircle}/>
                                        </OverlayTrigger>
                                    </div>
                                } onClick={() => handleSubmit(false)}/>
                            </>
                        }
                    </div>
                </div>
            </Modal.Body>
            <Modal show={showUserSelector} onHide={()=>setShowUserSelector(false)} size='xl'>
                <UsersForEmail setUsersForEmail={setUsersForEmail} usersForEmail={usersForEmail} hideModal={()=>setShowUserSelector(false)}/>
            </Modal>

            <Modal show={showSubmitModal} onHide={()=>setShowSubmitModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Send Notification?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <CustomButton isLoading={isSubmitting} label='Submit' onClick={() => handleSubmit(true)}/>
                </Modal.Footer>
            </Modal>
        </>
    )
}