/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import Button from 'react-bootstrap/Button';
import CustomSpinner from "../../../components/CustomSpinner";
import PayrollEmployeeEditor from "./PayrollEmployeeEditor/PayrollEmployeeEditor";
import { 
    reviewPayrollPeriod as reviewPayrollPeriodAdmin, 
    approvePayrollPeriod as approvePayrollPeriodAdmin, 
    savePayrollVersion as savePayrollVersionAdmin, 
    deleteCustomPayroll
} from "../../../services/PayrollServiceMtb";
import { 
    reviewPayrollPeriod as reviewPayrollPeriodClient, 
    approvePayrollPeriod as approvePayrollPeriodClient, 
    savePayrollVersion as savePayrollVersionClient, 
} from "../../../services/PayrollServiceClient";

import PayrollEmployeeSelector from "./PayrollEmployeeSelector";
import { bigToUsd, adminMode } from "../payrollTools";
import { ListGroup, CloseButton, Form, ButtonGroup, Popover, OverlayTrigger } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClipboardCheck, faEllipsis } from "@fortawesome/free-solid-svg-icons";
import Card from 'react-bootstrap/Card';
import PayrollCSVDownloadModal from "../PayrollCSVDownloadModal";
import CustomButton from "../../../components/CustomButton";
import PayrollEntry from "../Models/PayrollEntry";
import Big from "big.js";
import PayrollPeriodPreview from "./PayrollPeriodPreview";
import PayrollEditorSavedTime from "./PayrollEditorSavedTime";
import PayrollVersionList from "./PayrollVersionList";
import Cookies from "universal-cookie";
import SendPayrollBackToIC from "./PayrollEmployeeEditor/Modals/SendPayrollBackToIC";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";

export default class PayrollEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isSubmitting: false,
            entries: [],
            selectedEntry: undefined,
            modalSwitch: 'none',

            employees: [],
            
            userData: [],
            previousEntries: [],
            
            employeeFilter: [],

            isSaving: false,
            isSaved: true,
            touched: false,
            dateTimeSave: undefined,
            
            payrollPeriod: undefined,
            payrollVersions: [],

            //ADMIN STUFF:
            
            isCustom: false,
            deleteConfirmation:'',
        }
        this.handleSet = this.handleSet.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleProceed = this.handleProceed.bind(this);
        this.handleAddBonusToAllEntries = this.handleAddBonusToAllEntries.bind(this);
        this.handleAddDeductionToAllEntries = this.handleAddDeductionToAllEntries.bind(this);
        this.handleSetEntries = this.handleSetEntries.bind(this);
    }

    async loadData() {
        this.setState({isLoading: true});

        let response;

        if (adminMode) {
            response = await reviewPayrollPeriodAdmin(this.props.selectedPayPeriod.uid);
        } else {
            response = await reviewPayrollPeriodClient(this.props.selectedPayPeriod.uid);
        }

        if (response.status === 200) {
            let entries = [];
            let noSavedVersions = !response.latestVersion.userIdentifier && !response.latestVersion.adminIdentifier;
            const previousEntries = response.previousPayrollPeriod ? PayrollEntry.decodeArray(response.previousPayrollPeriod.entries ?? []) : undefined;
            entries = PayrollEntry.decodeArray(response.latestVersion.entries);
            if (entries.length > 0 && !entries[0].status) {
                entries[0].status = 'pending';
            }

            const newEmployeeFilter = 
                response.payrollPeriod.isCustom == 1 && noSavedVersions ? [] 
                : response.payrollPeriod.csaIdentifier && noSavedVersions ? entries.filter(e => e.csaName === response.payrollPeriod.csaName).map(e => e.userIdentifier)
                : entries.map(e => e.userIdentifier)
            ;

            
            this.setState({
                payrollPeriod: response.payrollPeriod,
                latestVersion: response.latestVersion,
                employees: response.employees,
                entries: entries.sort(PayrollEntry.sort), 
                employeeFilter: newEmployeeFilter,
                selectedEntry: newEmployeeFilter.length > 0 ? newEmployeeFilter[0] : undefined, 
                previousEntries: previousEntries,
                payrollVersions: response.versions,
                modalSwitch: response.payrollPeriod.isCustom == 1 && noSavedVersions ? 'selectEmployees' : '',
                isCustom: response.payrollPeriod.isCustom == 1,
            });
        }

        this.setState({isLoading: false});
    }

    componentDidMount() {
        this.loadData();
        window.addEventListener("beforeunload", this.handleBeforeUnload);
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.handleBeforeUnload);
    }

    handleBeforeUnload(event) {
        event.preventDefault();
        return (event.returnValue = "");
    }
    

    handleSet(userIdentifier, key, value) {
        const newEntries = Array.from(this.state.entries);

        const entry = newEntries.find(e => e.userIdentifier === userIdentifier);
        entry[key] = value;
        if(key !== 'status'){
            entry.status = 'pending';
        }
        this.setState({entries: newEntries, isSaved: false, touched: key !== 'weekIndex' ? true : false});
    }

    handleAddBonusToAllEntries(type, bonus, weekIndex) {
        const entries = Array.from(this.state.entries);
        entries.forEach((entry)=>{
            entry.weeks[weekIndex][type].push(bonus.duplicate());
        });
        this.setState({entries: entries});
    }

    handleAddDeductionToAllEntries(deduction){
        const newEntries = Array.from(this.state.entries);
        newEntries.forEach(entry => {entry.deductions.push(deduction.duplicate())})
        this.setState({entries: newEntries});
    }

    handleProceed() {
        const filteredEntries = this.state.entries.filter(e => this.state.employeeFilter.includes(e.userIdentifier));

        let index = filteredEntries.map(e => e.userIdentifier).indexOf(this.state.selectedEntry) + 1;
        
        if (index > 0 && index < filteredEntries.length) {
            const selectedEntryObj = filteredEntries[index];
            const userIdentifier = selectedEntryObj.userIdentifier;

            this.setState({selectedEntry: userIdentifier});
            if (!selectedEntryObj.status) {
                this.handleSet(userIdentifier, 'status', 'pending');
            }
        }
    }

    handleSetEntry = (entry) => {
        const newArr = Array.from(this.state.entries);
        const index = newArr.findIndex((e)=> e.userIdentifier === entry.userIdentifier);
        if (index >= 0) {
            newArr[index] = entry;
            this.setState({entries: newArr});
        }
    }

    handleSetEntries(entries, setFilterToEntries) {
        if (setFilterToEntries) {
            this.setState({employeeFilter: entries.map(e=>e.userIdentifier)});
        }
        this.setState({entries: entries.sort(PayrollEntry.sort), selectedEntry: this.state.employeeFilter[0], modalSwitch: undefined});
    }

    async handleSave() {
        this.setState({isSaving:true});
        const filteredEntries = this.state.entries.filter(e => this.state.employeeFilter.includes(e.userIdentifier));
        const encodedEntries = PayrollEntry.encodeArray(filteredEntries);

        const payrollVersion = {
            payrollIdentifier: this.props.selectedPayPeriod.uid,
            gross: filteredEntries.reduce((prev, curr) => {
                return prev.plus(curr.gross());
            }, new Big(0)).toFixed(2),
            entries: encodedEntries
        }

        try {
            let response;

            if (adminMode) {
                response = await savePayrollVersionAdmin(payrollVersion);
            } else {
                response = await savePayrollVersionClient(payrollVersion);
            }

            if (response.status === 200) {
                const userData = new Cookies().get('userData');

                const version = {
                    uid: response.uid,
                    user: {
                        firstName: userData.firstName,
                        middleName: '',
                        lastName: userData.lastName
                    },
                    dateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
                    userIdentifier: adminMode ? undefined : 1,
                    adminIdentifier: adminMode ? 1 : '',
                }

                
                const newArr = Array.from(this.state.payrollVersions);
                newArr.unshift(version);
                this.setState({isSaved: true, dateTimeSave: moment().format('YYYY-MM-DD HH:mm:ss'), payrollVersions: newArr});
                
            }
        } catch (e) {
            console.log(e);
        } finally {
            this.setState({isSaving: false});
        }
        return false;   
    }

    async handleSubmit() {
        this.setState({isSubmitting: true});

        const filteredEntries = this.state.entries.filter(e => this.state.employeeFilter.includes(e.userIdentifier));
        filteredEntries.forEach((entry)=>{
            entry.status = '';
        });
        const encodedEntries = PayrollEntry.encodeArray(filteredEntries);

        const payrollVersion = {
            payrollIdentifier: this.props.selectedPayPeriod.uid,
            gross: filteredEntries.reduce((prev, curr) => {
                return prev.plus(curr.gross());
            }, new Big(0)).toFixed(2),
            entries: encodedEntries
        }

        try {
            let response;
            if (adminMode) {
                response = await approvePayrollPeriodAdmin(payrollVersion);
            } else {
                response = await approvePayrollPeriodClient(payrollVersion);
            }
            if (response.status === 200) {
                this.handleHideEditor();
            }
        } catch (e) {
            console.log(e);
        }
        this.setState({isSubmitting: false});
    }

    handleHideEditor = () => {
        this.setState({modalSwitch:''});
        this.props.hideModal();
        this.props.loadData();
    }

    async handleDeleteCustomPayroll() {
        const response = await deleteCustomPayroll(this.props.selectedPayPeriod.uid);
        if (response.status === 200) {
            this.handleHideEditor();
        } 
    }

    render() {
        const filteredEntries = this.state.entries.filter(e => this.state.employeeFilter.includes(e.userIdentifier));
    
        const locations = [];
        this.state.entries.filter(e => this.state.employeeFilter.includes(e.userIdentifier)).forEach((e) => {
            if (!locations.find(l => l.uid === e.location.uid)) {
                locations.push(e.location);
            }
        })

        const userList = locations.map((location) => {
            const oldUsersInCsa = filteredEntries.filter(e => !e.isNew && e.location.uid === location.uid).map((entry) => {
                const isSelected = entry.userIdentifier === this.state.selectedEntry;

                return (
                    <ListGroup.Item key={entry.userIdentifier} className='cursor-pointer' style={{backgroundColor: isSelected ? 'var(--bs-primary)' : 'white'}} onClick={() => {
                            this.setState({selectedEntry: entry.userIdentifier});
                            if (!entry.status) {
                                this.handleSet(entry.userIdentifier, 'status', 'pending');
                            }
                        }}
                        >
                        <div key={entry.userIdentifier} style={{display: 'flex', justifyContent: 'space-between', minWidth: 300, alignItems:'center'}}>
                            <div style={{display:'flex', gap:10, alignItems:'center'}}>
                                <span style={isSelected ? {color: 'white'} : {}}>
                                    {entry.lastName + ', ' + entry.firstName + ' ' + entry.middleName}
                                </span>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
                                <span style={{color: isSelected ? 'white' : 'black', opacity: 0.5}}>{bigToUsd(entry.gross())}</span>
                                { entry.status &&
                                    <FontAwesomeIcon icon={entry.status === 'approved' ? faCheck : faEllipsis} style={{color: entry.status === 'approved' ? 'green' : 'gold'}}/>
                                }
                            </div>
                        </div>
                    </ListGroup.Item>
                )
            })

            const newUsersInCsa = filteredEntries.filter(e => e.isNew && e.location.uid === location.uid).map((entry) => {
                const isSelected = entry.userIdentifier === this.state.selectedEntry;
                return (
                    <ListGroup.Item key={entry.userIdentifier} className='cursor-pointer' style={{backgroundColor: isSelected ? 'var(--bs-primary)' : 'white'}} onClick={() => {
                            this.setState({selectedEntry: entry.userIdentifier});
                            if (!entry.status) {
                                this.handleSet(entry.userIdentifier, 'status', 'pending');
                            }
                        }}
                        >
                        <div key={entry.userIdentifier} style={{display: 'flex', justifyContent: 'space-between', minWidth: 300}}>
                            <span style={isSelected ? {color: 'white'} : {}}>
                                {entry.lastName + ', ' + entry.firstName + ' ' + entry.middleName + ' (NEW)'}
                            </span>
                            <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
                                <span style={{color: isSelected ? 'white' : 'black', opacity: 0.5}}>{bigToUsd(entry.gross())}</span>
                                { entry.status &&
                                    <FontAwesomeIcon icon={entry.status === 'approved' ? faCheck : faEllipsis} style={{color: entry.status === 'approved' ? 'green' : 'gold'}}/>
                                }
                            </div>
                        </div>
                    </ListGroup.Item>
                )
            })

            return (
                <div key={location.uid} style={{display: 'flex', flexDirection: 'column', gap: 8, marginBottom: 8, alignItems: 'center'}}>
                    <span style={{fontWeight: 'bold'}}>{location.name}</span>
                    <ListGroup>
                        {oldUsersInCsa}
                    </ListGroup>
                    <ListGroup>
                        {newUsersInCsa}
                    </ListGroup>
                </div>
            )
        })
                
        const selectedEntry = this.state.entries.find(e => e.userIdentifier === this.state.selectedEntry);

        const content = (
            <div style={{display: 'flex', flex: 1}}>
                <Card style={{display: 'flex', margin: 8}}>
                    <Card.Body style={{height: '100%'}}>
                        <div style={{overflowY: 'scroll', height: '100%'}}>
                            {userList}
                        </div>
                    </Card.Body>
                </Card>
                
                { selectedEntry &&
                    <PayrollEmployeeEditor
                        key={this.state.selectedEntry}
                        periodStart={this.props.selectedPayPeriod.periodStart} 
                        periodEnd={this.props.selectedPayPeriod.periodEnd}
                        entry={selectedEntry} 
                        previousEntry={this.state.previousEntries?.find(r => r.userIdentifier === selectedEntry.userIdentifier)}
                        handleSet={this.handleSet} 
                        handleProceed={this.handleProceed} 
                        handleAddBonusToAllEntries={this.handleAddBonusToAllEntries}
                        handleAddDeductionToAllEntries={this.handleAddDeductionToAllEntries}
                        handleSetEntry={this.handleSetEntry}
                        selectedPayPeriod={this.props.selectedPayPeriod}
                    />
                }
            </div>
            
        );

        const allApproved = filteredEntries.reduce((prev, curr) => {
            return prev && curr.status === 'approved';
        }, true);

        const confirmationCode = 'delete custom payroll';

        const letterElements = confirmationCode.split('').map((char, index) => {
            return (
                <p key={index} style={{display: 'inline', marginBottom: 0, color: (this.state.deleteConfirmation.length >= (index + 1) && this.state.deleteConfirmation.charAt(index) === char) ? 'green' : 'red'}}>{char}</p>
            )
        });
       
        return (
            <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                <Modal.Header style={{gap:30, alignItems:'center'}}>
                    <Modal.Title>{moment(this.props.selectedPayPeriod.periodStart).format('MMM D, YYYY') + ' - ' + moment(this.props.selectedPayPeriod.periodEnd).format('MMM D, YYYY') + ' Payroll Period'}</Modal.Title>
                    <CustomButton label='Save Changes' disabled={this.state.employeeFilter.length === 0} isLoading={this.state.isSaving} onClick={() => {this.handleSave()}}/>
                    {!this.state.touched ? <span></span> : this.state.isSaving ? <span style={{fontStyle:'italic', opacity:0.5}}>Saving....</span> : this.state.isSaved ? <span style={{fontStyle:'italic', opacity:0.5}}>Saved</span> : this.state.dateTimeSave ? <PayrollEditorSavedTime timeStamp={this.state.dateTimeSave}/> : <span style={{fontStyle:'italic', opacity:0.5}}>You have unsaved changes</span>}
                    { adminMode && this.state.isCustom && 
                        <Button style={{background:'none', color:'red', boxShadow:'none', border:'none'}} onClick={()=>this.setState({modalSwitch:'deleteCustom'})}>Delete Custom Payroll?</Button>
                    }
                    <CloseButton onClick={()=>this.setState({modalSwitch: 'exit'})}/>
                </Modal.Header>
                <Modal.Body style={{display: 'flex', flex: 1, padding: 0, backgroundColor: 'rgb(245, 245, 245)'}}>
                    {this.state.isLoading ? 
                        <div style={{flex: 1}}>
                            <CustomSpinner height={'100%'} spinnerHeight={100} spinnerWidth={100}/> 
                        </div>
                        : 
                        content
                    }
                </Modal.Body>
                <Modal.Footer>
                    <div style={{flex: 1}}/>
                    <ButtonGroup>
                        { adminMode && (this.props.selectedPayPeriod.clientSubmission || this.state.payrollVersions.find(v => v.adminIdentifier)) &&
                            <Button variant={'outline-primary'} onClick={()=>this.setState({modalSwitch:'sendBack'})}>Send Payroll Back To IC</Button>
                        }
                        <Button variant={'outline-primary'} onClick={()=>this.setState({modalSwitch: 'selectVersion'})}>Select Payroll Version</Button>
                        <Button variant={'outline-primary'} onClick={() => {this.setState({modalSwitch: 'selectEmployees'})}}>Change Selected Employees</Button>
                        <Button variant={'outline-primary'} onClick={() => {this.setState({modalSwitch: 'previewSpreadsheet'})}}>Preview Payroll Spreadsheet</Button>
                    </ButtonGroup>
                    <div style={{flex: 0.5}}/>
                    <Button variant={'outline-primary'} disabled={this.state.isLoading || !allApproved || this.state.employeeFilter.length === 0} onClick={() => {this.setState({modalSwitch: 'approve'})}}>Submit Payroll</Button>
                    { !allApproved &&
                        <OverlayTrigger overlay={
                            <Popover>
                                <Popover.Body>You must approve the payroll entries for all employees before submitting</Popover.Body>
                            </Popover>
                        }>
                            <FontAwesomeIcon icon={faQuestionCircle} style={{color: 'var(--bs-primary)'}}/>
                        </OverlayTrigger>
                    }
                </Modal.Footer>
                <Modal show={this.state.modalSwitch === 'approve'} onHide={() => {this.setState({modalSwitch: 'none'})}} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{adminMode ? 'Approve payroll period on behalf of TTA?' : 'Approve payroll on behalf of your company?'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button variant={'outline-primary'} onClick={() => {(this.setState({modalSwitch: 'previewSpreadsheet'}))}}>Preview Payroll Spreadsheet</Button>
                        <CustomButton label='Submit' onClick={this.handleSubmit} isLoading={this.state.isSubmitting}/>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.modalSwitch === 'download'} backdrop='static' centered>
                    <PayrollCSVDownloadModal 
                        entries={filteredEntries} 
                        callBack={() => {this.setState({modalSwitch: 'none'})}}
                        periodStart={this.props.selectedPayPeriod.periodStart} 
                        periodEnd={this.props.selectedPayPeriod.periodEnd} 
                        companyName={this.props.companyName}
                    />
                </Modal>
                <Modal show={this.state.modalSwitch === 'selectEmployees'} onHide={() => {this.setState({modalSwitch: ''})}} size='lg'>
                    <PayrollEmployeeSelector 
                        payrollIdentifier={this.state.payrollPeriod?.uid}
                        entries={this.state.entries} 
                        handleSetEntries={this.handleSetEntries}
                        employeeFilter={this.state.employeeFilter} 
                        setEmployeeFilter={(value) => {this.setState({employeeFilter: value})}} 
                        employees={this.state.employees}
                    />
                </Modal>
                <Modal show={this.state.modalSwitch === 'previewSpreadsheet'} fullscreen onHide={() => {this.setState({modalSwitch: ''})}}>
                    <PayrollPeriodPreview 
                        entries={filteredEntries} 
                        periodStart={this.props.selectedPayPeriod.periodStart} 
                        periodEnd={this.props.selectedPayPeriod.periodEnd}
                        companyName={this.props.companyName}
                    />
                </Modal>
                <Modal centered show={this.state.modalSwitch === 'exit'} onHide={()=>this.setState({modalSwitch: undefined})}>
                    <Modal.Header>
                        <Modal.Title>Are you sure you want to exit?</Modal.Title>
                    </Modal.Header>
                    { this.props.selectedPayPeriod.status === '1' &&
                        <Modal.Body>
                            <span>Saving payroll on behalf of TTA will prevent the client from making any further changes.</span>
                        </Modal.Body>
                    }
                    <Modal.Footer>
                        <CustomButton label='Save & Exit' onClick={()=>{this.handleSave();this.props.hideModal()}}/>
                        <CustomButton label='Exit Without Saving' onClick={()=>{this.props.loadData();this.props.hideModal()}}/>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.modalSwitch == 'selectVersion' || this.state.modalSwitch == 'sendBack'} onHide={()=>this.setState({modalSwitch: undefined})}>
                    <PayrollVersionList 
                        payrollPeriod={this.state.payrollPeriod}
                        payrollVersions={this.state.payrollVersions}
                        latestVersion={this.state.latestVersion}                        
                        handleSetEntries={this.handleSetEntries} 
                        handleHideEditor={this.handleHideEditor}
                        isSendingBack={this.state.modalSwitch == 'sendBack'}
                    />
                </Modal>

                { !adminMode &&
                    <Modal show={this.state.modalSwitch === 'sentBack'} onHide={()=>this.setState({modalSwitch:''})}>
                        <Modal.Header closeButton>
                            <Modal.Title>Payroll Sent Back From TTA</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>This Payroll Period has been sent back from TTA for your revision.</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant={'outline-primary'} onClick={()=>this.setState({modalSwitch:''})}>Okay</Button>
                        </Modal.Footer>
                    </Modal>
                }
                { adminMode &&
                    <Modal show={this.state.modalSwitch === 'deleteCustom'} onHide={()=>this.setState({modalSwitch:''})}>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Custom Payroll?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Once deleted all data from this custom payroll will no longer be accesible. Please be sure you want to delete before continuing.</p>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 12}}>
                            <Form.Group>
                                <Form.Label>Type "{letterElements}" to confirm deletion</Form.Label>
                                <Form.Control isValid={this.state.deleteConfirmation === confirmationCode} isInvalid={!confirmationCode.includes(this.state.deleteConfirmation)} value={this.state.deleteConfirmation} onChange={(event) => {this.setState({deleteConfirmation:event.target.value})}} type='text' placeholder='delete custom payroll'/>
                            </Form.Group>
                            <CustomButton label='Confirm Deletion'  disabled={this.state.deleteConfirmation !== confirmationCode} onClick={()=>this.handleDeleteCustomPayroll()}/>
                        </div>
                        </Modal.Body>
                    </Modal>
                }
            </div>
        );
    }
}