/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import moment from "moment";
import PayrollPeriodHeadersRow from "./PayrollPeriodHeadersRow";
import PayrollPeriodTotalsRow from "./PayrollPeriodTotalsRow";
import PayrollPeriodEntryRow from "./PayrollPeriodEntryRow";
import { PDFViewer, Text, View, Document, Page } from "@react-pdf/renderer";
import { Modal } from "react-bootstrap";

export default function PayrollPeriodPdf({entries, companyName, periodStart, periodEnd, originalEntries}){

    const tableHeaders = PayrollPeriodHeadersRow(entries, periodStart, periodEnd);

    let pageHeaders = [tableHeaders.splice(0, 15)];

    while (tableHeaders.length > 0) {
        const newPage = tableHeaders.splice(0, 15);
        newPage.unshift(pageHeaders[0][0]);
        pageHeaders.push(newPage);
    }
    
    const pages = pageHeaders.map((page, i)=>{
        const width = (100 / page.length).toString()+'%'
        const tableHeaderElements = page.map((th) => {
            return (
                <View key={th} style={{borderRight:'1px solid black', width:width, padding:2, overflow: 'hidden'}}>
                    <Text>{th}</Text>
                </View>
            )
        })
    
        const entryRows = entries.map((entry) => {
            const entryRows = PayrollPeriodEntryRow(entry);
            const originalEntry = originalEntries?.find(e => e.userIdentifier === entry.userIdentifier);
            const originalEntryRows = originalEntry ? PayrollPeriodEntryRow(originalEntry) : undefined;
    
            const entryRowElements = entryRows.map((entryRow, index) => {
                const originalEntryRow = originalEntryRows && originalEntryRows.length > index ? originalEntryRows[index] : undefined;
                
                
                const entryRowElement = page.map((th) => {
                    
                    const colValue = entryRow[th];
                    let highlight = false;
                    if (originalEntryRow) {
                        const originalColValue = originalEntryRow[th];
                        if (colValue !== originalColValue) {
                            highlight = true;
                        }
                    }
    
                    return (
                        <View key={th} style={{width:width,fontSize:8, borderRight:'1px solid black', justifyContent:'center', backgroundColor: highlight ?  'yellow' : '', textAlign:'center', padding:2}}>
                            <Text>{colValue}</Text>
                        </View>
                    )
                });

                let showRow = false;
                for(let i=0; i < page.length; i++){
                    if(entryRow[page[i]]){
                        showRow = true;
                        break;
                    } 
                }
    
                return (
                    <React.Fragment key={index}>
                        {showRow && 
                        <View style={{flexDirection:'row', justifyContent:'space-between', border: '1px gray solid', alignContent:'center', marginBottom:-1}} wrap={false}>
                            {entryRowElement}
                        </View>
                        }
                    </React.Fragment>
                )
            });
    
            return (
                <React.Fragment key={entry.userIdentifier}>
                    {entryRowElements}
                </React.Fragment>
            )
    
        })
       
            const totals = PayrollPeriodTotalsRow(entries);
            const originalTotals = originalEntries ?  PayrollPeriodTotalsRow(originalEntries) : undefined;
            
            
            let tableTotalsElements = page.slice(1).map((th) => {
                let highlight = false;
                if (originalEntries && originalEntries.length > 0) {
                    const originalTotalValue = originalTotals.get(th);
                    if (totals.get(th)!= originalTotalValue) {
                        highlight = true;
                    }
                }
                
                return (
                    <View key={th} style={{display:'flex', width:width,fontSize:8, borderRight:'1px solid black', padding:2, backgroundColor: highlight?  'yellow' : ''}}>
                        <Text style={{textAlign:'center'}}>{totals.get(th)}</Text>
                    </View>
                )
            })
            const gross = (
                <View key={'Gross'} style={{width:width,fontSize:10, borderRight:'1px solid black'}}>
                    <Text style={{textAlign:'center'}}>Totals</Text>
                </View>
            )
            tableTotalsElements.unshift(gross);
        
        return (
            <Page key={i} size='LETTER' style={{padding: '0.5in', flexDirection: 'column'}} orientation='landscape'>
                <View style={{textAlign:'center', textDecoration:'underline', padding:10}}>
                    <Text>{companyName + ' Payroll ' + moment(periodStart).format('MMM D, YYYY') + ' - ' + moment(periodEnd).format('MMM D, YYYY') }</Text>
                </View>
                <View style={{display:'flex', flexDirection:'column', padding:4, marginBottom:10}}>
                    <View style={{width: '100%', fontSize: 9}}>
                        <View style={{flexDirection:'row', border:' 1px solid black', textAlign:'center', display:'flex', flexDirection:'row'}}>
                            {tableHeaderElements}
                        </View>
                        {entryRows}
                        <View style={{flexDirection:'row', justifyContent:'space-between', border: '1px gray solid', alignContent:'center'}}>
                            {tableTotalsElements}
                        </View>
                    </View>
                </View>
            </Page>
        )
        
    })
   
    return (
       <>
            <Modal.Header closeButton>
            <p style={{fontWeight:'bold', fontSize:20}}>{companyName + ' Payroll ' + moment(periodStart).format('MMM D, YYYY') + ' - ' + moment(periodEnd).format('MMM D, YYYY') }</p>
            </Modal.Header>
            <Modal.Body style={{width:'100%', height:'100%'}}>
                <PDFViewer style={{width: '100%', height: '100%'}}>
                    <Document>
                        {pages}
                    </Document>
                </PDFViewer>
            </Modal.Body>   
       </>
        
    )
}

    
