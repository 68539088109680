/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import fakeUsers from "../../fakeData/fakeUsers";
import fakeCompanyUsers from "../../fakeData/fakeCompanyUsers";
import fakeAddresses from "../../fakeData/fakeAddresses";
import { timeout } from "../../tools";
import moment from "moment";
import { getResponse, getToken } from "../serviceTools";

export async function getEmployeeProfile(companyUserIdentifier) {
    var data = {
        type: 'hr',
        target: 'getEmployeeProfile',
        authToken: getToken(),
        companyUserIdentifier: companyUserIdentifier
    }
    return await getResponse(data);
}

export async function updateHRUserProfile(profile) {
    var data = {
        type: 'hr',
        target: 'updateUser',
        authToken: getToken(),
        data: profile
    }

    const response = await getResponse(data);

    return response;
}

export async function changeHRUserPassword(companyUserIdentifier, newPassword, sendEmail) {
    var data = {
        type: 'hr',
        target: 'changePassword',
        authToken: getToken(),
        companyUserIdentifier: companyUserIdentifier,
        newPassword: newPassword,
        sendEmail: sendEmail
    }

    const response = await getResponse(data);

    return response;
}