/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";

import CustomControl from "../../../components/CustomControl";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from 'react-bootstrap/Container';
import AddressControl from "../../../components/AddressControl";
import RadioControl from "../../../components/RadioControl";
import AvailabilityControl from "../../../components/AvailabilityControl";
import SwitchControl from "../../../components/SwitchControl";

export default function CreateCompanyInformation({companyInfo, handleSetCompanyInfo, validators}) {

    return (
        <Container fluid>
            <Row>
                <CustomControl validator={validators.companyName} lg={3} type='text' max={30} title='Company Name' value={companyInfo.companyName} onChange={(event) => handleSetCompanyInfo('companyName', event.target.value)}/>
                <CustomControl lg={3} type='text' max={30} title='FedEx Username' value={companyInfo.fedexUsername} onChange={(event) => handleSetCompanyInfo('fedexUsername', event.target.value)}/>
                <CustomControl lg={3} type='text' max={30} title='FedEx Password' value={companyInfo.fedexPassword} onChange={(event) => handleSetCompanyInfo('fedexPassword', event.target.value)}/>
                <CustomControl lg={3} type='text' max={30} title='WC Username' value={companyInfo.wcUsername} onChange={(event) => handleSetCompanyInfo('wcUsername', event.target.value)}/>
                <CustomControl lg={3} type='text' max={30} title='WC Password' value={companyInfo.wcPassword} onChange={(event) => handleSetCompanyInfo('wcPassword', event.target.value)}/>
                <CustomControl lg={3} type='text' max={30} title='WC Policy Number' value={companyInfo.wcPolicyNumber} onChange={(event) => handleSetCompanyInfo('wcPolicyNumber', event.target.value)}/>
                <CustomControl validator={validators.phoneNumber} required lg={3} max={10} type='text' title='Phone Number' value={companyInfo.phoneNumber} onChange={(event) => handleSetCompanyInfo('phoneNumber', event.target.value)}/>
                <CustomControl lg={3} type='text' max={30} title='EIN' value={companyInfo.ein} onChange={(event) => handleSetCompanyInfo('ein', event.target.value)}/>
                <CustomControl lg={12} type='text' max={30} title='Benefits' value={companyInfo.benefits} onChange={(event) => handleSetCompanyInfo('benefits', event.target.value)}/>
                <Col xxl={12}>
                    <AddressControl validator={validators.address} lg={4} title='Address' address={companyInfo.address} setAddress={(value) => {handleSetCompanyInfo('address', value)}}/>
                </Col>
                
                <SwitchControl lg={6} value={companyInfo.forceMfa} setValue={(value) => {handleSetCompanyInfo('forceMfa', value)}} title='Force all employees to use MFA'/>
                <SwitchControl lg={6} value={companyInfo.forceLocation} setValue={(value) => {handleSetCompanyInfo('forceLocation', value)}} title='Force location services when clocking in and out'/>
                <SwitchControl lg={6} value={companyInfo.companyAddressAllowClockIn} setValue={(value) => {handleSetCompanyInfo('companyAddressAllowClockIn', value)}} title='Allow clock-in at company address'/>
                { companyInfo.companyAddressAllowClockIn === '1' &&
                    <div style={{width: '100%', paddingLeft: 24, borderLeft: '1px solid lightgray'}}>
                        <CustomControl validator={validators.companyAddressClockInRadius} lg={3} type='number' max={30} title='Company Address Clock-In Radius' value={companyInfo.companyAddressClockInRadius} onChange={(event) => handleSetCompanyInfo('companyAddressClockInRadius', event.target.value)}/>
                    </div>
                }
                <SwitchControl lg={6} value={companyInfo.enableGeofencing} setValue={(value) => {handleSetCompanyInfo('enableGeofencing', value)}} title='Enable Geofencing'/>
                <SwitchControl lg={6} value={companyInfo.allowBCAccessToSensitiveInformation} setValue={(value) => {handleSetCompanyInfo('allowBCAccessToSensitiveInformation', value)}} title='Allow BCs access to sensitive information'/>
                <SwitchControl lg={6} value={companyInfo.enableBCNotifications} setValue={(value) => {handleSetCompanyInfo('enableBCNotifications', value)}} title='Enable BC Notifications'/>
                <SwitchControl lg={6} value={companyInfo.restrictBcsToCsa} setValue={(value) => {handleSetCompanyInfo('restrictBcsToCsa', value)}} title='Restrict BCs to their own CSAs'/>
                <SwitchControl lg={6} value={companyInfo.sendFirstLoginText} setValue={(value) => {handleSetCompanyInfo('sendFirstLoginText', value)}} title='Send First Login Text'/>
                <CustomControl lg={6} value={companyInfo.driverSchedulePreviewDays} onChange={(event) => {handleSetCompanyInfo('driverSchedulePreviewDays', event.target.value)}} title='Driver Schedule Preview Days' type='number'/>
                <SwitchControl lg={6} value={companyInfo.payrollEnabled} setValue={(value) => {handleSetCompanyInfo('payrollEnabled', value)}} title='Enable Payroll for Company'/>
            </Row>
            { companyInfo.payrollEnabled == '1' &&
                <Row style={{border: '1px solid lightgray', borderRadius: 6, padding: 8}}>
                    <Col xxl={12} style={{textAlign: 'center', marginBottom: 8, fontWeight: 'bold'}}>
                        Payroll
                    </Col>
                    <RadioControl lg={6} selection={companyInfo.payrollPeriodLength} setSelection={(value) => {handleSetCompanyInfo('payrollPeriodLength', value)}} title='Payroll Period Length' inline optionNames={['7 Days', '14 Days']} optionValues={[7, 14]}/>
                    <RadioControl lg={6} selection={companyInfo.payrollPeriodStartDate} setSelection={(value) => {handleSetCompanyInfo('payrollPeriodStartDate', value)}} title='Payroll Period Start Date' inline optionNames={['Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri']} optionValues={['Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri']}/>
                    <SwitchControl lg={6} value={companyInfo.bcPayrollEnabled} setValue={(value) => {handleSetCompanyInfo('bcPayrollEnabled', value)}} title='Enable Payroll for BCs'/>
                    <SwitchControl lg={6} value={companyInfo.showBcsPayrollHistory} setValue={(value) => {handleSetCompanyInfo('showBcsPayrollHistory', value)}} title='Allow BCs to view payroll history'/>
                    <SwitchControl lg={6} value={companyInfo.generatePayrollForEachCsa} setValue={(value) => {handleSetCompanyInfo('generatePayrollForEachCsa', value)}} title='Generate Payroll For Each CSA'/>
                    <SwitchControl lg={6} value={companyInfo.includeAOsInPayroll} setValue={(value) => {handleSetCompanyInfo('includeAOsInPayroll', value)}} title='Include AOs In Payroll'/>
                </Row>
            }
        </Container>
    )
}