/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useEffect, useState } from "react";
import { createLinehaulToken, getAllPayroll } from "../../services/PayrollServiceLh";
import { Button, Card, ListGroup } from "react-bootstrap";
import { getPayrollStatus } from "./payrollTools";
import { formatDateRange, usdFormatter } from "../../tools";
import Cookies from "universal-cookie";
import { DEV_MODE } from "../../services/serviceTools";
import PageSpinner from "../../components/PageSpinner";

export default function LHPayroll({}) {
    const [isLoading, setIsLoading] = useState(false);

    const [payrollPeriods, setPayrollPeriods] = useState([]);

    useEffect(() => {
        loadData();
    }, [])

    async function loadData() {
        setIsLoading(true);
        const response = await getAllPayroll();
        if (response.status == '200') {
            setPayrollPeriods(response.payrollPeriods);
        }
        setIsLoading(false);
    }

    async function handleCreateLinehaulToken(payrollIdentifier) {
        const response = await createLinehaulToken();
        if (response.status == '200') {
            const elem = window.document.createElement('a');
            elem.href = DEV_MODE ? 
                `http://localhost:3000/authenticate?authToken=${response.token}&destination=payroll/${payrollIdentifier}` 
            : 
                `https://mytacticaltrucking.com/authenticate?authToken=${response.token}&destination=payroll/${payrollIdentifier}`
            ;
            elem.target = '_blank';
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
            
        }
    }

    const editablePayrollPeriods = payrollPeriods.filter(p => !p.ttaSubmission).map((p) => {
        return (
            <PayrollPeriodItem key={p.uid} payrollPeriod={p} handleCreateLinehaulToken={handleCreateLinehaulToken}/>
        )
    })

    const historicPayrollElements = payrollPeriods.filter(p => p.ttaSubmission).map((payrollPeriod) => {
        return (
            <ListGroup.Item key={payrollPeriod.uid} action onClick={() => {handleCreateLinehaulToken(payrollPeriod.uid)}}>
                <div style={{padding: '4px 8px', backgroundColor: 'var(--bs-card-cap-bg)', borderTopLeftRadius: 6, borderTopRightRadius: 6, fontSize: 20}}>
                    <div style={{display:'flex', justifyContent:'space-between', gap: 6, flexWrap: 'wrap'}}>
                        <span style={{fontWeight: 'bold'}}>{payrollPeriod.companyName}</span>
                        <span style={{fontWeight: 'bold'}}>{formatDateRange(payrollPeriod.periodStart, payrollPeriod.periodEnd)}</span>
                        <span>{payrollPeriod.terminalName ?? 'All Terminals'}</span>
                    </div>
                </div>
            </ListGroup.Item>
        )
    });

    return (
        <div style={{padding: 12, display: 'flex', flexDirection: 'column', gap: 12, height: '100%', overflowY: 'auto'}}>
            { isLoading ? <PageSpinner/> : 
                <>
                    <p style={{opacity: 0.5, fontWeight: 'bold', fontSize: 14, marginBottom: 6}}>EDITABLE PAYROLL PERIODS</p>
                    <ListGroup style={{gap: 8}}>
                        {editablePayrollPeriods}
                    </ListGroup>
                    <p style={{opacity: 0.5, fontWeight: 'bold', fontSize: 14, marginBottom: 6}}>HISTORIC PAYROLL PERIODS</p>
                    <ListGroup style={{gap: 8}}>
                        {historicPayrollElements}
                    </ListGroup>
                </>
            }
        </div>
    )
}

function PayrollPeriodItem({payrollPeriod, handleCreateLinehaulToken}) {
    return (
        <Card>
            <Card.Header style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', gap: 6}}>
                <Card.Title style={{margin: 0}}>{`${payrollPeriod.terminalName ? `${payrollPeriod.terminalName} | ` : ''}${formatDateRange(payrollPeriod.periodStart, payrollPeriod.periodEnd)}`}</Card.Title>
                { !!payrollPeriod.isCustom && 
                    <span style={{fontWeight: 'bold'}}>*Custom Payroll*</span>
                }
                <Button variant='outline-primary' onClick={() => {handleCreateLinehaulToken(payrollPeriod.uid)}}>View</Button>
            </Card.Header>
            <Card.Body style={{padding: 12}}>
                <div style={{display: 'flex', flexWrap: 'wrap', whiteSpace: 'nowrap', alignItems: 'center', gap: 6}}>
                    <span>Status:</span>
                    <div style={{borderTop: '2px dotted lightgray', flex: 1, marginTop: 'auto', paddingBottom: 6}}/>
                    <span style={{fontWeight: 'bold'}}>{getPayrollStatus(payrollPeriod, payrollPeriod.terminalCount)}</span>
                </div>
                { !!payrollPeriod.gross && 
                    <div style={{display: 'flex', flexWrap: 'wrap', whiteSpace: 'nowrap', alignItems: 'center', gap: 6}}>
                        <span>Gross:</span>
                        <div style={{borderTop: '2px dotted lightgray', flex: 1, marginTop: 'auto', paddingBottom: 6}}/>
                        <span style={{fontWeight: 'bold', color: 'green'}}>{usdFormatter.format(payrollPeriod.gross)}</span>
                    </div>
                }

            </Card.Body>
        </Card>
    )
}