/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import AddressControl from "../../components/AddressControl";
import CustomControl from "../../components/CustomControl";
import { getCompanyProfile, updateCompanyProfile } from "../../services/CompanyServices/CompanyProfileService";
import PageSpinner from "../../components/PageSpinner";
import TextAreaControl from "../../components/TextAreaControl";
import LoadingButton from "../../components/LoadingButton";
import { timeout } from "../../tools";
import CustomButton from "../../components/CustomButton";

export default function CompanyProfile({selectedCompany}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [companyProfile, setCompanyProfile] = useState(undefined)
    const [originalCompanyProfile, setOriginalCompanyProfile] = useState(undefined);

    const loadData = async () => {
        setIsLoading(true);
        const response = await getCompanyProfile(selectedCompany.uid);
        if (response.status === '200') {
            setCompanyProfile(response.profile);
            setOriginalCompanyProfile(response.profile);
        }
        setIsLoading(false);
    }


    useEffect(() => {
        loadData();
    }, [])

    const handleSetSettings = (key, value) => {
        const newObj = structuredClone(companyProfile);
        newObj[key] = value;
        setCompanyProfile(newObj);
    }

    const handleSaveChanges = async () => {
        setIsSaving(true);
        const response = await updateCompanyProfile(companyProfile);
        if (response.status === '200') {
            setOriginalCompanyProfile(companyProfile);
        }
        setIsSaving(false);
    }

    const content = !companyProfile ? '' : (
        <Container fluid>
            <Row>
                <Col xxl={12}>
                    <p style={{textAlign: 'center', margin: 0, fontSize: 24, fontWeight: 'bold'}}>{companyProfile.companyName}</p>
                </Col>
                <AddressControl containerBreakPoints={{xl: 12}} lg={6} xl={4} title='Company Address' originalValue={originalCompanyProfile.address} address={companyProfile.address} setAddress={(value) => {handleSetSettings('address', value)}}/>
                <CustomControl lg={6} xl={4} type='text' title='Phone Number' originalValue={originalCompanyProfile.phoneNumber} value={companyProfile.phoneNumber} onChange={(event) => {handleSetSettings('phoneNumber', event.target.value)}}/>
                <CustomControl lg={6} xl={4} type='text' title='MyGroundBiz Username' originalValue={originalCompanyProfile.fedexUsername} value={companyProfile.fedexUsername} onChange={(event) => {handleSetSettings('fedexUsername', event.target.value)}}/>
                <CustomControl lg={6} xl={4} type='text' title='MyGroundBiz Password' originalValue={originalCompanyProfile.fedexPassword} value={companyProfile.fedexPassword} onChange={(event) => {handleSetSettings('fedexPassword', event.target.value)}}/>
                <CustomControl lg={6} xl={4} type='text' title='BWC Username' originalValue={originalCompanyProfile.wcUsername} value={companyProfile.wcUsername} onChange={(event) => {handleSetSettings('wcUsername', event.target.value)}}/>
                <CustomControl lg={6} xl={4} type='text' title='BWC Password' originalValue={originalCompanyProfile.wcPassword} value={companyProfile.wcPassword} onChange={(event) => {handleSetSettings('wcPassword', event.target.value)}}/>
                <CustomControl max={10} md={6} xl={4} type='text' title='BWC Policy #' originalValue={originalCompanyProfile.wcPolicyNumber ? originalCompanyProfile.wcPolicyNumber : ''} value={companyProfile.wcPolicyNumber ? companyProfile.wcPolicyNumber : ''} onChange={(event) => {handleSetSettings('wcPolicyNumber', event.target.value)}}/>
                <CustomControl lg={6} xl={4} type='text' title='EIN' originalValue={originalCompanyProfile.ein} value={companyProfile.ein ? companyProfile.ein : ''} onChange={(event) => {handleSetSettings('ein', event.target.value)}}/>
                <TextAreaControl xxl={12} rows={4} label='Company Benefits' originalValue={originalCompanyProfile.benefits} value={companyProfile.benefits} setValue={(value) => {handleSetSettings('benefits', value)}}/>

                <Col xxl={12} style={{marginBottom: 8, display: 'flex', flexDirection: 'column'}}>
                    <CustomButton label='Save Changes' style={{alignSelf: 'flex-end'}} isLoading={isSaving} onClick={handleSaveChanges}/>
                </Col>
            </Row>
        </Container>
    )

    return (
        <>
            {isLoading ? <PageSpinner/> : content}
        </>
            
    )
}