/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { Button, Card, Modal, Row } from "react-bootstrap";
import { AppGlobals } from "../../App";
import { deleteAuthenticationTokens } from "../../services/AdminToolsService";
import CustomControl from "../../components/CustomControl";
import CustomButton from "../../components/CustomButton";
import { useState } from "react";
import moment from "moment";

export default function DeleteAuthenticationTokens({}){

    const [showModal, setShowModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [tokenDate, setTokenDate] = useState('');

    async function handleDeleteTokens(){
        setIsSubmitting(true);
        const response = await deleteAuthenticationTokens(tokenDate);
        if(response.status == 200){
            AppGlobals.alert('success', response.message);
            setShowModal(false)
        }else{
            AppGlobals.alert('danger', response.message);
        }
        setIsSubmitting(false);
    }

    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title>Delete Authentication Tokens</Card.Title>
                </Card.Header>
                <Card.Body>
                <Row style={{alignItems: 'center'}}>
                    <CustomControl sm={12} max={moment().format('YYYY-MM-DD')} title={'Delete Tokens Before Date'} type='date' value={tokenDate} onChange={(e)=>setTokenDate(e.target.value)}/>
                </Row>
                </Card.Body>
                <Card.Footer>
                    <Button disabled={!moment(tokenDate).isValid()} onClick={()=>setShowModal(true)} style={{display: 'block', marginLeft: 'auto'}}>
                        Delete Tokens
                    </Button>
                </Card.Footer>
            </Card>
            <Modal show={showModal} onHide={()=>setShowModal(false)}>
               <Modal.Header closeButton>
                    <Modal.Title>Delete Confirmation</Modal.Title>
               </Modal.Header>
               <Modal.Body style={{fontSize: 14}}>
                    Are you sure you want to delete all tokens before {moment(tokenDate).format('MMMM DD, YYYY')}?
               </Modal.Body>
               <Modal.Footer>
                    <CustomButton isLoading={isSubmitting} label={'Delete'} onClick={handleDeleteTokens}/>
               </Modal.Footer>
            </Modal>
        </>
    )
}