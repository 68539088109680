/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from "../serviceTools";

export async function getCompanySettings(companyIdentifier) {
    var data = {
        type: 'company',
        target: 'getCompanySettings',
        authToken: getToken(),
        companyIdentifier: companyIdentifier
    }
    return await getResponse(data);
}

export async function updateCompanySettings(settings) {
    var data = {
        type: 'company',
        target: 'updateCompanySettings',
        authToken: getToken(),
        settings: settings
    }
    return await getResponse(data);
}