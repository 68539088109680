
/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomControl from '../../../components/CustomControl';
import RadioControl from '../../../components/RadioControl';
import { reviseRideAlongRecord, createRideAlongRecord } from '../../../services/HRServices/HRSafetyService';
import CustomButton from '../../../components/CustomButton';
import TextAreaControl from '../../../components/TextAreaControl';

export default function HRUserSafetyRideAlongForm({users, selectedSafetyRecord, handleAddRecord, handleReviseRecord}) {

    const [date, setDate] = useState(selectedSafetyRecord ? selectedSafetyRecord.date : moment().format('YYYY-MM-DD'));
    const [notes, setNotes] = useState(selectedSafetyRecord ? selectedSafetyRecord.notes : '');
    const [rideAlongType, setRideAlongType] = useState(selectedSafetyRecord ? selectedSafetyRecord.rideAlongType : '');
    const [performance, setPerformance] = useState(selectedSafetyRecord ? selectedSafetyRecord.performance : '');

    const [confirmSubmission, setConfirmSubmission] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    // attachments
    // author
    // submissionDate

    const handleSubmit = () => {
        setIsLoading(true);
        setConfirmSubmission(false);
        const newRideAlong = {
            rideAlongID: selectedSafetyRecord ? selectedSafetyRecord.rideAlongID : undefined,
            date: date,
            notes: notes,
            rideAlongType: rideAlongType,
            performance: performance,

        }

        if (selectedSafetyRecord) {
            reviseRideAlongRecord(newRideAlong).then((response) => {
                if (response && response.status === '200') {
                    newRideAlong.uid = response.uid;
                    handleReviseRecord(newRideAlong, 'Ride-Along');
                }
                setIsLoading(false);
            });
        } else {
            createRideAlongRecord(newRideAlong).then((response) => {
                if (response && response.status === '200') {
                    newRideAlong.rideAlongID = response.uid;
                    newRideAlong.uid = response.versionID;
                    handleAddRecord(newRideAlong);
                }
                setIsLoading(false);
            })
        }
    }

    const rideAlongTypeDictionary = {
        newHire: 'New Hire', safety: 'Safety', service: 'Service', customerService: 'Customer Service', productivity: 'Productivity'
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{selectedSafetyRecord ? 'Creating New Version' : 'Creating New Ride-Along Report'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <CustomControl disabled={isLoading} originalValue={selectedSafetyRecord ? selectedSafetyRecord.date : undefined} lg={4} type='date' title='Date' value={date} onChange={(event) => {setDate(event.target.value)}}/>
                        <Col>
                            <RadioControl disabled={isLoading} originalValue={selectedSafetyRecord ? selectedSafetyRecord.rideAlongType : undefined} title='Ride-Along Type' selection={rideAlongType} setSelection={setRideAlongType} optionNames={Object.values(rideAlongTypeDictionary)} optionValues={Object.keys(rideAlongTypeDictionary)}/>
                        </Col>
                        <Col>
                            <RadioControl disabled={isLoading} originalValue={selectedSafetyRecord ? selectedSafetyRecord.performance : undefined} title='Employee Performance' selection={performance} setSelection={setPerformance} optionNames={['Poor', 'Adequate', 'Excellent']} optionValues={['poor', 'adequate', 'excellent']}/>
                        </Col>

                        <Col lg={12} style={{marginBottom: 8}}>
                            <Form.Group>
                                <Form.Label>Attachments</Form.Label>  
                                <Form.Control disabled={isLoading} type='file' multiple/>
                            </Form.Group>
                        </Col>

                        <Col xxl={12} style={{marginBottom: 8}}>
                            <TextAreaControl label='Notes' originalValue={selectedSafetyRecord ? selectedSafetyRecord.notes : undefined} disabled={isLoading} rows={4} value={notes} setValue={setNotes}/>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton label='Submit' isLoading={isLoading} onClick={() => {setConfirmSubmission(true)}}/>
            </Modal.Footer>
            <Modal show={confirmSubmission} centered onHide={() => {setConfirmSubmission(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Submission</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Once submitted, the entered information will become permanent record. Proceed?
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{color: 'white'}} onClick={() => {setConfirmSubmission(false)}}>Cancel</Button>
                    <Button style={{color: 'white'}} onClick={handleSubmit}>Confirm Submission</Button>
                </Modal.Footer>
            </Modal>

        </>
    )
    
}
