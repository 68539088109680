
/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import CustomButton from '../../../components/CustomButton';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomControl from '../../../components/CustomControl';
import AddressControl from '../../../components/AddressControl';
import RadioControl from '../../../components/RadioControl';
import DropdownControl from '../../../components/DropdownControl';
import SwitchControl from '../../../components/SwitchControl';
import TextAreaControl from '../../../components/TextAreaControl';
import { reviseAccidentRecord, createAccidentRecord } from '../../../services/HRServices/HRSafetyService';


function HRUserSafetyAccidentForm({users, selectedSafetyRecord, handleAddRecord, handleReviseRecord}) {

    const [accidentType, setAccidentType] = useState(selectedSafetyRecord ? selectedSafetyRecord.accidentType : 'structure');
    const [isBackingAccident, setIsBackingAccident] = useState(selectedSafetyRecord ? selectedSafetyRecord.isBackingAccident : false);
    const [injuries, setInjuries] = useState(selectedSafetyRecord ? selectedSafetyRecord.notes : '');
    const [dateTime, setDateTime] = useState(selectedSafetyRecord ? selectedSafetyRecord.dateTime : moment().format('YYYY-MM-DD HH:mm'));
    const [address, setAddress] = useState(selectedSafetyRecord ? selectedSafetyRecord.address : {thoroughfare: '', premise: '', administrativeArea: '', locality: '', postalCode: ''});
    const [cost, setCost] = useState(selectedSafetyRecord ? selectedSafetyRecord.cost : 0);
    const [notes, setNotes] = useState(selectedSafetyRecord ? selectedSafetyRecord.notes : '');
    const [propertyOwnerContacted, setPropertyOwnerContacted] = useState(selectedSafetyRecord ? selectedSafetyRecord.propertyOwnerContacted : false);
    const [propertyOwnerFirstName, setPropertyOwnerFirstName] = useState(selectedSafetyRecord ? selectedSafetyRecord.propertyOwnerFirstName : '');
    const [propertyOwnerLastName, setPropertyOwnerLastName] = useState(selectedSafetyRecord ? selectedSafetyRecord.propertyOwnerLastName : '');
    const [propertyOwnerEmail, setPropertyOwnerEmail] = useState(selectedSafetyRecord ? selectedSafetyRecord.propertyOwnerEmail : '');
    const [propertyOwnerPhoneNumber, setPropertyOwnerPhoneNumber] = useState(selectedSafetyRecord ? selectedSafetyRecord.propertyOwnerPhoneNumber : '');
    const [propertyOwnerAddress, setPropertyOwnerAddress] = useState(selectedSafetyRecord ? selectedSafetyRecord.propertyOwnerAddress : {thoroughfare: '', premise: '', administrativeArea: '', locality: '', postalCode: ''});
    const [propertyOwnerInsuranceCompany, setPropertyOwnerInsuranceCompany] = useState(selectedSafetyRecord ? selectedSafetyRecord.propertyOwnerInsuranceCompany : '');
    const [policeContacted, setPoliceContacted] = useState(selectedSafetyRecord ? selectedSafetyRecord.policeContacted : false);
    const [policeReportNumber, setPoliceReportNumber] = useState(selectedSafetyRecord ? selectedSafetyRecord.policeReportNumber : '');
    const [reporterToFedex, setReporterToFedex] = useState(selectedSafetyRecord ? selectedSafetyRecord.reporterToFedex : undefined);

    const [wasReported, setWasReported] = useState(selectedSafetyRecord && selectedSafetyRecord.reporterToFedex ? true : false);

    const [confirmSubmission, setConfirmSubmission] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    // attachments
    // author
    // submissionDate

    const handleSubmit = () => {
        setIsLoading(true);
        setConfirmSubmission(false);
        const newAccident = {
            accidentID: selectedSafetyRecord ? selectedSafetyRecord.accidentID : undefined,
            accidentType: accidentType,
            isBackingAccident: isBackingAccident,
            injuries: injuries,
            dateTime: dateTime,
            address: address,
            cost: cost,
            notes: notes,
            propertyOwnerContacted: propertyOwnerContacted,
            propertyOwnerFirstName: propertyOwnerFirstName,
            propertyOwnerLastName: propertyOwnerLastName,
            propertyOwnerEmail: propertyOwnerEmail,
            propertyOwnerPhoneNumber: propertyOwnerPhoneNumber,
            propertyOwnerAddress: propertyOwnerAddress,
            propertyOwnerInsuranceCompany: propertyOwnerInsuranceCompany,
            policeContacted: policeContacted,
            policeReportNumber: policeReportNumber,
            reporterToFedex: reporterToFedex
        }

        if (selectedSafetyRecord) {
            reviseAccidentRecord(newAccident).then((response) => {
                if (response && response.status === '200') {
                    newAccident.uid = response.uid;
                    handleReviseRecord(newAccident, 'Accident');
                }
                setIsLoading(false);
            });
        } else {
            createAccidentRecord(newAccident).then((response) => {
                if (response && response.status === '200') {
                    newAccident.accidentID = response.uid;
                    newAccident.uid = response.versionID;
                    handleAddRecord(newAccident);
                }
                setIsLoading(false);
            })
        }
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{selectedSafetyRecord ? 'Revising Accident' : 'Creating New Accident Report'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <CustomControl lg={4} disabled={isLoading} originalValue={selectedSafetyRecord ? selectedSafetyRecord.dateTime : undefined} type='datetime-local' title='Date & Time' value={dateTime} onChange={(event) => {setDateTime(event.target.value)}}/>
                        <CustomControl lg={4} disabled={isLoading} originalValue={selectedSafetyRecord ? selectedSafetyRecord.cost : undefined} type='number' title='Total Cost' value={cost} onChange={(event) => {setCost(event.target.value)}}/>
                        <Col lg={4} style={{marginBottom: 8}}>
                            <RadioControl disabled={isLoading} originalValue={selectedSafetyRecord ? selectedSafetyRecord.accidentType : undefined} title='Accident Type' selection={accidentType} setSelection={setAccidentType} optionValues={['structure', 'vehicle', 'person', 'other']} optionNames={['Structure', 'Vehicle', 'Person', 'Other']} inline/>
                        </Col>
                        { accidentType !== 'person' ? '' :
                            <CustomControl lg={6} disabled={isLoading} originalValue={selectedSafetyRecord ? selectedSafetyRecord.injuries : undefined} type='text' title='Description of Injuries' value={injuries} onChange={(event) => {setInjuries(event.target.value)}}/>
                        }
                        <Col style={{marginBottom: 8}}>
                            <SwitchControl title='Backing Accident?' disabled={isLoading} originalValue={selectedSafetyRecord ? selectedSafetyRecord.isBackingAccident : undefined} value={isBackingAccident} toggleValue={() => {setIsBackingAccident(!isBackingAccident)}}/>
                        </Col>
                        <Col lg={4} style={{marginBottom: 8}}>
                            <Form.Group style={{border: '1px lightgray solid', borderRadius: 6, padding: 12}}>
                                <Form.Check disabled={isLoading} type='switch' label='Reported To FedEx?' checked={wasReported} onChange={() => {setWasReported(!wasReported)}}/>
                                { !wasReported ? '' : 
                                    <DropdownControl title='Who Reported?' selection={reporterToFedex} setSelection={setReporterToFedex} itemNames={users.map(u => u.firstName + ' ' + u.lastName)} itemValues={users.map(u => u.userID)}/>
                                }
                            </Form.Group>
                        </Col>
                        <Col lg={4} style={{marginBottom: 8}}>
                            <Form.Group style={{border: '1px lightgray solid', borderRadius: 6, padding: 12}}>
                                <Form.Check disabled={isLoading} type='switch' label='Police Contacted?' checked={policeContacted} onChange={() => {setPoliceContacted(!policeContacted)}} style={{marginBottom: 8}}/>
                                { !policeContacted ? '' :
                                    <FloatingLabel label='Police Report Number'>
                                        <Form.Control disabled={isLoading} type='text' placeholder=' ' value={policeReportNumber} onChange={(event) => {setPoliceReportNumber(event.target.value)}}/>
                                    </FloatingLabel>
                                }
                            </Form.Group>
                        </Col>

                        <Col xxl={12} style={{marginBottom: 8}}>
                            <AddressControl disabled={isLoading} title='Address' originalValue={selectedSafetyRecord ? selectedSafetyRecord.address : undefined} address={address} setAddress={setAddress} lg={6} xl={4}/>
                        </Col>

                        <Col xxl={12} style={{marginBottom: 8}}>
                            <Container style={{border: '1px lightgray solid', borderRadius: 6, paddingBottom: 12}}>
                                <Row>
                                    <Col xxl={12}>
                                        <Form.Check disabled={isLoading} style={{margin: 12}} type='switch' label='Property Owner Contacted?' checked={propertyOwnerContacted} onChange={() => {setPropertyOwnerContacted(!propertyOwnerContacted)}}/>
                                    </Col>
                                    {!propertyOwnerContacted ? '' : 
                                        <>
                                            <CustomControl lg={4} disabled={isLoading} originalValue={selectedSafetyRecord ? selectedSafetyRecord.propertyOwnerFirstName : undefined} type='text' title='First Name' value={propertyOwnerFirstName} onChange={(event) => {setPropertyOwnerFirstName(event.target.value)}}/>
                                            <CustomControl lg={4} disabled={isLoading} originalValue={selectedSafetyRecord ? selectedSafetyRecord.propertyOwnerLastName : undefined} type='text' title='Last Name' value={propertyOwnerLastName} onChange={(event) => {setPropertyOwnerLastName(event.target.value)}}/>
                                            <CustomControl lg={4} disabled={isLoading} originalValue={selectedSafetyRecord ? selectedSafetyRecord.propertyOwnerEmail : undefined} type='text' title='Email Address' value={propertyOwnerEmail} onChange={(event) => {setPropertyOwnerEmail(event.target.value)}}/>
                                            <CustomControl lg={6} disabled={isLoading} originalValue={selectedSafetyRecord ? selectedSafetyRecord.propertyOwnerPhoneNumber : undefined} type='text' title='Phone Number' value={propertyOwnerPhoneNumber} onChange={(event) => {setPropertyOwnerPhoneNumber(event.target.value)}}/>
                                            <CustomControl lg={6} disabled={isLoading} originalValue={selectedSafetyRecord ? selectedSafetyRecord.propertyOwnerInsuranceCompany : undefined} type='text' title='Insurance Company Name' value={propertyOwnerInsuranceCompany} onChange={(event) => {setPropertyOwnerInsuranceCompany(event.target.value)}}/>
                                            <AddressControl title='Address of Property Owner' originalValue={selectedSafetyRecord ? selectedSafetyRecord.propertyOwnerAddress : undefined} address={propertyOwnerAddress} setAddress={setPropertyOwnerAddress} lg={4}/>
                                        </>
                                    }
                                </Row>
                            </Container>
                        </Col>

                        <Col lg={12} style={{marginBottom: 8}}>
                            <Form.Group>
                                <Form.Label>Attachments</Form.Label>  
                                <Form.Control disabled={isLoading} type='file' multiple/>
                            </Form.Group>
                        </Col>

                        <Col xxl={12} style={{marginBottom: 8}}>
                            <TextAreaControl label='Notes' originalValue={selectedSafetyRecord ? selectedSafetyRecord.notes : undefined} disabled={isLoading} rows={4} value={notes} setValue={setNotes}/>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton label='Submit' isLoading={isLoading} onClick={() => {setConfirmSubmission(true)}}/>
            </Modal.Footer>
            <Modal show={confirmSubmission} centered onHide={() => {setConfirmSubmission(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Submission</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Once submitted, the entered information will become permanent record. Proceed?
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{color: 'white'}} onClick={() => {setConfirmSubmission(false)}}>Cancel</Button>
                    <Button style={{color: 'white'}} onClick={handleSubmit}>Confirm Submission</Button>
                </Modal.Footer>
            </Modal>

        </>
    )
    
}




export default HRUserSafetyAccidentForm;

