/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import CustomButton from './CustomButton';

export default function FileDownloadButton({value, doc, downloadService}) { 
    const [isLoading, setIsLoading] = useState(false);
    const [base64, setBase64] = useState(doc && doc.base64 ? doc.base64 : undefined);

    const handleDownload = async () => {
        setIsLoading(true);
        try {
            const downloadFileResponse = base64 ? {base64: base64, data: doc} : await downloadService(value);
            if (!base64) {
                setBase64(downloadFileResponse.base64);
            }
            const fetchResponse = await fetch(downloadFileResponse.base64);
            const blob = await fetchResponse.blob();
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = downloadFileResponse.data.documentName + '.' + downloadFileResponse.data.documentType;        
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);

        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false)
        }
        
    }

    return (
        <CustomButton label='Download' isLoading={isLoading} onClick={handleDownload}/>
    )
    
}