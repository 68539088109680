/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

function CustomControl({type, title, originalValue, value, onChange, min, max, step, xs, sm, md, lg, xl, xxl, disabled, validator, readOnly, rows, as}) {
    const [touched, setTouched] = useState(false);

    const handleOnChange = (event) => {
        if (max === undefined || event.target.value.length <= max || (type && type !== 'text' && type !== 'number')) {
            onChange(event);
        } else {
            event.target.value = event.target.value.slice(0, max);
            onChange(event);
        }
    }

    const content = (
        <FloatingLabel label={title}>
            <Form.Control
                as={as}
                readOnly={readOnly} 
                onBlur={() => {setTouched(true)}} 
                isInvalid={touched && validator && !validator.isValid()} 
                isValid={touched && validator && validator.isValid()} 
                disabled={disabled} 
                min={min} 
                max={type === 'number' ? undefined : max} 
                step={step}
                type={type}
                rows={rows} 
                placeholder=' ' 
                value={value} 
                onChange={handleOnChange} 
                style={originalValue !== undefined && value && value.toString() !== originalValue.toString() ? {backgroundColor: '#faffe3'} : {}}
            />
            { (validator && validator.getInvalidMessage()) && 
                <Form.Control.Feedback type='invalid'>{validator.getInvalidMessage()}</Form.Control.Feedback>
            }
            { (validator && validator.getValidMessage()) && 
                <Form.Control.Feedback type='valid'>{validator.getValidMessage()}</Form.Control.Feedback>
            }
        </FloatingLabel>
    )

    const contentWrapped = (
        <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} style={{marginBottom: 8}}>
            {content}
        </Col>
    )
    
    return (
        <>
            {xs || sm || md || lg || xl || xxl ? contentWrapped : content}
        </>
    )
}

export default CustomControl;