/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RadioControl from '../../../components/RadioControl';
import CustomControl from '../../../components/CustomControl';
import { payTypeDictionary, ptoAccrualTypeDictionary, ptoTypeDictionary } from '../../../tools';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faX } from '@fortawesome/free-solid-svg-icons';
import { createUserPay } from '../../../services/HRServices/HRPayService';
import CustomButton from '../../../components/CustomButton';
import { FloatingLabel, InputGroup, Dropdown } from 'react-bootstrap';
import { validateExistence, validatePositiveNumber, ValidationGroup } from '../../../validation';
import TextAreaControl from '../../../components/TextAreaControl';
import BwcCodeControl from '../../../components/BwcCodeControl';
import Deduction from '../../Payroll/Models/Deduction';
import AdditionalPay from '../../Payroll/Models/AdditionalPay';
import QuickTable from '../../../components/QuickTable';
import { faTrashCan, faPlus } from '@fortawesome/free-solid-svg-icons';




export default function HRUserPayEditor({companyUserUid, handleAddPay, selectedPay, isCreating,}) {
    const [date, setDate] = useState(
        isCreating ? moment().format('YYYY-MM-DD') : selectedPay.date
    );
    const [title, setTitle] = useState(selectedPay.title);
    const [payRate, setPayRate] = useState(selectedPay.payRate);
    const [payType, setPayType] = useState(selectedPay.payType);
    const [hourlyWage, setHourlyWage] = useState(selectedPay.hourlyWage);
    const [notes, setNotes] = useState(selectedPay.notes);
    const [employeeType, setEmployeeType] = useState(selectedPay.employeeType);
    const [medical, setMedical] = useState(selectedPay.medical);
    const [dental, setDental] = useState(selectedPay.dental);
    const [vision, setVision] = useState(selectedPay.vision);
    const [childSupport, setChildSupport] = useState(
        selectedPay.childSupport ? JSON.parse(selectedPay.childSupport) : []
    );
    const [automaticDeductions, setAutomaticDeductions] = useState(
        selectedPay.automaticDeductions ? JSON.parse(selectedPay.automaticDeductions) : []
    );
    const [automaticReimbursement, setAutomaticReimbursement] = useState(
        selectedPay.automaticReimbursement ? JSON.parse(selectedPay.automaticReimbursement) : []);
    const [bwcCode, setBwcCode] = useState(selectedPay.bwcCode);
    const [ptoAccrual, setPtoAccrual] = useState(selectedPay.ptoAccrual);
    const [ptoAccrualType, setPtoAccrualType] = useState(selectedPay.ptoAccrualType);
    const [stopBonusWeekdayThreshold, setStopBonusWeekdayThreshold] = useState(selectedPay.stopBonusWeekdayThreshold);
    const [stopBonusWeekendThreshold, setStopBonusWeekendThreshold] = useState(selectedPay.stopBonusWeekendThreshold);
    const [stopBonusWeekdayAmount, setStopBonusWeekdayAmount] = useState(selectedPay.stopBonusAmount);
    const [stopBonusWeekendAmount, setStopBonusWeekendAmount] = useState(selectedPay.stopBonusWeekendAmount);
    const [sendOfferLetter, setSendOfferLetter] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleCreateUserPay = async () => {
        setIsLoading(true);
        const newPay = {
            companyUserIdentifier: companyUserUid,
            date: date,
            title: title,
            payType: payType,
            payRate: payRate,
            hourlyWage: hourlyWage,
            notes: notes,
            employeeType: employeeType,
            medical: medical,
            dental: dental,
            vision: vision,
            childSupport: JSON.stringify(childSupport),
            bwcCode: bwcCode,
            ptoAccrual: ptoAccrual,
            ptoAccrualType: ptoAccrualType,
            stopBonusWeekdayThreshold: stopBonusWeekdayThreshold,
            stopBonusWeekendThreshold: stopBonusWeekendThreshold,
            stopBonusAmount: stopBonusWeekdayAmount,
            stopBonusWeekendAmount: stopBonusWeekendAmount,
            automaticReimbursement: JSON.stringify(automaticReimbursement),
            automaticDeductions: JSON.stringify(automaticDeductions),
            sendOfferLetter: sendOfferLetter ? '1' : '0'
        };
        const response = await createUserPay(newPay);
        if (response.status === '200') {
            newPay.uid = response.uid;
            handleAddPay(newPay);
        }
        setIsLoading(false);
    };

    const handleSetChildSupport = (index, value) => {
        const newArray = Array.from(childSupport);
        newArray[index] = isNaN(parseFloat(value)) ? 0 : parseFloat(value);
        setChildSupport(newArray);
    };
    const handleAddChildSupport = () => {
        const newArray = Array.from(childSupport);
        newArray.push(0);
        setChildSupport(newArray);
    };

    const handleRemoveChildSupport = (index) => {
        const newArray = [];
        childSupport.forEach((cs, i) => {
            if (i !== index) {
                newArray.push(cs);
            }
        });
        setChildSupport(newArray);
    };

    const handleSetAutomaticDeduction = (index, key, value) =>{
        const newArray = Array.from(automaticDeductions);
        newArray[index][key]=value;
        setAutomaticDeductions(newArray);
    }

    const handleAddAutomaticDeduction = () =>{
        const newArray = Array.from(automaticDeductions);
        newArray.push({
            label: "",
            amount: 0
        });
        setAutomaticDeductions(newArray);
    }

    const handleRemoveAutomaticDeduction = (index) =>{
        const newArrray = [];
        automaticDeductions.forEach((ar,i) =>{
            if(i !==index){
                newArrray.push(ar);
            }
        });
        setAutomaticDeductions(newArrray);
    }

    const handleSetAutomaticReimbursement = (index, key, value) =>{
        const newArray = Array.from(automaticReimbursement);
        newArray[index][key]=value;
        setAutomaticReimbursement(newArray);
    }

    const handleAddAutomaticReimbursement = () =>{
        const newArray = Array.from(automaticReimbursement);
        newArray.push({
            label: "",
            amount: 0
        });
        setAutomaticReimbursement(newArray);
  }
  
    const handleRemoveAutomaticReimbursement = (index) =>{
        const newArrray = [];
        automaticReimbursement.forEach((ar,i) =>{
            if(i !==index){
                newArrray.push(ar);
            }
        });
        setAutomaticReimbursement(newArrray);
    }
 
    const offerLetterToolTip = (
        <OverlayTrigger
            overlay={
                <Tooltip>
                After submission, an offer letter will be generated using the above
                information and sent to the employee to sign.
                </Tooltip>
            }
        >
            <FontAwesomeIcon
                icon={faQuestionCircle}
                style={{ color: 'var(--bs-primary)' }}
            />
        </OverlayTrigger>
    );

    const childSupportElements = childSupport.map((cs, index) => {
        return (
            <ChildSupportElement key={index} isCreating = {isCreating} isLoading={isLoading} value={cs} index={index} handleSetChildSupport={(value) => handleSetChildSupport(index, value)} removeChildSupport={handleRemoveChildSupport}/>
        );
    });

    const automaticReimbursementElements = automaticReimbursement.map((ar, index) => {
        return (
            <AutomaticReimbursement key={index} isCreating = {isCreating} isLoading={isLoading} reimbursement={ar} index={index} handleSetAutomaticReimbursement={handleSetAutomaticReimbursement} handleRemoveAutomaticReimbursement={handleRemoveAutomaticReimbursement}/>
        );
    });

    // const addAutomaticReimbursementButton = (
    //     <>
    //         {isCreating && !isLoading ? 
    //             <div style={{paddingTop:8, display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center'}}>
    //                 <Button style={{color: 'white', marginBottom: 8, borderRadius: 14, padding: '0px 5px 0px 5px'}}  onClick={handleAddAutomaticReimbursement}>
    //                     <FontAwesomeIcon icon={faPlus} style={{color: 'white'}}/>
    //                 </Button> 
    //             </div>
    //             : 
    //             ''
    //         }
    //     </>
    // )
 
 
    const automaticDeductionElements = automaticDeductions.map((ar, index) => {
        return (
            <AutomaticDeductionType key ={index} deduction ={ar} isCreating={isCreating} isLoading={isLoading} handleSetAutomaticDeduction={handleSetAutomaticDeduction} handleRemoveAutomaticDeduction={handleRemoveAutomaticDeduction} index={index}/> 
        );
    });

    const validationGroup = new ValidationGroup();
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>
                    {isCreating ? 'Creating New' : 'Viewing History'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <CustomControl
                            lg={4}
                            disabled={!isCreating || isLoading}
                            title='Date Effective'
                            type='date'
                            value={date}
                            onChange={(event) => {
                                setDate(event.target.value);
                            }}
                            validator={validationGroup.createValidator(()=>validateExistence(date))}
                        />
                        <CustomControl
                            lg={4}
                            max={10}
                            disabled={!isCreating || isLoading}
                            title='Pay Rate'
                            type='number'
                            value={payRate}
                            onChange={(event) => {
                                setPayRate(event.target.value);
                            }}
                            validator={validationGroup.createValidator(()=>validatePositiveNumber(payRate))}
                        />
                        <CustomControl
                            lg={4}
                            max={10}
                            disabled={!isCreating || isLoading}
                            title='Hourly Wage'
                            type='number'
                            value={hourlyWage}
                            onChange={(event) => {
                                setHourlyWage(event.target.value);
                            }}
                            validator={validationGroup.createValidator(()=>validatePositiveNumber(hourlyWage))}
                        />
                        <Col xxl={12} style={{ marginBottom: 8 }}>
                            <RadioControl disabled={!isCreating || isLoading} title='Pay Type' inline selection={payType} setSelection={setPayType} optionNames={Object.values(payTypeDictionary)} optionValues={Object.keys(payTypeDictionary)} validator={validationGroup.createValidator(()=>validateExistence(payType))} />
                        </Col>
                        <Col lg={6} style={{ marginBottom: 8 }}>
                            <RadioControl disabled={!isCreating || isLoading} title='Employee Type' inline selection={employeeType} setSelection={setEmployeeType} optionNames={['Full-Time', 'Part-Time']} optionValues={['0', '1']} validator={validationGroup.createValidator(()=>validateExistence(employeeType))} />
                        </Col>
                        <Col lg={6} style={{ marginBottom: 8 }}>
                            <RadioControl disabled={!isCreating || isLoading} title='Title' inline selection={title} setSelection={setTitle} optionNames={['Driver', 'BC', 'Admin', 'Other']} optionValues={['Driver', 'BC', 'Admin', 'Other']} validator={validationGroup.createValidator(()=>validateExistence(title))} />
                        </Col>
                        <BwcCodeControl disabled={!isCreating || isLoading} inline value={bwcCode} setValue={(value) => {setBwcCode(value)}} lg={6} validator={validationGroup.createValidator(()=>validateExistence(bwcCode))}/>
                        <Col lg={4} style={{paddingBottom:10}}>
                            <div style={{marginBottom:8}}>
                                <span>PTO</span>
                            </div>
                            <CustomControl
                                max={10}
                                disabled={!isCreating || isLoading}
                                title='PTO Accrual (In Hours)'
                                type='number'
                                value={ptoAccrual}
                                onChange={(event) => {
                                setPtoAccrual(event.target.value);
                                }}
                                validator={validationGroup.createValidator(()=>validateExistence(ptoAccrual))}
                            />
                            <div style={{marginTop:5}}>
                                <RadioControl
                                    disabled={!isCreating || isLoading}
                                    inline
                                    selection={ptoAccrualType}
                                    setSelection={setPtoAccrualType}
                                    optionNames={Object.values(ptoAccrualTypeDictionary)}
                                    optionValues={Object.keys(ptoAccrualTypeDictionary)}
                                    validator={validationGroup.createValidator(()=>validateExistence(ptoAccrualType))}
                                />
                            </div>
                        </Col>
                        <CustomControl lg={6} max={10} disabled={!isCreating || isLoading} title={'Weekday Stop Threshold'} type='number' value={stopBonusWeekdayThreshold} onChange={(event) => {
                                setStopBonusWeekdayThreshold(event.target.value);
                            }}
                        /> 
                        <CustomControl lg={6} max={10} disabled={!isCreating || isLoading} title='Weekend Stop Threshold' type='number' value={stopBonusWeekendThreshold} onChange={(event) => {
                                setStopBonusWeekendThreshold(event.target.value);
                            }}
                        /> 
                        <CustomControl lg={6} max={10} disabled={!isCreating || isLoading} title='Weekday Stop Wage Dollar Amount' type='number' value={stopBonusWeekdayAmount} onChange={(event) => {
                                setStopBonusWeekdayAmount(event.target.value);
                            }}
                        /> 
                        <CustomControl lg={6} max={10} disabled={!isCreating || isLoading} title='Weekend Stop Wage Dollar Amount' type='number' value={stopBonusWeekendAmount} onChange={(event) => {
                                setStopBonusWeekendAmount(event.target.value);
                            }}
                        /> 
                    </Row>
                    <Row style={{border: '1px solid lightgray', borderRadius: 6, padding: 12, marginBottom: 8}}>
                        <Col xxl={12}>
                            <p>Deductions</p>
                        </Col>
                        <CustomControl lg={4} max={10} disabled={!isCreating || isLoading} title='Medical Insurance' type='number' value={medical} onChange={(event) => {setMedical(event.target.value)}}/>
                        <CustomControl lg={4} max={10} disabled={!isCreating || isLoading} title='Dental Insurance' type='number' value={dental} onChange={(event) => {setDental(event.target.value)}}/>
                        <CustomControl lg={4} max={10} disabled={!isCreating || isLoading} title='Vision Insurance' type='number' value={vision} onChange={(event) => {setVision(event.target.value)}}/>
                        <Row style={{paddingTop:5}}>
                            <Col lg={6}>
                                <div style={{display:'flex', justifyContent:'center'}}>
                                <span style={{paddingRight:5}}>Child Support</span>
                                {isCreating && !isLoading ? (
                                <Button style={{color: 'white', marginBottom: 8, borderRadius: 14, padding: '0px 5px 0px 5px'}} onClick={handleAddChildSupport}>
                                    <FontAwesomeIcon icon={faPlus} style={{color: 'white'}}/>
                                </Button> 
                                ) : (
                                ''
                                )}
                                </div>
                                {childSupportElements.length > 0 &&
                                    <QuickTable headers={['Deduction', 'Amount', '']} widths={[null,300,40]} rows={childSupportElements} size={'sm'}/>
                                }
                            </Col>
                            <Col lg={6}>
                                <div style={{display:'flex', justifyContent:'center'}}>
                                <span style={{paddingRight:5}}>Automatic Deductions</span>
                                {isCreating && !isLoading ? (
                                <Button style={{color: 'white', marginBottom: 8, borderRadius: 14, padding: '0px 5px 0px 5px'}}  onClick={handleAddAutomaticDeduction}>
                                    <FontAwesomeIcon icon={faPlus} style={{color: 'white'}}/>
                                </Button> 
                                ) : (
                                ''
                                )}
                                </div>
                                {automaticDeductionElements.length > 0 && 
                                <QuickTable headers={['Deduction', 'Amount', '']} widths={[null,null,40]} rows={automaticDeductionElements} size={'sm'}/>}
                            </Col>
                        </Row>
                    </Row>
                    <Row style={{border: '1px solid lightgray', borderRadius: 6, padding: 12, marginBottom: 8}}>
                        <Col lg={12}>
                            <div style={{display:'flex'}}>
                                <span style={{paddingRight:5}}>Automatic Reimbursements</span>
                                {(isCreating && !isLoading) && 
                                    <Button style={{color: 'white', marginBottom: 8, borderRadius: 14, padding: '0px 5px 0px 5px'}}  onClick={handleAddAutomaticReimbursement}>
                                        <FontAwesomeIcon icon={faPlus} style={{color: 'white'}}/>
                                    </Button> 
                                }
                            </div>
                            {automaticReimbursementElements.length > 0 &&
                                <QuickTable headers={['Reimbursement', 'Amount', '']} widths={[null,null,40]} rows={automaticReimbursementElements} size={'sm'}/>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col xxl={12} style={{ marginBottom: 8 }}>
                            <TextAreaControl disabled={!isCreating || isLoading} label='Notes' max={65535} setValue={setNotes} value={notes} validator={validationGroup.createValidator(()=>validateExistence(notes))} rows={6}/>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            {isCreating && 
                <Modal.Footer>
                    <Form.Check
                        type='switch'
                        disabled={isLoading}
                        label={<>Send Offer Letter? {offerLetterToolTip}</>}
                        checked={sendOfferLetter}
                        onChange={() => {
                            setSendOfferLetter(!sendOfferLetter);
                        }}
                    />
                    <CustomButton
                        label='Submit'
                        isLoading={isLoading}
                        onClick={handleCreateUserPay}
                    />
                </Modal.Footer>
            }
        </>
    );
}

function AutomaticDeductionType({isCreating, isLoading, handleSetAutomaticDeduction, index, deduction, handleRemoveAutomaticDeduction}){
    const [showDropdown, setShowDropdown] = useState(false);
    const [deductionSearch, setDeductionSearch] = useState(deduction.label);

    const handleSetDropdown = (value) =>{
        handleSetAutomaticDeduction(index,'label',value);
        setDeductionSearch(value);
    }

    const deductionDropDownItems = Deduction.deductionTypes.map((type) =>{
        return (
            
            <Dropdown.Item key={type} eventKey={type} active={deductionSearch === type} onClick={() => {handleSetDropdown(type); setShowDropdown(false)}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <p style={{marginBottom: 0, marginRight: 8}}>{type}</p> 
                </div>
            </Dropdown.Item>  
        )
    });
    return (
        <tr>
            <td>
                <Form.Control disabled={!isCreating || isLoading} readOnly style={{height:'90%'}} placeholder='Deduction Type' type='text' value ={deductionSearch} onChange={(event)=>{handleSetDropdown(event.target.value)}} onFocus={()=>setShowDropdown(true)} onBlur={() => setTimeout(() => {setShowDropdown(false)}, 150)} />
                <div style={{position:'absolute', marginBottom:2, marginTop:-1.5}}>
                    <Dropdown.Menu onMouseDown={(e)=>e.preventDefault()} key="dropdown-menu" show={showDropdown} style={{maxHeight: 300, overflowY: 'scroll'}}>
                        {deductionDropDownItems}
                    </Dropdown.Menu>
                </div>
            </td>
            <td>
                <InputGroup>
                    {deduction.label !== '401K (% of Gross)' && deduction.label !== 'Other (% of Gross)' ? <InputGroup.Text style={{padding: '2px 6px 2px 6px'}}>$</InputGroup.Text> : <InputGroup.Text style={{padding: '2px 6px 2px 6px'}}>%</InputGroup.Text> }
                    <Form.Control  disabled={!isCreating || isLoading} style={{padding: '2px 6px 2px 6px'}} type='number' min={0} value={deduction.amount} placeholder='Amount' onChange={(event) => {handleSetAutomaticDeduction(index, 'amount', event.target.value.replace('-', ''))}}/>
                </InputGroup>
            </td>
            <td>
                <Button disabled={!isCreating || isLoading} style={{color: 'white', padding: '2px 6px 2px 6px', width: '100%'}} onClick={() => handleRemoveAutomaticDeduction(index)}>
                    <FontAwesomeIcon icon={faTrashCan}/>
                </Button>
            </td>
        </tr>
    )
}

function ChildSupportElement({value, removeChildSupport, handleSetChildSupport, index, isCreating, isLoading}){
  return(
      <tr>
          <td>Child Support #{index+1} </td>
          <td style={{width:'40%'}}>
              <InputGroup>
                  <InputGroup.Text style={{padding: '2px 6px 2px 6px'}}>$</InputGroup.Text>
                  <Form.Control disabled={!isCreating || isLoading} style={{padding: '2px 6px 2px 6px'}} type='number' min={0} value={value} placeholder='Amount' onChange={(event)=>{handleSetChildSupport(event.target.value)}}/>
              </InputGroup>
          </td>
          <td>
              <Button disabled={!isCreating || isLoading} style={{color: 'white', padding: '2px 6px 2px 6px', width: '100%'}} onClick={() => removeChildSupport(index)}>
                  <FontAwesomeIcon icon={faTrashCan}/>
              </Button>
          </td>
      </tr>
  )
}

function AutomaticReimbursement({handleSetAutomaticReimbursement, index, reimbursement, handleRemoveAutomaticReimbursement}){
    const[showDropdown, setShowDropdown] = useState(false);
    const[reimbursementSearch, setReimbursementSearch] = useState(reimbursement.label);

    const handleSetDropdown = (value) =>{
        handleSetAutomaticReimbursement(index,'label',value);
        setReimbursementSearch(value);
    }

    const reimbursementDropDownItems = AdditionalPay.additionalPayTypes.filter((ap)=>ap === 'Per Diem' || ap === 'Expense Reimbursement').map((type) =>{
        return (
            
            <Dropdown.Item key={type} eventKey={type} active={reimbursementSearch === type} onClick={() => {handleSetDropdown(type); setShowDropdown(false)}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <p style={{marginBottom: 0, marginRight: 8}}>{type}</p> 
                </div>
            </Dropdown.Item>  
        )
    });

    return (
        <tr>
            <td>   
            <Form.Control readOnly style={{height:'90%'}} placeholder='Reimbursement Type' type='text' value ={reimbursementSearch} onChange={(event)=>{handleSetDropdown(event.target.value)}} onFocus={()=>setShowDropdown(true)} onBlur={() => setTimeout(() => {setShowDropdown(false)}, 150)} />
            <div style={{position:'absolute', marginBottom:2, marginTop:-1.5, width:'21%'}}>
                <Dropdown.Menu onMouseDown={(e)=>e.preventDefault()} key="dropdown-menu" show={showDropdown} style={{maxHeight: 300, overflowY: 'scroll'}}>
                    {reimbursementDropDownItems}
                </Dropdown.Menu>
            </div>
            </td>
            <td style={{width:'35%'}}>
                <InputGroup>
                <InputGroup.Text style={{padding: '2px 6px 2px 6px'}}>$</InputGroup.Text>
                    <Form.Control  style={{padding: '2px 6px 2px 6px'}} type='number' min={0} value={reimbursement.amount} placeholder='Pay Rate' onChange={(event) => {handleSetAutomaticReimbursement(index, 'amount', event.target.value.replace('-', ''))}}/>
                </InputGroup>
            </td>
            <td>
                <Button  style={{color: 'white', padding: '2px 6px 2px 6px', width: '100%'}} onClick={() => handleRemoveAutomaticReimbursement(index)}>
                    <FontAwesomeIcon icon={faTrashCan}/>
                </Button>
            </td>
        </tr> 
    )
}