/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form'
import { wrapElementInCol } from '../tools';

function RadioControl({selection, setSelection, originalValue, optionNames, optionValues, inline, title, disabled, bordered = true, validator, xs, sm, md, lg, xl, xxl}) {
    const [touched, setTouched] = useState(false);

    const radioButtons = optionValues.map((optionValue, index) => {
        return (
            <Form.Check onFocus={() => {setTouched(true)}} disabled={disabled} inline={inline} key={optionValue} type='radio' label={optionNames[index]} checked={selection === optionValue} onChange={() => setSelection(optionValue)}/>
        )
    });

    const content = (
        <Form.Group>
            { title ? <Form.Label>{title}</Form.Label> : ''}
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: bordered ? '1px solid ' + (validator && touched && validator.isValid() ? 'green' : validator && touched ? 'rgb(209, 30, 54)' : 'lightgray') : '', borderRadius: 6, padding: bordered ? 12 : 0, backgroundColor: originalValue && originalValue !== selection ? '#faffe3' : ''}}>
                <div>
                    {radioButtons}
                </div>
                { validator && validator.isValid() && touched ?
                <FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/>
                : validator && touched ? <FontAwesomeIcon icon={faExclamationCircle} style={{color: 'rgb(209, 30, 54)'}}/>
                : ''
                }
            </div>
            
        </Form.Group>
    );

    return (
        <>
            {xs || sm || md || lg || xl || xxl ? wrapElementInCol(content, {xs: xs, sm: sm, md: md, lg: lg, xl: xl, xxl: xxl}) : content}
        </>
    )
}

export default RadioControl;