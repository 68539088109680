/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomControl from '../../../components/CustomControl';
import AddressControl from '../../../components/AddressControl';

export default function HRHireEContacts({personalInformation, handleSetPersonalInformation}) {
    return (
        <Container fluid>
            <Row>
                <Col lg={6}>
                    <Form.Group>
                        <Form.Label>Emergency Contact #1</Form.Label>
                        <Container fluid style={{border: '1px solid lightgray', borderRadius: 6, padding: 12}}>
                            <Row>
                                <CustomControl lg={6} type='text' max={20} title='First Name' value={personalInformation.eContact1FirstName} onChange={(event) => handleSetPersonalInformation('eContact1FirstName', event.target.value)}/>
                                <CustomControl lg={6} type='text' max={20} title='Last Name' value={personalInformation.eContact1LastName} onChange={(event) => handleSetPersonalInformation('eContact1LastName', event.target.value)}/>
                                <CustomControl lg={6} type='text' max={10} title='Phone Number' value={personalInformation.eContact1PhoneNumber} onChange={(event) => handleSetPersonalInformation('eContact1PhoneNumber', event.target.value)}/>
                                <CustomControl lg={6} type='text' max={20} title='Email Address' value={personalInformation.eContact1Email} onChange={(event) => handleSetPersonalInformation('eContact1Email', event.target.value)}/>
                                <CustomControl lg={12} type='text' max={20} title='Relationship' value={personalInformation.eContact1Relationship} onChange={(event) => handleSetPersonalInformation('eContact1Relationship', event.target.value)}/>
                                <AddressControl containerBreakPoints={{xxl: 12}} lg={6} title='Address' address={personalInformation.eContact1Address} setAddress={(value) => handleSetPersonalInformation('eContact1Address', value)}/>
                            </Row>
                        </Container>
                    </Form.Group>
                </Col>
                <Col lg={6}>
                    <Form.Group>
                        <Form.Label>Emergency Contact #2</Form.Label>
                        <Container fluid style={{border: '1px solid lightgray', borderRadius: 6, padding: 12}}>
                            <Row>
                                <CustomControl lg={6} type='text' max={20} title='First Name' value={personalInformation.eContact2FirstName} onChange={(event) => handleSetPersonalInformation('eContact2FirstName', event.target.value)}/>
                                <CustomControl lg={6} type='text' max={20} title='Last Name' value={personalInformation.eContact2LastName} onChange={(event) => handleSetPersonalInformation('eContact2LastName', event.target.value)}/>
                                <CustomControl lg={6} type='text' max={10} title='Phone Number' value={personalInformation.eContact2PhoneNumber} onChange={(event) => handleSetPersonalInformation('eContact2PhoneNumber', event.target.value)}/>
                                <CustomControl lg={6} type='text' max={20} title='Email Address' value={personalInformation.eContact2Email} onChange={(event) => handleSetPersonalInformation('eContact2Email', event.target.value)}/>
                                <CustomControl lg={12} type='text' max={20} title='Relationship' value={personalInformation.eContact2Relationship} onChange={(event) => handleSetPersonalInformation('eContact2Relationship', event.target.value)}/>
                                <AddressControl containerBreakPoints={{xxl: 12}} lg={6} title='Address' address={personalInformation.eContact2Address} setAddress={(value) => handleSetPersonalInformation('eContact2Address', value)}/>
                            </Row>
                        </Container>
                    </Form.Group>
                </Col>
            </Row>
        </Container>
    )
}