/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Cookies from "universal-cookie";

import { timeout } from "../tools";
export const DEV_MODE = false;
export const APIURL = DEV_MODE ? "http://192.168.1.52/admin/public/" : "https://adminapi.tacticalcontractor.com";

const cookieMonster = new Cookies();
export function getToken() {
    return cookieMonster.get('adminToken');
}

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
}

function getRequest(data) {
    return {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
    }
}

export async function getResponse(data) {

    console.log(data);
    try {
        const response = await fetch(APIURL, getRequest(data))
        if (response) {
            const responseObject = await response.json();
            if (!responseObject.status) {
                responseObject.status = response.status;
            }
            console.log(responseObject);        
            return responseObject;
        }
    } catch (error) {
        console.log(error);
        return {status: '500', message: error.message};
    }
    
}



