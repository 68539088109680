/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { usdFormatter } from "../../../tools";
import { bigToUsd, validateUsd, toFixedMax } from "../payrollTools";

export default function PayrollPeriodEntryPayRow(pay) {
    const row = {};

    if (pay.payType == 0) {
        row['Hourly Rate'] = usdFormatter.format(validateUsd(pay.payRate));
        row['Hours Worked'] = toFixedMax(pay.unitsWorked, 3);
        row['Hourly Wages'] = bigToUsd(pay.getWages());
    } else {
        row['Salary'] = usdFormatter.format(validateUsd(pay.payRate));
        row['Regular Wages'] = bigToUsd(pay.getWages());
    }
    
    return row;
}