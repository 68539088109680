/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import PayrollPeriodColumnInclusion from "./PayrollPeriodColumnInclusion";
import PayrollPeriodNDBonusColumnInclusion from "./PayrollPeriodNDBonusColumnInclusion";
import PayrollPeriodDeductionColumnInclusion from "./PayrollPeriodDeductionColumnInclusion";
import PayrollPeriodNonBonusAPColumnInclusion from "./PayrollPeriodNonBonusAPColumnInclusion";

export default function PayrollPeriodHeadersRow(entries, periodStart, periodEnd) {
    const columnInclusion = PayrollPeriodColumnInclusion(entries);
    const ndBonusColumnInclusion = PayrollPeriodNDBonusColumnInclusion(entries);
    const deductionColumnInclusion = PayrollPeriodDeductionColumnInclusion(entries);
    const apNonBonusColumnInclusion = PayrollPeriodNonBonusAPColumnInclusion(entries);

    const tableHeaders = ['Name', 'Location'];
    if (columnInclusion.includeOvertimeColumns && entries[0]?.weeks.length > 1) {
        tableHeaders.push('Week #');
    }
    if (columnInclusion.includeSalary) {tableHeaders.push('Salary');}
    if (columnInclusion.includeHourly) {tableHeaders.push('Hourly Rate');}
    if (columnInclusion.includeOvertimeColumns) {
        tableHeaders.push('Adjusted Hourly Rate');
        tableHeaders.push('Overtime Rate');
    }
    tableHeaders.push('Days Worked');
    tableHeaders.push('Hours Worked');
    if (columnInclusion.includeOvertimeColumns) {tableHeaders.push('Overtime Hours');}
    if (columnInclusion.includePto) {tableHeaders.push('PTO Hours');}
    if (columnInclusion.includeRegularWages) {tableHeaders.push('Regular Wages');}
    if (columnInclusion.includeHourly) {tableHeaders.push('Hourly Wages');}
    if (columnInclusion.includeOvertimeColumns) {tableHeaders.push('Overtime Wages');}
    if (columnInclusion.includePto) {tableHeaders.push('PTO Wages');}
    if (columnInclusion.includeHolidayWages) {tableHeaders.push('Holiday Wages');}

    for (let i = 0; i < ndBonusColumnInclusion; i++) {
        tableHeaders.push(`Nondiscretionary Bonus #${i + 1}`);
    }

    if (columnInclusion.includeHolidayBonus) {tableHeaders.push('Holiday Bonus');}
    if (columnInclusion.includeOtherDiscretionaryBonus) {tableHeaders.push('Other Discretionary Bonus');}
    
    Object.entries(apNonBonusColumnInclusion).forEach(([key, value]) => {
        for (let i = 0; i < value; i++) {
            tableHeaders.push(`${key} #${i + 1}`);
        }
    })
    
    tableHeaders.push('Gross');

    const loanRowCount = entries.reduce((prev, curr) => {
        return Math.max(prev, curr.getEnabledLoans().length);
    }, 0);

    for (let i = 1; i <= loanRowCount; i++) {
        tableHeaders.push(`Loan #${i}`);
    }

    if (columnInclusion.inlcudeChildSupport) {tableHeaders.push('Child Support');}
    if (columnInclusion.includeMedical) {tableHeaders.push('Medical Insurance');}
    if (columnInclusion.includeDental) {tableHeaders.push('Dental Insurance');}
    if (columnInclusion.includeVision) {tableHeaders.push('Vision Insurance');}

    deductionColumnInclusion.forEach((deductionType) => {
        tableHeaders.push(deductionType);
    })

    tableHeaders.push('Notes');

    return tableHeaders;
}