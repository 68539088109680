/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/


import { getResponse, getToken } from "../serviceTools";
import { timeout } from "../../tools";
import { getFakeUID } from "../../fake-data";

export async function getCompanyDocuments(companyIdentifier) {
    const data = {
        type: 'company',
        target: 'getCompanyDocuments',
        authToken: getToken(),
        companyIdentifier: companyIdentifier
    }

    const response = await getResponse(data);
    return response;
}

export async function uploadCompanyDocument(companyIdentifier, file) {
    const data = {
        type: 'company',
        target: 'createCompanyDocument',
        authToken: getToken(),
        companyIdentifier: companyIdentifier,
        data: file,
    }

    const response = await getResponse(data);
    return response;
}

export async function downloadCompanyDocument(uid) {
    const data = {
        type: 'company',
        target: 'getCompanyDocument',
        authToken: getToken(),
        uid: uid,
    }

    const response = await getResponse(data);
    return response;
}

export async function deleteCompanyDocument(uid) {
    const data = {
        type: 'company',
        target: 'deleteCompanyDocument',
        authToken: getToken(),
        uid: uid,
    }

    const response = await getResponse(data);
    return response;
}

export async function editCompanyDocument(document) {
    const data = {
        type: 'company',
        target: 'editCompanyDocument',
        authToken: getToken(),
        data: document
    }

    const response = await getResponse(data);
    return response;
}