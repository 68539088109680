/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from "../serviceTools";

export async function getCompanyProfile(companyIdentifier) {
    var data = {
        type: 'company',
        target: 'getCompanyProfile',
        authToken: getToken(),
        companyIdentifier: companyIdentifier
    }
    return await getResponse(data);
}

export async function updateCompanyProfile(profile) {
    var data = {
        type: 'company',
        target: 'updateCompanyProfile',
        authToken: getToken(),
        profile: profile
    }
    return await getResponse(data);
}