/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import CustomToolTip from '../../../components/CustomToolTip';
import Table from 'react-bootstrap/Table';

export default function HRHirePermissions({handleSetPermissions, handleUseTemplate, permissions, permissionsTemplate}) {

    const permissionRows = permissionsTemplate.map((p) => {
        return (
            <tr key={p.permissionName}>
                <td>
                    <span style={{marginRight: 8}}>{p.permissionTitle}</span>
                    <CustomToolTip text={p.description}/>
                </td>
                <td>
                    <Form.Check type='switch' checked={permissions[p.permissionName] === '1'} onChange={() => {handleSetPermissions(p.permissionName)}}/>
                </td>
                
            </tr>
        )
    });

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 12}}>
                <div style={{display: 'flex', gap: 12, alignItems: 'center', border: '1px solid lightgray', borderRadius: 6, padding: 8, width: 'fit-content'}}>
                    <span>Default Permission Templates</span>
                    <Button style={{color: 'white'}} onClick={() => {handleUseTemplate('ao')}}>Authorized Officer</Button>
                    <Button style={{color: 'white'}} onClick={() => {handleUseTemplate('bc')}}>Business Contact</Button>
                    <Button style={{color: 'white'}} onClick={() => {handleUseTemplate('driver')}}>Driver</Button>
                </div>
            </div>

            <Table responsive bordered striped size='sm'>
                <thead>
                    <tr>
                        <th>Permission</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {permissionRows}
                </tbody>
            </Table>
        </>
    )
}

