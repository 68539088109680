/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import moment from 'moment';
import KeyValueRow from '../../../components/KeyValueRow';
import ListGroup from 'react-bootstrap/ListGroup';
import { 
    addressToString, 
    availabilityToString, 
    payTypeDictionary, 
    usdFormatter, 
    employeeTypeDictionary, 
    formatPhoneNumber, 
    formatSSN, 
    formatTime 
} from '../../../tools';

export default function HRHireReview({csas, users, personalInformation, employmentDetails}) {
    const csa = csas.find(csa => csa.uid === employmentDetails.csaIdentifier);
    const supervisor = users.find(u => u.companyUserUid === employmentDetails.supervisor);

    return (
        <ListGroup>
            <KeyValueRow isListItem title={'First Name'} value={personalInformation.firstName}/>
            <KeyValueRow isListItem title={'Middle Name'} value={personalInformation.middleName}/>
            <KeyValueRow isListItem title={'Last Name'} value={personalInformation.lastName}/>
            <KeyValueRow isListItem title={'Email Address'} value={personalInformation.email}/>
            <KeyValueRow isListItem title={'Phone Number'} value={personalInformation.phoneNumber ? formatPhoneNumber(personalInformation.phoneNumber) : ''}/>
            <KeyValueRow isListItem title={'Address'} value={addressToString(personalInformation.address)}/>
            <KeyValueRow isListItem title={'Date of Birth'} value={personalInformation.dateOfBirth ? moment(personalInformation.dateOfBirth).format('MMM D, YYYY') : ''}/>
            <KeyValueRow isListItem title={'Social Security Number'} value={personalInformation.ssn ? formatSSN(personalInformation.ssn) : ''}/>
            <KeyValueRow isListItem title={'Emergency Contact #1 First Name'} value={personalInformation.eContact1FirstName}/>
            <KeyValueRow isListItem title={'Emergency Contact #1 Last Name'} value={personalInformation.eContact1LastName}/>
            <KeyValueRow isListItem title={'Emergency Contact #1 Phone Number'} value={personalInformation.eContact1PhoneNumber ? formatPhoneNumber(personalInformation.eContact1PhoneNumber) : ''}/>
            <KeyValueRow isListItem title={'Emergency Contact #1 Email Address'} value={personalInformation.eContact1Email}/>
            <KeyValueRow isListItem title={'Emergency Contact #1 Relationship'} value={personalInformation.eContact1Relationship}/>
            <KeyValueRow isListItem title={'Emergency Contact #1 Address'} value={addressToString(personalInformation.eContact1Address)}/>
            <KeyValueRow isListItem title={'Emergency Contact #2 First Name'} value={personalInformation.eContact2FirstName}/>
            <KeyValueRow isListItem title={'Emergency Contact #2 Last Name'} value={personalInformation.eContact2LastName}/>
            <KeyValueRow isListItem title={'Emergency Contact #2 Phone Number'} value={personalInformation.eContact2PhoneNumber ? formatPhoneNumber(personalInformation.eContact2PhoneNumber) : ''}/>
            <KeyValueRow isListItem title={'Emergency Contact #2 Email Address'} value={personalInformation.eContact2Email}/>
            <KeyValueRow isListItem title={'Emergency Contact #2 Relationship'} value={personalInformation.eContact2Relationship}/>
            <KeyValueRow isListItem title={'Emergency Contact #2 Address'} value={addressToString(personalInformation.eContact2Address)}/>
            <KeyValueRow isListItem title={'Employee Type'} value={employeeTypeDictionary[employmentDetails.employeeType]}/>
            <KeyValueRow isListItem title={'Title'} value={employmentDetails.title}/>
            <KeyValueRow isListItem title={'CSA'} value={csa ? csa.csaName : ''}/>
            <KeyValueRow isListItem title={'Expected Availability'} value={availabilityToString(employmentDetails.availability)}/>
            <KeyValueRow isListItem title={'Pay Type'} value={payTypeDictionary[employmentDetails.payType]}/>
            <KeyValueRow isListItem title={'Pay Rate'} value={employmentDetails.payRate ? usdFormatter.format(employmentDetails.payRate) : ''}/>
            <KeyValueRow isListItem title={'Hourly Wage'} value={employmentDetails.hourlyWage ? usdFormatter.format(employmentDetails.hourlyWage): ''}/>
            <KeyValueRow isListItem title= {'PTO Hours Accrued Per 40 Hours Worked'} value={employmentDetails.ptoAccrual}/>
            <KeyValueRow isListItem title={ 'Weekday Stop Threshold'} value={employmentDetails.stopBonusWeekdayThreshold}/>
            <KeyValueRow isListItem title={ 'Weekend Stop Threshold'} value={employmentDetails.stopBonusWeekendThreshold}/>
            <KeyValueRow isListItem title={ 'Stop Bonus Dollar Amount'} value={employmentDetails.stopBonusAmount ? usdFormatter.format(employmentDetails.stopBonusAmount) : ''}/>
            <KeyValueRow isListItem title={'Daily Start Time'} value={employmentDetails.dailyStartTime ? formatTime(employmentDetails.dailyStartTime, 'h:mm A') : ''}/>
            <KeyValueRow isListItem title={'Start Date'} value={employmentDetails.startDate ? moment(employmentDetails.startDate).format('MMM D, YYYY') : ''}/>
            <KeyValueRow isListItem title={'Start Time on First Day'} value={employmentDetails.firstDayStartTime ? formatTime(employmentDetails.firstDayStartTime, 'h:mm A') : ''}/>
            <KeyValueRow isListItem title={'BWC Code'} value={employmentDetails.bwcCode}/>
            <KeyValueRow isListItem title={'Direct Supervisor'} value={supervisor ? supervisor.firstName + ' ' + supervisor.lastName : ''}/>
            <KeyValueRow isListItem title={ 'Automatic Reimbursements'} value={usdFormatter.format(employmentDetails.automaticReimbursement.reduce((prev, curr) => {
                return prev + parseFloat(curr.amount);
            }, 0))}/>
            <KeyValueRow isListItem title= {'Medical'} value={employmentDetails.medical ? usdFormatter.format(employmentDetails.medical) : '$0.00'}/>
            <KeyValueRow isListItem title={ 'Dental'} value={employmentDetails.dental ? usdFormatter.format(employmentDetails.dental) : '$0.00'}/>
            <KeyValueRow isListItem title={ 'Vision'} value={employmentDetails.vision ? usdFormatter.format(employmentDetails.vision) : '$0.00'}/>
            <KeyValueRow isListItem title={ 'Automatic Deductions (Flat Rate)'} value={usdFormatter.format(employmentDetails.automaticDeductions.filter((ad)=>ad.label !== '401K (% of Gross)' && ad.label !== 'Other (% of Gross)').reduce((prev, curr) => {
                        return prev + parseFloat(curr.amount);
                    }, 0))}/>
            <KeyValueRow isListItem title={ 'Automatic Deductions (% of Gross)'} value={employmentDetails.automaticDeductions.filter((ad)=>ad.label === '401K (% of Gross)' || ad.label === 'Other (% of Gross)').reduce((prev, curr) => {
                return prev + parseFloat(curr.amount);
            }, 0) + '%'}/>
            <KeyValueRow isListItem title={ 'Child Support'} value={usdFormatter.format(employmentDetails.childSupport.reduce((prev, curr) => {
                return prev + parseFloat(curr);
            }, 0))}/>
        </ListGroup>
    )
}