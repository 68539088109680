/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import CustomSpinner from '../../components/CustomSpinner';
import CustomToolTip from '../../components/CustomToolTip';
import CustomButton from '../../components/CustomButton';
import { newPermissions } from '../../fake-data';
import { getUserPermissions, updateUserPermissions } from '../../services/HRServices/HRPermissionsService';

class HRUserPermissions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isSubmitting: false,
            permissions: [],
            employeePermissions: {},
            selectedTemplate: '',
            permissionsIdentifier: undefined,
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUseTemplate = this.handleUseTemplate.bind(this);
    }

    loadData() {
        if (!this.state.isLoading) {
            this.setState({isLoading: true});
        }

        getUserPermissions(this.props.companyUserUid).then((response) => {
            if (response && response.status === '200') {  
                
                this.setState({
                    permissions: response.template,
                    employeePermissions: response.data,
                });
                
            }
            this.setState({isLoading: false});
        });
    }

    componentDidMount() {
        this.loadData();
    }

    handlePermissionChange(permissionName) {
        const newPermissions = structuredClone(this.state.employeePermissions);
        newPermissions[permissionName] = newPermissions[permissionName] === '0' ? '1' : '0';
        this.setState({employeePermissions: newPermissions});
    }

    handleSubmit() {
        this.setState({isSubmitting: true});

        const newPermissions = [];

        this.state.permissions.forEach((perm) => {
            newPermissions.push({
                permissionsName: perm.permissionName,
                value: this.state.employeePermissions[perm.permissionName]
            });
        });

        updateUserPermissions(this.state.employeePermissions.uid, newPermissions).then((response) => {
            if (response) {
                this.loadData();
            }
            this.setState({isSubmitting: false});
        });
    }

    handleUseTemplate() {
        const newPermissions = structuredClone(this.state.employeePermissions);
        this.state.permissions.forEach((permObj) => {
            newPermissions[permObj.permissionName] = permObj[this.state.selectedTemplate];
        });
        this.setState({employeePermissions: newPermissions, selectedTemplate: undefined});
    }


    render() {


        

        const permissionRows = this.state.permissions.map((p) => {
            return (
                <tr key={p.permissionName}>
                    <td>
                        <span style={{marginRight: 8}}>{p.permissionTitle}</span>
                        <CustomToolTip text={p.description}/>
                    </td>
                    <td>
                        <Form.Check type='switch' checked={this.state.employeePermissions[p.permissionName] === '1'} onChange={() => {this.handlePermissionChange(p.permissionName)}}/>
                    </td>
                </tr>
            )
        });

        const content = (
            <div>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 12}}>
                    <div style={{display: 'flex', gap: 12, alignItems: 'center', border: '1px solid lightgray', borderRadius: 6, padding: 8, width: 'fit-content'}}>
                        <span>Default Permission Templates</span>
                        <Button style={{color: 'white'}} onClick={() => {this.setState({selectedTemplate: 'ao'})}}>Authorized Officer</Button>
                        <Button style={{color: 'white'}} onClick={() => {this.setState({selectedTemplate: 'bc'})}}>Business Contact</Button>
                        <Button style={{color: 'white'}} onClick={() => {this.setState({selectedTemplate: 'driver'})}}>Driver</Button>
                    </div>
                    <CustomButton label='Save Changes' isLoading={this.state.isSubmitting} onClick={this.handleSubmit}/>
                </div>

                <Table responsive bordered striped size='sm'>
                    <thead>
                        <tr>
                            <th>Permission</th>
                            <th></th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {permissionRows}
                    </tbody>
                </Table>
                <Modal show={this.state.selectedTemplate} onHide={() => {this.setState({selectedTemplate: undefined})}} centered>
                    <Modal.Header>
                        <Modal.Title>Confirm Load Template</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {'Replace current permissions with the default values for a(n) ' + this.state.selectedTemplate + '?'}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button style={{color: 'white'}} onClick={this.handleUseTemplate}>Confirm</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );


        return (
            <>
                {this.state.isLoading ? <CustomSpinner spinnerHeight='10vh' spinnerWidth='10vh' height='50vh'/> : content}
            </>
        )
    }
}

export default HRUserPermissions;