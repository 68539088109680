/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import moment from "moment";
import Button from 'react-bootstrap/Button';
import { dateIsInRange, dateRangeToString } from "../../../tools";
import DateRangeSelector from "../../../components/DateRangeSelector";
import SearchBar from "../../../components/SearchBar";
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import { getCompanyNotes } from "../../../services/CompanyServices/CompanyNotesService";
import CompanyNotesModal from "../CompanyNotes/CompanyNotesModal";
import PageSpinner from "../../../components/PageSpinner";
import Cookies from "universal-cookie";

export default class CompanyNotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            notes: [],
            filter: '',
            filterType: 'title',
            startDate: '',
            endDate: '',
            onlyShowAuthoredNotes: false,
            showModal: false,
            selectedNote: undefined,

            currentCompanyUser: undefined
        }

        this.cookieMonster = new Cookies();
        this.handleAddNote = this.handleAddNote.bind(this);
        this.handleDeleteNote = this.handleDeleteNote.bind(this);
    }

    sortNotes(a, b) {
        if (moment(a.submissionDate).isBefore(moment(b.submissionDate), 'days')) {
            return 1;
        } else if (moment(a.submissionDate).isAfter(moment(b.submissionDate), 'days')) {
            return -1;
        } else {
            return 0;
        }
    }

    async loadData() {
        this.setState({isLoading: true});
        const response = await getCompanyNotes(this.props.selectedCompany.uid);
        if (response && response.status === '200') {
            this.setState({notes: response.data});
        }
        this.setState({isLoading: false});
    }

    componentDidMount() {
        this.loadData();
    }

    handleAddNote(note) {
        const newArray = Array.from(this.state.notes);
        newArray.push(note);
        this.setState({notes: newArray.sort(this.sortNotes), showModal: false});
    }

    handleDeleteNote(uid) {
        const newArray = Array.from(this.state.notes).filter(r => r.uid !== uid);
        this.setState({notes: newArray.sort(this.sortNotes), showModal: false});
    }


    render() {

        const filteredNotes = this.state.notes.filter((note) => {
            const isInRange = dateIsInRange(note.submissionDate, this.state.startDate, this.state.endDate);
            const isInSearch = !this.state.filter || note[this.state.filterType].toLocaleLowerCase().includes(this.state.filter.toLocaleLowerCase());
            const obeysOnlyShowAuthoredNotes = !this.state.onlyShowAuthoredNotes || this.cookieMonster.get('userData').companyUserUid === note.author;
            return isInRange && isInSearch && obeysOnlyShowAuthoredNotes;
        })

        const noteElements = filteredNotes.map((note) => {

            return (
                <Card key={note.uid} className='cursor-pointer' onClick={() => {this.setState({selectedNote: note, showModal: true})}}>
                    <Card.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                        <Card.Title>{note.title}</Card.Title>
                        <div style={{backgroundColor: 'rgba(0, 0, 0, 0.05)', borderRadius: 6, padding: 6}}>
                            <span>{note.body.length > 200 ? (note.body.slice(0, 200) + '...') : note.body}</span>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <span style={{opacity: 0.5}}>{(note.lastModified ? 'Last edited on ' + moment(note.lastModified).format('MMM D, YYYY') : 'Created on ' + moment(note.submissionDate).format('MMM D, YYYY'))}</span>
                            { note.user &&
                                <span style={{color: 'var(--bs-primary)'}}>{'- ' + note.user.firstName + ' ' + note.user.lastName}</span>
                            }
                        </div>
                    </Card.Body>
                </Card>
            )
        });

        const content = (
            <>
                <div style={{display: 'flex', gap: 12, alignItems: 'center', marginBottom: 12}}>
                    <SearchBar 
                        label='Filter by...' 
                        value={this.state.filter} 
                        setValue={(value) => {this.setState({filter: value})}} 
                        typeNames={['Title', 'Content']} 
                        typeValues={['title', 'body']} 
                        type={this.state.filterType} 
                        setType={(value) => {this.setState({filterType: value})}}
                    />
                    <Button style={{flexShrink: 0, color: 'white'}} onClick={() => {this.setState({showDateRangeFilter: true})}}>{'Submission Date Filter: ' + dateRangeToString(this.state.startDate, this.state.endDate)}</Button>
                    <div style={{flexShrink: 0}}>
                        <Form.Check type='switch' label='Only Show My Notes' value={this.state.onlyShowAuthoredNotes} onChange={() => {this.setState({onlyShowAuthoredNotes: !this.state.onlyShowAuthoredNotes})}}/>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                    {noteElements}
                </div>
                <DateRangeSelector show={this.state.showDateRangeFilter} onHide={() => {this.setState({showDateRangeFilter: false})}} centered handleSetDateRange={this.handleSetDateRange} presetOptions={['pastYear', 'pastMonth', 'pastWeek', 'allTime']}/>
            </>
        );


        return (
            <>
                {this.state.isLoading ? <PageSpinner/> : content}
                <Modal show={this.state.showModal} onHide={() => this.setState({showModal: false})} size='lg'>
                    <CompanyNotesModal note={this.state.selectedNote} handleDeleteNote={this.handleDeleteNote}/>
                </Modal>
            </>
        )
    }
}