/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import { Alert, Modal } from 'react-bootstrap';
import QuickTable from '../../../components/QuickTable';
import HRUserLoanEditor from './HRUserLoanEditor';
import CustomButton from '../../../components/CustomButton';
import { getAllEmployeeLoans } from '../../../services/HRServices/HRLoanService';
import { usdFormatter, validateDecimal } from '../../../tools';
import HRUserTicketEditor from './HRUserTicketEditor';
import PageSpinner from '../../../components/PageSpinner';

export default class HRUserLoan extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            loans:[],
            tickets: [],
            showLoanModal: false,
            showTicketModal: false,
            selectedLoan: undefined,
            selectedTicket: undefined,
        }

        this.handleLoanCrud = this.handleLoanCrud.bind(this);
        this.handleTicketCrud = this.handleTicketCrud.bind(this);
    }

    async loadData(){
        this.setState({isLoading: true});
        const response = await getAllEmployeeLoans(this.props.user.companyUserUid);
        if (response.status == '200'){
            this.setState({loans: response.loans, tickets: response.tickets});
        }
        this.setState({isLoading: false});
    }

    componentDidMount(){
        this.loadData();
    }

    handleLoanCrud(type, data) {
        let newArr = Array.from(this.state.loans);
        if (type === 'create') {
            newArr.push(data);
        } else if (type === 'update') {
            newArr = newArr.filter(t => t.uid != data.uid);
            newArr.push(data);
        } else if (type === 'delete') {
            newArr = newArr.filter(t => t.uid != data);
        } else if (type === 'createInstallment') {
            const loan = newArr.find(l => l.uid == data.loanIdentifier);
            loan.installmentSum = validateDecimal(loan.installmentSum) + validateDecimal(data.amount);
        } else if (type === 'deleteInstallment') {
            const loan = newArr.find(l => l.uid == data.loanIdentifier);
            loan.installmentSum = validateDecimal(loan.installmentSum) - validateDecimal(data.amount);
        }
        this.setState({loans: newArr});
        if (type !== 'createInstallment' && type !== 'deleteInstallment') {
            this.setState({showLoanModal: false});
        }
        if (this.props.handleRecalculateEntry) {
            this.props.handleRecalculateEntry();
        }
    }

    handleTicketCrud(type, data) {
        let newArr = Array.from(this.state.tickets);
        if (type === 'create') {
            newArr.push(data);
        } else if (type === 'update') {
            newArr = newArr.filter(t => t.uid != data.uid);
            newArr.push(data);
        } else if (type === 'delete') {
            newArr = newArr.filter(t => t.uid != data);
        } else if (type === 'createInstallment') {
            const ticket = newArr.find(l => l.uid == data.ticketIdentifier);
            ticket.installmentSum = validateDecimal(ticket.installmentSum) + validateDecimal(data.amount);
        } else if (type === 'deleteInstallment') {
            const ticket = newArr.find(l => l.uid == data.ticketIdentifier);
            ticket.installmentSum = validateDecimal(ticket.installmentSum) - validateDecimal(data.amount);
        }
        this.setState({tickets: newArr});
        if (type !== 'createInstallment' && type !== 'deleteInstallment') {
            this.setState({showTicketModal: false});
        }
        if (this.props.handleRecalculateEntry) {
            this.props.handleRecalculateEntry();
        }
    }
    

    

    render(){

        const loanRows = this.state.loans.filter(l => validateDecimal(l.installmentSum) < validateDecimal(l.amount)).map((l)=>{
            const amountLeft = validateDecimal(l.amount) - validateDecimal(l.installmentSum);
            return (
               <tr key={l.uid} className='cursor-pointer' onClick={()=>{this.setState({selectedLoan: l.uid, showLoanModal: true})}}>
                    <td>{l.name}</td>
                    <td>{usdFormatter.format(l.amount)}</td>
                    <td>{usdFormatter.format(l.installment)}</td>
                    <td>{usdFormatter.format(l.installmentSum)}</td>
                    <td>{usdFormatter.format(amountLeft)}</td>
               </tr>
            )
        });

        const ticketRows = this.state.tickets.filter(t => validateDecimal(t.installmentSum) < validateDecimal(t.amount)).map((ticket) => {
            const amountLeft = ticket.amount - ticket.installmentSum;
            return (
               <tr key={ticket.uid} className='cursor-pointer' onClick={()=>{this.setState({selectedTicket: ticket.uid, showTicketModal: true})}}>
                    <td>{ticket.name}</td>
                    <td>{usdFormatter.format(ticket.amount)}</td>
                    <td>{usdFormatter.format(ticket.installment)}</td>
                    <td>{usdFormatter.format(ticket.installmentSum)}</td>
                    <td>{usdFormatter.format(amountLeft)}</td>
                    <td>{ticket.deductFromSafetyBonuses ? 'Yes' : 'No'}</td>
               </tr>
            )
        });

        const loanHistoryRows = this.state.loans.filter(l => validateDecimal(l.installmentSum) >= validateDecimal(l.amount)).map((l) => {
            return (
                <tr key={'loan' + l.uid} className='cursor-pointer' onClick={()=>{this.setState({selectedLoan: l.uid, showLoanModal: true})}}>
                    <td>Loan</td>
                    <td>{l.name}</td>
                    <td>{l.amount}</td>
                </tr>
            )
        })

        const ticketHistoryRows = this.state.tickets.filter(t => validateDecimal(t.installmentSum) >= validateDecimal(t.amount)).map((t) => {
            return (
                <tr key={'ticket' + t.uid} className='cursor-pointer' onClick={()=>{this.setState({selectedTicket: t.uid, showTicketModal: true})}}>
                    <td>Fine Ticket Damage</td>
                    <td>{t.name}</td>
                    <td>{t.amount}</td>
                </tr>
            )
        })


        return this.state.isLoading ? <PageSpinner/> : (
            <>
                <div>
                    <div style={{display:'flex', justifyContent:'flex-end', padding:4, gap: 8, alignItems: 'center'}}>
                        <CustomButton label={'Create Loan'} onClick={()=>{this.setState({selectedLoan: undefined, showLoanModal: true})}}/>
                        <CustomButton label={'Create Ticket'} onClick={()=>{this.setState({selectedTicket: undefined, showTicketModal: true})}}/>
                    </div>
                    <QuickTable title='Current Loans' headers={['Name','Amount Borrowed', 'Installment', 'Amount Paid', 'Amount Remaining']} rows={loanRows}  size={'sm'}/>
                    <QuickTable title='Current Fine Ticket Damage' headers={['Name','Amount Borrowed', 'Installment', 'Amount Paid', 'Amount Remaining', 'Deduct From Safety Bonuses']} rows={ticketRows} size={'sm'}/>
                    <QuickTable title='Paid Loans / Fine Ticket Damage' headers={['Type', 'Name', 'Amount']} rows={loanHistoryRows.concat(ticketHistoryRows)} size='sm'/>
                </div>
                <Modal show={this.state.showLoanModal} onHide={()=>this.setState({showLoanModal:false})} size='lg'>
                    <HRUserLoanEditor companyUserIdentifier={this.props.user.companyUserUid} selectedLoan={this.state.selectedLoan} handleLoanCrud={this.handleLoanCrud}/>
                </Modal>
                <Modal show={this.state.showTicketModal} onHide={()=>this.setState({showTicketModal:false})} size='lg'>
                    <HRUserTicketEditor companyUserIdentifier={this.props.user.companyUserUid} selectedTicket={this.state.selectedTicket} handleTicketCrud={this.handleTicketCrud}/>
                </Modal>
            </>
        )
    }

}