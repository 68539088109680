/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomControl from '../../../components/CustomControl';
import RadioControl from '../../../components/RadioControl';
import AvailabilityControl from '../../../components/AvailabilityControl';
import DropdownControl from '../../../components/DropdownControl';
import { FloatingLabel, InputGroup, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import BwcCodeControl from '../../../components/BwcCodeControl';
import { validateAvailablity, validateExistence } from '../../../validation';
import { Modal } from 'react-bootstrap';
import { ptoTypeDictionary } from '../../../tools';
import { useState, useEffect } from 'react';
import QuickTable from '../../../components/QuickTable';
import Deduction from '../../Payroll/Models/Deduction';
import AdditionalPay from '../../Payroll/Models/AdditionalPay';
import { faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { getFakeUID } from '../../../fake-data';

export default function HRHireEmploymentDetails({csas, users, employmentDetails, handleSetEmploymentDetails, validationGroup}) {
    const handleSetAutomaticReimbursement = (index, key, value) =>{
        const newArray = Array.from(employmentDetails.automaticReimbursement);
        newArray[index][key]=value;
        handleSetEmploymentDetails('automaticReimbursement', newArray);
      }
  
      const handleAddAutomaticReimbursement = () => {
        const newArray = Array.from(employmentDetails.automaticReimbursement);
        newArray.push({
            label: "",
            amount: 0
        });
        handleSetEmploymentDetails('automaticReimbursement', newArray);
      }
  
      const handleRemoveAutomaticReimbursement = (index) => {
        const newArray = [];
        employmentDetails.automaticReimbursement.forEach((ar,i) => {
            if(i !==index) {
                newArray.push(ar);
            }
        });
        handleSetEmploymentDetails('automaticReimbursement', newArray);
      }
  
      const handleSetAutomaticDeductions = (index, key, value) =>{
          const newArray = Array.from(employmentDetails.automaticDeductions);
          newArray[index][key]=value;
          handleSetEmploymentDetails('automaticDeductions', newArray);
      }
    
      const handleAddAutomaticDeductions = () => {
          const newArray = Array.from(employmentDetails.automaticDeductions);
          newArray.push({
              label: "",
              amount: 0
          });
          handleSetEmploymentDetails('automaticDeductions', newArray);
      }
    
      const handleRemoveAutomaticDeductions = (index) => {
          const newArray = [];
          employmentDetails.automaticDeductions.forEach((ar,i) => {
              if(i !==index) {
                  newArray.push(ar);
              }
          });
          handleSetEmploymentDetails('automaticDeductions', newArray);
      }
      const handleSetChildSupport = (index, value) => {
          const newArray = Array.from(employmentDetails.childSupport);
          newArray[index] = value;
          handleSetEmploymentDetails('childSupport', newArray);
      };
      const handleAddChildSupport = () => {
          const newArray = Array.from(employmentDetails.childSupport);
          newArray.push(0);
          handleSetEmploymentDetails('childSupport', newArray);
      };
  
      const handleRemoveChildSupport = (index) => {
          const newArray = [];
          employmentDetails.childSupport.forEach((cs, i) => {
              if (i !== index) {
                  newArray.push(cs);
              }
          });
          handleSetEmploymentDetails('childSupport', newArray);
      };
  
  
  
    const childSupportElements = employmentDetails.childSupport.map((cs, index) => {
    
        return (
          <ChildSupportElement key={index} value={cs} index={index} handleSetChildSupport={handleSetChildSupport} removeChildSupport={handleRemoveChildSupport}/>
        );
    });
    
    const automaticReimbursementElements = employmentDetails.automaticReimbursement.map((ar, index) => {
        
        return (
          <AutomaticReimbursement key={index} reimbursement={ar} index={index} handleSetAutomaticReimbursement={handleSetAutomaticReimbursement} handleRemoveAutomaticReimbursement={handleRemoveAutomaticReimbursement}/>
        );
    });

    const automaticDeductionElements = employmentDetails.automaticDeductions.map((ar, index) => {
  
        return (
            <AutomaticDeductionType key ={getFakeUID} deduction ={ar}  handleSetAutomaticDeduction={handleSetAutomaticDeductions} handleRemoveAutomaticDeduction={handleRemoveAutomaticDeductions} index={index}/> 
        );
    });
  
      return (
        <>
          <Modal.Body>
              <Container fluid>
                    <Row>
                        <RadioControl lg={4} validator={validationGroup.createValidator('employeeType', employmentDetails.employeeType, validateExistence)} inline title='Employee Type' selection={employmentDetails.employeeType} setSelection={(value) => {handleSetEmploymentDetails('employeeType', value)}} optionNames={['Full-Time', 'Part-Time']} optionValues={[0, 1]}/>
                        <RadioControl lg={4} validator={validationGroup.createValidator('title', employmentDetails.title, validateExistence)} inline title='Title' selection={employmentDetails.title} setSelection={(value) => {handleSetEmploymentDetails('title', value)}} optionNames={['Driver', 'BC']} optionValues={['Driver', 'BC']}/>
                        <RadioControl lg={4} validator={validationGroup.createValidator('csa', employmentDetails.csaIdentifier, validateExistence)} inline title='CSA' selection={employmentDetails.csaIdentifier} setSelection={(value) => {handleSetEmploymentDetails('csaIdentifier', value)}} optionNames={csas.map(c => c.csaName)} optionValues={csas.map(c => c.uid)}/>
                        <AvailabilityControl lg={6} validator={validationGroup.createValidator('availability', employmentDetails.availability, validateAvailablity)} inline title='Expected Availability' value={employmentDetails.availability} setValue={(value) => {handleSetEmploymentDetails('availability', value)}}/>
                        <RadioControl lg={6} validator={validationGroup.createValidator('payType', employmentDetails.payType, validateExistence)} inline title='Pay Type' selection={employmentDetails.payType} setSelection={(value) => {handleSetEmploymentDetails('payType', value)}} optionNames={['Per Year', 'Per Day', 'Per Hour', 'Per Stop', 'Per Mile']} optionValues={['py', 'pd', 'ph', 'ps', 'pm']}/>
                        <CustomControl validator={validationGroup.createValidator('payRate', employmentDetails.payRate, validateExistence)} max={10} lg={4} type='number' title='Pay Rate' value={employmentDetails.payRate} onChange={(event) => handleSetEmploymentDetails('payRate', event.target.value)}/>
                        <CustomControl validator={validationGroup.createValidator('hourlyWage', employmentDetails.hourlyWage, validateExistence)} max={10} lg={4} type='number' title='Hourly Wage' value={employmentDetails.hourlyWage} onChange={(event) => handleSetEmploymentDetails('hourlyWage', event.target.value)}/>
                        <Col lg={4} style={{paddingBottom:10}}>
                            <div style={{display:'flex', flexDirection:'column'}}>
                                <CustomControl validator={validationGroup.createValidator('ptoAccrual', employmentDetails.ptoAccrual, validateExistence)} max={10} type='number' title='PTO Accrual' value={employmentDetails.ptoAccrual} onChange={(event) => handleSetEmploymentDetails('ptoAccrual', event.target.value)}/>
                                <div style={{marginTop:5}}>
                                    <RadioControl lg={12} validator={validationGroup.createValidator('ptoType', employmentDetails.ptoType, validateExistence)} inline selection={employmentDetails.ptoType} setSelection={(value) => {handleSetEmploymentDetails('ptoType', value)}} optionNames={Object.values(ptoTypeDictionary)} optionValues={Object.keys(ptoTypeDictionary)}/>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} style={{paddingBottom:10}}>
                            <CustomControl validator={validationGroup.createValidator('stopBonusWeekdayThreshold', employmentDetails.stopBonusWeekdayThreshold, () => {})} max={10}  type='number' title='Weekday Stops Threshold' value={employmentDetails.stopBonusWeekdayThreshold} onChange={(event) => handleSetEmploymentDetails('stopBonusWeekdayThreshold', event.target.value)}/>
                            <span style={{fontSize:12}}>(Employee will recieve a bonus for all stops above this threshold.)</span>
                        </Col>
                        <Col lg={4} style={{paddingBottom:10}}>
                            <CustomControl validator={validationGroup.createValidator('stopBonusWeekendThreshold', employmentDetails.stopBonusWeekendThreshold, () => {})} max={10}  type='number' title='Weekend Stops Threshold' value={employmentDetails.stopBonusWeekendThreshold} onChange={(event) => handleSetEmploymentDetails('stopBonusWeekendThreshold', event.target.value)}/>
                            <span style={{fontSize:12}}>(Employee will recieve a bonus for all stops above this threshold.)</span>
                        </Col>
                        <Col lg={4} style={{paddingBottom:10}}>
                            <CustomControl validator={validationGroup.createValidator('stopBonusAmount', employmentDetails.stopBonusAmount, () => {})} max={10}  type='number' title='Weekday Stop Wage Dollar Amount' value={employmentDetails.stopBonusAmount} onChange={(event) => handleSetEmploymentDetails('stopBonusAmount', event.target.value)}/>
                            <span style={{fontSize:12}}>(Dollar amount per stop for all stops beyond the threshold.)</span>
                        </Col>
                        <Col lg={4} style={{paddingBottom:10}}>
                            <CustomControl validator={validationGroup.createValidator('stopBonusWeekendAmount', employmentDetails.stopBonusWeekendAmount, () => {})} max={10}  type='number' title='Weekend Stop Wage Dollar Amount' value={employmentDetails.stopBonusWeekendAmount} onChange={(event) => handleSetEmploymentDetails('stopBonusWeekendAmount', event.target.value)}/>
                            <span style={{fontSize:12}}>(Dollar amount per stop for all stops beyond the threshold.)</span>
                        </Col>
                        <CustomControl validator={validationGroup.createValidator('dailyStartTime', employmentDetails.dailyStartTime, validateExistence)} lg={4} type='time' title='Daily Start Time' value={employmentDetails.dailyStartTime} onChange={(event) => {handleSetEmploymentDetails('dailyStartTime', event.target.value)}}/>
                        <CustomControl validator={validationGroup.createValidator('startDate', employmentDetails.startDate, validateExistence)} lg={4} type='date' title='Start Date' value={employmentDetails.startDate} onChange={(event) => {handleSetEmploymentDetails('startDate', event.target.value)}}/>
                        <CustomControl validator={validationGroup.createValidator('firstDayStartTime', employmentDetails.firstDayStartTime, validateExistence)} lg={4} type='time' title='Start Time on First Day' value={employmentDetails.firstDayStartTime} onChange={(event) => {handleSetEmploymentDetails('firstDayStartTime', event.target.value)}}/>
                        <Col style={{marginBottom: 8}} lg={4}>
                            <DropdownControl title='Direct Supervisor' selection={employmentDetails.supervisor} setSelection={(value) => {handleSetEmploymentDetails('supervisor', value)}} itemNames={users.map(u => u.firstName + ' ' + u.lastName)} itemValues={users.map(u => u.companyUserUid)}/>
                        </Col>
                        <BwcCodeControl validator={validationGroup.createValidator('bwcCode', employmentDetails.bwcCode, validateExistence)} lg={12} inline value={employmentDetails.bwcCode} setValue={(value) => {handleSetEmploymentDetails('bwcCode', value)}}/>
                        
                        <CustomControl validator={validationGroup.createValidator('benefits', employmentDetails.benefits, () => {})} lg={12} type='text' title='Benefits' value={employmentDetails.benefits} onChange={(event) => {handleSetEmploymentDetails('benefits', event.target.value)}}/>
                    </Row>
                    <Row style={{border: '1px solid lightgray',borderRadius: 6, padding: 12, marginBottom: 8,}}>
                        <Col xxl={12}>
                            <p>Deductions</p>
                        </Col>
                        <CustomControl
                            lg={4}
                            max={10}
                            title='Medical Insurance'
                            type='number'
                            value={employmentDetails.medical}
                            onChange={(event) => {handleSetEmploymentDetails('medical', event.target.value)}}
                        />
                        <CustomControl
                            lg={4}
                            max={10}
                            title='Dental Insurance'
                            type='number'
                            value={employmentDetails.dental}
                            onChange={(event) => {handleSetEmploymentDetails('dental', event.target.value)}}
                        />
                        <CustomControl
                            lg={4}
                            max={10}
                            title='Vision Insurance'
                            type='number'
                            value={employmentDetails.vision}
                            onChange={(event) => {handleSetEmploymentDetails('vision', event.target.value)}}
                        />   
                        <Row style={{paddingTop:5}}>
                            <Col lg={6}>
                            <div style={{display:'flex', justifyContent:'center'}}>
                                <span style={{paddingRight:5}}>Child Support</span>
                                <Button style={{color: 'white', marginBottom: 8, borderRadius: 14, padding: '0px 5px 0px 5px'}}  onClick={handleAddChildSupport}>
                                <FontAwesomeIcon icon={faPlus} style={{color: 'white'}}/>
                                </Button> 
                            </div>
                            {childSupportElements.length > 0 &&
                                <QuickTable headers={['Deduction', 'Amount', '']} widths={[null,300,40]} rows={childSupportElements} size={'sm'}/>
                            }
                            </Col>
                            <Col lg={6}>
                            <div style={{display:'flex', justifyContent:'center'}}>
                                <span style={{paddingRight:5}}>Automatic Deductions</span>
                                <Button style={{color: 'white', marginBottom: 8, borderRadius: 14, padding: '0px 5px 0px 5px'}}  onClick={handleAddAutomaticDeductions}>
                                <FontAwesomeIcon icon={faPlus} style={{color: 'white'}}/>
                                </Button> 
                            </div>
                            {automaticDeductionElements.length > 0 && 
                                <QuickTable headers={['Deduction', 'Amount', '']} widths={[null,null,40]} rows={automaticDeductionElements} size={'sm'}/>}
                            </Col>
                        </Row>
                    </Row>
                    <Row style={{border: '1px solid lightgray', borderRadius: 6,padding: 12,marginBottom: 8, }}>
                        <Col lg={12}>
                            <div style={{display:'flex'}}>
                                <span style={{paddingRight:5}}>Automatic Reimbursements</span>
                                <Button style={{color: 'white', marginBottom: 8, borderRadius: 14, padding: '0px 5px 0px 5px'}}  onClick={handleAddAutomaticReimbursement}>
                                <FontAwesomeIcon icon={faPlus} style={{color: 'white'}}/>
                                </Button> 
                            </div>
                            {automaticReimbursementElements.length > 0 &&
                            <QuickTable headers={['Reimbursment', 'Amount', '']} widths={[null,null,40]} rows={automaticReimbursementElements} size={'sm'}/>}
                        </Col>
                    </Row>
              </Container>
          </Modal.Body>
        </>
      )
  }

  function AutomaticDeductionType({handleSetAutomaticDeduction, index, deduction, handleRemoveAutomaticDeduction}){
    const[showDropdown, setShowDropdown] = useState(false);
    const[deductionSearch, setDeductionSearch] = useState(deduction.label);
  
    const handleSetDropdown = (value) =>{
      handleSetAutomaticDeduction(index,'label',value);
      setDeductionSearch(value);
     }
  
    const deductionDropDownItems = Deduction.deductionTypes.map((type) =>{
      return (
          
          <Dropdown.Item key={type} eventKey={type} active={deductionSearch === type} onClick={() => {handleSetDropdown(type); setShowDropdown(false)}}>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                  <p style={{marginBottom: 0, marginRight: 8}}>{type}</p> 
              </div>
          </Dropdown.Item>  
      )
    });
    return (
          <tr>
              <td>   
                  <div style={{position:'absolute', marginBottom:2, marginTop:-1.5, width:'21%'}}>
                      <Form.Control  readOnly style={{height:'90%'}} placeholder='Deduction Type' type='text' value ={deductionSearch} onChange={(event)=>{handleSetDropdown(event.target.value)}} onFocus={()=>setShowDropdown(true)} onBlur={() => setTimeout(() => {setShowDropdown(false)}, 150)} />
                      <Dropdown.Menu onMouseDown={(e)=>e.preventDefault()} key="dropdown-menu" show={showDropdown} style={{maxHeight: 300, overflowY: 'scroll'}}>
                          {deductionDropDownItems}
                      </Dropdown.Menu>
                  </div>
              </td>
              <td style={{width:'35%'}}>
                  <InputGroup>
                  {deduction.label !== '401K (% of Gross)' && deduction.label !== 'Other (% of Gross)' ? <InputGroup.Text style={{padding: '2px 6px 2px 6px'}}>$</InputGroup.Text> : <InputGroup.Text style={{padding: '2px 6px 2px 6px'}}>%</InputGroup.Text> }
                      <Form.Control   style={{padding: '2px 6px 2px 6px'}} type='number' min={0} value={deduction.amount} placeholder='Pay Rate' onChange={(event) => {handleSetAutomaticDeduction(index, 'amount', event.target.value.replace('-', ''))}}/>
                  </InputGroup>
              </td>
              <td>
                  <Button  style={{color: 'white', padding: '2px 6px 2px 6px', width: '100%'}} onClick={() => handleRemoveAutomaticDeduction(index)}>
                      <FontAwesomeIcon icon={faTrashCan}/>
                  </Button>
              </td>
          </tr>
    )
  }
  
  function ChildSupportElement({value, removeChildSupport, handleSetChildSupport, index}){
    const [childSupport, setChildSupport] = useState(value);
  
    useEffect(() => {
        setChildSupport(value);
      }, [value]);
  
    const handleChange = (value) => {
        const newValue = value;
        setChildSupport(newValue);
        if (newValue !== '' || !isNaN(parseFloat(newValue))) {
            handleSetChildSupport(index, newValue);
        } else {
            handleSetChildSupport(index, 0);
        }
    };
    
    return(
        <tr>
            <td>Child Support #{index+1} </td>
            <td style={{width:'40%'}}>
                <InputGroup>
                    <InputGroup.Text style={{padding: '2px 6px 2px 6px'}}>$</InputGroup.Text>
                    <Form.Control style={{padding: '2px 6px 2px 6px'}} type='number' min={0} value={childSupport} placeholder='Amount' onChange={(event)=>{handleChange(event.target.value)}}/>
                </InputGroup>
            </td>
            <td>
                <Button  style={{color: 'white', padding: '2px 6px 2px 6px', width: '100%'}} onClick={() => removeChildSupport(index)}>
                    <FontAwesomeIcon icon={faTrashCan}/>
                </Button>
            </td>
        </tr>
    )
  }
  
  function AutomaticReimbursement({handleSetAutomaticReimbursement, index, reimbursement, handleRemoveAutomaticReimbursement}){
    const[showDropdown, setShowDropdown] = useState(false);
    const[reimbursementSearch, setReimbursementSearch] = useState(reimbursement.label);
  
    const handleSetDropdown = (value) =>{
      handleSetAutomaticReimbursement(index,'label',value);
      setReimbursementSearch(value);
     }
  
    const reimbursementDropDownItems = AdditionalPay.additionalPayTypes.filter(type => type === 'Per Diem' || type === 'Expense Reimbursement').map((type) =>{
      return (
          
          <Dropdown.Item key={type} eventKey={type} active={reimbursementSearch === type} onClick={() => {handleSetDropdown(type); setShowDropdown(false)}}>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                  <p style={{marginBottom: 0, marginRight: 8}}>{type}</p> 
              </div>
          </Dropdown.Item>  
      )
    });
  
    return (
      <tr>
          <td>   
            <div style={{position:'absolute', marginBottom:2, marginTop:-1.5, width:'21%'}}>
                <Form.Control readOnly style={{height:'90%'}} placeholder='Reimbursement Type' type='text' value ={reimbursementSearch} onChange={(event)=>{handleSetDropdown(event.target.value)}} onFocus={()=>setShowDropdown(true)} onBlur={() => setTimeout(() => {setShowDropdown(false)}, 150)} />
                <Dropdown.Menu onMouseDown={(e)=>e.preventDefault()} key="dropdown-menu" show={showDropdown} style={{maxHeight: 300, overflowY: 'scroll'}}>
                    {reimbursementDropDownItems}
                </Dropdown.Menu>
            </div>
          </td>
          <td style={{width:'35%'}}>
              <InputGroup>
              <InputGroup.Text style={{padding: '2px 6px 2px 6px'}}>$</InputGroup.Text>
                  <Form.Control  style={{padding: '2px 6px 2px 6px'}} type='number' min={0} value={reimbursement.amount} placeholder='Pay Rate' onChange={(event) => {handleSetAutomaticReimbursement(index, 'amount', event.target.value.replace('-', ''))}}/>
              </InputGroup>
          </td>
          <td>
              <Button  style={{color: 'white', padding: '2px 6px 2px 6px', width: '100%'}} onClick={() => handleRemoveAutomaticReimbursement(index)}>
                  <FontAwesomeIcon icon={faTrashCan}/>
              </Button>
          </td>
      </tr> 
    )
  }
