/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { addressToString, packageAddress } from "../../../tools";
import Form from 'react-bootstrap/Form';
import CompanyCSAModal from "../CompanyCsas/CompanyCSAModal";
import { getCompanyCsas } from "../../../services/CompanyServices/CompanyCsasService";
import PageSpinner from "../../../components/PageSpinner";
import QuickTable from "../../../components/QuickTable";

export default function CompanyCSAs({selectedCompany}) {
    const [isLoading, setIsLoading] = useState(false);
    const [showInactiveItems, setShowInactiveItems] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [csas, setCsas] = useState([]);

    const sortByUid = (a, b) => { return a.uid - b.uid };

    const loadData = async () => {
        setIsLoading(true);
        const response = await getCompanyCsas(selectedCompany.uid);
        if (response.status === '200') {
            setCsas(response.csas.sort(sortByUid));
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadData();
    }, [])

    const handleCrud = (type, csa) => {
        let newArray = Array.from(csas);
        if (type !== 'create') {
            newArray = newArray.filter(r => r.uid !== csa.uid);
        }
        if (type !== 'delete') {
            newArray.push(csa);
        }
        newArray.sort(sortByUid);
        setCsas(newArray);
        setShowModal(false);
    }

    const csaRows = csas.filter(csa => showInactiveItems || csa.isActive === '1').map((csa) => {
        return (
            <tr key={csa.uid} style={csa.isActive === '1' ? {} : {opacity: 0.5}} className='cursor-pointer' onClick={() => {setSelectedItem(csa); setShowModal(true);}}>
                <td>{csa.uid}</td>
                <td>{csa.csaName + (csa.isActive === '1' ? '' : ' (inactive)')}</td>
                <td>{addressToString(csa.address)}</td>
                <td>{csa.clockInRadius}</td>
            </tr>
        )
    });

    const content = (
        <div>
            <div style={{display: 'flex', paddingLeft: 24, paddingRight: 24, marginBottom: 12, justifyContent: 'space-between', alignItems: 'center'}}>
                <Form.Check style={{flexShrink: 0}} type='switch' label='Show Inactive CSAs' checked={showInactiveItems} onChange={() => {setShowInactiveItems(!showInactiveItems)}}/>
                <Button style={{color: 'white'}} onClick={() => {setSelectedItem(undefined); setShowModal(true);}}>
                    Add CSA
                </Button>
            </div>

            <QuickTable headers={['UID', 'Name', 'Address', 'Click-In Radius (mi)']} rows={csaRows} hover/>

            <Modal show={showModal} onHide={() => {setShowModal(false)}} centered size='lg'>
                <CompanyCSAModal data={selectedItem} handleCrud={handleCrud} selectedCompany={selectedCompany}/>
            </Modal>
        </div>
    )

    return (
        <>
            {isLoading ? <PageSpinner/> : content}
        </>
    )
}