/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import CustomControl from "../../../components/CustomControl";
import RadioControl from "../../../components/RadioControl";
import { createWorkArea, updateWorkArea, deleteWorkArea } from "../../../services/CompanyServices/CompanyWorkAreasService";
import CustomButton from "../../../components/CustomButton";
import { validateExistence, ValidationGroup } from "../../../validation";
import SwitchControl from "../../../components/SwitchControl";
import AlertModal from "../../../components/AlertModals/AlertModal";
import { encodeAvailability, parseAvailability } from "../../../tools";
import AvailabilityControl from "../../../components/AvailabilityControl";


export default function CompanyWorkAreaModal({data, handleCrud, csas, selectedCompany}) {
    const [workAreaName, setWorkAreaName] = useState(data ? data.workAreaName : '');
    const [csa, setCsa] = useState(data ? data.csaIdentifier : '');
    const [isActive, setIsActive] = useState(data ? data.isActive : '1');
    const [showModal, setShowModal] = useState(false);
    const [workAreaAvailability, setWorkAreaAvailability] = useState(data ? parseAvailability(data.workAreaAvailability) : [true, true, true, true, true, true, true]);

    const [isLoading, setIsLoading] = useState(false);

    const validationGroup = new ValidationGroup();

    const handleSubmit = () => {
        setIsLoading(true);
        const workAreaToSave = {
            uid: data ? data.uid : undefined,
            workAreaName: workAreaName,
            csaIdentifier: csa,
            isActive: isActive,
            workAreaAvailability: encodeAvailability(workAreaAvailability)
        }
        if (data) {
            updateWorkArea(selectedCompany.uid, workAreaToSave).then((response) => {
                if (response) {
                    handleCrud('update', workAreaToSave);
                }
                setIsLoading(false);
            });
        } else {
            createWorkArea(selectedCompany.uid, workAreaToSave).then((response) => {
                if (response && response.status === '200') {
                    workAreaToSave.uid = response.uid;
                    handleCrud('create', workAreaToSave);
                }
                setIsLoading(false);
            });
        }
    }

    const handleDeleteWorkArea = async () => {
        const response = await deleteWorkArea(data.uid);
        if (response.status === '200') {
            handleCrud('delete', data);
            return true;
        }
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{data ? 'Editing Work Area' : 'Creating Work Area'}</Modal.Title>
                {data ? 
                    <Button style={{marginLeft: 24, color: 'white'}} onClick={() => {setShowModal(true)}}>Delete Work Area</Button>              
                : ''}
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <SwitchControl md={6} title='Work Area Is Active' originalValue={data ? data.isActive : undefined} value={isActive} setValue={setIsActive}/>
                        <CustomControl md={6} validator={validationGroup.createValidator(() => validateExistence(workAreaName))} type='text' max={50} title='Name' originalValue={data ? data.workAreaName : undefined} value={workAreaName} onChange={(event) => {setWorkAreaName(event.target.value)}}/>
                        <AvailabilityControl xxl={12} inline title="Availability" value={workAreaAvailability} setValue={setWorkAreaAvailability}/>
                        <RadioControl md={12} validator={validationGroup.createValidator(() => validateExistence(csa))} title='CSA' selection={csa} setSelection={setCsa} originalValue={data ? data.csaIdentifier : undefined} optionNames={csas.map(csa => csa.csaName)} optionValues={csas.map(csa => csa.uid)} inline/>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton label={data ? 'Save Changes' : 'Create Work Area'} disabled={!validationGroup.isValid()} isLoading={isLoading} onClick={handleSubmit}/>
            </Modal.Footer>
            <AlertModal 
                show={showModal} 
                onHide={() => setShowModal(false)} 
                centered 
                title='Permanently Delete Work Area?' 
                message='This action cannot be undone' 
                buttonLabel='Confirm Deletion' 
                callBack={handleDeleteWorkArea}
            />
        </>
    )
}