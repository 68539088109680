/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from "../serviceTools";

export async function getCompanyNotes(companyIdentifier) {
    const data = {
        type: 'company',
        target: 'getCompanyNotes',
        authToken: getToken(),
        companyIdentifier: companyIdentifier
    }

    const response = await getResponse(data);
    return response;
}

export async function deleteCompanyNote(uid) {
    const data = {
        type: 'company',
        target: 'deleteCompanyNote',
        authToken: getToken(),
        uid: uid
    }

    const response = await getResponse(data);
    return response;
}