/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import CustomButton from "../../components/CustomButton";
import DropdownControl from "../../components/DropdownControl";
import { validateExistence, ValidationGroup } from "../../validation";
import { createCustomPayroll } from "../../services/PayrollServiceMtb";
import Alert from 'react-bootstrap/Alert';
import moment from "moment";

export default function CreateCustomPayroll({companies, handleAddPayrollPeriod, payrollPeriods}) {
    const [isLoading, setIsLoading] = useState(false);
    const [company, setCompany] = useState(undefined);
    const [payrollIdentifier, setPayrollIdentifier] = useState(undefined);

    const validationGroup = new ValidationGroup();

    const handleSubmit = async () => {
        setIsLoading(true);
        const period = payrollPeriods.find(p => p.uid == payrollIdentifier);
        if (period) {
            const response = await createCustomPayroll(company, period.periodStart, period.periodEnd);
            if (response.status === 200) {
                handleAddPayrollPeriod({
                    uid: response.uid,
                    companyIdentifier: company,
                    periodStart: period.periodStart,
                    periodEnd: period.periodEnd,
                    isCustom: 1
                });
            }
        }
        setIsLoading(false);
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Creating Custom Payroll</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <DropdownControl 
                    validator={validationGroup.createValidator(() => validateExistence(company))} 
                    title='Company' selection={company} setSelection={setCompany} 
                    itemNames={companies.map(c => c.name)} 
                    itemValues={companies.map(c => c.uid)}
                    disabled={isLoading}
                />
                { company &&
                    <DropdownControl 
                        validator={validationGroup.createValidator(() => validateExistence(payrollIdentifier))} 
                        title='Period' selection={payrollIdentifier} setSelection={setPayrollIdentifier} 
                        itemNames={payrollPeriods.filter(p => p.companyIdentifier == company).map(p => `${moment(p.periodStart).format('MMM D, YYYY')} - ${moment(p.periodEnd).format('MMM D, YYYY')}`)} 
                        itemValues={payrollPeriods.filter(p => p.companyIdentifier == company).map(p => p.uid)}
                        disabled={isLoading}
                    />
                }
            </Modal.Body>
            <Modal.Footer>
                <CustomButton label='Create Payroll' isLoading={isLoading} disabled={!validationGroup.isValid()} onClick={handleSubmit}/>
            </Modal.Footer>
        </>
    )
}