/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from "../serviceTools";
import { timeout } from "../../tools";
import { getFakeUID } from "../../fake-data";

export async function getCompanyCsas(companyIdentifier) {
    const data = {
        type: 'company',
        target: 'getCsas',
        authToken: getToken(),
        companyIdentifier: companyIdentifier
    }

    const response = await getResponse(data);
    return response;

}

export async function createCsa(companyIdentifier, csa) {
    const data = {
        type: 'company',
        target: 'createCsa',
        authToken: getToken(),
        companyIdentifier: companyIdentifier,
        data: csa,
    }

    const response = await getResponse(data);
    return response;
}
export async function updateCsa(companyIdentifier, csa) {
    const data = {
        type: 'company',
        target: 'updateCsa',
        authToken: getToken(),
        companyIdentifier: companyIdentifier,
        data: csa,
    }

    const response = await getResponse(data);
    return response;
}
export async function deleteCsa(uid) {
    const data = {
        type: 'company',
        target: 'deleteCsa',
        authToken: getToken(),
        uid: uid,
    }

    const response = await getResponse(data);
    return response;
}