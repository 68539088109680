/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from "./serviceTools";

export async function getPayrollHistory() {
    var data = {
        type: 'payroll',
        target: 'getHistory',
        authToken: getToken(),
    }
    const response = await getResponse(data);

    return response;
}

export async function getPayrollPeriod(uid) {
    var data = {
        type: 'payroll',
        target: 'getPayrollPeriod',
        authToken: getToken(),
        uid: uid
    }
    const response = await getResponse(data);
    return response;
}
    
export async function reviewPayrollPeriod(uid) {
    var data = {
        type: 'payroll',
        target: 'reviewPayrollPeriod',
        authToken: getToken(),
        uid: uid
    }
    const response = await getResponse(data);
    return response;
}

export async function approvePayrollPeriod(payrollPeriod, transactions) {
    var data = {
        type: 'payroll',
        target: 'approvePayrollPeriod',
        authToken: getToken(),
        data: payrollPeriod,
        transactions:transactions
    }

    const response = await getResponse(data);
    return response;
}

export async function getHRUserTimesheetEntries(companyUserIdentifier, startDate, endDate) {
    var data = {
        type: 'hr',
        target: 'getTimesheet',
        authToken: getToken(),
        companyUserIdentifier: companyUserIdentifier,
        startDate: startDate,
        endDate: endDate
    }

    const response = await getResponse(data);
    return response;
}

export async function savePayrollVersion(payrollVersion){
    var data = {
        type:'payroll',
        target: 'savePayrollVersion',
        authToken: getToken(),
        payrollVersion: payrollVersion
    }

    const response = await getResponse(data);
    return response;
}

export async function getPayrollVersion(uid){
    var data = {
        type : 'payroll',
        target: 'getPayrollVersion',
        authToken: getToken(),
        uid: uid
    }

    const response = await getResponse(data);
    return response;
}

export async function getEmployeePayrollPto(companyUserIdentifier, payrollPeriod) {
    var data = {
        type: 'payroll',
        target: 'getEmployeePayrollPto',
        authToken: getToken(),
        companyUserIdentifier: companyUserIdentifier,
        payrollPeriod: payrollPeriod
    }

    return await getResponse(data);
}

//MTB Only

export async function hydratePayrollEntry(entry) {
    var data = {
        type: 'payroll',
        target: 'hydratePayrollEntry',
        token: getToken(),
        entry: entry,
    }

    return await getResponse(data);
}

export async function generatePayrollEntries(payrollIdentifier, userIdentifiers) {
    var data = {
        type: 'payroll',
        target: 'generatePayrollEntries',
        token: getToken(),
        payrollIdentifier: payrollIdentifier,
        userIdentifiers: userIdentifiers
    }

    return await getResponse(data);
}