/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";

import CustomControl from "../../../components/CustomControl";
import Row from "react-bootstrap/Row";
import Container from 'react-bootstrap/Container';
import RadioControl from "../../../components/RadioControl";
import QuickTable from "../../../components/QuickTable";
import { Button } from "react-bootstrap";
import { getFakeUID } from "../../../fake-data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faX } from "@fortawesome/free-solid-svg-icons";
import SwitchControl from "../../../components/SwitchControl";


export default function CreateCompanyVehicles({vehicles, handleAddVehicle, handleRemoveVehicle, csas}) {


    const [vehicleName, setVehicleName] = useState('');
    const [vehicleWeight, setVehicleWeight] = useState('');
    const [csaIdentifier, setCsaIdentifier] = useState('');
    const [isStraight, setIsStraight] = useState('0');


    const vehicleRows = vehicles.map((v) => {

        const csaName = v.csaIdentifier ? csas.find(r => r.uid === v.csaIdentifier).csaName : ''

        return (
            <tr key={v.uid}>
                <td>{v.vehicleName}</td>
                <td>{v.vehicleWeight}</td>
                <td>{csaName}</td>
                <td>{v.isStraight == '1' ? 'Yes' : 'No'}</td>
                <td style={{width: 0}}>
                    <Button onClick={() => {handleRemoveVehicle(v.uid)}}>
                        <FontAwesomeIcon icon={faX}/>
                    </Button>
                </td>
            </tr>
        )
    })

    const clearForm = () => {
        setVehicleName('');
        setVehicleWeight('');
        setIsStraight('0');
    }
    
    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: 8}}>
            <QuickTable headers={['Name', 'Weight', 'CSA', 'Straight Truck?', '']} rows={vehicleRows}/>
            <FontAwesomeIcon icon={faArrowUp} size='2x'/>
            <Container fluid style={{border: 'solid 1px lightgray', padding: 12, borderRadius: 6}}>
                <Row>
                    <CustomControl md={6} type='text' title='Name' max={50} value={vehicleName} onChange={(event) => {setVehicleName(event.target.value)}}/>
                    <CustomControl md={6} type='number' title='Weight' max={50} value={vehicleWeight} onChange={(event) => {setVehicleWeight(event.target.value)}}/>
                    <RadioControl md={6} title='CSA' selection={csaIdentifier} setSelection={setCsaIdentifier} optionNames={csas.map(csa => csa.csaName)} optionValues={csas.map(csa => csa.uid)} inline/>
                    <SwitchControl md={6} title='Straight Truck?' value={isStraight} setValue={setIsStraight}/>
                </Row>
                <Button disabled={!vehicleName || !vehicleWeight || !csaIdentifier} onClick={() => {handleAddVehicle({uid: getFakeUID(), vehicleName: vehicleName, vehicleWeight: vehicleWeight, csaIdentifier: csaIdentifier, isStraight: isStraight}); clearForm()}}>Add Vehicle</Button>
            </Container>
        </div>
    )
}