/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import CustomButton from "../../components/CustomButton";
import CustomControl from "../../components/CustomControl";
import DropdownControl from "../../components/DropdownControl";
import { validateExistence, ValidationGroup } from "../../validation";
import { generatePayroll } from "../../services/PayrollServiceAdmin";
import Alert from 'react-bootstrap/Alert';
import moment from "moment";

export default function GeneratePayrollModal() {
    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState(undefined);
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));

    const handleSubmit = async () => {
        if (date) {
            setIsLoading(true);
            setResponse(undefined);
            const response = await generatePayroll(date);
            setResponse(response);
            setIsLoading(false);
        }
    }

    const logElements = (response?.logs ?? []).map((log, index) => {
        return (
            <li key={index}>
                {log}
            </li>
        )
    })

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Generate Payroll</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                { response ? 
                    <>
                        {response.status === '200' ? 
                            <span style={{color: 'green', textAlign: 'center'}}>
                                {response.logs?.length > 0 ? 'Successfully generated payroll!' : 'Nothing was generated today.'}
                            </span>
                        : response.status === '500' ?
                            <span style={{color: 'red', textAlign: 'center'}}>Failed to generate payroll for all companies. Any changes have been rolled back.</span>
                        : response.status === '401' ?
                            <span style={{color: 'red', textAlign: 'center'}}>You do not have the permissions to do this</span>
                        :
                            <span style={{color: 'red', textAlign: 'center'}}>Something unexpected went wrong</span>
                        }
                    </>
                    :
                    <div>
                        <p style={{textAlign: 'center'}}>
                            Press the button to generate payroll for eligible companies whose payroll period starts on <b>{moment(date).add(1, 'day').format('dddd')}</b> and ends on <b>{moment(date).format('MMM D, YYYY')}</b>
                        </p>
                        <CustomControl type='date' title='Selected Date' value={date} onChange={(event) => {setDate(event.target.value)}}/>
                    </div>
                }
                <ul style={{marginBottom: 0}}>
                    {logElements}
                </ul>
            </Modal.Body>
            <Modal.Footer>
                { response ?
                    <CustomButton disabled={!date} label='Keep Generating' onClick={() => {setResponse(undefined)}}/>
                    :
                    <CustomButton disabled={!date} label='Generate Payroll' isLoading={isLoading} onClick={handleSubmit}/>
                }
            </Modal.Footer>
        </>
    )
}