/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import { getFakeUID } from "../../fake-data";
import { timeout } from "../../tools";
import { getResponse, getToken } from "../serviceTools";


const fakeNotes = [];

for (let i = 0; i < 20; i++) {
    fakeNotes.push({
        uid: i + 1,
        companyUserIdentifier: '53',
        submissionDate: moment().subtract(i * 15 + 15, 'days').format('YYYY-MM-DD'),
        author: ['53', '54', '68', '104'][i % 4],
        title: 'loremIpsum.slice(Math.min(i * 3, loremIpsum.length - 8), Math.min(i * 3 + 8, loremIpsum.length))',
        body: 'loremIpsum.slice(Math.min(i * 5, loremIpsum.length - 500), Math.min(i * 5 + 500, loremIpsum.length))'
    });
}

export async function getEmployeeNotes(companyUserIdentifier) {
    var data = {
        type: 'hr',
        target: 'REPLACE',
        authToken: getToken(),
        companyUserIdentifier: companyUserIdentifier
    }

    //const response = await getResponse(data);

    await timeout(300);

    const response = {
        status: '200',
        data: fakeNotes,
        currentCompanyUser: '53',
    }

    return response;
}

export async function createEmployeeNote(note) {
    var data = {
        type: 'hr',
        target: 'REPLACE',
        authToken: getToken(),
        data: note
    }

    //const response = await getResponse(data);

    const response = {
        status: '200',
        uid: getFakeUID()
    }

    await timeout(300);

    return response;
}

export async function deleteEmployeeNote(uid) {
    var data = {
        type: 'hr',
        target: 'REPLACE',
        authToken: getToken(),
        uid: uid
    }

    //const response = await getResponse(data);

    const response = {
        status: '200',
    }

    await timeout(300);

    return response;
}