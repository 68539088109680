/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../decoding";
import { validateDecimal } from "../payrollTools";
import Big from "big.js";
import { getFakeUID } from "../../../tools";

export default class Holiday {
    id;
    payType;
    payRate;
    unitsWorked;

    constructor(payType, payRate, unitsWorked) {
        this.id = getFakeUID();
        this.payType = payType
        this.payRate = payRate;
        this.unitsWorked = unitsWorked;
        
    }

    static initDefault() {
        return new Holiday('flat', 0.0, 0.0);
    }

    static decode(json) {
        const decoder = new Decoder(json);
        const payType = decoder.decode('payType', Decoder.StringStrict);
        const payRate = decoder.decode('payRate', Decoder.Decimal);
        const unitsWorked = decoder.decode('unitsWorked', Decoder.Decimal);
        
        if (decoder.checkForErrors()) {
            return new Holiday(payType, payRate, unitsWorked);
        } else {
            return Holiday.initDefault();
        }
    }

    encode() {
        return {
            payType: this.payType,
            payRate: validateDecimal(this.payRate),
            unitsWorked: validateDecimal(this.unitsWorked)
        }
    }

    duplicate() {
        return new Holiday(this.payType, this.payRate, this.unitsWorked);
    }

    holidayPay(){
        if(this.payType === 'flat'){
            return new Big(this.payRate);
        } else if(this.payRate !== '' && this.unitsWorked !== ''){
            return new Big(this.payRate).times(this.unitsWorked)
        } else {
            return new Big('0.00')
        }
    }
}