/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import { Alert, Modal, Form } from "react-bootstrap";
import CompanySelector from "./CompanySelector";
import Button from 'react-bootstrap/Button';
import ButtonGroup from "react-bootstrap/ButtonGroup";
import CreateCompany from "./CompanyCreator/CreateCompany";
import CompanyCSAs from "./CompanyCsas/CompanyCSAs";
import CompanyWorkAreas from "./CompanyWorkAreas/CompanyWorkAreas";
import CompanyVehicles from "./CompanyVehicles/CompanyVehicles";
import CompanyProfile from "./CompanyProfile";
import CompanySettings from "./CompanySettings";
import CompanyDocuments from "./CompanyDocuments/CompanyDocuments";
import CompanyNotes from "./CompanyNotes/CompanyNotes";
import HRHire from "./CompanyHire/HRHire";
import HRHireCSV from "./CompanyHire/HRHireCSV/HRHireCSV";
import { disableCompany, enableCompany } from "../../services/CompanyServices/CompaniesService";
import CustomButton from "../../components/CustomButton";
import CompanyPasswordReset from "./CompanyPasswordReset";

export default function Companies() {
    const [modalSwitch, setModalSwitch] = useState('companySelector');
    const [selectedCompany, setSelectedCompany] = useState(undefined);
    const [activeTab, setActiveTab] = useState('Company Profile');
    const [showDisableModal, setShowDisableModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [disableConfirmation, setDisableConfirmation] = useState('');

    const tabs = {
        'Company Profile': <CompanyProfile selectedCompany={selectedCompany}/>,
        'Settings': <CompanySettings selectedCompany={selectedCompany}/>,
        'Documents': <CompanyDocuments selectedCompany={selectedCompany}/>,
        'Notes': <CompanyNotes selectedCompany={selectedCompany}/>,
        'CSAs': <CompanyCSAs selectedCompany={selectedCompany}/>,
        'Work Areas': <CompanyWorkAreas selectedCompany={selectedCompany}/>,
        'Vehicles': <CompanyVehicles selectedCompany={selectedCompany}/>
    };

    const tabButtons = Object.keys(tabs).map((key) => {
        return (
            <Button 
                key={key}
                style={activeTab === key ? {color: 'white'} : {color: 'var(--bs-primary)', backgroundColor: 'white'}} 
                onClick={() => setActiveTab(key)}
                >
                {key}
            </Button>
        )
    });

    const handleEnableDisableCompany = async() =>{
        setIsLoading(true);
        if(selectedCompany.isEnabled == '1'){
            const response = await disableCompany(selectedCompany.uid);
            if(response.status == '200'){
                setShowDisableModal(false);
                setDisableConfirmation('')
                selectedCompany.isEnabled = '0'
            }
        } else {
            const response = await enableCompany(selectedCompany.uid);
            if(response.status == '200'){
                setShowDisableModal(false);
                setDisableConfirmation('')
                selectedCompany.isEnabled = '1'
            }
        }
        setIsLoading(false);
    }

    const confirmationCode = selectedCompany && selectedCompany.isEnabled == '1' ? 'disable company' : 'enable company';

    const letterElements = confirmationCode.split('').map((char, index) => {
        return (
            <p key={index} style={{display: 'inline', marginBottom: 0, color: (disableConfirmation.length >= (index + 1) && disableConfirmation.charAt(index) === char) ? 'green' : 'red'}}>{char}</p>
        )
    });
    return (
        <div className="page-wrapper" style={{padding: 12, flexDirection: 'column', gap: 12}}>
            <div style={{display: 'flex', gap: 12}}>
                { selectedCompany ?
                    <Alert style={{paddingTop: 4, paddingBottom: 4, marginBottom: 0}}>
                        <span>Currently Selected: </span>
                        <span style={{fontWeight: 'bold'}}>{selectedCompany.companyName}</span>
                    </Alert>
                : ''}
                <Button onClick={() => {setModalSwitch('companySelector'); setSelectedCompany(undefined);}}>{selectedCompany ? 'Change Company' : 'Select Company'}</Button>
                {selectedCompany && <Button onClick={()=>setShowDisableModal(true)}>{selectedCompany && selectedCompany.isEnabled == '1' ? 'Disable Company' : 'Enable Company'}</Button>}
                { selectedCompany && 
                    <Button onClick={() => {setModalSwitch('resetPasswords')}}>Reset Employee Passwords</Button>
                }
                <div style={{flex: 1}}/>
                <Button onClick={() => setModalSwitch('createCompany')}>Onboard New Company</Button>
                { selectedCompany &&
                    <>
                        {/* <Button onClick={() => setModalSwitch('hire')}>Hire New Employee</Button> */}
                        {/* <Button onClick={() => setModalSwitch('csvHire')}>CSV Hire</Button> */}
                    </>
                }
            </div>
            { selectedCompany &&
                <>
                    <ButtonGroup>
                        {tabButtons}
                    </ButtonGroup>
                    <div style={{height: '100%', margin: '12px 0px 0px 12px',  overflowY: 'scroll'}}>
                        <div style={{height: '100%', marginRight: 12}}>
                            {tabs[activeTab]}
                        </div>
                    </div>
                </>
            }
            <Modal show={modalSwitch === 'companySelector'} onHide={() => setModalSwitch('')} centered size="lg">
                <CompanySelector handleSelectCompany={(company) => {setSelectedCompany(company); setModalSwitch('')}} setModalSwitch={(value) => {setModalSwitch(value)}}/>
            </Modal>
            <Modal show={modalSwitch === 'createCompany'} onHide={() => setModalSwitch('')} centered size='xl' backdrop='static'>
                <CreateCompany hideModal={() => setModalSwitch('')}/>
            </Modal>
            <Modal backdrop='static' show={modalSwitch === 'hire'} size='xl'>
                <HRHire selectedCompany={selectedCompany} hideModal={() => {setModalSwitch('')}}/>
            </Modal>
            <Modal backdrop='static' show={modalSwitch === 'csvHire'} size='xl'>
                <HRHireCSV selectedCompany={selectedCompany} hideModal={() => {setModalSwitch('')}}/>
            </Modal>
            <Modal show={showDisableModal} onHide={()=>setShowDisableModal(false)}>
                {selectedCompany && 
                <>
                    <Modal.Header closeButton>
                        {selectedCompany.isEnabled == '1' ?
                        <Modal.Title>{'Disable ' + selectedCompany.companyName + '?'}</Modal.Title> :
                        <Modal.Title>{'Enable ' + selectedCompany.companyName + '?'}</Modal.Title> 
                        }
                    </Modal.Header>
                    <Modal.Body>
                        {selectedCompany && selectedCompany.isEnabled =='1' ? 
                        <p>{'Once disabled all users from this company will no longer be able to login to their ' + selectedCompany.companyName + ' TTA accounts.'} </p> :
                        <p>{'Once enabled all users from this company will have access to their ' + selectedCompany.companyName + ' TTA accounts.'} </p> 
                        }
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 12}}>
                        <Form.Group>
                            {selectedCompany.isEnabled ? 
                            <Form.Label>Type "{letterElements}" to confirm deactivation</Form.Label> :
                            <Form.Label>Type "{letterElements}" to confirm activation</Form.Label>
                            }
                            <Form.Control isValid={disableConfirmation === confirmationCode} isInvalid={!confirmationCode.includes(disableConfirmation)} value={disableConfirmation} onChange={(event) => {setDisableConfirmation(event.target.value)}} type='text' placeholder={selectedCompany && selectedCompany.isEnabled == '1' ? 'disable company' : 'enable company'}/>
                        </Form.Group>
                        <CustomButton label={selectedCompany && selectedCompany.isEnabled =='1' ? 'Confirm Deactivation' : 'Confirm Activation'}  disabled={disableConfirmation !== confirmationCode} onClick={()=>handleEnableDisableCompany()}/>
                    </div>
                    </Modal.Body>
                </>
                }
            </Modal>
            <Modal show={modalSwitch === 'resetPasswords'} onHide={() => {setModalSwitch('')}}>
                <CompanyPasswordReset selectedCompany={selectedCompany}/>
            </Modal>

        </div>
    )
}