/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import './NavigationBar.css';
import { Accordion } from 'react-bootstrap';
import { 
    faUser, 
    faTableColumns,   
    faMoneyCheckAlt,
    faBuildingUser,
    faUsers,
    faTicket,
    faBell,
    faPeopleGroup,
    faWrench,
    
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../assets/logo.png';
import NavigationBarHeader from './NavigationBarHeader';
import NavigationBarLink from './NavigationBarLink';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import moment from 'moment';
import Cookies from 'universal-cookie';

function NavigationBar() {
    
    const userData = new Cookies().get('userData');

    return (
        <div className={'nav-bar-wrapper'} style={{flexShrink: 0}}>
            <div id='nav-menu-wrapper' style={{width: '100%', overflowY: 'scroll'}}>
                <img src={logo} alt={'TTA LOGO'} id='logo'/>
                
                
                <Accordion defaultActiveKey="0" style={{width: '100%', marginBottom: 8}}>
                    <Accordion.Item eventKey="0" className='accordion-item'>
                        <Accordion.Header>
                            <NavigationBarHeader title={'Data Management'} icon={faUser}/>
                        </Accordion.Header>
                        <Accordion.Body>
                            <NavigationBarLink title={'Dashboard'} icon={faTableColumns} destination={'/'}/>
                            <NavigationBarLink title={'Companies'} icon={faBuildingUser} destination={'/companies'}/>
                            <NavigationBarLink title={'Human Resources'} icon={faUsers} destination={'/human-resources'}/>
                            <NavigationBarLink title={'Linehaul HR'} icon={faUsers} destination={'/lh-human-resources'}/>
                            <NavigationBarLink title={'Payroll'} icon={faMoneyCheckAlt} destination={'/payroll'}/>
                            <NavigationBarLink title={'MTB Payroll'} icon={faMoneyCheckAlt} destination={'/mtb-payroll'}/>
                            <NavigationBarLink title={'LH Payroll'} icon={faMoneyCheckAlt} destination={'/lh-payroll'}/>
                            
                            <div style={{paddingRight: 8, paddingLeft: 32}}>
                                <a style={{textDecoration: 'none', display: 'flex', alignItems: 'center'}} href='https://tacticaltransportation.freshdesk.com/a/admin' target="_blank">
                                    <FontAwesomeIcon icon={faTicket} style={{marginRight: 8}}/>
                                    <p style={{fontSize: 18, margin: 0, }}> {'Tickets'}</p>
                                </a>
                            </div>
                            <NavigationBarLink title={'Notifications'} icon={faBell} destination={'/notifications'}/>
                           {userData['permissions'] === 'superDuperAdministrator' && <NavigationBarLink title={'Admin Tools'} icon={faWrench} destination={'/admin-tools'}/>}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                
            </div>

            <div style={{flex: 1}}/>
            
            <div id='footer' style={{justifySelf: 'flex-end'}}>
                <p style={{margin: 0, fontSize: 12}}>{`Copyright © ${moment().format('YYYY')}`}</p>
                <p style={{margin: 0, fontSize: 12}}>Tactical Transportation Advisors</p>
            </div>
            
        </div>
    );
}


export default NavigationBar;