/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { fakeMyScheduleData, getFakeUID } from "../../fake-data";
import { fakeCsas, fakeWorkAreas, fakeVehicles } from "../../fakeData/fakeCompanyResources";
import fakeUsers from "../../fakeData/fakeUsers";
import fakeCompanyUsers from "../../fakeData/fakeCompanyUsers";
import { getResponse, getToken } from "../serviceTools";
import { timeout } from "../../tools";
import moment from "moment";

export async function getHRUserScheduleItems(companyUserIdentifier, startDate, endDate) {
    var data = {
        type: 'hr',
        target: 'getEmployeeSchedule',
        authToken: getToken(),
        companyUserIdentifier: companyUserIdentifier,
        startDate: startDate ? startDate : '1000-01-01',
        endDate: endDate ? endDate : '3000-01-01' 
    }

    const response = await getResponse(data);
    return response;
}

export async function createScheduleItem(scheduleItem) {
    var data = {
        type: 'hr',
        target: 'createScheduleItem',
        authToken: getToken(),
        data: scheduleItem,
    }

    const response = await getResponse(data);
    return response;
}

export async function updateScheduleItem(scheduleItem) {
    var data = {
        type: 'hr',
        target: 'updateScheduleItem',
        authToken: getToken(),
        data: scheduleItem,
    }

    const response = await getResponse(data);
    return response;
}

export async function deleteScheduleItem(uid) {
    var data = {
        type: 'hr',
        target: 'deleteScheduleItem',
        authToken: getToken(),
        uid: uid,
    }
    
    const response = await getResponse(data);
    return response;
}

export async function rejectRequestOff(uid) {
    var data = {
        type: 'hr',
        target: 'rejectRequestOff',
        authToken: getToken(),
        uid: uid,
    }

    return await getResponse(data);
}

export async function acceptRequestOff(scheduleItemArray, uid) {
    var data = {
        type: 'hr',
        target: 'acceptRequestOff',
        authToken: getToken(),
        data: scheduleItemArray,
        uid: uid,
    }

    return await getResponse(data);
}

export async function getAllTimeOff(companyIdentifier, startDate, endDate) {
    var data = {
        type: 'hr',
        target: 'getAllTimeOff',
        authToken: getToken(),
        companyIdentifier: companyIdentifier,
        startDate: startDate,
        endDate: endDate
    }

    const response = await getResponse(data);
    return response;
}

export async function getEmployeeTimeOff(companyUserIdentifier) {
    var data = {
        type: 'hr',
        target: 'timeOff',
        authToken: getToken(),
        companyUserIdentifier: companyUserIdentifier,
    }

    const response = await getResponse(data);
    return response;
}