/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";

export default class Decoder {
    object;//: {[key: string]: any}
    errors = [];//: DecodingError[] = [];
    silent;//: boolean;


    constructor(object, silent = false) {
        this.object = object;
        this.silent = silent;
    }


    decode(key, test, fallback) {
        if (fallback) {
            try {
                return test(key, this.object[key]);
            } catch {
                if (fallback.warn) {
                    console.log(`Warning: Falling back to default value for ${key} with value ${this.object[key]}`);
                }
                return fallback.defaultValue;
            }
        } else {
            try {
                return test(key, this.object[key]);
            } catch(e) {
                this.errors.push(e);
            }
        }
    }
    checkForErrors() {
        if (this.errors.length === 0) {
            return true;
        } else {
            if (!this.silent) {
                this.errors.forEach(e => console.log(e.message));
            }
            return false;
        }
    }


    static Decode(obj, key, test, fallback) {
        if (fallback) {
            try {
                return test(key, obj[key]);
            } catch {
                if (fallback.warn) {
                    console.log(`Warning: Falling back to default value for ${key} with value ${obj[key]}`);
                }
                return fallback.defaultValue;
            }
        } else {
            try {
                return test(key, obj[key]);
            } catch(e) {
                throw e;
            }
        }
    }

    static String(key, value) {
        if (typeof value === 'object') {
            throw new DecodingError(key, value, 'String');
        } else {
            return value.toString();
        }
    }
    static StringStrict(key, value) {
        if (typeof value !== 'string') {
            throw new DecodingError(key, value, 'StringStrict');
        } else {
            return value;
        }
    }
    static NonEmptyString(key, value) {
        try {
            if (Decoder.StringStrict(key, value) === '') {
                throw new DecodingError(key, value, 'NonEmptyString');
            } else {
                return value;
            }
        } catch {
            throw new DecodingError(key, value, 'NonEmptyString');
        }
    }
    static Date(key, value) {
        try {
            if (!/^([123]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/g.test(Decoder.NonEmptyString(key, value))) {
                throw new DecodingError(key, value, 'Date');
            } else {
                return value;
            }
        } catch {
            throw new DecodingError(key, value, 'Date');
        }
    }
    static DateTime(key, value) {
        try {
            if (!/^[123]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]) ([01]\d|2[0-4]):([0-5]\d)$/g.test(Decoder.NonEmptyString(key, value))) {
                throw new DecodingError(key, value, 'Date');
            } else {
                return value;
            }
        } catch {
            throw new DecodingError(key, value, 'Date');
        }
    }
    static Integer(key, value) {
        const parsed = parseInt(value);
        if (!isNaN(parsed)) {
            return parsed;
        } else {
            throw new DecodingError(key, value, 'Integer');
        }
    }
    static IntegerStrict(key, value) {
        if (typeof value === 'number' && Math.floor(value) === value) {
            return value;
        } else {
            throw new DecodingError(key, value, 'IntegerStrict');
        }
    }
    static Decimal(key, value) {
        const parsed = parseFloat(value);
        if (!isNaN(parsed)) {
            return parsed;
        } else {
            throw new DecodingError(key, value, 'Decimal');
        }
    }
    static DecimalStrict(key, value) {
        if (typeof value === 'number') {
            return value;
        } else {
            throw new DecodingError(key, value, 'DecimalStrict');
        }
    }
    static Boolean(key, value) {
        if (typeof value === 'boolean') {
            return value;
        } else {
            throw new DecodingError(key, value, 'Boolean');
        }
    }
    static BooleanFlexible(key, value) {
        if (value === 1 || value === '1' || value === true) {
            return true;
        } else if (value === 0 || value === '0' || value === false) {
            return false;
        } else {
            throw new DecodingError(key, value, 'BooleanFlexible');
        }
    }
    static Uid(key, value) {
        const parsed = parseInt(value);
        if (!isNaN(parsed) && parsed > 0) {
            return parsed;
        } else {
            throw new DecodingError(key, value, 'Uid');
        }
    }
    static UidString(key, value) {
        const parsed = parseInt(value);
        if (!isNaN(parsed) && parsed > 0) {
            return value;
        } else {
            throw new DecodingError(key, value, 'UidString');
        }
    }
    static UidStrict(key, value) {
        try {
            if (Decoder.Integer(key, value) > 0) {
                return value;
            } else {
                throw new DecodingError(key, value, 'UidStrict');
            }
        } catch {
            throw new DecodingError(key, value, 'UidStrict');
        }
    }
    
    static NonEmptyArray(key, value) {
        if (Array.isArray(value) && value.length > 0) {
            return value;
        } else {
            throw new DecodingError(key, value, 'NonEmptyArray');
        }
    }
    static Array(key, value) {
        if (Array.isArray(value)) {
            return value;
        } else {
            throw new DecodingError(key, value, 'Array');
        }
    }
    static StringArray(key, value) {
        try {
            if (Decoder.Array(key, value) && !value.find(e => typeof e !== 'string')) {
                return value;
            } else {
                throw new DecodingError(key, value, 'StringArray');
            }
        } catch {
            throw new DecodingError(key, value, 'StringArray');
        }
    }
    static DecimalArray(key, value) {
        try {
            if (Decoder.Array(key, value) && !value.find(e => isNaN(parseFloat(e)))) {
                return value;
            } else {
                throw new DecodingError(key, value, 'DecimalArray');
            }
        } catch {
            throw new DecodingError(key, value, 'DecimalArray');
        }
    }
    static NonEmptyStringArray(key, value) {
        try {
            if (Decoder.NonEmptyArray(key, value) && !value.find(e => typeof e !== 'string')) {
                return value;
            } else {
                throw new DecodingError(key, value, 'NonEmptyStringArray');
            }
        } catch {
            throw new DecodingError(key, value, 'NonEmptyStringArray');
        }
    }
}

export class DecodingError extends Error {
    constructor(key, value, method) {
        super(`Failed to decode ${key} with value ${value} using method ${method}`);
        this.name = 'DecodingError';
    }
}

// export type DecodingFallback = {
//     defaultValue;
//     warn: boolean;
// }
