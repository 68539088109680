/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import { getEmployeeNotes } from '../../../services/HRServices/HRNotesService';
import moment from 'moment';
import Card from 'react-bootstrap/Card';
import { Button, Form } from 'react-bootstrap';
import CustomSpinner from '../../../components/CustomSpinner';
import SearchBar from '../../../components/SearchBar';
import { dateIsInRange, dateRangeToString } from '../../../tools';
import DateRangeSelector from '../../../components/DateRangeSelector';
import SwitchControl from '../../../components/SwitchControl';
import HRNotesEditor from './HRNotesEditor';
import Modal from 'react-bootstrap/Modal';

export default class HRNotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            notes: [],
            currentCompanyUser: undefined,
            startDate: '',
            endDate: '',
            filter: '',
            filterType: 'title',
            showDateRangeFilter: false,
            onlyShowAuthoredNotes: false,

            showEditor: false,
            selectedNote: undefined,
        }

        this.handleSetDateRange = this.handleSetDateRange.bind(this);
        this.handleCrud = this.handleCrud.bind(this);
    }


    sortNotes(a, b) {
        if (moment(a.submissionDate).isBefore(moment(b.submissionDate), 'days')) {
            return 1;
        } else if (moment(a.submissionDate).isAfter(moment(b.submissionDate), 'days')) {
            return -1;
        } else {
            return 0;
        }
    }

    async loadData() {
        this.setState({isLoading: true});
        const response = await getEmployeeNotes(this.props.companyUserUid);
        if (response.status === '200') {
            this.setState({notes: response.data.sort(this.sortNotes), currentCompanyUser: response.currentCompanyUser})
        } else if (response.status === '401') {
            window.location.reload(false);
        }

        this.setState({isLoading: false});

    }

    componentDidMount() {
        this.loadData();
    }

    handleCrud(type, obj) {
        let newArray = Array.from(this.state.notes);

        if (type === 'create') {
            newArray.push(obj);
        } else if (type === 'delete') {
            newArray = newArray.filter(r => r.uid !== obj.uid);
        }
        this.setState({notes: newArray.sort(this.sortNotes)});
    }

    handleSetDateRange(startDate, endDate) {
        this.setState({startDate: startDate, endDate: endDate});
    }


    render() {
        
        const filteredNotes = this.state.notes.filter((note) => {
            const isInRange = dateIsInRange(note.submissionDate, this.state.startDate, this.state.endDate);
            const isInSearch = !this.state.filter || note[this.state.filterType].toLocaleLowerCase().includes(this.state.filter.toLocaleLowerCase());
            const obeysOnlyShowAuthoredNotes = !this.state.onlyShowAuthoredNotes || this.state.currentCompanyUser === note.author;
            return isInRange && isInSearch && obeysOnlyShowAuthoredNotes;
        })

        const noteElements = filteredNotes.map((note) => {
            const author = this.props.users.find(u => u.companyUserUid === note.author);

            return (
                <Card key={note.uid} className='cursor-pointer' onClick={() => {this.setState({selectedNote: note, showEditor: true})}}>
                    <Card.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                        <Card.Title>{note.title}</Card.Title>
                        <div style={{backgroundColor: 'rgba(0, 0, 0, 0.05)', borderRadius: 6, padding: 6}}>
                            <span>{note.body.length > 200 ? (note.body.slice(0, 200) + '...') : note.body}</span>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <span style={{opacity: 0.5}}>{(note.lastModified ? 'Last edited on ' + moment(note.lastModified).format('MMM D, YYYY') : 'Created on ' + moment(note.submissionDate).format('MMM D, YYYY'))}</span>
                            <span style={{color: 'var(--bs-primary)'}}>{'- ' + author.firstName + ' ' + author.lastName}</span>
                        </div>
                    </Card.Body>
                </Card>
            )
        });

        const content = (
            <>
                <div style={{display: 'flex', gap: 12, alignItems: 'center', marginBottom: 12}}>
                    <SearchBar 
                        label='Filter by...' 
                        value={this.state.filter} 
                        setValue={(value) => {this.setState({filter: value})}} 
                        typeNames={['Title', 'Content']} 
                        typeValues={['title', 'body']} 
                        type={this.state.filterType} 
                        setType={(value) => {this.setState({filterType: value})}}
                    />
                    <Button style={{flexShrink: 0, color: 'white'}} onClick={() => {this.setState({showDateRangeFilter: true})}}>{'Submission Date Filter: ' + dateRangeToString(this.state.startDate, this.state.endDate)}</Button>
                    <div style={{flexShrink: 0}}>
                        <Form.Check type='switch' label='Only Show My Notes' value={this.state.onlyShowAuthoredNotes} onChange={() => {this.setState({onlyShowAuthoredNotes: !this.state.onlyShowAuthoredNotes})}}/>
                    </div>
                    <Button style={{flexShrink: 0, color: 'white'}} onClick={() => {this.setState({showEditor: true, selectedNote: undefined})}}>Create Note</Button>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: 12, maxHeight: '70vh', overflowY: 'scroll'}}>
                    {noteElements}
                </div>
                <DateRangeSelector show={this.state.showDateRangeFilter} onHide={() => {this.setState({showDateRangeFilter: false})}} centered handleSetDateRange={this.handleSetDateRange} presetOptions={['pastYear', 'pastMonth', 'pastWeek', 'allTime']}/>
            </>
        );

        return (
            <>
                {this.state.isLoading ? <CustomSpinner spinnerHeight='10vh' spinnerWidth='10vh' height='50vh'/> : content}
                <Modal show={this.state.showEditor} onHide={() => this.setState({showEditor: false})} size='lg'>
                    <HRNotesEditor onHide={() => this.setState({showEditor: false})} note={this.state.selectedNote} handleCrud={this.handleCrud} user={this.props.user} currentCompanyUser={this.state.currentCompanyUser}/>
                </Modal>
            </>
        )
    }
}