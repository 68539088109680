/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import Button from 'react-bootstrap/Button';
import CustomSpinner from "../../../components/CustomSpinner";
import PayrollEmployeeEditor from "./PayrollEmployeeEditor/PayrollEmployeeEditor";
import { 
    reviewPayrollPeriod as reviewPayrollPeriodAdmin, 
    approvePayrollPeriod as approvePayrollPeriodAdmin, 
    savePayrollVersion as savePayrollVersionAdmin, 
    deletePayroll
} from "../../../services/PayrollServiceAdmin";
import { 
    reviewPayrollPeriod as reviewPayrollPeriodClient, 
    approvePayrollPeriod as approvePayrollPeriodClient, 
    savePayrollVersion as savePayrollVersionClient, 
} from "../../../services/PayrollServiceClient";

import PayrollEmployeeSelector from "./PayrollEmployeeSelector";
import { bigToUsd, adminMode } from "../payrollTools";
import ClockOutCheck from './ClockOutCheck';
import { ListGroup, CloseButton, Form, ButtonGroup, Popover, OverlayTrigger } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClipboardCheck, faEllipsis } from "@fortawesome/free-solid-svg-icons";
import Card from 'react-bootstrap/Card';
import PayrollCSVDownloadModal from "../PayrollCSVDownloadModal";
import CustomButton from "../../../components/CustomButton";
import PayrollEntry from "../Models/PayrollEntry";
import Big from "big.js";
import PayrollPeriodPreview from "./PayrollPeriodPreview";
import PayrollEditorSavedTime from "./PayrollEditorSavedTime";
import PayrollVersionList from "./PayrollVersionList";
import Cookies from "universal-cookie";
// import SendPayrollBackToIC from "./PayrollEmployeeEditor/Modals/SendPayrollBackToIC";
import MultiplePayAlert from "./MultiplePayAlert";
import PayrollSettlementReportModal from "./SettlementReport/PayrollSettlementReportModal";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";

export default class PayrollEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isSubmitting: false,
            entries: [],
            selectedEntry: undefined,
            modalSwitch: 'none',
            notClockedOutUsers: [],
            
            userData: [],
            timesheetData: [],
            payData: [],
            ptoData: [],
            previousEntries: [],
            
            displayPtoInDays: false,
            employeeFilter: [],

            isSaving: false,
            isSaved: true,
            touched: false,
            dateTimeSave: undefined,

            showVersionSelector:false,

            originalReviewPayrollPeriod: undefined,
            
            payrollVersions: [],
            bcVersions: [],
            bcData: [],
            csas: [],

            settlementReportData:[],

            loadedVersion: undefined,

            //ADMIN STUFF:
            
            isCustom: false,
            deleteConfirmation:'',
        }
        this.handleSet = this.handleSet.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleProceed = this.handleProceed.bind(this);
        this.handleAddDBonusToAllEntries = this.handleAddDBonusToAllEntries.bind(this);
        this.handleAddDeductionToAllEntries = this.handleAddDeductionToAllEntries.bind(this);
        this.handleAddNdToAllEntries = this.handleAddNdToAllEntries.bind(this);
        this.handleAddAPToAllEntries = this.handleAddAPToAllEntries.bind(this);
        this.handleSetEntries = this.handleSetEntries.bind(this);
        this.handleAddEntries = this.handleAddEntries.bind(this);
        this.handleSetTimesheetData = this.handleSetTimesheetData.bind(this);
        this.handleUpdateUserFedexIds = this.handleUpdateUserFedexIds.bind(this);
        this.handleSetSettlementReportData = this.handleSetSettlementReportData.bind(this);
        this.cookieMonster = new Cookies();
        this.userData = this.cookieMonster.get('userData');
    }

    sortEntries(a, b) {
        if (a.csaName < b.csaName) {
            return -1;
        } else if (a.csaName > b.csaName) {
            return 1;
        } else {
            if (a.isNew != b.isNew) {
                if (a.isNew) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if (a.name() < b.name()) {
                    return -1;
                } else if (a.name() > b.name()) {
                    return 1;
                } else {
                    return 0;
                }
            }
        }
    }

    handleUpdateUserFedexIds(sReportObjects){
        const newArr = Array.from(this.state.userData);
        newArr.forEach((u)=>{
            let reportForUser = sReportObjects.find((o)=>o.companyUserIdentifier == u.companyUserUid);
            if(reportForUser){
                u.fedexId = reportForUser.fedexId;
            }
        });
        this.setState({userData:newArr});
    }

    handleSetSettlementReportData(settlementReport){
        const newArr = Array.from(this.state.settlementReportData).filter((s)=>s.csaIdentifier != settlementReport.csaIdentifier || s.startDate != settlementReport.startDate || s.endDate != settlementReport.endDate);
        newArr.push(settlementReport);
        this.setState({settlementReportData:newArr});
    }

    async loadData() {
        this.setState({isLoading: true});

        let response;

        if (adminMode) {
            response = await reviewPayrollPeriodAdmin(this.props.selectedPayPeriod);
        } else {
            response = await reviewPayrollPeriodClient(this.props.selectedPayPeriod.uid);
        }

        if (response.status == '200') {
            let entries = [];
            let didGenerate = false;
            const previousPayrollPeriod = response.previousPayrollPeriod ? PayrollEntry.decodeArray(response.previousPayrollPeriod.approvedEntries ?? [], response.previousPayrollPeriod.periodStart, response.previousPayrollPeriod.periodEnd) : undefined;
            const hydrationData = {
                ptoData: response.pto, 
                ptoBalanceData: response.ptoBalanceData, 
                loanData: response.loanData, 
                ticketData: response.ticketData
            }

            if (response.payrollPeriod.approvedEntries) { //TTA Submission
                entries = PayrollEntry.decodeArray(response.payrollPeriod.approvedEntries, this.props.selectedPayPeriod.periodStart, this.props.selectedPayPeriod.periodEnd, hydrationData);
                this.setState({loadedVersion: {uid: this.props.selectedPayPeriod.uid, type: 'ttaSubmission'}});
            } else if (response.latestVersion && response.latestVersion.isTta == '1') { //TTA Saved Version
                entries = PayrollEntry.decodeArray(response.latestVersion.entries, this.props.selectedPayPeriod.periodStart, this.props.selectedPayPeriod.periodEnd, hydrationData);
                this.setState({loadedVersion: {uid: response.latestVersion.uid, type: 'version'}});
            } else if (response.payrollPeriod.entries) { //AO Submission
                entries = PayrollEntry.decodeArray(response.payrollPeriod.entries, this.props.selectedPayPeriod.periodStart, this.props.selectedPayPeriod.periodEnd, hydrationData);
                this.setState({loadedVersion: {uid: this.props.selectedPayPeriod.uid, type: 'aoSubmission'}});
            } else if (response.latestVersion && response.latestVersion.payrollIdentifier) { //AO Saved Version
                entries = PayrollEntry.decodeArray(response.latestVersion.entries, this.props.selectedPayPeriod.periodStart, this.props.selectedPayPeriod.periodEnd, hydrationData);
                this.setState({loadedVersion: {uid: response.latestVersion.uid, type: 'version'}});
                if (!adminMode && parseInt(response.latestVersion.companyUserIdentifier) < 0) { // CLIENT ONLY: this means it's actually a TTA version sent back
                    this.setState({modalSwitch:'sentBack'});
                }
            } else if (response.bcData.find((bc) => response.payrollPeriod.csaIdentifier || bc.csaIdentifier == '-1')) { //Unrestricted BC Submission
                const bcData = response.bcData.find((bc) => response.payrollPeriod.csaIdentifier || bc.csaIdentifier == '-1');
                entries = PayrollEntry.decodeArray(bcData.entries, this.props.selectedPayPeriod.periodStart, this.props.selectedPayPeriod.periodEnd, hydrationData);
                this.setState({loadedVersion: {uid: bcData.uid, type: 'bcSubmission'}});
            } else if (!adminMode && response.latestVersion) { //CLIENT ONLY: Any BC Saved Version. Restricted BCs will only recieve a latest version if it is for their CSA. Nonrestricted BCs will only recieve a latest version if it is for all CSAs
                entries = PayrollEntry.decodeArray(response.latestVersion.entries, this.props.selectedPayPeriod.periodStart, this.props.selectedPayPeriod.periodEnd, hydrationData);
                this.setState({loadedVersion: {uid: response.latestVersion.uid, type: 'bcVersion'}});
            } else if (adminMode && response.bcVersions.length > 0) { //ADMIN ONLY: Unrestricted BC Saved Version
                entries = PayrollEntry.decodeArray(response.bcVersions[0].entries, this.props.selectedPayPeriod.periodStart, this.props.selectedPayPeriod.periodEnd, hydrationData);
                this.setState({loadedVersion: {uid: response.bcVersions[0].uid, type: 'bcVersion'}});
            } else { //GENERATE
                didGenerate = true;
                response.bcData.forEach((obj) => {
                    if (obj.entries) {
                        entries = entries.concat(PayrollEntry.decodeArray(obj.entries, this.props.selectedPayPeriod.periodStart, this.props.selectedPayPeriod.periodEnd, hydrationData));
                    }
                });

                response.bcVersions.forEach((obj) => { //only occurs on Client if restrictBcsToCsa is true
                    if (obj.entries) {
                        let version = PayrollEntry.decodeArray(obj.entries, this.props.selectedPayPeriod.periodStart, this.props.selectedPayPeriod.periodEnd, hydrationData).filter((v)=>!entries.map((e)=>e.companyUserUid).includes(v.companyUserUid));
                        entries = entries.concat(version);
                    }
                });
                
                //skip administrators and users which have been parsed from bcData and bcVersions
                response.userData.filter(u => u.isAdministrator == '0' && !entries.find(e => e.companyUserIdentifier == u.companyUserUid)).forEach((user) => {
                    const timesheetData = response.timesheetData.filter(r => r.companyUserIdentifier == user.companyUserUid);
                    
                    let userPayData = response.payData.filter(pay => pay.companyUserIdentifier == user.companyUserUid);
                    if (userPayData.length == 0 && timesheetData.length == 0) { //filter out employees who do not have a position during this pay period
                        return;
                    }
                    if (!userPayData.find(p => p.payType != 'termination') && timesheetData.length == 0) { //exclude terminated employees who have no time data
                        return;
                    }
                    userPayData = userPayData.filter(p => p.payType != 'termination'); //terminations must be filtered out before checking for AO title and before generating entries
                    if (response.includeAOsInPayroll != '1' && userPayData.find(p => p.title == 'AO')) { //exclude AOs
                        return;
                    }
                    if (user.onLoa == '1') {
                        return; //exlude employees on LOA
                    }

                    const ptoBalance = response.ptoBalanceData[user.companyUserUid.toString()];
                    const vehicleWeights = response.vehicleWeights.filter(r => r.companyUserIdentifier == user.companyUserUid);
                    const csa = response.csas.find(csa => csa.uid == user.csaIdentifier);
                    const previousEntry = previousPayrollPeriod?.find(r => r.companyUserIdentifier == user.companyUserUid);
                    const isNew = previousPayrollPeriod && !previousEntry;
                    const ptoData = response.pto.filter(si => si.companyUserIdentifier == user.companyUserUid);
                    const loanData = response.loanData.filter(l => l.companyUserIdentifier == user.companyUserUid);
                    const ticketData = response.ticketData.filter(t => t.companyUserIdentifier == user.companyUserUid);
                    
                    let settlementReportData = [];
                    response.settlementReportData.forEach((report) => {
                        settlementReportData.push({
                            ...report,
                            days: report.days.filter((r)=>r.companyUserIdentifier == user.companyUserUid)
                        })
                    });
                    
                    const entry = PayrollEntry.generate({
                        user: user,
                        timesheetData: timesheetData,
                        payData: userPayData,
                        vehicleWeights: vehicleWeights,
                        csaName: csa.csaName,
                        isNew: isNew,
                        ptoData: ptoData,
                        loanData: loanData,
                        ticketData:ticketData,
                        ptoBalance: ptoBalance,
                        previousEntry: previousEntry,
                        periodStart: this.props.selectedPayPeriod.periodStart,
                        periodEnd: this.props.selectedPayPeriod.periodEnd,
                        settlementReportData: settlementReportData,
                        autoIncentiveWageAmount: response.autoIncentiveWageAmount,
                        autoIncentiveWageThreshold: response.autoIncentiveWageThreshold,
                        customIncentiveWages: response.customIncentiveWages,
                        autoOvertimeThreshold: response.autoOvertimeThreshold, 
                        autoOvertimeAmount: response.autoOvertimeAmount
                    });
                    entry.wasGenerated = true;
                    entries.push(entry);
                });
            }
            
            entries.sort(this.sortEntries);
            if (entries.length > 0 && !entries[0].status) {
                entries[0].status = 'pending';
            }

            const newEmployeeFilter = 
                response.payrollPeriod.isCustom == '1' && didGenerate ? [] 
                : response.payrollPeriod.csaIdentifier && didGenerate ? entries.filter(e => e.csaName == response.payrollPeriod.csaName || !e.wasGenerated).map(e => e.companyUserIdentifier) 
                : response.restrictToCsa > 0 ? entries.filter(e => e.csaName == response.csas.find(c => c.uid == response.restrictToCsa).csaName).map(e => e.companyUserIdentifier)
                : entries.map(e => e.companyUserIdentifier)
            ;

            const notClockedOutUsers = [];
            response.timesheetData.forEach((timesheet) => {
                if (!timesheet.outTime && newEmployeeFilter.includes(timesheet.companyUserIdentifier)) {
                    const user = entries.find(e => e.companyUserIdentifier == timesheet.companyUserIdentifier);
                    if (user) {
                        notClockedOutUsers.push(user.firstName + ' ' + user.lastName);
                    }
                }
            });
            
            this.setState({
                entries: entries, 
                employeeFilter: newEmployeeFilter,
                selectedEntry: newEmployeeFilter.length > 0 ? newEmployeeFilter[0] : undefined, 
                timesheetData: response.timesheetData, 
                payData: response.payData,
                ptoData: response.pto,
                previousEntries: previousPayrollPeriod,
                payrollVersions: response.versions,
                bcData: response.bcData ?? [],
                bcVersions: response.bcVersions,
                csas: response.csas,
                modalSwitch: (response.payrollPeriod.isCustom == '1' && didGenerate) || entries.length == 0 ? 'selectEmployees' : !adminMode && entries.find((e)=>e.settlementReportStatus != 'complete') ? 'settlementModal' : '',
                userData: response.userData, 
                originalReviewPayrollPeriod: response, 
                isCustom: response.payrollPeriod.isCustom == '1',
                settlementReportData:response.settlementReportData
            }, () => {
                this.setState({notClockedOutUsers: notClockedOutUsers});
            });
        }

        this.setState({isLoading: false});
    }

    componentDidMount() {
        this.loadData();
        window.addEventListener("beforeunload", this.handleBeforeUnload);
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.handleBeforeUnload);
    }

    handleBeforeUnload(event) {
        event.preventDefault();
        return (event.returnValue = "");
    }
    

    handleSet(companyUserIdentifier, key, value) {
        const newEntries = Array.from(this.state.entries);

        const entry = newEntries.find(e => e.companyUserIdentifier == companyUserIdentifier);
        entry[key] = value;
        if(key != 'status'){
            entry.status = 'pending';
        }
        this.setState({entries: newEntries, isSaved: false, touched: key != 'weekIndex' ? true : false});
    }

    handleAddDBonusToAllEntries(dBonus) {
        const newEntries = Array.from(this.state.entries);
        newEntries.forEach(entry => {entry.dBonuses.push(dBonus.duplicate())})
        this.setState({entries: newEntries});
    }

    handleAddDeductionToAllEntries(deduction){
        const newEntries = Array.from(this.state.entries);
        newEntries.forEach(entry => {entry.deductions.push(deduction.duplicate())})
        this.setState({entries: newEntries});
    }

    handleAddNdToAllEntries(ndBonus, weekIndex){
        const entries = Array.from(this.state.entries);
        entries.forEach((entry)=>{
            entry.weeks[weekIndex].ndBonuses.push(ndBonus.duplicate());
        });
        this.setState({entries:entries});
    }

    handleAddAPToAllEntries(ap, weekIndex) {
        const entries = Array.from(this.state.entries);
        entries.forEach((entry)=>{
            entry.weeks[weekIndex].additionalPay.push(ap.duplicate());
        });
        this.setState({entries:entries});
    }

    handleSetTimesheetData(companyUserIdentifier, timesheetData) {
        let newArray = this.state.timesheetData.filter(t => t.companyUserIdentifier != companyUserIdentifier);
        newArray = newArray.concat(timesheetData);
        this.setState({timesheetData: newArray});
    }

    handleProceed() {
        const filteredEntries = this.state.entries.filter(e => this.state.employeeFilter.includes(e.companyUserIdentifier));

        let index = filteredEntries.map(e => e.companyUserIdentifier).indexOf(this.state.selectedEntry) + 1;
        
        if (index > 0 && index < filteredEntries.length) {
            const selectedEntryObj = filteredEntries[index];
            const companyUserIdentifier = selectedEntryObj.companyUserIdentifier;

            this.setState({selectedEntry: companyUserIdentifier});
            if (!selectedEntryObj.status && this.state.payData.filter(p => p.companyUserIdentifier == companyUserIdentifier).length > 1) {
                this.setState({modalSwitch: 'multiplePayAlert'});
            }
            if (!selectedEntryObj.status) {
                this.handleSet(companyUserIdentifier, 'status', 'pending');
            }
        }
    }

    handleSetEntry = (entry) => {
        const newArr = Array.from(this.state.entries);
        const index = newArr.findIndex((e)=> e.companyUserIdentifier == entry.companyUserIdentifier);
        if (index >= 0) {
            newArr[index] = entry;
            this.setState({entries: newArr});
        }
    }

    async handleSave() {
        this.setState({isSaving:true});
        const filteredEntries = this.state.entries.filter(e => this.state.employeeFilter.includes(e.companyUserIdentifier));
        let encodedEntries = PayrollEntry.encodeArray(filteredEntries);

        const payrollVersion = {
            payrollIdentifier: this.props.selectedPayPeriod.uid,
            entries: encodedEntries
        }

        try {
            let response;

            if (adminMode) {
                const gross = filteredEntries.reduce((prev, curr) => {
                    return prev.plus(curr.gross());
                }, new Big(0)).toFixed(2);
                response = await savePayrollVersionAdmin(payrollVersion, gross);
            } else {
                response = await savePayrollVersionClient(payrollVersion);
            }

            if (response.status == '200') {
                const version = {
                    uid: response.uid,
                    isTta: adminMode ? '1' : '0',
                    user: {
                        firstName: this.userData.firstName,
                        middleName: '',
                        lastName: this.userData.lastName
                    },
                    dateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
                    companyUserIdentifier: 1,
                }

                if (this.props.isBc) {
                    const newArr = Array.from(this.state.bcVersions);
                    newArr.unshift(version);
                    this.setState({isSaved: true, dateTimeSave: moment().format('YYYY-MM-DD HH:mm:ss'), bcVersions: newArr});
                } else {
                    const newArr = Array.from(this.state.payrollVersions);
                    newArr.unshift(version);
                    this.setState({isSaved: true, dateTimeSave: moment().format('YYYY-MM-DD HH:mm:ss'), payrollVersions: newArr});
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            this.setState({isSaving: false});
        }
        return false;   
    }

    handleSetEntries(entries, setFilterToEntries) {
        if (setFilterToEntries) {
            this.setState({employeeFilter: entries.map(e=>e.companyUserIdentifier)});
        }
        this.setState({entries:entries, selectedEntry: this.state.employeeFilter[0], modalSwitch: undefined});
    }


    handleAddEntries(entryArray){
        const newArr = Array.from(this.state.entries);
        entryArray.forEach((entry)=>{
            newArr.push(entry);
        });
        this.setState({entries: newArr});
    }

    async handleSubmit() {
        this.setState({isSubmitting: true});

        const filteredEntries = this.state.entries.filter(e => this.state.employeeFilter.includes(e.companyUserIdentifier));

        filteredEntries.forEach((entry)=>{
            entry.status = '';
        });

        const payrollPeriod = {
            uid: this.props.bcPeriod ? this.props.bcPeriod.uid : this.props.selectedPayPeriod.uid,
            entries: PayrollEntry.encodeArray(filteredEntries),
            gross: filteredEntries.reduce((prev, curr) => {
                const prevBig = new Big(prev);
                const currBig = new Big(curr.gross());
                return prevBig.plus(currBig);
            }, new Big(0)).toFixed(2),
        }

        try {
            let response;
            if (adminMode) {
                const pto = filteredEntries.reduce((prev, curr) => {
                    const ptoFromWeeks = curr.weeks.reduce((prevWeek, currWeek) => {
                        return prevWeek.concat(currWeek.ptoArray.filter(p => p.isEnabled).map((i) => {
                            return {...i.encode(), companyUserIdentifier: curr.companyUserIdentifier}
                        }));
                    }, [])
                    return prev.concat(ptoFromWeeks);
                }, [])
                const ptoAccrual = [];
                filteredEntries.forEach((e) => {
                    const hours = e.getPtoHoursAccrued();
                    if (hours > 0) {
                        ptoAccrual.push({
                            companyUserIdentifier: e.companyUserIdentifier,
                            hours: hours
                        });
                    }
                })

                const loans = filteredEntries.reduce((prev, curr) => {
                    return prev.concat(curr.loans.filter(l => l.isEnabled).map(l => l.encode()));
                }, []);
                const tickets = filteredEntries.reduce((prev, curr) => {
                    const entryTickets = curr.tickets.filter(t => t.isEnabled && t.getAmountToPay(curr) > 0).map((t) => {
                        return {
                            ...t,
                            amount: t.getAmountToPay(curr)
                        }
                    })
                    return prev.concat(entryTickets);
                }, []);

                response = await approvePayrollPeriodAdmin(payrollPeriod, pto, ptoAccrual, loans, tickets);
            } else {
                response = await approvePayrollPeriodClient(payrollPeriod);
            }
            if (response.status == '200') {
                this.handleHideEditor();
            }
        } catch (e) {
            console.log(e);
        } finally {
            this.setState({isSubmitting: false});
        }
    }

    handleHideEditor = () =>{
        this.setState({modalSwitch:''});
        this.props.hideModal();
        this.props.loadData();
    }

    async handleDeleteCustomPayroll(){
        const response = await deletePayroll(this.props.selectedPayPeriod.uid);
        if(response && response.status == '200'){
            this.setState({modalSwitch:''});
            this.props.handleDeletingCustomPeriod(this.props.selectedPayPeriod.uid);
            this.props.hideModal()
        } 
    }

    render() {
        const filteredEntries = this.state.entries.filter(e => this.state.employeeFilter.includes(e.companyUserIdentifier));
    
        const csas = []
        this.state.entries.forEach((e) => {
            if (!csas.includes(e.csaName)) {
                csas.push(e.csaName);
            }
        })
        
        const popover = (
            <Popover style={{position:'fixed'}}>
                <Popover.Header></Popover.Header>
                <Popover.Body>
                    <span>This employee has settlement report data used to calculate their stop wages and package deliveries during this pay period</span>
                </Popover.Body>
            </Popover>
        );
        
        const userList = csas.filter((csa)=>filteredEntries.find((e)=>e.csaName == csa)).map((csaName) => {
            const oldUsersInCsa = filteredEntries.filter(e => !e.isNew && e.csaName == csaName).map((entry) => {
                const isSelected = entry.companyUserIdentifier == this.state.selectedEntry;
                const userData = this.state.userData.find((u)=>u.companyUserUid == entry.companyUserIdentifier);

                return (
                    <ListGroup.Item key={entry.companyUserIdentifier} className='cursor-pointer' style={{backgroundColor: isSelected ? 'var(--bs-primary)' : 'white'}} onClick={() => {
                            this.setState({selectedEntry: entry.companyUserIdentifier});
                            if (!entry.status) {
                                this.handleSet(entry.companyUserIdentifier, 'status', 'pending');
                                if (this.state.payData.filter(r => r.companyUserIdentifier == entry.companyUserIdentifier).length > 1) {
                                    this.setState({modalSwitch: 'multiplePayAlert'});
                                }
                            }
                        }}
                        >
                        <div key={entry.companyUserIdentifier} style={{display: 'flex', justifyContent: 'space-between', minWidth: 300, alignItems:'center'}}>
                            <div style={{display:'flex', gap:10, alignItems:'center'}}>
                                <span style={isSelected ? {color: 'white'} : {}}>
                                    {entry.lastName + ', ' + entry.firstName + ' ' + entry.middleName + (userData.onLoa == '1' ? ' (L.o.A)' : '')}
                                </span>
                                {(entry.settlementReportStatus == 'partial' || entry.settlementReportStatus == 'complete') && 
                                 <OverlayTrigger placement='top' overlay={popover}>
                                    <FontAwesomeIcon icon={faClipboardCheck} style={{color: isSelected ? 'white' :'var(--bs-primary)'}}/>
                                </OverlayTrigger>
                                }
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
                                <span style={{color: isSelected ? 'white' : 'black', opacity: 0.5}}>{bigToUsd(entry.gross())}</span>
                                { entry.status &&
                                    <FontAwesomeIcon icon={entry.status == 'approved' ? faCheck : faEllipsis} style={{color: entry.status == 'approved' ? 'green' : 'gold'}}/>
                                }
                            </div>
                        </div>
                    </ListGroup.Item>
                )
            })

            const newUsersInCsa = filteredEntries.filter(e => e.isNew && e.csaName == csaName).map((entry) => {
                const isSelected = entry.companyUserIdentifier == this.state.selectedEntry;
                const userData = this.state.userData.find((u)=>u.companyUserUid == entry.companyUserIdentifier);
                return (
                    <ListGroup.Item key={entry.companyUserIdentifier} className='cursor-pointer' style={{backgroundColor: isSelected ? 'var(--bs-primary)' : 'white'}} onClick={() => {
                            this.setState({selectedEntry: entry.companyUserIdentifier});
                            if (!entry.status) {
                                this.handleSet(entry.companyUserIdentifier, 'status', 'pending');
                                if (this.state.payData.filter(r => r.companyUserIdentifier == entry.companyUserIdentifier).length > 1) {
                                    this.setState({modalSwitch: 'multiplePayAlert'});
                                }
                            }
                        }}
                        >
                        <div key={entry.companyUserIdentifier} style={{display: 'flex', justifyContent: 'space-between', minWidth: 300}}>
                            <span style={isSelected ? {color: 'white'} : {}}>
                                {entry.lastName + ', ' + entry.firstName + ' ' + entry.middleName + (userData.onLoa == '1' ? ' (L.o.A)' : ' (NEW)')}
                            </span>
                            {(entry.settlementReportStatus == 'partial' || entry.settlementReportStatus == 'complete') && 
                                 <OverlayTrigger placement='top' overlay={popover}>
                                    <FontAwesomeIcon icon={faClipboardCheck} style={{color: isSelected ? 'white' :'var(--bs-primary)'}}/>
                                </OverlayTrigger>
                            }
                            <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
                                <span style={{color: isSelected ? 'white' : 'black', opacity: 0.5}}>{bigToUsd(entry.gross())}</span>
                                { entry.status &&
                                    <FontAwesomeIcon icon={entry.status == 'approved' ? faCheck : faEllipsis} style={{color: entry.status == 'approved' ? 'green' : 'gold'}}/>
                                }
                            </div>
                        </div>
                    </ListGroup.Item>
                )
            })

            return (
                <div key={csaName} style={{display: 'flex', flexDirection: 'column', gap: 8, marginBottom: 8, alignItems: 'center'}}>
                    <span style={{fontWeight: 'bold'}}>{csaName}</span>
                    <ListGroup>
                        {oldUsersInCsa}
                    </ListGroup>
                    <ListGroup>
                        {newUsersInCsa}
                    </ListGroup>
                </div>
            )
        })
                
        const selectedEntry = this.state.entries.find(e => e.companyUserIdentifier == this.state.selectedEntry);

        const content = (
            <div style={{display: 'flex', flex: 1}}>
                <Card style={{display: 'flex', margin: 8}}>
                    <Card.Body style={{height: '100%'}}>
                        <div style={{overflowY: 'scroll', height: '100%'}}>
                            {userList}
                        </div>
                    </Card.Body>
                </Card>
                
                { selectedEntry &&
                    <PayrollEmployeeEditor
                        key={this.state.selectedEntry}
                        periodStart={this.props.selectedPayPeriod.periodStart} 
                        periodEnd={this.props.selectedPayPeriod.periodEnd}
                        timesheetData={this.state.timesheetData.filter(r => r.companyUserIdentifier == selectedEntry.companyUserIdentifier)}
                        payData={this.state.payData.filter(r => r.companyUserIdentifier == selectedEntry.companyUserIdentifier)} 
                        ptoData={this.state.ptoData.filter(r => r.companyUserIdentifier == selectedEntry.companyUserIdentifier)}
                        entry={selectedEntry} 
                        previousEntry={this.state.previousEntries?.find(r => r.companyUserIdentifier == selectedEntry.companyUserIdentifier)}
                        handleSet={this.handleSet} 
                        handleProceed={this.handleProceed} 
                        handleAddDBonusToAllEntries={this.handleAddDBonusToAllEntries}
                        handleAddDeductionToAllEntries={this.handleAddDeductionToAllEntries}
                        handleAddNdToAllEntries={this.handleAddNdToAllEntries} 
                        handleAddAPToAllEntries={this.handleAddAPToAllEntries}
                        displayPtoInDays={this.state.displayPtoInDays}
                        setDisplayPtoInDays={(value) => {this.setState({displayPtoInDays: value})}}
                        handleSetEntry={this.handleSetEntry}
                        handleSetTimesheetData={this.handleSetTimesheetData}
                        settlementReportData={this.state.settlementReportData}
                        selectedPayPeriod={this.props.selectedPayPeriod}
                    />
                }
            </div>
            
        );

        const allApproved = filteredEntries.reduce((prev, curr) => {
            return prev && curr.status == 'approved';
        }, true);

        const confirmationCode = 'delete custom payroll';

        const letterElements = confirmationCode.split('').map((char, index) => {
            return (
                <p key={index} style={{display: 'inline', marginBottom: 0, color: (this.state.deleteConfirmation.length >= (index + 1) && this.state.deleteConfirmation.charAt(index) == char) ? 'green' : 'red'}}>{char}</p>
            )
        });
       
        return (
            <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                <Modal.Header style={{gap:30, alignItems:'center'}}>
                    <Modal.Title>{moment(this.props.selectedPayPeriod.periodStart).format('MMM D, YYYY') + ' - ' + moment(this.props.selectedPayPeriod.periodEnd).format('MMM D, YYYY') + ' Payroll Period'}</Modal.Title>
                    <CustomButton label='Save Changes' disabled={this.state.employeeFilter.length == 0} isLoading={this.state.isSaving} onClick={() => {this.handleSave()}}/>
                    {!this.state.touched ? <span></span> : this.state.isSaving ? <span style={{fontStyle:'italic', opacity:0.5}}>Saving....</span> : this.state.isSaved ? <span style={{fontStyle:'italic', opacity:0.5}}>Saved</span> : this.state.dateTimeSave ? <PayrollEditorSavedTime timeStamp={this.state.dateTimeSave}/> : <span style={{fontStyle:'italic', opacity:0.5}}>You have unsaved changes</span>}
                    { adminMode && this.state.isCustom && 
                        <Button style={{background:'none', color:'red', boxShadow:'none', border:'none'}} onClick={()=>this.setState({modalSwitch:'deleteCustom'})}>Delete Custom Payroll?</Button>
                    }
                    <CloseButton onClick={()=>this.setState({modalSwitch: 'exit'})}/>
                </Modal.Header>
                <Modal.Body style={{display: 'flex', flex: 1, padding: 0, backgroundColor: 'rgb(245, 245, 245)'}}>
                    {this.state.isLoading ? 
                        <div style={{flex: 1}}>
                            <CustomSpinner height={'100%'} spinnerHeight={100} spinnerWidth={100}/> 
                        </div>
                        : 
                        content
                    }
                </Modal.Body>
                <Modal.Footer>
                    <div style={{flex: 1}}/>
                    <ButtonGroup>
                        { adminMode && parseInt(this.props.selectedPayPeriod.status) > 1 &&
                            <Button variant={'outline-primary'} onClick={()=>this.setState({modalSwitch:'sendBack'})}>Send Payroll Back To IC</Button>
                        }
                        <Button variant={'outline-primary'} onClick={()=>this.setState({modalSwitch: 'settlementModal'})}>{adminMode ? 'View Settlement Data' :'Import Settlement Data'}</Button>
                        <Button variant={'outline-primary'} onClick={()=>this.setState({modalSwitch: 'selectVersion'})}>Select Payroll Version</Button>
                        <Button variant={'outline-primary'} onClick={() => {this.setState({modalSwitch: 'selectEmployees'})}}>Change Selected Employees</Button>
                        <Button variant={'outline-primary'} onClick={() => {this.setState({modalSwitch: 'previewSpreadsheet'})}}>Preview Payroll Spreadsheet</Button>
                    </ButtonGroup>
                    <div style={{flex: 0.5}}/>
                    <Button variant={'outline-primary'} disabled={this.state.isLoading || !allApproved || this.state.employeeFilter.length == 0} onClick={() => {this.setState({modalSwitch: 'approve'})}}>Submit Payroll</Button>
                    { !allApproved &&
                        <OverlayTrigger overlay={
                            <Popover>
                                <Popover.Body>You must approve the payroll entries for all employees before submitting</Popover.Body>
                            </Popover>
                        }>
                            <FontAwesomeIcon icon={faQuestionCircle} style={{color: 'var(--bs-primary)'}}/>
                        </OverlayTrigger>
                    }
                </Modal.Footer>
                <Modal show={this.state.modalSwitch == 'approve'} onHide={() => {this.setState({modalSwitch: 'none'})}} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{adminMode ? 'Approve payroll period on behalf of TTA?' : 'Approve payroll on behalf of your company?'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button variant={'outline-primary'} onClick={() => {(this.setState({modalSwitch: 'previewSpreadsheet'}))}}>Preview Payroll Spreadsheet</Button>
                        <CustomButton label='Submit' onClick={this.handleSubmit} isLoading={this.state.isSubmitting}/>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.modalSwitch == 'download'} backdrop='static' centered>
                    <PayrollCSVDownloadModal 
                        entries={filteredEntries} 
                        callBack={() => {this.setState({modalSwitch: 'none'})}}
                        periodStart={this.props.selectedPayPeriod.periodStart} 
                        periodEnd={this.props.selectedPayPeriod.periodEnd} 
                        companyName={this.props.companyName}
                    />
                </Modal>
                <Modal show={this.state.modalSwitch == 'selectEmployees'} onHide={() => {this.setState({modalSwitch: ''})}} size='lg'>
                    <PayrollEmployeeSelector 
                        periodStart={this.props.selectedPayPeriod.periodStart} 
                        periodEnd={this.props.selectedPayPeriod.periodEnd} 
                        originalPreviousPeriodArray={this.state.previousEntries} 
                        handleAddEntries={this.handleAddEntries} 
                        originalReviewPayrollPeriod={this.state.originalReviewPayrollPeriod} 
                        entries={this.state.entries} 
                        employeeFilter={this.state.employeeFilter} 
                        setEmployeeFilter={(value) => {this.setState({employeeFilter: value})}} 
                        hideModal={() => {this.setState({modalSwitch: 'none'})}}
                    />
                </Modal>
                <Modal show={this.state.modalSwitch == 'previewSpreadsheet'} fullscreen onHide={() => {this.setState({modalSwitch: ''})}}>
                    <PayrollPeriodPreview 
                        entries={filteredEntries} 
                        periodStart={this.props.selectedPayPeriod.periodStart} 
                        periodEnd={this.props.selectedPayPeriod.periodEnd}
                        companyName={this.props.companyName}
                    />
                </Modal>
                <Modal show={this.state.notClockedOutUsers.length > 0} centered backdrop='static'>
                    <ClockOutCheck goBack={() => {this.props.hideModal(); this.setState({notClockedOutUsers: [], modalSwitch: ''})}} continueAnyway={() => {this.setState({notClockedOutUsers: []})}} notClockedOutUsers={this.state.notClockedOutUsers}/>
                </Modal>
                <Modal centered show={this.state.modalSwitch == 'exit'} onHide={()=>this.setState({modalSwitch: undefined})}>
                    <Modal.Header>
                        <Modal.Title>Are you sure you want to exit?</Modal.Title>
                    </Modal.Header>
                    { this.props.selectedPayPeriod.status == '1' &&
                        <Modal.Body>
                            <span>Saving payroll on behalf of TTA will prevent the client from making any further changes.</span>
                        </Modal.Body>
                    }
                    <Modal.Footer>
                        <CustomButton label='Save & Exit' onClick={()=>{this.handleSave();this.props.hideModal()}}/>
                        <CustomButton label='Exit Without Saving' onClick={()=>{this.props.loadData();this.props.hideModal()}}/>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.modalSwitch == 'selectVersion' || this.state.modalSwitch == 'sendBack'} onHide={()=>this.setState({modalSwitch: undefined})}>
                    <PayrollVersionList 
                        loadedVersion={this.state.loadedVersion} 
                        csas={this.state.csas} 
                        payrollVersions={this.state.payrollVersions} 
                        bcVersions={this.state.bcVersions} 
                        bcData={this.state.bcData} 
                        handleSetEntries={this.handleSetEntries} 
                        payrollPeriod={this.props.selectedPayPeriod} 
                        handleHideEditor={this.handleHideEditor}
                        isSendingBack={this.state.modalSwitch == 'sendBack'}
                    />
                </Modal>

                { !adminMode &&
                    <Modal show={this.state.modalSwitch == 'sentBack'} onHide={()=>this.setState({modalSwitch:''})}>
                        <Modal.Header closeButton>
                            <Modal.Title>Payroll Sent Back From TTA</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>This Payroll Period has been sent back from TTA for your revision.</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant={'outline-primary'} onClick={()=>this.setState({modalSwitch:''})}>Okay</Button>
                        </Modal.Footer>
                    </Modal>
                }
                {/* { adminMode &&
                    <Modal show={this.state.modalSwitch == 'sendBack'} onHide={()=>{this.setState({modalSwitch:''})}}>
                        <SendPayrollBackToIC 
                            payPeriod={this.props.selectedPayPeriod} 
                            payrollVersions={this.state.payrollVersions} 
                            hideEditor={this.handleHideEditor} 
                            loadData={this.props.loadData}
                        />
                    </Modal>
                } */}
                { adminMode &&
                    <Modal show={this.state.modalSwitch == 'deleteCustom'} onHide={()=>this.setState({modalSwitch:''})}>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Custom Payroll?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Once deleted all data from this custom payroll will no longer be accesible. Please be sure you want to delete before continuing.</p>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 12}}>
                            <Form.Group>
                                <Form.Label>Type "{letterElements}" to confirm deletion</Form.Label>
                                <Form.Control isValid={this.state.deleteConfirmation == confirmationCode} isInvalid={!confirmationCode.includes(this.state.deleteConfirmation)} value={this.state.deleteConfirmation} onChange={(event) => {this.setState({deleteConfirmation:event.target.value})}} type='text' placeholder='delete custom payroll'/>
                            </Form.Group>
                            <CustomButton label='Confirm Deletion'  disabled={this.state.deleteConfirmation != confirmationCode} onClick={()=>this.handleDeleteCustomPayroll()}/>
                        </div>
                        </Modal.Body>
                    </Modal>
                }
                <Modal size='lg' show={this.state.modalSwitch == 'multiplePayAlert'} onHide={() => {this.setState({modalSwitch: ''})}}>
                    <MultiplePayAlert name={selectedEntry?.name() ?? ""} payData={this.state.payData.filter(r => r.companyUserIdentifier == selectedEntry?.companyUserIdentifier)}/>
                </Modal>
                <Modal show={this.state.modalSwitch == 'settlementModal'} onHide={()=>{this.setState({modalSwitch:''})}} size='lg'>
                    <PayrollSettlementReportModal hideModal={()=>this.setState({modalSwitch:''})} payData={this.state.payData} handleSetEntry={this.handleSetEntry} entries={this.state.entries} employeeFilter={this.state.employeeFilter} csas={this.state.csas} userData={this.state.userData} settlementReportData={this.state.settlementReportData} handleUpdateUserFedexIds={this.handleUpdateUserFedexIds} payrollPeriod={this.props.selectedPayPeriod} handleSetSettlementReportData={this.handleSetSettlementReportData}/>
                </Modal>
            </div>
        );
    }
}