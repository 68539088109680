/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../decoding";
import { validateDecimal } from "../payrollTools";

export default class Loan {
    uid;
    name;
    amount;
    isEnabled;

    constructor(uid, amount, name, isEnabled) {
        this.uid = uid;
        this.name = name;
        this.amount = amount
        this.isEnabled = isEnabled;   
    }

    static initDefault() {
        return new Loan(-1, 0.0, -1, '', true);
    }

    static decode(json) {
        const decoder = new Decoder(json);
        const uid = decoder.decode('uid', Decoder.Uid);
        const name = decoder.decode('name', Decoder.String);
        const amount = decoder.decode('amount', Decoder.Decimal);
        const isEnabled = decoder.decode('isEnabled', Decoder.Boolean, {defaultValue: true, warn: false});
        if (decoder.checkForErrors()) {
            return new Loan(uid, amount, name, isEnabled);
        } else {
            return Loan.initDefault();
        }
    }

    encode() {
        return {
            uid: this.uid,
            name: this.name,
            amount: validateDecimal(this.amount),
            isEnabled: this.isEnabled
        }
    }

    duplicate() {
        return new Loan(this.uid, this.amount, this.name, this.isEnabled);
    }

    getAmount() {
        return validateDecimal(this.amount);
    }

}