/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useEffect, useState } from "react"
import { Dropdown, Form, Modal } from "react-bootstrap";
import CustomControl from "../../components/CustomControl";
import CustomButton from "../../components/CustomButton";
import { getCompanies, getCompanyUsers } from "../../services/CompanyServices/CompaniesService";
import QuickTable from "../../components/QuickTable";
import { getNotificationRecipients, sendText } from "../../services/NotificationService";
import { AppGlobals } from "../../App";
import CustomAlert from "../../components/CustomAlert";
import SearchBar from "../../components/SearchBar";
import PageSpinner from "../../components/PageSpinner";

export default function SendCustomTextModal({}){

    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [csas, setCsas] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [usersToText, setUsersToText] = useState([]);

    const [companySearch, setCompanySearch] = useState('');
    const [userSearch, setUserSearch] = useState('');

    const [showConfirmModal, setShowConfirmModal] = useState(false)

    useEffect(()=>{
        loadData();
    }, [])

    async function loadData(){
        setIsLoading(true);
        const response = await getNotificationRecipients();
        if(response.status === '200'){
            setCompanies(response.companies);
            setCsas(response.csas);
            setAllUsers(response.users);
        }else{
            AppGlobals.alert('danger', response.message)
        }
        setIsLoading(false)
    }

    async function handleSendText(){
        setIsSubmitting(true);
        const response = await sendText(usersToText, message);
        if(response.status === '200'){
            AppGlobals.alert('success', response.message)
        }else{
            AppGlobals.alert('danger', response.message)
        }
        setIsSubmitting(false);
    }

    function handleSelectCompany(company){
        const newUsers = allUsers.filter(user=>user.companyIdentifier === company.uid)
        setUsers(newUsers);
        setSelectedCompany(company)
    }

    function handleSelectUser(userIdentifier){
        let newUsersToText = Array.from(usersToText);

        if(newUsersToText.includes(userIdentifier)){
            newUsersToText = newUsersToText.filter(uid=> uid !== userIdentifier);
        }else{
            newUsersToText.push(userIdentifier);
        }
        setUsersToText(newUsersToText);
    }


    function handleSelectMultiple(type, csaIdentifier = -1){
        let newUsersToText = Array.from(usersToText);

        switch(type){
            case "selectAll":
                setUsersToText([...new Set(allUsers.map(user=>user.uid))]);
                break;
            case "selectAllManagement":
                setUsersToText([...new Set([...newUsersToText, ...allUsers.filter(user=>(['AO', 'BC'].includes(user.title) && !usersToText.includes(user.uid))).map(user=>user.uid)])]);
                break;
            case "selectAllDrivers":
                setUsersToText([...new Set([...newUsersToText, ...allUsers.filter(user=>(!['AO', 'BC'].includes(user.title) && !usersToText.includes(user.uid))).map(user=>user.uid)])]);
                break;
            case 'deselectAllManagement':
                setUsersToText((newUsersToText.filter(uid=> !allUsers.filter(user=>(['AO', 'BC'].includes(user.title))).map(user=>user.uid).includes(uid))));
                break;
            case 'deselectAllDrivers':
                setUsersToText((newUsersToText.filter(uid=> !allUsers.filter(user=>(!['AO', 'BC'].includes(user.title))).map(user=>user.uid).includes(uid))));
                break;
            case 'deselectAll':
                setUsersToText([]);
                break;
            case 'selectAllCompany':
                setUsersToText([...newUsersToText, ...users.filter(user=> !usersToText.includes(user.uid)).map(user=>user.uid)]);
                break;
            case 'selectCompanyManagement':
                setUsersToText([...newUsersToText, ...users.filter(user=>(['AO', 'BC'].includes(user.title) && !usersToText.includes(user.uid))).map(user=>user.uid)]);
                break;
            case 'selectCompanyDrivers':
                setUsersToText([...newUsersToText, ...users.filter(user=>(!['AO', 'BC'].includes(user.title) && !usersToText.includes(user.uid))).map(user=>user.uid)]);
                break;
            case 'deselectAllCompany':
                setUsersToText((newUsersToText.filter(uid=> !users.map(user=>user.uid).includes(uid))));
                break;
            case 'deselectCompanyManagement':
                setUsersToText(newUsersToText.filter(uid=> !users.filter(user=>(['AO', 'BC'].includes(user.title))).map(user=>user.uid).includes(uid)));
                break;
            case 'deselectCompanyDrivers':
                setUsersToText(newUsersToText.filter(uid=> !users.filter(user=>(!['AO', 'BC'].includes(user.title))).map(user=>user.uid).includes(uid)));
                break;
            case 'selectAllCsa':
                setUsersToText([...newUsersToText, ...users.filter(user=>(user.csaIdentifier == csaIdentifier && !usersToText.includes(user.uid))).map(user=>user.uid)]);
                break;
            case 'deselectAllCsa':
                setUsersToText((newUsersToText.filter(uid=> !users.filter(user=>(user.csaIdentifier == csaIdentifier)).map(user=>user.uid).includes(uid))));
                break;
            default:
                break;
        }
    }

    const companyRows = companies.filter((company)=>{
        if(companySearch){
            return company.companyName.toLowerCase().includes(companySearch.toLowerCase())
        }
        return true;
    }).map((company) => {
        return (
            <tr key={company.uid} className='cursor-pointer' onClick={() => {handleSelectCompany(company)}} style={selectedCompany?.uid === company.uid ? {backgroundColor: 'yellow'} : {}}>
                <td>{company.uid}</td>
                <td>{company.companyName}</td>
            </tr>
        )
    });

    const userRows = users.filter((user)=>{
        if(userSearch){
            const fullName = `${user.firstName} ${user.lastName}`
            const fullNameReverse = `${user.lastName} ${user.firstName}`
            return (fullName.toLowerCase().includes(userSearch.toLowerCase()) || fullNameReverse.toLowerCase().includes(userSearch.toLowerCase()))
        }
        return true;
    }).map((user) => {
        return (
            <tr key={user.uid} style={{backgroundColor: usersToText.includes(user.uid) ? 'yellow' : undefined}} className='cursor-pointer' onClick={() => {handleSelectUser(user.uid)}}>
                <td>{user.uid}</td>
                <td>{user.companyUserUid}</td>
                <td>{user.lastName}</td>
                <td>{user.firstName}</td>
                <td>{user.title}</td>
                <td>{csas.find(csa=>csa.uid === user.csaIdentifier)?.csaName}</td>
            </tr>
        )
    });

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Send Custom Text</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {isLoading ? <PageSpinner/> :
                <>
                    <div style={{display: 'flex', gap: 24}}>
                        <div style={{flex: 1}}>
                            <Dropdown style={{marginBottom: 12}}>
                                <Dropdown.Toggle>
                                    Select In All Companies
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Header>Select</Dropdown.Header>
                                    <Dropdown.Item onClick={()=>handleSelectMultiple('selectAll')}>Select All Users</Dropdown.Item>
                                    <Dropdown.Item onClick={()=>handleSelectMultiple('selectAllManagement')}>Select All Management</Dropdown.Item>
                                    <Dropdown.Item onClick={()=>handleSelectMultiple('selectAllDrivers')}>Select All Drivers</Dropdown.Item>
                                    <Dropdown.Divider/>
                                    <Dropdown.Header>Deselect</Dropdown.Header>
                                    <Dropdown.Item onClick={()=>handleSelectMultiple('deselectAll')}>Deselect All Users</Dropdown.Item>
                                    <Dropdown.Item onClick={()=>handleSelectMultiple('deselectAllManagement')}>Deselect All Management</Dropdown.Item>
                                    <Dropdown.Item onClick={()=>handleSelectMultiple('deselectAllDrivers')}>Deselect All Drivers</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <SearchBar label='Search by Company' value={companySearch} setValue={setCompanySearch}/>
                            <QuickTable title='Companies' headers={['UID', 'Company Name']} rows={companyRows} hover/>
                        </div>
                        { selectedCompany &&
                            <div style={{flex: 3}}>
                                <div style={{display: 'flex', gap: 12, justifyContent: 'end'}}>
                                    <Dropdown style={{marginBottom: 12}}>
                                        <Dropdown.Toggle>
                                            Select In Company
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Header>Select</Dropdown.Header>
                                            <Dropdown.Item onClick={()=>handleSelectMultiple('selectAllCompany')}>Select Company Users </Dropdown.Item>
                                            <Dropdown.Item onClick={()=>handleSelectMultiple('selectCompanyManagement')}>Select Company Management</Dropdown.Item>
                                            <Dropdown.Item onClick={()=>handleSelectMultiple('selectCompanyDrivers')}>Select Company Drivers</Dropdown.Item>
                                            <Dropdown.Divider/>
                                            <Dropdown.Header>Deselect</Dropdown.Header>
                                            <Dropdown.Item onClick={()=>handleSelectMultiple('deselectAllCompany')}>Deselect Company Users</Dropdown.Item>
                                            <Dropdown.Item onClick={()=>handleSelectMultiple('deselectCompanyManagement')}>Deselect Comapany Management</Dropdown.Item>
                                            <Dropdown.Item onClick={()=>handleSelectMultiple('deselectCompanyDrivers')}>Deselect Company Drivers</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Dropdown style={{marginBottom: 12}}>
                                        <Dropdown.Toggle>
                                            Select In CSA
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Header>Select</Dropdown.Header>
                                            {csas.filter(csa=> users.map(user=>user.csaIdentifier).includes(csa.uid)).map(csa=>{
                                                return <Dropdown.Item key={csa.uid} onClick={()=>handleSelectMultiple('selectAllCsa', csa.uid)}>{csa.csaName}</Dropdown.Item>
                                            })}
                                            <Dropdown.Divider/>
                                            <Dropdown.Header>Deselect</Dropdown.Header>
                                            {csas.filter(csa=> users.map(user=>user.csaIdentifier).includes(csa.uid)).map(csa=>{
                                                return <Dropdown.Item key={csa.uid} onClick={()=>handleSelectMultiple('deselectAllCsa', csa.uid)}>{csa.csaName}</Dropdown.Item>
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <SearchBar label='Search by Full Name' value={userSearch} setValue={setUserSearch}/>
                                <QuickTable title='Users' headers={['User ID', 'CompanyUser ID', 'Last Name', 'First Name', 'Title', 'CSA']} rows={userRows} hover/>
                            </div>
                        }
                    </div>
                    <p style={{margin: '8px 0'}}><strong>Total Recipients:</strong> {usersToText.length}</p>
                    <Form.Control placeholder="Message" rows={4} as='textarea' value={message} onChange={(e)=>setMessage(e.target.value)}/>
                </>
            }
            </Modal.Body>
            <Modal.Footer>
                <CustomButton isLoading={isLoading} disabled={!message || !usersToText.length} onClick={()=>setShowConfirmModal(true)}  label='Send'/>
            </Modal.Footer>
            <Modal show={showConfirmModal} onHide={()=>setShowConfirmModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Send Text</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{message}</p>
                    <p><strong>Total Recipients: </strong>{usersToText.length}</p>
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton isLoading={isSubmitting} onClick={handleSendText} label={'Send'}/>
                </Modal.Footer>
            </Modal>
        </>
    )
}