/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from "./serviceTools";

export async function createNotification(notification) {
    var data = {
        type: 'notifications',
        target: 'createNotification',
        authToken: getToken(),
        notification:notification

    }
    const response = await getResponse(data);
    return response;

}

export async function sendText(userIdentifiers, message){
    var data = {
        type: 'notifications',
        target: 'sendText',
        authToken: getToken(),
        userIdentifiers,
        message

    }
    return await getResponse(data);
}

export async function getNotificationRecipients(){
    var data = {
        type: 'notifications',
        target: 'getNotificationRecipients',
        authToken: getToken(),
    }

    return await getResponse(data);
}

export async function getAllNotifications(){
    var data = {
        type: 'notifications',
        target: 'getAllNotifications',
        authToken: getToken()
    }
    const response = await getResponse(data);
    return response;
}

export async function getNotification(uid){
    var data = {
        type: 'notifications',
        target: 'getNotification',
        authToken: getToken(),
        uid:uid
    }
    const response = await getResponse(data);
    return response;
}

export async function updateNotification(notification) {
    var data = {
        type: 'notifications',
        target: 'updateNotification',
        authToken: getToken(),
        notification:notification

    }
    const response = await getResponse(data);
    return response;

}

export async function deleteNotification(uid){
    var data = {
        type: 'notifications',
        target: 'deleteNotification',
        authToken: getToken(),
        uid:uid
    }
    const response = await getResponse(data);
    return response;
}

export async function disableNotification(uid){
    var data = {
        type: 'notifications',
        target: 'disableNotification',
        authToken: getToken(),
        uid: uid
    }
    const response = await getResponse(data);
    return response;
}

export async function sendNotification(data, resend) {
    var data = {
        type:'notifications',
        target:'sendNotification',
        authToken: getToken(),
        data: data,
        resend: resend
    }
    const response = await getResponse(data);
    return response;
}