/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { validateInteger } from "../tools";
import moment from "moment";
import React from "react";
import { Form } from "react-bootstrap";

export default function DateTimePicker({value, setValue, title, optional, defaultDate, type = 'date'}) { //type [date, time, timePrecise, dateTime, dateTimePrecise]
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const componentTypes = ['day', 'month', 'year', 'hour', 'minute', 'second'];

    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [hour, setHour] = useState('');
    const [minute, setMinute] = useState('');
    const [second, setSecond] = useState('');
    const [isPm, setIsPm] = useState(false);

    const getIndicesForComponent = (componentType) => {
        if (componentType == 'day') {
            if (['date', 'dateTime', 'dateTimePrecise'].includes(type)) {
                return [8, 10];
            }
        } else if (componentType == 'month') {
            if (['date', 'dateTime', 'dateTimePrecise'].includes(type)) {
                return [5, 7];
            }
        } else if (componentType == 'year') {
            if (['date', 'dateTime', 'dateTimePrecise'].includes(type)) {
                return [0, 4];
            }
        } else if (componentType == 'hour') {
            if (['dateTime', 'dateTimePrecise'].includes(type)) {
                return [11, 13];
            } else if (['time', 'timePrecise'].includes(type)) {
                return [0, 2];
            }
        } else if (componentType == 'minute') {
            if (['dateTime', 'dateTimePrecise'].includes(type)) {
                return [14, 16];
            } else if (['time', 'timePrecise'].includes(type)) {
                return [3, 5];
            }
        } else if (componentType == 'second') {
            if (type == 'dateTimePrecise') {
                return [17, 19];
            } else if (type == 'timePrecise') {
                return [6, 8];
            }
        }
    }

    const handleGetDisplayValue = (componentType) => {
        if (!value) {
            return '';
        }
        switch (componentType) {
            case 'day':
                return moment(value).format('DD');
            case 'month':
                return moment(value).format('MMM');
            case 'year':
                return moment(value).format('YYYY');
            default:
                const indices = getIndicesForComponent(componentType == 'isPm' ? 'hour' : componentType);
                if (componentType == 'hour') {
                    let hourNumber = parseInt(value.substring(indices[0], indices[1]));
                    if (hourNumber > 12) {
                        hourNumber -= 12;
                    } else if (hourNumber == 0) {
                        hourNumber = 12;
                    }
                    return hourNumber.toString().padStart(2, '0');
                } else if (componentType == 'isPm') {
                    let hourNumber = parseInt(value.substring(indices[0], indices[1]));
                    return hourNumber > 11;
                } else {
                    return value.substring(indices[0], indices[1]);
                }
        }
    }

    const handleSetValue = (componentType, newValue) => {
        const indices = getIndicesForComponent(componentType);
        const str = `${value.substring(0, indices[0])}${newValue}${value.substring(indices[1])}`;
        setValue(str);
    }

    useEffect(() => {
        validateDateComponent('hour', hour);
    }, [isPm])

    useEffect(() => {
        if (['date', 'dateTime', 'dateTimePrecise'].includes(type)) {
            setDay(handleGetDisplayValue('day'));
            setMonth(handleGetDisplayValue('month'));
            setYear(handleGetDisplayValue('year'));
        }
        if (['dateTime', 'dateTimePrecise', 'time', 'timePrecise'].includes(type)) {
            setHour(handleGetDisplayValue('hour'));
            setMinute(handleGetDisplayValue('minute'));
            if (['dateTimePrecise', 'timePrecise'].includes(type)) {
                setSecond(handleGetDisplayValue('second'));
                setIsPm(handleGetDisplayValue('isPm'));
            }
        }
    }, [value]);

    const getDisplayValue = (componentType) => {
        return [day, month, year, hour, minute, second][componentTypes.indexOf(componentType)];
    }

    const handleSetDateComponent = (componentType, newValue) => {
        if (componentType == 'day') {
            newValue = newValue.substring(0, 2);
            setDay(newValue);
        } else if (componentType == 'month') {
            newValue = newValue.substring(0, 3);
            setMonth(newValue);
        } else if (componentType == 'year') {
            newValue = newValue.substring(0, 4);
            setYear(newValue);
        } else if (componentType == 'hour') {
            newValue = newValue.substring(0, 2);
            setHour(newValue);
        } else if (componentType == 'minute') {
            newValue = newValue.substring(0, 2);
            setMinute(newValue);
        } else if (componentType == 'second') {
            newValue = newValue.substring(0, 2);
            setSecond(newValue);
        }
    }

    const handleIncrementDateComponent = (componentType, offset) => {
        if (componentType == 'day') {
            const maxDay = parseInt(moment(value).endOf('month').format('D'));
            const newValue = (((parseInt(day) + offset) % maxDay) + maxDay) % maxDay;
            validateDateComponent('day', newValue ? newValue : maxDay);
        } else if (componentType == 'month') {
            const index = months.indexOf(moment(value).format('MMM'));
            const newValue = months[(((index + offset) % 12) + 12) % 12]
            validateDateComponent('month', newValue);
        } else if (componentType == 'year') {
            const newValue = (((parseInt(year) + offset) % 9999) + 9999) % 9999;
            validateDateComponent('year', newValue);
        } else if (componentType == 'hour') {
            const newValue = (((parseInt(hour) + offset) % 12) + 12) % 12;
            validateDateComponent('hour', newValue);
        } else if (componentType == 'minute') {
            const newValue = (((parseInt(minute) + offset) % 60) + 60) % 60;
            validateDateComponent('minute', newValue);
        } else if (componentType == 'second') {
            const newValue = (((parseInt(second) + offset) % 60) + 60) % 60;
            validateDateComponent('second', newValue);
        }
    }

    const validateDateComponent = (componentType, currentValue) => {
        if (componentType == 'day') {
            let newValue = parseInt(currentValue);
            if (!isNaN(newValue)) {
                const max = parseInt(moment(value).endOf('month').format('D'));
                newValue = Math.max(1, Math.min(max, newValue));
                newValue = newValue.toString().padStart(2, '0');
                handleSetValue('day', newValue);
            } else {
                setDay(handleGetDisplayValue('day'));
            }
        } else if (componentType == 'month') {
            let newValue = currentValue;
            if (newValue) {
                newValue = months.find((label) => {
                    return label.toLowerCase().startsWith((currentValue).toLowerCase());
                })
            }
            if (newValue) {
                const index = months.indexOf(newValue);
                let num = index + 1;
                num = num.toString().padStart(2, '0');

                const lastDayOfMonth = moment(value).set('month', index).endOf('month').format('D');
                if (validateInteger(day) > parseInt(lastDayOfMonth)) {
                    setValue(`${value.substring(0, 5)}${num}-${lastDayOfMonth}${value.substring(10)}`);
                } else {
                    handleSetValue('month', num);
                }
            } else {
                setMonth(handleGetDisplayValue('month'));
            }
        } else if (componentType == 'year') {
            let newValue = parseInt(currentValue);
            if (!isNaN(newValue)) {
                newValue = Math.max(1, Math.min(9999, newValue)).toString();
                newValue = newValue.padStart(4, '0')

                const lastDayOfMonth = moment(value).set('year', parseInt(newValue)).endOf('month').format('D');
                if (validateInteger(day) > parseInt(lastDayOfMonth)) {
                    setValue(`${newValue}${value.substring(4, 8)}${lastDayOfMonth}${value.substring(10)}`);
                } else {
                    handleSetValue('year', newValue);
                }
            } else {
                setYear(handleGetDisplayValue('year'));
            }
        } else if (componentType == 'hour') {
            let newValue = parseInt(currentValue);
            if (!isNaN(newValue)) {
                newValue = Math.max(0, Math.min(12, newValue));
                newValue = newValue % 12;
                if (isPm) {
                    handleSetValue('hour', (newValue + 12).toString().padStart(2, '0'));
                } else {
                    handleSetValue('hour', newValue.toString().padStart(2, '0'));
                }
            } else {
                setHour(handleGetDisplayValue('hour'));
            }
        } else if (componentType == 'minute') {
            let newValue = parseInt(currentValue);
            if (!isNaN(newValue)) {
                newValue = Math.max(0, Math.min(59, newValue)).toString();
                newValue = newValue.padStart(2, '0')
                handleSetValue('minute', newValue);
            } else {
                setMinute(handleGetDisplayValue('minute'));
            }
        } else if (componentType == 'second') {
            let newValue = parseInt(currentValue);
            if (!isNaN(newValue)) {
                newValue = Math.max(0, Math.min(59, newValue)).toString();
                newValue = newValue.padStart(2, '0')
                handleSetValue('second', newValue);
            } else {
                setSecond(handleGetDisplayValue('second'));
            }
        }
    }

    const handleToggleAmPm = () => {
        setIsPm(!isPm);
    }

    const toggleEnabled = () => {
        if (value) {
            setValue('');
        } else {
            let dateFormat = '';
            if (type == 'date') {
                dateFormat = 'YYYY-MM-DD';
            } else if (type == 'time') {
                dateFormat = 'HH:mm';
            } else if (type == 'timePrecise') {
                dateFormat = 'HH:mm:ss';
            } else if (type == 'dateTime') {
                dateFormat = 'YYYY-MM-DD HH:mm';
            } else if (type == 'dateTimePrecise') {
                dateFormat = 'YYYY-MM-DD HH:mm:ss';
            }

            if (defaultDate) {
                setValue(defaultDate);
            } else {
                setValue(moment().format(dateFormat));
            }

            if (['date', 'dateTime', 'dateTimePrecise'].includes(type)) {
                setDay(moment(defaultDate).format('D'));
                setMonth(moment(defaultDate).format('MMM'));
                setYear(moment(defaultDate).format('YYYY'));
                if (['dateTime', 'dateTimePrecise'].includes(type)) {
                    setHour(moment(defaultDate).format('hh'));
                    setMinute(moment(defaultDate).format('mm'));
                    setIsPm(moment(defaultDate).format('A') == 'PM');
                    if (type == 'dateTimePrecise') {
                        setSecond(moment(defaultDate).format('ss'));
                    }
                }
            } else if (['time', 'timePrecise'].includes(type)) {
                setHour(defaultDate ? defaultDate.substring(0, 2) : moment().format('hh'));
                setMinute(defaultDate ? defaultDate.substring(3, 5) : moment().format('mm'));
                setIsPm(defaultDate ? parseInt(defaultDate.substring(0, 2)) > 11 : moment().format('A') == 'PM');
                if (type == 'timePrecise') {
                    setSecond(defaultDate ? defaultDate.substring(6, 7) : moment().format('ss'));
                }
            }
            
        }
    }

    const dateExists = value ? true : false;

    const dateComponents = ['date', 'dateTime', 'dateTimePrecise'].includes(type) ? ['day', 'month', 'year'] : [];
    const dateComponentElements = dateComponents.map((componentType) => {
        return (
            <DateComponent 
                key={componentType}
                componentType={componentType} 
                handleIncrementDateComponent={handleIncrementDateComponent} 
                displayValue={getDisplayValue(componentType)} 
                dateExists={dateExists} 
                validateDateComponent={validateDateComponent} 
                handleSetDateComponent={handleSetDateComponent}
            />
        )
    })

    const timeComponents = (type == 'dateTime' || type == 'time') ? ['hour', 'minute'] : (type == 'dateTimePrecise' || type == 'timePrecise') ? ['hour', 'minute', 'second'] : [];
    const timeComponentElements = timeComponents.map((componentType) => {
        return (
            <DateComponent 
                key={componentType}
                componentType={componentType} 
                handleIncrementDateComponent={handleIncrementDateComponent} 
                displayValue={getDisplayValue(componentType)} 
                dateExists={dateExists} 
                validateDateComponent={validateDateComponent} 
                handleSetDateComponent={handleSetDateComponent}
            />
        )
    })

    return (
        <div>  
            { title && 
                <div style={{border: '1px solid lightgray', borderTopLeftRadius: 6, borderTopRightRadius: 6, paddingLeft: 6, paddingRight: 6, textAlign: 'center'}}>
                    <span style={{fontWeight: 'bold', opacity: 0.65}}>{title}</span>
                </div>
            }

            <div style={{display: 'flex', alignItems: 'center', border: '1px solid lightgray', borderTop: title ? 'none' : '1px solid lightgray', borderRadius: 6, borderTopLeftRadius: title ? 0 : 6, borderTopRightRadius: title ? 0 : 6}}>
                { dateComponents.length > 0 && 
                    <div style={{display: 'flex', paddingLeft: 1, paddingRight: 1, gap: 1}}>
                        {dateComponentElements}
                        { value && 
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <input max={'9999-12-31'} tabIndex={-1} type='date' style={{width: 21, border: 'none'}} value={value.substring(0, 10)} onChange={(e) => {
                                    if (!e.target.value) {
                                        if (optional) {
                                            setValue('');
                                        }
                                    } else if (moment(e.target.value).isValid()) { //moment(e.target.value).isValid()
                                        setValue(`${e.target.value}${value.substring(10)}`)
                                    }
                                }}/>
                            </div>
                        }
                    </div>
                }
                { timeComponents.length > 0 && 
                    <div style={{display: 'flex', alignItems: 'center', paddingLeft: 1, paddingRight: 1, borderLeft: dateComponents.length > 0 ? '1px solid lightgray' : 'none'}}>
                        {timeComponentElements}
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <button disabled={!dateExists} tabIndex={-1} style={{background: 'none', border: 'none', color: 'var(--bs-primary)'}} onClick={handleToggleAmPm}><FontAwesomeIcon icon={faChevronUp}/></button>
                            <input onKeyDown={(event) => {
                                if ((isPm && (event.key == 'a' || event.key == 'A')) || (!isPm && (event.key == 'p' || event.key == 'P'))) {
                                    handleToggleAmPm();
                                }
                            }} readOnly disabled={!dateExists} style={{width: 28, border: 'none', textAlign: 'center'}} value={dateExists ? isPm ? 'PM' : 'AM' : '-'} onChange={handleToggleAmPm}/>
                            <button disabled={!dateExists} tabIndex={-1} style={{background: 'none', border: 'none', color: 'var(--bs-primary)'}} onClick={handleToggleAmPm}><FontAwesomeIcon icon={faChevronDown}/></button>
                        </div>
                    </div>
                }
                { (optional || !value) && 
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', alignItems: 'center', height: 78, borderLeft: '1px solid lightgray', paddingLeft: 2, paddingRight: 2}}>
                        <Form.Check style={{marginRight: -8}} type="switch" checked={dateExists} onChange={toggleEnabled}/>
                    </div>
                }
            </div>
        </div>
    )
}

function DateComponent({componentType, handleIncrementDateComponent, displayValue, dateExists, validateDateComponent, handleSetDateComponent}) {
    const ref = useRef();

    return (
        <React.Fragment key={componentType}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <button disabled={!dateExists} tabIndex={-1} style={{background: 'none', border: 'none', color: 'var(--bs-primary)'}} onClick={() => handleIncrementDateComponent(componentType, 1)}>
                    <FontAwesomeIcon icon={faChevronUp}/>
                </button>
                <input 
                    ref={ref}
                    style={{
                        width: componentType == 'month' ? 34 : componentType == 'year' ? 42 : 24, 
                        border: 'none',
                        textAlign: 'center'
                    }} 
                    onFocus={() => {ref.current.select()}}
                    onBlur={() => validateDateComponent(componentType, displayValue)} 
                    onKeyDown={(e) => {if (e.key == 'Enter') {validateDateComponent(componentType, displayValue)}}} 
                    type={componentType == 'month' || !dateExists ? 'text' : 'number'}
                    value={dateExists ? displayValue : '-'}
                    disabled={!dateExists}
                    onChange={(event) => {handleSetDateComponent(componentType, event.target.value)}}
                />
                <button disabled={!dateExists} tabIndex={-1} style={{background: 'none', border: 'none', color: 'var(--bs-primary)'}} onClick={() => handleIncrementDateComponent(componentType, -1)}>
                    <FontAwesomeIcon icon={faChevronDown}/>
                </button>
            </div>
            { (componentType == 'hour' || componentType == 'minute') && 
                <span>:</span>
            }
        </React.Fragment>
    )
}