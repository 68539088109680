/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { fakeHRUserBWCData } from '../../../fake-data';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomControl from '../../../components/CustomControl';
import AddressControl from '../../../components/AddressControl';
import DropdownControl from '../../../components/DropdownControl';
import CustomButton from '../../../components/CustomButton';
import TextAreaControl from '../../../components/TextAreaControl';
import SwitchControl from '../../../components/SwitchControl';
import { createBWCRecord, reviseBWCRecord } from '../../../services/HRServices/HRBWCService';


export default function HRUserBWCForm({user, users, selectedBWC, handleAddRecord, handleReviseRecord}) {
    const [dateOfInjury, setDateOfInjury] = useState(selectedBWC ? selectedBWC.dateOfInjury : moment().format('YYYY-MM-DD'));
    const [injuryReportDate, setInjuryReportDate] = useState(selectedBWC ? selectedBWC.injuryReportDate : moment().format('YYYY-MM-DD'));
    const [injuryReportedTo, setInjuryReportedTo] = useState(selectedBWC ? selectedBWC.injuryReportedTo : undefined);
    const [treatmentFacilityName, setTreatmentFacilityName] = useState(selectedBWC ? selectedBWC.treatmentFacilityName : '');
    const [treatmentFacilityAddress, setTreatmentFacilityAddress] = useState(selectedBWC ? selectedBWC.treatmentFacilityAddress : {thoroughfare: '', premise: '', administrativeArea: '', locality: '', postalCode: ''});
    const [treatmentFacilityAdmittedDateTime, setTreatmentFacilityAdmittedDateTime] = useState(selectedBWC ? selectedBWC.treatmentFacilityAdmittedDateTime : '');
    const [salaryContinuation, setSalaryContinuation] = useState(selectedBWC ? selectedBWC.salaryContinuation : '0');
    const [rtwLightDutyDate, setRtwLightDutyDate] = useState(selectedBWC ? selectedBWC.rtwLightDutyDate : '');
    const [lightDutyDescription, setLightDutyDescription] = useState(selectedBWC ? selectedBWC.lightDutyDescription : '');
    const [rtwFullDutyDate, setRtwFullDutyDate] = useState(selectedBWC && selectedBWC.rtwFullDutyDate ? selectedBWC.rtwFullDutyDate : '');
    const [bwcInjuryClaimNumber, setBwcInjuryClaimNumber] = useState(selectedBWC ? selectedBWC.bwcInjuryClaimNumber : '');
    const [notes, setNotes] = useState(selectedBWC ? selectedBWC.notes : '');

    const [confirmSubmission, setConfirmSubmission] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    //medco14: undefined,
    //c55: undefined,
    // attachments
    // author
    // submissionDate

    const handleSubmit = () => {
        setIsLoading(true);
        setConfirmSubmission(false);
        const newBWC = {
            bwcRecordsIdentifier: selectedBWC ? selectedBWC.bwcRecordsIdentifier : undefined,
            dateOfInjury: dateOfInjury,
            injuryReportDate: injuryReportDate,
            injuryReportedTo: injuryReportedTo,
            treatmentFacilityName: treatmentFacilityName,
            treatmentFacilityAddress: treatmentFacilityAddress,
            treatmentFacilityAdmittedDateTime: treatmentFacilityAdmittedDateTime,
            salaryContinuation: salaryContinuation,
            rtwLightDutyDate: rtwLightDutyDate,
            lightDutyDescription: lightDutyDescription,
            rtwFullDutyDate: rtwFullDutyDate,
            bwcInjuryClaimNumber: bwcInjuryClaimNumber,
            notes: notes
        }
        if (selectedBWC) {
            reviseBWCRecord(newBWC).then((response) => {
                if (response && response.status === '200') {
                    newBWC.uid = response.data.uid;
                    newBWC.author = response.data.author;
                    newBWC.submissionDateTime = response.data.submissionDateTime;
                    handleReviseRecord(newBWC);
                }
                setIsLoading(false);
            });
        } else {
            createBWCRecord(user.companyUserUid, newBWC).then((response) => {
                if (response && response.status === '200') {
                    newBWC.bwcRecordsIdentifier = response.data.bwcRecordsIdentifier;
                    newBWC.uid = response.data.uid;
                    newBWC.author = response.data.author;
                    newBWC.submissionDateTime = response.data.submissionDateTime;
                    handleAddRecord(newBWC);
                }
                setIsLoading(false);
            })
        }
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{selectedBWC ? 'Revising BWC Report' : 'Creating New BWC Report'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <CustomControl lg={4} disabled={isLoading} originalValue={selectedBWC ? selectedBWC.dateOfInjury : undefined} type='date' title='Date of Injury' value={dateOfInjury} onChange={(event) => {setDateOfInjury(event.target.value)}}/>
                        <CustomControl lg={4} disabled={isLoading} originalValue={selectedBWC ? selectedBWC.injuryReportDate : undefined} type='date' title='Date Reported' value={injuryReportDate} onChange={(event) => {setInjuryReportDate(event.target.value)}}/>
                        <Col lg={4} style={{marginBottom: 8}}>
                            <DropdownControl title={'Injury Reported To'} selection={injuryReportedTo} setSelection={setInjuryReportedTo} itemNames={users.map(u => u.firstName + ' ' + u.lastName)} itemValues={users.map(u => u.companyUserUid)}/>
                        </Col>
                        <CustomControl lg={6} disabled={isLoading} max={50} originalValue={selectedBWC ? selectedBWC.treatmentFacilityName : undefined} type='text' title='Treatment Facility Name' value={treatmentFacilityName} onChange={(event) => {setTreatmentFacilityName(event.target.value)}}/>
                        <CustomControl lg={6} disabled={isLoading} originalValue={selectedBWC ? selectedBWC.treatmentFacilityAdmittedDateTime : undefined} type='datetime-local' title='Date & Time Admitted to Facility' value={treatmentFacilityAdmittedDateTime} onChange={(event) => {setTreatmentFacilityAdmittedDateTime(event.target.value)}}/>
                        <Col xxl={12} style={{marginBottom: 8}}>
                            <AddressControl lg={4} disabled={isLoading} originalValue={selectedBWC ? selectedBWC.treatmentFacilityAddress : undefined} title='Treatment Facility Address' address={treatmentFacilityAddress} setAddress={setTreatmentFacilityAddress}/>
                        </Col>
                        <Col style={{marginBottom: 8}}>
                            <SwitchControl disabled={isLoading} originalValue={selectedBWC ? selectedBWC.salaryContinuation : undefined} title='Salary Continuation?' value={salaryContinuation} setValue={(value) => {setSalaryContinuation(value)}}/>
                        </Col>
                        <CustomControl lg={6} disabled={isLoading} originalValue={selectedBWC ? selectedBWC.rtwLightDutyDate : undefined} type='date' title='Date Returned to Work for Light Duty' value={rtwLightDutyDate} onChange={(event) => {setRtwLightDutyDate(event.target.value)}}/>
                        { !rtwLightDutyDate ? '' :
                            <Col xxl={12} style={{marginBottom: 8}}>
                                <TextAreaControl disabled={isLoading} max={65535} originalValue={selectedBWC ? selectedBWC.lightDutyDescription : undefined} label='Description of Light Duty' rows={4} value={lightDutyDescription} setValue={setLightDutyDescription}/>
                            </Col>
                        }
                        <CustomControl lg={6} disabled={isLoading} originalValue={selectedBWC ? selectedBWC.rtwFullDutyDate : undefined} type='date' title='Date Returned to Work for Full Duty' value={rtwFullDutyDate} onChange={(event) => {setRtwFullDutyDate(event.target.value)}}/>
                        <CustomControl lg={6} disabled={isLoading} max={20} originalValue={selectedBWC ? selectedBWC.bwcInjuryClaimNumber : undefined} type='text' title='BWC Injury Claim #' value={bwcInjuryClaimNumber} onChange={(event) => {setBwcInjuryClaimNumber(event.target.value)}}/>
                        
                        <Col lg={6} style={{marginBottom: 8}}>
                            <Form.Group>
                                <Form.Label>Physician's Report of Work Ability (MEDCO-14)</Form.Label>  
                                <Form.Control disabled={isLoading} type='file'/>
                            </Form.Group>
                        </Col>
                        <Col lg={6} style={{marginBottom: 8}}>
                            <Form.Group>
                                <Form.Label>Salary Continuation Agreement (C-55)</Form.Label>  
                                <Form.Control disabled={isLoading} type='file'/>
                            </Form.Group>
                        </Col>
                        
                        <Col lg={12} style={{marginBottom: 8}}>
                            <Form.Group>
                                <Form.Label>Other Attachments</Form.Label>  
                                <Form.Control disabled={isLoading} type='file' multiple/>
                            </Form.Group>
                        </Col>
                        <Col xxl={12} style={{marginBottom: 8}}>
                            <TextAreaControl disabled={isLoading} max={65535} originalValue={selectedBWC ? selectedBWC.notes : undefined} label='Notes' rows={4} value={notes} setValue={setNotes}/>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton label='Submit' isLoading={isLoading} onClick={() => {setConfirmSubmission(true)}}/>
            </Modal.Footer>
            <Modal show={confirmSubmission} centered onHide={() => {setConfirmSubmission(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Submission</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Once submitted, the entered information will become permanent record. Proceed?
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{color: 'white'}} onClick={() => {setConfirmSubmission(false)}}>Cancel</Button>
                    <Button style={{color: 'white'}} onClick={handleSubmit}>Confirm Submission</Button>
                </Modal.Footer>
            </Modal>

        </>
    )
    
}

