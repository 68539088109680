/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import AddressControl from "../../../components/AddressControl";
import CustomControl from "../../../components/CustomControl";
import CustomButton from '../../../components/CustomButton';
import { createCsa, updateCsa, deleteCsa } from "../../../services/CompanyServices/CompanyCsasService";
import SwitchControl from "../../../components/SwitchControl";
import AlertModal from "../../../components/AlertModals/AlertModal";
import { packetIdentifierDictionary } from "../../../tools";
import DropdownControl from "../../../components/DropdownControl";
import { AddressValidator, validateExistence, ValidationGroup } from "../../../validation";

export default function CompanyCSAModal({data, handleCrud, selectedCompany}) {
    const [csaName, setCsaName] = useState(data ? data.csaName : '');
    const [address, setAddress] = useState(data ? data.address : {});
    const [clockInRadius, setClockInRadius] = useState(data ? data.clockInRadius.toString() : '1');
    const [isActive, setIsActive] = useState(data ? data.isActive : '1');
    const [packetIdentifier, setPacketIdentifier] = useState(data ? data.packetIdentifier : undefined);
    const [showModal, setShowModal] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const validationGroup = new ValidationGroup();
    const addressValidator = new AddressValidator(address);

    const handleSubmit = () => {
        setIsLoading(true);
        const csaToSave = {
            uid: data ? data.uid : undefined,
            csaName: csaName,
            address: address,
            clockInRadius: clockInRadius,
            packetIdentifier: packetIdentifier,
            isActive: isActive,
        }
        if (data) {
            updateCsa(selectedCompany.uid, csaToSave).then((response) => {
                if (response) {
                    handleCrud('update', csaToSave);
                }
                setIsLoading(false);
            });
        } else {
            createCsa(selectedCompany.uid, csaToSave).then((response) => {
                if (response && response.status === '200') {
                    csaToSave.uid = response.uid;
                    handleCrud('create', csaToSave);
                }
                setIsLoading(false);
            });
        }
    }

    const handleDeleteCsa = async () => {
        const response = await deleteCsa(data.uid);
        if (response.status === '200') {
            handleCrud('delete', data);
            return true;
        }
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{data ? 'Editing CSA' : 'Creating CSA'}</Modal.Title>  
                {data ? 
                    <Button style={{marginLeft: 24, color: 'white'}} onClick={() => {setShowModal(true)}}>Delete CSA</Button>              
                : ''}
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <SwitchControl xxl={12} title='CSA Is Active' originalValue={data ? data.isActive : undefined} value={isActive} setValue={setIsActive}/>
                        <CustomControl validator={validationGroup.createValidator(() => validateExistence(csaName))} md={6} type='text' title='Name' max={50} originalValue={data ? data.csaName : undefined} value={csaName} onChange={(event) => {setCsaName(event.target.value)}}/>
                        <CustomControl validator={validationGroup.createValidator(() => {})} md={6} type='number' min={0.5} step={0.1} max={10} title='Clock-In Radius (in miles)' originalValue={data ? data.clockInRadius : undefined} value={clockInRadius} onChange={(event) => {setClockInRadius(event.target.value)}}/>
                        <DropdownControl validator={validationGroup.createValidator(() => validateExistence(packetIdentifier))} selection={packetIdentifier} setSelection={setPacketIdentifier} itemNames={Object.values(packetIdentifierDictionary)} itemValues={Object.keys(packetIdentifierDictionary)} title='Onboarding Packet'/>
                        <AddressControl validator={addressValidator} md={6} title='Address' originalValue={data ? data.address : undefined} address={address} setAddress={setAddress}/>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton label={data ? 'Save Changes' : 'Create CSA'} disabled={!validationGroup.isValid() || !addressValidator.isValid()} isLoading={isLoading} onClick={handleSubmit}/>
            </Modal.Footer>
            <AlertModal 
                show={showModal} 
                onHide={() => setShowModal(false)} 
                centered 
                title='Permanently Delete CSA?' 
                message='This action cannot be undone' 
                buttonLabel='Confirm Deletion' 
                callBack={handleDeleteCsa}
            />
        </>
    )
}