import React, { useEffect, useState } from 'react'
import { getTimesheetEntries } from '../../../services/HumanResourcesService';
import moment from 'moment';
import { Button, Card, Modal } from 'react-bootstrap';
import QuickTable from '../../../components/QuickTable';
import HRUserTimesheetDateSelector from './HRUserTimesheetDateSelector';
import PageSpinner from '../../../components/PageSpinner';
import { dateRangeToString, durationToString } from '../../../tools';
import HRUserTimesheetEditor from './HRUserTimesheetEditor';

export default function HumanResourcesTimesheet({selectedEmployee, defaultStartDate, defaultEndDate, crudCallback}) {
    const [isLoading, setIsLoading] = useState(false);
    const [timesheets, setTimesheets] = useState([]);
    const [selectedStartDate, setSelectedStartDate] = useState(moment().subtract(6, 'days').format('YYYY-MM-DD'));
    const [selectedEndDate, setSelectedEndDate]= useState(moment().format('YYYY-MM-DD'));
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showEditor, setShowEditor] = useState(false);
    const [selectedEntry, setSelectedEntry] = useState(undefined);


    function sortEntries(a, b) {
        if (a.inPunch < b.inPunch) {
            return 1;
        } else if (a.inPunch > b.inPunch) {
            return -1;
        } else {
            return 0;
        }
    }
    
    async function loadData(startDate = defaultStartDate ?? selectedStartDate, endDate = defaultEndDate ?? selectedEndDate) {
        setIsLoading(true);
        const response = await getTimesheetEntries(selectedEmployee.uid, startDate ? startDate : '1000-01-01', endDate ? endDate : '3000-01-01');
        if(response.status == '200'){
            setTimesheets(response.timesheets.sort(sortEntries));
            setSelectedStartDate(startDate);
            setSelectedEndDate(endDate);
        }
        setIsLoading(false);
    }

    useEffect(()=>{
        loadData();
    }, crudCallback ? [] : [selectedEmployee])

    function handleCrud(type, data) {
        let newArray = Array.from(timesheets);
        if (type === 'create') {
            newArray.push(data);
        } else if (type === 'update') {
            newArray = newArray.filter(t => t.uid !== data.uid);
            newArray.push(data);
        } else if (type === 'delete') {
            newArray = newArray.filter(t => t.uid !== data);
        }
        newArray.sort(sortEntries);
        setTimesheets(newArray);
        setShowEditor(false);
        if (crudCallback) {
            crudCallback();
        }
    }

    function getTotalHours() {
        let totalHours = 0;
        timesheets.filter((t) => t.outPunch).forEach((e) => {
            totalHours += moment(e.outPunch).diff(moment(e.inPunch), 'hours', true);
        })
        return totalHours.toFixed(2);
    }

    const historyRows = timesheets.map((item) => {
        return (
            <tr key={item.uid} className='cursor-pointer' onClick={()=>{setSelectedEntry(item); setShowEditor(true);}}>
                <td>{moment(item.inPunch).format('MMM D, YYYY')}</td>
                <td>{moment(item.inPunch).format('h:mm:ss A')}</td>
                <td>{item.outPunch ? moment(item.outPunch).format('h:mm:ss A') : ''}</td>
                <td>{item.outPunch ? durationToString(moment(item.outPunch).diff(moment(item.inPunch), 'minutes')) : 'N/A'}</td>
                <td>{item.deviceName}</td>
            </tr>
        )
    });

    const timesheetHistoryCard = (
        <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <Card.Title>{'Total Hours Worked: ' + getTotalHours()}</Card.Title>
                <div style={{display: 'flex', gap: 12}}>
                    <Button variant={'outline-primary'} style={{ justifySelf: 'flex-end' }} onClick={() => setShowDatePicker(true)}>{dateRangeToString(selectedStartDate, selectedEndDate)}</Button>
                    <Button variant={'outline-primary'} style={{ alignSelf: 'flex-end' }} onClick={()=>{setShowEditor(true); setSelectedEntry(undefined);}}>New Timesheet Entry</Button>
                </div>
            </div>
            <QuickTable responsive={false} headers={['Date', 'In Time', 'Out Time', 'Total Time', 'Device']} rows={historyRows} hover size='sm' />
        </>
    );

    const content = (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                {timesheetHistoryCard}
        </div>
    )

  return (
    <> 
      {isLoading ? <PageSpinner/>
      :
        <div>
          {content}
          <Modal show={showDatePicker} onHide={() => setShowDatePicker(false)} centered>
              <HRUserTimesheetDateSelector
                hideModal={() => setShowDatePicker(false)}
                setDateRange={loadData}
              />
          </Modal>
          <Modal backdrop='static' show={showEditor} onHide={() => setShowEditor(false)} centered size='lg'>
                <HRUserTimesheetEditor 
                    employee={selectedEmployee}
                    selectedEntry={selectedEntry} 
                    handleCrud={handleCrud} 
                    timesheets={timesheets}
                />
          </Modal>
        </div>
      }
      </>
)

}
