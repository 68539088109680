/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";

import CustomControl from "../../../components/CustomControl";
import Row from "react-bootstrap/Row";
import Container from 'react-bootstrap/Container';
import RadioControl from "../../../components/RadioControl";
import QuickTable from "../../../components/QuickTable";
import { Button } from "react-bootstrap";
import { getFakeUID } from "../../../fake-data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faX } from "@fortawesome/free-solid-svg-icons";
import AvailabilityControl from "../../../components/AvailabilityControl";
import { encodeAvailability } from "../../../tools";


export default function CreateCompanyWorkAreas({workAreas, handleAddWorkArea, handleRemoveWorkArea, csas}) {


    const [workAreaName, setWorkAreaName] = useState('');
    const [csaIdentifier, setCsaIdentifier] = useState('');
    const [availability, setAvailability] = useState([true, true, true, true, true, true, true]);


    const workAreaRows = workAreas.map((wa) => {

        const csa = csas.find(r => r.uid === wa.csaIdentifier);

        return (
            <tr key={wa.uid}>
                <td>{wa.workAreaName}</td>
                <td>{csa.csaName}</td>
                <td>{wa.workAreaAvailability}</td>
                <td style={{width: 0}}>
                    <Button onClick={() => {handleRemoveWorkArea(wa.uid)}}>
                        <FontAwesomeIcon icon={faX}/>
                    </Button>
                </td>
            </tr>
        )
    })

    const clearForm = () => {
        setWorkAreaName('');
    }
    
    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: 8}}>
            <QuickTable headers={['Name', 'CSA', 'Availability', '']} rows={workAreaRows}/>
            <FontAwesomeIcon icon={faArrowUp} size='2x'/>
            <Container fluid style={{border: 'solid 1px lightgray', padding: 12, borderRadius: 6}}>
                <Row>
                    <CustomControl md={6} type='text' title='Name' max={50} value={workAreaName} onChange={(event) => {setWorkAreaName(event.target.value)}}/>
                    <RadioControl md={6} title='CSA' selection={csaIdentifier} setSelection={setCsaIdentifier} optionNames={csas.map(csa => csa.csaName)} optionValues={csas.map(csa => csa.uid)} inline/>
                    <AvailabilityControl md={6} title="Availability" value={availability} setValue={setAvailability} inline/>
                </Row>
                <Button disabled={!workAreaName || !csaIdentifier} onClick={() => {handleAddWorkArea({uid: getFakeUID(), workAreaName: workAreaName, csaIdentifier: csaIdentifier, workAreaAvailability: encodeAvailability(availability)}); clearForm()}}>Add Work Area</Button>
            </Container>
        </div>
    )
}